import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { SitesService } from 'src/app/sites/sites.service';
import { mainDeviceConfig } from '../device/device-factory/device-config';
import { IotahDevice } from '../device/device-factory/Iotah-device';
import { DeviceFactory } from '../device/device-factory/device-factory';
import { BattlinkSitePerformanceService } from './battlink-site-performance.service';
@Component({
	selector: 'app-battlink-site-performance',
	templateUrl: './battlink-site-performance.component.html',
	styleUrls: ['./battlink-site-performance.component.css']
})
export class BattlinkSitePerformanceComponent {
	deviceConfig: mainDeviceConfig | null = (new IotahDevice).config;;
	tags: any;
	deviceTags: any;
	devices: any;
	siteId: number = 0;
	customerId: number = 0;
	siteInfo: any;
	dataSub: any;
	data: any;
	hasDevices = false;

	constructor(
		private sideMenuService: SideMenuService,
		private sitesService: SitesService,
		private commonService: CommonService,
		private battlinkSitePerformanceService: BattlinkSitePerformanceService
	) {}

	ngOnInit() {
		this.dataSub = this.sideMenuService.currentSite.subscribe((currentSite:any) => {
			if(!currentSite || !currentSite.id)
				return;

			this.siteInfo = currentSite;
			this.customerId = currentSite.customer_id;
			this.siteId = currentSite.id;

			this.sitesService.getReportsData(this.siteId, this.deviceConfig.isCharglink, DeviceFactory.types.battlink).subscribe((reportData: any) => {
				this.prepareSiteReportData(reportData.data);
				this.prepareDevices(reportData.devices);
				this.prepareTags(reportData.tags);
			});
		});
	}

	private prepareSiteReportData(siteData) {
		let data = this.commonService.decompress(siteData, 'siteReportData');
		let result = {};

		for(let section in data) {
			result[section] = this.commonService.decompress(data[section], 'siteReportDataPeriods');
		}
		this.data = result;
	}

	private prepareDevices(devices) {
		const devicesObj = {};

		devices.forEach((device) => {
			device = this.commonService.decompress(device, 'siteDevice');
			devicesObj[device.mac_address] = device;
		});

		this.devices = devicesObj;
		this.battlinkSitePerformanceService.updateTypesList(this.devices);
		this.hasDevices = Object.keys(devicesObj).length > 0;
	}

	private prepareTags(tagsData) {
		const siteTags = [];
		const deviceTags = {};

		Object.values(tagsData.siteTags).forEach((tag) => {
			siteTags.push(this.commonService.decompress(tag, 'tag'));
		});

		for(let macAddress in tagsData.deviceTags) {
			deviceTags[macAddress] = deviceTags[macAddress] || [];
			Object.values(tagsData.deviceTags[macAddress]).forEach((tag) => {
				deviceTags[macAddress].push(this.commonService.decompress(tag, 'deviceTag'));
			});
		}

		this.tags = siteTags;
		this.deviceTags = deviceTags;
	}

	ngOnDestroy() {
		this.dataSub.unsubscribe();
	}
}
