import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { SitesService } from 'src/app/sites/sites.service';
import { UsersService } from 'src/app/users/users.service';
import { SiteDashboardService } from '../../site-dashboard.service';

@Component({
	selector: 'app-site-settings',
	templateUrl: './site-settings.component.html',
	styleUrls: ['./site-settings.component.css']
})
export class SiteSettingsComponent implements OnInit, OnDestroy {
	errorMassages = 'g.invalid_data';
	siteId: number;
	workingDaysOptions: any;
	selectedWorkingDays: any;

	voltageLevels: any;
	isValidVoltageLevel = true;
	currentUser: any = {};

	subSite: Subscription = new Subscription();
	permissionSub: Subscription = new Subscription();

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private sideMenuService: SideMenuService,
		private usersService: UsersService,
		private sitesService: SitesService,
		private notificationMessage: NotificationMessageService,
		private siteDashboardService: SiteDashboardService,
	) { }

	ngOnInit(): void {
		this.currentUser = this.usersService.getCurrentUser();
		this.subSite = this.sideMenuService.currentSite.subscribe((data: any) => {
			if (!data || !data['id'])
				return;
			let siteId = Number(this.route.parent.snapshot.params['siteId']);
			let customerId = Number(this.route.parent.snapshot.params['customerId']);
			if (data.id != siteId || data['customer_id'] != customerId)
				return;

			this.siteId = siteId;
			this.permissionSub = this.usersService.getPermissions(this.siteId).subscribe((permissions: any) => {
				if(!this.usersService.hasAccessPermission(permissions, 'basic_setup', 'write'))
					return this.router.navigate(['/unauthorized']);
			});
			this.selectedWorkingDays = data.working_days || this.sitesService.workingDaysOptions.operation_5;
			this.voltageLevels = data.voltage_levels ? data.voltage_levels : this.siteDashboardService.voltageLevels;
		});
		this.workingDaysOptions = Object.values(this.sitesService.workingDaysOptions);
	}


	setSiteWorkingDays() {
		this.sitesService.setSiteWorkingDays(this.siteId, this.selectedWorkingDays).subscribe((res: any) => {
			this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
		});
	}

	setVoltageLevelColors() {
		this.isValidVoltageLevel = this.isVoltageLimitsValid();
		if (!this.isValidVoltageLevel)
			return;
		this.sitesService.setSiteEndVoltageLevels(this.siteId, this.voltageLevels).subscribe((res: any) => {
			this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
		});
	}

	isVoltageLimitsValid(): boolean {
		const level1 = this.voltageLevels[0];
		const level2 = this.voltageLevels[1];
		const level3 = this.voltageLevels[2];

		let hasNullValue = false;
		let hasNegativeValue = false;
		this.voltageLevels.forEach(level => {
			// fields should not be empty
			if (level.min == null || level.max == null)
				hasNullValue = true;
			// Check if there are negative values
			if (level.min < 0 || level.max < 0)
				hasNegativeValue = true;
		});

		if (hasNullValue) {
			this.errorMassages = "g.empty_field";
			return false;
		}

		if (hasNegativeValue) {
			this.errorMassages = "g.negative_values";
			return false;
		}

		for (let i = 0; i < this.voltageLevels.length; i++) {
			const currentLevel = this.voltageLevels[i];
			const nextLevel = this.voltageLevels[i + 1];

			// Check if levels are overlapping
			if (nextLevel && currentLevel.max > nextLevel.min) {
					this.errorMassages = "g.ranges_overlap";
					return false;
			}

			// Check if there are gaps
			if (nextLevel && nextLevel.min != currentLevel.max) {
				this.errorMassages = "g.ranges_gaps";
				return false;
			}

			// Check if min is less than max
			if (currentLevel.max <= currentLevel.min) {
				this.errorMassages = "g.max_less_than_min";
				return false;
			}
		}

		return true;
	}

	ngOnDestroy() {
		this.subSite.unsubscribe();
		this.permissionSub.unsubscribe();
	}
}
