<app-loader></app-loader>
<div>
	<div class="page-container">
		<app-notification-message></app-notification-message>
		<header>
			<img src="/sctportal/{{loginLogo}}" [class]="imageClass" alt="smartTelemetrics">
			<div></div>
		</header>
		<div class="form-container">
			<div class="login-text">
				<h1>{{ pagesTitles[page] | translate}}</h1>
				<h1 *ngIf="page == 'login'">{{"login_register.your_account" | translate}}</h1>
				<p *ngIf="pagesSubTitles[page]">{{ pagesSubTitles[page] }}</p>
			</div>
			<router-outlet></router-outlet>
		</div>
		<footer>
			<p>{{"© " + copyrightYear + " " + siteName}}. {{"g.all_rights_reserved" | translate}}</p>
			<div class="mobile-apps">
				<a href="{{appStoreAppLink}}" target="_blank">
					<img src="/sctportal/images/smartTelemetrics/app_store.svg" alt="smartTelemetrics">
				</a>
				<a href="{{googlePlayAppLink}}" target="_blank">
					<img src="/sctportal/images/smartTelemetrics/google_play.svg" alt="smartTelemetrics">
				</a>
			</div>
		</footer>
		<div class="cloud-container">
			<div class="clouds"></div>
		</div>
	</div>
</div>
