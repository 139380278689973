import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '../../device.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { DomainsService } from 'src/app/shared/services/domains.service';

@Component({
	selector: 'app-set-adc',
	templateUrl: './set-adc.component.html',
	styleUrls: ['./set-adc.component.css']
})
export class SetAdcComponent implements OnInit {

	enableOptions = [];
	adc: any = {
		el_enable: '',
		ext_adc_voltage_enable: '',
		current_low_enable: '',
		halleffect_c_enable: '',
		ext_adc_current_enable: '',
		temperature_adc_enable: '',
		unused_vals: 0,
		unused_enable: 0,
		unused: 0
	};
	@Input() device: any = {};

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		public translate: TranslateService,
		private deviceService : DeviceService,
		private notificationMessage: NotificationMessageService,
		private domainsService: DomainsService,
	) { }

	ngOnInit() {
		// check user permission debug_data to allow page access
		const isProduction = this.domainsService.isProductionDomain;
		if(isProduction) return this.router.navigate(['/unauthorized']);;

		this.route.params.subscribe(params => {
			this.device.macAddress = params.deviceId || '';
		});
		this.enableOptions = [
			{text: this.translate.instant('g.select'), val: ''},
			{text: this.translate.instant('g.disable'), val: 0},
			{text: this.translate.instant('g.enable'), val: 1},
			{text: this.translate.instant('adc.option_3'), val: 2},
		];
	}

	setADC() {
		for (let item in this.adc) {
			if (this.adc[item] == null) {
				delete this.adc[item];
			} else if (!item.endsWith('enable')) {
				this.adc[item] = this.adc[item] ? parseFloat(this.adc[item]).toFixed(2) : '';
			}
		}
		this.deviceService.sendCommand(this.device.mac_address, 'writeAdcOverride', this.adc).subscribe((data: any) => {
			if (data.api_status == this.deviceService.deviceCommandsResponse) {
				this.notificationMessage.setMessage('translate|settings.queue_and_wait_timeout_msg', {clearOnXTimeNavigate: 1, type: 'warning'});
			} else {
				this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			}
		});
	}

	readADC() {
		this.deviceService.sendCommand(this.device.mac_address, 'readAdcOverride').subscribe((data: any) => {
			this.adc = data;
		});
	}
}
