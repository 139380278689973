import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})

export class AlertSettingsService {
	route = '/alertSettings/';
	constructor(
		private httpClient: HttpClient,
	) { }

	getUsersAlertSettings(site_id) {
		return this.httpClient.post(this.route + 'getUsersAlertSettings', {site_id}, {
			observe: "body"
		});
	}

	saveUserAlertSettings(user_id: number, userAlertSettings, site_id) {
		return this.httpClient.post(this.route + 'saveUserAlertSettings', {user_id, userAlertSettings, site_id}, {
			observe: "body"
		});
	}

	getSiteAlertSettings(siteId: number) {
		return this.httpClient.get(this.route + 'getSiteAlertSettings', {
			observe: "body",
			params: {
				siteId
			}
		});
	}

	saveSiteAlertSettings(siteId: number, siteAlertSettings: any) {
		return this.httpClient.post(this.route + 'saveSiteAlertSettings', { siteId, siteAlertSettings }, {
			observe: "body"
		});
	}
}
