<div class="main-black border-radius-bottom white-box">
	<div class="m-0 p-4">
		<div class="card mt-2">
			<div class="panel-body p-3">
				<div *ngFor="let group of siteGroups" class="float-left">
					<div class="btn btn-md btn-outline-secondary pointer p-1 m-1 word-break-btn btn-group">
						<span>{{group.group_text}}</span>
						<span appDropdown>
							<img class="size-15x15 pointer dropdown opacity-50 ml-1" src="/sctportal/images/settings.svg">
							<ul class="dropdown-menu gear-dropdown-menu" role="menu">
								<li>
									<a class="pointer dropdown-item" (click)="showConfirmationModal(group.id)">{{'charglink_brm.delete_group' | translate}}</a>
								</li>
							</ul>
						</span>
					</div>
				</div>
			</div>
		</div>

		<div class="card mt-4">
			<div class="card-header"><strong>{{'charglink_brm.groups' | translate}}</strong></div>
			<div class="panel-body p-3">
				<div class="d-flex flex-row">
					<input type="text" class="form-control" name="newBRMGroups" [(ngModel)]="newGroup" placeholder="{{'g.add' | translate}}">
					<button class="btn btn-primary" (click)="addGroup()">{{'g.add' | translate}}</button>
				</div>
			</div>
		</div>
	</div>
</div>

<app-modal #deleteConfirmModal title="{{'g.confirm_action' | translate}}" [closeBtnText]="'g.cancel' | translate">
	<p>{{'charglink_brm.delete_brm_group_confirm_msg'| translate: { "groupName": groupToDelete.group_text } }}</p>
</app-modal>
