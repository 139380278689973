import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import {Subscription} from 'rxjs';

import { LineChartConfig } from '../Models/LineChartConfig';
import { GoogleLineChartService } from '../Services/google-line-chart.service';

declare var google: any;

@Component({
	selector: 'line-chart',
	template: '<div id="{{chartElementId}}"></div>'
})
export class LineChartComponent implements OnInit, OnChanges {

	@Input() data: any[];
	@Input() config: LineChartConfig;
	@Input() elementId: string;
	@Output() onSelectItem = new EventEmitter<any>(true);
	@Output() onChartReady = new EventEmitter<any>();
	chartElementId: string;

	subscription:Subscription;
	readySubscription: Subscription;
	_lineChartService: GoogleLineChartService = new GoogleLineChartService();

		constructor() {}

	ngOnInit(): void {
		this.chartElementId = 'randomName_' + Math.random() + this.elementId;
		if (this.data && this.data.length > 0) {
			this.buildChart();
		}

		this.subscription = this._lineChartService.selectItem.subscribe(
			item => {
				this.onSelectItem.emit(item);
			}
		);

		this.readySubscription = this._lineChartService.chartReady.subscribe(
			chart => {
				this.onChartReady.emit({elementId: this.elementId, chart: chart});
			}
		);
	}

	ngOnChanges(changes: SimpleChanges) {
		if(this.chartElementId && ((changes['data'] && changes['data'].previousValue != changes['data'].currentValue) || (changes['config'] && changes['config'].previousValue != changes['config'].currentValue))) {
			this.buildChart();
		}
	}

	buildChart() {
		this._lineChartService.BuildLineChart(this.chartElementId, this.data, this.config);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.readySubscription.unsubscribe();
	}
}
