<div *ngIf="hasDevices; else noDevices">
	<div class="p-3 gray-bg d-flex flex-nowrap">
		<div class="col-4" *ngIf="hasWarningAccess">
			<ng-select
				[(ngModel)]="filters.warningFilter"
				placeholder="{{'devices.warnings_filter' | translate}}"
				[items]="warningOptionsList"
				bindLabel="value"
				bindValue="id"
				(ngModelChange)="filterListing()"
				name="warning_filter"
				[multiple]="true"
			></ng-select>
		</div>
		<div class="col-4" *ngIf="truckTypes.length > 0 && isIotAHDomain">
			<ng-select
				[(ngModel)]="filters.truckTypeFilter"
				placeholder="{{'devices.truck_type_filter' | translate}}"
				[items]="truckTypes"
				(ngModelChange)="filterListing()"
				name="truck_type_filter"
				[multiple]="true"
			></ng-select>
		</div>
		<div class="col-4" *ngIf="chargerManufacturers.length > 0 && isChargLinkDomain">
			<ng-select
				[(ngModel)]="filters.chargerManufacturersFilter"
				placeholder="{{'chargLinkDevice.site.filter_by_charger' | translate}}"
				[items]="chargerManufacturers"
				(ngModelChange)="filterListing()"
				name="charger_manufacturer_filter"
				[multiple]="true"
			></ng-select>
		</div>
		<div class="col-4">
			<ng-select
				[(ngModel)]="filters.lastConnectTime"
				placeholder="{{'devices.filter_by_connectivity_status' | translate}}"
				[items]="connectTimeOptions"
				(ngModelChange)="filterListing()"
				name="last_connect_time_filter"
				[multiple]="false"
				bindLabel="label"
				bindValue="id"
				[clearable]="true"
			></ng-select>
		</div>
	</div>

	<div class="white-box p-3 mb-3">
		<div>
			<h4>
				{{'tags.site_tags' | translate}}
				<app-help-tooltip *ngIf="tooltips.site_tags" [field]="tooltips.site_tags"></app-help-tooltip>
			</h4>
		</div>
		<div class="mt-1">
			<div *ngFor="let tag of siteTags" class="btn-group pointer p-1">
				<button class="btn btn-md" (click)="filterTags(tag)" [ngClass]="{'active': tag.isActive, 'btn-outline-danger': tag.sharing_option == tagsService.shareOptions.private, 'btn-outline-info': tag.sharing_option == tagsService.shareOptions.group, 'btn-outline-success': tag.sharing_option == tagsService.shareOptions.public}">
					{{tag.tag_name}}
				</button>
			</div>
		</div>

		<div class="mt-3">
			<button class="btn btn-md btn-primary" (click)="showAddTagBox()" *ngIf="countOfDevicesChecked > 0">
				{{'tags.add_tags' | translate}}
			</button>
			<button class="btn btn-md btn-danger ml-2" (click)="deleteTags()" *ngIf="countOfDevicesChecked > 0 && commonTags.length > 0">
				{{'tags.delete_tags' | translate}}
			</button>
		</div>
	</div>

	<div *ngIf="showBRMGroups" class="white-box p-3 mb-3">
		<div>
			<h4>
				{{'nav.brm_groups' | translate}}
			</h4>
		</div>
		<div class="row">
			<ng-select
				class="col-4"
				placeholder="{{'nav.brm_groups' | translate}}"
				[items]="brmGroups"
				[(ngModel)]="deviceBRMGroupId"
				bindLabel="group_text"
				bindValue="id"
				name="brm_group_filter"
				[multiple]="false"
				[clearable]="true">
			</ng-select>
		</div>
		<div class="mt-3" *ngIf="showBRMGroupsModal">
			<button class="btn btn-md btn-primary" (click)="assignDeviceToBRMGroupModal.show()" [disabled]="!deviceBRMGroupId">
				{{'charglink_brm.asign_device_brm_group' | translate}}
			</button>
		</div>
	</div>

	<app-modal #addTagModal [hasConfirmButton]="false" [hasCloseButton]="false" title="{{'tags.add_tags' | translate}}">
		<div>
			<app-tag-input
				[items]="siteTags"
				[tagInput]="commonTags"
				[siteId]="siteId"
				[isSCTuser]="currentUser.isSCT"
				[deviceId]="getSelectedDevices()"
				(onUpdateTags)="siteTagAdded($event)"
			></app-tag-input>
		</div>
		<div class="mt-3">
			<button class="btn btn-md btn-primary" (click)="addTagModal.hide()">
				{{'g.done' | translate}}
			</button>
		</div>
	</app-modal>

	<app-modal #exportEventsModal [hasConfirmButton]="false" [hasCloseButton]="false" title="{{'devices.export_events' | translate}}">
		<div class="row">
			<div class="col-md-12 mb-3">
				<div class="input-group">
					<div class="input-group-prepend">
						<span class="input-group-text">{{'g.start_date' | translate}}</span>
					</div>
					<input [ngClass]="{'invalid-input': invalidStartDate || dateRangeError}" type="text" class="form-control" placeholder="mm/dd/yyyy" name="dp1" [(ngModel)]="fromDate" bsDatepicker #d1="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}" [minDate]="minDate">
					<div class="input-group-append pointer" (click)="d1.toggle()">
						<span class="input-group-text"><i class="fa fa-calendar"></i></span>
					</div>
				</div>
			</div>

			<div class="col-md-12">
				<div class="input-group">
					<div class="input-group-prepend">
						<span class="input-group-text">{{'g.end_date' | translate}}</span>
					</div>
					<input [ngClass]="{'invalid-input': dateRangeError}" type="text" class="form-control" placeholder="mm/dd/yyyy" name="dp2" [(ngModel)]="toDate" bsDatepicker #d2="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}">
					<div class="input-group-append pointer" (click)="d2.toggle()">
						<span class="input-group-text"><i class="fa fa-calendar"></i></span>
					</div>
				</div>
			</div>
			<div class="col-md-12 mt-3">
				<div class="d-flex mb-3">
					<div class="dropdown ml-auto" appDropdown>
						<img class="size-20x20 pointer dropdown opacity-50 pointer mr-2" dropdown-toggle src="/sctportal/images/settings.svg">
						<ul class="dropdown-menu grid-dropdown-menu dropdown-menu-custom dropdown-menu-right" role="menu">
							<li *ngFor="let col of columns">
								<a class="pointer" (click)="toggleColumn(col)"><small><i class="fa" [ngClass]="col.hide ? 'fa-times' : 'fa-check'"></i> {{col.headerName | translate}}</small></a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<p class=" ml-2 text-danger" *ngIf="exportEventDateError"><small>{{exportEventDateError}}</small></p>
		</div>
		<div class="mt-3 d-flex">
			<button class="btn btn-md btn-primary" (click)="queueExportEventsRequest()">
				{{'devices.export_csv' | translate}}
			</button>
		</div>
	</app-modal>

	<div class="white-box p-4 m-0 w-100 border-radius-bottom">
			<a *ngIf="visibility.bulk_fill_device_info" [routerLink]="['/admin-panel/bulk_fill_device_info', customerId, siteId]">
				<button class="btn btn-primary ml-2">{{'devices.bulk_fill_device_info' | translate}}</button>
			</a>
			<div class="d-flex flex-row" *ngIf="devicesListing.length > 0">
				<div class="form-check pt-1">
					<input class="form-check-input" type="checkbox" [(ngModel)]="selectedAll" (ngModelChange)="selectAll($event)" id="selectAll">
					<label class="form-check-label" for="selectAll">
						{{'g.select_all' | translate}}
					</label>
				</div>
				<span *ngIf="countOfDevicesChecked > 0">
					<button class="btn btn-primary ml-2" (click)="saveSettings()">
						{{'g.settings' | translate}}
					</button>
					<button class="btn btn-primary ml-2" *ngIf="deviceService.showManageDeviceOption({}, currentSite)" (click)="manageDevices()">
						{{'devices.manage_device' | translate}}
					</button>
					<button class="btn btn-primary ml-2" *ngIf="!deviceService.showManageDeviceOption({}, currentSite) && !currentSite.is_special" (click)="manageDevices()">
						{{'devices.request_manage_device' | translate}}
					</button>
					<button class="btn btn-primary ml-2" (click)="openLastPMModal()" *ngIf="visibility.set_maintenance_date && !hideSetMaintenanceDateButton">
						{{'devices.set_maintenance_date' | translate}}
					</button>
					<button class="btn btn-primary ml-2" *ngIf="visibility.export_events" (click)="showExportEventsPopup()">
						{{'devices.export_events' | translate}}
					</button>
					<button class="btn btn-primary ml-2" *ngIf="visibility.export_last_connect_time_devices" (click)="exportDevicesLastConnectTime()">
						{{'devices.export_last_connect_time_devices'| translate}}
					</button>
				</span>
			</div>

		<ng-container *ngFor="let device of devicesListing; let idx = index">
			<div class="row m-0 h-100 align-items-center text-center" *ngIf="(idx % 4) == 0 && devicesListing[idx]">
				<div class="col-md-3 p-2" *ngIf="devicesListing[idx]">
					<ng-container *ngTemplateOutlet="deviceItem;context:{device: devicesListing[idx]}" ></ng-container>
				</div>
				<div class="col-md-3 p-2" *ngIf="devicesListing[idx+1]">
					<ng-container *ngTemplateOutlet="deviceItem;context:{device: devicesListing[idx+1]}" ></ng-container>
				</div>
				<div class="col-md-3 p-2" *ngIf="devicesListing[idx+2]">
					<ng-container *ngTemplateOutlet="deviceItem;context:{device: devicesListing[idx+2]}" ></ng-container>
				</div>
				<div class="col-md-3 p-2" *ngIf="devicesListing[idx+3]">
					<ng-container *ngTemplateOutlet="deviceItem;context:{device: devicesListing[idx+3]}" ></ng-container>
				</div>
			</div>
		</ng-container>

		<div class="alert alert-info" *ngIf="devicesListing.length === 0 && allDevices.length > 0">
			<strong>{{'devices.filter_with_no_results' | translate}}</strong>
		</div>
	</div>
</div>

<!-- device card -->
<!-- [pTooltip]="charger.tooltipMessage.join('\n')" tooltipPosition="top" tooltipStyleClass="device-changes-log" -->
<ng-template #deviceItem let-device="device">
	<div class="border p-3 device-card {{device.bgClass}}" data-toggle="tooltip" data-placement="top" [title]="device.tooltipMessages?.join('\n')" [ngClass]="{'gray-bg': device.connectivityFlag == 4}">
		<div class="text-center">
			<div>
				<div class="row p-0 m-0">
					<div class="pointer" *ngIf="device.connectivityStatus">
						<a [routerLink]="deviceService.getImageRouterLink('cellular', customerId, {mac_address: device.mac_address, site_id: siteId})">
							<img src="/sctportal{{device.connectivityStatus}}" class="size-35x35">
						</a>
					</div>

					<div class="pointer" *ngIf="device.wifiConnectivityStatus">
						<a [routerLink]="deviceService.getImageRouterLink('wifi', customerId, {mac_address: device.mac_address, site_id: siteId})" [queryParams]="{tab: 'networking'}">
							<img src="{{device.wifiConnectivityStatus}}" class="size-35x35">
						</a>
					</div>
					<div class="ml-auto">
						<input type="checkbox" [(ngModel)]="device.selected" name="group" (ngModelChange)="deviceChecked($event)"  (click)="$event.stopPropagation();" />
					</div>
					<div class="ml-auto mt-1" appDropdown (click)="$event.stopPropagation()">
						<img class="size-25x25 pointer dropdown opacity-50" src="/sctportal/images/settings.svg">
						<ul class="dropdown-menu gear-dropdown-menu" role="menu">
							<!-- <li><a
								class="dropdown-item"
								[routerLink]="['/', customerId, siteId,  device.mac_address, 'performance']">{{'g.analytics' | translate}}</a></li> -->
							<li><a
								class="dropdown-item"
								[routerLink]="['/', customerId, siteId,  device.mac_address, 'settings']" [queryParams]="{tab: 'info'}">{{'g.settings' | translate}}</a></li>
							<li><a
								class="dropdown-item"
								[routerLink]="['/', customerId, siteId,  device.mac_address, 'quick-view']"> {{'nav.quick_view' | translate}} </a></li>
							<li
								class="dropdown-submenu pointer" appDropdown>
								<a class="dropdown-item" tabindex="-1">
									<span>{{'g.actions' | translate}}
										<i class="fa fa-caret-right margin-left-sm"></i>
									</span>
								</a>
								<ul class="dropdown-menu dropdown-menu-inner" role="menu">
									<li *ngIf="deviceService.showManageDeviceOption(device, currentSite); else requestManage">
										<a class="dropdown-item" [routerLink]="['/device', 'management', device.mac_address]">{{'devices.manage_device' | translate}}</a>
									</li>
									<li *ngIf="visibility.restart_device">
										<a class="dropdown-item has-action pointer"  (click)="sendCommand('restartDevice', device.mac_address)">{{'actions.restart_device' | translate}}</a>
									</li>
									<li *ngIf="visibility.reset_rtc_lost_detection && device.lost_rtc_time && device.last_cm_date_tested < device.lost_rtc_time">
										<a class="dropdown-item has-action" (click)="resetRTCLostDetectionModal.passData = [device.mac_address]; resetRTCLostDetectionModal.show();">{{'actions.reset_rtc_lost_detection' | translate}}</a>
									</li>
									<li *ngIf="visibility.factory_reset">
										<a class="dropdown-item has-action pointer" (click)="factoryResetModal.passData = ['factoryReset', device.mac_address]; factoryResetModal.show();">{{'actions.factory_reset' | translate}}</a>
									</li>
									<ng-template #requestManage>
										<li *ngIf="!deviceService.showManageDeviceOption(device, currentSite) && ((commonService.powerStudiesDevicesSiteName == currentSite.name && device.is_study_device) || !currentSite.is_special)">
											<a class="dropdown-item" [routerLink]="['/device', 'management-request', device.mac_address]">{{'devices.request_manage_device' | translate}}</a>
										</li>
									</ng-template>
								</ul>
							</li>
						</ul>
					</div>
				</div>
				<div *ngIf="device.isBrm">
					<div class="light-grey btn btn-success disabled">
						<span class="main-black bold"><b>{{'charglink_brm.brm_group' | translate}}</b>: {{brmGroupsById[device.brm_group_id]?.group_text || ('charglink_brm.no_brm_group' | translate) }}</span>
					</div>
				</div>
				<a [routerLink]="['/', customerId, siteId,  device.mac_address, 'performance']">
					<img [class]="'mt-2 mb-3 pointer '+ getLogoClasses(device) + (+device.is_study_device ? 'study-logo': '')" src="/sctportal/{{(device.imageLink)}}" width="158" height="100">
				</a>
			</div>
		</div>
		<div class="text-center small pointer">
			<div>
				<b>{{device.serial_number}}</b>
			</div>
			<div>
				<div class="light-grey">{{(device.truck_sn?device.truck_sn:'&nbsp;')}}</div>
			</div>
		</div>
		<div class="d-inline-block">
			<div class="float-left p-1" *ngFor="let tag of getTags(device)">
				<button class="btn btn-sm" [ngClass]="{'btn-outline-danger': tag.sharing_option == tagsService.shareOptions.private, 'btn-outline-info': tag.sharing_option == tagsService.shareOptions.group, 'btn-outline-success': tag.sharing_option == tagsService.shareOptions.public, 'replacement-tag': tag.keep_in_replacement && currentUser.isSCT}">
					{{tag.tag_name}}
				</button>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #noDevices>
	<div class="panel panel-default">
		<div class="panel-body">
			<strong>{{'devices.no_device_registered' | translate}}</strong>
		</div>
	</div>
</ng-template>

<app-modal #removeTagModal title="{{'tags.delete_tags' | translate}}" [hasConfirmButton]="false" [hasCloseButton]="false" [closeBtnText]="'g.cancel' | translate">
	<p>{{'tags.remove_tags_msg' | translate}}</p>
	<div class="col-md-12">
		<div class="form-check col-md-4" *ngFor="let tag of tagsNamesToDelete; let i = index">
			<input class="form-check-input" type="checkbox" value="tag" [(ngModel)]="selectedTagsToDelete[i]" [checked]="selectedTagsToDelete[i]">
			<label class="form-check-label">{{tag.tag_name}}</label>
		</div>
	</div>
	<div class="modal-footer mt-3">
		<button type="button" class="btn btn-primary" (click)="removeTagModal.onConfirm()" [disabled]="isDeleteBtnDisabled()">{{'g.confirm' | translate}}</button>
		<button type="button" class="btn btn-light" (click)="removeTagModal.onHide()">{{'g.close' | translate}}</button>
	</div>
</app-modal>

<app-modal #factoryResetModal title="{{'devices.confirm_factory_reset' | translate}}" [closeBtnText]="'g.cancel' | translate">
	{{'settings.factory_reset_prompt' | translate}}
</app-modal>

<app-modal #lastPMModal title="{{'actions.set_last_maintenance_date' | translate}}" [hasConfirmButton]="false" [hasCloseButton]="false">
	<div class="mb-5">
		<input
			class="form-control"
			[ngClass]="{'invalid-input' : !lastPMModalVars.isValid}"
			name="last_pm_date"
			placeholder="mm/dd/yyyy"
			[(ngModel)]="lastPMModalVars.lastPMDate"
			bsDatepicker #d="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }" [minDate]="lastPMModalVars.min" [maxDate]="lastPMModalVars.max"
			(ngModelChange)="lastPMModalVars.isValid=true"
			check-permission="basic_setup"
			calender
		/>
		<p class="text-danger mt-2" *ngIf="!lastPMModalVars.isValid">{{'g.invalid_input' | translate}}!</p>
	</div>
	<div class="modal-footer">
		<button class="btn btn-default mr-2" (click)="closeLastPMModal()">{{'g.cancel' | translate}}</button>
		<button class="btn btn-primary" (click)="setLastPMDate()" [disabled]="!lastPMModalVars.isValid">{{'g.ok' | translate}}</button>
	</div>
</app-modal>

<app-modal #resetRTCLostDetectionModal title="{{'devices.confirm_reset_rtc_lost_detection' | translate}}" [closeBtnText]="'g.cancel' | translate">
	{{'settings.reset_rtc_lost_detection_prompt' | translate}}
</app-modal>

<app-modal #assignDeviceToBRMGroupModal [hasConfirmButton]="true" [hasCloseButton]="true" title="{{'charglink_brm.asign_device_brm_group' | translate}}">
	<p> {{'charglink_brm.add_device_brm' |translate}} </p>
</app-modal>
