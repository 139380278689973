import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import { DeviceService } from '../../device.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-study-charge-cycle-chart',
	templateUrl: './study-charge-cycle-chart.component.html',
	styleUrls: ['./study-charge-cycle-chart.component.css']
})
export class StudyChargeCycleChartComponent {
	@Input() minDeviceDate: any = null;
	@Input() maxDeviceDate: any = null;
	@Input() studyInfo: any = {};
	@Input() device: any = [];

	macAddress: string | null = null;

	// chart
	chartObject: any = {
		formatterType: 'DateFormat',
		formatter: {pattern: "MM/dd/yyyy"},
		columnNames: ['Date', 'Charge (Ah)', 'Discharge (Ah)'],
		config: {
			orientation: 'horizontal',
			vAxis: {
				title: 'Charge Cycles (Ah)',
			},
			hAxis: {
				title: this.translate.instant('g.date'),
				format: "MM/dd/yyyy",
			},
			chartArea: {
				bottom: 120,
			},
		},
	};

	// date data
	deviceInstallationDate: any = moment(0).toDate();
	fromDate: Date = moment().subtract(3, 'months').toDate();
	toDate: Date = moment().subtract(1, 'day').toDate();
	maxDate = moment().subtract(1, 'day').toDate();
	invalidDateRange: boolean = false;

	constructor(
		private deviceService: DeviceService,
		private translate: TranslateService
	) { }

	ngOnInit() {
		if (this.maxDeviceDate && this.maxDeviceDate < this.maxDate) {
			this.maxDate = this.maxDeviceDate;
			this.toDate = this.maxDeviceDate;
		}
	}

	ngOnChanges(changes) {
		this.deviceInstallationDate = moment(0).toDate();
		this.fromDate = moment().subtract(3, 'months').toDate();
		this.maxDate = new Date(new Date().setDate(new Date().getDate() - 1));
		if (this.studyInfo?.id)
			this.handleStudyDeviceStartEndDate();

		if(changes.device) {
			if (this.maxDeviceDate && this.maxDeviceDate < this.maxDate) {
				this.maxDate = this.maxDeviceDate;
				this.toDate = this.maxDeviceDate;
			}

			if (!this.device.config_info.is_charglink)
				this.macAddress = this.device?.config_info?.mac_address;

			this.updateInstallationDate((this.device.config_info.installation_date || 0));
			this.getChargeCycleData();
		}
	}

	DatesChanged() {
		this.invalidDateRange = false;
		if(moment(this.fromDate).unix() > moment(this.toDate).unix())
			return this.invalidDateRange = true;

		this.getChargeCycleData();
	}

	handleStudyDeviceStartEndDate() {
		this.deviceInstallationDate = new Date(this.studyInfo.start_study_time*1000);
		this.maxDate = this.studyInfo.is_done ? new Date(this.studyInfo.end_study_time*1000) : new Date(new Date().setDate(new Date().getDate()));
		this.fromDate = this.deviceInstallationDate;
		this.toDate = this.maxDate;
		this.getChargeCycleData();
	}

	updateInstallationDate(date) {
		if (date > 0)
			this.deviceInstallationDate = moment(date*1000).toDate();

		if (this.minDeviceDate && moment(this.minDeviceDate).unix() > moment(this.deviceInstallationDate).unix())
			this.deviceInstallationDate = this.minDeviceDate;

		if(moment(this.fromDate).unix() < moment(this.deviceInstallationDate).unix())
			this.fromDate = this.deviceInstallationDate;
	}

	getChargeCycleData() {
		if (!this.macAddress) return;

		let zoneDiff = new Date().getTimezoneOffset() * -1;
		let from:any = new Date(new Date(this.fromDate).getTime() + (zoneDiff * 60 * 1000));
		let to:any = new Date(new Date(this.toDate).getTime() + (zoneDiff * 60 * 1000));

		from = moment(from).utc().startOf('day').unix();
		to = moment(to).utc().endOf('day').unix();


		this.deviceService.getDeviceChargeCycles(this.macAddress, from, to).subscribe((res: any) => {
			this.prepareChartData(res);
		})
	}

	prepareChartData(data) {
		if (!data || !data.length) return;

		let chartData = [];
		data.forEach((item) => {
			chartData.push([
				new Date(+item.date*1000),
				item.charge_as ? +(item.charge_as / (60 * 60)).toFixed(2) : 0,
				item.inuse_as ?  +(item.inuse_as / (60 * 60)).toFixed(2) : 0,
			]);
		});

		this.chartObject.data = chartData;
	}
}
