import { Component, Input } from '@angular/core';
import { SiteWidget } from '../widget.interface';
import { TranslateService } from '@ngx-translate/core';
import { BattlinkSitePerformanceService } from '../battlink-site-performance.service';
import { ChartDataService } from 'src/app/shared/chart-data-widget/chart-data.service';

@Component({
	selector: 'app-battery-alert-summary',
	templateUrl: './battery-alert-summary.component.html',
})
export class BatteryAlertSummaryComponent {
	@Input() siteId: number;
	@Input() customerId: number;
	@Input() devices: any = {};
	@Input() tags: any;
	@Input() deviceTags: any;
	@Input() data: any;

	widgetConfig: SiteWidget = {
		data: [],
		tableData: {},
		scale: 1,
		config: {
			vAxis: { title: this.translateService.instant('battery_alerts.battery_count'), format: "0" },
			legend: "none",
			colors: [ 'red' ],
			isStacked: true
		},
		columnNames: [ "Type", "value"],
		type: "ColumnChart",
		title: this.translateService.instant('perf_analytic.battery_alert_summary'),
		tooltip: "",
		widgetId: "battery_alert_summary",
		dataRanges: [ "last_7_days", "last_30_days", "last_90_days", "yesterday", "last_week" ],
		selectedDateRange: "last_7_days",
		filterByTag: true,
		selectedTags: [],
		filterByType: false,
		selectedType: "all",
		hideWidget: true,
		stackColumn: "truck_type",
		groups: [],
		dropDownGroupList: [
			{
				name: 'date_range',
				label: '',
				options: [
					{label: 'Yesterday', value: 'yesterday'},
					{label: 'Last Week', value: 'last_week'},
					{label: 'Last 7 days', value: 'last_7_days'},
					{label: 'Last 30 days', value: 'last_30_days'},
					{label: 'Last 90 days', value: 'last_90_days'},
				],
			},
			{
				name: 'tag',
				label: 'Tags',
				options: [
					{label: 'All Tags', value: 'all'},
				]
			},
		],
		selectedDropDown: {date_range: 'yesterday', tag: 'all'},
	};
	noDataForCurrentFilters = false;

	alertList = {
		0 :{ key: 'WEEKLY_MISSED_EQ', title: this.translateService.instant('battery_alerts.weekly_missed_eq') },
		1 :{ key: 'HIGH_TEMPERATURE', title: this.translateService.instant('battery_alerts.high_temperature') },
		2 :{ key: 'POTENTIAL_WEAK_CELLS', title: this.translateService.instant('battery_alerts.potential_weak_cells') },
		3 :{ key: 'HIGH_EBU_USAGE', title: this.translateService.instant('battery_alerts.high_ebu_usage') },
		4 :{ key: 'WATER_LEVEL_LOW', title: this.translateService.instant('battery_alerts.water_level_low') },
		5 :{ key: 'MISSED_FINISH', title: this.translateService.instant('battery_alerts.missed_finish') },
		6 :{ key: 'DEEP_DISCHARGE', title: this.translateService.instant('battery_alerts.deep_discharge') },
	};

	constructor(
		private translateService: TranslateService,
		private battlinkSitePerformanceService: BattlinkSitePerformanceService,
		private chartDataService: ChartDataService
	) {}

	ngOnInit() {
		this.updateWidgetData();
		this.battlinkSitePerformanceService.updateTagsList(this.widgetConfig, this.tags);
	}

	onFilter(filter) {
		this.battlinkSitePerformanceService.updateFilter(this.widgetConfig, filter);
		this.updateWidgetData();
	}

	updateWidgetData() {
		const formattedWidgetData = [];
		const widgetData = this.data[this.widgetConfig.widgetId];
		this.noDataForCurrentFilters = false;

		if (!Object.keys(widgetData).length)
			return;

		this.widgetConfig.hideWidget = false;
		const selectedRangData = widgetData[this.widgetConfig.selectedDateRange];

		if (!Object.keys(selectedRangData).length)
			return this.noDataForCurrentFilters = true;

		for (const alertKey in selectedRangData) {
			const alertDevices = selectedRangData[alertKey];
			const alertTitle = this.alertList[alertKey].title;

			const filteredDevices = this.chartDataService.filterDevicesByTags(alertDevices, this.widgetConfig.selectedTags, this.deviceTags);
			const alertDevicesCount = Object.keys(filteredDevices).length;

			formattedWidgetData.push([alertTitle, alertDevicesCount]);
		}

		this.widgetConfig.data = formattedWidgetData;
	}
}
