import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DeviceService } from '../site-dashboard/device/device.service';
import * as moment from 'moment';
import { CommonService } from 'src/app/shared/services/common.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-ebu-and-charge-opportunity',
	templateUrl: './ebu-and-charge-opportunity.component.html',
	styleUrls: ['./ebu-and-charge-opportunity.component.css']
})
export class EbuAndChargeOpportunityComponent implements OnInit, OnChanges {

	@Input() device: any = {};
	@Input() studyInfo: any = {};

	deviceInstallationDate: any = null;
	maxDate = new Date(new Date().setDate(new Date().getDate() - 1));
	invalidDate = false;

	oneDayInMinutes = 24 * 60;
	randomMinValue = 99999;
	randomMaxValue = -11111;

	startDate: any = moment().subtract(1, 'months').toDate();
	endDate: any = moment().toDate();

	hasData = false;

	dailyDetailsData: any = [];

	data = {
		idle_times: [],
		daily_charging_opportunities: [],
		min_charging_opportunities: [],
		max_charging_opportunities: [],
		daily_ebu_times: [],
		max_ebu_times: [],
	}

	columnNames = [this.translate.instant('g.name'), this.translate.instant('g.value'), { role: 'style' }];

	ChartConfig:any = {
		legend: { position: 'none' },
	};
	minutesChartConfig:any = {
		...this.ChartConfig,
		vAxis: { title: this.translate.instant('g.minutes') },
	};

	keyToTranslateExport = {
		idle_times: [this.translate.instant('perf_analytic.idle_times'), this.translate.instant('g.hours'), this.translate.instant('g.minutes')],
		daily_charging_opportunities: [this.translate.instant('perf_analytic.average_daily_charge_opportunities'), this.translate.instant('time.day'), this.translate.instant('g.minutes')],
		min_charging_opportunities: [this.translate.instant('perf_analytic.maximum_daily_charge_opportunities'), this.translate.instant('time.day'), this.translate.instant('g.minutes')],
		max_charging_opportunities: [this.translate.instant('perf_analytic.minimum_daily_charge_opportunities'), this.translate.instant('time.day'), this.translate.instant('g.minutes')],
		daily_ebu_times: [this.translate.instant('perf_analytic.average_daily_ebu_usage'),this.translate.instant('time.day'),  this.translate.instant('g.value')],
		max_ebu_times: [this.translate.instant('perf_analytic.maximum_daily_ebu_usage'), this.translate.instant('time.day'), this.translate.instant('g.value')],
	};

	constructor(
		private deviceService : DeviceService,
		public common: CommonService,
		private translate: TranslateService
	) {}

	ngOnInit() {
		this.getDailyDetails();
	}

	ngOnChanges() {
		this.deviceInstallationDate = this.device.config_info.installation_date;
		if (this.deviceInstallationDate < this.studyInfo.start_study_time)
			this.startDate = new Date(this.studyInfo.start_study_time*1000);

		if (this.studyInfo?.end_study_time && moment(this.endDate).unix() > this.studyInfo.end_study_time) {
			const endStudyTime = new Date(this.studyInfo.end_study_time*1000);
			this.maxDate = endStudyTime;
			this.endDate = endStudyTime;
		}

		this.deviceInstallationDate = new Date(this.studyInfo.start_study_time*1000);
	}

	getDailyDetails() {
		this.hasData = false;
		const startDate = moment(this.startDate).unix();
		const endDate = moment(this.endDate).unix();
		if (startDate > endDate)
			return this.invalidDate = true;
		this.deviceService.getDailyDetails(this.device.mac_address, startDate, endDate, {}, this.studyInfo.id).subscribe((res: any) => {
			this.dailyDetailsData = res;
			this.initData(!!res.length);
			if(res.length) {
				this.calculateChartsData();
				this.hasData = true;
			}
		});
	}

	initData(hasData: boolean) {
		this.data = {
			idle_times: [],
			daily_charging_opportunities: [],
			min_charging_opportunities: [],
			max_charging_opportunities: [],
			daily_ebu_times: [],
			max_ebu_times: [],
		};

		if(!hasData)
			return;

		const hours = ['12AM','1AM','2AM','3AM','4AM','5AM','6AM','7AM','8AM','9AM','10AM','11AM','12PM','1PM','2PM','3PM','4PM','5PM','6PM','7PM','8PM','9PM','10PM','11PM'];

		for(let i = 0; i < 24; i++) {
			this.data.idle_times.push([hours[i], 0, '#76A7FA']);
		}

		const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

		for(let i = 0; i < 7; i++) {
			this.data.daily_charging_opportunities.push([days[i], 0, '#76A7FA']);
			this.data.min_charging_opportunities.push([days[i], 0, '#76A7FA']);
			this.data.max_charging_opportunities.push([days[i], 0, '#76A7FA']);
			this.data.daily_ebu_times.push([days[i], 0, '#76A7FA']);
			this.data.max_ebu_times.push([days[i], 0, '#76A7FA']);
		}
	}

	calculateChartsData() {
		const batteryCapacity = this.device.config_info.battery_capacity;
		const oneDay = 24 * 60 * 60;

		const dailyTotalChargeOpportunities = [];
		const totalInuseAh = [];
		const totalWorkingDays = [];

		for(let i = 0; i < this.dailyDetailsData.length; i++) {
			const dailyData = this.dailyDetailsData[i];
			const currentDayOfTheWeek = moment(dailyData.date*1000).utc().day();

			const idleTimes = dailyData.idle_times_distribution;
			for(let key in idleTimes) {
				this.data.idle_times[key][1] += idleTimes[key];
			}

			let dailyChargingOpportunities = oneDay - dailyData.inuse_duration;
			if(dailyChargingOpportunities < 0)
				dailyChargingOpportunities = 0;

			dailyTotalChargeOpportunities[currentDayOfTheWeek] ||= 0;
			dailyTotalChargeOpportunities[currentDayOfTheWeek] += dailyChargingOpportunities / 60;

			totalWorkingDays[currentDayOfTheWeek] ||= 0;
			totalWorkingDays[currentDayOfTheWeek]++;

			this.data.min_charging_opportunities[currentDayOfTheWeek][1] ||= this.randomMinValue;
			this.data.min_charging_opportunities[currentDayOfTheWeek][1] = Math.min(this.data.min_charging_opportunities[currentDayOfTheWeek][1], dailyChargingOpportunities / 60);

			this.data.max_charging_opportunities[currentDayOfTheWeek][1] ||= this.randomMaxValue;
			this.data.max_charging_opportunities[currentDayOfTheWeek][1] = Math.max(this.data.max_charging_opportunities[currentDayOfTheWeek][1], dailyChargingOpportunities / 60);

			totalInuseAh[currentDayOfTheWeek] ||= 0;
			totalInuseAh[currentDayOfTheWeek] += dailyData.inuse_events_as;

			this.data.max_ebu_times[currentDayOfTheWeek][1] = Math.max(this.data.max_ebu_times[currentDayOfTheWeek][1], dailyData.inuse_events_as / (3600 * 0.8 * batteryCapacity));
		}

		for(let key = 0; key < 7; key++) {
			this.data.daily_charging_opportunities[key][1] = (dailyTotalChargeOpportunities[key] || 0) / (totalWorkingDays[key] || 1);
			this.data.daily_ebu_times[key][1] = (totalInuseAh[key] || 0) / ((totalWorkingDays[key] || 1) * 3600 * 0.8 * batteryCapacity);
		}
	}

	exportDataToCsv() {
		const csvData = [];
		for(const key in this.data) {
			csvData.push([this.keyToTranslateExport[key][0]], [this.keyToTranslateExport[key][1], this.keyToTranslateExport[key][2]], ...this.data[key].map(item => [item[0], item[1]]));
			csvData.push([]);
		}
		new AngularCsv(csvData, this.translate.instant('perf_analytic.charge_opportunity_analysis'));
	}
}
