import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { UsersService } from 'src/app/users/users.service';
import { AdminService } from '../admin.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import * as moment from 'moment';

@Component({
	selector: 'app-app-version-setting',
	templateUrl: './app-version-setting.component.html',
	styleUrls: ['./app-version-setting.component.css']
})

export class AppVersionSettingComponent implements OnInit {
	currentUser: any;
	appVersionsInfo: any = {
		min_app_version: '',
		latest_app_version: '',
		st_min_app_version: '',
		st_latest_app_version: ''
	};

	constructor (
		private router: Router,
		private userService: UsersService,
		private adminService: AdminService,
		private notificationMessage: NotificationMessageService,
	) {}

	ngOnInit() {
		if (!this.userService.hasAccessFunction('dev_team'))
			return this.router.navigate(['/unauthorized']);

		this.currentUser = this.userService.getCurrentUser();
		return this.getCurrentAppVersionInfo();
	}

	saveAppVersionSettings() {
		if (this.validateAppVersionSettings()) {
			const data: any = {
				min_app_version: this.appVersionsInfo.min_app_version,
				latest_app_version: this.appVersionsInfo.latest_app_version,
				st_min_app_version: this.appVersionsInfo.st_min_app_version,
				st_latest_app_version: this.appVersionsInfo.st_latest_app_version,
				user_id: this.currentUser.id
			}
			this.adminService.saveAppVersionSettings(data).subscribe((res) => {
				this.appVersionsInfo = res;
				this.appVersionsInfo.created_at = moment.unix(this.appVersionsInfo.created_at ||0).format('M/D/YYYY, h:mm:ss A');
				return this.notificationMessage.setMessage('globalSuccessMsg');
			});
		}
	}

	getCurrentAppVersionInfo() {
		this.adminService.getCurrentAppVersionInfo().subscribe((res: any) => {
			this.appVersionsInfo = res;
			this.appVersionsInfo.created_at = this.appVersionsInfo?.created_at ? moment.unix(this.appVersionsInfo.created_at ||0).format('M/D/YYYY, h:mm:ss A') : '---';
			this.appVersionsInfo.user_name = this.appVersionsInfo?.user_name ? this.appVersionsInfo?.user_name : '---';
		});
	}

	validateAppVersionSettings() {
		const { min_app_version, latest_app_version, st_min_app_version, st_latest_app_version } = this.appVersionsInfo;
	
		if (!min_app_version || !latest_app_version || !st_min_app_version || !st_latest_app_version) {
			this.notificationMessage.setMessage('translate|g.field_is_required');
			return false;
		}
	
		const isValidVersion = (version: string): boolean => {
			return version.split('.').every(part => !isNaN(Number(part)) && part.trim() !== '');
		};
	
		if (!isValidVersion(min_app_version) || !isValidVersion(latest_app_version) || !isValidVersion(st_min_app_version) || !isValidVersion(st_latest_app_version)) {
			this.notificationMessage.setMessage('translate|g.invalid_input');
			return false;
		}
		return true;
	}
}