import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-daily-charger-utilization',
	templateUrl: './daily-charger-utilization.component.html',
	styleUrls: ['./daily-charger-utilization.component.css']
})
export class DailyChargerUtilizationComponent {
	@Input() data: any = {};
	@Input() devices: any = {};
	chart = {
		firstChartTitle: this.translate.instant('utilization.daily_charger_fleet_utilization'),
		secondChartTitle: this.translate.instant('utilization.daily_percent_charger_fleet_utilization'),
	}

	constructor(
		private translate: TranslateService
	) {}
}
