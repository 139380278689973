<div class="white-box main-black mt-4 p-4 border-radius">
		<div class="row">
		<div class="col">
			<h4>
				{{'batteries_customized_charts.tytle' | translate}}
			</h4>
		</div>
	</div>

	<!-- time filter -->
	<div class="row py-4">
		<div class="col-sm-6 col-md-7 d-inline-flex">
			<div class="input-group col-6 p-0 mr-3 d-inline-flex">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.start_date' | translate}}</span>
				</div>
				<input class="form-control"
					[ngClass]="{'text-danger': invalidDateRange}"
					[minDate]="deviceInstallationDate"
					[maxDate]="maxDate" placeholder="mm/dd/yyyy" name="startDB"
					[(ngModel)]="fromDate" (ngModelChange)="DatesChanged()"
					bsDatepicker #startDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"
				/>
				<div class="input-group-append pointer" (click)="startDB.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
			<div class="input-group col-6 p-0 mr-3 d-inline-flex">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.end_date' | translate}}</span>
				</div>
				<input class="form-control" placeholder="mm/dd/yyyy" name="endDB" [(ngModel)]="toDate"
					[minDate]="deviceInstallationDate" [maxDate]="maxDate" (ngModelChange)="DatesChanged()"
					bsDatepicker #endDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"
				/>
				<div class="input-group-append pointer" (click)="endDB.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
		</div>
	</div>

	<!-- daily usage chart -->
	<div *ngIf="dailyUsageChart?.data?.length" style="width:100%; height:400px; vertical-align: top;  display: inline-block; padding: 10px;">
		<column-chart
			[data]="dailyUsageChart.data"
			[config]="dailyUsageChart.config"
			elementId="dailyUsageChart"
			(onChartReady)="generateDownloadImage($event, 'daily-usage')"
		></column-chart>
	</div>

	<!-- daily Ebus chart -->
	<div *ngIf="dailyEbusChart?.data?.length" style="width:100%; height:400px; vertical-align: top;  display: inline-block; padding: 10px;">
		<column-chart
			[data]="dailyEbusChart.data"
			[config]="dailyEbusChart.config"
			elementId="dailyEbusChart"
			(onChartReady)="generateDownloadImage($event, 'daily-ebus')"
		></column-chart>
	</div>

	<!-- daily Ahrs chart -->
	<div style="margin-top: 15px" *ngIf="dailyAhrsChart?.data?.length">
		<div class="margin-left-xlg">
			<input type="checkbox" id="available_ahr" class="form-control" (change)="checkBoxChanged();" [(ngModel)]="availableAhrsCheckBox">
			<label for="available_ahr">{{"batteries_customized_charts.available_ahr" | translate}}</label>
		</div>
		<div style="width:100%; height:400px; vertical-align: top;  display: inline-block; padding: 10px;">
			<column-chart
				[data]="dailyAhrsChart.data"
				[config]="dailyAhrsChart.config"
				elementId="dailyAhrsChart"
				(onChartReady)="generateDownloadImage($event, 'daily-ahrs')"
			></column-chart>
		</div>
	</div>

	<!-- soc chart -->
	<div  *ngIf="socChart?.data?.length" style="width:100%; height:400px; vertical-align: top;  display: inline-block; padding: 10px;">
		<line-chart
			[data]="socChart.data"
			[config]="socChart.config"
			elementId="socChart"
			(onChartReady)="generateDownloadImage($event, 'soc')"
		></line-chart>
	</div>

	<!-- max temperature chart -->
	<div style="margin-top: 15px" *ngIf="maxTempChart?.data?.length">
		<div class="margin-left-xlg">
			<input type="checkbox" class="form-control" (change)="checkBoxChanged();" [(ngModel)]="maxTempCheckBox">
			<label>{{"batteries_customized_charts.max_temperature_limit" | translate}}</label>
		</div>
		<div style="width:100%; height:400px; vertical-align: top;  display: inline-block; padding: 10px;">
			<line-chart
				[data]="maxTempChart.data"
				[config]="maxTempChart.config"
				elementId="maxTempChart"
				(onChartReady)="generateDownloadImage($event, 'max-temperature')"
			></line-chart>
		</div>
	</div>

	<!-- end temperature chart ==> @@ Based on with AMR Discussion No need End Temperature Chart For Now @@ --> 

	<!-- <div style="margin-top: 15px" *ngIf="endTempChart?.data?.length">
		<div class="margin-left-xlg">
			<input type="checkbox" class="form-control" (change)="checkBoxChanged();" [(ngModel)]="endTempCheckBox">
			<label>{{"batteries_customized_charts.max_temperature_limit" | translate}}</label>
		</div>
		<div style="width:100%; height:400px; vertical-align: top;  display: inline-block; padding: 10px;">
			<line-chart
				[data]="endTempChart.data"
				[config]="endTempChart.config"
				elementId="endTempChart"
				(onChartReady)="generateDownloadImage($event, 'end-temperature')"
			></line-chart>
		</div>
	</div>

	<!-- end voltage chart -->
	<div *ngIf="endVoltageChart?.data?.length" style="margin-top: 15px">
		<div class="margin-left-xlg">
			<label>
				<input type="checkbox" name="Idle" value="Idle" (change)="updateSelectedType($event);checkBoxChanged()" /> {{"event.idle" | translate}}  &nbsp;
			</label>
			<label>
				<input type="checkbox" name="Inuse" value="Inuse" (change)="updateSelectedType($event);checkBoxChanged()" /> {{"event.inuse" | translate}} &nbsp;
			</label>
			<label>
				<input type="checkbox" name="Charge" value="Charge" (change)="updateSelectedType($event);checkBoxChanged()" [checked]="true" /> {{"event.charge" | translate}} &nbsp;
			</label>
		</div>
		<div class="margin-left-xlg">
			<span>
				<input type="checkbox" id="show-dischargelimit" class="form-control" [(ngModel)]="inuseLimitCheckBox" (change)="checkBoxChanged()">
				<label for="show-dischargelimit">{{"batteries_customized_charts.deep_discharge_limit" | translate}}</label>
			</span>
			<span *ngIf="showPotentialCheckBox">
				<input type="checkbox" id="show-potential" class="form-control" [(ngModel)]="potentialCheckBox" (change)="checkBoxChanged()">
				<label for="show-potential">{{"batteries_customized_charts.potential_weak_cells_limit" | translate}}</label>
			</span>
			<span *ngIf="selectedTypes.indexOf('Charge')>-1">
				<input type="checkbox" id="show-compensated" class="form-control" [(ngModel)]="isCompensatedVoltageCheckBox" (change)="checkBoxChanged()">
				<label for="show-compensated">{{"batteries_customized_charts.show_voltages_as_temperature_compensated" | translate}}</label>
			</span>
		</div>
		<div style="width:100%; height:400px; vertical-align: top;  display: inline-block; padding: 10px;">
			<line-chart
				[data]="endVoltageChart.data"
				[config]="endVoltageChart.config"
				elementId="endVoltageChart"
				(onChartReady)="generateDownloadImage($event, 'end-voltage')"
			></line-chart>
		</div>
	</div>

	<div class="row py-4">
		<div
			*ngIf=" !dailyUsageChart?.data?.length &&
				!dailyEbusChart?.data?.length &&
				!dailyAhrsChart?.data?.length &&
				!socChart?.data?.length &&
				!maxTempChart?.data?.length &&
				!endTempChart?.data?.length &&
				!endVoltageChart?.data?.length"
			class="col-md-12">
			<div class="alert alert-warning" role="alert">
				{{"g.no_data_found" | translate}}
			</div>
		</div>
	</div>
</div>
