declare var require: any
import { TranslateLoader } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { DomainsService } from "./shared/services/domains.service";

export class TranslateFileLoader implements TranslateLoader {
		_currentLanguage: string;
		_currentData: any;
		constructor(private domainsService: DomainsService) {}

		/**
		 * Gets the translations from JSON file
		 * @param lang
		 * @returns {any}
		 */
		public getTranslation(lang: string): Observable<any> {
			return Observable.create(observer => {
				if(this._currentLanguage == lang){
					observer.next(this._currentData);
					observer.complete();
					return observer;
				}

				let data = require(`../../../Common/translation/${lang}.json`);
				if(this.domainsService.isChargLink)
					data = this.margeTranslationData(data, data.chargeLink);

				if(this.domainsService.isBattlink)
					data = this.margeTranslationData(data, data.battlink);

				this._currentData = data;
				this._currentLanguage = lang;
				observer.next(this._currentData);
				observer.complete();
			});
		}

		private margeTranslationData ( firstObj, secondObj ) {
			if (typeof firstObj !== "object" || typeof secondObj !== "object" || firstObj === null || secondObj === null)
				return secondObj;
			const merged = { ...firstObj };

			for (const key in secondObj) {
				if (secondObj.hasOwnProperty(key))
					merged[key] = this.margeTranslationData(firstObj[key], secondObj[key]);
			}

			return merged;
		}
}
