import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-charger-utilization-score',
	templateUrl: './charger-utilization-score.component.html',
	styleUrls: ['./charger-utilization-score.component.css']
})
export class ChargerUtilizationScoreComponent {
	@Input() devices: any = {};
	@Input() utilizationScoreData: any = {};

	utilizationScoreSelectedData = {};

	periods = [
		{ value: 'last_week', label: this.translate.instant('durations.last_week') },
		{ value: 'last_7_days', label: this.translate.instant('durations.last_7_days') },
		{ value: 'last_30_days', label: this.translate.instant('durations.last_30_days') },
		{ value: 'last_90_days', label: this.translate.instant('durations.last_90_days') },
		{ value: 'year_to_date', label: this.translate.instant('durations.year_to_date') },
		{ value: 'last_year', label: this.translate.instant('durations.last_year') },
		{ value: 'all_years', label: this.translate.instant('durations.all_years') },
	];
	selectedPeriod = this.periods[0].value;

	constructor(private translate: TranslateService, private cdr: ChangeDetectorRef) { }

	ngOnInit() {
		this.updateSelectedData();
	}

	onSelectionChanged(event) {
		switch (event.filter) {
			case 'period':
				this.selectedPeriod = event.value;
				break;
			default:
				break;
		}

		this.updateSelectedData();
	}

	updateSelectedData() {
		this.utilizationScoreSelectedData = this.utilizationScoreData[this.selectedPeriod];
		this.cdr.markForCheck();
	}
}
