<div id="tab-content" class="settings">
	<form class="form-auth">
		<div class="container-fluid no-gutter">
			<div class="boxs3">
				<fieldset class="margin-bottom-xlg">
					<legend class="margin-bottom-lg">{{"site_settings.temperature" | translate}}</legend>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">{{"site_settings.temperature_limit" | translate}}</label>
						<div class="col-md-7">
							<label><input type="checkbox" name="override_temperature_limit" [(ngModel)]="alert.override_temperature_limit" class="form-control" /><span> {{'site_settings.override_temperature_limit' | translate}}</span></label>
						</div>
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg"></label>
						<div class="col-md-7">
							<div class='input-group'>
								<input type="number" min="90" max="160" step="0.1" class="form-control" name="temperature_limit" [(ngModel)]="alert.temperature_limit"/>
								<span class="input-group-addon"><span>&deg;F</span></span>
							</div>
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">{{"site_settings.high_temperature_limit_days" | translate}}</label>
						<div class="col-md-7">
							<select class="form-control" name="high_temperature_limit_days" [(ngModel)]="alert.high_temperature_limit_days">
								<option *ngFor="let n of generateRange(1,4)" [value]="n">{{n}}</option>
							</select>
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">{{"site_settings.cold_Temperature_limit" | translate}}</label>
						<div class="col-md-7">
							<div class='input-group'>
								<input type="number" min="0" max="80" step="0.1" class="form-control" name="cold_temperature_limit" [(ngModel)]="alert.cold_temperature_limit"/>
								<span class="input-group-addon"><span>&deg;F</span></span>
							</div>
						</div>
					</div>
				</fieldset>

				<fieldset class="margin-bottom-xlg">
					<legend class="margin-bottom-lg">{{"site_settings.ebu_over_usage" | translate}}</legend>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">{{"site_settings.conventional_ebu_daily_limit" | translate}}</label>
						<div class="col-md-7">
							<input type="number" min="0.25" max="2" step="0.01" class="form-control" name="conventional_ebu_limit" [(ngModel)]="alert.conventional_ebu_limit" />
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">{{"site_settings.opportunity_ebu_daily_limit" | translate}}</label>
						<div class="col-md-7">
							<input type="number" min="0.25" max="2" step="0.01" class="form-control" name="opportunity_ebu_limit" [(ngModel)]="alert.opportunity_ebu_limit" />
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">{{"site_settings.fast_ebu_daily_limit" | translate}}</label>
						<div class="col-md-7">
							<input type="number" min="0.25" max="2" step="0.01" class="form-control" name="fast_ebu_limit" [(ngModel)]="alert.fast_ebu_limit" />
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">{{"site_settings.lithium_ebu_daily_limit" | translate}}</label>
						<div class="col-md-7">
							<input type="number" min="0.25" max="2" step="0.01" class="form-control" name="lithium_ebu_limit" [(ngModel)]="alert.lithium_ebu_limit" />
						</div>
					</div>
				</fieldset>

				<fieldset class="margin-bottom-xlg">
					<legend class="margin-bottom-lg">{{"site_settings.deep_usage" | translate}}</legend>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">{{"site_settings.potential_weak_cells_limit" | translate}}</label>
						<div class="col-md-7">
							<div class='input-group'>
								<input type="number" min="0" max="10" step="0.01" class="form-control" name="deep_use_limit" [(ngModel)]="alert.deep_use_limit" />
								<span class="input-group-addon"><span>{{"site_settings.vpc" | translate}}</span></span>
							</div>
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">{{"site_settings.deep_discharge_limit" | translate}}</label>
						<div class="col-md-7">
							<div class='input-group'>
								<input type="number" min="0" max="10" step="0.01" class="form-control" name="deep_discharge_limit" [(ngModel)]="alert.deep_discharge_limit" />
								<span class="input-group-addon"><span>{{"site_settings.vpc" | translate}}</span></span>
							</div>
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">{{"site_settings.potential_weak_cells_limit_days" | translate}}</label>
						<div class="col-md-7">
							<select class="form-control" name="potential_weak_cells_limit_days" [(ngModel)]="alert.potential_weak_cells_limit_days">
								<option *ngFor="let n of generateRange(1,4)" [value]="n">{{n}}</option>
							</select>
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">{{"site_settings.deep_discharge_limit_days" | translate}}</label>
						<div class="col-md-7">
							<select class="form-control" name="deep_discharge_limit_days" [(ngModel)]="alert.deep_discharge_limit_days">
								<option *ngFor="let n of generateRange(1,4)" [value]="n">{{n}}</option>
							</select>
						</div>
					</div>
				</fieldset>

				<fieldset class="margin-bottom-xlg">
					<legend class="margin-bottom-lg">{{"site_settings.water_level" | translate}}</legend>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">{{"site_settings.lowWater_level_delay_days" | translate}}</label>
						<div class="col-md-7">
							<select class="form-control" name="low_water_level_days" [(ngModel)]="alert.low_water_level_days">
								<option *ngFor="let n of generateRange(1,7)" [value]="n">{{n}}</option>
							</select>
						</div>
					</div>
				</fieldset>

				<!-- check with AMR regarding act_intelligent_type flag not exist in IoTah project-->
				<fieldset class="margin-bottom-xlg" *ngIf="currentSite.act_intelligent_type != 0">
					<legend class="margin-bottom-lg">{{"site_settings.missed_connection" | translate}}</legend>

					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">{{"site_settings.total_missed_connection_limit_hours" | translate}}</label>
						<div class="col-md-7">
							<input type="number" min="1" max="168" step="1" class="form-control" name="missed_connection_limit_hours" [(ngModel)]="alert.missed_connection_limit_hours" />
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">{{"site_settings.average_daily_idle_times" | translate}}</label>
						<div class="col-md-7">
							<input type="number" min="1" max="1440" step="1" class="form-control" name="idle_times_limit" [(ngModel)]="alert.idle_times_limit" />
						</div>
					</div>
				</fieldset>
				<div class="form-buttons bvf">
					<button class="btn btn-info" (click)="updateAlertsSettings()">{{"g.save" | translate}}</button>
				</div>
			</div>
		</div>
	</form>
</div>

