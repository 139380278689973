import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { RtRequestHistoryService } from './rt-request-history.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { ColumnsConfig, TableConfig, TableData} from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';
import { DatePipe } from '@angular/common';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
	selector: 'app-rt-requests-history',
	templateUrl: './rt-requests-history.component.html',
	styleUrls: ['./rt-requests-history.component.css']
})
export class RtRequestsHistoryComponent implements OnInit, AfterViewInit {
	// table
	tableData: TableData[] = [];

	tableConfig: TableConfig = {
		hasExport: true,
		hasPagination: true,
		draggable: true,
		pageSize: 50,
		tableId: "tr-request-history",
		fileName:  this.translateService.instant('nav.rt_requests_history'),
	};

	isPositiveTimeZone = new Date().getTimezoneOffset() > 0;

	columnConfig: ColumnsConfig[] = [
		{ key: 'mac_address', name: this.translateService.instant('g.mac_address'), type: "link" },
		{ key: 'username', name: this.translateService.instant('login_register.user_name'), type: "link" },
		{ key: 'source_fmt', name: this.translateService.instant('rt_history.source'), type: "string" },
		{ key: 'status_fmt', name: this.translateService.instant('rt_history.status'), type: "string" },
		{ key: 'created_at', name: this.translateService.instant('rt_history.request_time') + ` (UTC ${this.isPositiveTimeZone ? '' : '+'}${(new Date().getTimezoneOffset() * -1) / 60})`, type: 'date' },
		{ key: 'start_id', name: this.translateService.instant('rt_history.start_id'), type: "number", noValueString: '-' },
		{ key: 'end_id', name: this.translateService.instant('rt_history.end_id'), type: "number", noValueString: '-'},
		{ key: 'start_time', name: this.translateService.instant('rt_history.start_time'), type: 'date', hasTooltip: this.translateService.instant('force_read_rt.time_shown_device_time') },
		{ key: 'end_time', name: this.translateService.instant('rt_history.end_time'), type: 'date', hasTooltip: this.translateService.instant('force_read_rt.time_shown_device_time') },
		{ key: 'action', name: this.translateService.instant('g.actions'), type: 'dropdown'}
	];

	periods = [];
	timeRequest = {};
	hasTimeRequest = false;
	rowType = 0;
	reason = null;

	deviceID: string = '0';

	hasCancelList = [this.rtRequestHistoryService.statuses.processing, this.rtRequestHistoryService.statuses.queued, this.rtRequestHistoryService.statuses.time_processing];
	hasViewPeriods = [...this.hasCancelList, this.rtRequestHistoryService.statuses.failed, this.rtRequestHistoryService.statuses.cancel, this.rtRequestHistoryService.statuses, this.rtRequestHistoryService.statuses.processed];

	@ViewChild('infoModal') infoModal: ModalComponent;
	@ViewChild('confirmCancelRequest') confirmCancelRequest: ModalComponent;
	interval: any;

	constructor(
		private route: ActivatedRoute,
		public rtRequestHistoryService: RtRequestHistoryService,
		private translateService: TranslateService,
		private commonService: CommonService
	) {}

	ngAfterViewInit(): void {
		this.infoModal.onClose.subscribe(() => {
			this.periods = [];
		});

		this.confirmCancelRequest.onClose.subscribe((res) => {
			if(res[0])
				this.cancelRequest(res[1]);
			this.confirmCancelRequest.passData = null;
		});
	}

	ngOnInit() {
		if(this.commonService.isDevelopmentDomain())
			this.tableConfig.pageSize = 5;

		this.route.params.subscribe(params => {
			this.deviceID = params['deviceId'];
			const filters = {};
			if(this.deviceID && this.deviceID !== '0') {
				filters['mac_address'] = this.deviceID;
			}
			this.rtRequestHistoryService.getRtRequestsHistory(filters).subscribe((data) => {
				if(data.length > 0) {
					this.initData(data);
					this.doAutoRefresh();
				}
			});
		});
	}

	initData(data) {
		let datePipe: DatePipe = new DatePipe("en-US");
		const tableData: TableData[] = [];
		for(const row of data) {
			const optionList = [];
			if (this.hasCancelList.includes(row.status) && row.source == this.rtRequestHistoryService.sources.idRequest)
				optionList.push({text: this.translateService.instant('g.cancel'), action: (data: any)=> {this.showConfirmCancelRequest(data.row)}});

			if (this.hasViewPeriods.includes(row.status))
				optionList.push({text: this.translateService.instant('g.details'), action: (data: any)=> {this.viewPeriods(data.row)}});

			let rewData: TableData  = {
				row,
				mac_address: {value: row.mac_address, link: ["/", row.customer_id, row.site_id, row.mac_address, 'performance']},
				username: {value: row.username, link: row.user_id ? ["/", "user", "edit", row.user_id] : null},
				source_fmt: {value: this.translateService.instant('rt_history.' + this.rtRequestHistoryService.sources[row.source])},
				status_fmt: {value: this.translateService.instant('rt_history.' + this.rtRequestHistoryService.statuses[row.status])},
				created_at: {value:  datePipe.transform(row.created_at, 'MM/dd/yyyy HH:mm:ss')},
				start_id: {value: [undefined, null].includes(row.start_id) ? null : row.start_id},
				end_id: {value: [undefined, null].includes(row.end_id) ? null : row.end_id},
				start_time: {value: datePipe.transform(row.start_time, 'MM/dd/yyyy HH:mm:ss', 'UTC') || '-'},
				end_time: {value: datePipe.transform(row.end_time, 'MM/dd/yyyy HH:mm:ss', 'UTC') || '-'},
				action: {value: null, options: optionList}
			};

			tableData.push(rewData);
		}

		this.tableData = tableData;
	}

	showConfirmCancelRequest(row: any) {
		this.confirmCancelRequest.passData = row;
		this.confirmCancelRequest.show();
	}

	cancelRequest(row: any) {
		const data = {
			id: row.id,
			status: this.rtRequestHistoryService.statuses.cancel
		};
		this.rtRequestHistoryService.updateRequestHistory(data).subscribe(() => {
			row.status = this.rtRequestHistoryService.statuses.cancel;
			row.status_fmt = this.translateService.instant('rt_history.' + this.rtRequestHistoryService.statuses[row.status]);
			row.cancel.icon = null;
		});
	}

	viewPeriods(row: any) {
		const periods = row.extra_info?.periods || [];
		for(const period of periods) {
			if(period.start == period.end) {
				period.percentage = 100;
			} else {
				period.percentage = Math.round((period.lastId - period.start) / (period.end - period.start) * 100);
				if(period.percentage > 100) {
					period.percentage = 100;
				}
			}
		}
		const timeRequest = row.extra_info?.timeRequest || {};

		this.hasTimeRequest = Object.keys(timeRequest).length > 0;
		this.rowType = row.source;
		this.timeRequest = timeRequest;
		this.periods = periods;
		this.reason = row.extra_info?.reason || null;

		this.infoModal.show();
	}

	doAutoRefresh() {
		// we need to check if any records are in processing state or queued state we will add an interval to refresh the data every 15 seconds, and we will clear the interval when all records are processed
		const processingRecords = this.tableData.filter((record: any) => this.hasCancelList.includes(record.row.status));
		if (processingRecords.length > 0) {
			this.interval = setInterval(() => {
				this.rtRequestHistoryService.getRtRequestsHistory({ mac_address: this.deviceID }).subscribe((data) => {
					this.tableData = [];
					this.initData(data);
					const processingRecords = this.tableData.filter((record: any) => this.hasCancelList.includes(record.row.status));
					if (processingRecords.length === 0) {
						clearInterval(this.interval);
					}
				});
			}, 15000);
		}
	}
}
