import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { SideMenuService } from '../side-menu/side-menu.service';
import { GoogleMapService } from '../google-map/google-map.service';
import { MapsAPILoader } from '@agm/core';
import { SitesService } from '../../sites/sites.service'
import * as mapboxgl from 'mapbox-gl';
import { MapComponent } from 'ngx-mapbox-gl';
import ngeohash from 'ngeohash';
import { nanoid } from 'nanoid';

declare const google;

@Component({
	selector: 'app-mapbox',
	templateUrl: './mapbox.component.html',
	styleUrls: ['./mapbox.component.css']
})
export class MapboxComponent implements OnInit, OnChanges {
	@ViewChild(MapComponent) mapComponent: MapComponent;
	@Input() latitude: number;
	@Input() longitude: number;
	@Input() zoom: number[] = [3];
	@Input() zoomIn: boolean = false;
	@Input() showPopup = true;
	@Input() dots;
	@Input() dotsType;
	@Input() clickEnabled:boolean = false;
	@Output() onAddressChanged = new EventEmitter<Object>();

	center;
	map: Object = {};
	mapInstance: any = null;

	constructor(
		public sideMenuService: SideMenuService,
		private googleMapService: GoogleMapService,
		private mapsAPILoader: MapsAPILoader,
		private sitesService: SitesService,
	) {
	}

	ngOnInit() {
		this.center = [this.longitude, this.latitude];

		this.mapsAPILoader.load().then((goog) => {
			this.setCurrentPosition({
				lngLat: {
					lng: this.center[0],
					lat: this.center[1]
				}
			})

		});
		// this.mapRef.resize.emit();
		// this.map.mapEvents.resize.emit();
	}

	ngOnChanges(changes){
		if(changes.longitude && changes.latitude){
			this.center = [changes.longitude.currentValue || this.sitesService.defaultMapLocation.longitude, changes.latitude.currentValue || this.sitesService.defaultMapLocation.latitude];
		}
		if(this.dots && this.dots.length){
			for (let dot of this.dots) {
				dot.opened = false;
			}
		}
		if (this.mapInstance)
			this.fitMapToBounds(this.mapInstance);
		// if(changes.dots)
		// 	this.sideMenuService.reDrawMap();
	}

	setCurrentPosition(event, updateDots=true) {
		if(!this.clickEnabled) {
			return;
		}
		let pinLocation = event.lngLat||null;
		if(pinLocation) {
			let lat = pinLocation.lat,
				lng = pinLocation.lng;
			this.map['lat'] = lat;
			this.map['lng'] = lng;
			if(updateDots) {
				this.dots[0].latitude = lat;
				this.dots[0].longitude = lng;
			}
			if(this.zoomIn) {
				this.zoom = [50];
			}
			this.googleMapService.getAddressName({lat, lng}).subscribe(
				data => {
					if(!data['features'].length) {
						this.map['address'] = '';
						this.map['country'] = '';
						this.map['state'] = '';
						this.onAddressChanged.emit(this.map);
						return;
					}
					data = data['features'][0]['properties'];
					this.map['address'] = data['full_address'];
					this.map['country'] = data['context']['country']['name'];
					this.map['state'] = data['context']['region']['name'];
					this.onAddressChanged.emit(this.map);
				}
			);
		}
	}

	onGeocoderResult(event) {
		this.zoom=[13];
		this.setCurrentPosition({
			lngLat: {
				lng: event.result.geometry.coordinates[0],
				lat: event.result.geometry.coordinates[1]
			}
		})
	}

	ngAfterViewInit() {
		this.mapComponent.mapLoad.subscribe(mapInstance => {
			this.mapInstance = mapInstance;
			this.fitMapToBounds(mapInstance);
		});
	}

	fitMapToBounds(mapInstance: mapboxgl.Map) {
		if (!mapInstance)
			return;

		if (this.dots && this.dots.length) {
			const bounds = new mapboxgl.LngLatBounds();

			this.dots.forEach(dot => {
				bounds.extend([dot.longitude, dot.latitude]);
			});

			mapInstance.fitBounds(bounds, {
				padding: { top: 50, bottom: 50, left: 50, right: 50 }
			});
		}
	}

	// Capture the MapBox instance when the map is loaded
	onMapLoad(mapInstance: mapboxgl.Map) {
		this.mapInstance = mapInstance;
		// Attach the zoomEnd event listener
		mapInstance.on('zoomend', () => {
			const newZoomLevel = mapInstance.getZoom(); // Get the new zoom level

			// Perform your specific action based on the zoom level
			const precision = this.getGeoHashPrecision(newZoomLevel);
			this.groupDots(precision);
		});
	}

	getGeoHashPrecision(zoom: number) {
		// Map the zoom level (0 - 22) to a GeoHash precision (1 - 12)
		// This is an example, adjust the ranges as needed
		if (zoom <= 5)
			return 2; // Large areas for low zoom

		if (zoom <= 8)
			return 4; // Medium areas for mid zoom

		if (zoom <= 12)
			return 6; // Smaller areas for higher zoom

		if (zoom <= 16)
			return 8; // More detail for high zoom

		return 10; // Maximum detail for very high zoom
	}

	groupDots(precision: number) {
		const existGeoHashes = [];
		const geoHashesIndex: any = {};
		let index = 0;

		for (const dot of this.dots) {
			if (!dot.group) {
				index++;
				continue ;
			}

			dot.geoHash = ngeohash.encode(dot.latitude, dot.longitude, precision);
			dot.data = [];

			if (existGeoHashes.includes(dot.geoHash)) {
				dot.draw = false;
				this.dots[geoHashesIndex[dot.geoHash]].data.push(dot);
			} else {
				existGeoHashes.push(dot.geoHash);
				geoHashesIndex[dot.geoHash] = index;
				dot.data.push(dot);
				dot.draw = true;
			}

			index++;
		}
	}
}
