import { Component, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { UsersService } from 'src/app/users/users.service';
import { NotificationMessageService } from '../notification-message/notification-message.service';

@Component({
	selector: 'sct-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit, OnChanges {

	changesStackHtml: string = '';
	invalidFields: any = {};
	@Input() data: any;
	@Input() name: string;
	@Input() permissions: any;
	@Input() fields: any[];
	@Input() showSubmitButton: boolean = true;
	@Input() showCancelButton: boolean = false;
	@Input() changesStack: any[];
	@Input() validateDirty: boolean = true; // To only validate dirty fields
	@Input() highlightEmpty: boolean = false; // Highlight fields that are missing from the "data" object
	@Input() permissionMapperKey: string;
	@Output() formSubmitted: EventEmitter<any> = new EventEmitter();
	@Output() formCancelled: EventEmitter<void> = new EventEmitter();
	@Output() dropdownChanged = new EventEmitter<{field: string, value: any, data: any}>();
	@Output() onBeforeSubmit: EventEmitter<any> = new EventEmitter();
	@Output() removeChangeStack: EventEmitter<any> = new EventEmitter();
	@ViewChild("formRef") formRef:NgForm;
	@ViewChild("changesStackTooltip", { static: true }) changesStackTooltip;

	constructor(
    private renderer: Renderer2,
		private translate: TranslateService,
		private notificationMessage: NotificationMessageService,
		private usersService: UsersService
	) { }

	ngOnInit() {
	}

	ngOnChanges() {
		this.invalidFields = {};
	}

	validateFields(formValues) {
		let invalidFields = {};

		for(let field of this.fields) {
			let fieldName = this.isArray(field.name) ? field.name.join('.') : field.name;
			if(this.validateDirty && !(formValues[fieldName] && formValues[fieldName].dirty) || this.usersService.checkFieldPermissions(field.permission, this.permissions, this.permissionMapperKey) != 'write')
				continue;

			let rules = field.validation || [];
			let value = this.isArray(field.name) ? this.data[field.name[0]][field.name[1]] : this.data[field.name];
			if(field.type == 'date') {
				value = moment(value, 'MM/DD/YYYY').startOf('day').unix();
			} else if(field.type == 'boolean') {
				value = +value;
			}

			if(rules.dependsOn && rules.rules?.length) {
				if(!this.fields.find(item => item.name == rules.dependsOn)) {
					invalidFields[fieldName] = 'The depend on Field must be exists';
					continue;
				}

				let passRules = false;
				for(const rule of rules.rules) {
					if(this.isArray(rule.value) && rule.value.length > 1 && 
					(
						(rule.in && rule.value.includes(this.data[rules.dependsOn])) || 
						(rules.dependsOn >= rule.value[0] && rules.dependsOn <= rule.value[1])
					)) {
						rules = rule.validation;
						passRules = true;
						break;
					} else if(this.data[rules.dependsOn] == rule.value) {
						rules = rule.validation;
						passRules = true;
						break;
					}
				}

				if(!passRules) {
					invalidFields[fieldName] = 'The depend on Field must be exists 1';
					continue;
				}
			}

			//Validate only if parent field is enabled
			if(field.controlledBy && !this.data[field.controlledBy])
				continue;

			rules.forEach(function(rule) {
				var errMsg = this.dataValidator(rule.type, {...rule, fieldType: field.type, error_message: field.error_message}, value, field.label);
				if(errMsg) {
					if(this.isArray(field.name)) {
						invalidFields[field.name[0]] = invalidFields[field.name[0]] || [];
						invalidFields[field.name[0]][field.name[1]] = errMsg;
					} else {
						invalidFields[field.name] = errMsg;
					}
				}
			}, this);
		}
		return invalidFields;
	}

	dataValidator(type, options, value, field) {
		let errMsg;
		switch(type) {
			case 'integer':
				if(value == parseInt(value)) {

					value = parseInt(value);

					var validMin = true, validMax = true, validStep = true;

					if(typeof(options.min) !== "undefined" && value < options.min)
						validMin = false;
					if(typeof(options.max) !== "undefined" && value > options.max)
						validMax = false;
					if(typeof(options.step) !== "undefined" && (value % options.step != 0))
						validStep = false;
					if(!validMin || !validMax || !validStep) {
						if(options.fieldType == 'date') {
							options.min = moment(options.min*1000).format('MM/DD/YYYY');
							options.max = moment(options.max*1000).format('MM/DD/YYYY');

						}
						errMsg = options.error_message || this.translate.instant(field) + ' should be between ' + options.min + ' and ' + options.max;
					}
				} else {
					errMsg = this.translate.instant(field) + ' is invalid';
				}
			break;

			case 'float':
				if(value == parseFloat(value)) {

					value = parseFloat(value);

					var validMin = true, validMax = true, validStep = true;

					if(typeof(options.min) !== "undefined" && value < options.min)
						validMin = false;
					if(typeof(options.max) !== "undefined" && value > options.max)
						validMax = false;
					if(typeof(options.step) !== "undefined" && (value % options.step != 0))
						validStep = false;

					if(!validMin || !validMax || !validStep) {
						errMsg = options.error_message || this.translate.instant(field) + ' should be between ' + options.min + ' and ' + options.max;
					}
				} else {
					errMsg = this.translate.instant(field) + ' is invalid';
				}
			break;

			case 'boolean':
				if(typeof(value) !== "boolean") {
					errMsg = this.translate.instant(field) + ' is invalid';
				}
			break;

			case 'string':
				if(options.allowEmpty && this.isEmpty(value)){
					break;
				}

				if(typeof(value) === "string") {
					var validMin = true, validMax = true;
					if(typeof(options.min) !== "undefined" && value.length < options.min) {
						validMin = false;
					}
					if(typeof(options.max) !== "undefined" && value.length > options.max) {
						validMax = false;
					}

					if(!validMin && !validMax) {
						errMsg = this.translate.instant(field) + ' should be ' + options.min + '-' + options.max + ' characters long';
					} else if(!validMin) {
						errMsg = this.translate.instant(field) + ' should be at least ' + options.min + ' characters long';
					} else if(!validMax) {
						errMsg = this.translate.instant(field) + ' should be ' + options.max + ' characters long at most';
					}
				}
			break;

			case 'hexString':
				if(options.allowEmpty && (value === null || value == undefined || value == '')) {
					break;
				}

				if(typeof(value) === "string") {
					value = value.trim().replace(/:/g, "");
					if(/^[a-fA-F0-9]{12}$/.test(value)) {
						break;
					};
				}
				errMsg = this.translate.instant(field) + ' is invalid';
			break;

			case 'hex':
				if(this.isEmpty(value)){
					if(options.allowEmpty) {
						break;
					}
					errMsg = this.translate.instant(field) + ' is invalid';
				}
				var regex = /\b[0-9A-Fa-f]+\b/gi;
				var validMin = true, validMax = true;
				if(typeof(options.min) !== "undefined" && value.length < options.min)
					validMin = false;
				if(typeof(options.max) !== "undefined" && value.length > options.max)
					validMax = false;

				if(validMin && validMax && regex.test(value))
					break;

				if(!validMin && !validMax) {
					errMsg = this.translate.instant(field) + ' should be ' + options.min + '-' + options.max + ' characters long';
				} else if(!validMin) {
					errMsg = this.translate.instant(field) + ' should be at least ' + options.min + ' characters long';
				} else if(!validMax) {
					errMsg = this.translate.instant(field) + ' should be ' + options.max + ' characters long at most';
				} else {
					errMsg = this.translate.instant(field) + ' is invalid';
				}
			break;

			case 'password':
				if(this.isEmpty(value)){
					if(options.allowEmpty) {
						break;
					}
					errMsg = this.translate.instant(field) + ' is required';
				}
				// if(typeof(value) === "string") {
				// 	// isValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(value); // one upper, one lower, one digit, at least 8 characters.
				// 	isValid = value.length > 4;
				// }
				if(typeof(value) === "string" && value.length > 4 && value.trim().length != 0) {
					break;
				}
				errMsg = this.translate.instant(field) + ' is invalid';
			break;
			case 'email':
				if(this.isEmpty(value)){
					if(options.allowEmpty) {
						break;
					}
					errMsg = this.translate.instant(field) + ' is required';
				}
				if(typeof(value) === "string" && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) && value.length <= 255) {
					break;
				}
				errMsg = this.translate.instant(field) + ' is invalid';
			break;
			case 'serialNumber':
				if(this.isEmpty(value)){
					if(options.allowEmpty) {
						break;
					}
					errMsg = this.translate.instant(field) + ' is required';
				}
				if(typeof(value) === "string" && /^T(\S){2}(0[1-9]|1[0-2])\d{2}\d{5}$/.test(value)) {
					break;
				}
				errMsg = this.translate.instant(field) + ' is invalid';
			break;

			case 'arrayOf':
				if(this.isEmpty(value)){
					if(options.allowEmpty) {
						break;
					}
					errMsg = this.translate.instant(field) + ' is required';
				}
				switch(options.subType) {
					case 'integers':
						for (const ele of value) {
							if(typeof ele != 'number') {
								errMsg = this.translate.instant(field) + ' should only contain integer values';
								break;
							}
						}
					break;
				}
			break;

			case 'inArray':
				switch(options.subType) {
					case 'integer':
						if(value == parseInt(value)) {
							value = parseInt(value);
						}
					break;
				}
				if(options.values.indexOf(value) < 0) {
					errMsg = this.translate.instant(field) + ' is invalid. Allowed values: ' + options.values.join(', ');
				}
			break;

			case 'notInArray':
				switch(options.subType) {
					case 'float':
						if(value == parseFloat(value)) {
							value = parseFloat(value);
							if(options.values.indexOf(value) > -1) {
								errMsg = this.translate.instant(field) + ' is invalid';
							}
						}
					break;
				}
			break;

			case 'phone':
				if(this.isEmpty(value)){
					if(options.allowEmpty) {
						break;
					}
					errMsg = this.translate.instant(field) + ' is required';
				}
				if(/^(\+\d{1,2}\s*)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value))
					break;
				errMsg = this.translate.instant(field) + ' is invalid';
			break;

			case 'time':
				if(typeof(value) === "string") {
					var patt = /^((0|1)[0-9]|2[0-4]):[0-5][0-9]$/;
					if(patt.test(value)) {
						var time = value.split(':');
						var timeInMints = parseInt(time[0]) * 60 + parseInt(time[1]);

						var validMin = true, validMax = true, validStep = true;

						if(typeof(options.min) !== "undefined") {
							var minTime = options.min.split(':');
							var minTimeInMints = parseInt(minTime[0]) * 60 + parseInt(minTime[1]);

								if(timeInMints < minTimeInMints)
								validMin = false;
						}

						if(typeof(options.max) !== "undefined") {
							var maxTime = options.max.split(':');
							var maxTimeInMints = parseInt(maxTime[0]) * 60 + parseInt(maxTime[1]);

								if(timeInMints > maxTimeInMints)
								validMax = false;
						}

						if(typeof(options.step) !== "undefined" && (timeInMints % options.step != 0))
							validStep = false;

						if(validMin && validMax && validStep) {
							break;
						}
						if(!validMin && !validMax) {
							errMsg = this.translate.instant(field) + ' should be between ' + options.min + '-' + options.max;
						} else if(!validMin) {
							errMsg = this.translate.instant(field) + ' should be later than ' + options.min;
						} else if(!validMax) {
							errMsg = this.translate.instant(field) + ' should be nearer than ' + options.max;
						} else {
							errMsg = this.translate.instant(field) + ' is invalid';
						}
					}
				}
			break;

			case 'daysMask':
				var days = [0, 1, 2, 3, 4, 5, 6];
				if(typeof(value) === "object") {
					var withinDays = true;
					value.forEach(function(ele) {
						if(withinDays && days.indexOf(ele) == -1) {
							withinDays = false;
						}
					});

					if(withinDays) {
						break;
					}
				}
				errMsg = this.translate.instant(field) + ' is invalid';
			break;

			case 'dateRange':
				var validMin = true, validMax = true;

				var DateInSecs = new Date(value).getTime();

				if(typeof(options.min) !== "undefined" && DateInSecs < options.min.getTime())
					validMin = false;
				if(typeof(options.max) !== "undefined" && DateInSecs > options.max.getTime())
					validMax = false;

				if(!validMin || !validMax) {
					errMsg = this.translate.instant(field) + ' should be between ' + options.min + ' and ' + options.max;
				}
			break;

			case 'ipAddressRegex':
				let ipPatt = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
				if(options.allowEmpty && this.isEmpty(value) || ipPatt.test(value)) {
					break;
				}
				errMsg = this.translate.instant(field) + ' is invalid';
			break;

			case 'regex':
				if(
					options.allowEmpty && this.isEmpty(value) ||
					options.patt && options.patt.test(value) ||
					options.strPatt && new RegExp(options.strPatt).test(value)
				){
					break;
				}
				errMsg = this.translate.instant(field) + ' is invalid';
			break;

			case 'arraySubset':
				if(typeof(value) === "object") {
					var withinDays = true;
					value.forEach(function(ele) {
						if(withinDays && options.values.indexOf(ele) == -1) {
							withinDays = false;
						}
					});

					if(withinDays) {
						break;
					}
				}
				errMsg = this.translate.instant(field) + ' is invalid. Allowed values: ' + options.values.join(', ');
			break;

			case 'notNull':
				if(typeof(value) === 'string') {
					let trimmedValue = value.trim();
					if(trimmedValue.length > 0)
						break;
				}
				errMsg = this.translate.instant(field) + ' is invalid';
			break;

			case 'preventWhiteSpaces':
				if(typeof(value) === 'string') {
					if(options.allowEmpty && this.isEmpty(value)) {
						break;
					}
					let trimmedValue = value.trim();
					if(trimmedValue.length > 0) 
					break;
				}
				errMsg = this.translate.instant(field) + ' is invalid';
			break;
		}

		return errMsg;
	}

	getStackHTML(ele, name) {
		var stack = this.checkHasStack(ele, name, null);

		if(!stack) {
			this.changesStackHtml = "";
			return false;
		}

		var returnHTML = `
				<p>
					<strong>`+this.translate.instant('settings.changes_stack')+`</strong>
				</p>
				<table>
					<tr>
						<th>`+this.translate.instant('g.value')+`</th>
						<th>`+this.translate.instant('login_register.user_name')+`</th>
						<th>`+this.translate.instant('g.time')+`</th>
					</tr>`;

		stack.forEach((item) => {
			returnHTML += "<tr><td>" + item[0] + "</td><td>" + item[1]+ "</td><td>" + moment(item[2]).format('YYYY-MM-DD HH:mm:ss') + "</td></tr>";
		});

		returnHTML += "</table>";

		this.changesStackHtml = returnHTML;
	}

	checkHasStack(ele, nameAttr, toColor = 'border') {
		let clearStack = () => {

			if(toColor !== null)
				this.renderer.removeClass(ele, 'dirty-settings-'+toColor);

			return false;
		};

		let stack = this.changesStack;
		if(!stack)
			return clearStack();

		let splitted	= nameAttr.split('.');
		let field		= splitted[0];
		let subField	= splitted[1];
		let fieldStack	= stack[field];

		if(subField) {
			if(!stack[field] || !stack[field][subField])
				return clearStack();

			fieldStack = stack[field][subField];
		} else if(!stack[field]) {

			return clearStack();
		}

		if(toColor !== null)
			this.renderer.addClass(ele, 'dirty-settings-'+toColor);

		return fieldStack;
	}

	onSubmit() {
		let values = this.formRef.form.controls;
		if (this.onBeforeSubmit)
			this.onBeforeSubmit.emit(values);

		this.invalidFields = this.validateFields(values);

		if(Object.keys(this.invalidFields).length)
			return this.notificationMessage.setMessage(this.translate.instant('g.invalid_input'), {clearOnXTimeNavigate: 1});

		this.formSubmitted.emit(values);
	}

	onCancel() {
		this.formCancelled.emit();
	}

	checkReadOnly(field) {
		return this.usersService.checkFieldPermissions(field, this.permissions, this.permissionMapperKey) != 'write';
	}

	isEmpty(data) {
		if(typeof(data) == 'number' || typeof(data) == 'boolean') {
			return false;
		}
		if(typeof(data) == 'undefined' || data === null) {
			return true;
		}
		if(typeof(data.length) != 'undefined') {
			return data.length == 0;
		}
		var count = 0;
		for(var i in data) {
			if(data.hasOwnProperty(i))
				count ++;
		}
		return count == 0;
	}

	isArray(arg) {
		return Array.isArray(arg);
	}

	isReadOnly(field) {
		if(!field.editable)
			return false;

		return field.editable.relatedValues.includes(this.data[field.editable.relatedField]);
	}

	onDropdownChanged(field, value) {
		this.dropdownChanged.emit({field, value, data: this.data});
	}

	highlightEmptyField(field) {
		let fieldVal = Array.isArray(field) ? this.data[field[0]][field[1]] : this.data[field];
		return this.highlightEmpty && fieldVal === undefined;
	}

	showRemoveChangesQueueBtn(field) {
		let fieldName = field.name;
		if(['installation_date_fmt', 'setup_time_fmt', 'last_pm_date_fmt'].includes(fieldName))
			fieldName = fieldName.slice(0, fieldName.indexOf("_fmt"));
		
		const isSubField = this.isArray(fieldName);
		if (this.changesStack)
			return isSubField ? !!this.changesStack[fieldName[0]] && !!this.changesStack[fieldName[0]][fieldName[1]] : !!this.changesStack[fieldName];
	}

	deleteChangeStack(field) {
		let fieldName = field.name;
		if(['installation_date_fmt', 'setup_time_fmt', 'last_pm_date_fmt'].includes(fieldName))
			fieldName = fieldName.slice(0, fieldName.indexOf("_fmt"));

		const isSubField = this.isArray(fieldName);
		const changesStack = isSubField? this.changesStack[fieldName[0]][fieldName[1]]: this.changesStack[fieldName];
		const commandId = changesStack[0][3];
		const userId = changesStack[0][4];
		const data = {fieldName: fieldName, commandId: commandId, userId: userId};
		this.removeChangeStack.emit(data);
	}

	// highlightInvalidFields(invalidFields) {
	// 	let forms = this.elementRef.nativeElement.querySelectorAll('form');

	// 	forms.forEach((form) => {

	// 		if(form.getAttribute('name') == this.formName) {
	// 			for(let idx in form) {
	// 				let ele = form[idx];
	// 				if(Number.isInteger(Number(idx))) {
	// 					if(invalidFields.indexOf(ele.getAttribute('name')) != -1)
	// 						this.renderer.addClass(ele, 'invalid-input');
	// 				}
	// 			}
	// 		}
	// 	});
	// 	this.notificationMessage.setMessage(this.translate.instant('g.invalid_input'));
	// }
}
