import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonDataService } from 'src/app/shared/services/common-data.service';
import * as moment from 'moment';
import { DeviceFactory } from './device-factory/device-factory';


@Injectable({
	providedIn: 'root'
})

export class DeviceCommonService {

	booleanFields: string[] = [ 'is_study_device', 'in_test_mode', 'setup_done', 'enable_current_mask',
								'disable_xtra', 'disable_external_rtc', 'has_current_low_range',
								'has_temperature_sensor', 'has_hall_effect', 'has_water_level', 'enable_rt',
								'enable_multicast', 'enable_wifi_ap_on_start', 'enable_serversocket', 'is_online_enabled',
								'enable_cellular', 'enable_wifi_sta', 'cal_status', 'replacement_part'];

	customFields: string[] = ['battery_type', 'cellular_region', 'metered_flag', 'zone_id'];
	nbIotFields: string[] = ['lte_cellular_bands', 'nbiot_cellular_bands'];
	timeStampFields: string[] = ['installation_date', 'setup_time'];
	networkingFields: string[] = ['mobile_master', 'mobile_network', 'router_network', 'online_network'];

	ddLists = {
		current_cable_scale: [
			{id: 1, label: 1},
			{id: 2, label: 2},
			{id: -1, label: -1},
			{id: -2, label: -2},
		],
		dead_man_current_limit: [],
		battery_type: [
			{id: 0, label: this.translate.instant('events.battery_type_0')},
			{id: 2, label: this.translate.instant('events.battery_type_2')},
		],
		number_of_cells: [
			{id: 12, label: 12},
			{id: 18, label: 18},
			{id: 24, label: 24},
			{id: 36, label: 36},
			{id: 40, label: 40},
		],
		remoter_server_ip: [
			{id: 'production',	text: this.translate.instant('env.production'),	port: 9311, encCode: 2, domain: this.commonData.iotahProductionDomain},
			{id: 'staging',		text: this.translate.instant('env.staging'), 	port: 9359, encCode: 2, domain: this.commonData.iotahStagingDomain},
			{id: 'development',	text: this.translate.instant('env.dev'), 		port: 9311, encCode: 1, domain: this.commonData.iotahDevelopmentDomain},
			{id: 'custom',		text: this.translate.instant('env.custom'), 	port: 9311, encCode: 1, domain: ''}
		],

		cellular_region: [
			{ text: this.translate.instant('g.usa_and_canada'), id: 0 },
			{ text: this.translate.instant('g.usa'), id: 1 },
			{ text: this.translate.instant('g.canada'), id: 2 },
		],

		rt_log_frequency_units: [
			{ text: this.translate.instant('g.seconds'), id: 1 },
			{ text: this.translate.instant('g.minutes'), id: 2 },
		],

		lte_bands: [ // 0x8fe
			{ text: "BAND B1", value: 0x01 },
			{ text: "BAND B2", value: 0x02 }, //
			{ text: "BAND B3", value: 0x04 }, //
			{ text: "BAND B4", value: 0x08 }, //
			{ text: "BAND B5", value: 0x10 }, //
			{ text: "BAND B8", value: 0x20 }, //
			{ text: "BAND B12", value: 0x40 }, //
			{ text: "BAND B13", value: 0x80 }, //
			{ text: "BAND B18", value: 0x100 },
			{ text: "BAND B19", value: 0x200 },
			{ text: "BAND B20", value: 0x400 },
			{ text: "BAND B25", value: 0x800 }, //
			{ text: "BAND B26", value: 0x1000 },
			{ text: "BAND B27", value: 0x2000 },
			{ text: "BAND B28", value: 0x4000 },
			{ text: "BAND B66", value: 0x10000 },
			{ text: "BAND B85", value: 0x80000 },
		],

		nbiot_bands: [
			{ text: "BAND B1", value: 0x01 },
			{ text: "BAND B2", value: 0x02 },
			{ text: "BAND B3", value: 0x04 },
			{ text: "BAND B4", value: 0x08 },
			{ text: "BAND B5", value: 0x10 },
			{ text: "BAND B8", value: 0x20 },
			{ text: "BAND B12", value: 0x40 },
			{ text: "BAND B13", value: 0x80 },
			{ text: "BAND B18", value: 0x100 },
			{ text: "BAND B19", value: 0x200 },
			{ text: "BAND B20", value: 0x400 },
			{ text: "BAND B25", value: 0x800 },
			{ text: "BAND B28", value: 0x4000 },
			{ text: "BAND B66", value: 0x10000 },
			{ text: "BAND B71", value: 0x100000 },
			{ text: "BAND B85", value: 0x80000 },
		],

		metered_flag: [
			{ label: this.translate.instant('devices.cellular_only'), value: 1 },
			{ label: this.translate.instant('devices.metered_flag_both'), value: 2 },
			{ label: this.translate.instant('devices.metered_flag_none'), value: 3 },
		],

		networkPriorities: [
			{id: 0, text: this.translate.instant('priority_options.0')},
			{id: 1, text: this.translate.instant('priority_options.1')},
			{id: 2, text: this.translate.instant('priority_options.2')},
			{id: 3, text: this.translate.instant('priority_options.3')},
			{id: 10, text: this.translate.instant('priority_options.10')}
		]
	};

	chargersType: Array<{ label: string, value: number }> = [
		{ label: this.translate.instant('devices.conventional_charge'), value: 0},
		{ label: this.translate.instant('devices.fast_charger'), value: 1},
		{ label: this.translate.instant('devices.opportunity_charge'), value: 2},
	];

	constructor(
		private translate: TranslateService,
		private commonData: CommonDataService,
	) { }

	formatQueueFields(changes: { [key: string]: any}) {
		let changesToShow = [];

		changes.forEach(change => {
			for (const field in change) {
				let value;

				switch (true) {
					case this.booleanFields.includes(field):
						value = this.translate.instant(change[field] == 1 ? 'g.true' : 'g.false');
						break;
					case this.timeStampFields.includes(field):
						value = moment(change[field] * 1000).format('MM/DD/YYYY');
						break;
					case this.nbIotFields.includes(field):
						value = this.formatLteBands(field, change[field]);
						break;
					case this.customFields.includes(field) && field == 'cellular_region':
						value = this.ddLists.cellular_region.find(item => item.id == change[field])?.text;
						break;
					case this.customFields.includes(field) && field == 'sct_device_type':
						value = DeviceFactory.options.find(item => item.id == change[field])?.name;
						break;
					case this.customFields.includes(field) && field == 'battery_type':
						value = this.ddLists.battery_type.find(item => item.id == change[field])?.label;
						break;
					case this.customFields.includes(field) && field == 'metered_flag':
						value = this.ddLists.metered_flag.find(item => item.value == change[field])?.label;
						break;
					case this.customFields.includes(field) && field == 'zone_id':
						value = this.commonData.TimeZonesMenu.find(item => item.id == change[field])?.display_name;
						break;
					case this.networkingFields.includes(field):
						for (let modal in change[field]) {
							value = change[field][modal]
							if (['priority'].includes(modal))
								value = this.ddLists.networkPriorities.find(item => item.id == value).text;

							changesToShow.push({ 'key': [`${field}_${modal}`], 'value': value });
						}
						continue;
					default:
						value = change[field];
						break;
				}

				changesToShow.push({ 'key': field, 'value': value });
			}
		});

		return changesToShow;
	}

	formatLteBands(field: string, value: number) {
		const cellularField = field == 'lte_cellular_bands'? this.ddLists.lte_bands : this.ddLists.nbiot_bands;
		let cellularBandArray: number[] = [];
		for (const it of cellularField) {
			if ((value & it.value) != 0)
				cellularBandArray.push(it.value);
		}
		return cellularField.filter(obj => cellularBandArray.includes(obj.value)).map(obj => obj.text);
	}

	getApStatus(qvApStatus, qvApIp) {

		let AP_STATUS_STARTED = 1,
		AP_STATUS_STOPPED = 3,
		AP_STATUS_STA_CONNECTED = 4,
		AP_STATUS_STA_DISCONNECTED = 5,
		AP_STATUS_PROBEREQRECVED = 6,
		AP_STATUS_STA_GOT_IP6 = 7,
		AP_STATUS_MAX = 8;

		if(![AP_STATUS_STOPPED, AP_STATUS_MAX].includes(qvApStatus) && qvApIp != "0.0.0.0" && qvApIp)
			return this.translate.instant('qv.ap_on')+' '+qvApIp;

		return this.translate.instant('qv.ap_on');
	}


}
