import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';

@Component({
	selector: 'app-fake-data-generator',
	templateUrl: './fake-data-generator.component.html',
	styleUrls: ['./fake-data-generator.component.css']
})
export class FakeDataGeneratorComponent implements OnInit {

	typeList = [
		{name: 'Events', id: 'event'},
		{name: 'Rts', id: 'rt'},
		{name: 'Quick View', id: 'quick'}
	];

	deviceId: string;
	from: Date;
	to: Date;
	maxPerDay: number;
	type: string;
	withDailyDetails: boolean;
	withAnalytics: boolean;
	isSerialNumber: boolean = false;
	lastConnectTime: number;
	shippingDate: number;
	deleteOldRecords: boolean = false;
	invalidFromDate: boolean = false;
	invalidToDate: boolean = false
	invalidDeviceField: boolean = false;

	constructor(
		private adminService: AdminService,
		private commonService: CommonService,
		private notificationService: NotificationMessageService,
		private router: Router
	) {}

	ngOnInit(): void {
		const isProduction = this.commonService.isProductionDomain();
		const isStaging = this.commonService.isStagingDomain();

		if(isProduction || isStaging) {
			this.router.navigate(['/unauthorized']);
			return;
		}
	}

	onSubmit() {
		if(this.type != 'event' && this.deleteOldRecords)
			this.deleteOldRecords = false;

		const options = {
			deviceId: this.deviceId,
			from: this.from,
            to: this.to,
            type: this.type,
            maxPerDay: this.maxPerDay,
            withDailyDetails: this.withDailyDetails,
			withAnalytics: this.withAnalytics,
            isSerialNumber: this.isSerialNumber,
			lastConnectTime: this.lastConnectTime || 0,
			deleteOldRecords: this.deleteOldRecords,
			shippingDate: this.shippingDate || 0
		};

		if (this.validateFormData(options)) {
			this.adminService.generateData(options).subscribe((res) => {
				if (res)
					return this.notificationService.setMessage('globalSuccessMsg');
			});
		}
	}

	validateFormData(data): boolean {
		this.invalidFromDate = !data.from;
		this.invalidToDate = !data.to;
		this.invalidDeviceField = !data.deviceId;

		if (this.invalidFromDate || this.invalidToDate || this.invalidDeviceField) {
			this.notificationService.setMessage('translate|g.invalid_input');
			return false;
		}

		if (!data.type) {
			this.notificationService.setMessage('translate|g.select_generate_fake_type');
			return false;
		}

		return true;
	}
}
