import { Component, Input } from '@angular/core';
import { DeviceFactory } from '../../site-dashboard/device/device-factory/device-factory';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-customer-utilization-chart',
	templateUrl: './customer-utilization-chart.component.html',
	styleUrls: ['./customer-utilization-chart.component.css']
})
export class CustomerUtilizationChartComponent {
	@Input() deviceType?: number = DeviceFactory.types.iotah;
	@Input() quarterlyData: any = [];
	@Input() monthlyData: any = [];

	quarterChartObject = {
		config:  {
			legend: 'none',
			bar: {
				groupWidth: 30
			},
			tooltip: {isHtml: true},
		},
		columnNames: ['Quarter', 'Utilization'],
		hasData: false
	}

	monthlyChartObject = {
		config:  {
			legend: 'none',
			bar: {
				groupWidth: 30
			},
			tooltip: {isHtml: true},
		},
		columnNames: ['Quarter', 'Utilization'],
		hasData: false
	}

	periodFilters = [
		{ id: 'quarterly', durations: this.translateService.instant('g.quarterly') },
		{ id: 'monthly', durations: this.translateService.instant('g.monthly') }
	];
	selectedPeriod = 'quarterly';

	selectedYear = moment().utc().get('year').toString();
	yearsList = [];
	deviceConfig = DeviceFactory.createDevice(this.deviceType).config;

	constructor(
		private translateService: TranslateService
	) { }

	ngOnInit() {
		this.deviceConfig = DeviceFactory.createDevice(this.deviceType).config;

		if (this.quarterlyData[this.selectedYear]?.length > 0)
			this.quarterChartObject.hasData = true;

		if (this.monthlyData[this.selectedYear]?.length > 0)
			this.monthlyChartObject.hasData = true;

		this.prepareYearsList();
	}

	prepareYearsList() {
		if (this.selectedPeriod === 'quarterly')
			this.yearsList = Object.keys(this.quarterlyData).filter(year => year != 'all').map((year) => ({ year, id: year })).reverse();
		else
			this.yearsList = Object.keys(this.monthlyData).filter(year => year != 'all').map((year) => ({ year, id: year })).reverse();

		this.yearsList.unshift({ year: this.translateService.instant("durations.all_years"), id: 'all' });
	}
}
