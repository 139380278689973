declare var require: any
import { TranslateLoader } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { catchError } from 'rxjs/operators';
import { CommonDataService } from "./shared/services/common-data.service";

export class TranslateFileLoader implements TranslateLoader {
		_currentLanguage: string;
		_currentData: any;
		constructor(private commonData: CommonDataService) {}

		/**
		 * Gets the translations from JSON file
		 * @param lang
		 * @returns {any}
		 */
		public getTranslation(lang: string): Observable<any> {
				return Observable.create(observer => {
						if(this._currentLanguage == lang){
								observer.next(this._currentData);
								observer.complete();
								return observer;
						}

						let domainMappings = [
							{ translationKey: "chargeLink", domains: this.commonData.chargLinkDomains },
							{ translationKey: "battlink", domains: this.commonData.battLinkDomains }
						];

						let data = require(`../../../Common/translation/${lang}.json`);

						domainMappings.forEach(({ translationKey, domains }) => {
							if (domains.includes(window.location.hostname)) {
								data = this.margeTranslationData(data, data[translationKey]);
							}
						});

						this._currentData = data;
						this._currentLanguage = lang;
						observer.next(this._currentData);
						observer.complete();
				});
		}

		private margeTranslationData ( firstObj, secondObj ) {
			if (typeof firstObj !== "object" || typeof secondObj !== "object" || firstObj === null || secondObj === null)
				return secondObj;
			const merged = { ...firstObj };

			for (const key in secondObj) {
				if (secondObj.hasOwnProperty(key))
					merged[key] = this.margeTranslationData(firstObj[key], secondObj[key]);
			}

			return merged;
		}
}
