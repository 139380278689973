<div class="white-box main-black mt-4 p-4 border-radius" *ngIf="hasData">
	<div class="row">
		<div class="col">
			<h4>
				{{this.deviceConfig.quarterlyUsage.perfAnalyticsKey | device_translation: deviceType}}
				<app-help-tooltip *ngIf="tooltips" [field]="tooltips"></app-help-tooltip>
			</h4>
		</div>
	</div>
	<div class="row py-4">
		<div *ngIf="ddList.quarter.length > 0" class="col-sm-6 col-md-4 col-lg-2 d-inline-flex">
			<ng-select class="mr-auto w-100" [searchable]="false" [items]="ddList.quarter" bindLabel="year" bindValue="id" placeholder="{{'g.year' | translate}}" name="year" [(ngModel)]="selectedYear" [clearable]="false">
			</ng-select>
		</div>
		<div class="col-md-6 col-lg-4 col-xl-2 d-inline-flex" *ngIf="options.hasTruckTypeFilter && ddList.truckTypes.length > 0 && deviceConfig.quarterlyUsage.hasTypeFilter">
			<ng-select class="mr-auto w-100" [searchable]="false" [items]="ddList.truckTypes" bindLabel="text" bindValue="id" placeholder="{{'devices.truck_type' | translate}}" name="truckTypes" [(ngModel)]="selectedTruckType" (ngModelChange)="rebuildPerformanceCharts(deviceConfig.quarterlyUsage.period)" [clearable]="false">
			</ng-select>
		</div>
		<div class="col-md-6 col-lg-4 col-xl-2 d-inline-flex" *ngIf="options.hasYearFilter && ddList.truckYearsList.length > 0 && deviceConfig.quarterlyUsage.hasYearFilter">
			<ng-select class="mr-auto w-100" [searchable]="false" [items]="ddList.truckYearsList" bindLabel="label" bindValue="value" placeholder="{{'devices.truck_manufacturing_year' | translate}}" name="truckYear" [(ngModel)]="selectedTruckYear" (ngModelChange)="rebuildPerformanceCharts(deviceConfig.quarterlyUsage.period)" [clearable]="false">
			</ng-select>
		</div>
		<div class="col-sm-6 col-md-4 col-lg-2 d-inline-flex" *ngIf="options.hasSiteFilter && sites.length > 0">
			<ng-select class="mr-auto w-100" [searchable]="false" [items]="sites" bindLabel="name" bindValue="id" placeholder="{{'site.all_sites' | translate}}" name="site" [(ngModel)]="selectedSites" (ngModelChange)="rebuildPerformanceCharts(deviceConfig.quarterlyUsage.period)" [multiple]="true">
			</ng-select>
		</div>
		<div class="col-sm-6 col-md-4 col-lg-2 d-inline-flex" *ngIf="tags && tags.length > 0">
			<ng-select
				[multiple]="true"
				[items]="tags"
				[addTag]="false"
				[clearable]="false"
				[hideSelected]="true"
				[searchable]="false"
				bindLabel="tag_name"
				bindValue="id"
				(ngModelChange)="onSelectedTagsChanges($event, 'quarter')"
				[placeholder]="'tags.filter_by_tag' | translate"
				[(ngModel)]="selectedTags"
				class="mr-auto w-100"
			></ng-select>
		</div>
		<div *ngIf="showChart" class="d-inline-flex ml-auto" [ngClass]="{'pt-2': common.isMobileScreen}">
			<button  class="btn fa fa-print fa-lg" printTitle="{{'g.performance' | translate}}" [useExistingCss]="true" printSectionId="quarter-performance-chart-{{deviceConfig.quarterlyUsage.performanceChartType}}" ngxPrint title="{{'g.print' | translate}}"></button>
			<button class="fa fa-upload btn" title="{{'g.export_to_csv' | translate}}" (click)="exportQuarterlyUsageData()"></button>
		</div>
	</div>
	<div class="row py-4" *ngIf="parentPage != 'device' && trucksColors.length > 0 && showChart">
		<div class="font-size-12 ml-3" *ngFor="let truckType of trucksColors">
			<ng-container *ngIf="truckType.id != '' && deviceConfig.quarterlyUsage.hasChartLegend">
				<div class="chartLegend mr-1" [ngStyle]="{'background-color': truckType.color}"></div><b>{{truckType.text}}</b>
			</ng-container>
		</div>
	</div>
	<div id="quarter-performance-chart-{{deviceConfig.quarterlyUsage.performanceChartType}}" class="overflow-auto" *ngIf="showChart">
		<div class="showOnPrint">
			<ng-container *ngIf="parentPage == 'device'">
				<h3>{{'devices.truck_sn_short' | translate}}: {{devices[0].truck_sn}}</h3>
				<h3>{{'devices.serial_number' | translate}}: {{devices[0].serial_number}}</h3>
			</ng-container>
			<h4>{{'g.year' | translate}}: {{selectedYear}}</h4>
			<ng-container *ngIf="parentPage != 'device'">
				<h4>{{selectedTruckType || 'site_performance.all_truck_types' | translate}}</h4>
				<h4>{{'site_performance.truck_year_'+ (selectedTruckYear || 'all') | translate}}</h4>
				<h4 *ngIf="selectedTagNames.length">{{'tags.tags' | translate}}: {{selectedTagNames.join(', ')}}</h4>
			</ng-container>
		</div>
		<div class="col-md-12">
			<div class="row">
			<div class="col-md-{{deviceConfig.quarterlyUsage.chartGridColumns}} col-sm-12">
				<sct-charts
					id="quarter-performance-chart-{{deviceConfig.quarterlyUsage.performanceChartType}}-total-ahr"
					title="{{'perf_analytic.total_inuse_as_from_all_events_raw' | device_translation: deviceType}}"
					[data]="quarterChartObject.data_total_ahr[selectedYear]"
					[columnNames]="quarterChartObject.columnNames"
					[options]="chartConfig"
					[type]="'ColumnChart'"
					[height]="500"
				></sct-charts>
			</div>
			<div class="col-md-{{deviceConfig.quarterlyUsage.chartGridColumns}} col-sm-12">
				<sct-charts
					id="quarter-performance-chart-{{deviceConfig.quarterlyUsage.performanceChartType}}-avg-ahr"
					title="{{'perf_analytic.avg_total_inuse_as_from_all_events_raw' | device_translation: deviceType}}"
					[data]="quarterChartObject.data_avg_ahr[selectedYear]"
					[columnNames]="quarterChartObject.columnNames"
					[options]="chartConfig"
					[type]="'ColumnChart'"
					[height]="500"
				></sct-charts>
			</div>
			</div>
		</div>
		<div class="col-md-12">
			<div class="row">
			<div class="col-md-{{deviceConfig.quarterlyUsage.chartGridColumns}} col-sm-12">
				<sct-charts
					id="quarter-performance-chart-{{deviceConfig.quarterlyUsage.performanceChartType}}-total-kwhr"
					title="{{'perf_analytic.total_inuse_ws_from_all_events_raw' | device_translation: deviceType}}"
					[data]="quarterChartObject[parentPage == 'customer' && deviceConfig.customer.mix_quarterly_usage_kwh ? 'data_total_charge_kwhr_mix' : 'data_total_kwhr'][selectedYear]"
					[columnNames]="quarterChartObject.columnNames"
					[options]="chartConfig"
					[type]="'ColumnChart'"
					[height]="500"
				></sct-charts>
			</div>
			<div class="col-md-{{deviceConfig.quarterlyUsage.chartGridColumns}} col-sm-12">
				<sct-charts
					id="quarter-performance-chart-{{deviceConfig.quarterlyUsage.performanceChartType}}-avg-kwhr"
					title="{{'perf_analytic.avg_total_inuse_ws_from_all_events_raw' | device_translation: deviceType}}"
					[data]="quarterChartObject[parentPage == 'customer' && deviceConfig.customer.mix_quarterly_usage_kwh ? 'data_avg_charge_kwhr_mix' : 'data_avg_kwhr'][selectedYear]"
					[columnNames]="quarterChartObject.columnNames"
					[options]="chartConfig"
					[type]="'ColumnChart'"
					[height]="500"
				></sct-charts>
			</div>
			</div>
		</div>
		<div class="col-md-12" *ngIf="deviceConfig.quarterlyUsage.hasTotalLiftTimeChart">
			<div class="row">
			<div class="col-md-12 col-sm-12">
				<sct-charts
					id="quarter-performance-chart-{{deviceConfig.quarterlyUsage.performanceChartType}}-total-kwhr"
					title="{{'perf_analytic.total_lift_time_raw' | device_translation: deviceType}}"
					[data]="quarterChartObject.data_total_lift_time[selectedYear]"
					[columnNames]="quarterChartObject.columnNames"
					[options]="timeChartConfig"
					[type]="'ColumnChart'"
					[height]="500"
				></sct-charts>
			</div>
			<div class="col-md-12 col-sm-12">
				<sct-charts
					id="quarter-performance-chart-{{deviceConfig.quarterlyUsage.performanceChartType}}-avg-kwhr"
					title="{{'perf_analytic.avg_total_lift_time_raw' | device_translation: deviceType}}"
					[data]="quarterChartObject.data_avg_lift_time[selectedYear]"
					[columnNames]="quarterChartObject.columnNames"
					[options]="timeChartConfig"
					[type]="'ColumnChart'"
					[height]="500"
				></sct-charts>
			</div>
			</div>
		</div>
		<div class="col-md-12" *ngIf="deviceConfig.quarterlyUsage.hasTotalTravelTimeChart">
			<div class="row">
			<div class="col-md-12 col-sm-12">
				<sct-charts
					id="quarter-performance-chart-{{deviceConfig.quarterlyUsage.performanceChartType}}-total-kwhr"
					title="{{'perf_analytic.total_travel_time_raw' | device_translation: deviceType}}"
					[data]="quarterChartObject.data_total_travel_time[selectedYear]"
					[columnNames]="quarterChartObject.columnNames"
					[options]="timeChartConfig"
					[type]="'ColumnChart'"
					[height]="500"
				></sct-charts>
			</div>
			<div class="col-md-12 col-sm-12">
				<sct-charts
					id="quarter-performance-chart-{{deviceConfig.quarterlyUsage.performanceChartType}}-avg-kwhr"
					title="{{'perf_analytic.avg_total_travel_time_raw' | device_translation: deviceType}}"
					[data]="quarterChartObject.data_avg_travel_time[selectedYear]"
					[columnNames]="quarterChartObject.columnNames"
					[options]="timeChartConfig"
					[type]="'ColumnChart'"
					[height]="500"
				></sct-charts>
			</div>
			</div>
		</div>

		<!-- utilization charts -->
		<div class="col-md-12" *ngIf="quarterChartObject.utilization_score[selectedYear] && quarterChartObject.energy_utilization_score[selectedYear]">
			<div class="row">
				<div [class]="deviceConfig.quarterlyUsage.period == 'quarter' ? 'col-md-6 col-sm-6' : 'col-md-12 col-sm-12'">
					<sct-charts
						id="quarter-performance-chart-{{deviceConfig.quarterlyUsage.performanceChartType}}-utilization-score"
						title="{{'utilization.utilization_score' | translate}}"
						[data]="quarterChartObject.utilization_score[selectedYear]"
						[columnNames]="quarterChartObject.columnNames"
						[options]="chartConfig"
						[type]="'ColumnChart'"
						[height]="500"
					></sct-charts>
				</div>
				<div [class]="deviceConfig.quarterlyUsage.period == 'quarter' ? 'col-md-6 col-sm-6' : 'col-md-12 col-sm-12'">
					<sct-charts
						id="quarter-performance-chart-{{deviceConfig.quarterlyUsage.performanceChartType}}-energy-utilization-score"
						title="{{'utilization.energy_utilization_score' | translate}}"
						[data]="quarterChartObject.energy_utilization_score[selectedYear]"
						[columnNames]="quarterChartObject.columnNames"
						[options]="chartConfig"
						[type]="'ColumnChart'"
						[height]="500"
					></sct-charts>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="!showChart" class="mt-4 alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</div>
