import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UsersService } from '../users.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { CaptchaComponent } from '../captcha/captcha.component';
import { TranslateService } from '@ngx-translate/core';
import { CommonDataService } from 'src/app/shared/services/common-data.service';
import { ValidationService } from 'src/app/shared/services/validation.service';

@Component({
	// changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
	@ViewChild('captchaComponent', { static: false }) captchaComponent: CaptchaComponent;
	@ViewChild('emailComponent', { static: false }) emailComponent: ElementRef;
	isValidEmail = false;
	showCaptcha: boolean = false;
	copyrightYear = new Date().getFullYear();

	googlePlayAppLink: string = this.commonUtil.mobileAppLinks.google_play.iotah;
	appStoreAppLink: string = this.commonUtil.mobileAppLinks.app_store.iotah;
	siteName: string = this.translateService.instant('g.smart_telemetrics');
	imageClass: string = 'smart_telemetrics';

	constructor(
		private router: Router,
		private navigation: NavigationService,
		private usersService: UsersService,
		private notificationMessage: NotificationMessageService,
		private commonUtil: CommonService,
		private translateService: TranslateService,
		private route: ActivatedRoute,
		private commonData: CommonDataService,
		private validationService: ValidationService
	) {}

	yearAge = new Date().getFullYear();
	loginLogo: string = '';

	ngOnInit() {
		this.checkExpiredLink();
		this.updateLoginImage();

		if (this.commonUtil.isChargeLinkDomain()) {
			this.googlePlayAppLink = this.commonUtil.mobileAppLinks.google_play.chargLink;
			this.appStoreAppLink = this.commonUtil.mobileAppLinks.app_store.chargLink;
		}
	}

	ngAfterViewInit() {
		this.emailComponent.nativeElement.focus();
	}

	checkExpiredLink() {
		this.route.queryParams.subscribe(params => {
			if('link_expired' in params)
				return this.notificationMessage.setMessage(this.translateService.instant('g.link_expired'));
		})
	}

	updateLoginImage() {
		if(this.commonUtil.isChargeLinkDomain()) {
			this.imageClass = 'charglink';
			this.siteName = this.translateService.instant('g.charglink');
			this.loginLogo = '/images/chargLink/charglink-logo.png';
			return
		}

		if(this.commonUtil.isBattlinkDomain()) {
			this.imageClass = 'battlink';
			this.siteName = this.translateService.instant('g.battlink');
			this.loginLogo = '/images/battLink/battlink-logo.png';
			return
		}

		if(this.commonUtil.isIotahDomain()) {
			this.imageClass = 'iotah';
			this.siteName = this.translateService.instant('g.iotah');
			this.loginLogo = '/images/logo-v1.png';
			return
		}

		this.imageClass = 'smart_telemetrics';
		this.siteName = this.translateService.instant('g.smart_telemetrics');
		this.loginLogo = '/images/smartTelemetrics/logo.png';
		return;
	}
	onLogin(form: NgForm) {
		const email    = form.value.email;
		const password = form.value.password;
		const captcha_key = this.captchaComponent?.captchaKey;
		const captcha_text = this.captchaComponent?.captchaText;

		if(!email || !password)
			return;
		if(captcha_key && !captcha_text)
			return this.notificationMessage.setMessage('translate|login_register.too_many_incorrect_attempts_provide_captcha');

		this.usersService.login({email, password, captcha_key, captcha_text}).subscribe(
			(res: any) => {
				this.notificationMessage.closeNotification();
				if(res.api_status){
					switch (res.api_status) {
						case 2:
							this.setAppropriteFailedMessage(res.response);
							break;
						case 3:
							return this.router.navigate(['/non-confirmed-user-email', res.response.id]);
						case 4:
							this.notificationMessage.setMessage('translate|login_register.please_check_your_email', {type: 'info'});
							break;
						case 6:
							this.notificationMessage.setMessage('translate|login_register.account_deactivated', {type: 'danger'});
							break;
						case 5:
							this.notificationMessage.setMessage('translate|users.prevent_to_login_err_msg', {type: 'danger'});
							break;
					}
					if(res.response?.shouldShowCaptcha){
						this.captchaComponent?.getCaptcha(true);
						this.showCaptcha = true;
					}
				} else
					 this.onSuccessLogin(res);
			}
		);
	}

	setAppropriteFailedMessage(response){
		if(response?.shouldBlockLogin)
			return this.notificationMessage.setMessage(
				this.translateService.instant(
					'login_register.login_blocked_try_after_x_minutes',
					{counter: response.timeRemaining}));

		if(response?.shouldShowCaptcha)
			return this.notificationMessage.setMessage(
				'translate|login_register.too_many_incorrect_attempts_provide_captcha');

		return this.notificationMessage.setMessage('translate|login_register.invalid_username_or_password', {clearOnXTimeNavigate: 1});
	}

	onSuccessLogin(loginResponse) {
		loginResponse.access_functions			= this.commonUtil.revertPermissionValue(loginResponse.access_functions);
		loginResponse.permissions.permission	= this.commonUtil.revertPermissionValue(loginResponse.permissions.permission);
		this.usersService.setCurrentUser(loginResponse);

		if(loginResponse.force_password_change)
			return this.router.navigate(['/change-expired-password']);

		let url = this.navigation.getPreviousUrl();
		if(url) {
			if(url==='/landing')
				this.router.navigate(['/']);
			else
				this.router.navigateByUrl(url)
		} else
			this.router.navigate(['/'])
	}

	checkEmailValidation() {
		const email = this.emailComponent.nativeElement.value;
		this.isValidEmail = this.validationService.dataValidator('email', {allowEmpty: true}, email);
	}
}
