import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-battery-ah-return-score',
	templateUrl: './battery-ah-return-score.component.html',
	styleUrls: ['./battery-ah-return-score.component.css']
})
export class BatteryAhReturnScoreComponent {
	@Input() data = null;
	ranges = [
		{from: 0, to: 80, color: '#bf0001', status: this.translateService.instant('battery_return_score.very_low'), width: 0},
		{from: 80, to: 100, color: '#ffc000', status: this.translateService.instant('battery_return_score.under_charge'), width: 0},
		{from: 100, to: 105, color: '#92d051', status: this.translateService.instant('battery_return_score.marginally_charged'), width: 0},
		{from: 105, to: 120, color: '#0e7c41', status: this.translateService.instant('battery_return_score.optimal'), width: 0},
		{from: 120, to: 150, color: 'purple', status: this.translateService.instant('battery_return_score.over_charge'), width: 0},
	];

	constructor(
		private translateService: TranslateService,
	) {}
}
