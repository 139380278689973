<div class="mb-5">
	<h1 class="h3 m-4">{{'utilization.truck_utilization_score' | translate}}</h1>
	<div class="chart-container">
		<div class="filter-container">
			<!-- period filter -->
			<ng-select
				class="select"
				[items]="periods"
				[addTag]="false"
				[clearable]="false"
				[searchable]="false"
				bindLabel="label"
				bindValue="value"
				(change)="onSelectionChanged({filter: 'period', value: $event.value})"
				[placeholder]="'g.durations' | translate"
				[(ngModel)]="selectedPeriod"
			></ng-select>
		</div>
		<app-horizontal-chart
			[title]="'utilization.percent_utilization_score'| translate"
			[devices]="devices"
			[data]="utilizationScoreSelectedData"
			[vAxisTitle]="'g.truck' |translate"
		></app-horizontal-chart>
		<app-horizontal-chart
			[title]="'utilization.percent_energy_usage_score'| translate"
			[devices]="devices"
			[data]="energyScoreSelectedData"
			[vAxisTitle]="'g.truck' |translate"
		></app-horizontal-chart>
	</div>
</div>

