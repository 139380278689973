import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-score-chart',
	templateUrl: './score-chart.component.html',
	styleUrls: ['./score-chart.component.scss']
})
export class ScoreChartComponent {
	@Input() data: any = {};
	@Input() devices: any = {};
	@Input() hasTruckTypeFilter = false;
	@Input() range: any = null;
	value = 0;

	periods = [
		{ value: 'last_week', label: this.translateService.instant('durations.last_week') },
		{ value: 'last_7_days', label: this.translateService.instant('durations.last_7_days') },
		{ value: 'last_30_days', label: this.translateService.instant('durations.last_30_days') },
		{ value: 'last_90_days', label: this.translateService.instant('durations.last_90_days') },
		{ value: 'year_to_date', label: this.translateService.instant('durations.year_to_date') },
		{ value: 'last_year', label: this.translateService.instant('durations.last_year') },
		{ value: 'all_years', label: this.translateService.instant('durations.all_years') },
	];

	truckTypes = [];
	selectedPeriod = this.periods[0].value;
	selectedTruckType = "All";

	valuePx = 0;
	valueStatus = 'Very Low';
	ranges = [
		{from: 0, to: 10, color: '#bf0001', status: 'Very Low', width: 0},
		{from: 10, to: 30, color: '#ffc000', status: 'Low', width: 0},
		{from: 30, to: 60, color: '#feff99', status: 'Medium', width: 0},
		{from: 60, to: 80, color: '#92d051', status: 'Good', width: 0},
		{from: 80, to: 100, color: '#0e7c41', status: 'Excellent', width: 0},
	];
	rangesLabels = [];
	chartWidthPx = 600;

	constructor(
		private translateService: TranslateService,
	) {}

	ngOnInit() {
		this.prepareRanges();
		this.prepareTruckTypes();
		this.formatSelectedData();
		this.updateChart();
	}

	prepareRanges() {
		if (this.range)
			this.ranges = this.range;

		this.rangesLabels = ['0%'];
		const maxValue = this.ranges[this.ranges.length - 1].to;
		const rangeStep = maxValue / 5;
		let counter = 1;

		for (const range of this.ranges) {
			this.rangesLabels.push(`${rangeStep * counter}%`);
			range.width = (range.to - range.from) * this.chartWidthPx / maxValue;
			counter++;
		}
	}

	onSelectionChanged(event) {
		switch (event.filter) {
			case 'period':
				this.selectedPeriod = event.value;
				break;
			case 'truckType':
				this.selectedTruckType = event.value;
				break;
			default:
				break;
		}

		this.formatSelectedData();
		this.updateChart();
	}

	prepareTruckTypes() {
		this.truckTypes = [{value: 'All', label: 'All'}];

		for (const macAddress in this.devices) {
			const device = this.devices[macAddress];

			if (device.truck_type)
				this.truckTypes.push({
					value: device.truck_type,
					label: device.truck_type,
				});
		}
	}

	formatSelectedData() {
		const selectedData = this.data[this.selectedPeriod];
		let total = 0;
		let count = 0;

		for (const macAddress in selectedData) {
			if (this.hasTruckTypeFilter && this.selectedTruckType !== 'All' && this.devices?.[macAddress]?.truck_type !== this.selectedTruckType)
				continue;

			if (selectedData.hasOwnProperty(macAddress)) {
				const deviceData = selectedData[macAddress];
				total += deviceData;
				count ++;
			}
		}

		this.value = Math.floor(total / count);
	}

	updateChart() {
		if (this.value < 0) {
			this.value = 0;
		} else if (this.value > 100) {
			this.value = 100;
		}

		const maxValue = this.ranges[this.ranges.length - 1].to;
		this.valuePx = this.value * this.chartWidthPx / maxValue;
		this.valueStatus = this.ranges.find(range => range.from <= this.value && range.to > this.value).status;
	}
}
