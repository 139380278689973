<div class="mb-5">
	<h1 class="h3 m-4">{{'utilization.relative_truck_utilization' | translate}}</h1>
	<div class="widget-container" *ngIf="showChart">
		<div class="filter-container">
			<!-- period filter -->
			<ng-select
				class="select"
				[items]="periods"
				[addTag]="false"
				[clearable]="false"
				bindLabel="label"
				bindValue="value"
				(change)="onSelectionChanged({filter: 'period', value: $event.value})"
				[placeholder]="'g.durations' | translate"
				[(ngModel)]="selectedPeriod"
			></ng-select>
		</div>
		<sct-charts
			class="chart"
			[id]="chart.title"
			[title]="chart.title"
			[data]="chart.data"
			[columnNames]="chart.columnNames"
			[options]="chart.config"
			[type]="'ColumnChart'"
			[height]="200"
		></sct-charts>
	</div>
</div>
