import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-relative-truck-utilization',
	templateUrl: './relative-truck-utilization.component.html',
	styleUrls: ['./relative-truck-utilization.component.css']
})
export class RelativeTruckUtilizationComponent {
	@Input() data: any = {};
	@Input() devices: any = {};
	showChart = false;

	periods = [
		{ value: 'last_week', label: this.translateService.instant('durations.last_week') },
		{ value: 'last_7_days', label: this.translateService.instant('durations.last_7_days') },
		{ value: 'last_30_days', label: this.translateService.instant('durations.last_30_days') },
		{ value: 'last_90_days', label: this.translateService.instant('durations.last_90_days') },
		{ value: 'year_to_date', label: this.translateService.instant('durations.year_to_date') },
		{ value: 'last_year', label: this.translateService.instant('durations.last_year') },
	];
	selectedPeriod = this.periods[0].value;

	chart = {
		title: this.translateService.instant('utilization.relative_truck_utilization'),
		data: [],
		columnNames: [this.translateService.instant('g.device'), this.translateService.instant('g.usage_percentage')],
		config: {
			hAxis: {
				title: this.translateService.instant('g.device'),
			},
			vAxis: {
				title: this.translateService.instant('g.usage_percentage'),
				viewWindow: {
					min: 0,
					max: 100
				}
			},
			colors: ['#3466cc'],
			legend: {
				position: 'top',
				alignment: 'center',
				textStyle: { fontSize: 12 }
			},
			titleTextStyle: {
				fontSize: 16,
				bold: true,
				color: '#333',
				paddingBottom: 20,
				paddingTop: 20
			}
		}
	}

	constructor(
			private translateService: TranslateService,
	) {}

	ngOnInit() {
		this.formatSelectedData();
	}

	onSelectionChanged(event) {
		switch (event.filter) {
			case 'period':
				this.selectedPeriod = event.value;
				break;
			default:
				break;
		}

		this.formatSelectedData();
	}

	formatSelectedData() {
		const selectedData = this.data[this.selectedPeriod];

		if (!selectedData || !Object.keys(this.devices).length)
			return;

		this.showChart = true;
		const data = [];

		for (const mac in selectedData) {
			const name = this.devices[mac]?.serial_number;
			if (!name)
				continue;

			data.push([name, selectedData[mac]]);
		}

		this.chart.data = data;
	}
}
