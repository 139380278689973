<form #forgotPasswordFrm="ngForm" (ngSubmit)="forgotPassword()" validate-form="forgotPassword" class="before-login-form">
	<div class="row mt-2 align-items-center">
		<div class="col">
			<p>{{'login_register.forgot_header' | translate}}</p>
		</div>
	</div>
	<div class="row mt-2 align-items-center">
		<div class="col">
			<input type="email" class="form-control" name="email" [(ngModel)]="forgot.email" placeholder="{{'users.email_address' | translate}}" required email/>
		</div>
	</div>
	<div class="row mt-2 align-items-center">
		<div class="col-12">
			<app-captcha #captchaComponent></app-captcha>
		</div>
	</div>
	<div class="row mt-4 align-items-center">
		<div class="col-md-12">
			<button
				type="submit"
				class="btn text-white main-blue-bg btn-lg col-5 mb-2 mr-2 green-btn"
				[disabled]="!captchaComponent.captchaText || captchaComponent.triesLimitReached"
			>
				{{'g.submit' | translate}}
			</button>
			<button type="button" class="btn btn-light btn-lg col-5 mb-2" (click)="goBack()">{{'g.cancel' | translate}}</button>
		</div>
	</div>
</form>
