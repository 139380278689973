import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import * as moment from 'moment';
import { UsersService } from 'src/app/users/users.service';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { ColumnsConfig, TableConfig, TableData} from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';
import * as _ from 'lodash';
import { DomainsService } from 'src/app/shared/services/domains.service';

@Component({
	selector: 'sim-usage-management',
	templateUrl: './sim-usage-management.component.html',
	styleUrls: ['./sim-usage-management.component.css']
})
export class simUsageManagementComponent implements OnInit, OnDestroy {
	@ViewChild("tableDataPopup") tableDataPopup: ModalComponent;
	@ViewChild("exportFilePopup") exportFilePopup: ModalComponent;
	@ViewChild("blockedSimsConfirmationPopup") blockedSimsConfirmationPopup: ModalComponent;
	@ViewChild("actionsConfirmationPopup") actionsConfirmationPopup: ModalComponent;

	columnConfig: ColumnsConfig[] = [];
	historyColumnModal: ColumnsConfig[] = [
		{ key: 'data_usage', name: this.translate.instant('sim_management.data_usage'), type: 'number'},
		{ key: 'usage_date', name: this.translate.instant('sim_management.usage_date'), type: 'date'},
		{ key: 'updating_fw', name: this.translate.instant('sim_management.updating_fw'), type: 'boolean'}
	];

	disabledSimsColumnsModal: ColumnsConfig[] = [
		{ key: 'id', name: 'id', type: 'id', hidden: true},
		{ key: 'mac_address', name: this.translate.instant('g.mac_address'), type: 'link'},
		{ key: 'serial_number', name: this.translate.instant('devices.serial_number'), type: 'string'},
		{ key: 'customer_name', name: this.translate.instant('g.customer_name'), type: 'link'},
		{ key: 'site_name', name: this.translate.instant('g.site_name'), type: 'link'},
		{ key: 'cellular_iccid', name: this.translate.instant('qv.cellular_iccid'), type: 'link'},
		{ key: 'last_disabled_time', name: this.translate.instant('sim.last_disabled_time'), type: 'date_with_ref'},
		{ key: 'disabled_count', name: this.translate.instant('sim_management.automatic_disable_count'), type: 'number', hasTooltip: this.translate.instant('sim_management.disabled_count_tooltip_msg') },
		{ key: 'disabled_source', name: this.translate.instant('sim.disabled_source'), type: 'string'},
	];

	blockedSimsColumnModal: ColumnsConfig[] = [
		{ key: 'id', name: 'id', type: 'id', hidden: true},
		{ key: 'serial_number', name: this.translate.instant('devices.serial_number'), type: 'link'},
		{ key: 'mac_address', name: this.translate.instant('g.mac_address'), type: 'string'},
		{ key: 'customer_name', name: this.translate.instant('g.customer_name'), type: 'link'},
		{ key: 'site_name', name: this.translate.instant('g.site_name'), type: 'link'},
		{ key: 'cellular_iccid', name: this.translate.instant('qv.cellular_iccid'), type: 'link'},
		{ key: 'last_block_time', name: this.translate.instant('sim_management.blocked_since'), type: 'date_with_ref'}
	];

	tableConfig: TableConfig = {
		hasExport: true,
		hasPagination: true,
		pageSize: 100,
		draggable: true,
		hasSelectionColumn: true,
		fileName: this.translate.instant('sim.sim_usage_management'),
		hasActionButtons: true,
		actionButtonsList: [
			{ text: this.translate.instant('g.enable'), action: () => this.showUpdateConfigPopup('enable') },
			{ text: this.translate.instant('g.disable'), action: () => this.showUpdateConfigPopup('disable') },
			{ text: this.translate.instant('g.lock'), action: () => this.showUpdateConfigPopup('lock') },
			{ text: this.translate.instant('g.unlock'), action: () => this.showUpdateConfigPopup('unlock') },

		]
	};

	historyTableConfig: TableConfig = {
		hasExport: true,
		hasPagination: false,
		draggable: true,
		fileName: this.translate.instant('sim.sim_usage_management')
	};

	disabledSimsTableConfig: TableConfig = {
		hasExport: false,
		hasPagination: false,
		draggable: true,
		hasSelectionColumn: true,
	};

	isHidePopupShown = false;
	draggedColumn = "";

	selectedDeviceData: any;
	toShowUsageHistory: any = [];
	devices: any = [];
	disabledSims: any = [];
	blockedSims: any = [];
	selectedICCIDs: number[] = [];
	selectedBlockedICCIDs: string[] = [];
	doneBlockICCIDs: string[] = [];
	blockActionDone: boolean = false;
	selectedDisabledICCIDs: string[] = [];
	sortResult: any[] = [];
	weeklyUsage: any = {};
	monthlyUsage: any = {};
	actionReason: string = '';

	activeTab = 'usage';
	maxDate: Date = moment().subtract(1, 'days').startOf('day').toDate();
	minDate: Date = new Date(new Date(2024, 2, 17).setHours(0, 0, 0, 0));

	invalidStartDate: boolean = false;
	disableBackCalender: boolean = false;
	disableNextCalender: boolean = false;
	simUsageDate = {
		from: null,
		to: null
	};

	dailyAveragePeriod = {
		lastWeek: 7,
		lastMonth: 30,
	};
	selectedDate: Date = moment().subtract(1, 'days').startOf('day').toDate();

	operation: 'enable' | 'disable' | 'lock' | 'unlock' | undefined;
	ICCIDs = [];

	unblockReason = '';
	currentPage: number = 1;
	toShowDevices: any = [];

	fromDate: Date = null;
	toDate: Date = null;

	popupFromData: Date = new Date();
	popupToData: Date = new Date();

	notValidDate = false;
	notValidPopupDate = false;

	simOverUsageLimit140K = 140 * 1024;
	simOverUsageLimit1M = 1024 * 1024;
	simOverUsageLimit3M = 1024 * 1024 * 3;
	simOverUsageLimit5M = 1024 * 1024 * 5;

	usageConfig = ['5M', '3M', '1M', '140K'];

	disabledSources = {
		1: 'sim.disable_system',
		2: 'sim.disable_1nce'
	};

	chartObject: any = {
	formatterType: 'DateFormat',
	formatter: {pattern: "MM/dd"},
		columnNames: ['Date', 'Data Usage'],
	};

	boolFields = ['imei_lock', 'updating_fw', 'debug_enabled', 'metered_device'];
	timeFields = ['last_connect_time', 'usage_date', 'expiry_date', 'installation_date'];
	stringFields = ['cellular_iccid', 'mac_address', 'serial_number', 'customer_name', 'site_name', 'imei', 'iccid_status', 'fw_version'];
	numberFields = ['weeklyUsage', 'monthlyUsage', 'usage', 'data_usage', 'reconnect_time', 'remaining_usage'];

	constructor(
		private router: Router,
		private userService: UsersService,
		private deviceService: DeviceService,
		private notificationMessage: NotificationMessageService,
		private commonService: CommonService,
		public translate: TranslateService,
		public route: ActivatedRoute,
		private domainsService: DomainsService,
	) {}

	ngOnDestroy(): void {
		this.blockedSimsConfirmationPopup.hide();
		this.tableDataPopup.hide();
		this.exportFilePopup.hide();
	}

	ngOnInit() {
		if (!this.userService.hasAccessPermission(null, 'noc', 'write'))
			this.router.navigate(['/unauthorized']);

		this.route.params.subscribe(
			params => {
				const pageName = params['pageName'];
				if (pageName)
					this.activeTab = pageName;
			}
		);
		if (this.domainsService.isDevelopmentDomain)
			this.tableConfig.pageSize = 20;

		//one day shift because we show yesterday usage
		this.popupToData.setDate(this.popupToData.getDate() - 1);
		this.popupFromData.setDate(this.popupFromData.getDate() - 31);

		this.datesChanged();
	}

	confirmUnblock() {
		this.unblockSims();
		return ;
	}

	ngAfterViewInit() {
		this.actionsConfirmationPopup.onClose.subscribe((ok) => {
			this.actionsConfirmationPopup.closeOnConfirm = true;
			if(!ok) {
				this.actionReason = '';
				return;
			}

			if(!this.actionReason) {
				this.actionsConfirmationPopup.closeOnConfirm = false;
				return;
			}

			this.updateSimConfig(this.operation);
			this.actionReason = '';
		});
	}

	fillSimUsageTableColumns() {
		let columns: ColumnsConfig[] = [
			{ key: 'id', name: 'id', type: 'id', hidden: true},
			{ key: 'serial_number', name: this.translate.instant('devices.serial_number'), type: 'link' },
			{ key: 'mac_address', 	name: this.translate.instant('g.mac_address'), type: 'string' },
			{ key: 'customer_name', name: this.translate.instant('g.customer_name'), type: 'link' },
			{ key: 'site_name', name: this.translate.instant('g.site_name'), type: 'link' },
			{ key: 'cellular_iccid', name: this.translate.instant('qv.cellular_iccid'), type: 'link' },
			{ key: 'data_usage', name: this.translate.instant('sim_management.data_usage'), type: 'number' },
			{ key: 'remaining_usage', name: this.translate.instant('sim_management.remaining_usage'), type: 'number', hidden: true },
		];

		if (this.isWeeklyCalculatedDataAvailable())
			columns.push({ key: 'weeklyUsage', name: this.translate.instant('sim_management.week_average'), type: 'number' });

		if (this.isMonthlyCalculatedDataAvailable())
			columns.push({ key:'monthlyUsage', name: this.translate.instant('sim_management.month_average'), type: 'number' });

		columns.push(
			{ key: 'updating_fw', name: this.translate.instant('sim_management.updating_fw'), type: 'boolean', filterTrueText: this.translate.instant('g.yes'), filterFalseText: this.translate.instant('g.no') },
			{ key: 'imei', name: this.translate.instant('sim.imei'), type: 'string', hidden: true },
			{ key: 'disabled_count', name: this.translate.instant('sim_management.automatic_disable_count'), type: 'number', hasTooltip: this.translate.instant('sim_management.disabled_count_tooltip_msg') },
			{ key: 'iccid_status', name: this.translate.instant('sim.iccid_status'), type: 'boolean', filterTrueText: this.translate.instant('sim.enable'), filterFalseText: this.translate.instant('sim.disable')},
			{ key: 'imei_lock', name: this.translate.instant('sim.imei_status'), type: 'boolean', filterTrueText: 'Locked', filterFalseText: 'Unlocked', hidden: true },
			{ key: 'fw_version', name: this.translate.instant('g.fw_version'), type: 'number' },
			{ key: 'debug_enabled', name: this.translate.instant('sim_management.debug_enabled'), type: 'boolean', filterTrueText: this.translate.instant('g.yes'), filterFalseText: this.translate.instant('g.no') },
			{ key: 'metered_device', name: this.translate.instant('sim_management.metered_device'), type: 'boolean', filterTrueText: this.translate.instant('g.yes'), filterFalseText: this.translate.instant('g.no') },
			{ key: 'last_connect_time', name: `${this.translate.instant('devices.last_connect_time')} (${this.commonService.showUserTimeZoneReference()})`, type: 'date', hidden: true },
			{ key: 'usage_date', name: this.translate.instant('sim_management.usage_date'), type: 'date', hidden: true },
			{ key: 'expiry_date', name: this.translate.instant('sim_management.expiry_date'), type: 'date', hidden: true},
			{ key: 'reconnect_time', name: this.translate.instant('devices.cellular_reconnect_time'), type: 'number' },
			{ key: 'installation_date', name: this.translate.instant('devices.installation_date'), type: 'date', hidden: true },
			{ key: 'usage_history', name: this.translate.instant('sim_management.usage_history'), type: 'icon'}
		);

		this.columnConfig = columns;
	}

	isWeeklyCalculatedDataAvailable() {
		const selectedDate = moment(this.selectedDate).utc().startOf('day').unix();
		return this.weeklyUsage[selectedDate] && Object.keys(this.weeklyUsage[selectedDate]).length;
	}

	isMonthlyCalculatedDataAvailable() {
		const selectedDate = moment(this.selectedDate).utc().startOf('day').unix();
		return this.monthlyUsage[selectedDate] && Object.keys(this.monthlyUsage[selectedDate]).length;
	}

	setActiveTab(tab: string) {
		this.activeTab = tab;
		switch (tab) {
			case this.deviceService.simManagementTabs.usage:
				return this.getSimDevicesData();
				break;
			case this.deviceService.simManagementTabs.disabled:
				if (!this.disabledSims.length)
					return this.getSimsDataByStatus(this.deviceService.simManagementTabs.disabled);
				break;

			case this.deviceService.simManagementTabs.blocked:
				if (!this.blockedSims.length)
					return this.getSimsDataByStatus(this.deviceService.simManagementTabs.blocked);
				break;
		}
	}

	formatSimsDataInfo() {
		this.toShowDevices = [];
		const toShowDevices = [];
		[...this.devices].forEach((row) => {
			row.expiry_date = this.formateUsageDate(row.expiry_date);
			row.usage_date = this.formateUsageDate(row.usage_date);

			let rowData: TableData = {
				row,
				id: {value: row.cellular_iccid},
				serial_number: {value: row.serial_number, link: ['/', row.customer_id, row.site_id, row.mac_address, 'performance']},
				mac_address: {value: row.mac_address},
				customer_name: {value: row.customer_name, link: ['/', row.customer_id]},
				site_name: {value: row.site_name, link: ['/', row.customer_id, row.site_id]},
				cellular_iccid: {value: row.cellular_iccid, link: ['/',row.customer_id, row.site_id, row.mac_address,'cellular-settings']},
				data_usage: {value: (row.data_usage / 1024).toFixed(2), backGroundColor: this.getClass(row)},
				remaining_usage: {value: (row.remaining_usage / 1024).toFixed(2)},
				updating_fw: {value: row.updating_fw, trueText: this.translate.instant('g.yes'), falseText: this.translate.instant('g.no')},
				imei: {value: row.imei},
				disabled_count: {value: row.iccid_disabled_count},
				iccid_status: {value: row.iccid_status == 'Enabled', trueText: this.translate.instant('sim.enable'), falseText: this.translate.instant('sim.disable')},
				imei_lock: {value: row.imei_lock, trueText: this.translate.instant('sim.lock'), falseText: this.translate.instant('sim.unlock')},
				fw_version: {value: row.fw_version || '-'},
				debug_enabled: {value: row.debug_enabled, trueText: this.translate.instant('g.yes'), falseText: this.translate.instant('g.no')},
				metered_device: {value: row.metered_device, trueText: this.translate.instant('g.yes'), falseText: this.translate.instant('g.no')},
				last_connect_time: {value: this.formateDate(row.last_connect_time, null, {notUtc: true})},
				usage_date: {value: row.usage_date},
				expiry_date: {value: this.formateDate(row.expiry_date, 'MM/DD/YYYY')},
				reconnect_time: {value: row.reconnect_time || '-'},
				installation_date: {value: this.formateDate(row.installation_date, 'MM/DD/YYYY'), sortValue: row.installation_date},
				usage_history: {icon: 'history', action: () => this.showUsageHistory(row), iconColor: 'green'}
			}

			if (this.isWeeklyCalculatedDataAvailable())
				rowData['weeklyUsage'] = {value: +((this.weeklyUsage[moment(this.selectedDate).utc().startOf('day').unix()][rowData.mac_address.value]?.average || 0) / 1024 ).toFixed(2)};

			if (this.isMonthlyCalculatedDataAvailable())
				rowData['monthlyUsage'] = {value: +((this.monthlyUsage[moment(this.selectedDate).utc().startOf('day').unix()][rowData.mac_address.value]?.average || 0) / 1024).toFixed(2)};

			toShowDevices.push(rowData);
		});

		this.toShowDevices = toShowDevices;
	}
	getSimDevicesData() {
		this.deviceService.getSimDevicesData(this.simUsageDate).subscribe((response: any) => {
			this.devices = response;
			this.formatSimsDataInfo();
		});
	}

	getSimsDataByStatus(status: string) {
		this.deviceService.getSimsDataByStatus(status).subscribe((data: any) => {
			if (status == this.deviceService.simManagementTabs.disabled) {
				const disabledSimsInfo = data.disabled;
				this.disabledSims = [];
				const disabledSimInfo = [];
				[...disabledSimsInfo].forEach((record) => {
					let records: TableData = {
						record,
						id: {value: record.mac_address},
						mac_address: {value: record.mac_address, link: ['/', record.customer_id, record.site_id, record.mac_address, 'performance']},
						serial_number: {value: record.serial_number},
						customer_name: {value: record.customer_name, link: ['/', record.customer_id]},
						site_name: {value: record.site_name, link: ['/', record.customer_id, record.site_id]},
						cellular_iccid: {value: record.cellular_iccid, link: ['/',record.customer_id, record.site_id, record.mac_address, 'cellular-settings']},
						last_disabled_time: {value: this.formateDate(record.last_disabled_time, 'MM/DD/YYYY hh:mm:ss A')},
						disabled_count: {value: record.iccid_disabled_count },
						disabled_source: {value: this.translate.instant(this.disabledSources[record.disabled_source] || '---')}
					}
					disabledSimInfo.push(records);
				});
				this.disabledSims = disabledSimInfo;
			}

			if (status == this.deviceService.simManagementTabs.blocked) {
				const blockedSimsInfo = data.blocked;
				this.blockedSims = [];
				const blockedSimInfo = [];
				[...blockedSimsInfo].forEach((row) => {
					let rowData: TableData = {
						row,
						id: {value: row.mac_address},
						serial_number: {value: row.serial_number, link: ['/', row.customer_id, row.site_id, row.mac_address, 'performance']},
						mac_address: {value: row.mac_address},
						customer_name: {value: row.customer_name, link: ['/', row.customer_id]},
						site_name: {value: row.site_name, link: ['/', row.customer_id, row.site_id]},
						cellular_iccid: {value: row.cellular_iccid, link: ['/',row.customer_id, row.site_id, row.mac_address, 'cellular-settings']},
						last_block_time: {value: row.last_block_time != '---'? this.formateDate(row.last_block_time, 'MM/DD/YYYY hh:mm:ss A') : '---'}
					}
					blockedSimInfo.push(rowData);
				});
				this.blockedSims = blockedSimInfo;
			}
		});
	}

	handleCalendarButtons(forward: boolean) {
		if (forward)
			this.selectedDate  =  new Date(this.selectedDate.getTime() + this.deviceService.oneDaySeconds*1000);
		else
			this.selectedDate  =  new Date(this.selectedDate.getTime() - this.deviceService.oneDaySeconds*1000);

		if (moment(this.maxDate).utc().startOf('day').unix() == moment(this.selectedDate).utc().startOf('day').unix())
			this.disableNextCalender = true;
		else
			this.disableNextCalender = false;

		if (moment(this.minDate).utc().startOf('day').unix() == moment(this.selectedDate).utc().startOf('day').unix())
			this.disableBackCalender = true;
		else
			this.disableBackCalender = false;

		return this.datesChanged();
	}

	datesChanged() {
		if (!this.selectedDate)
			return this.invalidStartDate = true;

		const zoneDiff = new Date().getTimezoneOffset() * -1;
		const from: any = new Date(new Date(this.selectedDate).getTime() + (zoneDiff * 60 * 1000));
		const to: any = new Date(new Date(this.selectedDate).getTime() + (zoneDiff * 60 * 1000));
		this.simUsageDate.from = moment(from).startOf('day').unix();
		this.simUsageDate.to = moment(to).endOf('day').unix();

		if (this.simUsageDate.from == moment(this.maxDate).startOf('day').unix())
			this.disableNextCalender = true;
		else
			this.disableNextCalender = false;

		if (this.simUsageDate.from == moment(this.minDate).startOf('day').unix())
			this.disableBackCalender = true;
		else
			this.disableBackCalender = false;

		this.fillSimUsageTableColumns();
		return this.setActiveTab(this.activeTab);
	}

	calculateDailyAvgData(period) {
		this.deviceService.getSimDailyAverageData(period).subscribe((res: any) => {
			if (period == this.dailyAveragePeriod.lastWeek)
				this.weeklyUsage[moment(this.selectedDate).utc().startOf('day').unix()] = res.weeklyUsage;

			if (period == this.dailyAveragePeriod.lastMonth)
				this.monthlyUsage[moment(this.selectedDate).utc().startOf('day').unix()] = res.monthlyUsage;

			this.fillSimUsageTableColumns();
			this.formatSimsDataInfo();
		});
	}

	sortByDataAndUsage(DataArray) {
		DataArray.sort((a, b) => {
			const aTime = moment(a.usage_date);
			const bTime = moment(b.usage_date);

			if (aTime.isSame(bTime, 'day')) {
				return b.data_usage - a.data_usage;
			}

			return bTime.unix() - aTime.unix();
		});

		return DataArray;
	}

	formateDate(date: moment.MomentInput, format = 'MM/DD/YYYY hh:mm:ss a', options: any = {}) {
		if (options.notUtc)
			return date && date != '---' ? moment(date).format(format || 'MM/DD/YYYY hh:mm:ss a') : '---';

		return date && date != '---' ? moment(date).utc().format(format || 'MM/DD/YYYY hh:mm:ss a') : '---';
	}

	formateUsageDate(date: moment.MomentInput) {
		return date && date != '---' ? moment(date).format('MM/DD/YYYY') : '---';
	}

	prepareChartData(usageHistory) {
		const data = [];

		if (!usageHistory.length)
			return [];

		usageHistory.forEach(dayData => {
			const usage = +(dayData.data_usage / 1024).toFixed(0);
			const formattedTime = moment(dayData.usage_date).utc().format('D MMM');
			data.push([formattedTime, usage]);
		})

		return data;
	}

	showUsageHistory(deviceData, openPopup = true) {
		const toShowUsageHistory = [];
		let zoneDiff = new Date().getTimezoneOffset() * -1;

		let from:any = new Date(new Date(this.popupFromData).getTime() + (zoneDiff * 60 * 1000));
		let to:any = new Date(new Date(this.popupToData).getTime() + (zoneDiff * 60 * 1000));

		from = moment(from).utc().startOf('day').unix();
		to = moment(to).utc().endOf('day').unix();

		if (this.popupFromData && from > to)
			return this.notValidPopupDate = true;

		this.notValidPopupDate = false;

		this.deviceService.getSimUsageHistory(deviceData.mac_address, from, to).subscribe((response: any) => {
			this.selectedDeviceData = deviceData;
			const deviceUsageHistoryData = this.sortByDataAndUsage(response);
			const data = deviceUsageHistoryData.length ? this.prepareChartData(deviceUsageHistoryData) : [];

			this.chartObject.data = data.reverse();
			[...deviceUsageHistoryData].forEach((row) => {
				let rowData: TableData = {
					row,
					data_usage:  {value: +(row.data_usage / 1024).toFixed(2), backGroundColor: this.getClass(row)},
					usage_date:  {value: this.formateUsageDate(row.usage_date)},
					updating_fw: {value: row.updating_fw, trueText: this.translate.instant('g.yes'), falseText: this.translate.instant('g.no')}
				}
				toShowUsageHistory.push(rowData);
			});

			this.toShowUsageHistory = toShowUsageHistory;
			if (openPopup)
				this.tableDataPopup.show();
		})
	}

	showUpdateConfigPopup(operation: 'enable' | 'disable' | 'lock' | 'unlock', fromPopup=false) {
		this.operation = operation;
		this.ICCIDs = fromPopup ? this.selectedDisabledICCIDs : this.selectedICCIDs;
		this.actionsConfirmationPopup.show();
	}

	updateSimConfig(operation: 'enable' | 'disable' | 'lock' | 'unlock') {
		this.deviceService.updateSimConfig(this.ICCIDs, operation, this.actionReason).subscribe((res: any) => {
			if (res.api_status == 2)
				return this.notificationMessage.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});

			return this.notificationMessage.setMessage('translate|sim_management.action_will_applied_tomorrow', {clearOnXTimeNavigate: 1, type: 'success'});
		});
	}

	unblockSims() {
		let ICCIDs = this.selectedBlockedICCIDs;

		if (!ICCIDs.length)
			return;

		this.deviceService.unblockSims(ICCIDs, this.unblockReason).subscribe((res: any) => {
			if (res.api_status == 2)
				return this.notificationMessage.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});

			this.blockActionDone = true;
			this.doneBlockICCIDs = res;

			return this.notificationMessage.setMessage('translate|sim_management.action_will_applied_tomorrow', {clearOnXTimeNavigate: 1, type: 'success'});
		});
	}

	get shouldShowSimActions() {
		return this.userService.hasAccessPermission(null, 'noc', 'write');
	}

	selectICCID(rows) {
		this.selectedICCIDs = _.uniq(rows.map(item => item.cellular_iccid.value));
	}

	selectBlockedICCID(rows) {
		this.selectedBlockedICCIDs = _.uniq(rows.map(item => item.cellular_iccid.value));
	}

	selectDisabledICCID(rows) {
		this.selectedDisabledICCIDs = _.uniq(rows.map(item => item.cellular_iccid.value));
	}

	showBlockedSimsConfirmationPopup() {
		this.unblockReason = '';
		this.blockActionDone = false;
		this.blockedSimsConfirmationPopup.show();
	}

	checkDateValidation() {
		if (this.fromDate && this.toDate && moment(this.fromDate).unix() > moment(this.toDate).unix())
			this.notValidDate = true;
		else
			this.notValidDate = false;
	}

	getClass(row) {
		const colorLevel = {
			1: '#ffff00',
			3: '#ff9900',
			5: '#ff0000',
			50: '#e20aea',
			140: '#2783ca'
		}
		return colorLevel[row.log_level];
	}

	getTabUrl(tab: string): string {
		return `/admin-panel/sim-usage-management/${tab}`;
	}
}
