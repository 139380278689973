import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { UsersService } from 'src/app/users/users.service';
import { SitesService } from 'src/app/sites/sites.service';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ColumnsConfig, TableConfig, TableData} from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';

@Component({
	selector: 'app-site-warranty',
	templateUrl: './site-warranty.component.html',
	styleUrls: ['./site-warranty.component.css']
})
export class SiteWarrantyComponent implements OnInit, OnDestroy {
	// table data
	tableData: TableData[] = [];

	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: true,
		pageSize: 100,
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'serial_number', name: this.translateService.instant('devices.device_sn'), type: "link"},
		{ key: 'truck_sn', name: this.translateService.instant('devices.power_view_sn'), type: 'string' },
		{ key: 'warranty', name: this.translateService.instant('site.device_warranty'), type: "string" },
	];

	siteId: number = 0;
	customerId: number = 0;
	subSite: Subscription = new Subscription();
	permissionSub: Subscription = new Subscription();

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private translateService: TranslateService,
		private sideMenuService: SideMenuService,
		private usersService: UsersService,
		private sitesService: SitesService,
		private deviceService: DeviceService,
		private common: CommonService
	) { }

	ngOnInit() {
		this.subSite = this.sideMenuService.currentSite.subscribe((data: any) => {
			if (!data || !data['id'])
				return;
			let siteId = Number(this.route.parent.snapshot.params['siteId']);
			let customerId = Number(this.route.parent.snapshot.params['customerId']);

			if (data.id != siteId || data['customer_id'] != customerId)
				return;

			this.siteId = siteId;
			this.customerId = customerId;

			this.permissionSub = this.usersService.getPermissions(siteId).subscribe((permissions: any) => {
				if(!this.usersService.userHasNOCAccess(permissions))
					return this.router.navigate(['/unauthorized']);
			});

			this.getSiteDevicesWarranty(siteId);
		});
	}

	getSiteDevicesWarranty(siteId) {
		this.sitesService.getSiteDevicesWarranty(siteId).subscribe((res: any) => {
			const tableData = [];
			[...res].forEach((device) => {
				device._device_link = ["/", this.customerId, this.siteId, device.mac_address, 'performance'];
				device.warranty = this.common.getRemainingPeriod(device.shipping_date, device.warranty_years || this.deviceService.deviceWarranty);

				if(!device.warranty)
					device.warranty = this.translateService.instant('site.expired');

				tableData.push({
					serial_number: {value: device.serial_number, link: device.is_delete ? null : device._device_link},
					truck_sn: {value: device.truck_sn},
					warranty: {value: device.warranty, sortValue: device.shipping_date},
				})
			});

			this.tableData = tableData;
		});
	}

	ngOnDestroy() {
		this.subSite.unsubscribe();
		this.permissionSub.unsubscribe();
	}
}
