import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { SitesService } from 'src/app/sites/sites.service';
import { UsersService } from 'src/app/users/users.service';
import _ from 'lodash';
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CommonDataService } from 'src/app/shared/services/common-data.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { ColumnsConfig, TableConfig, TableData} from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';

type IncompleteDevicesPopupData = {
	site_id: number,
	customer_id: number,
	customer_name: string,
	site_name: string,
	devices: {mac_address: string, serial_number: string}[]
};

@Component({
	selector: 'app-smart-rebates-sites',
	templateUrl: './smart-rebates-sites.component.html',
	styleUrls: ['./smart-rebates-sites.component.css']
})
export class SmartRebatesSitesComponent implements OnInit {
	@ViewChild("consumptionsReportDialog") consumptionsReportDialog: any;
	@ViewChild("bulkExportConsumptionsReportDialog") bulkExportConsumptionsReportDialog: any;
	@ViewChild("defectiveDevicesModal") defectiveDevicesModal: ModalComponent;
	@ViewChild("incompleteDevicesDataModal") incompleteDevicesDataModal: ModalComponent;

	downloadConsumptionsReportSubscription!: Subscription;
	selectedYear: number = new Date().getFullYear();
	selectedSiteIds: number[] = [];
	isValidDate = true;

	incompleteDevicesPopupData: IncompleteDevicesPopupData = {site_id: 0, customer_id: 0, customer_name: '', site_name: '', devices: []};

	quarters = [
		{id: 1, value:'Q1'},
		{id: 2, value:'Q2'},
		{id: 3, value:'Q3'},
		{id: 4, value:'Q4'}
	];

	selectedQuarter = this.quarters[0].id;
	years = [];
	selectedStartDate: any = 0;

	data: any[] = [];

	MONTHS_LIST = this.commonDataService.MONTHS_LIST;
	selectedSitesToExport = [];
	periodTypes = {
		yearly: 0,
		quarterly: 1,
		monthly: 2,
	};
	periodTypesItems = [
		{ id: this.periodTypes.yearly, value: this.translate.instant('g.yearly') },
		{ id: this.periodTypes.quarterly, value: this.translate.instant('g.quarterly') },
		{ id: this.periodTypes.monthly, value: this.translate.instant('g.monthly') },
	];
	selectedPeriod = this.periodTypesItems[0].id;
	monthlyPeriod: { from: { year: number, month: number }, to: { year: number, month: number } } = {
		from: { year: null, month: null },
		to: { year: null, month: null },
	};

	selectedReport = null;

	columnConfig: ColumnsConfig[] = [
		{ key: 'id', name: 'id', type: 'id', hidden: true},
		{ key: 'customer_name', name: this.translate.instant('g.customer_name'), type: 'link' },
		{ key: 'site_name', name: this.translate.instant('g.site_name'), type: 'link' },
		{ key: 'location', name: this.translate.instant('g.device_location'), type: 'string' },
		{ key: 'incomplete_data_devices_count', name: this.translate.instant('site.incomplete_data_devices_count'), type: 'number_link' },
		{ key: 'included_in_report', name: this.translate.instant('smart_rebates_reports.included_in_reports'), type: 'number' },
		{ key: 'defective_devices', name: this.translate.instant('smart_rebates_reports.defective_devices'), type: 'number_link' },
		{ key: 'installation_date', name: this.translate.instant('site.site_installation_date'), type: 'timestamp', dateFormat: 'MM/DD/YYYY', hidden: true },
		{ key: 'consumptions_report', name: this.translate.instant('nav.consumptions_report'), type: 'icon' }
	];

	tableConfig: TableConfig = {
		hasPagination: false,
		hasSelectionColumn: true,
	};

	constructor(
		private router: Router,
		private sitesService: SitesService,
		private usersService: UsersService,
		private sideMenuService: SideMenuService,
		private notificationMessage: NotificationMessageService,
		public translate: TranslateService,
		public commonDataService: CommonDataService,
		private commonService: CommonService
	) { }

	ngOnInit() {
		this.sideMenuService.hide();
		if (!(this.usersService.hasAccessFunction('official_reporting') || this.usersService.hasAccessFunction('site_management')))
			return this.router.navigate(['/unauthorized']);
		return this.getSmartRebatesSitesInfo();
	}

	ngAfterViewInit() {

		this.bulkExportConsumptionsReportDialog.onClose.subscribe((result: any) => {
			if (result) {
				this.downloadConsumptionsReport();
			}
		});
	}

	getSmartRebatesSitesInfo() {
		this.data = [];
		this.sitesService.getSmartRebatesSitesInfo().subscribe((data: any) => {
			const tableData = [];
			const sortedData: any = this.commonService.sortDataAlphabetically(data, 'customer_name');
			[...sortedData].forEach((row) => {
				let rowData: TableData = {
					row,
					id: {value: row.id},
					customer_name: { value: row.customer_name, link: ['/', row.customerId] },
					site_name: { value: row.name, link: ['/', row.customerId, row.id] },
					location: { value: row.location },
					incomplete_data_devices_count: {
						value: row.incomplete_devices_data.length ?  row.incomplete_devices_data.length : '0',
						action: row.incomplete_devices_data.length ? () => {this.showIncompleteDevicesPopup(row)} : null
					},
					included_in_report: { value: row.included_in_reports_count },
					defective_devices: {
						value: !row.defective_devices_list.length ? '0' : row.defective_devices_list.length,
						action:  row.defective_devices_list.length ? () => {this.openDefectiveDevicesModal(row)} : null
					},
					installation_date: { value: row.installation_date*1000 },
					consumptions_report: { icon: 'download', action: () => this.bulkExportConsumptionsReport(row) }
				};
				tableData.push(rowData);
			});

			this.data = tableData;
		})
	}

	bulkExportConsumptionsReport(selectedSite = null) {
		let installationDates = [];
		this.selectedSiteIds = [];

		if(selectedSite) {
			this.selectedSiteIds = [+selectedSite.id];
			installationDates = [moment(selectedSite.installation_date * 1000).unix()];
		} else {
			for (const site of this.selectedSitesToExport) {
				this.selectedSiteIds.push(site.id);
				installationDates.push(moment(site.installation_date * 1000).unix());
			}
		}

		const bulkInstallationDate = _.min(installationDates);
		const startDate = new Date(bulkInstallationDate * 1000);
		this.getAllYearsFromDate(startDate);
		this.bulkExportConsumptionsReportDialog.show();
	}

	getAllYearsFromDate(startDate) {
		if (!startDate)
			return

		const currentDate = new Date();
		const startYear = startDate.getFullYear();
		const currentYear = currentDate.getFullYear();
		const years = [];

		for (let year = startYear; year <= currentYear; year++) {
			years.push(year);
		}

		if (!years.length)
			years.push(currentYear);

		this.selectedYear = years[years.length -1];
		this.years = years;
	}

	getReportConsumptionsPeriod() {
		let fromTime: number, toTime: number = 0;
		switch (this.selectedPeriod) {
			case this.periodTypes.yearly:
				fromTime = moment.utc(this.selectedYear, 'YYYY').startOf('year').utc().unix();
				toTime = moment.utc(this.selectedYear, 'YYYY').endOf('year').utc().unix();
				break;
			case this.periodTypes.quarterly:
				fromTime = moment.utc(this.selectedYear, 'YYYY').quarter(this.selectedQuarter).startOf('quarter').utc().unix();
				toTime = moment.utc(this.selectedYear, 'YYYY').quarter(this.selectedQuarter).endOf('quarter').utc().unix();
				break;
			case this.periodTypes.monthly:
				const zoneDiff = (new Date().getTimezoneOffset() * -1) * 60;
				fromTime = moment().year(this.monthlyPeriod.from.year).month(this.monthlyPeriod.from.month - 1).startOf('month').utc().unix() + zoneDiff;
				toTime = moment().year(this.monthlyPeriod.to.year).month(this.monthlyPeriod.to.month - 1).endOf('month').utc().unix() + zoneDiff;
				break;
		}

		return { fromTime, toTime };
	}

	checkIsValidDate() {
		this.isValidDate = true;
		const from = this.monthlyPeriod.from;
		const to = this.monthlyPeriod.to;

		if (this.selectedPeriod == this.periodTypes.monthly &&  (
				(!from.year || !from.month || !to.year || !to.month) || // not all field has value
				(from.year > to.year || (from.year == to.year && from.month > to.month)) // from > to
			)
		)
			this.isValidDate = false;
	}

	downloadConsumptionsReport() {
		const selectedStartDate = moment(this.selectedStartDate || 0).utc().startOf('day').unix() || 0;

		const { fromTime, toTime } = this.getReportConsumptionsPeriod();

		this.downloadConsumptionsReportSubscription = this.sitesService.downloadConsumptionsReport(fromTime, toTime, this.selectedSiteIds, selectedStartDate).subscribe((res: any) => {
			switch (res.api_status) {
				case 2:
					this.notificationMessage.setMessage(this.translate.instant('orders.not_sr_site'));
					break;
				case 3:
					this.notificationMessage.setMessage(this.translate.instant('orders.no_devices_founded'));
					break;
				case 4:
					this.notificationMessage.setMessage(this.translate.instant('smart_rebates_reports.no_data'));
					break;
				default:
					this.downloadReport(res);
					break;
			}
		});
	}

	downloadReport(data: any) {
		if (!data) return;
		const arr = new Uint8Array(data.content.data);
		const blob = new Blob([arr]);
		if (blob)
			saveAs(blob, data.name);
	}

	selectSite(sites: any) {
		this.selectedSitesToExport = sites.length ? sites.map(record => ({id: +record.row.id, installation_date: record.row.installation_date})) : [];
	}

	openDefectiveDevicesModal(report: any) {
		this.selectedReport = report;
		this.defectiveDevicesModal.show();
	}

	showIncompleteDevicesPopup(row) {
		this.incompleteDevicesPopupData = {
			site_id: row.id,
			customer_id: row.customerId,
			customer_name: row.customer_name,
			site_name: row.name,
			devices: row.incomplete_devices_data
		};

		this.incompleteDevicesDataModal.show();
	}

	exportIncompleteDevicesList() {
		const columnNames = [this.translate.instant("devices.unique_mac_address"), this.translate.instant("devices.serial_number")];
		const fileName = `${this.incompleteDevicesPopupData.customer_name}_${this.incompleteDevicesPopupData.site_name}_incomplete_devices_list.csv`;
		const scvData = [];

		this.incompleteDevicesPopupData.devices.forEach(device => {
			scvData.push([device.mac_address, device.serial_number]);
		});

		this.commonService.exportToCsv(columnNames, scvData, fileName);
	}

	ngOnDestroy() {
		if (this.downloadConsumptionsReportSubscription)
			this.downloadConsumptionsReportSubscription.unsubscribe();

		this.incompleteDevicesDataModal.hide();
		this.defectiveDevicesModal.hide();
	}
}
