<form #contactUs="ngForm" (ngSubmit)="logContactUs()" class="before-login-form">
	<div class="row mt-2 align-items-center">
		<div class="col-md-3">
			<span>{{'contact_us.subject' | translate}}</span>
			<span class="ml-1">*</span>
		</div>
		<div class="col-md-9">
			<input
				class="form-control"
				name="subject"
				[(ngModel)]="form.subject"
				placeholder="{{'contact_us.subject' | translate}}"
				required
				[ngClass]="{ 'is-invalid': submitted && contactUs.form.controls['subject']?.errors }"
			/>
		</div>
	</div>
	<div class="row mt-2">
		<div class="col-md-3">
			<span>{{'contact_us.your_enquiry' | translate}}</span>
			<span class="ml-1">*</span>
		</div>
		<div class="col-md-9">
			<textarea
				required
				rows="3"
				class="form-control"
				[(ngModel)]="form.body"
				name="body" placeholder="{{'contact_us.your_enquiry' | translate}}"
				[ngClass]="{ 'is-invalid': submitted && contactUs.form.controls['body']?.errors }"
			></textarea>
		</div>
	</div>
	<div class="row mt-2 align-items-center" *ngIf="captchaEnabled">
		<div class="col-md-12">
			<app-captcha #captchaComponent [textFieldCols]="5"></app-captcha>
		</div>
	</div>
	<div class="row mt-3 align-items-center">
		<div class="col-md-12">
			<button
				type="submit"
				class="btn text-white main-blue-bg btn-lg col-5 mr-4 green-btn"
				[disabled]="contactUs.invalid || captchaEnabled && (!captchaComponent || !captchaComponent.captchaText || captchaComponent.triesLimitReached)"
			>
				{{'g.submit' | translate}}
			</button>
			<button type="button" class="btn btn-light btn-lg col-5" (click)="goBack()">{{'g.back' | translate}}</button>
		</div>
	</div>
</form>
