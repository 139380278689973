<div *ngIf="siteDevices.length && !isStudyDevice">
	<div class="row mx-3">
		<div class="d-flex align-items-center">
			<div class="device-details-split mx-3"></div>
			<div *ngIf="device.config_info?.ip_address" class="device-details">
				<div class="font-weight-bold">
					{{'devices.public_ip' | translate}}
				</div>
				<div>
					{{device.config_info.ip_address}}
				</div>
			</div>
			<div *ngIf="device.config_info?.ip_address" class="device-details-split mx-3"></div>
			<div *ngIf="isTabletOrMobile">
				<div class="search-container d-flex flex-column">
					<div class="devices-list">
						<ng-select
							[items]="siteDevices"
							[searchable]="!commonService.isTabletOrMobileScreen"
							bindLabel="prettyName"
							bindValue="mac_address"
							placeholder="{{'devices.select_device' | translate}}"
							name="deviceID"
							class="multi-line-options"
							[(ngModel)]="deviceID"
							[clearable]="false"
							(ngModelChange)="deviceChanged()"
						></ng-select>
					</div>
					<div *ngIf="deviceLastConnectTime" class="device-details-split-horizontal"></div>
					<div *ngIf="deviceLastConnectTime" class="device-details">
						<div class="font-weight-bold">
							{{'devices.last_connect_time' | translate}} ({{showUserTimeZoneReference()}})
						</div>
						<div>
							{{getDeviceLastConnectTime}}
						</div>
					</div>
			</div>
			</div>
			<div *ngIf="deviceLastConnectTime && !isTabletOrMobile" class="device-details">
				<div class="font-weight-bold">
					{{'devices.last_connect_time' | translate}} ({{showUserTimeZoneReference()}})
				</div>
				<div>
					{{getDeviceLastConnectTime}}
				</div>
			</div>
			<div *ngIf="deviceLastConnectTime && !isTabletOrMobile" class="device-details-split mx-3"></div>
			<div class="devices-list" *ngIf="!isTabletOrMobile">
				<ng-select
					[items]="siteDevices"
					bindLabel="prettyName"
					bindValue="mac_address"
					placeholder="{{'devices.select_device' | translate}}"
					name="deviceID"
					class="multi-line-options"
					[(ngModel)]="deviceID"
					[clearable]="false"
					(ngModelChange)="deviceChanged()"
				></ng-select>
			</div>
			<app-device-actions
				[device]="device"
				[changesStack]="changesStack"
				[simInfo]="simInfo"
				[enterprisePermissions]="enterprisePermissions"
				(changePage)="goToPage($event)"
				(refreshDevice)="refreshDevice()"
				*ngIf="!multiDevices"
			></app-device-actions>
		</div>
	</div>
</div>
