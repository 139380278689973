<div id="sidebar-wrapper" [@enterAnimation] *ngIf="!hideMenuView">
	<ul class="sidebar-nav m-2">
		<div class="form-group">
			<input id="search-filter" name="tv-side-search" [(ngModel)]="searchText" (ngModelChange)="applySearchFilter()" offset="15" placeholder="Search/Filter" class="form-control"/>
		</div>
		<div class="form-group" *ngIf="usersService.hasAccessPermission(null, 'noc')">
			<ng-select
				[searchable]="false"
				[items]="enterprisesList"
				[multiple]="true"
				bindLabel="name"
				placeholder="{{'ent.select_enterprise' | translate}}"
				name="enterprise_id"
				[(ngModel)]="filteredEnterprise"
				(ngModelChange)="applySearchFilter()"
			></ng-select>
		</div>
		<div class="form-group mt-1" *ngIf="usersService.hasAccessPermission(null, 'noc')">
			<ng-select
				[searchable]="false"
				[items]="sitesToHide"
				[multiple]="true"
				bindLabel="name"
				placeholder="{{'g.hide_sites' | translate}}"
				[(ngModel)]="hideSites"
				(ngModelChange)="applySearchFilter()"
			></ng-select>
		</div>
		<div class="form-group mt-1" *ngIf="currentUser.is_admin">
			<ng-select
				[searchable]="false"
				[items]="smartRebates"
				bindLabel="label"
				bindValue="value"
				placeholder="{{'g.show_smart_rebate_site' | translate}}"
				[(ngModel)]="showSmartRebateSites"
				(ngModelChange)="applySearchFilter()"
			></ng-select>
		</div>
		<div class="form-group mt-1">
			<ng-select
				[searchable]="false"
				[items]="devicesTypeList"
				bindLabel="label"
				bindValue="value"
				placeholder="{{'g.select_device_type' | translate}}"
				[(ngModel)]="showSitesRelatedDevicesType"
				[multiple]="true"
				(ngModelChange)="applySearchFilter()"
			></ng-select>
		</div>
		<div class="form-group mt-1">
			<div class="location-filter">
				<div class="selected-options" *ngIf="selectedLocations.length" [ngClass]="{'grow': selectedLocations.length}">
					<div class="option" title="{{formateTitle(selectedLocations[0])}}">
							<span>{{formatLocation(selectedLocations[0])}}</span>
							<fa-icon class="icon" icon="times" size="lg" (click)="removeFilterOption(selectedLocations[0])"></fa-icon>
					</div>
					<div class="option" *ngIf="selectedLocations.length > 1" title="{{formateTitle(selectedLocations[1])}}">
							<span>{{formatLocation(selectedLocations[1])}}</span>
							<fa-icon class="icon" icon="times" size="lg" (click)="removeFilterOption(selectedLocations[1])"></fa-icon>
					</div>
					<div class="option" *ngIf="selectedLocations.length > 2" (click)="showAllOptions = !showAllOptions">
							<span class="number">+{{selectedLocations.length - 2}}</span>
					</div>
				</div>
				<div class="field-container">
					<input type="text" name="location_filter" id="location_filter" [(ngModel)]="locationFilterString" placeholder="{{'site.location_filter' | translate}}" (input)="filterLocation()">
					<fa-icon class="icon" icon="map-marker-alt" size="lg"></fa-icon>
				</div>
			</div>
		</div>
		<div class="form-group mt-1">
			<div class="location-filter-options" *ngIf="selectedLocations.length && showAllOptions" [ngClass]="{'grow': selectedLocations.length && showAllOptions}">
				<div class="option" *ngFor="let location of selectedLocations; let i = index">
					<fa-icon class="icon" icon="times" size="lg" (click)="removeFilterOption(selectedLocations[i])"></fa-icon>
					<span>{{location.state}}</span><span *ngIf="location.state != ''">,</span><span>{{location.country}}</span>
				</div>
			</div>
		</div>
		<div class="form-group mt-1">
			<div class="location-filter-options" *ngIf="filteredLocationOptions.length" [ngClass]="{'grow': filteredLocationOptions.length}">
				<div class="option" *ngFor="let location of filteredLocationOptions" (click)="addToSelectedLocations(location)">
					<fa-icon class="icon" icon="map-marker-alt" size="lg"></fa-icon>
					<span>{{location.state}}</span><span *ngIf="location.state != ''">,</span><span>{{location.country}}</span>
				</div>
			</div>
		</div>
		<li
			class="top-level-item"
			*ngFor="let customer of customers">
			<a
				class="pointer"
				[ngClass]="{'active': customer.id == currentCustomer.id, 'expanded': customer.expanded}"
				(click)="onSelectCustomer(customer)"
			>
				<span class="ml-3">
					<i
						class="fa mr-2"
						[ngClass]="{'fa-plus-circle': !customer.expanded, 'fa-minus-circle': customer.expanded}"
						(click)="$event.stopPropagation();toggleCustomerExpand(customer);"
						aria-hidden="true"></i>
					<span>
						<i *ngIf="currentUser.isSCT && customer.original_enterprise" class="fa fa-star star mx-2"></i>
					</span>
					<a [routerLink]="[customer.id]" (click)="onSelectCustomer(customer); commonService.handleLinkClickEvents($event);">{{customer.customer_name}}</a>
				</span>
				<span class="mr-3 ml-auto">
					<i
						class="fa align-text-top font-size-12"
						[ngClass]="{'fa-chevron-down': !customer.expanded, 'fa-chevron-up': customer.expanded}"
						(click)="$event.stopPropagation();toggleCustomerExpand(customer);"
						aria-hidden="true"></i>
				</span>
			</a>
			<ul
				 *ngIf="customer.expanded"
				 class="sidebar-nav sub-items ">
				<li [ngClass]="{'active': site.id === currentSite.id}"
					*ngFor="let site of getCustomerSites(customer)"
					class="pointer site-item"
				>
					<a [routerLink]="[site.customer_id, site.id]" (click)="selectSite($event, site); commonService.handleLinkClickEvents($event);" >
						<span class="star-span">
							<i *ngIf="currentUser.isSCT && site.original_enterprise && site.is_auto_generated" class="fa fa-star star"></i>
						</span>
						{{site.name}}
					</a>
				</li>
			</ul>
		</li>
	</ul>
</div>
