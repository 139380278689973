<div class="row justify-content-md-center">
	<div class="col-md-12">
		<a class="btn btn-primary btn-lg pointer pull-right text-white" [routerLink]="['/user', 'edit', 0]" *ngIf="usersService.hasAccessFunction('add_user_admin')">{{'g.add' | translate}} {{'g.user' | translate}}</a>
		<form #searchUserForm="ngForm" (ngSubmit)="getUsersList()">
			<legend>
				{{'g.search' | translate}} {{'g.user' | translate}}
			</legend>
			<div class="mt-3">
				<div>
					<input placeholder="{{'users.user_id' | translate}}" type="number" class="form-control" name="id" [(ngModel)]="criteria.id"/>
				</div>
				<div class="mt-2">
					<input placeholder="{{'login_register.first_name' | translate}}" type="text" class="form-control" name="first_name" [(ngModel)]="criteria.first_name"/>
				</div>
				<div class="mt-2">
					<input placeholder="{{'login_register.last_name' | translate}}" type="text" class="form-control" name="last_name" [(ngModel)]="criteria.last_name"/>
				</div>
				<div class="mt-2">
					<input placeholder="{{'login_register.email' | translate}}" type='email' class="form-control" name="email" [(ngModel)]="criteria.email"/>
				</div>
				<div class="mt-2" *ngIf="currentUser.is_admin">
					<input placeholder="{{'login_register.domain' |translate}}" type="text" class="form-control" name="domain" [(ngModel)]="criteria.domain">
				</div>
				<div class="mt-2">
					<ng-select [items]="enterpriseList" bindLabel="name" bindValue="id" placeholder="{{'ent.select_enterprise' | translate}}" name="enterprise_id" [(ngModel)]="criteria.enterprise_id"></ng-select>
				</div>
				<div class="mt-2">
					<ng-select [multiple]="true" [items]="email_subscription_list" bindLabel="name" bindValue="id" placeholder="{{'users.email_subscription_list' | translate}}" name="email_subscription_list" [(ngModel)]="criteria.selected_subscriptions"></ng-select>
				</div>
				<div class="mt-2">
					<button type="submit" class="btn btn-primary pointer" [disabled]="isEmptyCriteria()">{{'g.search' | translate}}</button>
				</div>
			</div>
		</form>
	</div>
</div>

<div class="mt-5">
	<sct-custom-table
		*ngIf="submitted"
		[columnsConfig]="columnConfig"
		[data]="tableData"
		[config]="tableConfig"
	></sct-custom-table>
</div>

<mat-menu #userMenu="matMenu" yPosition="below" [overlapTrigger]="false">
	<ng-template matMenuContent let-user="user">
		<span mat-menu-item [routerLink]="['/user', 'edit', user.id]" *ngIf="usersService.hasAccessFunction('edit_user_admin')">{{'users.edit_user' | translate}}</span>
		<span mat-menu-item [routerLink]="['/admin-panel/users', 'edit-access-functions', user.id]" *ngIf="currentUser.can_grant_access_functions">{{'users.edit_access_functions' | translate}}</span>
		<span mat-menu-item [routerLink]="['/admin-panel', 'permission-edit', user.permission_id]" *ngIf="user.permission_id > 0 && usersService.hasAccessFunction('edit_user_admin')">{{'g.edit_permissions' | translate}}</span>
		<span mat-menu-item *ngIf="!user.is_admin" (click)="getBecomeUserUrl(user.id)">{{'action_log.become_user' | translate}}</span>
		<span mat-menu-item class="text-danger" (click)="deleteUserModal.passData = user.id; deleteUserModal.show()" *ngIf="usersService.hasAccessFunction('delete_user') && !user.special_user">{{'g.delete' | translate}}</span>
	</ng-template>
</mat-menu>

<app-modal #deleteUserModal title="{{'users.delete_user' | translate}}" [closeBtnText]="'g.cancel' | translate">
	{{'g.del_user_prompt' | translate}}
</app-modal>
