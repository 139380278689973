<div class="white-box main-black mt-4 p-4 border-radius">
	<div class="row">
		<div class="col">
			<h4>
				{{"site.energy_utilization_charts" | device_translation: deviceType}}
			</h4>
		</div>
	</div>
	<div class="row py-4">
		<!-- period filter quarterly or monthly -->
		<div class="col-sm-6 col-md-4 col-lg-2 d-inline-flex">
			<ng-select [searchable]="false" class="mr-auto w-100" [items]="periodFilters" bindLabel="durations" bindValue="id" placeholder="{{'g.durations' | translate}}" name="durations" [(ngModel)]="selectedPeriod" [clearable]="false">
			</ng-select>
		</div>
		<!-- years filter -->
		<div *ngIf="yearsList.length" class="col-sm-6 col-md-4 col-lg-2 d-inline-flex">
			<ng-select [searchable]="false" class="mr-auto w-100" [items]="yearsList" bindLabel="year" bindValue="id" placeholder="{{'g.year' | translate}}" name="year" [(ngModel)]="selectedYear" [clearable]="false">
			</ng-select>
		</div>
	</div>

	<!-- months charts -->
	<sct-charts
		*ngIf="selectedPeriod == 'monthly' && this.kwhMonthlyChartObject.hasData"
		id="monthly-customer-utilization-chart-{{deviceType}}"
		[title]="kwhMonthlyChartObject.title"
		[data]="kwhMonthlyData[selectedYear]"
		[columnNames]="kwhMonthlyChartObject.columnNames"
		[options]="kwhMonthlyChartObject.config"
		[type]="'ColumnChart'"
		[height]="500"
	></sct-charts>

	<sct-charts
		*ngIf="selectedPeriod == 'monthly' && this.ahMonthlyChartObject.hasData"
		id="monthly-customer-utilization-chart-{{deviceType}}"
		[title]="ahMonthlyChartObject.title"
		[data]="ahMonthlyData[selectedYear]"
		[columnNames]="ahMonthlyChartObject.columnNames"
		[options]="ahMonthlyChartObject.config"
		[type]="'ColumnChart'"
		[height]="500"
	></sct-charts>

	<!-- quarters charts -->
	<sct-charts
		*ngIf="selectedPeriod == 'quarterly' && this.kwhQuarterChartObject.hasData"
		id="quarterly-customer-utilization-chart-{{deviceType}}"
		[title]="kwhQuarterChartObject.title"
		[data]="kwhQuarterlyData[selectedYear]"
		[columnNames]="kwhQuarterChartObject.columnNames"
		[options]="kwhQuarterChartObject.config"
		[type]="'ColumnChart'"
		[height]="500"
	></sct-charts>

	<sct-charts
		*ngIf="selectedPeriod == 'quarterly' && this.ahQuarterChartObject.hasData"
		id="quarterly-customer-utilization-chart-{{deviceType}}"
		[title]="ahQuarterChartObject.title"
		[data]="ahQuarterlyData[selectedYear]"
		[columnNames]="ahQuarterChartObject.columnNames"
		[options]="ahQuarterChartObject.config"
		[type]="'ColumnChart'"
		[height]="500"
	></sct-charts>
</div>
