import { Component, OnInit, OnChanges, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { SitesService } from 'src/app/sites/sites.service';
import { UsersService } from 'src/app/users/users.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-brm-groups',
	templateUrl: './brm-groups.component.html',
	styleUrls: ['./brm-groups.component.css']
})

export class BrmGroupsComponent implements OnInit, OnChanges {
	@Input() siteId: number;
	siteGroups: any[] = [];
	newGroup: string;
	groupToDelete: any = {};

	@ViewChild("deleteConfirmModal") deleteConfirmModal;

	constructor(
		private siteService: SitesService,
		private userService: UsersService,
		private notificationService: NotificationMessageService,
		private router: Router,
		private el: ElementRef,
		private renderer: Renderer2,
	){ }

	ngOnInit() {
		if (!this.userService.hasAccessFunction('show_charglink_brm'))
			return this.router.navigate(['/unauthorized']);

	}

	ngOnChanges() {
		if (this.siteId)
			return this.getBRMGroups();
	}

	ngAfterViewInit() {
		this.deleteConfirmModal.onClose.subscribe((ok: boolean) => {
			if (ok)
				this.removeGroup(this.groupToDelete.id);

			this.deleteConfirmModal.hide();
		});
	}

	getBRMGroups() {
		this.siteService.getBRMDeviceGroups(this.siteId).subscribe((groups: any) => {
			this.siteGroups = groups;
		});
	}

	removeGroup(groupId: number) {
		if (!groupId)
			return;

		this.siteService.deleteBRMDeviceGroup(groupId).subscribe((res: any) => {
			if (res) {
				this.siteGroups = this.siteGroups.filter((group) => group.id!== groupId);
				this.groupToDelete = {};
				return this.notificationService.setMessage('globalSuccessMsg', { clearOnXTimeNavigate: 1 });
			} else {
				return this.notificationService.setMessage('globalErrMsg', { clearOnXTimeNavigate: 1 });
			}
		});
	}

	validation() {
		const form = this.el.nativeElement;
		const ele = form.querySelectorAll('[name=newBRMGroups]');

		this.newGroup = this.newGroup.trim();

		let errorMsg = '';
		if (!this.newGroup)
			errorMsg = 'charglink_brm.brm_group_name_required';

		if (this.siteGroups.find((group) => group.group_text === this.newGroup))
			errorMsg = 'charglink_brm.brm_group_name_exists';
		
		if (errorMsg) {
			this.renderer.addClass(ele[0], 'invalid-input');
			this.notificationService.setMessage(`translate|${errorMsg}`);
		} else {
			this.renderer.removeClass(ele[0], 'invalid-input');
			return true;
		}
	}

	addGroup() {
		const res = this.validation();
		if (!res)
			return res;

		this.siteService.saveBRMDeviceGroup(this.newGroup, this.siteId).subscribe((res: any) => {
			if (res) {
				this.siteGroups.push(res);
				this.newGroup = '';
				return this.notificationService.setMessage('globalSuccessMsg', { clearOnXTimeNavigate: 1 });
			} else {
				return this.notificationService.setMessage('globalErrMsg', { clearOnXTimeNavigate: 1 });
			}
		})
	}

	showConfirmationModal(groupId: number) {
		this.groupToDelete = this.siteGroups.find((group) => group.id === groupId);
		this.deleteConfirmModal.show();
	}
}
