import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { UsersService } from 'src/app/users/users.service';
import { AlertSettingsService } from '../alert-settings.service';
import { keyBy } from 'lodash-es'

@Component({
	selector: 'app-users-alert-settings',
	templateUrl: './users-alert-settings.component.html',
	styleUrls: ['./users-alert-settings.component.css']
})

export class UsersAlertSettingsComponent implements OnInit {
	@Input() currentUser: any = {};
	defaultUserSettings = {
		maintenance_reports: 0,
		temperature_alert_freq: 0,
		cold_temperature_alert_freq: 0,
		ebu_alert_freq: 0,
		potential_weak_cells_alert_freq: 0,
		deep_discharge_alert_freq: 0,
		over_charge_alert_freq: 0,
		low_water_level_alert_freq: 0,
		idle_times_alert_freq: 0,
		missed_connection_alert_freq: 0,
	};

	selectedUserSettings = structuredClone(this.defaultUserSettings);

	usersList: any = [];
	selectedUser: number = 0;
	type: string;
	typesValue: {0: any, 1: any, 2: any};
	rangeValue: any = 0;
	siteID: number;
	usersAlertSettings: any [] = [];

	constructor (
		private route: ActivatedRoute,
		private router: Router,
		private usersService: UsersService,
		private notificationMessage: NotificationMessageService,
		private translateService: TranslateService,
		private commonService: CommonService,
		private alertSettingsService: AlertSettingsService
	) {
		this.typesValue = {
			0: this.translateService.instant('alert_frequency.none'),
			1: this.translateService.instant('alert_frequency.daily'),
			2: this.translateService.instant('alert_frequency.weekly')
		}
	}

	ngOnInit(): void {
		this.siteID = this.route.parent.snapshot.params.siteId || 0;
		this.getUsersAndAlertSettings();
	}

	ngOnChanges() {
		if (!this.currentUser.isSCT)
			return this.router.navigate(['/unauthorized']);
	}

	getUsersAndAlertSettings(userId?: number) {
		this.alertSettingsService.getUsersAlertSettings(+this.siteID).subscribe((res: any) => {
			if (!this.usersList.length) {
				res.users.map((item) => this.usersList.push({id: item.id, name: item.first_name +' '+ item.last_name}));
				this.usersList = this.commonService.sortDataAlphabetically(this.usersList, 'name');
			}

			this.usersAlertSettings = keyBy(res.usersAlertSettings, 'user_id');
			if (userId)
				this.selectOption(userId);
		});
	}

	selectOption(id: number) {
		this.selectedUser = id;
		let userSettings = this.usersAlertSettings[this.selectedUser] || {};

		if (Object.keys(userSettings).length && userSettings.user_id == this.selectedUser) {
			for (const index in userSettings)
				this.selectedUserSettings[index] = +(Object.keys(this.typesValue).find(key => this.typesValue[key] == userSettings[index]));
		} else {
			this.selectedUserSettings = structuredClone(this.defaultUserSettings);
			this.rangeValue = 0;
		}
	}
	
	validateUserAlertSettings() {
		const validValues = Object.values(this.typesValue);

		for (let key in this.selectedUserSettings) {
			if (!validValues.includes(this.typesValue[this.selectedUserSettings[key]]))
				delete this.selectedUserSettings[key];
		}

		if (!Object.keys(this.selectedUserSettings).length)
			return false;

		return true;
	}

	onChange(e) {
		this.selectedUserSettings[e.target.id] = +e.target.value;
	}

	saveUserAlertSettings(selectedUser: number){
		if (this.validateUserAlertSettings()) {

			for (const key in this.selectedUserSettings)
				this.selectedUserSettings[key] = this.typesValue[this.selectedUserSettings[key]];

			this.alertSettingsService.saveUserAlertSettings(selectedUser, this.selectedUserSettings, +this.siteID).subscribe((res: any) => {
				this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
				this.getUsersAndAlertSettings(selectedUser);
			});
		}
	}
}
