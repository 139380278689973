import { Component, OnInit } from '@angular/core';
import { FooterService } from './footer.service';
import { CommonService } from '../services/common.service';
import { DomainsService } from '../services/domains.service';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

	year = new Date().getFullYear();

	googlePlayAppLink: string = this.domainsService.mobileAppLinks.google_play.iotah;
	appStoreAppLink: string = this.domainsService.mobileAppLinks.app_store.iotah;

	constructor(
		public footer: FooterService,
		private commonService: CommonService,
		private domainsService: DomainsService,
	) {	}

	ngOnInit() {
		const domainLinks = this.domainsService.getAppLinks();

		this.googlePlayAppLink = domainLinks.google_play;
		this.appStoreAppLink = domainLinks.app_store;
	}
}
