import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'underscore';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '../../device.service';

@Component({
	selector: 'app-device-alerts',
	templateUrl: './device-alerts.component.html',
	styleUrls: ['./device-alerts.component.css']
})
export class DeviceAlertsComponent implements OnInit  {
	@Input() device: any = {};
	@Input() siteAlertsSettings: any = {};

	dailyDetails: any =  [];
	data: any = [];

	alertsColors = {
		low_water: '#3366cc',
		high_temp: '#9F8AD1',
		ebu_over_usage: '#D18A99',
		coldTempAlert: '#FFD700',
		missed_fi: '#FF6347',
		weekly_missed_eq: '#ff9900',
		potential_weak_cells: '#0099c6',
		deep_discharge: '#3dd3b2',
		sulfatedBatteryAlerts: '#c13dd3',
		overChargeAlerts: '#d3b33d',
	};

	invalidDateRange: boolean = false;
	deviceInstallationDate: any = null;
	startDate: any = moment().subtract(1, 'months').toDate();
	endDate: any = moment().toDate();
	maxDate = new Date(new Date().setDate(new Date().getDate()));

	constructor(
		private devicesService: DeviceService,
		private translateService: TranslateService
	) {}

	ngOnInit(): void {

	}

	chartOptions = {
		title: this.translateService.instant('perf_analytic.device_alerts'),
		height: 650,
		legend: { position: 'right', textStyle: { fontSize: 16 }, alignment: 'center'},
		tooltip: {
			trigger: 'hover',
			isHtml: false,
			text: 'percentage',
			showColorCode: true
		},
		slices: {
			0: { color: this.alertsColors.low_water },
			1: { color: this.alertsColors.high_temp },
			2: { color: this.alertsColors.weekly_missed_eq },
			3: { color: this.alertsColors.ebu_over_usage },
			9: { color: this.alertsColors.missed_fi },
			4: { color: this.alertsColors.potential_weak_cells },
			5: { color: this.alertsColors.deep_discharge },
			7: { color: this.alertsColors.sulfatedBatteryAlerts },
			8: { color: this.alertsColors.overChargeAlerts },
			6: { color: this.alertsColors.coldTempAlert }
		},
		pieSliceText: 'percentage',
		pieSliceTextStyle: {
			fontSize: 10
		},
	};

	ngOnChanges(changes) {
		if(this.device && this.device.mac_address && Object.keys(this.siteAlertsSettings).length) {
			this.deviceInstallationDate = new Date(this.device.config_info.installation_date * 1000);
			return this.getDailyDetails();
		}
	}

	getDailyDetails() {
		const startDate = moment(this.startDate).unix();
		const endDate = moment(this.endDate).unix();
		if (startDate > endDate)
			this.invalidDateRange = true;

		if (!this.device.mac_address || this.invalidDateRange)
			return;

		this.devicesService.getDailyDetails(this.device.mac_address, startDate, endDate).subscribe((response: any ) => {
			this.dailyDetails = response;
            this.populateDeviceAlerts();
		});
	}

	populateDeviceAlerts() {
		this.data = [];
		const deviceAlerts = this.prepareDeviceAlerts();
		const alertKeys = [
			'low_water_alert', 'high_temp_alert', 'weekly_missed_eq', 'ebu_over_usage_alert',
			'missed_fi_alert', 'potential_weak_cells_alert', 'deep_discharge',
			'potentially_sulfated_battery_alert', 'over_charge_alert', 'cold_temperature_alert'
		];

		alertKeys.forEach(alert => {
			this.data.push([this.translateService.instant(`device_alerts.${alert}`), deviceAlerts[alert]]);
		});
	}

	prepareDeviceAlerts() {
		let waterLevelLowDays	= 0,
			countOfValidDays	= 0,
			oneDaySeconds	= 60*60*24,
			dataHasSulfatedBatteryAlerts	= true,
			missedEqThisWeek	= true,
			thisWeekEqAfterIdleCount	= 0,
			tempSulfatedBatteryAlertsCount	= 0,
			missedFiThisWeek	= true,
			totalInuseAs2	= 0,
			totalChargeAs2	= 0,
			countOfWorkingDays2	= 0,
			totalInuseAs	= 0,
			tempWaterLevelAlerts	= 0;

		let low_water_alert = 0,
			high_temp_alert = 0,
			weekly_missed_eq = 0,
			missed_fi_alert = 0,
			over_charge_alert = 0,
			potentially_sulfated_battery_alert = 0,
			ebu_over_usage_alert = 0,
			potential_weak_cells_alert = 0,
			deep_discharge = 0,
			cold_temperature_alert = 0

		let endOfCurrentWeek,
			endOfLastWeek;


		let expectedEBU;
		switch (this.device.config_info.charger_type) {
			case this.devicesService.chargerTypes.Conventional:
				expectedEBU =  this.siteAlertsSettings.conventional_ebu_limit || 1.6;
			break;
			case this.devicesService.chargerTypes.Fast:
				expectedEBU = this.siteAlertsSettings.fast_ebu_limit || 1;
			break;
			case this.devicesService.chargerTypes.Opportunity:
				expectedEBU = this.siteAlertsSettings.opportunity_ebu_limit || 1.25;
			break;
			default:
				expectedEBU = 1;
			break;
		}

		let sortedDailyDetails = [];
		if (this.dailyDetails.length > 0) {
			sortedDailyDetails = this.dailyDetails.sort((a,b) => {return (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0);});
			let endDate = +moment(this.endDate).utc().startOf('day').format("X");

			endOfCurrentWeek	= endDate - (7*oneDaySeconds);
			endOfLastWeek		= endOfCurrentWeek;
		}

		for (let dayIndex in sortedDailyDetails) {
			const currentDayData = sortedDailyDetails[dayIndex];

			// calculate missed EQ
			if (currentDayData.date <= endOfCurrentWeek) {
				if (endOfLastWeek <= endOfCurrentWeek && countOfValidDays === 0) {
					dataHasSulfatedBatteryAlerts = false;
				}
				if (countOfValidDays > 3) {
					if (missedEqThisWeek == true && thisWeekEqAfterIdleCount != 7) {
						weekly_missed_eq++;
						if (dataHasSulfatedBatteryAlerts) {
							tempSulfatedBatteryAlertsCount++;
						}
					} else {
						if (tempSulfatedBatteryAlertsCount >= 4) {
							// increase the alerts
							potentially_sulfated_battery_alert++;
						}
						tempSulfatedBatteryAlertsCount = 0;
					}

					if (missedFiThisWeek == true)
						missed_fi_alert++;

					// over charge
					let chargePercentage = -1;
					let forcedOkCharge	= false;
					if (totalInuseAs2 > 0) {
						chargePercentage = (totalChargeAs2 / totalInuseAs2) * 100;
						forcedOkCharge =  (totalInuseAs2 / (3600 * 0.8 * this.device.config_info.battery_capacity * countOfWorkingDays2) < 0.25);
					}

					forcedOkCharge	= forcedOkCharge || ((Math.abs(totalChargeAs2 - totalInuseAs2) / 3600) < this.device.config_info.battery_capacity);

					if (chargePercentage > 130 && !forcedOkCharge)
						over_charge_alert++;

					totalChargeAs2		= 0;
					totalInuseAs2		= 0;
					countOfWorkingDays2	= 0;
				}

				missedEqThisWeek = true;
				thisWeekEqAfterIdleCount = 0;
				missedFiThisWeek = true;

				countOfValidDays = 0;
				endOfCurrentWeek -= 7 * oneDaySeconds;
			}

			countOfValidDays++;
			// calculate water_level_low alert
			if (currentDayData.water_level_low) {
				waterLevelLowDays++;
				tempWaterLevelAlerts++;
			}

			if ((currentDayData.count_of_eqs > 0 || currentDayData.has_eq_start) && waterLevelLowDays >= this.siteAlertsSettings.low_water_level_days) {
				low_water_alert += tempWaterLevelAlerts;
				tempWaterLevelAlerts = 0;
			}

			if (!currentDayData.water_level_low) {
				waterLevelLowDays = 0;
				tempWaterLevelAlerts = 0;
			}

			// calculate high_temp_alert
			if (currentDayData.max_temperature_exceeded > 0)
				high_temp_alert += 1;

			if(currentDayData.min_temperature_exceeded > 0)
				cold_temperature_alert += 1;

			if (missedEqThisWeek == true && (currentDayData.count_of_eqs > 0 || currentDayData.has_eq_start))
				missedEqThisWeek = false;

			if(currentDayData.eq_after_idle_day)
				thisWeekEqAfterIdleCount++;

			let inuseAsValue = currentDayData.inuse_as;
			let chargeAsValue = currentDayData.charge_as;

			totalChargeAs2 += chargeAsValue;
			totalInuseAs2 += inuseAsValue;

			// calculate EBU
			if (currentDayData.is_working_day) {
				totalInuseAs += inuseAsValue;
				const EBU	= inuseAsValue / (3600 * 0.8 * this.device.config_info.battery_capacity);
				if (EBU > expectedEBU && EBU <= 3)
					ebu_over_usage_alert++;
			}

			if (currentDayData.potential_week_cells_exceeded > 0)
				potential_weak_cells_alert++;

			if (currentDayData.deep_discharge_exceeded > 0)
				deep_discharge++;

			if (high_temp_alert < this.siteAlertsSettings.high_temperature_limit_days)
				high_temp_alert = 0;

			if (potential_weak_cells_alert < this.siteAlertsSettings.potential_weak_cells_limit_days)
				potential_weak_cells_alert = 0;

			if (currentDayData.deepDischarge < this.siteAlertsSettings.deep_discharge_limit_days)
				deep_discharge = 0;

			if (countOfValidDays > 3) {
				if (missedEqThisWeek == true && thisWeekEqAfterIdleCount != 7) {
					weekly_missed_eq++;
					if (dataHasSulfatedBatteryAlerts) {
						tempSulfatedBatteryAlertsCount++;
					}
				}

				if (missedFiThisWeek == true)
					missed_fi_alert++;

				// over charge
				let chargePercentage = -1;
				let forcedOkCharge	= false;
				if (totalInuseAs2 > 0) {
					chargePercentage = (totalChargeAs2 / totalInuseAs2) * 100;
					forcedOkCharge =  (totalInuseAs2 / (3600 * 0.8 * this.device.config_info.battery_capacity * countOfWorkingDays2) < 0.25);
				}

				forcedOkCharge	= forcedOkCharge || ((Math.abs(totalChargeAs2 - totalInuseAs2) / 3600) < this.device.config_info.battery_capacity);

				if (chargePercentage > 130 && !forcedOkCharge)
					over_charge_alert++;
			}

			if (tempSulfatedBatteryAlertsCount >= 4)
				potentially_sulfated_battery_alert++;
		}

		return  {
			low_water_alert, 
			high_temp_alert, 
			weekly_missed_eq, 
			missed_fi_alert, 
			over_charge_alert,
			potentially_sulfated_battery_alert, 
			ebu_over_usage_alert, 
			potential_weak_cells_alert, 
			deep_discharge, 
			cold_temperature_alert
		}
	}
}
