<div class="row justify-content-md-center">
	<div class="col-md-8 bg-white">
		<div class="border-bottom p-3">
			<h2 *ngIf="pageName == 'reset_password'">{{'login_register.reset_password' | translate}}</h2>
			<h2 *ngIf="pageName != 'reset_password'">{{'login_register.change_password' | translate}}</h2>
		</div>
		<div class="px-5 py-4">
			<form #changePasswordForm="ngForm" (ngSubmit)="changeUserPassword()" validate-form="editUserPassword">
				<div class="row mt-2 align-items-center" *ngIf="pageName != 'reset_password' && this.pageName != 'force_change'">
					<div class="col-md-4">
						<strong>{{'users.old_password' | translate}}</strong>
					</div>
					<div class="col-md-6">
						<input type="password" class="form-control" name="old_password" [(ngModel)]="old_password" placeholder="{{'users.old_password' | translate}}" required show-password/>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'users.new_password' | translate}}</strong>
					</div>
					<div class="col-md-6">
						<input type="password" class="form-control" name="new_password" [(ngModel)]="new_password" placeholder="{{'users.new_password' | translate}}" required show-password/>
					</div>
					<div class="col-md-2">
						<password-strength [password]="new_password" [stringStyle]="true" (strengthStatus)="getPasswordStrength($event)"></password-strength>
					</div>
				</div>
				<div *ngIf="passwordStrength == 1" class="row mt-2 align-items-center">
					<div class="offset-md-4 col-md-6">
						<small>{{'password_field.format' | translate}}</small>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'users.confirm_password' | translate}}</strong>
					</div>
					<div class="col-md-6">
						<input type="password" class="form-control" name="confirm_password" [(ngModel)]="confirm_password" placeholder="{{'users.confirm_password' | translate}}" required show-password/>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col">
						<span class="text-danger" *ngIf="confirm_password && new_password && confirm_password != new_password">* {{'users.passwords_dont_match' | translate}}!<br/><br/></span>
						<span class="text-danger" *ngIf="showChangePasswordMsg()">* {{'users.please_choose_a_new_password' | translate}}!<br/><br/></span>
					</div>
				</div>
				<div class="row my-4 align-items-center">
					<div class="col-md-4">
					</div>
					<div class="col-md-8">
						<div class="row align-items-center">
							<div class="col-md-8">
								<button
									type="submit"
									class="btn text-white main-blue-bg btn-lg col-md-5 mb-4 mr-4"
									[disabled]="changePasswordForm.invalid || (confirm_password != new_password) || (old_password == new_password && new_password == confirm_password)"
									[class.green-btn]="beforeLoginPage"
								>
									{{'g.submit' | translate}}
								</button>
								<button
									*ngIf="this.pageName != 'force_change'"
									type="button"
									class="btn text-white main-grey-bg btn-lg col-md-5 mb-4"
									routerLink="/sites-map"
								>
									{{'g.cancel' | translate}}
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
