<div class="white-box p-3 mb-3" *ngIf="isShowCustomer">
	<app-charger-fleet-utilization-score *ngIf="data.charger_fleet_utilization_score" [data]="data.charger_fleet_utilization_score"></app-charger-fleet-utilization-score>
	<app-charger-utilization-score
		*ngIf="data.charger_fleet_utilization_score"
		[utilizationScoreData]="data.charger_fleet_utilization_score"
		[devices]="devices"
	></app-charger-utilization-score>
	<app-daily-charger-utilization *ngIf="data.daily_charger_utilization" [data]="data.daily_charger_utilization" [devices]="devices"></app-daily-charger-utilization>
</div>

<app-site-performance></app-site-performance>
