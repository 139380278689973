import { Component, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit, OnChanges } from '@angular/core';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { UsersService } from 'src/app/users/users.service';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { LoaderInterceptorService } from 'src/app/shared/loader/loader-interceptor.service';
import * as moment from 'moment';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { CommonDataService } from 'src/app/shared/services/common-data.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { DeviceFactory } from '../../device-factory/device-factory';
import { mainDeviceConfig } from '../../device-factory/device-config';
import { IotahDevice } from '../../device-factory/Iotah-device';
import { DomainsService } from 'src/app/shared/services/domains.service';

@Component({
	selector: 'app-device-actions',
	templateUrl: './device-actions.component.html',
	styleUrls: ['./device-actions.component.css']
})
export class DeviceActionsComponent implements OnInit, AfterViewInit, OnChanges {

	@Input() device: any = {};
	@Input() changesStack: any = {};
	@Input() simInfo: any = {};
	@Input() enterprisePermissions: any = {};

	deviceConfig: mainDeviceConfig | null = (new IotahDevice).config;

	newSerialNumber: string = '';
	snIsInvalid: boolean = false;
	setPasswordModal = {
		password: '',
		isValid: true
	};
	lastPMModalVars: any = {};
	firmwareVersions: any = [];
	firmwareVersionToPush: number = 0;
	deviceFWVersionToPush: number;
	siteSpecial:boolean;
	simConnectTimeDelay: number = 10;
	showConnectCellularOption: boolean = false;
	confirmActions = {
		'resetDataHistory': 'actions.reset_data_history_confirm',
		'factoryReset': 'settings.factory_reset_prompt'
	};
	isAdmin = false;
	isProduction = false;
	macAddress: string ='';
	customerId = 0;
	siteInfo: any = {};
	cancelCalibrationMinimumFWVersion = 2.0290;
	passwordStrength: number = 0;

	@ViewChild("setSnModal") setSnModal;
	@ViewChild("updatePasswordModal") updatePasswordModal;
	@ViewChild("pushFWModal") pushFWModal;
	@ViewChild("lastPMModal") lastPMModal;
	@ViewChild("resetRTCLostDetectionModal") resetRTCLostDetectionModal;
	@ViewChild("confirmSetCalibrationPoints") confirmSetCalibrationPoints;

	@Output() changePage = new EventEmitter<{pageName: string}>();
	@Output() refreshDevice = new EventEmitter<void>();

	@ViewChild("confirmActionModal") confirmActionModal;

	resetCellularOEMSub!: Subscription;
	isCalStatusQueued: boolean = false;

	constructor(
		public deviceService: DeviceService,
		public usersService: UsersService,
		public commonUtil: CommonService,
		private notificationMessage: NotificationMessageService,
		private loader: LoaderInterceptorService,
		public sideMenuService: SideMenuService,
		public commonData: CommonDataService,
		private route: ActivatedRoute,
		private validationService: ValidationService,
		private domainsService: DomainsService
	) { }

	ngOnInit() {
		this.isAdmin = this.usersService.getCurrentUser().is_admin;
		this.isProduction = this.domainsService.isProductionDomain;
		this.deviceService.getDeviceFirmwareVersions().subscribe((data: any) => {
			this.firmwareVersions = data;
		});

		this.sideMenuService.currentSite.subscribe((siteInfo:any) => {
			if(!siteInfo['id'])
				return;

			this.siteInfo = siteInfo;
			this.siteSpecial = siteInfo.is_special
		});

		this.route.params.subscribe( params => {
			this.customerId = +params['customerId'] || 0;
			this.macAddress = params['deviceId'] || 0;
		});
	}

	ngAfterViewInit() {
		this.confirmActionModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			this.sendCommand(result[1][0], result[1][1]);
		});

		this.resetRTCLostDetectionModal.onClose.subscribe((result) => {
			if(!result[0])
				return;
			this.resetRTCLostDetection();
		});

		this.confirmSetCalibrationPoints.onClose.subscribe((result: any[]) => {
			if (result)
				this.setCalibrationPoints();
		});
	}

	ngOnChanges(changes) {
		if(this.device.config_info) {
			this.deviceConfig = DeviceFactory.createDevice(this.device?.config_info?.sct_device_type).config;
			this.showConnectCellularOption = false;
			this.getSIMInfo();

			if(this.device.config_info.firmware_version_to_push)
				this.deviceFWVersionToPush = this.device.config_info.firmware_version_to_push;
		}
	}

	closeSetSnModal() {
		this.newSerialNumber = null;
		this.setSnModal.hide();
	}

	openSetSnModal() {
		this.newSerialNumber = this.device.serial_number;
		this.setSnModal.show();
		this.snIsInvalid = false;
	}

	setDeviceSN() {

		const validationRules = this.validationService.getFormValidationRules('editDevice');
		let rule = validationRules['serial_number'];
		const isValid = this.validationService.dataValidator('serialNumber', rule, this.newSerialNumber);

		if(!isValid) {
			this.snIsInvalid = true;
			return;
		}

		if(this.device.serial_number == this.newSerialNumber)
			return;

		this.snIsInvalid = false;

		this.sendCommand('setDeviceSN', {'serial_number': this.newSerialNumber, 'restart': true});
	}

	closeUpdatePasswordModal() {
		this.setPasswordModal.password = '';
		this.setPasswordModal.isValid = true;
		this.updatePasswordModal.hide();
	}

	updatePassword() {
		let toUpdate = {'device_password': this.setPasswordModal.password};
		let invalidFields = this.commonUtil.validateDeviceSettings(toUpdate);

		if(invalidFields.length > 0) {
			this.setPasswordModal.isValid = false;
			return;
		}

		this.setPasswordModal.isValid = true;

		this.deviceService.sendCommand(this.device.mac_address, 'updatePassword', toUpdate).subscribe((data: any) => {
			if (data.response_status == this.loader.resStatus.api_timeout || data.api_status == this.deviceService.deviceCommandsResponse) { // if the api timed-out OR if wait sent and the device is disconnected.
				this.notificationMessage.setMessage('translate|settings.queue_and_wait_timeout_msg', {clearOnXTimeNavigate: 1, type: 'warning'});
				this.closeUpdatePasswordModal();
			} else {
				switch (data) {
					case "invalid_password":
						this.setPasswordModal.isValid = false;
					break;

					default:
						this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
						this.closeUpdatePasswordModal();
					break;
				}
			}
		});
	}

	filterVersions(currentVersion) {
		return this.firmwareVersions.filter(function(ver) {
			return ver != currentVersion;
		});
	}

	closePushFWModal() {
		this.firmwareVersionToPush = 0;
		this.pushFWModal.hide();
	}

	openPushFWModal() {
		this.firmwareVersionToPush = 0;
		this.pushFWModal.show();
	}

	pushFirmwareUpdate() {
		this.deviceService.pushFirmwareUpdate(this.device.mac_address, this.firmwareVersionToPush, 'device').subscribe((data) => {
			if(data == 1) {
				this.deviceFWVersionToPush = this.firmwareVersionToPush;
				this.notificationMessage.setMessage('translate|settings.queue_and_wait_timeout_msg', {clearOnXTimeNavigate: 1, type: 'warning'});
				this.closePushFWModal();
			}
		});
	}

	closeLastPMModal() {
		this.lastPMModal.hide();
	}

	openLastPMModal() {
		this.lastPMModalVars = {
			lastPMDate: this.getDateFieldFormatted(this.device.config_info.last_pm_date || 0),
			isValid: true,
			enabled: false,
			min: new Date(this.device.config_info.installation_date * 1000),
			max: new Date()
		};
		this.lastPMModal.show();
	}

	setLastPMDate() {
		//  local time -> utc
		let lastPMDate = moment(this.lastPMModalVars.lastPMDate).startOf('day').unix();
		if(!this.deviceService.validateLastPMDate(lastPMDate, this.device.config_info.installation_date)) {
			this.lastPMModalVars.isValid = false;
			return;
		}
		this.lastPMModalVars.isValid = true;

		let zoneDiff = new Date().getTimezoneOffset();
		lastPMDate -= (zoneDiff * 60);
		this.deviceService.setLastMaintenanceDate(this.device.mac_address, lastPMDate).subscribe((res: any) => {
			if(res.api_status) {
				this.lastPMModalVars.isValid = false;
				return false;
			}
			this.notificationMessage.setMessage('translate|settings.queue_and_wait_timeout_msg', {clearOnXTimeNavigate: 1, type: 'warning'});
			this.closeLastPMModal();
		});
	}

	getDateFieldFormatted(value) {
		if(value <= 0)
			return new Date();
		let zoneDiff = new Date().getTimezoneOffset();
			value += (zoneDiff * 60);
		if(value < this.device.config_info.installation_date) value = this.device.config_info.installation_date;
		return new Date(value*1000);
	}

	sendCommand(action, params = {}) {
		this.deviceService.sendCommand(this.device.mac_address, action, params).subscribe((data: any) => {
			switch(data.api_status){
				case 4:
					this.notificationMessage.setMessage('translate|g.duplicate_sn');
					return this.setSnModal?.hide() ;
			}

			// if the api timed-out OR if wait sent and the device is disconnected.
			if (data.response_status == this.loader.resStatus.api_timeout || data.api_status == this.deviceService.deviceCommandsResponse)
				this.notificationMessage.setMessage('translate|settings.queue_and_wait_timeout_msg', {clearOnXTimeNavigate: 1, type: 'warning'});
			else
				this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});

			if(action == 'setDeviceSN') {
				this.device.serial_number = this.newSerialNumber;
				this.closeSetSnModal();
			}
		});
	}

	checkShowConnectCellularOption() {
		setTimeout(() => {
			if (!Object.keys(this.simInfo).length || this.simInfo.iccid != this.device.config_info.cellular_iccid) {
				this.simInfo = {};
				return this.showConnectCellularOption = false;
			}

			let lastConnectedTime = moment().utc().unix() - this.device.config_info.last_connect_time;
			if (lastConnectedTime > this.simConnectTimeDelay) {
				this.showConnectCellularOption = true;
			} else {
				if(this.showConnectCellularOption)
					this.showConnectCellularOption = false;

				this.checkShowConnectCellularOption();
			}
		}, 1000);
	}

	getSIMInfo() {
		this.checkShowConnectCellularOption();
	}

	addDeviceToWakeUpList() {
		this.deviceService.addDeviceToWakeUpList(this.device.mac_address, this.simInfo.iccid, this.device.config_info.remoter_server_port).subscribe((data: any) => {
			switch (data.api_status) {
				case 2:
					this.notificationMessage.setMessage('translate|sim.sim_not_found_in_service')
					break;
				case 3:
					this.notificationMessage.setMessage('translate|sim.sim_not_connected')
					break;
				default:
					this.notificationMessage.setMessage('globalSuccessMsg');
					break;
			}
		});
	}

	resetRTCLostDetection() {
		this.deviceService.resetRTCLostDetection(this.device.mac_address).subscribe((response: any) => {
			this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			this.refreshDevice.emit();
		});
	}

	goToPage(page) {
		this.changePage.emit({pageName: page});
	}

	resetCellularOEM() {
		this.resetCellularOEMSub = this.deviceService.resetCellularOEM(this.device.mac_address).subscribe((data:any) => {
			if (data?.response?.invalid_fields)
				return this.notificationMessage.setMessage('translate|g.invalid_input');
			return this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
		});
	}

	clearRTCTimeDriftWarning() {
		this.deviceService.clearRTCTimeDriftWarning(this.device.mac_address).subscribe((data:any) => {
			if (data?.response?.invalid_fields)
				return this.notificationMessage.setMessage('translate|g.invalid_input');

			this.refreshDevice.emit();
			return this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
		});
	}

	showHideCalibrationPointsButton() {
		const mpCalStatus = this.device.config_info.mp_cal_status;
		const fwVersion = this.device.config_info.fw_version;

		if (!this.usersService.hasAccessPermission(this.enterprisePermissions, 'noc') || mpCalStatus != 2 || fwVersion < this.cancelCalibrationMinimumFWVersion)
			return false;

		const pAsciiValue = this.commonUtil.asciiValue('P');
		if (this.commonUtil.asciiValue(this.device.config_info.hw_version) >= pAsciiValue)
			return true;

		return false;
	}

	setCalibrationPoints() {
		if (this.device.config_info.mp_cal_status != 2)
			return this.notificationMessage.setMessage('globalErrMsg',{ clearOnXTimeNavigate: 1 });

		this.deviceService.sendCommand(this.device.config_info.mac_address, 'setCalStatus', { cal_status: 1 }).subscribe((data: any) => {
			this.isCalStatusQueued = true;
			if (data.response_status == this.loader.resStatus.api_timeout || data.api_status == this.deviceService.deviceCommandsResponse)
				this.notificationMessage.setMessage('translate|settings.queue_and_wait_timeout_msg', {type: 'warning', timeout: 10000, scrollToTop: true, clearOnXTimeNavigate: 1});
			else
				this.notificationMessage.setMessage('translate|settings.command_was_executed_successfully', {type: 'success', timeout: 10000, scrollToTop: true, clearOnXTimeNavigate: 1});
		});
	}

	canDisableHallEffect() {
		const hwVersion = this.device.config_info.hw_version;
		const fwVersion = this.device.config_info.fw_version;

		return (
			this.deviceService.isHWVersionSupportedFacture(hwVersion, "disable_hall_effect") &&
			this.deviceService.isFWSupportedFacture(fwVersion, "disable_hall_effect") &&
			this.usersService.hasAccessPermission(this.enterprisePermissions, 'noc')
		);
	}

	getPasswordStrength(passwordStrength: number) {
		this.passwordStrength = passwordStrength;
	}
}
