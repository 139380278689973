<div class="main-black border-radius-bottom white-box m-0 p-4">
	<div class="d-flex justify-content-between">
		<!-- tabs -->
		<nav class="navbar navbar-expand-lg border-radius-top navbar-light bg-white topnav pt-2 pb-0 px-4 nav-border main-black">
			<ul class="navbar-nav mr-auto">
				<li class="nav-item">
					<a class="nav-link p-3 light-grey small pointer"
						[ngClass]="{'active': activeTable == 'pick'}"
						(click)="setActiveTable('pick')"
					>
						<h5> {{'charglink_brm.pick' | translate}}</h5>
					</a>
				</li>
				<li class="nav-item">
					<a class="nav-link p-3 light-grey small pointer"
						[ngClass]="{'active': activeTable == 'miss_pick'}"
						(click)="setActiveTable('miss_pick')"
					>
						<h5> {{'charglink_brm.miss_pick' | translate}}</h5>
					</a>
				</li>
			</ul>
		</nav>
		<button class="btn btn-primary my-4" [routerLink]="['/', 'charglink-brm', 'public', '0']" [queryParams]="{siteId}">{{"nav.public_page" | translate}}</button>
	</div>


	<!-- filters -->
	<details open class="white-box p-4 filter-box my-4">
		<summary class="mb-2">{{'g.filter_box' | translate}}</summary>
		<!-- filters -->
		<div class="white-field col-12">
			<div class="custom-row d-flex justify-content-between align-items-center">
				<div class="d-flex">
					<!-- from time -->
					<div class="filter mb-2 mr-2">
						<label for="warning">{{'g.from_time' | translate}}</label>
						<input type="datetime-local" class="form-control" [(ngModel)]="filter.fromTimeLocal" (ngModelChange)="formatTime('fromTime', $event)" [max]="filter.toTimeLocal">
					</div>
					<!-- to time -->
					<div class="filter mb-2">
						<label for="warning">{{'g.to_time' | translate}}</label>
						<input type="datetime-local" class="form-control" [(ngModel)]="filter.toTimeLocal" (ngModelChange)="formatTime('toTime', $event)" [min]="filter.fromTimeLocal">
					</div>
				</div>
				<!-- search btn -->
				<div class="filter">
					<button class="btn btn-primary" [disabled]="!filter.fromTimeLocal || !filter.toTimeLocal" (click)="applyFilter()">{{"g.search" | translate}}</button>
				</div>
			</div>
		</div>
	</details>

	<!-- table -->
	<sct-custom-table
		#sctCustomTable
		[columnsConfig]="columnConfig"
		[data]="tableData"
		[config]="tableConfig"
	></sct-custom-table>
</div>
