import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
	selector: 'app-charglink-brm',
	templateUrl: './charglink-brm.component.html',
	styleUrls: ['./charglink-brm.component.css']
})
export class CharglinkBrmComponent {
	activePage: string;
	siteId: number;
	customerId: number;

	constructor(
		public commonService: CommonService,
		private route: ActivatedRoute,
	) {}

	ngOnInit() {
		this.siteId = this.route.parent.snapshot.params.siteId || 0;
		this.customerId = this.route.parent.snapshot.params.customerId || 0;
	}

	ngAfterViewInit() {

		this.route.data.subscribe(params => {
			if (!params.pageName || params.pageName == 'charglink-brm')
				params.pageName = 'site-history';

			this.activePage = params.pageName;
		});
	}

	isActiveTab(tab) {
		return this.activePage == tab;
	}
}
