import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { AlertSettingsService } from '../alert-settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';

@Component({
	selector: 'app-site-alerts-settings',
	templateUrl: './site-alerts-settings.component.html',
	styleUrls: ['./site-alerts-settings.component.css']
})
export class SiteAlertsSettingsComponent implements OnInit {
	@Input() currentUser: any = {};
	userIsSubscribed: boolean = false;
	customerID: number = 0;
	siteID: number = 0;
	currentSite: any = {};

	alert: any = {};
	oldAlertsSettings: any = {
		missed_connection_alert: [],
		missedConnectionAlert: []
	};
	usersHaveAccessToSite: any;
	ACTusersHaveAccessToSite: any;
	SOCChargeUsersHaveAccessToSite: any;
	chargerEnegySubscribed: any;
	selectedDays: any = {};
	days: any = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
	daysSelected: any;
	commonPeriods: any = [];
	isEditPluggedInFilters: boolean = false;
	runSLider: boolean;

	chargerAlertTypes: any = {
		value: 'none',
		options: {
			showTicksValues: true,
			stepsArray: [
				{value: "latching"},
				{value: "both"}
			],
			translate: function(value){
				if(value == 'both')
					return "latching and non-latching";
				return value;
			}
		}
	};

	siteSubscription: any;
	constructor(
		private alertSettingsService: AlertSettingsService,
		private route: ActivatedRoute,
		private router: Router,
		private sideMenuService: SideMenuService,
		private elementRef: ElementRef,
		private notificationMessage: NotificationMessageService,
	) { }

	ngOnInit() {
		this.sideMenuService.currentSite.subscribe((data:any) => {
			this.siteID = Number(this.route.parent.snapshot.params.siteId);
			if(data.id == this.siteID) {
				this.currentSite = data;
				return this.getSiteAlertSettings();
			}
		});
	}

	ngOnChanges() {
		if (!this.currentUser.isSCT)
			return this.router.navigate(['/unauthorized']);
	}

	getSiteAlertSettings() {
		this.alertSettingsService.getSiteAlertSettings(this.siteID).subscribe((res: any) => {
			if (res && res.length > 0)
				return this.alert = res[0];
			return;
		});
	}

	updateAlertsSettings() {
		let invalidInputs = this.elementRef.nativeElement.querySelectorAll('.invalid-input');
		invalidInputs.forEach((item) => {
			item.classList.remove('invalid-input');
		});

		this.alertSettingsService.saveSiteAlertSettings(this.siteID, this.alert).subscribe((res: any) => {
			if (res) {
				if (res.api_status == 3) {
					res.response.invalid_fields.forEach((field: string) => {
						const ele = this.elementRef.nativeElement.querySelector(`[name=${field}]`);
						ele?.classList.add('invalid-input');
					});
			
					return this.notificationMessage.setMessage('translate|g.invalid_input');
				}
				this.getSiteAlertSettings();
				return this.notificationMessage.setMessage('globalSuccessMsg');
			}
			return this.notificationMessage.setMessage('globalErrMsg');
		});
	}

	anyDaySelected() {
		let selectedDays = this.getDaysSelected();
		return Object.keys(selectedDays).length > 0;
	}

	getDaysSelected() {
		var daysSelected = {};
		for (var selectedDay in this.selectedDays) {
			if (this.selectedDays[selectedDay] && this.selectedDays[selectedDay] == true) {
				daysSelected[+selectedDay] = this.days[+selectedDay];
			}
		}
		return daysSelected;
	}

	getCommonPeriods(daysSelected) {
		var daysIntervals = [];
		var daysSelectedArray = Object.keys(daysSelected);

		var missed_connection_alert = JSON.parse(JSON.stringify(this.alert.missed_connection_alert));
		if (missed_connection_alert.length === 0) {
			return [];
		}
		for(var dayPeriods in daysSelectedArray) {
			daysIntervals.push(missed_connection_alert[daysSelectedArray[dayPeriods]]);
		}

		var commonPeriods = daysIntervals[Object.keys(daysIntervals)[0]];

		if (!commonPeriods || Object.keys(commonPeriods).length === 0)
			return [];

		var forceNoCommon = false;
		commonPeriods.forEach((commonInterval, index) => {
			if (!forceNoCommon) {
				for(var day in daysIntervals) {

					// no periods in any day means there is no common period
					if (!daysIntervals[day]) {
						forceNoCommon = true;
						commonPeriods = [];
						break;
					}
					if (+day > 0) {
						var noCommon = true;

						daysIntervals[day].forEach((interval) => {
							if(commonInterval.s == interval.s && commonInterval.e == interval.e)
								noCommon = false;
						});

						if(noCommon) {
							commonPeriods.splice(index, 1);
							index--;
						}
					}
				}
			}
		});

		return commonPeriods;
	}

	updateMissedConnection() {
		this.daysSelected	= this.getDaysSelected();
		this.commonPeriods	= this.getCommonPeriods(this.daysSelected);
		this.isEditPluggedInFilters = true;
	}

	addNewRange() {
		this.commonPeriods.push({});
	}

	removeRange(index) {
		this.commonPeriods.splice(index, 1);
	}

	cancelAddingRange() {
		this.commonPeriods = [];
		this.isEditPluggedInFilters = false;
		this.selectedDays = {};
	}

	validatePeriods() {
		var allPeriods = Object.assign([], this.commonPeriods);

		var sortedPeriods = allPeriods.sort((previous, current) => {

			// get the start date from previous and current
			var previousTime = previous.start;
			var currentTime = current.start;

			if(currentTime == 0) {
				current.end = 86400;
			}

			// if the previous is earlier than the current
			if (previousTime < currentTime) return -1;

			// if the previous time is the same as the current time
			if (previousTime === currentTime) return 0;

			// if the previous time is later than the current time
			return 1;
		});

		var overlap = sortedPeriods.reduce((overlap, current, idx, arr) => {
			// get the previous range
			if (idx === 0) {
				return overlap;
			}
			var previous = arr[idx-1];

			// check for any overlap
			var previousEnd = previous.end;
			var currentStart = current.start;
			overlap = overlap || (previousEnd >= currentStart);

			return overlap;
		}, false);

		return !overlap;
	}

	generateRange(start: number, end: number): number[] {
		if (start > end)
			return [];

		const range: number[] = [];
		for (let i = start; i <= end; i++) {
			range.push(i);
		}

		return range;
	}
}
