import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SitesService } from 'src/app/sites/sites.service';
import { DeviceFactory } from '../../../device-factory/device-factory';
import { DomainsService } from 'src/app/shared/services/domains.service';

@Component({
	selector: 'app-charglink-utilization-report',
	templateUrl: './charglink-utilization-report.component.html',
	styleUrls: ['./charglink-utilization-report.component.css']
})
export class CharglinkUtilizationReportComponent {
	showCustomers = [1, 2211, 2077, 1990];
	stagingShowCustomers = [467];
	productionShowCustomers = [1, 528];
	isShowCustomer: any;
	siteId: number;
	data: any = {};
	devices: any = {};

	constructor(
		private sitesService: SitesService,
		private route: ActivatedRoute,
		private domainsService: DomainsService
	) {}

	ngOnInit() {
		this.isShowCustomer = this.showUtilizationCharts();

		this.siteId = this.route.parent.snapshot.params.siteId || 0;
		this.sitesService.getUtilizationReportData(this.siteId, DeviceFactory.types.charglink).subscribe((res: any) => {
			this.data = res.data;
			this.devices = res.devices;
		});
	}

	showUtilizationCharts() {
		let customersList = this.showCustomers;

		if (this.domainsService.isProductionDomain) {
			customersList = this.productionShowCustomers;
		} else if (this.domainsService.isStagingDomain) {
			customersList = this.stagingShowCustomers;
		}

		this.domainsService.isStagingDomain;

		const customerId = +this.route.parent.snapshot.params.customerId || 0;

		return customersList.includes(customerId);
	}
}
