import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { getPackageForChart, ScriptLoaderService } from 'angular-google-charts';

@Component({
	selector: 'sct-charts',
	templateUrl: './sct-charts.component.html',
	styleUrls: ['./sct-charts.component.css']
})
export class SctChartsComponent implements OnInit, OnChanges {

	@Input() data;
	@Input() columnNames;
	@Input() options;
	@Input() formatter;
	@Input() formatterType;
	@Input() title;
	@Input() type;
	@Input() height = 500;
	@Input() chartId = '';

	@Output() select: EventEmitter<any> = new EventEmitter();
	@Output() ready: EventEmitter<any> = new EventEmitter();

	public chartSpecs: google.visualization.ChartSpecs;
	public rawFormatter: any;

	private areaChartPackage;

	constructor(
		private loaderService: ScriptLoaderService
	) { }

	ngOnInit() {
		this.parseChartData();
	}

	ngOnChanges() {
		this.ready.emit([this.chartId, false]);
		this.areaChartPackage = getPackageForChart(this.type);
		this.parseChartData();
	}
	onSelect(event) {
		if (this.select.observers.length > 0) {
			this.select.emit(event.selection[0]);
			return;
		}
	}

	protected parseOptions(): any {
		return {
			title: this.title,
			height: this.height,
			...this.options
		};
	}

	private parseChartData() {
		this.chartSpecs = {
			chartType: this.type,
			dataTable: [this.columnNames].concat(this.data),
			options: this.parseOptions()
		};

		if(this.formatter && this.formatterType) {
			this.loaderService.loadChartPackages(this.areaChartPackage).subscribe(() => {
				if(this.formatterType == 'DateFormat') {
					this.rawFormatter = [{ formatter: new google.visualization.DateFormat(this.formatter), colIndex: 0 }];
				}
			});
		}

		// replace null values with 0
		this.data = this.data.map(row => row.map(value => value === null ? 0 : value));
	}

	onReady($event) {
		this.ready.emit([this.chartId, true]);
	}
}
