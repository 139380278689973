import { DeviceConfig, mainDeviceConfig, TypesString } from './device-config';

export class BrmDevice implements DeviceConfig {
	config: mainDeviceConfig = {
		sct_device_type: 3,
		isIotah: false,
		isCharglink: false,
		isBattlink: false,
		isBrm: true,
		couldHaveStudy: false,
		hasTypes: false,
		typeString: 'Brm',
		translationPath: 'brmDevice.',
		imageFolder: '/BRM/',
		logoStyleClass: 'brm-logo',
		mapColor: '#573f34',
		setLastMaintenanceDate: false,
		hasMaintenanceSchedule: false,
		hasBatterySettings: false,
		CO2data: false,
		permissionMapperKey: '',
		supportIncorrectEvent: true,
		fields: {
			kwhr: 'charge_kwhr',
			hasMaxChargerKw: true,
			hasTemperatureSensor: false,
			hasWaterLevel: false,
			hasHallEffect: false,
			trickleVoltage: false,
			cvTargetVoltage: false,
			fiTargetVoltage: false,
			eqVoltage: false,
			trickleCurrentRate: false,
			ccRate: false,
			cvEndCurrentRate: false,
			cvCurrentStep: false,
			fiCurrentRate: false,
			eqCurrentRate: false,
			batteryCapacity: false,
			batteryType: false,
			numberOfCells: false,
			batteryTemperatureCompensationInMv: false,
			fiDt: false,
			fiDv: false,
			liftCurrentThresholdPercentage: false,
			liftCurrentThresholdValue: false,
			liftCurrentThresholdDeltaPercentage: false,
			liftCurrentThresholdDeltaValue: false,
			deadManTime: false,
			returnFactor: true,
			batteryEfficiency: true,
			chargerEfficiency: true,
			deadManCurrentLimit: true,
			chargerType: true,
		},
		requiredFields: {
			truckType: false,
			truckManufacturer: false,
			truckManufacturingYear: false,
			currentLiftLowerThreshold: false,
			currentLiftUpperThreshold: false,
		},
		incorrectEvent: {
			type: 'inuse',
		},
		quarterlyUsage: {
			hasTypeFilter: false,
			hasYearFilter: false,
			performanceChartType: 'c',
			hasChartLegend: true,
			chartGridColumns: 6,
			hasTotalTravelTimeChart: false,
			hasTotalLiftTimeChart: false,
			period: 'quarter',
			perfAnalyticsKey: 'perf_analytic.quarters_data',
		},
		reporting: {
			device: 'brm',
		},
		dailyDetails: {
			hasField: {
				billed_kwh: false,
				accurate_inuse_seconds: false,
				lift_time: false,
				travel_time: false,
				ah_h: false,
				ebu: false,
				missed_eq: false,
				missed_fi: false,
			},
			hasTypes: {
				endVoltage: false,
				idleTimes: false,
			},
			hasOrPowerStudy: {
				min_soc: false,
			},
			useBilledKwh: true
		},
		events: {
			hasFields: {
				billed_kwh: false,
				lift_time: false,
				travel_time: false,
				accurate_inuse_seconds: false,
				flag_finish: false,
				flag_eq: false,
				flag_finish_start: false,
				flag_eq_start: false,
				finish_reason: false,
				eq_reason: false,
			},
			hasOrPowerStudy: {
				start_soc: false,
				end_soc: false,
			}
		},
		usage: {
			summaryField: 'usage_charger_summary',
			accurateSecondsField: 'accurate_charge_seconds'
		},
		performance: {
			hasEndVoltageReport: false,
			hasIdleEventsReport: false,
			hasRegenReport: false,
			hasUsageAsReport: false,
			hasUsageTimeWidget: false,
			usageSummaryKey: 'amp_charger_usage_summary',
			kwuUsageSummaryKey: 'kw_charger_usage_summary',
			hasDataWidget: false,
			hasRelativeUsageWidget: false,
			hasBatteriesCustomizedCharts: false,
			chargeOpportunity: false,
			hasBatteryPerformance: false,
		},
		customer: {
			mix_quarterly_usage_kwh: false
		},
	};

	getImagePath(imageType: 'not_connected' | 'connected' | 'inuse' | 'idle', options: {is_study_device: boolean} = {is_study_device : false}): string {
		let imageLink = '/images' + this.config.imageFolder;

		switch (imageType) {
			case 'not_connected':
				imageLink += `brm_device_off.png`;
				break;
			case 'connected':
				imageLink += `brm_device.png`;
				break;

			default:
				imageLink += `brm_device_off.png`;
				break;
		}

		return imageLink;
	}

	getEventImage(eventType: string, is_study_device: boolean): string {
		return this.getImagePath('connected', {is_study_device});
	}

	getTypesString(options: {getPowerStudyDevices: boolean, is_study_device: boolean}): TypesString {
		return 'Brm';
	}
}
