import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import { CommonService } from 'src/app/shared/services/common.service';
import * as _ from 'underscore';
import { ColumnChartConfig } from '../../../../../shared/google-chart/Models/ColumnChartConfig';
import { LineChartConfig } from 'src/app/shared/google-chart/Models/LineChartConfig';
import { DeviceService } from '../../device.service';
import { CommonDataService } from 'src/app/shared/services/common-data.service';

@Component({
	selector: 'app-batteries-customized-charts',
	templateUrl: './batteries-customized-charts.component.html',
	styleUrls: ['./batteries-customized-charts.component.css']
})
export class BatteriesCustomizedChartsComponent {
	@Input() minDeviceDate: any = null;
	@Input() maxDeviceDate: any = null;
	@Input() devices: any = [];
	@Input() siteInfo: any = {};
	@Input() siteAlertSettings: any = {};

	siteAlertsSettings: any = {};
	device: any = {};

	// date data
	invalidDateRange: boolean = false;
	deviceInstallationDate: any = moment(0).toDate();
	fromDate: Date = moment().subtract(3, 'months').toDate();
	toDate: Date = moment().subtract(1, 'day').toDate();
	maxDate = moment().subtract(1, 'day').toDate();

	// chart row data
	dailyDetailsReport: any[] = [];
	eventsReport: any[] = [];
	hasDailyDetailData: boolean = false;
	hasEventsData: boolean = false;

	// charts
	dailyUsageChart: any = {};
	dailyEbusChart: any = {};
	dailyAhrsChart: any = {};
	socChart: any = {};
	maxTempChart: any = {};
	endTempChart: any = {};
	endVoltageChart: any = {};
	printChartLinks: string[] = [];

	// chart options
	availableAhrsCheckBox: boolean = true;
	hasHorizontalLimit: boolean = false;
	maxTempCheckBox: boolean = true;
	endTempCheckBox: boolean = true;
	inuseLimitCheckBox: boolean = true;
	potentialCheckBox: boolean = true;
	isCompensatedVoltageCheckBox: boolean = true;
	showPotentialCheckBox: boolean = false;
	horizontalLimitObject: any = {};

	dataToShow :any =  {
		events: [],
		dailyDetails: []
	};

	eventsTypes: {
		charge: 1,
		idle: 2,
		inuse: 3
	};

	// common chart options
	commonChartOptions: any = {
		widgetHeight: 400,
		legend: { position: 'top'},
		LegendPosition: 'top',
		chartArea: {
			width: '80%',
			height: '70%',
			backgroundColor: {
				stroke: '#000',
				strokeWidth: 2
			}
		},
		hAxis: {'title': 'Date', 'titleTextStyle': {'color': 'black', 'fontSize': 18}},
	}
	vAxisTitleTextStyle = {vAxisTitleTextStyle: {color: 'black', fontSize: 18}};
	allCommonExplorerOptions = {
		explorer :{
			'axis': 'horizontal',
			'actions': ['dragToZoom', 'rightClickToReset'],
			maxZoomIn: 50.0,
			keepInBounds: true
		}
	};
	mostCommonExplorerOptions = {
		'actions': ['dragToZoom', 'rightClickToReset'],
		keepInBounds: true
	}

	// other
	activeSubTab: string = "customized";
	selectedTypes: any[] = ['Charge'];

	constructor(
		private commonService: CommonService,
		private deviceService: DeviceService,
		private commonDataService: CommonDataService
	) { }

	ngOnChanges(changes) {
		this.deviceInstallationDate = moment(0).toDate();
		this.fromDate = moment().subtract(3, 'months').toDate();
		this.maxDate = new Date(new Date().setDate(new Date().getDate() - 1));

		// update device and site data
		this.siteAlertsSettings = this.siteAlertSettings
		this.device = this.devices[0].config_info || {};

		if(changes.devices || changes.site) {
			if (this.maxDeviceDate && this.maxDeviceDate < this.maxDate) {
				this.maxDate = this.maxDeviceDate;
				this.toDate = this.maxDeviceDate;
			}

			const deviceData = this.devices[0].config_info;

			this.updateInstallationDate((deviceData.installation_date || 0));
			this.getData();
		}
	}

	updateInstallationDate(date) {
		if (date > 0)
			this.deviceInstallationDate = moment(date*1000).toDate();

		if (this.minDeviceDate && moment(this.minDeviceDate).unix() > moment(this.deviceInstallationDate).unix())
			this.deviceInstallationDate = this.minDeviceDate;

		if(moment(this.fromDate).unix() < moment(this.deviceInstallationDate).unix())
			this.fromDate = this.deviceInstallationDate;
	}

	DatesChanged() {
		this.invalidDateRange = false;
		if(moment(this.fromDate).unix() > moment(this.toDate).unix())
			return this.invalidDateRange = true;

		this.getData();
	}

	getData() {
		const macAddress = this.device.mac_address;
		const fromTime = moment(this.fromDate).unix();
		const toTime = moment(this.toDate).unix();

		let drawChart = false;
		this.deviceService.getEvents(macAddress, fromTime, toTime).subscribe((data: any) => {
			this.dataToShow.events = data;

			this.deviceService.getDailyDetails(macAddress, fromTime, toTime).subscribe((response) => {
				this.dataToShow.dailyDetails = response;

				if (this.dataToShow.dailyDetails) {
					this.dailyDetailsReport = this.generateDailyDetailReport(this.dataToShow.dailyDetails, this.devices[0].config_info);
					drawChart = true;
					this.hasDailyDetailData = this.dailyDetailsReport.length > 0;
				}

				if (this.dataToShow.events) {
					this.eventsReport = this.generateEventsReport(this.dataToShow.events, this.devices[0].config_info);
					this.hasEventsData = this.eventsReport.length > 0;
					drawChart = true;
				}

				if (drawChart)
					this.drawChart();
			});
		});
	}

	generateDailyDetailReport(dailyDetails:any, device: any){
		let dailyDetailsReport = [];

		for(let i = 0; i < dailyDetails.length; i++){
			let reportRecord: any = {};
			let currentDailyDetailsDay = dailyDetails[i];

			let inuseAsValue = currentDailyDetailsDay.inuse_as;
			let chargeAsValue = currentDailyDetailsDay.charge_as;

			reportRecord.date = currentDailyDetailsDay.date*1000;
			reportRecord.charges = currentDailyDetailsDay.total_charge_events;
			reportRecord.chargeAHR = chargeAsValue;
			reportRecord.chargeKWHR = currentDailyDetailsDay.charge_ws;
			reportRecord.total_inuse_events = currentDailyDetailsDay.total_inuse_events;
			reportRecord.inuse_ahr = inuseAsValue / 3600;
			reportRecord.inuse_kwhr = currentDailyDetailsDay.inuse_ws / 3600000;
			reportRecord.totalIdleEvents = currentDailyDetailsDay.total_idle_events;

			/* Usage Widgate */
			reportRecord.chargeDurationValue = currentDailyDetailsDay.charge_duration * 1000;
			reportRecord.dischargeDurationValue = currentDailyDetailsDay.inuse_duration * 1000;
			reportRecord.idleDurationValue = currentDailyDetailsDay.idle_duration * 1000;

			reportRecord.chargeOppurtinityDurationValue = currentDailyDetailsDay.charge_oppurtinity_duration;

			/* AHR value */
			reportRecord.dischargeAHR = (inuseAsValue / 3600).toFixed(1);
			reportRecord.availableAH = device.battery_capacity * 0.8;
			reportRecord.chargeAH = (chargeAsValue / 3600).toFixed(1);


			/* EBU Widgate */
			reportRecord.EBU = ((inuseAsValue/3600)/(0.8 * device.battery_capacity)).toFixed(2);
			if (reportRecord.EBU > 3)
				reportRecord.EBU = 3;

			reportRecord.temperatureSensorEnabled = currentDailyDetailsDay.temperature_sensor_enabled;

			let AhrPerHr = 0;
			if (currentDailyDetailsDay.inuse_duration > 0) {
				const inuse_duration = currentDailyDetailsDay.inuse_duration * 1000;
				const inuse_ah = inuseAsValue / 3600;
				AhrPerHr = inuse_ah / (inuse_duration/(3600*1000));
			}
			reportRecord.ahrPerHr = AhrPerHr;

			reportRecord.potentialWeekCellsExceeded	= currentDailyDetailsDay.potential_week_cells_exceeded;
			reportRecord.deepDischargeExceeded		= currentDailyDetailsDay.deep_discharge_exceeded;
			reportRecord.maxTemperatureExceeded	= currentDailyDetailsDay.max_temperature_exceeded;
			reportRecord.isWorkingDay			= currentDailyDetailsDay.is_working_day;
			reportRecord.inuseAs				= inuseAsValue;
			reportRecord.waterLevelLow			= currentDailyDetailsDay.water_level_low;

			dailyDetailsReport.push(reportRecord);
		}
		return dailyDetailsReport;
	}

	generateEventsReport(events: any[], device) {
		const maxTemperatureLimit = this.commonService.fahToCel(200);

		let eventsReport = [];
		for (let i = 0; i < events.length; i++) {
			const currentEvent = this.commonService.decompress(events[i].data, 'events');
			let currentRecord: any = {};
			currentRecord.date = currentEvent.timestamp * 1000;
			/* SOC Widgate */
			currentRecord.soc = currentEvent.start_soc;
			const nominalVoltage = device.number_of_cells * currentEvent.vpc; // TODO: check please
			currentEvent.vpc_end_voltage = currentEvent.end_voltage / (nominalVoltage / 2);
			let end_voltage_formatted = currentEvent.vpc_end_voltage;

			currentEvent.compensation_end_voltage = currentEvent.vpc_end_voltage;

			if (currentEvent.temperature_sensor_enabled) {
				let maxTemperature = currentEvent.max_temperature_recorded;
				if (maxTemperature > maxTemperatureLimit) {
					maxTemperature = maxTemperatureLimit;
				}

				maxTemperature = 1.8 * currentEvent.max_temperature_recorded + 32;

				/* Max Temp */
				currentRecord.temp = Math.round(maxTemperature * 10) / 10;

				var endVolTemp = currentEvent.max_temperature_recorded;
				if(events[i+1])
					endVolTemp = this.commonService.decompress(events[i+1].data, 'events').max_temperature_recorded;

				currentEvent.compensation_end_voltage = currentEvent.vpc_end_voltage + (endVolTemp - 25) * ((device?.batterytemperaturecompesnation || 5) / 1000); // TODO: As discussed with AMR we need to add 'batterytemperaturecompesnation' in deviceInfo object will be updated

				/* endTemp  As discussed with AMR no need End Temp Now*/

				// var endTemperature = currentEvent.start_temperature;
				// if (endTemperature > maxTemperatureLimit) {
				// 	endTemperature = maxTemperatureLimit;
				// }
				// if(device.temperatureformat == 1) {
				// 	endTemperature = 1.8 * currentEvent.start_temperature + 32;
				// }
				// currentRecord.endTemp = Math.round(endTemperature * 10) / 10;
			}

			if(currentEvent.event_name == 1)
				end_voltage_formatted = currentEvent.compensation_end_voltage;

			switch(currentEvent.event_name){
				case 2:
					currentRecord.type = this.eventsTypes.idle;
					break;
				case 3:
					currentRecord.type = this.eventsTypes.inuse;
					break;
				default:
					currentRecord.type = this.eventsTypes.charge;
			}

			currentRecord.endVoltage = end_voltage_formatted;

			/** show voltage as Temp */
			currentRecord.compensationEndVoltage	= currentEvent.compensation_end_voltage;
			currentRecord.VPCendVoltage				= currentEvent.vpc_end_voltage;

			eventsReport.push(currentRecord);
		}
		return eventsReport;
	}

	checkBoxChanged() {
		this.drawChart();
	}

	updateSelectedType($event) {
		let type = $event.currentTarget.value;
		let isChecked = $event.currentTarget.checked;

		if(isChecked) {
			if(this.selectedTypes.indexOf(type) === -1)
				this.selectedTypes.push(type);
		} else {
			let index = this.selectedTypes.indexOf(type);

			if(index > -1)
				this.selectedTypes.splice(index, 1);
		}
	}

	drawChart() {
		if (this.hasDailyDetailData) {
			this.dailyUsageChart = this.getChartObject({chartType: 'ColumnChart', getDataFromDailyDetails: true}, 'daily-usage');
			this.dailyEbusChart = this.getChartObject({chartType: 'ColumnChart', getDataFromDailyDetails: true}, 'daily-ebus');
			this.dailyAhrsChart = this.getChartObject({chartType: 'ColumnChart', getDataFromDailyDetails: true}, 'daily-ahrs');
		}

		if (this.hasEventsData) {
			this.socChart = this.getChartObject({chartType: 'LineChart'}, 'soc');
			// this.maxTempChart = this.getChartObject({chartType: 'LineChart'}, 'max-temperature');
			this.endTempChart = this.getChartObject({chartType: 'LineChart'}, 'end-temperature');
			this.endVoltageChart = this.getChartObject({chartType: 'LineChart'}, 'end-voltage');
		}
	}

	private getChartObject(chartOptions, subTab = null) {
		let getDataFromDailyDetails = chartOptions.getDataFromDailyDetails || false;
		let tab = this.activeSubTab;
		if(subTab !== null)
			tab = subTab;

		let config = this.getOptions(tab);

		let colArray = this.getChartColumnsTypes(tab);
		let _data = this.filterData(tab, getDataFromDailyDetails);

		let data = this.generateChartData(colArray, _data);
		data.unshift(this.getChartColumnsNames(tab));
		return {data, config};
	}

	private getOptions(subTab) {
		let options = {};
		switch (subTab) {
			case 'daily-usage':
				options = this.getDailyUsageOptions();
			break;
			case 'daily-ebus':
				options = this.getDailyEbusOptions();
			break;
			case 'daily-ahrs':
				options = this.getDailyAhrsOptions();
			break;
			case 'soc':
				options = this.getSocOptions();
			break;
			// case 'max-temperature':
			// 	options = this.getMaxTemperatureOptions();
			break;
			case 'end-temperature':
				options = this.getEndTemperatureOptions();
			break;
			case 'end-voltage':
				options = this.getEndVoltageOptions();
			break;
		}
		return options;
	}

	private getDailyUsageOptions() {
		var ticksAxisV = [];
		for (var i = 0 ;i < 25; i++) {
			var tickValue = [i,0,0,0];
			var tickFormat = i + " h";
			var tick = {
				v: tickValue,
				f: tickFormat
			};
			ticksAxisV.push(tick);
		}

		return new ColumnChartConfig({
			...this.commonChartOptions,
			...this.vAxisTitleTextStyle,
			...this.allCommonExplorerOptions,
			colors: ["#428bca", "#EF980C", "#666666"],
			tooltip: {},
			yTitle: 'Number of Hours',
			vAxisTicks: ticksAxisV,
			vAxisGridlines: {count: 24},
		});
	}

	private getDailyEbusOptions() {
		var hLineValue = 0;
		var hLineText = "";
		var EBUdata = this.getFilteredChartArr(this.dailyDetailsReport, "EBU");

		switch (this.device.chargertype) {
			case 0:
				hLineValue = this.siteAlertsSettings.fast_ebu_limit || 1.6;
				break;
			case 1:
				hLineValue = this.siteAlertsSettings.conventional_ebu_limit || 1;
				break;
			case 2:
				hLineValue = this.siteAlertsSettings.opportunity_ebu_limit || 1.25;
				break;
		}
		hLineText = "EBU Limit";

		// The max value of the data value + 10% OR the battery EBU limit, whatever is greater.
		var maxDataEBU = Math.max.apply(null, EBUdata);
		var maxEBU = _.isNaN(maxDataEBU) ? 0 : maxDataEBU;

		// If there is any value >=  battery EBU limit , then add a horizontal line of EBU limit
		if (hLineValue >= maxEBU) {
			var maxEBUChartValue = hLineValue;
		} else {
			var maxEBUChartValue = maxDataEBU * 1.10;
			this.hasHorizontalLimit = true;
			this.horizontalLimitObject = {
				text: hLineText,
				value: hLineValue,
				type: 'passedvalue'
			};
		}

		let options = new ColumnChartConfig({
			...this.commonChartOptions,
			...this.vAxisTitleTextStyle,
			...this.allCommonExplorerOptions,
			'colors': ['#3799db'],
			yTitle: 'EBU',
			tooltip: 0,
			vAxisMaxValue: maxEBUChartValue,
			vAxisMinValue: 0,
			seriesType: 'bars',
			interpolateNulls: true
		});

		if (this.hasHorizontalLimit)
			options = _.extend(options, {
				series: {
					1: {
						type: 'line',
						color: 'red',
					}
				}
			});

		return options;
	}

	private getDailyAhrsOptions() {
		var maxChargeAH = Math.max.apply(null, this.getFilteredChartArr(this.dailyDetailsReport, "chargeAH"));
		maxChargeAH =  _.isNaN(maxChargeAH) ? 0 : maxChargeAH;

		var maxDischargeAHR = Math.max.apply(null, this.getFilteredChartArr(this.dailyDetailsReport, "dischargeAHR"));
		maxDischargeAHR =  _.isNaN(maxDischargeAHR) ? 0 : maxDischargeAHR;

		var maxAHValue = Math.max.apply(null, [maxChargeAH, maxDischargeAHR]);
		maxAHValue = maxAHValue * 1.1;

		var maxAHValueOnChart = maxAHValue > this.device.battery_capacity ? maxAHValue : this.device.battery_capacity;

		return new ColumnChartConfig({
			...this.commonChartOptions,
			...this.vAxisTitleTextStyle,
			...this.allCommonExplorerOptions,
			yTitle: 'AHR Value',
			vAxisMaxValue: maxAHValueOnChart,
			vAxisMinValue: 0,
			vAxisGridlines: {'count': 10},
			'colors': ["#428bca", "#EF980C", "#AAAEB0"],
			'seriesType': 'bars',
			'series': { 2: {'type': 'line', 'color': 'green'}},
		});
	}

	private getSocOptions() {
		return new LineChartConfig({
			...this.commonChartOptions,
			...this.allCommonExplorerOptions,
			'colors': ["#3799db"],
			'vAxis': {
				'title': '%SOC',
				'titleTextStyle': {'color': 'black', 'fontSize': 18},
				'minValue': 0,
				'maxValue': 100,
				'gridlines': {'count': 10},
				viewWindow: { min: 0, max: 100},
				viewWindowMode: "explicit"
			},
		});
	}

	private getEndTemperatureOptions() {
		// the max should be max data value + 10% Or the battry high temperature value , whatever is greater
		var maxReportTempData = Math.max.apply(null, this.getFilteredChartArr(this.eventsReport, "endTemp"));
		var maxDataLimit = maxReportTempData * 1.1;

		var BattertHighTempLimit = this.getBatteryHighTempLimit();

		var tempMaxChartLimit = maxDataLimit > BattertHighTempLimit ? maxDataLimit : BattertHighTempLimit;

		var chartColors = ["#3799db"];

		if (this.endTempCheckBox) {
			chartColors = ['red', "#3799db"];
		}

		// the min will be TR temperature or min data value -10%, whatever is less.
		var minReportTempData = Math.min.apply(null, this.getFilteredChartArr(this.eventsReport, "endTemp"));
		var minReportTempLimit = minReportTempData * 0.9;
		var tempMinChartLimit = minReportTempLimit;

		// if there is any value <=  TR value , then add a horizontal line at  TR temperature value
		var trtemperature = this.device.trtemperature;
		var temperatureFormat = 'C';
		if(this.device.temperatureformat == 1) {
			trtemperature = this.commonService.fahToCel(trtemperature, true);
			temperatureFormat = 'F';
		}
		tempMinChartLimit = minReportTempLimit < trtemperature ? minReportTempLimit : trtemperature;

		var ticksAxisV = [];
		var increment = 10;
		for (var i = tempMinChartLimit ;i <= tempMaxChartLimit; i+= increment) {
			ticksAxisV.push(i);
		}

		var limit = tempMaxChartLimit;
		if (_.last(ticksAxisV) < tempMaxChartLimit) {
			var last = _.last(ticksAxisV) + increment;
			if (last) {
				limit = last;
				ticksAxisV.push(limit);
			}
		}

		return new LineChartConfig({
			...this.commonChartOptions,
			'explorer': { ...this.mostCommonExplorerOptions ,maxZoomIn: 50.0},
			'vAxis': {'title': 'Temperature ('+temperatureFormat+')', 'titleTextStyle': {'color': 'black', 'fontSize': 18},
				'minValue': tempMinChartLimit, 'maxValue': limit, 'ticks': ticksAxisV,
				'viewWindow': {min: tempMinChartLimit, max: limit}, viewWindowMode: "explicit"
			},
			'colors': chartColors,
		});
	}

	private getEndVoltageOptions() {
		let filteredEndVoltageData = this.filterEndVoltage();

		// the max should be max data value + 0.1 Or the EQ voltage + 0.1
		var maxEndVoltageData = Math.max.apply(null, this.getFilteredChartArr(filteredEndVoltageData, "endVoltage"));
		var eqvoltage = this.device.eqvoltage;
		var chartMaxVoltage = eqvoltage > maxEndVoltageData ? eqvoltage + 0.1 : maxEndVoltageData  + 0.1;
		// the min will be Potential Weak Cells Limit voltage or min data value -0.1, whatever is less.
		var minEndVoltageData = Math.min.apply(null, this.getFilteredChartArr(filteredEndVoltageData, "endVoltage"));
		var PotentialWeakCellsLimit = 1.7;
		if (this.siteAlertsSettings.deep_use_limit) {
			PotentialWeakCellsLimit = this.siteAlertsSettings.deep_use_limit;
		}
		var chartMinVoltage = PotentialWeakCellsLimit < minEndVoltageData - 0.1 ? PotentialWeakCellsLimit : minEndVoltageData;

		// if there is any value <=  Potential Weak Cells Limit value , then add a horizontal line at Potential Weak Cells Limit voltage
		this.showPotentialCheckBox = (minEndVoltageData <= PotentialWeakCellsLimit);

		var ticksAxisV = [];
		for (var i = chartMinVoltage ;i <= chartMaxVoltage; i+=0.1) {
			ticksAxisV.push(i);
		}

		return new LineChartConfig({
			...this.commonChartOptions,
			'explorer': {...this.mostCommonExplorerOptions},
			'vAxis': {'title': 'Voltage', 'titleTextStyle': {'color': 'black', 'fontSize': 18},
				'minValue': chartMinVoltage, 'maxValue': chartMaxVoltage, format:'0.0', 'ticks': ticksAxisV
			},
			'colors': ["#3799db", "#8A2BE2", "#EF980C"],
		});
	}

	private getFilteredChartArr(arr, attr) {
		let data = arr.filter(( element ) => {
			 return (element !== undefined && element[attr] !== undefined);
		});
		return _.pluck(data, attr);
	}

	private getBatteryHighTempLimit() {
		let battertHighTempLimit;
		if (this.siteAlertsSettings.override_temperature_limit)
			battertHighTempLimit = this.siteAlertsSettings.temperature_limit;
		else
			battertHighTempLimit = this.device?.config_info?.batteryhightemperature || 32; // TODO: batteryhightemperature 'Max Battery Temperature' added in deviceInfo object will be updated

		// ? INFO: AS discussied with AMR the temp saved in DB as celsius format, but in UI shown as fahrenheit format
		battertHighTempLimit = this.commonService.fahToCel(battertHighTempLimit, true);
		return battertHighTempLimit;
	}

	private getChartColumnsTypes(chartType) {
		let columns = [];
		switch(chartType) {
			case 'daily-usage':
				columns = [
					{col :'date', type: 'date'},
					{col: 'chargeDurationValue', type: 'timeofday'},
					{col:'dischargeDurationValue', type: 'timeofday'},
					{col :'idleDurationValue', type: 'timeofday'}
				];
			break;
			case 'daily-ebus':
				columns = [
					{col :'date', type: 'date'},
					{col: 'EBU', type: 'float'}
				];
				if(this.hasHorizontalLimit) {
					columns.push({col: this.horizontalLimitObject.text, type: 'passedvalue', value: this.horizontalLimitObject.value});
				}
			break;
			case 'daily-ahrs':
				columns = [
					{col :'date', type: 'date'},
					{col: 'chargeAH', type: 'float'},
					{col:'dischargeAHR', type: 'float'}
				];
				if(this.availableAhrsCheckBox) {
					var AHChartLimit =  0.8 * this.device.config_info.battery_capacity;
					columns.push({col: 'passedvalue', type: 'passedvalue', value: AHChartLimit});
				}
			break;
			case 'soc':
				columns = [
					{col: 'date', type: 'date'},
					{col: 'soc', type: 'float'}
				];
			break;
			// case 'max-temperature':
			// 	columns = [
			// 		{col: 'date', type: 'date'}
			// 	];

			// 	if (this.maxTempCheckBox) {
			// 		// if there is any value >=  high temperature value , then add a horizontal line at  high temperature value
			// 		let battertHighTempLimit = this.getBatteryHighTempLimit();
			// 		columns.push({col: 'passedvalue', type: 'passedvalue', value: battertHighTempLimit});
			// 	}

			// 	columns.push({col: 'temp', type: 'float'});
			// break;
			case 'end-temperature':
				columns = [
					{col: 'date', type: 'date'}
				];

				if (this.endTempCheckBox) {
					// if there is any value >=  high temperature value , then add a horizontal line at  high temperature value
					let battertHighTempLimit = this.getBatteryHighTempLimit();
					columns.push({col: 'passedvalue', type: 'passedvalue', value: battertHighTempLimit});
				}

				columns.push({col: 'endTemp', type: 'float'});
			break;
			case 'end-voltage':
				columns = [
					{col: 'date', type: 'date'},
					{col: 'endVoltage', type: 'float'}
				];

				/* Deep discharge limit */
				if (this.inuseLimitCheckBox) {
					// add a horizontal line for  Deep Discharge Limit
					let deepDischargeLimit = 2;
					if (this.siteAlertsSettings.deep_discharge_limit) {
						deepDischargeLimit = this.siteAlertsSettings.deep_discharge_limit;
					}
					// endVoltageData.addColumn('number', 'Deep Discharge Limit');
					columns.push({col: 'passedvalue', type: 'passedvalue', value: deepDischargeLimit});
				}

				let filteredEndVoltageData = this.filterEndVoltage();

				const minEndVoltageData = Math.min.apply(null, this.getFilteredChartArr(filteredEndVoltageData, "endVoltage"));

				let PotentialWeakCellsLimit = 1.7;
				if (this.siteAlertsSettings.deep_use_limit) {
					PotentialWeakCellsLimit = this.siteAlertsSettings.deep_use_limit;
				}

				if (minEndVoltageData <= PotentialWeakCellsLimit) {
					this.showPotentialCheckBox = true;
					if (this.potentialCheckBox)
						columns.push({col: 'passedvalue', type: 'passedvalue', value: PotentialWeakCellsLimit});
				} else
					this.showPotentialCheckBox = false;

				// add a horizontal line at EQ voltage :: As discussed with AMR will ignore eqvoltage
			break;
		}
		return columns;
	}

	private filterData(subTab, getDataFromDailyDetails) {
		let returnData = [];

		switch (subTab) {
			case 'end-voltage':
				returnData = this.filterEndVoltage();
			break;
			default:
				returnData = this.dailyDetailsReport;
				if(!getDataFromDailyDetails)
					returnData = this.eventsReport;
			break;
		}
		return returnData;
	}

	private filterEndVoltage() {
		let filteredEndVoltageData = [];

		this.eventsReport.forEach((item) => {
			if (this.selectedTypes.indexOf(item.type) > -1) {
				if(item.type == this.eventsTypes.charge) {
					item.endVoltage	= item.VPCendVoltage;
					if(this.isCompensatedVoltageCheckBox) {
						item.endVoltage	= item.compensationEndVoltage;
					}
				}
				filteredEndVoltageData.push(item);
			}
		});

		return filteredEndVoltageData;
	}

	private generateChartData(colArray, _data) {
		let resultData = [];

		if(_data){
			var oldValueArray = [];

			for(var element of _data){
				var objArray = [];
				var x = 0;

				for(var item of colArray){
					switch(item.type){
						case "int":
						if (element[item.col] !== undefined) {
							var n = parseInt(element[item.col]);
							n = _.isNumber(n) ? n : 0;

							objArray.push(n);
							oldValueArray[x] = n;
						} else {
							objArray.push(oldValueArray[x] || 0);
						}
						break;

						case "float":
							if (element[item.col] !== undefined) {
								var n = parseFloat(element[item.col]);
								n = _.isNumber(n) ? n : 0;

								objArray.push(n);
								oldValueArray[x] = n;
							} else {
								objArray.push(oldValueArray[x] || 0);
							}
						break;

						case "date":
							var now = new Date();
							var diff = (now.getHours() - now.getUTCHours()) * 3600 * 1000;
							// shift by diff to make it GMT then shift 12 hours to appear date centeralized in chart
							var d = new Date(element[item.col] - diff + (12*3600*1000));
							if (d !== undefined) {
								objArray.push(d);
							} else {
								objArray.push(new Date());
							}
						break;

						case "timeofday":
							var timeOfDay = [];
							var hours = Math.floor(element[item.col] / (1000 * 60 * 60));
							var mins = Math.floor(element[item.col] / (1000* 60) % 60);
							if (hours >= 24) {
								hours = 23;
								mins = 59;
							}
							timeOfDay.push(hours);
							timeOfDay.push(mins);
							timeOfDay.push(0);
							timeOfDay.push(0);
							objArray.push(timeOfDay);
						break;

						case "passedvalue":
							objArray.push(item.value);
						break;
						default:
							if (element[item.col] !== undefined) {
								objArray.push(element[item.col]);
								oldValueArray[x] = element[item.col];
							} else {
								objArray.push(oldValueArray[x] || "");
							}
					}
					x++;
				}

				resultData.push(objArray);
			}
		}

		return resultData;
	}

	private getChartColumnsNames(chartType) {
		let columns: any = [];
		switch(chartType) {
			case 'daily-usage':
				columns = [
					{"type": 'date', "label" : 'Date'},
					{"type": 'timeofday', "label" : 'Charge Duration'},
					{"type": 'timeofday', "label" : 'Inuse Duration'},
					{"type": 'timeofday', "label" : 'Idle Duration'}
				];
			break;
			case 'daily-ebus':
				columns = [
					{"type": 'date', "label" : 'Date'},
					{"type": 'number', "label" : 'EBU'},
				];
				if(this.hasHorizontalLimit)
					columns.push({type: 'number', label: this.horizontalLimitObject.text});
			break;
			case 'daily-ahrs':
				columns = [
					{"type": 'date', "label" : 'Date'},
					{"type": 'number', "label" : 'Charge AHR'},
					{"type": 'number', "label" : 'Inuse AHR'},
				];
				if(this.availableAhrsCheckBox)
					columns.push({"type": 'number', "label" : 'Available AHR'});
			break;
			case 'soc':
				columns = [
					{"type": 'date', "label" : 'Date'},
					{"type": 'number', "label" : 'SOC'}
				];
			break;
			// case 'max-temperature':
			// 	columns = [{"type": 'date', "label" : 'Date'}];
			// 	// if there is any value >=  high temperature value , then add a horizontal line at  high temperature value
			// 	if (this.maxTempCheckBox)
			// 		columns.push({"type": 'number', "label" : 'Max Temperature Limit'});

			// 	columns.push({"type": 'number', "label" : 'Temperature'});
			// break;
			case 'end-temperature':
				columns = [{"type": 'date', "label" : 'Date'}];
				// if there is any value >=  high temperature value , then add a horizontal line at  high temperature value
				if(this.endTempCheckBox)
					columns.push({"type": 'number', "label" : 'Max Temperature Limit'});

				columns.push({"type": 'number', "label" : 'Temperature'});
			break;
			case 'end-voltage':
				columns = [
					{"type": 'date', "label" : 'Date'},
					{"type": 'number', "label" : 'End Voltage'}
				];

				// add a horizontal line for  Deep Discharge Limit
				if(this.inuseLimitCheckBox)
					columns.push({"type": 'number', "label" : 'Deep Discharge Limit'});

				let filteredEndVoltageData = this.filterEndVoltage();

				var minEndVoltageData = Math.min.apply(null, this.getFilteredChartArr(filteredEndVoltageData, "endVoltage"));

				var PotentialWeakCellsLimit = 1.7;
				if (this.siteAlertsSettings.deep_use_limit)
					PotentialWeakCellsLimit = this.siteAlertsSettings.deep_use_limit;

				// if there is any value <=  Potential Weak Cells Limit value , then add a horizontal line at Potential Weak Cells Limit voltage
				if(minEndVoltageData <= PotentialWeakCellsLimit && this.potentialCheckBox)
					columns.push({"type": 'number', "label" : 'Potential Weak Cells Limit'});

			break;
		}
		return columns;
	}

	generateDownloadImage($event, chartType) {
		let chartObj = $event.chart;
		this.printChartLinks[chartType] = this.getChartImageUri(chartObj);
	}

	getChartImageUri(chartObj) {
		let thisChart;
		let image;

		if (typeof chartObj.getChart == "function")
			thisChart = chartObj.getChart();
		else if (typeof chartObj.getImageURI == "function")
			thisChart = chartObj;

		if(thisChart)
			image = thisChart.getImageURI();

		return image;
	}
}
