import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/users/users.service';
import { OrdersService } from '../orders.service';
import { TranslateService } from '@ngx-translate/core';
import { DateRangeSelectComponent } from 'src/app/home/site-dashboard/device/device-dashboard/date-range-select/date-range-select.component';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { cloneDeep } from 'lodash-es';
import { AttachmentsService } from 'src/app/shared/attachments/attachments.service';
import { ColumnsConfig, TableConfig, TableData} from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';
import { SCTCustomTable } from 'sct-custom-table/sct-table/projects/sct-table/src/lib/sct-table.component';
import { CommonDataService } from 'src/app/shared/services/common-data.service';
import { DomainsService } from 'src/app/shared/services/domains.service';

@Component({
	selector: 'app-manage-orders',
	templateUrl: './manage-orders.component.html',
})
export class ManageOrdersComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('downloadFilesDialog') downloadFilesDialog: ModalComponent;
	@ViewChild("addAttachmentsDialog") addAttachmentsDialog: ModalComponent;
	@ViewChild("successAttachmentsDialog") successAttachmentsDialog: ModalComponent;
	@ViewChild('deleteAttachmentsModal') deleteAttachmentsModal: ModalComponent;

	@ViewChild('uploadAttachmentsBtn') uploadAttachmentsBtn: ElementRef<HTMLInputElement>;
	@ViewChild('uploadAttachmentsBtnLabel', { read: ElementRef }) uploadAttachmentsBtnLabel: ElementRef;

	// table config
	@ViewChild("sctCustomTable", { static: true }) sctCustomTable!: SCTCustomTable;
	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: true,
		draggable: true,
		isBackendPagination: true,
		pageSize: 20
	};

	columnConfig: ColumnsConfig[] = [];
	tableData: TableData[] = [];

	filter: any = {};
	sort: any = {};

	idField: 'id' | 'shipping_date' = 'id';
	activeTable = 'unshipped';

	selectedFiles: any = [];
	selectedFilesValidations: any = {};
	successAttachments: any = {};
	selectedHistory: any;
	orderAttachedFiles: any[] = [];
	shippingAttachedFiles: any[] = [];
	selectedFilesToDownload: any = {};
	deletedAttachmentId: number = null;
	filesAlreadyUploaded: any = {};

	filterBySippingStatus: any[] = [
		{ id: (this.ordersService.getStatusesByKey()).NEW, label: this.translate.instant('orders.new') },
		{ id: (this.ordersService.getStatusesByKey()).IN_PROCESS, label: this.translate.instant('orders.in_process') },
		{ id: (this.ordersService.getStatusesByKey()).READY_TO_SHIP, label: this.translate.instant('orders.ready_to_ship') },
	];

	filterOrdersByDeviceTypes: Array<{ id: number, label: string }> = [
		{ id: this.ordersService.ORDERS_FILTER_BY_DEVICE_TYPE.ChargLink, label: this.translate.instant('g.charglink') },
		{ id: this.ordersService.ORDERS_FILTER_BY_DEVICE_TYPE.IoTAh, label: this.translate.instant('g.iotah') },
		{ id: this.ordersService.ORDERS_FILTER_BY_DEVICE_TYPE.IoTAh_PS, label: this.translate.instant('g.iotah_ps') }
	];

	sippingStatus = null;
	filterByDeviceType: number[] = [];

	orders: any[] = [];
	usersOptions = [];

	selectedCriteria = {
		field: '',
		value: null,
		startDate: null,
		endDate: null
	};

	criteria = {
		field: '',
		value: null,
		startDate: null,
		endDate: null
	};

	STATUSES = this.ordersService.STATUSES;
	SALES_CHANNEL = this.ordersService.SALES_CHANNEL;
	SEARCH_CRITERIA = this.ordersService.SEARCH_CRITERIA;

	mappedOrderStatus = this.ordersService.getStatusesByKey();
	selectedOrderInfo: any = {};
	trackingNumber: string = '';
	shippingCompany: string = '';
	shippingDate: Date = new Date();
	trackingNumberError = '';
	shippingCompanyError = '';
	trackingNumberMaxLength = 200;
	selectedOrderToDelete: any = {};

	currentSearchType: string = '';
	nextSearchType: string = '';
	searchApplied: boolean = false;

	readonly ordersCountLimit: number = 20;

	successCounter = 0;

	@ViewChild("trackingNumberModal", {static: true}) trackingNumberModal: ModalComponent;
	@ViewChild('dateRangePicker') dateRangePicker!: DateRangeSelectComponent;
	@ViewChild("searchOrdersForm") searchOrdersForm: NgForm;
	@ViewChild("deleteNewOrderModal") deleteNewOrderModal: ModalComponent;

	constructor(
		public userService: UsersService,
		private router: Router,
		private ordersService: OrdersService,
		private translate: TranslateService,
		private notificationMessage: NotificationMessageService,
		private sideMenuService: SideMenuService,
		public attachmentsService: AttachmentsService,
		public commonData: CommonDataService,
		private domainsService: DomainsService,
	) { }

	ngOnInit(): void {
		this.activeTable = this.ordersService.getActiveTab();

		if(this.domainsService.isDevelopmentDomain)
			this.tableConfig.pageSize = 5;

		this.sideMenuService.hide();
		if (!this.userService.hasAccessFunction('create_orders') &&
			!this.userService.hasAccessFunction('view_orders') &&
			!this.userService.hasAccessFunction('shipment_management')
		)
			this.router.navigate(['/unauthorized']);

		this.getOrders({currentPage: 1});
	}

	ngAfterViewInit() {
		this.downloadFilesDialog.onClose.subscribe((result) => {
			if(!result)
				return this.selectedFilesToDownload = {};
			this.downloadFilesDialog.hide();
			this.downloadFiles(Object.keys(this.selectedFilesToDownload));
			return this.selectedFilesToDownload = {};
		});

		this.deleteNewOrderModal.onClose.subscribe((ok) => {
			if (ok) {
				this.deleteSelectedOrder(this.selectedOrderToDelete);
			} else {
				this.deleteNewOrderModal.hide();
				this.selectedOrderToDelete = {};
			}
		})
	}

	setColumnConfig() {
		this.columnConfig = [
			{key: 'po_number', name: this.translate.instant('ready_to_ship.purchase_order'), type: 'string'},
			{key: 'created_at', name: this.translate.instant('users.create_time'), type: 'date'},
		];

		if (this.activeTable == 'shipped') {
			this.columnConfig.push({key: 'calculated_shipping_date', name: this.translate.instant('users.shipping_time'), type: 'date'});
		} else {
			const unshippedOrderStatuses = [...this.ordersService.STATUSES_OPTIONS].filter((status) => status.value !== +this.mappedOrderStatus.SHIPPED);
			this.columnConfig.push(
				{key: 'created_by', name: this.translate.instant('users.create_by'), type: 'link', items: this.usersOptions, filterType: 'dropdown'},
				{key: 'status', name: this.translate.instant('sim.status'), type: 'string', filterType: 'dropdown', items: unshippedOrderStatuses}
			);
		}

		this.columnConfig.push(
			{key: 'receiver_name', name: this.translate.instant('orders.receiver_name'), type: 'string'},
			{key: 'receiver_company_name', name: this.translate.instant('orders.receiver_company_search'), type: 'string'},
			{key: 'charglink_count', name: this.translate.instant('orders.charglink_count'), type: 'number'},
			{key: 'iotah_count', name: this.translate.instant('orders.iotah_count'), type: 'number'},
			{key: 'study_count', name: this.translate.instant('orders.ps_count'), type: 'number'},
		);

		if (this.activeTable != 'shipped') {
			this.columnConfig.push({key: 'charglink_used', name: this.translate.instant('orders.charglink_used'), type: 'number'});
			this.columnConfig.push({key: 'iotah_used', name: this.translate.instant('orders.iotah_used'), type: 'number'});
			this.columnConfig.push({key: 'study_used', name: this.translate.instant('orders.ps_used'), type: 'number'});
		}

		this.columnConfig.push(
			{key: 'sales_channel', name: this.translate.instant('orders.sales_channel'), type: 'string', filterType: 'dropdown', items: this.ordersService.SALES_CHANNEL_OPTIONS, hasSort: false},
			{key: 'view', name: this.translate.instant('g.view'), type: 'link', hasSort: false, hasFilter: false},
		);

		if (this.activeTable != 'shipped')
			this.columnConfig.push({key: 'actions', name: this.translate.instant('g.actions'), type: 'dropdown'});

		this.columnConfig.push({key: 'attachments', name: this.translate.instant('ready_to_ship.attachments'), type: 'icon'});
	}

	handleUploadedFiles() {
		const newAddedFiles = this.selectedFiles.filter(file => !(file.name in this.filesAlreadyUploaded)).map(file => file.name);

		if (this.selectedFiles.length && this.successCounter == this.selectedFiles.length) {
			this.selectedFiles = [];
			this.successAttachments = {};
			this.successAttachmentsDialog.hide();
		}

		if (this.successCounter && newAddedFiles.length == this.successCounter) {
			this.ordersService.sentAttachmentEmail(this.selectedHistory.id, this.selectedHistory.po_number, newAddedFiles, 'order', false).subscribe();
			this.getOrders({currentPage: 1});

			return this.notificationMessage.setMessage('globalSuccessMsg', {clearOnXTimeNavigate: 1});
		}
	}

	getOrders(paginationData) {
		if(!this.sctCustomTable)
			return;

		if(paginationData.currentPage == 1)
			this.sctCustomTable.backendPaginationInit();

		const criteria = this.criteria;
		const sippingStatus = this.activeTable == "shipped" ? (this.ordersService.getStatusesByKey()).SHIPPED : this.sippingStatus;
		const options: any = {
			criteria: JSON.stringify(criteria),
			shipped: this.activeTable == "shipped",
			sippingStatus: sippingStatus,
			filterByDeviceType: this.filterByDeviceType,
			currentPage: paginationData.currentPage,
			limit: this.sctCustomTable.config.pageSize,
			getCreators: this.usersOptions.length == 0,
			sort: JSON.stringify(paginationData.sort || {}),
			filters: JSON.stringify(paginationData.filters || {}),
		};

		this.filter = options.filters;
		this.sort = options.sort;

		this.ordersService.getOrders(options).subscribe((res: any) => {
			const {orders, totalDataCount, allCreatorsOptions} = res;
			this.orders = orders;
			if (allCreatorsOptions.length && !this.usersOptions.length)
				this.usersOptions = allCreatorsOptions;

			if (!this.columnConfig.length)
				this.setColumnConfig();

			this.fillTableData();
			this.sctCustomTable.updatePagination(totalDataCount);
		});
	}

	exportToCsvFile() {
		const sippingStatus = this.activeTable == "shipped" ? (this.ordersService.getStatusesByKey()).SHIPPED : this.sippingStatus;
		const criteria = this.criteria;

		const options: any = {
			criteria: JSON.stringify(criteria),
			shipped: this.activeTable == "shipped",
			sippingStatus: sippingStatus,
			filterByDeviceType: this.filterByDeviceType,
			limit: 1000000,
			currentPage: 1,
			getCount: false,
			columns: this.getTableColumn(),
			userTimeZoneDiff: new Date().getTimezoneOffset() * -1
		};

		if (this.filter)
			options.filters = this.filter;

		if (this.sort)
			options.sort = this.sort;

		this.ordersService.exportOrdersCSV(options).subscribe((data: any) => {
			const blob = new Blob([data], { type: 'text/csv' });
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;

			let fileName = this.translate.instant('orders.scv_file_unshipped') + '.csv';
			if (this.activeTable == 'shipped')
				fileName = this.translate.instant('orders.scv_file_shipped') + '.csv';

			a.download = fileName;
			a.click();

			window.URL.revokeObjectURL(url);
		});
	}

	fillTableData() {
		const hasEditUserAdminAccess = this.userService.hasAccessFunction('edit_user_admin')
		const tableData = [];

		for (const order of this.orders) {
			const orderObject:any = {
				po_number: {value: order.po_number},
				created_at: {value: order.created_at ? moment.unix((new Date(order.created_at).getTime() || 0) / 1000).format('MM/DD/yyyy hh:mm:ss a') : null},
				status: {value: this.translate.instant(`orders.${this.toLowerCase(this.STATUSES[order.status])}`)},
				receiver_name: {value: order.receiver_name},
				receiver_company_name: {value: order.receiver_company_name},
				iotah_count: {value: order.iotah_count},
				charglink_count: {value: order.charglink_count},
				study_count: {value: order.study_count},
				sales_channel: {value: this.translate.instant(`orders.${this.toLowerCase(this.SALES_CHANNEL[order.sales_channel])}`)},
				view: {value: this.translate.instant(`g.view`), link: ['/orders/view', order.id]},
				attachments: this.prepareAttachmentIcons(order)
			};

			if (this.activeTable == 'shipped') {
				orderObject.calculated_shipping_date = {value: this.formateShippingDate(order.shipping_date)};
			} else {
				orderObject.created_by = this.getCreatedByUser(order, hasEditUserAdminAccess);
				orderObject.iotah_used = {value: order.iotah_used};
				orderObject.charglink_used = {value: order.charglink_used};
				orderObject.study_used = {value: order.study_used};
				orderObject.actions = {value: null, options: this.prepareActionOptions(order)}
			}

			tableData.push(orderObject);
		}

		this.tableData = tableData;
	}

	getCreatedByUser(order, hasEditUserAdminAccess) {
		if (!order.is_deleted_user)
			return {
				value: order.created_by_user_name ? order.created_by_user_name : '-',
				link: hasEditUserAdminAccess ? ['/user', 'edit', order.created_by] : null
			}
		else
			return {value: this.translate.instant('users.deleted_user'), link: null};
	}

	prepareActionOptions(order) {
		const options = [];

		if (this.showEditOrderOption(order.status))
			options.push({text: this.translate.instant('g.edit'), link: ['/orders/edit', order.id]});

		if (this.showAddTrackingNumberOption(order.status)) {
			options.push({text: this.translate.instant('orders.add_tracking_number'), action: () => {
				this.trackingNumberModal.show();
				this.selectedOrderInfo = order;
			}});
		}

		if (this.showDeleteOrder(order.status)) {
			options.push({text: this.translate.instant('g.delete'), action: ()=> {
				this.deleteNewOrderModal.show();
				this.selectedOrderToDelete = order;
			}});
		}

		return options;
	}

	prepareAttachmentIcons(order) {
		const attachmentsObject = {value: null, icon: null, action: null};

		if (this.showAttachmentsButton(order)) {
			attachmentsObject.icon = 'paperclip';
			attachmentsObject.action = () => this.openDownloadDialog(order);
		}

		if (this.userService.hasAccessFunction('create_orders') && !this.showAttachmentsButton(order)) {
			attachmentsObject.icon = 'plus';
			attachmentsObject.action = () => this.openAddDialog(order);
		}

		return attachmentsObject;
	}

	addTrackingNumber() {
		if (this.trackingNumberValidation(this.trackingNumber, this.shippingCompany)) {
			const shippedOrderStatus = (this.mappedOrderStatus).SHIPPED;
			this.orders.find(item => item.id == this.selectedOrderInfo.id).status = shippedOrderStatus;

			const zoneDiff = new Date().getTimezoneOffset() * -1;
			this.ordersService.addTrackingNumber(this.selectedOrderInfo.id, this.trackingNumber, this.shippingCompany, zoneDiff, this.shippingDate).subscribe((res: any) => {
				switch (res.api_status) {
					case 2:
						this.trackingNumberError = this.translate.instant('g.max_length', { max: this.trackingNumberMaxLength });
						break;
					default:
						this.trackingNumber = '';
						this.trackingNumberModal.hide();
						this.notificationMessage.setMessage('globalSuccessMsg');
						this.getOrders({currentPage: 1});
					break;
				}
			});
		}
	}

	trackingNumberValidation(trackingNumber: string, shippingCompany: string) {
		trackingNumber = trackingNumber.trim();
		shippingCompany = shippingCompany.trim();

		if (!trackingNumber || !shippingCompany) {
			if (!trackingNumber)
				this.trackingNumberError = this.translate.instant('g.field_is_required');

			if (!shippingCompany)
				this.shippingCompanyError  = this.translate.instant('g.field_is_required');

			return false;
		}

		if (trackingNumber.length > this.trackingNumberMaxLength) {
			this.trackingNumberError = this.translate.instant('g.max_length', { max: this.trackingNumberMaxLength });
			return false;
		}

		return true;
	}

	showAddTrackingNumberOption(status: any) {
		return status == (this.ordersService.getStatusesByKey()).READY_TO_SHIP && (this.userService.hasAccessFunction('create_orders') || this.userService.hasAccessFunction('shipment_management'));
	}

	onDatesChanged(date: any) {
		this.selectedCriteria.value = date;
	}

	toLowerCase(text: string) {
		return text.toLocaleLowerCase();
	}

	selectCriteria(searchType: string) {
		this.currentSearchType = searchType;

		if(this.nextSearchType == 'date' || this.currentSearchType == 'date')
			this.selectedCriteria.value = null;

		this.nextSearchType = searchType;
	}

	showEditOrderOption(status: any) {
		return this.userService.hasAccessFunction('create_orders') && status != (this.mappedOrderStatus).SHIPPED;
	}

	setActiveTable(table: string) {
		this.activeTable = table;
		this.ordersService.setActiveTab(this.activeTable);

		if (this.activeTable == 'shipped')
			this.idField = 'shipping_date';
		else
			this.idField = 'id';

		this.setColumnConfig();
		this.getOrders({currentPage: 1});
	}

	getOrdersBySearchCriteria() {
		this.criteria = { ...this.selectedCriteria };
		this.getOrders({currentPage: 1});
		this.searchApplied = true;
	}

	shouldEnableSearchBtn(criteria) {
		const areDatesMissing = !criteria.startDate || !criteria.endDate;
		return !criteria.field || (!criteria.value && areDatesMissing);
	}

	resetFormData() {
		this.searchOrdersForm.reset();
		this.criteria = {
			field: '',
			value: null,
			startDate: null,
			endDate: null
		}
		this.getOrders({currentPage: 1});
		this.searchApplied = false;
	}

	openDownloadDialog(order: any) {
		this.selectedHistory = order;
		this.orderAttachedFiles = order.attachments;
		this.shippingAttachedFiles = order.shipping_attachments;

		let counter = 1;
		const shipmentAttachedFilesCount = Object.keys(this.shippingAttachedFiles).length;
		for (const id in this.shippingAttachedFiles) {
			this.shippingAttachedFiles[id].counter = counter;
			this.shippingAttachedFiles[id].is_last_shipment = counter == shipmentAttachedFilesCount;
			counter++;
		}

		this.downloadFilesDialog.title = this.translate.instant('orders.order') + ' ' + order.po_number + ' ' + this.translate.instant('ready_to_ship.attachments');
		this.selectedFilesToDownload = {};
		this.successAttachments = {};
		this.successCounter = 0;
		this.downloadFilesDialog.show();
	}

	openAddDialog(order: any) {
		this.addAttachmentsDialog.title = order.po_number + ' ' + this.translate.instant('ready_to_ship.attachments');
		this.selectedFiles = [];
		this.selectedHistory = order;
		this.uploadAttachmentsBtn.nativeElement.value = '';
		this.selectedFilesValidations = [];
		this.selectedFilesToDownload = {};
		this.addAttachmentsDialog.show()
	}

	getDownloadButtonStatus() {
		return !Object.keys(this.selectedFilesToDownload).length;
	}

	selectFiles(events: any) {
		this.selectedFiles = Object.values(events.target.files);
		//this.uploadAttachmentsBtnLabel.innerText = this.uploadAttachmentsBtn.value;
	}

	showValidationErrors() {
		return Object.keys(this.selectedFilesValidations).length;
	}

	selectFile(event: any, file: any, isShipping=false) {
		const isChecked = event.target.checked;

		let fileId = 0;
		if (isShipping) {
			fileId = file.id;
			file = file.file;
		} else {
			file = file.file_name;
		}

		if (isChecked)
			return this.selectedFilesToDownload[`${file}%%${fileId}`] = isChecked;

		delete this.selectedFilesToDownload[`${file}%%${fileId}`];
	}

	addAttachments() {
		if (!this.selectedFiles.length)
			return this.selectedFilesValidations = { ...this.selectedFilesValidations, min_count: true };

		this.selectedFilesValidations = this.attachmentsService.validate({ files: this.selectedFiles, note: '' });
		if (Object.keys(this.selectedFilesValidations).length)
			return;

		this.filesAlreadyUploaded = {};
		this.addAttachmentsDialog.hide();
		this.successAttachmentsDialog.show();

		let counter = 0;

		for (const file of this.selectedFiles) {
			let formData = new FormData();

			formData.append("file", file);
			formData.append('sourceId', this.selectedHistory.id.toString());
			formData.append('source', 'order');
			formData.append('note', '');

			this.attachmentsService.addAttachments(formData).subscribe((attachment: any) => {
				switch (attachment.api_status) {
					case 3:
						this.filesAlreadyUploaded = cloneDeep({...this.filesAlreadyUploaded, [file.name]: true});
						break;
					default:
						this.successAttachments = cloneDeep({...this.successAttachments, [attachment.file_name]: true});
						this.successCounter++;
						break;
				}

				counter++;
				if (counter == this.selectedFiles.length)
					this.handleUploadedFiles();
			});
		}
	}

	downloadFiles(files?: string[]) {
		const downloadFiles = [];
		for(const file of files) {
			const fileParts = file.split('%%');
			const shipmentFilesPath = `shipping-out-reports/${this.selectedHistory.po_number}/${+fileParts[1]}`;
			downloadFiles.push({file: fileParts[0], source: +fileParts[1] > 0 ? shipmentFilesPath : 'order_attachments/' + this.selectedHistory.id });
		}
		this.attachmentsService.downloadAttachments('order', this.selectedHistory.id, downloadFiles, true);
	}

	showDeleteAttachmentModel(attachmentId: number) {
		this.deletedAttachmentId = attachmentId;
		this.deleteAttachmentsModal.show();
	}

	deleteAttachment() {
		if(!this.deletedAttachmentId)
			return;

		this.attachmentsService.deleteAttachment('order', this.selectedHistory.id, this.deletedAttachmentId).subscribe((data: any) => {
			let filename = '';
			delete this.selectedHistory.attachments.filter((item: any) => {
				if(this.deletedAttachmentId == item.id)
					filename = item.file_name;
				return this.deletedAttachmentId == item.id
			})[0];
			this.deletedAttachmentId = null;
			this.deleteAttachmentsModal.hide();
			this.downloadFilesDialog.hide();
			this.ordersService.sentAttachmentEmail(this.selectedHistory.id, this.selectedHistory.po_number, [filename], 'order', true).subscribe();
			this.getOrders({currentPage: 1});
			return this.notificationMessage.setMessage('globalSuccessMsg', {clearOnXTimeNavigate: 1});
		});
	}

	formateShippingDate(shipping_date: any){
		return shipping_date ? moment.unix(shipping_date).utc().format('MM/DD/YYYY') : '-';
	}

	showAttachmentsButton(order: any) {
		return order.attachments.length || Object.keys(order.shipping_attachments).length;
	}

	showDeleteOrder(status: any) {
		return this.userService.hasAccessFunction('create_orders') && status == (this.mappedOrderStatus).NEW;
	}

	deleteSelectedOrder(order: any) {
		this.ordersService.deleteOrder(order.id).subscribe((response: any) => {
			switch(response) {
				case 1:
					this.notificationMessage.setMessage('globalSuccessMsg');
					this.selectedOrderToDelete = {};
					this.getOrders({currentPage: 1});
					break;
				default:
					this.notificationMessage.setMessage('globalErrMsg');
					break
			}
		});
	}

	resetAddTrackingModal() {
		this.trackingNumber = this.shippingCompany = '';
		this.shippingDate = new Date();
		this.trackingNumberModal.hide();
	}

	ngOnDestroy(): void {
		this.ordersService.shouldBackToSelectedTab = false;
	}

	getTableColumn() {
		let columns = [];
		if (this.sctCustomTable)
			columns = this.sctCustomTable.shownColumnsConfig.map((column) => column.key);

		return columns.filter((column) => !['view', 'actions', 'attachments'].includes(column));
	}
}
