import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { interval, Subscription, switchMap } from 'rxjs';
import { ColumnsConfig, TableConfig, TableData } from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';
import { SitesService } from 'src/app/sites/sites.service';
import { UsersService } from 'src/app/users/users.service';

@Component({
	selector: 'app-charglink-brm-site-history',
	templateUrl: './charglink-brm-site-history.component.html',
	styleUrls: ['./charglink-brm-site-history.component.css']
})
export class CharglinkBrmSiteHistoryComponent {
	@Input() siteId: number;
	@Input() customerId: number;
	private buzzSoundSubscription: Subscription = new Subscription();

	columnConfig: ColumnsConfig[] = [
		{ key: 'battery_name', name: this.translateService.instant("brmDevice.g.iotah_sn"), type: 'link' },
		{ key: 'truck_type', name: this.translateService.instant("charglink_brm.brm_group"), type: 'string' },
		{ key: 'date', name: this.translateService.instant("g.date"), type: 'timestamp', dateFormat: 'YYYY/MM/DD hh:mm:ss A' },
		{ key: 'type', name: this.translateService.instant("g.type"), type: 'string' },
		{ key: 'sub_type', name: this.translateService.instant("g.sub_type"), type: 'string' },
	];

	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: true,
		pageSize: 100,
		fitScreen: true,
		hideNoData: true
	};

	filter = {
		siteId: 0,
		customerId: 0,
		fromTimeLocal: moment().subtract(8, 'days').startOf('day').format('YYYY-MM-DDTHH:mm'),
		toTimeLocal: moment().format('YYYY-MM-DDTHH:mm'),
	};

	activeTable: 'pick' | 'miss_pick' = 'pick';

	constructor(
		private sitesService: SitesService,
		private translateService: TranslateService,
		private usersService: UsersService,
		private router: Router
	) { }

	ngOnInit() {
		if(!this.usersService.hasAccessFunction('show_charglink_brm'))
			return this.router.navigate(['/unauthorized']);

		this.getSiteHistory();
	}

	setActiveTable(table: 'pick' | 'miss_pick') {
		this.activeTable = table;

		this.getSiteHistory();
	}

	getSiteHistory() {
		const filters = {
			siteId: this.siteId,
			isMissed: this.activeTable === 'miss_pick',
			fromDate: this.filter.fromTimeLocal,
			toDate: this.filter.toTimeLocal,
		};

		this.sitesService.getSitePickLogs(filters).subscribe((res: any) => {
			const groups = res.groups;
			const devices = res.devices;
			const data = res.data;
			const tableData = [];

			for (const row of data) {
				const rowData = {
					battery_name: { value: devices[row.mac_address].serialNumber, link: ['/', this.customerId, this.siteId, row.mac_address, 'performance'] },
					truck_type: { value: groups[row.brm_group_id] },
					date: { value: row.created_at },
					type: { value: row.type },
					sub_type: { value: row.subtype },
				}

				tableData.push(rowData);
			}

			this.tableData = tableData;
		});
	}

	applyFilter() {
		this.getSiteHistory();
	}

	formatTime(field, value: string) {
		this.filter[field] = moment(value).format('YYYY/MM/DD HH:mm:ss');
	}

	ngOnDestroy() {
		this.buzzSoundSubscription.unsubscribe();
	}
}
