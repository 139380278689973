import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-site-fleet-utilization-score',
	templateUrl: './site-fleet-utilization-score.component.html',
	styleUrls: ['./site-fleet-utilization-score.component.css']
})
export class SiteFleetUtilizationScoreComponent {
	@Input() data = null;
	@Input() devices = null;
}
