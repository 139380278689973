import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { UsersService } from 'src/app/users/users.service';
import { DeviceManagementService } from '../device-management.service';
import { DeviceFactory } from 'src/app/home/site-dashboard/device/device-factory/device-factory';

@Component({
	selector: 'app-replace-device',
	templateUrl: './replace-device.component.html',
	styleUrls: ['./replace-device.component.css']
})
export class ReplaceDeviceComponent implements OnInit {
	@Input() device;

	deviceLogoClass: string = '';
	nowTime: number = new Date().getTime() / 1000;

	disconnectionLimit: number = 60*60*24*7;
	macAddress: string;
	getHitted: boolean = false;
	newDevice: any;
	newDeviceImageLink: string;
	siteID: number;
	customerID: number;
	hasResult: boolean = false;

	constructor(
		private deviceManagementService: DeviceManagementService,
		private notificationMessageService: NotificationMessageService,
		private usersService: UsersService,
		private router: Router,
		private route: ActivatedRoute,
	) { }

	ngOnInit() {
		if(!this.usersService.hasAccessFunction('replace_device'))
			return this.router.navigate(['/unauthorized']);

		this.siteID = this.route.parent.snapshot.params.siteId || 0;
		this.customerID = this.route.parent.snapshot.params.customerId || 0;
	}

	getDevice() {
		this.hasResult = false;
		this.newDevice = {};
		if(this.macAddress == this.device.mac_address)
			return this.notificationMessageService.setMessage('translate|replace_device.cannot_use_same_device', {clearOnXTimeNavigate: 1});

		this.deviceManagementService.searchDevices(this.macAddress, 'mac', false, 'no').subscribe((data: any) => {
			this.getHitted = true;
			// let devicePath = [];
			// let sitePath = [];
			let device: any;
			if(data.devices.length) {
				device = data.devices[0];
				if(data.sites && data.sites[device.site_id] && !device.is_deleted) {
					// let customerId = data.sites[device.site_id].customer_id;
					// devicePath = ['/', customerId, device.site_id, device.mac_address, 'performance'];
					// sitePath = ['/', customerId, device.site_id];
					device.site = data.sites[device.site_id];
				}
				this.hasResult = true;
			}
			this.newDevice = device;

			const deviceConfig = DeviceFactory.createDevice(device.sct_device_type);
			this.deviceLogoClass = deviceConfig.config.logoStyleClass;

			if (device.is_study_device)
				this.deviceLogoClass += ' study-logo';

			this.newDeviceImageLink = deviceConfig.getImagePath(device.connected ? "connected" : "not_connected", device);
		});
	}

	replaceDevice() {
		this.deviceManagementService.replaceDevice(this.device.mac_address, this.newDevice.mac_address).subscribe((data: any) => {
			if(data.err)
				return this.notificationMessageService.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});
			this.notificationMessageService.setMessage('globalSuccessMsg');

			this.router.navigate(['/', this.customerID, this.siteID]);
		});
	}
}
