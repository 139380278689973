import { Injectable } from '@angular/core';
import { SiteWidget } from './widget.interface';
import { ChartDataService } from 'src/app/shared/chart-data-widget/chart-data.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class BattlinkSitePerformanceService {
	devices = {};
	truckTypes = [];

	constructor(
		private chartDataService: ChartDataService,
	) { }

	generateWidgetData(widget: SiteWidget, widgetsdata: any, devices: any, deviceTags: any) {
		widget.hideWidget = false;
		this.devices = devices;
		this.truckTypes = this.truckTypes;
		const selectedDateRange = widget.selectedDateRange;

		if (!widgetsdata[widget.widgetId] || !widgetsdata[widget.widgetId][selectedDateRange]) {
			widget.data = [];
			return widget.hideWidget = true;
		}

		const data = widgetsdata[widget.widgetId][selectedDateRange];

		// filter devices
		let filteredDevices = [];

		if (!devices) {
			widget.hideWidget = true;
			return widget.data = [];
		}

		if (Object.keys(devices).length)
			filteredDevices = {...devices};

		if (widget.filterByTag)
			filteredDevices = this.chartDataService.filterDevicesByTags(filteredDevices, widget.selectedTags, deviceTags);

		if (widget.filterByType)
			filteredDevices = this.filterDevicesByType(filteredDevices, widget.selectedType);

		if(!Object.keys(filteredDevices).length) {
			widget.data = [];
			return widget.hideWidget = true;
		}

		// get available days and default count of days
		const availableDaysByDevices = this.getAvailableDaysByDevices(widget, selectedDateRange, widgetsdata);
		const defaultCountOfDays = this.chartDataService.getCountOfDaysForPeriod(selectedDateRange);

		const formattedWidgetData = widget.formateWidgetData(widget, data, filteredDevices, defaultCountOfDays, availableDaysByDevices);
		if(!Object.keys(formattedWidgetData).length)
			return widget.data = [];

		let chartData = [];
		let tableData = {};

		if (widget.groups && !widget.groupFunction)
			({chartData , tableData} = this.groupData(widget, formattedWidgetData, filteredDevices));

		if (widget.groupFunction)
			({chartData, tableData} = widget.groupFunction(widget, formattedWidgetData, filteredDevices));

		widget.data = chartData;
		widget.tableData = tableData;
	}

	getAvailableDaysByDevices(widget: SiteWidget, dateRange: string, data: any) {
		const widgetData = data[widget.widgetId];
		return widgetData.available_days && widgetData.available_days[dateRange] ? widgetData.available_days[dateRange] : {};
	}

	groupData(widget: SiteWidget, widgetData: any, devices: any): { chartData: any, tableData: any } {
		const chartData = [];
		const tableObjects: any = {};
		let counter = 0;
		let countOfStackedItems = this.getCountOfStackedItems(widget);
		let groups = widget.groups;

		// prepare chart data and table objects
		for(let group of groups) {
			let itemp = [];

			itemp.push(group[0]);

			for(let i = 0; i < countOfStackedItems; i++) {
				itemp.push(0);
			}
			chartData.push(itemp);
			tableObjects[counter] = [];
			counter++;
		}

		// fill chart data and table objects
		for(let itemId in widgetData) {
			let totalValue	= widgetData[itemId].total;
			let maxValue	= widgetData[itemId].max;
			let avgValue = widgetData[itemId].avg;
			let index = 0;
			let macAddress = itemId;

			if(!devices[macAddress])
				continue;

			for(let groupIndex in groups) {
        let groupByValue = widget.groupByAverage ? avgValue : totalValue;
				let scaledAvgValue = avgValue / widget.scale;

				if(groupByValue < groups[groupIndex][1] || +groupIndex == (groups.length-1)) {
					index = +groupIndex;
					let scaledTotalValue = totalValue / widget.scale;

					tableObjects[index].push({
						mac: macAddress,
						serial_number: devices[macAddress].serial_number,
						truck_id: devices[macAddress].truck_id,
						totalValue: widget.dataFormatter ? widget.dataFormatter(scaledTotalValue) : scaledTotalValue,
						maxValue: widget.dataFormatter ? widget.dataFormatter(maxValue) : maxValue,
						scaledAvgValue,
						avgValue: widget.dataFormatter ? widget.dataFormatter(scaledAvgValue) : scaledAvgValue,
						eventId: itemId
					});

					break;
				}
			}

			let deviceStackedIndex = this.getDeviceStackedIndex(macAddress, widget);
			chartData[index][deviceStackedIndex]++;
		}

		// prepare chart colors
		for (let i = 0; i < chartData.length; i++) {
			chartData[i].push(widget.config.colors[i]);
		}

		return { chartData, tableData: tableObjects };
	}

	private getDeviceStackedIndex(macAddress: string, widget: SiteWidget): number {
		let device = this.devices[macAddress];
		let index = 1;

		if (widget.stackedItems?.length) {
			for (let i = 0; i < widget.stackedItems.length; i++) {
				if (device[widget.stackedItems[i]] === widget.selectedDropDown.value) {
					index = i + 1;
					break;
				}
			}
		}

		return index;
	}

	private filterDevicesByType(devices: any, type: string) {
		if (!type)
			return devices;

		if (type === "all")
			return devices;

		const returnDevices = {};
		for (let macAddress in devices) {
			if (devices[macAddress].truck_type === type)
				returnDevices[macAddress] = devices[macAddress];
		}

		return returnDevices;
	}

	getCountOfStackedItems(widget: SiteWidget): number {
		let countOfStackedItems = 1;

		if (widget.stackedItems?.length)
			countOfStackedItems = widget.stackedItems.length;

		return countOfStackedItems;
	}

	getCountOfDaysAndAverage(data: any, defaultCountOfDays: number, macAddress: string, availableDaysByDevices: any): any {
		let countOfDays = defaultCountOfDays;
		let avg = 0;

		if(availableDaysByDevices[macAddress])
			countOfDays = availableDaysByDevices[macAddress];

		if(countOfDays > 0)
			avg = data[macAddress] / countOfDays;

		return { countOfDays, avg };
	}
	hoursMinFormatter(value: number): string {
		let timeValue: any = moment.duration(value, 'hours');

		timeValue = {
			hrs: timeValue.days() * 24 + timeValue.hours(),
			min: timeValue.minutes()
		}

		for(let t in timeValue)
			if(timeValue[t] < 10) timeValue[t] = '0'+timeValue[t];

		return timeValue.hrs + ':' + timeValue.min;
	}

	getElapsedMonths(start, end) {
		let months;
		months = (end.getFullYear() - start.getFullYear()) * 12;
		months -= start.getMonth() + 1;
		months += end.getMonth();
		return months <= 0 ? 0 : months;
	}

	updateTagsList(widgetConfig: SiteWidget, tags: any[]) {
		let tagsList = widgetConfig.dropDownGroupList.find((item) => item.name === 'tag');
		for (const tag of tags) {
			tagsList.options.push({label: tag.tag_name, value: tag.id});
		}
	}

	updateTypesList(devices: any) {
		for (let macAddress in devices) {
			let truckType = devices[macAddress].truck_type;
			if (truckType && this.truckTypes.findIndex((item) => item.value === truckType) === -1) {
				this.truckTypes.push({label: truckType, value: truckType});
			}
		}
	}

	updateTypesListForWidget(widgetConfig: SiteWidget) {
		const widgetTypesList = widgetConfig.dropDownGroupList.find((item) => item.name === 'truck_type');
		for (let type of this.truckTypes) {
			widgetTypesList.options.push({label: type.label, value: type.value});
		}
	}

	updateFilter(widgetConfig: SiteWidget, filter: any) {
		const updatedFilter = filter[0];
		const selectedValue = filter[1];

		switch(updatedFilter) {
			case 'date_range':
				widgetConfig.selectedDateRange = selectedValue == 'all' ? [] : selectedValue;
				break;
			case 'truck_type':
				widgetConfig.selectedType = selectedValue == 'all' ? [] : selectedValue;
				break;
			case 'tag':
				widgetConfig.selectedTags = selectedValue == 'all' ? [] : selectedValue;
				break;
		}
	}
}
