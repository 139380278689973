<div class="white-box main-black mt-4 p-4 border-radius mb-2">
	<h4 class="mb-3">
		{{"battery_performance.title" | translate}}
	</h4>
	<div class="row py-4">
		<div class="col-sm-6 col-md-9 col-xl-7 d-inline-flex">
			<div class="input-group col-6 p-0 mr-3 d-inline-flex">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.start_date' | translate}}</span>
				</div>
				<input class="form-control" [ngClass]="{'text-danger': invalidDateRange}" [minDate]="deviceInstallationDate" [maxDate]="maxDate" placeholder="mm/dd/yyyy" name="startDB" [(ngModel)]="startDate" (ngModelChange)="getDeviceDailyDetails()" bsDatepicker #startDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
				<div class="input-group-append pointer" (click)="startDB.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
			<div class="input-group col-6 p-0 mr-3 d-inline-flex">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.end_date' | translate}}</span>
				</div>
				<input class="form-control" placeholder="mm/dd/yyyy" name="endDB" [(ngModel)]="endDate" [minDate]="deviceInstallationDate" [maxDate]="maxDate" (ngModelChange)="getDeviceDailyDetails()" bsDatepicker #endDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
				<div class="input-group-append pointer" (click)="endDB.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
		</div>
	</div>
	<div class="row" *ngIf="dailyDetails?.length && batterySummary else noData">
		<div [class]="{'col-md-9': !batterySummary.hideEBU, 'col-md-12': batterySummary.hideEBU}">
			<div class="row mb-4">
				<div class="col-md-4">
					<div class="chart-container">
						<sct-charts
							id="usage-time-widget"
							title="{{'perf_analytic.usage_time_widget' | translate}}"
							[data]=" utilizationChartData"
							[columnNames]="['Event', 'Second']"
							[options]="utilizationChartOptions"
							[type]="'PieChart'"
							[height]="200"
						></sct-charts>
						<p class="header">{{pieChartOptions.utilization.title | translate}}</p>
					</div>
				</div>
				<div class="col-md-4">
					<div class="chart-container">
						<sct-charts
							class="mx-auto"
							id="usage-time-widget"
							title="{{'perf_analytic.usage_time_widget' | translate}}"
							[data]=" remainingLifeAhrsPercentChartData"
							[columnNames]="['Event', 'Second']"
							[options]="remainingLifeAhrsPercentChartOptions"
							[type]="'PieChart'"
							[height]="200"
						></sct-charts>
						<p class="header">{{pieChartOptions.remaining_lift_ahrs.title | translate}}</p>
					</div>
				</div>
				<div class="col-md-4">
					<div class="chart-container">
						<sct-charts
							class="mx-auto"
							id="usage-time-widget"
							title="{{'perf_analytic.usage_time_widget' | translate}}"
							[data]=" remainingLifeYrsPercentChartData"
							[columnNames]="['Event', 'Second']"
							[options]="remainingLifeYrsPercentChartOptions"
							[type]="'PieChart'"
							[height]="200"
						></sct-charts>
						 <p class="header">{{pieChartOptions.remaining_lift_Yrs.title | translate}}</p>
					</div>
				</div>
			</div>
			<div class="row info-cards">
				<div class="col-md-4">
					<div class="card pb-4">
						<img src="/sctportal/images/ahrs.png">
						<h1 class="main-blue font-weight-bold">{{batterySummary.maxDailyEBU}}</h1>
						<b>{{"battery_performance.max_daily_ebus" | translate}}</b>
					</div>
				</div>
				<div class="col-md-4">
					<div class="card pb-4">
						<img src="/sctportal/images/time.png">
						<h1 class="main-blue font-weight-bold">{{batterySummary.min_charge_oppurtinity_duration}}</h1>
						<b>{{"battery_performance.min_daily_idle_time" | translate}}</b>
					</div>
				</div>
				<div class="col-md-4">
					<div class="card pb-4">
						<img src="/sctportal/images/ahrs.png">
						<h1 class="main-blue font-weight-bold">{{batterySummary.avgDailyEBU}}</h1>
						<b>{{"battery_performance.average_daily_ebus" | translate}}</b>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-3" *ngIf="!batterySummary.hideEBU">
			<div class="bar-container">
				<div class="battery-bar" [style.--height]="batteryBarHeights.total + 'px'">
					<div class="aria red-aria" [style.--aria-height]="batteryBarHeights.red + 'px'">
						<p>{{limits[2]}}%</p>
					</div>
					<div class="aria green-aria" [style.--aria-height]="batteryBarHeights.green + 'px'">
						<p>{{limits[1]}}%</p>
					</div>
					<div class="aria orange-aria" [style.--aria-height]="batteryBarHeights.orange + 'px'">
						<p>{{limits[0]}}%</p>
					</div>
					<div class="aria white-space"
						[style.--after-height]="batteryBarHeights.filled + 'px'"
						[style.--after-color]="batteryBarHeights.color"
					></div>
					<div class="arrow" [style.--top]="batteryBarHeights.arrow + '%'">
						<span class="mr-2">{{arrowHeight}}%</span>
						<i class="fa fa-lg pointer fa-arrow-right main-blue" title="Next"></i>
					</div>
				</div>
			</div>
			<p class="header">{{"battery_performance.ahrs_returned" | translate}}</p>
		</div>
	</div>
</div>

<ng-template #noData>
	<div class="mt-4 alert alert-info">{{'g.no_data_available' | translate}}</div>
</ng-template>
