<div class="main-black border-radius-bottom white-box">
	<div class="m-0 p-4">
		<nav class="navbar navbar-expand-lg border-radius-top navbar-light bg-white topnav mb-3 pt-2 pb-0 px-4 nav-border main-black">
			<ul class="navbar-nav mr-auto">
				<li class="nav-item">
					<a class="nav-link p-3 light-grey small pointer"
						[ngClass]="{'active': activeTab == tabs.siteSettings}"
						(click)="setActiveTable(tabs.siteSettings)"
					>
						<h5> {{'nav.site_settings' | translate}}</h5>
					</a>
				</li>
				<li class="nav-item">
					<a class="nav-link p-3 light-grey small pointer"
						[ngClass]="{'active': activeTab == tabs.siteAlertsSettings}"
						(click)="setActiveTable(tabs.siteAlertsSettings)"
					>
						<h5> {{'site_settings.site_alert_settings' | translate}}</h5>
					</a>
				</li>
				<li class="nav-item">
					<a class="nav-link p-3 light-grey small pointer"
						[ngClass]="{'active': activeTab == tabs.userAlertsSettings}"
						(click)="setActiveTable(tabs.userAlertsSettings)"
					>
						<h5> {{'site_settings.user_alert_settings' | translate}}</h5>
					</a>
				</li>
			</ul>
		</nav>

		<ng-template #siteSettings>
			<app-site-settings></app-site-settings>
		</ng-template>

		<ng-template #siteAlertsSettings>
			<app-site-alerts-settings></app-site-alerts-settings>
		</ng-template>

		<ng-template #userAlertsSettings>
			<app-users-alert-settings></app-users-alert-settings>
		</ng-template>

		<ng-container *ngTemplateOutlet="activeTab"></ng-container>
	</div>
</div>
