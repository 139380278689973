import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-charger-fleet-utilization-score',
	templateUrl: './charger-fleet-utilization-score.component.html',
	styleUrls: ['./charger-fleet-utilization-score.component.css']
})
export class ChargerFleetUtilizationScoreComponent {
	@Input() data = null;
}
