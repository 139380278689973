import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomHttpParams } from '../shared/loader/custom-httpParam';
import fieldPermissionMapper from '../../../../iotah-common/permissions/fieldPermissionMapper-01.json';
import { SiteDashboardService } from '../home/site-dashboard/site-dashboard.service';
import { CommonService } from '../shared/services/common.service';
@Injectable({
	providedIn: 'root'
})
export class UsersService {

	route = '/user/';

	constructor(
		private httpClient: HttpClient,
		private siteDashboardService: SiteDashboardService,
		private commonService: CommonService
	) {}

	private currentUserSubject: any = new BehaviorSubject({});
	public currentUser = this.currentUserSubject.asObservable();

	getCurrentUser(forceUpdate = false) {
		let info = this.currentUserSubject.value;
		if(!info || !info.id || forceUpdate) {
			info = JSON.parse(localStorage.getItem('userInfo')) || {};
			if(info && info.id)
				this.currentUserSubject.next(info);
		}
		return info;
	}

	setCurrentUser(user) {
		if(Object.keys(user).length > 0)
			user.full_name = user.first_name + ' ' + user.last_name;
		localStorage.setItem('userInfo', JSON.stringify(user));
		this.currentUserSubject.next(user);
	}

	updateCurrentUser(user) {
		if(Object.keys(user).length > 0) {
			let prevUserObj = JSON.parse(localStorage.getItem('userInfo')) || {};
			user = Object.assign(prevUserObj, user);
			this.setCurrentUser(user);
		}
	}

	userHasNOCAccess(enterprisePermissions = null) {
		return this.hasAccessPermission(enterprisePermissions, 'noc');
	}

	isLoggedIn() {
		let user = JSON.parse(localStorage.getItem('userInfo')) || {};
		return !!(user && user.id);
	}

	getUserAuthData() {
		let user = JSON.parse(localStorage.getItem('userInfo')) || {};
		return {
			logged_in: !!(user && user.id),
			password_expired: (user && user.force_password_change),
			terms_agreement: (user && !user.terms_confirmed)
		};
	}

	login(user) {
		return this.httpClient.post('/user/login', user);
	}

	logout():Observable<boolean> {
		return this.httpClient.post('/user/logout',{}).pipe(map(res => {
			return true;
		}));
	}

	unblockLogin(enc: string) {
		return this.httpClient.post('/user/unblockLogin', {enc}, {
			observe: "body"
		});
	}

	hasAccessFunction(accessFunction, level = 'read') {

		if(!this.currentUserSubject.value || !this.currentUserSubject.value.access_functions)
			return false;

		let accessValue = this.currentUserSubject.value.access_functions[accessFunction];

		if (Object.keys(this.currentUserSubject.value.access_functions).includes('super_admin') && accessFunction !== 'user_login_disable')
			return true

		if(!accessValue)
			return false;

		if(level == 'read')
			return accessValue != 'noAccess';
		return accessValue == level;
	}

	forgotPassword(forgot) {
		return this.httpClient.post(this.route + 'forgotPassword', forgot, {
			observe: "body",
			params: new CustomHttpParams({acceptedStatus: ['invalid_captcha']})
		});
	}

	getUsersInfo(userId, options: {getUserIsBlocked: boolean} = {getUserIsBlocked: false}) {
		return this.httpClient.post('/user/getUsersInfo', {userId, getUserIsBlocked: options.getUserIsBlocked}, {
			observe: "body"
		});
	}

	enableFullAccessMode() {
		return this.httpClient.post('/user/enableFullAccessMode', {}, {
			observe: "body"
		});
	}

	getUsersList(criteria, options) {
		return this.httpClient.post('/user/getUsersList', {criteria, options}, {
			observe: "body"
		});
	}

	registerRequest(user, captcha_key, captcha_text) {
		return this.httpClient.post('/user/registerRequest', {user, captcha_key, captcha_text}, {
			observe: "body",
			params: new CustomHttpParams({acceptedStatus: ['invalid_captcha']})
		});
	}

	editUser(userId, user, options) {
		let route = '/user/editUser';
		if(!userId)
			route = '/user/addUser';
		return this.httpClient.post(route, {userId, user, options}, {
			observe: "body"
		});
	}

	deleteUser(userId) {
		return this.httpClient.post('/user/deleteUser', {userId}, {
			observe: "body"
		});
	}

	deleteUserAccount(verificationPassword: string): Observable<any> {
		return this.httpClient.post('/user/deleteUserAccount', { verificationPassword }, {
			observe: "body"
		});
	}

	removeUserBlock(userId: number): Observable<any> {
		return this.httpClient.post('/user/removeUserBlock', { userId }, {
			observe: "body"
		});
	}

	changeUserPassword(old_password, new_password) {
		return this.httpClient.post('/user/changeUserPassword', {old_password, new_password}, {
			observe: "body"
		});
	}

	changeExpiredPassword(old_password, new_password) {
		return this.httpClient.post('/user/changeExpiredPassword', {old_password, new_password}, {
			observe: "body"
		});
	}

	resetPassword(params) {
		return this.httpClient.post('/user/resetPassword', params, {
			observe: "body"
		});
	}

	checkFieldPermissions(fieldName, permissions) {
		let permissionMapper = fieldPermissionMapper;

		let mapperObj = Object.assign({}, permissionMapper);
		let parentObject = mapperObj[fieldName];

		if(fieldName.indexOf('.') != -1) {

			let names = fieldName.split('.');
			parentObject = mapperObj[names[0]][names[1]];
		}
		if(!parentObject) {
			return 'noAccess';
		}

		//Check access function instead of permissions
		if(parentObject.access_function) {
			return this.hasAccessFunction(parentObject.access_function) ? 'write' : 'noAccess';
		}

		let accessType = permissions[parentObject.key];
		if(parentObject.key == 'read') {
			accessType = 'read';
		}
		return accessType;
	}

	hasAccessPermission(enterprisePermissions, accessName, level = 'read', userPermissions = null) {
		let checkUserPermissions = (permissionObj) => {
			if(!permissionObj || !permissionObj.permission)
				return false;
			return this.checkFieldPermissions(accessName, permissionObj.permission);
		};

		if(!enterprisePermissions || Object.keys(enterprisePermissions).length === 0) {
			if(this.currentUserSubject.value && this.currentUserSubject.value.permissions)
				enterprisePermissions = this.currentUserSubject.value.permissions;
		}
		let userAccessLevel = checkUserPermissions(enterprisePermissions);

		if(!userAccessLevel)
			return false;

		if(userPermissions) {

			let otherUserAccessLevel = checkUserPermissions(userPermissions);

			if(otherUserAccessLevel == 'write' && userAccessLevel != 'write')
				return false;
		}

		if(level == 'read')
			return userAccessLevel != 'noAccess';

		return userAccessLevel == level;
	}

	sendConfirmationEmail(userId){
		return this.httpClient.post('/user/sendConfirmationEmail', {userId}, {
			observe: "body"
		});
	}

	confirmUserEmail(userId, enc) {
		return this.httpClient.post('/user/confirmUserEmail', {userId, enc}, {
			observe: "body"
		});
	}

	getUserAccessFunctions(userId) {
		return this.httpClient.post('/user/getUserAccessFunctions', {userId}, {
			observe: "body"
		});
	}

	logContactUs(form, captcha_key, captcha_text) {
		return this.httpClient.post('/user/logContactUs', {subject: form.subject, message: form.body, captcha_key, captcha_text, userId: form.userId}, {
			observe: "body",
			params: new CustomHttpParams({acceptedStatus: ['invalid_captcha']})
		});
	}

	updateUserAccessFunctions(userId, accessFunctions) {
		return this.httpClient.post('/user/updateUserAccessFunctions', {userId, accessFunctions}, {
			observe: "body"
		});
	}

	getPermissions(siteID) {
		return this.httpClient.post('/user/getUserPermission', {siteID}, {
			observe: "body"
		});
	}

	becomeUser(params) {
		return this.httpClient.post('/user/becomeUser', params, {
			observe: "body"
		});
	}

	getBecomeUserToken(userid) {
		return this.httpClient.post('/user/getBecomeUserToken', {userid}, {
			observe: "body"
		});
	}

	loginCheck() {
		return this.httpClient.post('/user/loginCheck', {}, {
			observe: "body"
		});
	}

	confirmUserAgreement() {
		return this.httpClient.post('/user/confirmUserAgreement', {}, {
			observe: "body"
		});
	}
}
