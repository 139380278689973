<div class="cookie-policy-container">
	<h3>{{'cookie_policy.msg1' | translate}}</h3>
	<br>
	<p>
		{{'cookie_policy.msg2' | translate}} <a href="https://www.smarttelemetrics.com">smarttelemetrics.com</a> {{'cookie_policy.msg2a' | translate}}
	</p>
	<p>{{'cookie_policy.msg3' | translate}}</p>
	<p>{{'cookie_policy.msg4' | translate}}</p>
	<p>{{'cookie_policy.msg5' | translate}}</p>

	<ul>
		<li>{{'cookie_policy.msg6' | translate}}</li>
		<li>{{'cookie_policy.msg7' | translate}}</li>
		<li>{{'cookie_policy.msg8' | translate}}</li>
		<li>{{'cookie_policy.msg9' | translate}}</li>
		<li>{{'cookie_policy.msg10' | translate}}</li>
	</ul>

	<h4>{{'cookie_policy.msg11' | translate}}</h4>
	<p>{{'cookie_policy.msg12' | translate}} <a href="mailto:info@smarttelemetrics.com">info@smarttelemetrics.com</a>.</p>
</div>
