<div class="widget-container">
	<div class="filter-container">
		<!-- period filter -->
		<ng-select
			class="select"
			[items]="periods"
			[addTag]="false"
			[clearable]="false"
			[searchable]="false"
			bindLabel="label"
			bindValue="value"
			(change)="onSelectionChanged({filter: 'period', value: $event.value})"
			[placeholder]="'g.durations' | translate"
			[(ngModel)]="selectedPeriod"
		></ng-select>
		<ng-select
			*ngIf="hasTruckTypeFilter"
			class="select"
			[items]="truckTypes"
			[addTag]="false"
			[clearable]="false"
			[searchable]="false"
			bindLabel="label"
			bindValue="value"
			(change)="onSelectionChanged({filter: 'truckType', value: $event.value})"
			[placeholder]="'g.type' | translate"
			[(ngModel)]="selectedTruckType"
		></ng-select>
	</div>
	<div class="widget-bar" [style.--width]="chartWidthPx + 'px'">
		<span *ngFor="let range of ranges" [style.--width]="range.width + 'px'" [style.--color]="range.color"></span>
		<div class="value-indecator" [style.--left]="valuePx + 'px'"></div>
	</div>
	<div class="slider-container" [style.--width]="chartWidthPx + 'px'">
		<input type="range" min="0" max="100" value="null" class="slider" id="slider">
		<div class="labels">
				<span class="right">{{rangesLabels[0]}}</span>
				<span class="right">{{rangesLabels[1]}}</span>
				<span class="right">{{rangesLabels[2]}}</span>
				<span class="left">{{rangesLabels[3]}}</span>
				<span class="left">{{rangesLabels[4]}}</span>
				<span class="left">{{rangesLabels[5]}}</span>
		</div>
	</div>
	<div class="detials" [style.--width]="chartWidthPx + 'px'">
		<span>{{value}}%</span>
		<span>{{valueStatus}}</span>
	</div>
</div>
