import { BehaviorSubject } from "rxjs";
import { EventEmitter } from '@angular/core';

declare var google: any;

export class GoogleChartsBaseService {
	private selectSubject = new BehaviorSubject<any>(null);
	selectItem = this.selectSubject.asObservable();
	chartReady : EventEmitter<Object>;

	constructor() {
		google.charts.load('current', {'packages':['corechart']});
		this.chartReady = new EventEmitter();
	}

	protected buildChart(data: any[], chartFunc: any, config: any, clearFunc: any = null, options: any = null) : void {
		var chart;
		var func = (chartFunc, config, clearFunc, options) => {
			if((data && data.length > 0) || (options && options.dataIsDataTable)) {
				var datatable:any;

				if(options && options.dataIsDataTable) {
					datatable = data;
				} else {
					datatable = google.visualization.arrayToDataTable(data);
				}

				if(config.useDateFormat) {
					var formatDate = new google.visualization.DateFormat({pattern: "MM/dd/yyyy HH:mm:ss"});
					formatDate.format(datatable, 0);
				}

				if(config.useUserDefinedFormatter) {
					config.userDefinedFormatter.format(datatable, 1);
				}

				chart = chartFunc();
				google.visualization.events.addListener(chart, 'select', selectHandler);
				google.visualization.events.addListener(chart, 'ready', () => {
					this.chartReady.emit(chart);
				});

				chart.draw(datatable, config);
			} else if(clearFunc) {
				clearFunc();
			}
		};

		var selectHandler = () => {
			var selectedItem = chart.getSelection()[0];
			this.selectSubject.next(selectedItem);
		}

		var callback = () => func(chartFunc, config, clearFunc, options);

		google.charts.setOnLoadCallback(callback);
	}
}
