import { IotahDevice } from './Iotah-device';
import { CharglinkDevice } from './charglink-device';
import { BattlinkDevice } from './battlink-device';
import { DeviceConfig } from './device-config';
import { BrmDevice } from './brm-device';

type Device = {
	is_study_device: boolean,
	sct_device_type: number
}

export class DeviceFactory {
	static readonly types = {
		iotah: 0,
		charglink: 1,
		battlink: 2,
		brm: 3
	};

	static readonly typesString = {
		iotah: 'IoTAh',
		charglink: 'ChargLink',
		study: 'Study',
		// battlink: 'BattLink' // Not used until now
		// brm: 'Brm' // Not used until now
	};

	static readonly options = [
		{id: DeviceFactory.types.iotah, name: 'IoTAh'},
		{id: DeviceFactory.types.charglink, name: 'ChargLink'},
		{id: DeviceFactory.types.battlink, name: 'BATTlink'},
		{id: DeviceFactory.types.brm, name: 'BRM'},
	];

	static readonly typesWithIncorrectEvents = [
		DeviceFactory.options[0], // Iotah
		DeviceFactory.options[1], // Charglink
		DeviceFactory.options[3], // Brm
	];

	static createDevice(deviceType: number): DeviceConfig {
		switch (deviceType) {
			case DeviceFactory.types.iotah:
				return new IotahDevice();

			case DeviceFactory.types.charglink:
				return new CharglinkDevice();

			case DeviceFactory.types.battlink:
				return new BattlinkDevice();

			case DeviceFactory.types.brm:
				return new BrmDevice();

			default:
				throw new Error('Invalid device type');
		}
	}

	static group(devices: Device[], options: { getPowerStudyDevices: boolean } = { getPowerStudyDevices: false }) {
		const returnValue: { [key: string]: any[] } = {
			iotahDevices: [],
			charglinkdDevices: [],
			battlinkDevices: [],
			brmDevices: []
		};

		if (options.getPowerStudyDevices)
			returnValue.iotahPsDevices = [];

		for (const device of devices) {
			if (device.is_study_device && options.getPowerStudyDevices && device.sct_device_type === DeviceFactory.types.iotah)
				returnValue.iotahPsDevices.push(device);

			else if (device.sct_device_type === DeviceFactory.types.charglink)
				returnValue.charglinkdDevices.push(device);

			else if (device.sct_device_type === DeviceFactory.types.battlink)
				returnValue.battlinkDevices.push(device);

			else if (device.sct_device_type === DeviceFactory.types.brm)
				returnValue.brmDevices.push(device);

			else
				returnValue.iotahDevices.push(device);
		}

		return returnValue;
	}
}
