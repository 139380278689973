<div [class.public-pages-bg]="isPublicPage" *ngIf="domainsService.hasDefinedDomain()">
	<router-outlet></router-outlet>
	<div class="banner" *ngIf="showBanner">
			<div class="text-center text-capitalize d-flex justify-content-center align-items-center">
					<p class="m-0">
							{{'cookie_policy.msg0' | translate}}
							<a class="a-cookie_policy" target="_blank" href="/cookie-policy" >{{'cookie_policy.cookie_policy' | translate}}</a>.
					</p>
					<div>
							<a class="s-btn s-btn_muted js-notice-close" aria-label="notice-dismiss" (click)="closeBanner()">
									<svg aria-hidden="true" class="svg-icon iconClear" width="18" height="18" viewBox="0 0 18 18">
											<path
													d="M15 4.41L13.59 3 9 7.59 4.41 3 3 4.41 7.59 9 3 13.59 4.41 15 9 10.41 13.59 15 15 13.59 10.41 9 15 4.41z">
											</path>
									</svg>
							</a>
					</div>

			</div>
	</div>
</div>
