<div class="page-container">
	<header>
		<img src="/sctportal/{{loginLogo}}" [class]="imageClass" alt="smartTelemetrics">
		<div></div>
	</header>
	<div class="login-container">
		<div class="login-text">
			<h1>{{"login_register.login_into" | translate}}</h1>
			<h1>{{"login_register.your_account" | translate}}</h1>
			<p>{{"login_register.access_real_time" | translate}}.</p>
		</div>
		<form (ngSubmit)="f.form.valid && onLogin(f)" #f="ngForm">
			<div class="form-group validated-field">
				<label for="email">{{"login_register.email" | translate}}</label>
				<input type="email" name="email" ngModel #emailComponent #email="ngModel" required email class="form-control rounded" placeholder="{{'login_register.email' | translate}}" autofocus (blur)="checkEmailValidation()">
				<div class="invalid-tooltip" *ngIf="email.touched && email.invalid">
					{{"g.fill_out_massage" | translate}}
				</div>
					<div class="invalid-tooltip" *ngIf="email.touched && !isValidEmail">
					{{"g.invalid_email_format" | translate}}
				</div>
			</div>
			<div class="form-group validated-field">
				<div class="d-flex justify-content-between">
					<label for="email">{{"g.password" | translate}}</label>
					<a routerLink="/forgot-password"><small>{{'login_register.forgot_password' | translate}}?</small></a>
				</div>
				<input type="password" name="password" ngModel #password="ngModel" required class="form-control rounded" placeholder="{{'g.password' | translate}}" show-password>
				<div class="invalid-tooltip" *ngIf="password.touched && password.invalid">
					{{"g.fill_out_massage" | translate}}
				</div>
			</div>
			<div *ngIf="showCaptcha">
				<app-captcha #captchaComponent></app-captcha>
			</div>
			<div class="submit-area form-group">
				<div class="submit-text">
					<p>{{'login_register.do_not_have_account' | translate}} <a routerLink="/register">{{'login_register.sign_up_now' | translate}}</a></p>
					<p>{{'login_register.need_support' | translate}} <a routerLink="/contact-us">{{'contact_us.title' | translate}}</a></p>
				</div>
				<button type="submit" class="btn">{{'login_register.login' | translate}}</button>
			</div>
		</form>
	</div>
	<footer>
		<p>{{"© " + copyrightYear + " " + siteName}}. {{"g.all_rights_reserved" | translate}}</p>
		<div class="mobile-apps">
			<a href="{{appStoreAppLink}}" target="_blank">
				<img src="/sctportal/images/smartTelemetrics/app_store.svg" alt="smartTelemetrics">
			</a>
			<a href="{{googlePlayAppLink}}" target="_blank">
				<img src="/sctportal/images/smartTelemetrics/google_play.svg" alt="smartTelemetrics">
			</a>
		</div>
	</footer>
	<div class="clouds"></div>
</div>
