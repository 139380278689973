import { Component, OnInit, Input, ViewChild, OnChanges, AfterViewInit, Output, EventEmitter, ElementRef, ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceService } from '../../device.service';
import { UsersService } from 'src/app/users/users.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { CommonDataService } from 'src/app/shared/services/common-data.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import * as lo from 'lodash-es';
import * as _ from 'underscore';
import { LoaderInterceptorService } from 'src/app/shared/loader/loader-interceptor.service';
import truckTypes from 'src/app/shared/lists/truck_type.json';
import truckManufacturers from 'src/app/shared/lists/truck_manufacturer.json';
import { DeviceCommonService } from '../../device-common.service';
import { mainDeviceConfig } from '../../device-factory/device-config';
import { DeviceFactory } from '../../device-factory/device-factory';
import { IotahDevice } from '../../device-factory/Iotah-device';

@Component({
	selector: 'app-device-settings',
	templateUrl: './device-settings.component.html',
	styleUrls: ['./device-settings.component.css']
})
export class DeviceSettingsComponent implements OnInit, OnChanges, AfterViewInit {

	@Input() device: any = {};
	@Input() config_info: any = {};
	@Input() multiDevices: boolean = false;
	@Input() deviceIds: any = '';
	@Input() currentSite: any;
	@Input() enterprisePermissions: any = {};
	@Input() changesStack: any = {};
	@Output() updateDeviceAttributes = new EventEmitter<any>();
	@Output() onDeviceDataChange = new EventEmitter<Object>();
	@ViewChild('cardContainer', { static: false }) cardContainer: ElementRef;
	@ViewChild("saveOptionsModal") saveOptionsModal;
	@ViewChild("deviceWithoutPasswordModal") deviceWithoutPasswordModal;
	@ViewChild("warningsModal") warningsModal;
	@ViewChild("changesStackTooltip", { static: true }) changesStackTooltip;
	@ViewChild("availableWifiNetworksModal", { static: true }) availableWifiNetworksModal;
	@ViewChild("selectWifiNetworksModal", { static: true }) selectWifiNetworksModal;
	@ViewChild("deleteChangeStack") deleteChangeStack;
	@ViewChild("restartDeviceConfirm") restartDeviceConfirm;

	doneScrolling = false;
	theUIInitDone = false;

	shipmentLink = null;
	connectivityVerficationLink = null;
	shipmentResults = {
		notTested: 0,
		passed: 1,
		failed: 2
	}

	tab: string = 'info';
	cardsVisibility: any = {};
	saveChangesVisibility: any = {};
	oldDevice: any = {};
	dirtyFields: any = {};
	zones: any[] = [];
	lastSavedUser: any;
	zoneId: number;
	forceDirtyFields:any = [];
	validationRules: any;
	deviceWarnings:any = [];
	maintenanceSchedule: any;
	oldMaintenanceSchedule: any = {};
	hasMaintenance: boolean = false;
	isChargLinkDevice: boolean = false;
	chargLinkTranslationPath: string = "";
	deviceConfig: mainDeviceConfig | null = (new IotahDevice).config;
	permissionMapperKey: string = '';
	siteID = 0;
	customerID = 0;

	uiStructure = {
		'info': [
			'setup_key',
			'memory_signature',
			'serial_number',
			"fw_version",
			"installation_date",
			"study_start_event_id",
			"device_id",
			"is_study_device",
			"truck_id",
			"truck_sn",
			"truck_type",
			"study_name",
			"hardware_version",
			"in_test_mode",
			"setup_requested_site",
			"setup_requested_customer",
			"setup_requested_address",
			"setup_replace_mac",
			"setup_user_id",
			"setup_time",
			"setup_done",
			"setup_reset_data",
			"truck_manufacturer",
			"last_pm_date",
			"last_pm_user_id",
			"latitude",
			"longitude",
			"last_latitude",
			"last_longitude",
			"last_update_location_time"
		],
		'deviceSettings': [
			'current_cable_scale',
			'current_idle_to_charge',
			'current_idle_to_inuse',
			'current_charge_to_idle',
			'current_charge_to_inuse',
			'current_inuse_to_charge',
			'current_inuse_to_idle',
			'remote_flash_timer',
			'dead_man_current_limit',
			'charge_to_idle_timer',
			'charge_to_inuse_timer',
			'inuse_to_charge_timer',
			'inuse_to_idle_timer',
			'idle_to_charge_timer',
			'idle_to_inuse_timer',
			'time_hysteresis',
			'operation_mode',
			'replacement_part',
			'zone_id',
			'enable_rt',
			'rt_log_frequency',
			'has_water_level',
			'has_hall_effect',
			'has_temperature_sensor',
			'has_current_low_range',
			'sct_device_type',
			'disable_external_rtc',
			'disable_xtra'
		],
		'batterySettings': [
			'fi_duration',
			'eq_duration',
			'vpc',
			'trickle_voltage',
			'cv_target_voltage',
			'fi_target_voltage',
			'eq_voltage',
			'trickle_current_rate',
			'cc_rate',
			'cv_end_current_rate',
			'cv_current_step',
			'fi_current_rate',
			'eq_current_rate',
			'battery_capacity',
			'battery_type',
			'number_of_cells',
			'battery_temperature_compensation_in_mv',
			'fi_dt',
			'fi_dv',
			'charger_type'
		],
		'cal': [
			'current_drift_adc_low_range',
			'voltage_drift_adc',
			'voltage_scale_adc',
			'current_drift_adc',
			'current_scale_adc',
			'temperature_beta',
			'current_scale_adc_low_range',
			'temperature_res_dividor',
			'temperature_res_drift',
			'temperature_res_dividor_drift',
			'probe_drift_adc',
			'probe_scale_adc',
			'gallons_count_scale',
			'high_probe_voltage',
		],
		'networkingSettings': [
			'remoter_server_port',
			'soft_ap_exit_no_setup',
			'mobile_port',
			'master_port',
			'reconnect_time',
			'server_socket_port',
			'remoter_server_ip',
			'ap_password',
			'ap_ssid',
			'ap_channel',
			'soft_ap_exit',
			'enable_wifi_ap_on_start',
			'enable_serversocket',
			'enable_multicast',
			'multicast_port',
			'multicast_ip',
			'is_online_enabled',
			'enable_cellular',
			'cellular_region',
			'cell_access_point_name',
			'lte_cellular_bands',
			'nbiot_cellular_bands',
			'cellular_reconnect_time',
			'metered_flag'
		],
		'networking': [
			'enable_wifi_sta',
			'mobile_master.ssid',
			'mobile_master.password',
			'mobile_master.priority',
			'mobile_network.ssid',
			'mobile_network.password',
			'mobile_network.priority',
			'router_network.ssid',
			'router_network.password',
			'router_network.priority',
			'online_network.ssid',
			'online_network.bssid',
			'online_network.password',
			'online_network.priority',
		],
		'connectivityFrequency': [],
		'billedKWHrsSettings': [
			'charger_efficiency',
			'battery_efficiency',
			'return_factor',
		],
		'maintenanceSchedule': [
			'maintenance_schedule.report_ahr_opt',
			'maintenance_schedule.report_ahr',
			'maintenance_schedule.report_kwhr_opt',
			'maintenance_schedule.report_kwhr',
			'maintenance_schedule.report_usage_hours_opt',
			'maintenance_schedule.report_usage_hours',
			'maintenance_schedule.report_period_opt',
			'maintenance_schedule.report_period',
		],
		'official-reporting': [
		]
	};

	tabs = [
		'info',
		'deviceSettings',
		'batterySettings',
		'cal',
		'networkingSettings',
		'networking',
		'billedKWHrsSettings',
		'maintenanceSchedule',
		'official-reporting',
		'connectivityFrequency'
	];

	fieldRestartDevice = ['disable_xtra', 'disable_external_rtc', 'hw_version', 'enc_code', 'lte_cellular_bands', 'nbiot_cellular_bands', 'enable_wifi_ap_on_start'];
	fieldRestartDeviceOnEnable = [ 'enable_cellular'];
	restartDeviceChangedFields = [];

	infoFormFields: any;
	deviceSettingsFormFields: any;
	batterySettingsFormFields: any;
	calFormFields: any;
	networkingSettingsFormFields: any;
	networkingFormFields: any;
	billedKWHrsSettingsFormFields: any;
	maintenanceScheduleFormFields: any;
	extraRules: any; //Fields needed for each device (multi settings)
	currentUser: any = {};
	serverIpsIdMap: any = {};
	availableWifiNetworks: any = [];
	selectedWifiNetwork: any = {};
	removedChangesStackInfo: any;
	confirmDeleteStack: boolean = false;


	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private cookieService: CookieService,
		private deviceService: DeviceService,
		public usersService: UsersService,
		private notificationMessage: NotificationMessageService,
		public commonUtil: CommonService,
		private commonData: CommonDataService,
		private translate: TranslateService,
		private loader: LoaderInterceptorService,
		private deviceCommonService: DeviceCommonService,
	) { }

	ngOnInit() {
		this.route.queryParams.subscribe(
			params => {
				this.setTab(params.tab);
			}
		);
		this.siteID = this.route.parent.snapshot.params.siteId || 0;
		this.customerID = this.route.parent.snapshot.params.customerId || 0;

		this.usersService.currentUser.subscribe((user: any) => {
			if(user && user['id']) {
				this.currentUser = user;
				this.theUIInitDone = true;
			}
		});

		this.serverIpsIdMap = _.indexBy(this.deviceCommonService.ddLists.remoter_server_ip, 'id');
		this.formatBandsFields()
		this.deviceConfig = DeviceFactory.createDevice(this.device.sct_device_type).config;
		this.permissionMapperKey = this.deviceConfig.permissionMapperKey;
	}

	ngAfterViewChecked(): void {
		if (!this.doneScrolling && this.theUIInitDone && this.cardContainer?.nativeElement?.querySelector('.active'))
			this.doneScrolling = this.commonUtil.scrollToActiveLink(this.cardContainer);
	}

	prepareDeadManCurrentLimitList() {
		let items = [];
		for(let i = -5; i > -11; i--) {
			items.push({id: i, label: i});
		}
		this.deviceCommonService.ddLists.dead_man_current_limit = items;
	}

	formatLastSavedUser(device) {

		let lastSavedUser;

		if(device.config_info.last_saved_user_id) {

			lastSavedUser = {'type': 'text'};

			if(device.config_info.last_saved_user_id == 0xFFFFFFFF) {

				lastSavedUser['value'] = this.translate.instant('g.system');
			} else {

				lastSavedUser['value'] = device.config_info.last_saved_user_id+' - ';

				if(device.extraInfo.lastSavedUser.is_deleted) {

					lastSavedUser['value'] += this.translate.instant('g.deleted');
				} else {

					lastSavedUser['value'] += device.extraInfo.lastSavedUser.name;

					if(this.usersService.hasAccessFunction('edit_user_admin'))
						lastSavedUser['type'] = 'link';
				}
			}
		}

		this.lastSavedUser = lastSavedUser;
	}

	ngOnChanges() {
		if (this.device.config_info) {
			this.deviceConfig = DeviceFactory.createDevice(this.device.config_info.sct_device_type).config;
			this.permissionMapperKey = this.deviceConfig.permissionMapperKey;
			if(!this.multiDevices)
				this.formatLastSavedUser(lo.cloneDeep(this.device));

			this.hasMaintenance = false;
			if(this.device.maintenance) {
				this.hasMaintenance = true;
				this.maintenanceSchedule = lo.cloneDeep(this.device.maintenance);
			}

			if(this.device.extra_rules)
				this.extraRules = lo.cloneDeep(this.device.extra_rules);
			this.device = lo.cloneDeep(this.device.config_info);

			this.device.remoter_server_ip_options = this.setRemoterServerIpOptions();
			this.device.subscription_date = this.device.subscription_date ? moment(this.device.subscription_date * 1000).format('MM/DD/yy') : '--/--/--';
			this.device.config_version = this.device.config_version? this.device.config_version : '---';
			this.prepareShipmentQualityAndConnectivityVerficationResult();
			this.device.shipment_quality_passed_time = this.device.shipment_quality_passed_time ? moment(this.device.shipment_quality_passed_time).format('MM/DD/yy') : '--/--/--';
			this.handleLiftTimeCurrentBatterySettings();
		}
		this.prepareValidationRules();

		// both 0 and 1 are Cellular Only
		if (this.device.metered_flag == 0)
			this.device.metered_flag = 1;

		if(Object.keys(this.device).length > 0) {
			this.zoneId = this.device.zone_id;
			if(!this.zoneId)
				this.zoneId = this.currentSite['zone_id'];

			if (this.permissionExists()) {
				this.permissionsCheckup();
				this.initVariables();
				this.resetForms();

				if(!this.multiDevices) {
					if(this.usersService.hasAccessPermission(this.enterprisePermissions, 'noc') && this.usersService.hasAccessFunction('show_warnings')) {
						this.showWarnings();
					} else {
						this.showNoPasswordWarn();
					}
				}
				this.forceDefaultValues();
				this.prepareForms();
			}
		}
	}

	prepareShipmentQualityAndConnectivityVerficationResult() {
		// shipment_quality_result
		this.shipmentLink = null;
		this.connectivityVerficationLink = null;

		let status = 'not_tested';
		if (this.device.shipment_quality_result && this.device.shipment_quality_result.report_id) {
			if (this.device.shipment_quality_result?.result == this.shipmentResults.passed)
				status = `passed`;

			if (this.device.shipment_quality_result?.result == this.shipmentResults.failed)
				status = `failed`;

			if (this.device.shipment_quality_result.history_source == 1)
				this.shipmentLink = ['/','management', 'receiving-shipment-history', this.device.shipment_quality_result?.report_id];

			else if (this.device.shipment_quality_result.history_source == 2)
				this.shipmentLink = ['/','management', 'shipment-quality-history', this.device.shipment_quality_result?.report_id];
		}
		this.device.shipment_quality_passed = this.translate.instant(`g.${status}`);

		// connectivity_verfication_result
		status = 'not_tested';
		if (this.device.connectivity_verfication_result && this.device.connectivity_verfication_result.report_id) {
			if (this.device.connectivity_verfication_result?.result == this.shipmentResults.passed)
				status = `passed`;

			if (this.device.connectivity_verfication_result?.result == this.shipmentResults.failed)
				status = `failed`;

			this.connectivityVerficationLink = ['/', 'admin-panel', 'connectivity-verification-log', this.device.connectivity_verfication_result?.report_id];
		}

		this.device.connectivity_verfication_status = this.translate.instant(`g.${status}`);
	}

	handleLiftTimeCurrentBatterySettings() {
		this.device.lift_current_threshold_percentage = Math.round(100 * ((this.device.current_lift_upper_threshold + this.device.current_lift_lower_threshold) * -1) / (2 * this.device.battery_capacity));
		if (this.device.lift_current_threshold_percentage == 0) {
			this.device.lift_current_threshold_percentage = 40;
			this.device.lift_current_threshold_delta_percentage = 5;
		}

		this.device.lift_current_threshold_delta_percentage = Math.round((100 * (this.device.current_lift_upper_threshold * -1) / this.device.battery_capacity) - this.device.lift_current_threshold_percentage);
		if (this.device.lift_current_threshold_delta_percentage == 0)
			this.device.lift_current_threshold_delta_percentage = 5;

		this.device.lift_current_threshold_value = (this.device.lift_current_threshold_percentage * this.device.battery_capacity) / 100;
		this.device.lift_current_threshold_delta_value = (this.device.lift_current_threshold_delta_percentage * this.device.battery_capacity) /100;

		this.device.current_lift_upper_threshold = +(this.device.current_lift_upper_threshold?.toFixed(1) ?? this.device.current_lift_upper_threshold);
		this.device.current_lift_lower_threshold = +(this.device.current_lift_lower_threshold?.toFixed(1) ?? this.device.current_lift_lower_threshold);
	}

	ngAfterViewInit() {
		this.selectWifiNetworksModal.onClose.subscribe((ok) => {
			if(ok) {
				this.updateWifiNetwork();
			} else {
				this.selectWifiNetworksModal.hide();
				this.availableWifiNetworksModal.show();
				this.selectedWifiNetwork = {};
			}
		});

		this.restartDeviceConfirm.onClose.subscribe((ok) => {
			if (ok)
				this.saveChanges();

			this.restartDeviceConfirm.hide();
		});

		this.deleteChangeStack.onClose.subscribe((ok) => {
			if (ok) {
				this.removeChangeStack(this.removedChangesStackInfo, false);
				this.confirmDeleteStack = false;
			} else {
				this.deleteChangeStack.hide();
				this.confirmDeleteStack = false;
			}
		});
	}

	setRemoterServerIpOptions() {
		let server = 'custom';
		for(let option of this.deviceCommonService.ddLists.remoter_server_ip) {
			if(option.domain == this.device.remoter_server_ip && option.port == this.device.remoter_server_port) {
				server = option.id;
				break;
			}
		}
		return server;
	}

	forceDefaultValues() {
		if(!this.multiDevices && this.tab == 'info' && !this.device.truck_manufacturing_year) {
			this.device.truck_manufacturing_year = 2010;
			this.forceDirtyFields.push('truck_manufacturing_year');
		}
	}

	permissionExists() {
		return Object.keys(this.enterprisePermissions).length > 0 && Object.keys(this.device).length > 0;
	}

	setTab(tabId="") {
		if(!this.tabs.includes(tabId))
			tabId = 'info';

		this.tab = tabId;

		if(this.permissionExists())
			this.forceDefaultValues();

		this.resetForms();

		if(this.tab == 'maintenanceSchedule' && this.deviceConfig.hasMaintenanceSchedule)
			this.prepareMaintenanceScheduleData();
	}

	getSettingUrl() {
		let deviceId = (this.device && this.device.mac_address ? this.device.mac_address : this.route.snapshot.params.deviceId);
		return ['/', this.customerID, this.siteID, deviceId, 'settings'];
	}

	permissionsCheckup() {
		let cardsVisibility	= {};
		let saveChangesVisibility = {};

		for(let card in this.uiStructure) {

			cardsVisibility[card] = false;
			saveChangesVisibility[card] = false;

			let fields = this.uiStructure[card];

			const checkSaveBtnVisibility = this.cardSavebuttonVisibility(card);
			var saveBtnStatus = checkSaveBtnVisibility['status'];

			for(let field of fields) {

				let accessType = this.usersService.checkFieldPermissions(field, this.enterprisePermissions.permission, this.permissionMapperKey);

				if(accessType && ['read', 'write'].indexOf(accessType) !=-1) {
					cardsVisibility[card] = true;
					if(saveBtnStatus == 'all' && accessType == 'write') {
						saveChangesVisibility[card] = true;
						break;
					}
					if (saveBtnStatus == 'part') {
						if(checkSaveBtnVisibility['permissions'].indexOf(field) != -1 && accessType =='write') {
							saveChangesVisibility[card] = true;
							break;
						}
					}
				}
			}
		}

		this.cardsVisibility		= cardsVisibility;
		this.saveChangesVisibility	= saveChangesVisibility;
	}

	initVariables() {
		this.oldDevice = JSON.parse(JSON.stringify(this.device));
		if(this.maintenanceSchedule)
			this.oldMaintenanceSchedule = JSON.parse(JSON.stringify(this.maintenanceSchedule));
		this.deviceService.formatDeviceFields(this.device);
	}

	cardSavebuttonVisibility(card) {
		// return {status: 'all'} : permissions are same as ui structure fields.
		// return {status: 'part', fields:[]} : permissions are part of ui structure fields because not all fields are editable.
		// return {status: 'custom'}: skip the check, needs special handling
		let returnValue = {};
		switch(card){
			case 'info':
				returnValue = { status: 'part', permissions: ['setup_key', 'memory_signature', 'serial_number', "fw_version", "installationDateFMT", "study_start_event_id", "device_id", "truck_id", "truck_sn", "truck_type", "study_name", "hardware_version", "in_test_mode", "is_study_device"] };
			break;
			case 'deviceSettings':
			case 'batterySettings':
			case 'cal':
			case 'networkingSettings':
			case 'networking':
			case 'billedKWHrsSettings':
			case 'maintenanceSchedule':
			case 'official-reporting':
			case 'connectivityFrequency':
				returnValue = {status: 'all'};
			break;
		}
		return returnValue;
	}

	showSaveButton(card) {
		return this.saveChangesVisibility[card];
	}

	resetForms(removeErrors = false) {
		if(!removeErrors) {
			this.device = JSON.parse(JSON.stringify(this.oldDevice));
			if(this.oldMaintenanceSchedule)
				this.maintenanceSchedule = JSON.parse(JSON.stringify(this.oldMaintenanceSchedule));
			this.deviceService.formatDeviceFields(this.device);
		}

		this.dirtyFields = {};
		this.forceDirtyFields = [];
		this.formatBandsFields();
	}

	onBeforeSubmit(formModels) {
		for (const field of this.deviceService.cellularBandsFields) {
			if(!formModels[field])
				continue;

			let cellularBandsArray = formModels[field].value;
			if (Array.isArray(cellularBandsArray)) {
				let cellularBandsSum = 0;

				cellularBandsArray.map((data: number) => cellularBandsSum += data);
				formModels[field].setValue(cellularBandsSum);
			}
		}

		if(formModels.rt_log_frequency_fmt && formModels.rt_log_frequency_unit) {
			const value = formModels.rt_log_frequency_fmt.value;
			const unitValue = formModels.rt_log_frequency_unit.value;
			this.device.rt_log_frequency = +value;
			if (unitValue == this.deviceService.rt_log_frequency_units.minutes)
				this.device.rt_log_frequency *= 60;
		}
	}

	checkChanges(formModels) {
		let dirtyFields: any	= {};
		let arrayFields	= [];
		let freshDevice	= this.device;
		let oldDevice	= this.oldDevice;
		let autoChangable	= ['enc_code', 'remoter_server_port', 'remoter_server_ip'];

		let toNumber	= [
			'in_test_mode',
			'replacement_part',
			'enable_rt',
			'has_water_level',
			'has_hall_effect',
			'has_temperature_sensor',
			'has_current_low_range',
			'enable_wifi_sta',
			'enable_multicast',
			'enable_wifi_ap_on_start',
			'enable_serversocket',
			'is_online_enabled',
			'setup_reset_data',
			'setup_done',
			'enable_cellular',
			'disable_xtra',
			'disable_external_rtc',
			'is_study_device',
			'enable_current_mask'
		];
		const dateFields = ['installation_date', 'setup_time', 'last_pm_date', 'last_update_location_time'];

		if(this.forceDirtyFields.length) {
			let dirtyFields = this.forceDirtyFields;
			for(let field of dirtyFields) {
				if(formModels[field]) {
					formModels[field].markAsDirty();
					this.forceDirtyFields = _.without(this.forceDirtyFields, field);
				}
			}
		}

		let zoneDiff = new Date().getTimezoneOffset();

		for(let model in formModels) {
			if(!formModels[model].dirty && !autoChangable.includes(model))
				continue;

			//Convert from boolean (false or true) into integer (0 or 1)
			if(toNumber.indexOf(model) > -1 && freshDevice[model] !== undefined)
				freshDevice[model] = +freshDevice[model];

			let originalModelName = model;
			if(['installation_date_fmt', 'setup_time_fmt', 'last_pm_date_fmt', 'last_update_location_time_fmt'].includes(model)) {
				originalModelName = model.slice(0, model.indexOf("_fmt"));
			}

			let oldValue = oldDevice[originalModelName];
			let newValue = freshDevice[model];

			if(dateFields.includes(originalModelName)) {
				newValue = moment(newValue).startOf('day').unix();
				//  local time -> utc
				newValue -= (zoneDiff * 60);
			}

			if(arrayFields.indexOf(model) != -1) {

				if(oldValue && newValue && !this.commonUtil.arrayCompare(oldValue, newValue)) {
					dirtyFields[model] = newValue;
				}
			} else if(this.tab == 'info' && model == 'max_charger_kw' && newValue == '') {
				dirtyFields[model] = null;
			} else if(this.tab == 'networking' && !['enable_wifi_sta'].includes(model)) {

				let tmpModel	= model.split('.');
				let netType		= tmpModel[0];
				let netProp		= tmpModel[1];

				oldValue	= oldDevice[netType][netProp];
				newValue	= freshDevice[netType][netProp];

				if(oldValue != newValue) {

					if(!dirtyFields[netType])
						dirtyFields[netType] = {};

					dirtyFields[netType][netProp] = newValue;
				}
			} else if(oldValue != newValue) {

				dirtyFields[originalModelName] = newValue;
			}
		}

		if(dirtyFields.rt_log_frequency_fmt) {
			dirtyFields.rt_log_frequency = dirtyFields.rt_log_frequency_fmt;
			if(
				[1, 2, 3, 4].includes(+dirtyFields.rt_log_frequency) && (
					dirtyFields.rt_log_frequency_unit == this.deviceService.rt_log_frequency_units.minutes ||
					this.device.rt_log_frequency_unit == this.deviceService.rt_log_frequency_units.minutes
				)
			)
				dirtyFields.rt_log_frequency = (+dirtyFields.rt_log_frequency) * 60;
			delete dirtyFields.rt_log_frequency_unit;
		}

		this.resetForms(true);

		if(Object.keys(dirtyFields).length > 0) {
			this.dirtyFields = dirtyFields;
			let willRestartDevice = false;
			this.restartDeviceChangedFields = [];

			for (let field of Object.keys(dirtyFields)) {
				if (this.fieldRestartDevice.includes(field) || (this.fieldRestartDeviceOnEnable.includes(field) && this.dirtyFields[field])) {
					willRestartDevice = true;
					this.restartDeviceChangedFields.push(field)
				}
			}

			if (willRestartDevice)
				return this.restartDeviceConfirm.show();;

			this.saveChanges();
		}
	}

	saveChanges() {
		if(this.multiDevices)
			return this.saveDeviceSettings('queue');

		if(this.usersService.hasAccessFunction('allow_device_db_save')) {
			this.saveOptionsModal.show();
		} else {
			this.saveDeviceSettings('queue_and_wait');
		}
	}

	checkMaintenanceChanges(formModels) {
		let dirtyFields	= {};
		let arrayFields	= [];
		let freshData	= this.maintenanceSchedule;
		let oldData		= this.oldMaintenanceSchedule;

		let flags = [
			'report_ahr_opt',
			'report_kwhr_opt',
			'report_usage_hours_opt',
			'report_period_opt',
		];

		let enabled = false;
		for(let flag of flags)
			enabled = enabled || this.maintenanceSchedule[flag];
		if(!enabled)
			return this.notificationMessage.setMessage(this.translate.instant('maintenance_schedule.err_min_option_enabled'), {clearOnXTimeNavigate: 1});

		if(this.forceDirtyFields.length) {
			let dirtyFields = this.forceDirtyFields;
			for(let field of dirtyFields) {
				if(formModels[field]) {
					formModels[field].markAsDirty();
					this.forceDirtyFields = _.without(this.forceDirtyFields, field);
				}
			}
		}

		for(let model in formModels) {
			if(!formModels[model].dirty)
				continue;

			//Convert from boolean (false or true) into integer (0 or 1)
			if(flags.indexOf(model) > -1 && freshData[model] !== undefined)
				freshData[model] = +freshData[model];

			let originalModelName = model;

			let oldValue = oldData[originalModelName];
			let newValue = freshData[model];

			if(arrayFields.indexOf(model) != -1) {
				if(oldValue && newValue && !this.commonUtil.arrayCompare(oldValue, newValue)) {
					dirtyFields[model] = newValue;
				}
			} else if(oldValue != newValue) {
				dirtyFields[originalModelName] = newValue;
			}
		}
		this.resetForms(true);

		for(let flag of flags) {
			let textField = flag.replace('_opt', '');
			if(!this.maintenanceSchedule[flag])
				delete dirtyFields[textField];
		}

		if(Object.keys(dirtyFields).length > 0) {
			if(this.multiDevices) {
				let deviceIds = this.deviceIds.split(',');
				this.deviceService.saveMultiMaintenanceSchedule(deviceIds, dirtyFields).subscribe((response: any) => {
					this.handleMaintenanceSaveResponse(response);
				});
			} else {
				this.deviceService.saveMaintenanceSchedule(this.device.mac_address, dirtyFields).subscribe((response: any) => {
					this.handleMaintenanceSaveResponse(response);
				});
			}
		}
	}

	showWarnings() {
		if(!['', 'info'].includes(this.tab))
			return;

		this.deviceService.getCurrentWarningsList(this.device.mac_address).subscribe((deviceWarnings: any) => {

			let userSessionCookie = this.cookieService.get('userSession');
			let deviceWarningsCookie = this.cookieService.get('warnings_modal_'+this.device.mac_address);

			if (userSessionCookie != deviceWarningsCookie) {
				this.deviceWarnings = this.deviceService.filterWarningsByPermission(deviceWarnings || [], this.enterprisePermissions.permission, {canIgnorePermission: true});

				if (this.deviceWarnings.length) {
					setTimeout(() => {
						this.warningsModal.show();
						this.cookieService.set('warnings_modal_'+this.device.mac_address, userSessionCookie);
					}, 0);
				}
			}
		});

	}

	showNoPasswordWarn() {

		if(!['', 'info'].includes(this.tab))
			return;

		let noPassword = !this.device.device_password;
		let hasAccess = this.usersService.hasAccessPermission(this.enterprisePermissions, 'device_password');
		let userSessionCookie = this.cookieService.get('userSession');
		let devicePassCookie = this.cookieService.get('pass_modal_'+this.device.mac_address);

		if(hasAccess && noPassword && userSessionCookie != devicePassCookie) {
			setTimeout(() => {
				this.deviceWithoutPasswordModal.show();
				this.cookieService.set('pass_modal_'+this.device.mac_address, userSessionCookie);
			}, 0);
		}
	}

	saveDeviceSettings(stage) {

		let originalStage = stage;
		if(this.usersService.hasAccessFunction('allow_device_db_save') && this.saveOptionsModal.render)
			this.saveOptionsModal.hide();

		if(stage == 'cancel')
			return;

		let dirtyFields	= this.dirtyFields;

		if(this.multiDevices) {
			let deviceIds = this.deviceIds.split(',');
			this.deviceService.saveMultiDevicesSettings(deviceIds, dirtyFields).subscribe((response: any) => {
				this.handleSaveResponse(response, originalStage, dirtyFields);
			});
		} else {
			let wait		= 0;
			let mac_address	= this.device.mac_address;

			if(stage == 'queue_and_wait'){
				wait = this.deviceService.waitTimeout;
				stage = 'queue';
			}

			let options: any = {};
			if(stage == 'queue') {
				options.get_changes_stack = true;
			}

			this.deviceService.saveDeviceSettings(mac_address, dirtyFields, stage, options, wait).subscribe((response: any) => {
				this.handleSaveResponse(response, originalStage, dirtyFields, mac_address);
			});
		}
	}

	handleSaveResponse(response, stage, dirtyFields, deviceId?) {
		const apiStatus = response.api_status;

		if (response.response_status == this.loader.resStatus.api_timeout || apiStatus == this.deviceService.deviceCommandsResponse) { // if the api timed-out OR if wait sent and the device is disconnected.
			this.notificationMessage.setMessage('translate|settings.queue_and_wait_timeout_msg', {clearOnXTimeNavigate: 1, type: 'warning'});
			response = response.response;
		} else {
			if(response.invalid_fields)
				return this.notificationMessage.setMessage(this.translate.instant('g.invalid_input'), {clearOnXTimeNavigate: 1});
			if(stage == 'queue')
				this.notificationMessage.setMessage('translate|devices_settings.changes_queued', {clearOnXTimeNavigate: 1, type: 'warning'});
			else {
				this.shouldRefressPage(dirtyFields);
				this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			}
		}
		if(response.changes_stack)
			this.changesStack = this.deviceService.formatChangesStack(response.changes_stack, this.zoneId);

		this.device = response.config_info;

		// both 0 and 1 are Cellular Only
		if (this.device.metered_flag == 0)
			this.device.metered_flag = 1;

		this.maintenanceSchedule = response.maintenance;
		this.device.subscription_date = this.device.subscription_date ? moment(this.device.subscription_date * 1000).format('MM/DD/yy') : '--/--/--';
		this.device.config_version = this.device.config_version? this.device.config_version : '---';

		this.initVariables();

		// update dropdown list
		this.device.remoter_server_ip_options = this.setRemoterServerIpOptions();
		this.formatBandsFields();

		//update maintenance form (validation)
		this.prepareMaintenanceScheduleForm();
		this.handleLiftTimeCurrentBatterySettings();
		this.device.shipment_quality_passed = this.translate.instant(`g.${this.device.shipment_quality_passed ? 'yes' : 'no'}`);

		this.device.connectivity_verfication_status = this.translate.instant(`g.${this.device.connectivity_verfication_status ? 'yes' : 'no'}`);
		// update select device options
		if (stage == 'direct' || (stage == 'queue_and_wait' && apiStatus != this.deviceService.deviceCommandsResponse)) {
			this.updateDeviceAttributes.emit({deviceId, updatedDeviceData: dirtyFields});
			this.onDeviceDataChange.emit(this.device); // to update device config data
		}
	}

	formatBandsFields() {
		for (let bandField of this.deviceService.cellularBandsFields) {
			let bandFieldValues = this.device[bandField];

			if (!bandFieldValues || Array.isArray(bandFieldValues))
				continue;

			let cellularBandArray = [];
			let changeStackBandArray = [];

			const cellularBands = lo.uniqBy([...this.deviceCommonService.ddLists.lte_bands, ...this.deviceCommonService.ddLists.nbiot_bands], 'value');

			for (let field of cellularBands) {
				if ((bandFieldValues & field.value) != 0)
					cellularBandArray.push(field.value);

				if (bandField in this.changesStack) {
					if ((this.changesStack[bandField][this.changesStack[bandField].length - 1][0] & field.value) != 0) // last change stack
						changeStackBandArray.push(field.text + '  ');
				}
			}

			this.device[bandField] = cellularBandArray;

			if (bandField in this.changesStack && !Array.isArray(this.changesStack[bandField][0][0]))
				this.changesStack[bandField][this.changesStack[bandField].length - 1][0] = changeStackBandArray; // TODO: This will handle last change. edit it after adding merge stack changes ticket.
		}
	}

	handleMaintenanceSaveResponse(response) {
		if(response.invalid_fields)
			return this.notificationMessage.setMessage(this.translate.instant('g.invalid_input'), {clearOnXTimeNavigate: 1});
		this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
		this.maintenanceSchedule = response.maintenance;
		this.initVariables();
	}

	shouldRefressPage(dirtyFields) {
		const fieldsToCheck = ['installation_date'];

		for (const field of fieldsToCheck) {
			if (field in dirtyFields) {
				window.location.reload();
				return; // Exit the function after the page refreshes
			}
		}
	}

	prepareForms() {
		if(!this.deviceCommonService.ddLists.dead_man_current_limit.length)
			this.prepareDeadManCurrentLimitList();
		if(!this.zones.length)
			this.zones = this.commonData.TimeZonesMenu;

		this.prepareInfoForm();
		this.prepareDeviceSettingsForm();
		this.prepareBatterySettingsForm();
		this.prepareCalForm();
		this.prepareNetworkingSettingsForm();
		this.prepareNetworkingForm();
		this.prepareBilledKWHrsSettingsForm();
		this.prepareMaintenanceScheduleForm();

		if(this.multiDevices) {
			this.infoFormFields = this.infoFormFields.filter((field) => {
				return this.deviceService.multiSettingsFields.deviceInfo.includes(field.name);
			});
			this.deviceSettingsFormFields = this.deviceSettingsFormFields.filter((field) => {
				return this.deviceService.multiSettingsFields.deviceSettings.includes(field.name);
			});
			this.batterySettingsFormFields = this.batterySettingsFormFields.filter((field) => {
				return this.deviceService.multiSettingsFields.batterySettings.includes(field.name);
			});
			this.calFormFields = this.calFormFields.filter((field) => {
				return this.deviceService.multiSettingsFields.cal.includes(field.name);
			});
			this.networkingSettingsFormFields = this.networkingSettingsFormFields.filter((field) => {
				return this.deviceService.multiSettingsFields.networkingSettings.includes(field.name);
			});
			this.networkingFormFields = this.networkingFormFields.filter((field) => {
				return this.deviceService.multiSettingsFields.networking.includes(Array.isArray(field.name)? field.name.join('.'): field.name);
			});
			this.billedKWHrsSettingsFormFields = this.billedKWHrsSettingsFormFields.filter((field) => {
				return this.deviceService.multiSettingsFields.billedKWHrsSettings.includes(field.name);
			});
			this.maintenanceScheduleFormFields = this.maintenanceScheduleFormFields.filter((field) => {
				return this.deviceService.multiSettingsFields.maintenanceSchedule.includes(field.name);
			});
		}
	}

	prepareInfoForm() {
		this.infoFormFields = [
			{
				name: 'memory_signature',
				label: 'devices.memory_signature',
				permission: 'memory_signature',
				type: 'label'
			},
		];

		if(this.lastSavedUser) {
			this.infoFormFields.push(
				{
					name: 'lastSavedUser',
					label: 'devices.last_saved_user_id',
					permission: 'memory_signature',
					type: (this.lastSavedUser.type == 'link' ? 'link' : 'label'),
					value: this.lastSavedUser.value,
					link: (this.lastSavedUser.type == 'link' ? ['/user', 'edit', this.device.last_saved_user_id] : null)
				}
			);
		}
		if(this.device.last_change_time) {
			this.infoFormFields.push(
				{
					name: 'last_change_time',
					label: 'devices.last_change_time',
					permission: 'memory_signature',
					type: 'dateLabel'
				}
			);
		}
		this.infoFormFields = this.infoFormFields.concat([
			{
				name: 'mac_address',
				label: 'g.mac_address',
				permission: 'read',
				type: 'label',
				tooltip: this.deviceService.getTranslationKey('mac_address', this.device)
			},
			{
				name: 'serial_number',
				label: 'g.serial_number',
				permission: 'serial_number',
				type: 'label',
				tooltip: this.deviceService.getTranslationKey('serial_number', this.device)
			},
			{
				name: 'cm_po_number',
				label: 'shipment.cm_po_number',
				permission: 'noc',
				type: 'label',
				tooltip: 'cm_po_number'
			},
		]);
		if(this.device.fw_version) {
			const isAdmin =  this.usersService.getCurrentUser().is_admin;
			this.infoFormFields.push(
				{
					name: 'fw_version',
					label: 'devices.fw_version',
					permission: 'fw_version',
					type: 'label',
					tooltip: this.deviceService.getTranslationKey('fw_version', this.device),
					value: this.commonUtil.toFixedWithoutRounding(this.device.fw_version, isAdmin ? 4 : 2) + (this.device.firmware_version_to_push && !this.device.testing_fw ? ', ' + this.translate.instant('settings.fw_version_to_push')+':'+ this.commonUtil.toFixedWithoutRounding(this.device.firmware_version_to_push, isAdmin? 4 : 2) : '')
				}
			);
		}

		const truckManufacturersItem: any = {
			name: 'truck_manufacturer',
			label: this.deviceService.getTranslationKey('devices.truck_manufacturer', this.device),
			permission: 'truck_manufacturer',
			type: 'autocomplete',
			validation: this.validationRules.truck_manufacturer,
			tooltip: this.deviceService.getTranslationKey('truck_manufacturer', this.device),
			required: this.deviceConfig.requiredFields.truckManufacturer
		};

		const truckTypeItem: any = {
			name: 'truck_type',
			label: this.deviceService.getTranslationKey('devices.truck_type', this.device),
			permission: 'truck_type',
			type: 'autocomplete',
			validation: this.validationRules.truck_type,
			tooltip: this.deviceService.getTranslationKey('truck_type', this.device),
			required: this.deviceConfig.requiredFields.truckType
		};

		if(this.deviceConfig.requiredFields.truckManufacturer)
			truckManufacturersItem.items = truckManufacturers;

		if(this.deviceConfig.requiredFields.truckType)
			truckTypeItem.items = truckTypes;

		this.infoFormFields = this.infoFormFields.concat([
			{
				name: 'installation_date_fmt',
				label: 'devices.installation_date',
				permission: 'installation_date',
				type: 'date',
				minDate: new Date(moment().subtract(5, 'years').unix()*1000),
				maxDate: new Date(moment().unix()*1000),
				validation: this.validationRules.installation_date,
				required: true,
				tooltip: this.deviceService.getTranslationKey('installation_date', this.device),
			},
			{
				name: 'shipment_quality_passed',
				label: 'devices.shipment_quality_checked',
				permission: 'shipment_quality_passed',
				type: this.shipmentLink ? 'link' : 'label',
				link: this.shipmentLink
			},
			{
				name: 'shipment_quality_passed_time',
				label: 'devices.shipment_quality_passed_time',
				permission: 'shipment_quality_passed_time',
				type: 'label',
			},
			{
				name: 'connectivity_verfication_status',
				label: 'devices.connectivity_verfication_status',
				permission: 'noc',
				type: this.connectivityVerficationLink ? 'link' : 'label',
				disabled: true,
				link: this.connectivityVerficationLink
			},
			{
				name: 'subscription_date',
				label: 'devices.subscription_date',
				permission: 'subscription_date',
				type: 'label'
			},
			{
				name: 'device_id',
				label: 'devices.device_id',
				permission: 'device_id',
				type: 'label',
				validation: this.validationRules.device_id
			},
			...this.fillStudiesField(),
			{
				name: 'truck_id',
				label: this.deviceService.getTranslationKey('devices.truck_id', this.device),
				permission: 'truck_id',
				type: 'text',
				validation: this.validationRules.truck_id,
				tooltip: this.deviceService.getTranslationKey('truck_id', this.device),
			},
			{
				name: 'truck_sn',
				label: this.deviceService.getTranslationKey('devices.truck_sn', this.device),
				permission: 'truck_sn',
				type: 'text',
				validation: this.validationRules.truck_sn,
				tooltip: this.deviceService.getTranslationKey('truck_sn', this.device),
				required: true
			},
			truckManufacturersItem
		]);
		if (this.deviceConfig.requiredFields.truckManufacturingYear) {
			this.infoFormFields.push({
				name: 'truck_manufacturing_year',
				label: this.deviceService.getTranslationKey('devices.truck_manufacturing_year', this.device),
				permission: 'truck_manufacturing_year',
				type: 'text',
				validation: this.validationRules.truck_manufacturing_year,
				required: this.deviceConfig.requiredFields.truckManufacturingYear,
				tooltip: this.deviceService.getTranslationKey('truck_manufacturing_year', this.device),
			})
		}

		this.infoFormFields.push(truckTypeItem);

		this.infoFormFields.push(
			{
				name: 'hw_version',
				label: 'devices.hw_version',
				permission: 'hw_version',
				type: 'text',
				validation: this.validationRules.hw_version,
				required: true,
				tooltip: this.deviceService.getTranslationKey('hw_version', this.device),
			},
			{
				name: 'in_test_mode',
				label: 'devices.in_test_mode',
				permission: 'in_test_mode',
				type: 'boolean',
				validation: this.validationRules.in_test_mode
			},
			{
				name: 'setup_requested_site',
				label: 'devices.setup_requested_site',
				permission: 'memory_signature',
				type: 'text',
				validation: this.validationRules.setup_requested_site
			},
			{
				name: 'setup_requested_customer',
				label: 'devices.setup_requested_customer',
				permission: 'memory_signature',
				type: 'text',
				validation: this.validationRules.setup_requested_customer
			},
			{
				name: 'setup_requested_address',
				label: 'devices.setup_requested_address',
				permission: 'memory_signature',
				type: 'text',
				validation: this.validationRules.setup_requested_address
			},
			{
				name: 'setup_replace_mac',
				label: 'devices.setup_replace_mac',
				permission: 'memory_signature',
				type: 'text',
				validation: this.validationRules.setup_replace_mac
			},
			{
				name: 'setup_user_id',
				label: 'devices.setup_user_id',
				permission: 'memory_signature',
				type: 'text',
				validation: this.validationRules.setup_user_id,
				required: true
			},
			{
				name: 'setup_time_fmt',
				label: 'devices.setup_time',
				permission: 'memory_signature',
				type: 'date',
				validation: this.validationRules.setup_time
			},
			{
				name: 'setup_done',
				label: 'devices.setup_done',
				permission: 'setup_done',
				type: 'boolean',
				validation: this.validationRules.setup_done
			},
			{
				name: 'setup_reset_data',
				label: 'devices.setup_reset_data',
				permission: 'memory_signature',
				type: 'boolean',
				validation: this.validationRules.setup_reset_data
			},
			{
				name: 'last_pm_date',
				label: 'devices.last_pm_date',
				permission: 'device_config_attribute',
				type: 'dateLabel',
				format: 'MM/dd/yyyy'
			},
			{
				name: 'last_pm_user_id',
				label: 'devices.last_pm_user_id',
				permission: 'device_config_attribute',
				type: 'label'
			},
			{
				name: 'latitude',
				label: 'devices.latitude',
				permission: 'latitude',
				type: 'text',
				validation: this.validationRules.latitude
			},
			{
				name: 'longitude',
				label: 'devices.longitude',
				permission: 'longitude',
				type: 'text',
				validation: this.validationRules.longitude
			},
			{
				label: 'devices.location',
				permission: 'noc',
				type: 'subSectionTitle'
			},
			{
			 	name: 'last_latitude',
				label: 'devices.last_latitude',
				permission: 'noc',
				type: 'label'
			},
			{
			 	name: 'last_longitude',
				label: 'devices.last_longitude',
				permission: 'noc',
				type: 'label'
			},
			{
			 	name: 'last_update_location_time_fmt',
				label: 'devices.last_update_location_time',
				permission: 'noc',
				type: 'label',
			}
		);

		if(this.deviceConfig.fields.hasMaxChargerKw) {
			this.infoFormFields.push({
				name: 'max_charger_kw',
				label: 'devices.max_charger_kw',
				permission: 'max_charger_kw',
				type: 'number'
			});
		}
	}

	prepareDeviceSettingsForm() {
		this.deviceSettingsFormFields = [
			{
				name: 'current_cable_scale',
				label: 'devices.current_cable_scale',
				permission: 'current_cable_scale',
				type: 'dropdown',
				items: this.deviceCommonService.ddLists.current_cable_scale,
				bindLabel: 'label',
				bindValue: 'id',
				validation: this.validationRules.current_cable_scale
			},
			{
				name: 'current_idle_to_charge',
				label: 'devices.current_idle_to_charge',
				permission: 'current_idle_to_charge',
				type: 'text',
				validation: this.validationRules.current_idle_to_charge
			},
			{
				name: 'current_idle_to_inuse',
				label: 'devices.current_idle_to_inuse',
				permission: 'current_idle_to_inuse',
				type: 'text',
				validation: this.validationRules.current_idle_to_inuse
			},
			{
				name: 'current_charge_to_idle',
				label: 'devices.current_charge_to_idle',
				permission: 'current_charge_to_idle',
				type: 'text',
				validation: this.validationRules.current_charge_to_idle
			},
			{
				name: 'current_charge_to_inuse',
				label: 'devices.current_charge_to_inuse',
				permission: 'current_charge_to_inuse',
				type: 'text',
				validation: this.validationRules.current_charge_to_inuse
			},
			{
				name: 'current_inuse_to_charge',
				label: 'devices.current_inuse_to_charge',
				permission: 'current_inuse_to_charge',
				type: 'text',
				validation: this.validationRules.current_inuse_to_charge
			},
			{
				name: 'current_inuse_to_idle',
				label: 'devices.current_inuse_to_idle',
				permission: 'current_inuse_to_idle',
				type: 'text',
				validation: this.validationRules.current_inuse_to_idle
			},
			{
				name: 'remote_flash_timer',
				label: 'devices.remote_flash_timer',
				permission: 'remote_flash_timer',
				type: 'number',
				validation: this.validationRules.remote_flash_timer
			}
		]

		if (this.deviceConfig.requiredFields.currentLiftUpperThreshold) {
			this.deviceSettingsFormFields.push(
				{
					name: 'current_lift_upper_threshold',
					label: 'devices.current_lift_upper_threshold',
					permission: 'current_lift_upper_threshold',
					type: 'text',
					validation: [{"type": "float", "max": this.device.current_lift_lower_threshold}],
					tooltip: !this.isFWSupportField('current_lift_upper_threshold')? 'disable_fields_fw_supported' : 'current_lift_upper_threshold_tooltip',
					disabled: !this.isFWSupportField('current_lift_upper_threshold'),
					error_message: this.translate.instant('devices.current_lift_upper_threshold_validation_message'),
				}
			)
		}

		if (this.deviceConfig.requiredFields.currentLiftLowerThreshold) {
			this.deviceSettingsFormFields.push(
				{
					name: 'current_lift_lower_threshold',
					label: 'devices.current_lift_lower_threshold',
					permission: 'current_lift_lower_threshold',
					type: 'text',
					validation: [{"type": "float", "min": this.device.current_lift_upper_threshold, "max": this.device.dead_man_current_limit}],
					tooltip: !this.isFWSupportField('current_lift_lower_threshold')? 'disable_fields_fw_supported' : 'current_lift_lower_threshold_tooltip',
					disabled: !this.isFWSupportField('current_lift_lower_threshold'),
				}
			)
		}

		if (this.deviceConfig.fields.deadManCurrentLimit) {
			this.deviceSettingsFormFields.push(
				{
					name: 'dead_man_current_limit',
					label: 'devices.dead_man_current_limit',
					permission: 'dead_man_current_limit',
					type: 'dropdown',
					items: this.deviceCommonService.ddLists.dead_man_current_limit,
					bindLabel: 'label',
					bindValue: 'id',
					validation: this.validationRules.dead_man_current_limit,
					tooltip: 'dead_man_current_limit'
				}
			)
		}

		if (this.deviceConfig.fields.hasWaterLevel) {
			this.deviceSettingsFormFields.push({
				name: 'has_water_level',
				label: 'devices.has_water_level',
				permission: 'has_water_level',
				type: 'boolean',
				validation: this.validationRules.has_water_level
			})
		}

		if (this.deviceConfig.fields.hasHallEffect) {
			this.deviceSettingsFormFields.push({
				name: 'has_hall_effect',
				label: 'devices.has_hall_effect',
				permission: 'has_hall_effect',
				type: 'boolean',
				validation: this.validationRules.has_hall_effect
			})
		}

		if (this.deviceConfig.fields.hasTemperatureSensor) {
				this.deviceSettingsFormFields.push({
				name: 'has_temperature_sensor',
				label: 'devices.has_temperature_sensor',
				permission: 'has_temperature_sensor',
				type: 'boolean',
				validation: this.validationRules.has_temperature_sensor
			})
		};

		this.deviceSettingsFormFields.push(
			{
				name: 'charge_to_idle_timer',
				label: 'devices.charge_to_idle_timer',
				permission: 'charge_to_idle_timer',
				type: 'number',
				validation: this.validationRules.charge_to_idle_timer
			},
			{
				name: 'charge_to_inuse_timer',
				label: 'devices.charge_to_inuse_timer',
				permission: 'charge_to_inuse_timer',
				type: 'number',
				validation: this.validationRules.charge_to_inuse_timer
			},
			{
				name: 'inuse_to_charge_timer',
				label: 'devices.inuse_to_charge_timer',
				permission: 'inuse_to_charge_timer',
				type: 'number',
				validation: this.validationRules.inuse_to_charge_timer
			},
			{
				name: 'inuse_to_idle_timer',
				label: 'devices.inuse_to_idle_timer',
				permission: 'inuse_to_idle_timer',
				type: 'number',
				validation: this.validationRules.inuse_to_idle_timer
			},
			{
				name: 'idle_to_charge_timer',
				label: 'devices.idle_to_charge_timer',
				permission: 'idle_to_charge_timer',
				type: 'number',
				validation: this.validationRules.idle_to_charge_timer
			},
			{
				name: 'idle_to_inuse_timer',
				label: 'devices.idle_to_inuse_timer',
				permission: 'idle_to_inuse_timer',
				type: 'number',
				validation: this.validationRules.idle_to_inuse_timer
			},
			{
				name: 'time_hysteresis',
				label: 'devices.time_hysteresis',
				permission: 'time_hysteresis',
				type: 'number',
				validation: this.validationRules.time_hysteresis
			},
			{
				name: 'operation_mode',
				label: 'devices.operation_mode',
				permission: 'operation_mode',
				type: 'number',
				validation: this.validationRules.operation_mode
			},
			{
				name: 'replacement_part',
				label: 'devices.replacement_part',
				permission: 'replacement_part',
				type: 'boolean',
				validation: this.validationRules.replacement_part
			},
			{
				name: 'zone_id',
				label: 'devices.zone_id',
				permission: 'zone_id',
				type: 'dropdown',
				items: this.zones,
				bindLabel: 'display_name',
				bindValue: 'id',
				placeholder: 'g.select_time_zone',
				validation: this.validationRules.zone_id,
				tooltip: this.deviceService.getTranslationKey('zone_id', this.device),
			},
			{
				name: 'enable_rt',
				label: 'devices.enable_rt',
				permission: 'enable_rt',
				type: 'boolean',
				validation: this.validationRules.enable_rt
			},
			{
				name: 'rt_log_frequency_fmt',
				label: 'devices.rt_log_frequency',
				permission: 'rt_log_frequency',
				type: 'number',
				validation: this.getLogFrequencyUnitValidationRule()
			},
			{
				name: 'rt_log_frequency_unit',
				label: 'devices.rt_log_frequency_unit',
				permission: 'rt_log_frequency',
				type: 'dropdown',
				items: this.deviceCommonService.ddLists.rt_log_frequency_units,
				bindLabel: 'text',
				bindValue: 'id',
				placeholder: 'g.frequency_unit',
			},
			{
				name: 'config_version',
				label: 'devices.config_version',
				permission: 'config_version',
				type: 'label'
			}
		);

		this.deviceSettingsFormFields.push(
			{
				name: 'has_current_low_range',
				label: 'devices.has_current_low_range',
				permission: 'has_current_low_range',
				type: 'boolean',
				validation: this.validationRules.has_current_low_range
			},
			{
				name: 'disable_external_rtc',
				label: 'devices.disable_external_rtc',
				permission: 'disable_external_rtc',
				type: 'boolean',
				validation: this.validationRules.disable_external_rtc,
				tooltip: !this.isFWSupportField('disable_external_rtc')? 'disable_fields_fw_supported' : false,
				disabled: !this.isFWSupportField('disable_external_rtc')
			},
			{
				name: 'disable_xtra',
				label: 'devices.disable_xtra',
				permission: 'disable_xtra',
				type: 'boolean',
				validation: this.validationRules.disable_xtra,
				tooltip: !this.isFWSupportField('disable_xtra')? 'disable_fields_fw_supported' : false,
				disabled: !this.isFWSupportField('disable_xtra')
			},
			{
				name: 'sct_device_type',
				label: 'devices.sct_device_type',
				permission: 'noc',
				type: 'dropdown',
				items: DeviceFactory.options,
				bindLabel: 'name',
				bindValue: 'id',
				validation: this.validationRules.sct_device_type
			},
			{
				name: 'enable_current_mask',
				label: 'devices.enable_current_mask',
				permission: 'enable_current_mask',
				type: 'boolean',
				validation: this.validationRules.enable_current_mask,
				tooltip: !this.isFWSupportField('enable_current_mask')? 'disable_enable_current_mask_fw_supported' : false,
				disabled: !this.isFWSupportField('enable_current_mask')
			},
		);
	}

	private getLogFrequencyUnitValidationRule() {
		return {
			dependsOn: 'rt_log_frequency_unit',
			rules: [
				{
					value: 1, // seconds
					validation: this.validationRules.rt_log_frequency
				},
				{
					value: 2, // minutes
					validation: [{"type": "integer", "min": 1, "max": 4}]
				}
			]
		};
	}

	prepareBatterySettingsForm() {
		this.batterySettingsFormFields = [
			{
				name: 'fi_duration',
				label: 'devices.fi_duration',
				permission: 'fi_duration',
				type: 'number',
				validation: this.validationRules.fi_duration
			},
			{
				name: 'eq_duration',
				label: 'devices.eq_duration',
				permission: 'eq_duration',
				type: 'number',
				validation: this.validationRules.eq_duration
			},
			{
				name: 'vpc',
				label: 'devices.vpc',
				permission: 'vpc',
				type: 'text',
				validation: this.validationRules.vpc
			}
		];

		if (this.deviceConfig.fields.trickleVoltage) {
			this.batterySettingsFormFields.push({
				name: 'trickle_voltage',
				label: 'devices.trickle_voltage',
				permission: 'trickle_voltage',
				type: 'number',
				validation: this.validationRules.trickle_voltage
			})
		}

		if (this.deviceConfig.fields.cvTargetVoltage) {
			this.batterySettingsFormFields.push({
				name: 'cv_target_voltage',
				label: 'devices.cv_target_voltage',
				permission: 'cv_target_voltage',
				type: 'text',
				validation: this.validationRules.cv_target_voltage
			})
		}

		if (this.deviceConfig.fields.fiTargetVoltage) {
			this.batterySettingsFormFields.push({
				name: 'fi_target_voltage',
				label: 'devices.fi_target_voltage',
				permission: 'fi_target_voltage',
				type: 'text',
				validation: this.validationRules.fi_target_voltage
			})
		}

		if (this.deviceConfig.fields.eqVoltage) {
			this.batterySettingsFormFields.push({
				name: 'eq_voltage',
				label: 'devices.eq_voltage',
				permission: 'eq_voltage',
				type: 'text',
				validation: this.validationRules.eq_voltage
			})
		}

		if (this.deviceConfig.fields.trickleCurrentRate) {
			this.batterySettingsFormFields.push({
				name: 'trickle_current_rate',
				label: 'devices.trickle_current_rate',
				permission: 'trickle_current_rate',
				type: 'text',
				validation: this.validationRules.trickle_current_rate
			})
		}

		if (this.deviceConfig.fields.ccRate) {
			this.batterySettingsFormFields.push({
				name: 'cc_rate',
				label: 'devices.cc_rate',
				permission: 'cc_rate',
				type: 'text',
				validation: this.validationRules.cc_rate
			})
		}

		if (this.deviceConfig.fields.cvEndCurrentRate) {
			this.batterySettingsFormFields.push({
				name: 'cv_end_current_rate',
				label: 'devices.cv_end_current_rate',
				permission: 'cv_end_current_rate',
				type: 'text',
				validation: this.validationRules.cv_end_current_rate
			})
		}

		if (this.deviceConfig.fields.cvCurrentStep) {
			this.batterySettingsFormFields.push({
				name: 'cv_current_step',
				label: 'devices.cv_current_step',
				permission: 'cv_current_step',
				type: 'text',
				validation: this.validationRules.cv_current_step
			})
		}

		if (this.deviceConfig.fields.fiCurrentRate) {
			this.batterySettingsFormFields.push({
				name: 'fi_current_rate',
				label: 'devices.fi_current_rate',
				permission: 'fi_current_rate',
				type: 'text',
				validation: this.validationRules.fi_current_rate
			})
		}

		if (this.deviceConfig.fields.eqCurrentRate) {
			this.batterySettingsFormFields.push({
				name: 'eq_current_rate',
				label: 'devices.eq_current_rate',
				permission: 'eq_current_rate',
				type: 'text',
				validation: this.validationRules.eq_current_rate
			})
		}

		if (this.deviceConfig.fields.batteryCapacity) {
			this.batterySettingsFormFields.push({
				name: 'battery_capacity',
				label: 'devices.battery_capacity',
				permission: 'battery_capacity',
				type: 'number',
				validation: this.validationRules.battery_capacity,
				tooltip: 'battery_capacity'
			})
		}

		if (this.deviceConfig.fields.batteryType) {
			this.batterySettingsFormFields.push({
				name: 'battery_type',
				label: 'devices.battery_type',
				permission: 'battery_type',
				type: 'dropdown',
				items: this.deviceCommonService.ddLists.battery_type,
				bindLabel: 'label',
				bindValue: 'id',
				validation: this.validationRules.battery_type,
				tooltip: 'battery_type'
			})
		}

		if (this.deviceConfig.fields.numberOfCells) {
			this.batterySettingsFormFields.push({
				name: 'number_of_cells',
				label: 'devices.number_of_cells',
				permission: 'number_of_cells',
				type: 'dropdown',
				items: this.deviceCommonService.ddLists.number_of_cells,
				bindLabel: 'label',
				bindValue: 'id',
				validation: this.validationRules.number_of_cells,
				tooltip: 'number_of_cells'
			})
		}

		if (this.deviceConfig.fields.batteryTemperatureCompensationInMv) {
			this.batterySettingsFormFields.push({
				name: 'battery_temperature_compensation_in_mv',
				label: 'devices.battery_temperature_compensation_in_mv',
				permission: 'battery_temperature_compensation_in_mv',
				type: 'number',
				validation: this.validationRules.battery_temperature_compensation_in_mv
			})
		}

		if (this.deviceConfig.fields.fiDt) {
			this.batterySettingsFormFields.push({
				name: 'fi_dt',
				label: 'devices.fi_dt',
				permission: 'fi_dt',
				type: 'number',
				validation: this.validationRules.fi_dt
			})
		}

		if (this.deviceConfig.fields.fiDv) {
			this.batterySettingsFormFields.push({
				name: 'fi_dv',
				label: 'devices.fi_dv',
				permission: 'fi_dv',
				type: 'number',
				validation: this.validationRules.fi_dv
			})
		}

		if (this.deviceConfig.fields.liftCurrentThresholdPercentage) {
			this.batterySettingsFormFields.push({
				name: 'lift_current_threshold_percentage',
				label: 'devices.lift_current_threshold_percentage',
				permission: 'lift_current_threshold_percentage',
				type: 'number',
				validation: this.validationRules.lift_current_threshold_percentage,
				tooltip: !this.isFWSupportField('lift_current_threshold_percentage')? 'disable_fields_fw_supported' : 'lift_current_threshold_percentage_tooltip',
				disabled: !this.isFWSupportField('lift_current_threshold_percentage')
			})
		}

		if (this.deviceConfig.fields.liftCurrentThresholdValue) {
			this.batterySettingsFormFields.push({
				name: 'lift_current_threshold_value',
				label: 'devices.lift_current_threshold_value',
				permission: 'lift_current_threshold_value',
				type: 'number',
				disabled: true,
				tooltip: 'lift_current_threshold_value_tooltip'
			})
		}

		if (this.deviceConfig.fields.liftCurrentThresholdDeltaPercentage) {
			this.batterySettingsFormFields.push({
				name: 'lift_current_threshold_delta_percentage',
				label: 'devices.lift_current_threshold_delta_percentage',
				permission: 'lift_current_threshold_delta_percentage',
				type: 'number',
				validation: this.validationRules.lift_current_threshold_delta_percentage,
				tooltip: !this.isFWSupportField('lift_current_threshold_delta_percentage')? 'disable_fields_fw_supported' : 'lift_current_threshold_delta_percentage_tooltip',
				disabled: !this.isFWSupportField('lift_current_threshold_delta_percentage')
			})
		}

		if (this.deviceConfig.fields.liftCurrentThresholdDeltaValue) {
			this.batterySettingsFormFields.push({
				name: 'lift_current_threshold_delta_value',
				label: 'devices.lift_current_threshold_delta_value',
				permission: 'lift_current_threshold_delta_value',
				type: 'number',
				disabled: true,
				tooltip: 'lift_current_threshold_delta_value_tooltip'
			})
		}

		if (this.deviceConfig.fields.chargerType) {
			this.batterySettingsFormFields.push(
				{
					name: 'charger_type',
					label: 'devices.charger_type',
					bindLabel: 'label',
					bindValue: 'value',
					permission: 'charger_type',
					validation:  this.validationRules.charger_type,
					type: 'dropdown',
					items: this.deviceCommonService.chargersType,
				}
			);
		}

		this.batterySettingsFormFields.push({
			name: 'max_battery_temperature',
			label: 'devices.max_battery_temperature',
			permission: 'max_battery_temperature',
			type: 'number',
			validation: this.validationRules.max_battery_temperature,
			tooltip: !this.isFWSupportField('max_battery_temperature') ? 'disable_max_battery_temperature_fw_supported' : false,
			disabled: !this.isFWSupportField('max_battery_temperature')
		});

		this.batterySettingsFormFields.push({
			name: 'cool_down_temperature',
			label: 'devices.cool_down_temperature',
			permission: 'cool_down_temperature',
			type: 'number',
			validation: this.validationRules.cool_down_temperature,
			tooltip: !this.isFWSupportField('cool_down_temperature') ? 'disable_cool_down_temperature_fw_supported' : false,
			disabled: !this.isFWSupportField('cool_down_temperature')
		});
	}

	prepareCalForm() {
		this.calFormFields = [
			{
				name: 'current_drift_adc_low_range',
				label: 'devices.current_drift_adc_low_range',
				permission: 'current_drift_adc_low_range',
				type: 'text',
				validation: this.validationRules.current_drift_adc_low_range
			},
			{
				name: 'voltage_drift_adc',
				label: 'devices.voltage_drift_adc',
				permission: 'voltage_drift_adc',
				type: 'text',
				validation: this.validationRules.voltage_drift_adc
			},
			{
				name: 'voltage_scale_adc',
				label: 'devices.voltage_scale_adc',
				permission: 'voltage_scale_adc',
				type: 'text',
				validation: this.validationRules.voltage_scale_adc
			},
			{
				name: 'current_drift_adc',
				label: 'devices.current_drift_adc',
				permission: 'current_drift_adc',
				type: 'text',
				validation: this.validationRules.current_drift_adc
			},
			{
				name: 'current_scale_adc',
				label: 'devices.current_scale_adc',
				permission: 'current_scale_adc',
				type: 'text',
				validation: this.validationRules.current_scale_adc
			},
			{
				name: 'temperature_beta',
				label: 'devices.temperature_beta',
				permission: 'temperature_beta',
				type: 'text',
				validation: this.validationRules.temperature_beta
			},
			{
				name: 'current_scale_adc_low_range',
				label: 'devices.current_scale_adc_low_range',
				permission: 'current_scale_adc_low_range',
				type: 'text',
				validation: this.validationRules.current_scale_adc_low_range
			},
			{
				name: 'temperature_res_dividor',
				label: 'devices.temperature_res_dividor',
				permission: 'temperature_res_dividor',
				type: 'text',
				validation: this.validationRules.temperature_res_dividor
			},
			{
				name: 'temperature_res_drift',
				label: 'devices.temperature_res_drift',
				permission: 'temperature_res_drift',
				type: 'text',
				validation: this.validationRules.temperature_res_drift
			},
			{
				name: 'temperature_res_dividor_drift',
				label: 'devices.temperature_res_dividor_drift',
				permission: 'temperature_res_dividor_drift',
				type: 'text',
				validation: this.validationRules.temperature_res_dividor_drift
			},
			{
				name: 'probe_drift_adc',
				label: 'devices.probe_drift_adc',
				permission: 'probe_drift_adc',
				type: 'text',
				validation: this.validationRules.probe_drift_adc
			},
			{
				name: 'probe_scale_adc',
				label: 'devices.probe_scale_adc',
				permission: 'probe_scale_adc',
				type: 'text',
				validation: this.validationRules.probe_scale_adc
			},
			{
				name: 'gallons_count_scale',
				label: 'devices.gallons_count_scale',
				permission: 'gallons_count_scale',
				type: 'text',
				validation: this.validationRules.gallons_count_scale
			},
			{
				name: 'high_probe_voltage',
				label: 'devices.high_probe_voltage',
				permission: 'high_probe_voltage',
				type: 'text',
				validation: this.validationRules.high_probe_voltage
			},
		];
	}

	prepareNetworkingSettingsForm() {
		this.deviceService.getShippingConnectivityData(this.device.mac_address).subscribe((data: any) => {
			let disableCellularReconnectTime = false;
			let revertTimeDate = null;
			if(data[0]) {
				revertTimeDate = moment(data[0].action_time).toDate();
				const currentTime = new Date();
				disableCellularReconnectTime = revertTimeDate > currentTime;
			}
			this.networkingSettingsFormFields = [
				{
					name: 'soft_ap_exit',
					label: 'devices.soft_ap_exit',
					permission: 'soft_ap_exit',
					type: 'text',
					validation: this.validationRules.soft_ap_exit
				},
				{
					name: 'soft_ap_exit_no_setup',
					label: 'devices.soft_ap_exit_no_setup',
					permission: 'soft_ap_exit_no_setup',
					type: 'text',
					validation: this.validationRules.soft_ap_exit_no_setup
				},
				{
					name: 'remoter_server_port',
					label: 'devices.remoter_server_port',
					permission: 'remoter_server_port',
					type: 'text',
					validation: this.validationRules.remoter_server_port
				},
				{
					name: 'mobile_port',
					label: 'devices.mobile_port',
					permission: 'mobile_port',
					type: 'text',
					validation: this.validationRules.mobile_port
				},
				{
					name: 'master_port',
					label: 'devices.master_port',
					permission: 'master_port',
					type: 'text',
					validation: this.validationRules.master_port
				},
				{
					name: 'reconnect_time',
					label: 'devices.reconnect_time',
					permission: 'reconnect_time',
					type: 'text',
					validation: this.validationRules.reconnect_time
				},
				{
					name: 'remoter_server_ip_options',
					label: 'devices.remoter_server_ip',
					permission: 'remoter_server_ip',
					type: 'dropdown',
					items: this.deviceCommonService.ddLists.remoter_server_ip,
					bindLabel: 'text',
					bindValue: 'id',
					validation: this.validationRules.remoter_server_ip
				},
				{
					name: 'remoter_server_ip',
					label: '',
					permission: 'remoter_server_ip',
					type: 'text',
					editable: {relatedField: 'remoter_server_ip_options', relatedValues: ['production', 'staging', 'development']},
					validation: this.validationRules.remoter_server_ip
				},
				{
					name: 'enable_multicast',
					label: 'devices.enable_multicast',
					permission: 'enable_multicast',
					type: 'boolean',
					validation: this.validationRules.enable_multicast
				},
				{
					name: 'multicast_port',
					label: 'devices.multicast_port',
					permission: 'multicast_port',
					type: 'text',
					validation: this.validationRules.multicast_port
				},
				{
					name: 'multicast_ip',
					label: 'devices.multicast_ip',
					permission: 'multicast_ip',
					type: 'text',
					validation: this.validationRules.multicast_ip
				},
				{
					name: 'ap_password',
					label: 'devices.ap_password',
					permission: 'ap_password',
					type: 'password',
					validation: this.validationRules.ap_password
				},
				{
					name: 'ap_ssid',
					label: 'devices.ap_ssid',
					permission: 'ap_ssid',
					type: 'text',
					validation: this.validationRules.ap_ssid
				},

				{
					name: 'enable_wifi_ap_on_start',
					label: 'devices.enable_wifi_ap_on_start',
					permission: 'enable_wifi_ap_on_start',
					type: 'boolean',
					validation: this.validationRules.enable_wifi_ap_on_start
				},
				{
					name: 'ap_channel',
					label: 'devices.ap_channel',
					permission: 'ap_channel',
					type: 'text',
					validation: this.validationRules.ap_channel
				},
				{
					name: 'enable_serversocket',
					label: 'devices.enable_serversocket',
					permission: 'enable_serversocket',
					type: 'boolean',
					validation: this.validationRules.enable_serversocket
				},
				{
					name: 'enc_code',
					label: 'devices.enc_code',
					permission: 'enc_code',
					type: 'text',
					validation: this.validationRules.enc_code
				},
				{
					name: 'is_online_enabled',
					label: 'devices.is_online_enabled',
					permission: 'is_online_enabled',
					type: 'boolean',
					validation: this.validationRules.is_online_enabled
				},
				{
					name: 'server_socket_port',
					label: 'devices.server_socket_port',
					permission: 'server_socket_port',
					type: 'text',
					validation: this.validationRules.server_socket_port
				},
				{
					name: 'enable_cellular',
					label: 'devices.enable_cellular',
					permission: 'enable_cellular',
					type: 'boolean',
					validation: this.validationRules.enable_cellular
				},
				{
					name: 'cellular_region',
					label: 'devices.cellular_region',
					permission: 'cellular_region',
					type: 'dropdown',
					items: this.deviceCommonService.ddLists.cellular_region,
					bindLabel: 'text',
					bindValue: 'id',
					validation: this.validationRules.cellular_region,
				},
				{
					name: 'cell_access_point_name',
					label: 'devices.cell_access_point_name',
					permission: 'cell_access_point_name',
					type: 'text',
					validation: this.validationRules.cell_access_point_name
				},
				{
					name: 'lte_cellular_bands',
					label: 'devices.lte_cellular_bands',
					permission: 'lte_cellular_bands',
					type: 'dropdown',
					items: this.deviceCommonService.ddLists.lte_bands,
					bindLabel: 'text',
					bindValue: 'value',
					multiple: true,
					required: true,
					validation: this.validationRules.lte_cellular_bands,
					error_message: this.translate.instant('devices.lte_cellular_bands_error'),
				},
				{
					name: 'nbiot_cellular_bands',
					label: 'devices.nbiot_cellular_bands',
					permission: 'nbiot_cellular_bands',
					type: 'dropdown',
					items: this.deviceCommonService.ddLists.nbiot_bands,
					bindLabel: 'text',
					bindValue: 'value',
					multiple: true,
					validation: this.validationRules.nbiot_cellular_bands,
				},
				{
					name: 'cellular_reconnect_time',
					label: 'devices.cellular_reconnect_time',
					permission: 'cellular_reconnect_time',
					type: 'text',
					validation: this.validationRules.cellular_reconnect_time,
					disabled: disableCellularReconnectTime,
					tooltip: disableCellularReconnectTime ? this.translate.instant('connectivity_frequency.tooltip') + moment(revertTimeDate).format('MM/DD/yy hh:mm:ss A') : false,
					tooltipIsTranslated: true
				},
				{
					name: 'metered_flag',
					label: 'devices.metered_flag',
					permission: 'metered_flag',
					type: 'dropdown',
					items: this.deviceCommonService.ddLists.metered_flag,
					bindLabel: 'label',
					bindValue: 'value',
					validation: this.validationRules.metered_flag
				},
			];
		});
	}

	prepareNetworkingForm() {
		this.networkingFormFields = [{
				name: 'enable_wifi_sta',
				label: 'devices.enable_wifi_sta',
				permission: 'enable_wifi_sta',
				type: 'boolean',
				validation: this.validationRules.enable_wifi_sta
			},
			{
				name: ['mobile_master', 'ssid'],
				label: 'devices.mobile_master_ssid',
				permission: 'mobile_master.ssid',
				type: 'text',
				validation: this.validationRules.mobile_master.ssid,
				required: true
			},
			{
				name: ['mobile_master', 'password'],
				label: 'devices.mobile_master_password',
				permission: 'mobile_master.password',
				type: 'password',
				validation: this.validationRules.mobile_master.password,
			},
			{
				name: ['mobile_master', 'priority'],
				label: 'devices.mobile_master_priority',
				permission: 'mobile_master.priority',
				type: 'dropdown',
				items: this.deviceCommonService.ddLists.networkPriorities,
				bindLabel: 'text',
				bindValue: 'id',
				validation: this.validationRules.mobile_master.priority
			},

			{
				name: ['mobile_network', 'ssid'],
				label: 'devices.mobile_network_ssid',
				permission: 'mobile_network.ssid',
				type: 'text',
				validation: this.validationRules.mobile_network.ssid,
				required: true
			},
			{
				name: ['mobile_network', 'password'],
				label: 'devices.mobile_network_password',
				permission: 'mobile_network.password',
				type: 'password',
				validation: this.validationRules.mobile_network.password,
			},
			{
				name: ['mobile_network', 'priority'],
				label: 'devices.mobile_network_priority',
				permission: 'mobile_network.priority',
				type: 'dropdown',
				items: this.deviceCommonService.ddLists.networkPriorities,
				bindLabel: 'text',
				bindValue: 'id',
				validation: this.validationRules.mobile_network.priority
			},

			{
				name: ['router_network', 'ssid'],
				label: 'devices.router_network_ssid',
				permission: 'router_network.ssid',
				type: 'text',
				validation: this.validationRules.router_network.ssid,
				required: true
			},
			{
				name: ['router_network', 'password'],
				label: 'devices.router_network_password',
				permission: 'router_network.password',
				type: 'password',
				validation: this.validationRules.router_network.password
			},
			{
				name: ['router_network', 'priority'],
				label: 'devices.router_network_priority',
				permission: 'router_network.priority',
				type: 'dropdown',
				items: this.deviceCommonService.ddLists.networkPriorities,
				bindLabel: 'text',
				bindValue: 'id',
				validation: this.validationRules.router_network.priority
			},

			{
				name: ['online_network', 'ssid'],
				label: 'devices.online_network_ssid',
				permission: 'online_network.ssid',
				type: 'text',
				validation: this.validationRules.online_network.ssid,
				required: true
			},
			{
				name: ['online_network', 'bssid'],
				label: 'devices.online_network_bssid',
				permission: 'online_network.bssid',
				type: 'text',
				validation: this.validationRules.online_network.bssid
			},
			{
				name: ['online_network', 'password'],
				label: 'devices.online_network_password',
				permission: 'online_network.password',
				type: 'password',
				validation: this.validationRules.online_network.password
			},
			{
				name: ['online_network', 'priority'],
				label: 'devices.online_network_priority',
				permission: 'online_network.priority',
				type: 'dropdown',
				items: this.deviceCommonService.ddLists.networkPriorities,
				bindLabel: 'text',
				bindValue: 'id',
				validation: this.validationRules.online_network.priority
			}
		];
	}

	prepareBilledKWHrsSettingsForm() {
		this.billedKWHrsSettingsFormFields = [];

		this.billedKWHrsSettingsFormFields.push({
			name: 'charger_efficiency',
			label: 'devices.charger_efficiency',
			permission: 'charger_efficiency',
			type: 'text',
			validation: this.validationRules.charger_efficiency
		});

		this.billedKWHrsSettingsFormFields.push({
			name: 'battery_efficiency',
			label: 'devices.battery_efficiency',
			permission: 'battery_efficiency',
			type: 'text',
			validation: this.validationRules.battery_efficiency
		});

		this.billedKWHrsSettingsFormFields.push(
			{
				name: 'return_factor',
				label: 'devices.return_factor',
				permission: 'return_factor',
				type: 'text',
				validation: this.validationRules.return_factor
			},
		);
	}

	prepareMaintenanceScheduleForm() {
		this.maintenanceScheduleFormFields = [];
		if(this.validationRules.report_ahr) {
			this.maintenanceScheduleFormFields.push(
				{
					name: 'report_ahr_opt',
					label: 'maintenance_schedule.report_ahr_opt',
					permission: 'maintenance_schedule.report_ahr_opt',
					type: 'boolean',
					validation: this.validationRules.report_ahr_opt,
					tooltip: 'report_ahr_opt'
				},
				{
					name: 'report_ahr',
					label: 'maintenance_schedule.report_ahr',
					permission: 'maintenance_schedule.report_ahr',
					type: 'text',
					validation: this.validationRules.report_ahr,
					controlledBy: 'report_ahr_opt'
				},
			);
		}
		if(this.validationRules.report_kwhr) {
			this.maintenanceScheduleFormFields.push(
				{
					name: 'report_kwhr_opt',
					label: 'maintenance_schedule.report_kwhr_opt',
					permission: 'maintenance_schedule.report_kwhr_opt',
					type: 'boolean',
					validation: this.validationRules.report_kwhr_opt,
					tooltip: 'report_kwhr_opt'
				},
				{
					name: 'report_kwhr',
					label: 'maintenance_schedule.report_kwhr',
					permission: 'maintenance_schedule.report_kwhr',
					type: 'text',
					validation: this.validationRules.report_kwhr,
					controlledBy: 'report_kwhr_opt'
				},
			);
		}
		this.maintenanceScheduleFormFields = this.maintenanceScheduleFormFields.concat([
			{
				name: 'report_usage_hours_opt',
				label: this.deviceService.getTranslationKey('maintenance_schedule.report_usage_hours_opt', this.device),
				permission: 'maintenance_schedule.report_usage_hours_opt',
				type: 'boolean',
				validation: this.validationRules.report_usage_hours_opt,
				tooltip: 'report_usage_hours_opt'
			},
			{
				name: 'report_usage_hours',
				label: this.deviceService.getTranslationKey('mmaintenance_schedule.report_usage_hours', this.device),
				permission: 'maintenance_schedule.report_usage_hours',
				type: 'text',
				validation: this.validationRules.report_usage_hours,
				controlledBy: 'report_usage_hours_opt'
			},
			{
				name: 'report_period_opt',
				label: 'maintenance_schedule.report_period_opt',
				permission: 'maintenance_schedule.report_period_opt',
				type: 'boolean',
				validation: this.validationRules.report_period_opt,
				tooltip: 'report_period_opt'
			},
			{
				name: 'report_period',
				label: 'maintenance_schedule.report_period',
				permission: 'maintenance_schedule.report_period',
				type: 'text',
				validation: this.validationRules.report_period,
				controlledBy: 'report_period_opt'
			}
		]);
	}

	prepareMaintenanceScheduleValidation() {
		let rules: any = {
			report_ahr_opt: [{type: 'integer', min: 0, max: 1}],
			report_ahr: [{type: 'integer', min: Math.round(24 * this.device.battery_capacity), max: Math.round(876 * this.device.battery_capacity)}],
			report_kwhr_opt: [{type: 'integer', min: 0, max: 1}],
			report_kwhr: [{type: 'integer', min: Math.round(24 * this.device.battery_capacity * this.device.number_of_cells * this.device.vpc / 100), max: Math.round(876 * this.device.battery_capacity * this.device.number_of_cells * this.device.vpc / 100)}],
			report_usage_hours_opt: [{type: 'integer', min: 0, max: 1}],
			report_usage_hours: [{type: 'integer', min: 10 * 24, max: 365 * 24}],
			report_period_opt: [{type: 'integer', min: 0, max: 1}],
			report_period: [{type: 'integer', min: 30, max: 1000}],
		};
		if(this.multiDevices) {
			delete rules.report_ahr;
			delete rules.report_kwhr;
		}
		if(this.extraRules) {
			if(this.extraRules.minAhr)
				rules.report_ahr = [{type: 'integer', min: Math.round(this.extraRules.minAhr), max: Math.round(this.extraRules.maxAhr)}];
			if(this.extraRules.minKwhr)
				rules.report_kwhr = [{type: 'integer', min: Math.round(this.extraRules.minKwhr), max: Math.round(this.extraRules.maxKwhr)}];
		}
		return rules;
	}

	prepareValidationRules() {
		this.validationRules = this.commonUtil.getValidationRules();
		this.validationRules = Object.assign(this.validationRules, this.prepareMaintenanceScheduleValidation());
	}

	onChangeBatteryType(type) {
		if(type == this.deviceService.batteryTypesByName.lithuim_ion) {
			this.device.vpc = 3;
			this.forceDirtyFields.push('vpc');
		} else if(type == this.deviceService.batteryTypesByName.lead) {
			this.device.vpc = 2;
			this.forceDirtyFields.push('vpc');
		}
	}
	onChangeRemoteServerIp(type) {
		let ipObj = this.serverIpsIdMap[type];

		if(ipObj.domain)
			this.device.remoter_server_ip = ipObj.domain;
		else
			this.device.remoter_server_ip = this.oldDevice.remoter_server_ip;

		// if(this.device.is_dev_device)
		// 	this.device.enc_code = this.serverIpsIdMap.dev.encCode;
		// else
			this.device.enc_code = ipObj.encCode;
		this.device.remoter_server_port = ipObj.port;
	}

	onDropdownChanged(event) {
		switch(event.field) {
			case 'battery_type':
				this.onChangeBatteryType(event.value);
			break;
			case 'remoter_server_ip_options':
				this.onChangeRemoteServerIp(event.value);
			break;
		}
	}

	scanWifiNetworks(refresh?) {
		if(refresh)
			this.availableWifiNetworksModal.hide();
		this.availableWifiNetworks = [];
		this.deviceService.sendCommand(this.device.mac_address, 'scanWifiNetworks').subscribe((res: any) => {
			this.availableWifiNetworks = this.deviceService.formatWifiNetworks(res.networks);
			this.availableWifiNetworksModal.show();
		});
	}

	selectWifiNetwork(network) {
		this.availableWifiNetworksModal.hide();
		this.selectWifiNetworksModal.show();
		this.selectedWifiNetwork = network;
	}

	updateWifiNetwork() {
		let valuesToChange = {
			'online_network.ssid': this.selectedWifiNetwork.ssid,
			'online_network.bssid': this.selectedWifiNetwork.bssid,
		};

		let isOpen = this.deviceService.formatWifiAuthMode(this.selectedWifiNetwork.auth_mode) == this.deviceService.wifiAuthMode.open;
		if(isOpen || this.device.online_network.ssid != this.selectedWifiNetwork.ssid) {
			valuesToChange['online_network.password'] = '';
		}

		for(let model in valuesToChange) {
			let modelArr = model.split('.');
			this.device[modelArr[0]][modelArr[1]] = valuesToChange[model];
			this.forceDirtyFields.push(model);
		}
	}

	prepareMaintenanceScheduleData() {
		if(!this.multiDevices && this.maintenanceSchedule.report_usage_hours === undefined) {
			let forceFields = {
				report_usage_hours_opt: true,
				report_usage_hours: 30 * 24
			};
			this.forceDirtyFields = Object.keys(forceFields);
			this.maintenanceSchedule = Object.assign(this.maintenanceSchedule, forceFields);
		}
	}

	fillStudiesField() {
		let fields = [];

		if (!this.deviceConfig.couldHaveStudy)
			return fields;

		if (this.device.is_study_device)
			fields = [
				{
					name: 'study_name',
					label: 'devices.study_name',
					permission: 'study_name',
					type: 'label',
					validation: this.validationRules.study_name
				},
				{
					name: 'study_start_event_id',
					label: 'devices.study_start_event_id',
					permission: 'study_start_event_id',
					type: 'label',
					validation: this.validationRules.study_start_event_id,
				},
			];

		if (!this.usersService.hasAccessFunction('shipment_management')) {
			fields.push(
				{
					name: 'is_study_device',
					label: 'studies.power_study',
					type: 'label',
					value: this.translate.instant(`g.${this.device.is_study_device ? 'yes' : 'no'}`),
					permission: 'read'
				},
			);
			delete this.changesStack['is_study_device'];
		} else {
			fields.push(
				{
					name: 'is_study_device',
					label: 'studies.power_study',
					permission: 'is_study_device',
					type: 'boolean',
					validation: this.validationRules.is_study_device
				},
			);
		}

		return fields;
	}

	showMultiPointCal(data) {
		return Object.keys(data).length > 0;
	}

	removeChangeStack(dataToUpdate, confirmed=true) {
		if (confirmed) {
			this.removedChangesStackInfo = dataToUpdate;
			if (dataToUpdate.userId != this.currentUser.id)
				this.confirmDeleteStack = true;
			return this.deleteChangeStack.show();
		}

		const {fieldName, commandId} = dataToUpdate;
		this.deviceService.removeChangeStack(fieldName, commandId).subscribe((response:any) => {
			switch(response) {
				case 1:
					Array.isArray(fieldName)? delete this.changesStack[fieldName[0]][fieldName[1]] : delete this.changesStack[fieldName];
					this.notificationMessage.setMessage('globalSuccessMsg', {clearOnXTimeNavigate: 1});
					break;
				case 2:
					this.notificationMessage.setMessage('translate|settings.queue_change_executed', {clearOnXTimeNavigate: 1, type: 'danger'});
					break;
				default:
					this.notificationMessage.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});
					break;
			}
		});
	};

	isFWSupportField(field: string) {
		switch (field) {
			case 'disable_external_rtc':
			case 'disable_xtra':
			case 'lift_current_threshold_percentage':
			case 'lift_current_threshold_delta_percentage':
			case 'current_lift_upper_threshold':
			case 'current_lift_lower_threshold':
				return this.device.fw_version >= 2.36;
			case 'enable_current_mask':
				return this.device.fw_version >= 2.3710;
			case 'max_battery_temperature':
			case 'cool_down_temperature':
				return this.device.fw_version >= 2.4401;
			default:
				return false;
		};
	}
}
