<!-- date filter -->
<div class="row col-xl-7 mb-4">
	<div class="col-xl-6 col-md-6">
		<div class="input-group">
			<div class="input-group-prepend">
				<span class="input-group-text">{{'g.start_date' | translate}}</span>
			</div>
			<input type="text" class="form-control" placeholder="mm/dd/yyyy" name="dp1" (ngModelChange)="changeDate('fromDate', $event)" [(ngModel)]="fromDate" bsDatepicker #d1="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}" [maxDate]="toDate">
			<div class="input-group-append pointer" (click)="d1.toggle()">
				<span class="input-group-text"><i class="fa fa-calendar"></i></span>
			</div>
		</div>
	</div>

	<div class="col-xl-6 col-md-6">
		<div class="input-group">
			<div class="input-group-prepend">
				<span class="input-group-text">{{'g.end_date' | translate}}</span>
			</div>
			<input type="text" class="form-control" placeholder="mm/dd/yyyy" name="dp2" (ngModelChange)="changeDate('toDate', $event)" [(ngModel)]="toDate" bsDatepicker #d2="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}" [minDate]="fromDate">
			<div class="input-group-append pointer" (click)="d2.toggle()">
				<span class="input-group-text"><i class="fa fa-calendar"></i></span>
			</div>
		</div>
	</div>
</div>

<div class="chart-container" *ngIf="showChart; else noData">
  <div class="mb-5 mt-5">
    <sct-charts
      class="chart"
      [id]="chart.firstChartTitle"
      [title]="chart.firstChartTitle"
      [data]="usedDevicesNumberChart.data"
      [columnNames]="usedDevicesNumberChart.columnNames"
      [options]="usedDevicesNumberChart.config"
      [type]="'ColumnChart'"
      [height]="200"
    ></sct-charts>
  </div>

	<sct-charts
		class="chart"
		[id]="chart.secondChartTitle"
		[title]="chart.secondChartTitle"
		[data]="usagePercentageChart.data"
		[columnNames]="usagePercentageChart.columnNames"
		[options]="usagePercentageChart.config"
		[type]="'LineChart'"
		[height]="200"
	></sct-charts>
</div>

<ng-template #noData class="mt-4">
	<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>
