<app-site-performance-widget
	[siteId]="siteId"
	[customerId]="customerId"
	[widgetConfig]="widgetConfig"
	[devices]="devices"
	[tableItems]="[]"
	[tableColumns]="[]"
	[exportFileName]="''"
	[sizeColumnsToFit]="false"
	[chartTableConfig]="{}"
	[tags]="tags"
	[noDataForCurrentFilters]="noDataForCurrentFilters"
	(filter)="onFilter($event)"
></app-site-performance-widget>
