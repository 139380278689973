import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { UsersService } from 'src/app/users/users.service';
import { AlertSettingsService } from '../alert-settings.service';

@Component({
	selector: 'app-users-alert-settings',
	templateUrl: './users-alert-settings.component.html',
	styleUrls: ['./users-alert-settings.component.css']
})

export class UsersAlertSettingsComponent implements OnInit {

	settings = {
		maintenance_reports: 0,
		temperature_alert_freq: 0,
		cold_temperature_alert_freq: 0,
		ebu_alert_freq: 0,
		potential_weak_cells_alert_freq: 0,
		deep_discharge_alert_freq: 0,
		over_charge_alert_freq: 0,
		low_water_level_alert_freq: 0,
		idle_times_alert_freq: 0,
		missed_connection_alert_freq: 0,
	};

	usersList: any = [];
	selectedUser: number = 0;
	type: string;
	typesValue: {0: any, 1: any, 2: any};
	rangeValue: any = 0;
	siteID: number;
	usersAlertSettings: any [] = [];

	constructor (
		private route: ActivatedRoute,
		private usersService: UsersService,
		private notificationMessage: NotificationMessageService,
		private translateService: TranslateService,
		private commonService: CommonService,
		private alertSettingsService: AlertSettingsService
	) {
		this.typesValue = {
			0: this.translateService.instant('alert_frequency.none'),
			1: this.translateService.instant('alert_frequency.daily'),
			2: this.translateService.instant('alert_frequency.weekly')
		}
	}

	ngOnInit(): void {
		this.siteID = this.route.parent.snapshot.params.siteId || 0;
		this.getUsersAndAlertSettings();
	}

	getUsersAndAlertSettings() {
		this.alertSettingsService.getUsersAlertSettings(+this.siteID).subscribe((res: any) => {
			res.users.map((item) => this.usersList.push({id: item.id, name: item.first_name +' '+ item.last_name}));
			this.usersList = this.commonService.sortDataAlphabetically(this.usersList, 'name');
			this.usersAlertSettings = res.usersAlertSettings;
		});
	}

	selectOption(id: number) {
		this.selectedUser = id;
		let userSettings = this.usersAlertSettings.filter(item => item.user_id == this.selectedUser);

		if (userSettings.length && userSettings[0].user_id == this.selectedUser) {
			for (const settings in userSettings[0]) {
				this.settings[settings] = +(Object.keys(this.typesValue).find(key => this.typesValue[key] == userSettings[0][settings]));
			}
		} else {
			this.rangeValue = 0;
		}

	}

	validateUserAlertSettings() {
		const validValues = Object.values(this.typesValue);
		for (let key in this.settings) {
			if (!validValues.includes(this.settings[key])) {
				delete this.settings[key];
			}
		}

		if (!Object.keys(this.settings).length)
			return false;
		return true;
	}

	onChange(e) {
		this.settings[e.target.id] = this.typesValue[e.target.value];
	}

	saveUserAlertSettings(selectedUser){
		if (this.validateUserAlertSettings()) {
			this.alertSettingsService.saveUserAlertSettings(selectedUser, this.settings, +this.siteID).subscribe((res: any) => {
				this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
				this.getUsersAndAlertSettings();
			});
		}
	}
}
