import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SitesService } from 'src/app/sites/sites.service';
import { DeviceFactory } from '../../../device-factory/device-factory';

@Component({
	selector: 'app-battlink-utilization-report',
	templateUrl: './battlink-utilization-report.component.html',
	styleUrls: ['./battlink-utilization-report.component.css']
})
export class BattlinkUtilizationReportComponent {
	siteId: number;
	data: any = {};
	devices: any = {};

	constructor(
		private sitesService: SitesService,
		private route: ActivatedRoute,
	) {}

	ngOnInit() {
		this.siteId = this.route.parent.snapshot.params.siteId || 0;
		this.sitesService.getUtilizationReportData(this.siteId, DeviceFactory.types.battlink).subscribe((res: any) => {
			this.data = res.data;
			this.devices = res.devices;
		});
	}
}
