import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { AdminService } from '../../admin.service';
import { TranslateService } from '@ngx-translate/core';
import { ServerPaginationComponent } from '../../../shared/server-pagination/server-pagination.component';

@Component({
	selector: 'app-action-log',
	templateUrl: './action-log.component.html',
	styleUrls: ['./action-log.component.css']
})
export class ActionLogComponent implements OnInit {

	recordsLimit = 200;
 	logMonths = [];
	actionsLog = <any>[];
	pageNum: number;
	filter: any = {};

	actions: {key: string, label: string}[] = [];

	columnNames = [];

	@ViewChild("serverPagination", { static: true }) serverPagination: ServerPaginationComponent;

	constructor(
		private adminService: AdminService,
		private commonService: CommonService,
		private translateService: TranslateService
	) {
	}

	ngOnInit() {

		this.columnNames = [
			{name: this.translateService.instant('action_log.action_name'), key:'action_name'},
			{name: this.translateService.instant('action_log.related_id_name'), key:'related_id'},
			{name: this.translateService.instant('action_log.additional_related_id_name'), key:'additional_related_id', headerClass: "text-center"},
			{name: this.translateService.instant('action_log.old_values'), key:'old_values', type: 'json' },
			{name: this.translateService.instant('action_log.new_values'), key:'new_values', type: 'json'},
			{name: this.translateService.instant('action_log.action_user_id_name'), key:'action_user_id'},
			{name: this.translateService.instant('action_log.action_time'), key:'action_time', type: 'dateTime'},
			{name: this.translateService.instant('g.ip_address'), key:'ip_address'},
			{name: this.translateService.instant('error_log.user_agent'), key:'user_agent'},
			{name: this.translateService.instant('g.git_info'), key:'git_info'},
		];

		this.actions = [
			'add_customer',
			'edit_customer',
			'delete_customer',
			'update_device_queue',
			'confirm_device_testing',
			'push_new_device_firmware',
			'abort_new_device_firmware',
			'device_settings_db',
			'set_device_sn',
			'reset_device_data_history',
			'update_device_password',
			'write_override_adc',
			'set_cal_status',
			'register_user_social',
			'register_user_registration_request',
			'register_user_add_user',
			'edit_user',
			'edit_user_admin',
			'delete_user',
			'user_login',
			'user_login_by_token',
			'social_user_login',
			'update_user_access_functions',
			'become_user',
			'hard_delete_tv',
			'change_device_owner',
			'delete_customer',
			'add_enterprise',
			'edit_enterprise',
			'delete_enterprise',
			'reject_registration_request',
			'approve_registration_request',
			'delete_site',
			'add_site',
			'edit_site',
			'delete_contact_us',
			'reverse_events',
			'reverse_calibration',
			'reverse_rt',
			'add_default_permissions_user',
			'add_default_permissions_enterprise',
			'edit_default_permissions_user',
			'edit_default_permissions_enterprise',
			'delete_default_permissions_user',
			'delete_default_permissions_enterprise',
			'add_model_notes',
			'delete_model_notes',
			'update_note_reminder_freq',
			'update_note_share_type',
			'process_firebase_token',
			'disable_user_firebase_token',
			'refresh_user_firebase_token',
			'new_management_requests_customer',
			'new_management_requests_site',
			'new_management_requests',
			'new_device_replacement_requests',
			'approve_customer_request',
			'approve_site_request',
			'approve_move_request',
			'approve_replacement_request',
			'map_to_approved_customer',
			'map_to_approved_site',
			'reject_customer_request',
			'reject_site_request',
			'reject_move_request',
			'reject_replacement_request',
			'insert_tag',
			'delete_tag',
			'hard_delete_tag',
			'deattach_device_tag',
			'deattach_device_all_tags',
			'attach_device_tag',
			'update_tag_sharing_options',
			'note_reminder_unsubscribe',
			'update_device',
			'flush_devices_cache',
			'set_last_maintenance_date',
			'receive_device_shipment',
			'check_shipment_quality',
			'save_maintenance_schedule',
			'ship_out_devices',
			'set_site_working_days',
			'bounced_user_email',
			'add_cm_device',
			'update_cm_device',
			'hard_delete_cm_device',
			'delete_cm_device',
			'reset_rtc_lost_detection',
			'delete_device',
			'generate_registration_purposes',
			'reset_device_after_hard_delete',
			'reset_device_after_hard_delete_super_admin',
			'site_add_attachment',
			'site_delete_attachment',
			'site_edit_attachment_note',
			'device_add_attachment',
			'device_delete_attachment',
			'device_edit_attachment_note',
			'create_orders',
			'edit_orders',
			'orders_add_tracking_number',
			'save_user_alert',
			'generate_sr_reports',
			'factory_reset',
			'set_device_time',
			'start_study',
			'delete_power_study',
			'update_noc_status_note',
			'remove_change_stack',
			'delete_order',
			'defective_device',
			'added_defective_device',
			'edited_defective_device',
			'deleted_defective_device',
			'delete_daily_details',
			'disable_xtra_file',
			'regenerate_daily_details',
			'regenerate_device_analytics_report',
			'clear_rtc_warnings',
			'move_from_rma_to_new_devices',
			'move_from_rma_to_discard',
			'user_changed_his_password',
			'reset_password',
			'email_confirmation',
			'enable_full_access',
			'replace_device',
			'cancle_search_events_by_ah',
			'generate_search_events_by_ah',
			'delete_site_group',
			'create_site_group',
			'edit_site_group',
			'create_brm_group',
			'delete_brm_group',
			'save_site_alert_settings',
			'custom_api_login',
		].map(action => ({key: action, label: this.translateService.instant('action_log.' + action)}))
			.sort((item1, item2) => item1.label.toLowerCase() > item2.label.toLowerCase() ? 1 : -1);

		this.filter.actionName = this.actions[0];

		let startMonth	= 12;
		let startYear	= 2019;
		let currDate	= new Date();
		let currMonth	= currDate.getMonth() + 1;
		let currYear	= currDate.getFullYear();

		for(let i = currYear; i >= startYear; i--) {
			for(let j = 12; j > 0; j--) {
				if(i == currYear && j > currMonth)
					continue;
				this.logMonths.push(this.commonService.lpad(String(j), 2) + '_' + i);
				if(i == startYear && j == startMonth)
					break;
			};
		};

		this.filter['logMonth'] = this.logMonths[0];
		this.getActionLogs(false, true);
	}

	getActionLogs(isBack=false, firstTime=false ) {
		if(firstTime)
			this.serverPagination.init();

		const options = {
			lastId: this.serverPagination.lastId,
			firstId: this.serverPagination.firstId,
			limit: this.recordsLimit,
			isBack,
			filter: this.filter
		}

		this.adminService.getActionLogs(options).subscribe((data: Object[]) => {
			this.actionsLog = [...data];

			if (this.actionsLog.length > this.recordsLimit)
				this.actionsLog.pop();

			this.serverPagination.updatePagination(data, isBack, firstTime);
			if(this.actionsLog.length > 0) {
				this.actionsLog.forEach(log=>{
					log.related_id = log.related_name || log.related_id;
					log.action_user_id = log.action_user_name || log.action_user_id;
					log.action_name = this.translateService.instant('action_log.' + log.action_name);
				})
			}
		});
	}
}
