import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
	selector: 'app-daily-utilization-chart',
	templateUrl: './daily-utilization-chart.component.html',
	styleUrls: ['./daily-utilization-chart.component.css']
})
export class DailyUtilizationChartComponent {
	@Input() data: any = {};
	@Input() devices: any = {};
	@Input() chart = {firstChartTitle: "", secondChartTitle: "", dateFormate: "yyyy-MM-dd", firstChartYAxeTitle: ""};
	@Input() maxValue: number = 800;
	@Input() showUsageValue: boolean = false;

	showChart = true;
	toDate = new Date();
	fromDate = new Date(this.toDate.getTime() - 7 * 24 * 60 * 60 * 1000); // 7 days ago

	usedDevicesNumberChart: {data: any, columnNames: string[], config: any} = {
		data: [],
		columnNames: [this.translate.instant('g.date'), this.translate.instant('g.used'), { role: 'annotation' }, this.translate.instant('g.not_used'), { role: 'annotation' }],
		config: {
			isStacked: true,
			hAxis: {
				title: this.translate.instant('g.date'),
				format: 'yyyy-MM-dd',
			},
			vAxis: {
				title: this.translate.instant('site.number_of_devices')
			},
			colors: ['#327339', '#b0c7b3'],
			legend: {
				position: 'top',
				alignment: 'center',
				textStyle: { fontSize: 12 }
			},
			titleTextStyle: {
				fontSize: 16,
				bold: true,
				color: '#333',
				paddingBottom: 20,
				paddingTop: 20
			},
		}
	};

	usagePercentageChart: {data: any, columnNames: string[], config: any} = {
		data: [],
		columnNames: [this.translate.instant('g.date'), this.translate.instant('g.usage_percentage')],
		config: {
			hAxis: {
				title: this.translate.instant('g.date'),
				format: 'yyyy-MM-dd',
			},
			vAxis: {
				title: this.translate.instant('g.usage_percentage'),
				viewWindow: {
					min: 0,
					max: 100
				}
			},
			colors: ['#327339'],
			legend: {
				position: 'top',
				alignment: 'center',
				textStyle: { fontSize: 12 }
			},
			titleTextStyle: {
				fontSize: 16,
				bold: true,
				color: '#333',
				paddingBottom: 20,
				paddingTop: 20
			},
		}
	}

	constructor(
		private translate: TranslateService,
	) {}

	ngOnInit() {
		this.usedDevicesNumberChart.config.vAxis.title = this.chart.firstChartYAxeTitle || this.usedDevicesNumberChart.config.vAxis.title;
		this.updateDateFormate();
		this.prepareDeviceNumberChartData();
	}

	changeDate(field, value) {
		this[field] = value;

		this.prepareDeviceNumberChartData();
	}

	updateDateFormate() {
		if (this.chart.dateFormate) {
			this.usedDevicesNumberChart.config.hAxis.format = this.chart.dateFormate;
			this.usagePercentageChart.config.hAxis.format = this.chart.dateFormate;
		}
	}

	prepareDeviceNumberChartData() {
		const usageData = [];
		const usagePercentageData = [];

		const usageCountObj = {};
		const usageValueObj = {};
		const usagePercentObj = {};

		const fromDate = moment(this.fromDate).startOf('day').unix() * 1000;
		const toDate = moment(this.toDate).endOf('day').unix() * 1000;
		const devicesCount = Object.keys(this.devices).length;

		this.updateChartTicks(fromDate, toDate);

		if (!devicesCount)
			return;

		for (const mac in this.data) {
			const deviceData = this.data[mac];

			for (const day in deviceData) {
				if (+day * 1000 < fromDate || +day * 1000 > toDate)
					continue;

				if (!usageCountObj[day]) {
					usageCountObj[day] = 0;
					usagePercentObj[day] = 0;
					usageValueObj[day] = 0;
				}

				if (deviceData[day]) {
					usageCountObj[day]++;
					usagePercentObj[day] += deviceData[day];
					usageValueObj[day] += deviceData[day] * this.maxValue / 100;
				}
			}
		}

		if (this.showUsageValue) {
			// show the value of usage
			const maxUsage = this.maxValue * devicesCount;
			for (const day in usageValueObj) {
				usageData.push([new Date(+day *1000), usageValueObj[day], usageValueObj[day], maxUsage  - usageValueObj[day], (maxUsage - usageValueObj[day]) || null]);
			}

			this.usedDevicesNumberChart.config.vAxis.viewWindow = {
				min: 0,
				max: maxUsage
			};
		} else {
			// show the count of devices that used
			for (const day in usageCountObj) {
				usageData.push([new Date(+day *1000), usageCountObj[day], usageCountObj[day], devicesCount - usageCountObj[day], (devicesCount - usageCountObj[day]) || null]);
			}
		}


		for (const day in usagePercentObj) {
			usagePercentageData.push([new Date(+day *1000), usagePercentObj[day] / devicesCount ]);
		}

		if (usageData.length === 0 || usagePercentageData.length === 0)
			return this.showChart = false;

		this.usedDevicesNumberChart.data = usageData;
		this.usagePercentageChart.data = usagePercentageData;
	}

	updateChartTicks(fromDate: number, toDate: number) {
	// if the days count less than 8 days update the chart steps
		if ((toDate - fromDate) < 8 * 24 * 60 * 60 * 1000) {
			this.usedDevicesNumberChart.config.hAxis.ticks = [];
			this.usagePercentageChart.config.hAxis.ticks = [];

			for (let i = fromDate; i <= toDate; i += 24 * 60 * 60 * 1000) {
			this.usedDevicesNumberChart.config.hAxis.ticks.push(new Date(i));
			this.usagePercentageChart.config.hAxis.ticks.push(new Date(i));
			}
		}
	}
}
