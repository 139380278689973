import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService } from '../users.service';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';

@Component({
	selector: 'app-reset-pasword-form',
	templateUrl: './reset-pasword-form.component.html',
	styleUrls: ['./reset-pasword-form.component.css']
})
export class ResetPasswordFormComponent {
	URLparameters: any = {};
	old_password: string = null;
	new_password: string = null;
	confirm_password: string = null;
	isSetPass: boolean = false;

	// for reset_password
	user: {
		new_password: string,
		confirm_password: string,
		id: number,
		enc: string
	} = {
		new_password: '',
		confirm_password: '',
		id: 0,
		enc: ''
	};

	constructor(
		private usersService: UsersService,
		private router: Router,
		private route: ActivatedRoute,
		private notificationMessage: NotificationMessageService
	) {
	}

	ngOnInit(){
		this.user.id = this.route.snapshot.params.userId;
		this.route.queryParams.subscribe(params => {
			this.user.enc = params.enc || '';
			this.isSetPass = params.isSetPass || false;
		});
	}

	ngOnDestroy(){}

	handleChangePasswordResponse(data) {
		if(data.api_status) {
			switch (data.api_status) {
				case 2:
					this.notificationMessage.setMessage('translate|login_register.incorrect_old_password', {clearOnXTimeNavigate: 1});
				break;

				case 3:
					this.notificationMessage.setMessage('translate|users.please_choose_a_new_password', {clearOnXTimeNavigate: 1});
				break;

				case 5:
					this.notificationMessage.setMessage('translate|login_register.already_loggedin');
					this.router.navigate(['/']);
				break;

				case 6:
					this.router.navigate(['/']);
					this.notificationMessage.setMessage('translate|login_register.reset_expired', {type: 'danger'});
				break;

				default:
					this.notificationMessage.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});
				break;
			}
		} else {
			if(this.isSetPass)
				this.notificationMessage.setMessage('translate|g.password_set_successfully', {type: 'success'});
			else
				this.notificationMessage.setMessage('translate|g.password_changed_successfully', {type: 'success'});

			this.usersService.logout().subscribe(() => {
				this.usersService.setCurrentUser({});
				this.router.navigate(['/login']);
			});
		}
	}

	showChangePasswordMsg() {
		let result = false;
		if (this.old_password && this.new_password && this.old_password ==this.new_password)
			result = true;

		// requested by thiker in comments of UPSVIEW-399 ticket;
		if (result && this.confirm_password && this.confirm_password != this.new_password)
			result = false;

		return result;
	}

	changeUserPassword() {
		this.notificationMessage.closeNotification();
		this.user.new_password = this.new_password;
		this.user.confirm_password = this.confirm_password;

		this.usersService.resetPassword(this.user).subscribe(
			(data: any) => {
				this.handleChangePasswordResponse(data);
			}
		);
	}
}
