<div class="white-box main-black mt-4 p-4 border-radius">
	<h4>
		{{'perf_analytic.soc_chart_title' | translate}}
	</h4>

	<!-- time filter -->
	<div class="row py-4">
		<div class="col-sm-6 col-md-7 d-inline-flex">
			<div class="input-group col-6 p-0 mr-3 d-inline-flex">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.start_date' | translate}}</span>
				</div>
				<input class="form-control" [ngClass]="{'text-danger': invalidDateRange}" [minDate]="deviceInstallationDate" [maxDate]="maxDate" placeholder="mm/dd/yyyy" name="startDB" [(ngModel)]="fromDate" (ngModelChange)="DatesChanged()" bsDatepicker #startDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
				<div class="input-group-append pointer" (click)="startDB.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
			<div class="input-group col-6 p-0 mr-3 d-inline-flex">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.end_date' | translate}}</span>
				</div>
				<input class="form-control" placeholder="mm/dd/yyyy" name="endDB" [(ngModel)]="toDate" [minDate]="deviceInstallationDate" [maxDate]="maxDate" (ngModelChange)="DatesChanged()" bsDatepicker #endDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
				<div class="input-group-append pointer" (click)="endDB.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
		</div>
	</div>

	<!-- chart -->
	<sct-charts
		*ngIf="chartObject.data; else noData"
		[data]="chartObject.data"
		[columnNames]="chartObject.columnNames"
		[options]="chartObject.config"
		[formatter]="chartObject.formatter"
		[formatterType]="chartObject.formatterType"
		[title]="('perf_analytic.soc_percent' | translate)"
		[type]="'LineChart'"
		[height]="500"
	></sct-charts>

	<ng-template #noData class="mt-4">
		<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
	</ng-template>
</div>
