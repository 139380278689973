<form #changePasswordForm="ngForm" (ngSubmit)="changeUserPassword()" validate-form="editUserPassword" class="before-login-form">
	<div class="row mt-2 align-items-center">
		<div class="col-md-4">
			<span>{{'users.new_password' | translate}}</span>
		</div>
		<div class="col-md-8">
			<input type="password" class="form-control" name="new_password" [(ngModel)]="new_password" placeholder="{{'users.new_password' | translate}}" required show-password/>
		</div>
	</div>
	<div class="offset-md-4 col-md-8">
		<password-strength [password]="new_password" [stringStyle]="true"></password-strength>
	</div>
	<div class="row mt-2 align-items-center">
		<div class="col-md-4">
			<span>{{'users.confirm_password' | translate}}</span>
		</div>
		<div class="col-md-8">
			<input type="password" class="form-control" name="confirm_password" [(ngModel)]="confirm_password" placeholder="{{'users.confirm_password' | translate}}" required show-password/>
		</div>
	</div>
	<div class="row mt-2 align-items-center">
		<div class="col-md-12">
			<small>{{'password_field.format' | translate}}</small>
		</div>
	</div>
	<div class="row mt-2 align-items-center">
		<div class="col">
			<span class="text-danger" *ngIf="confirm_password && new_password && confirm_password != new_password">* {{'users.passwords_dont_match' | translate}}!<br/><br/></span>
			<span class="text-danger" *ngIf="showChangePasswordMsg()">* {{'users.please_choose_a_new_password' | translate}}!<br/><br/></span>
		</div>
	</div>
	<div class="row mt-4 align-items-center">
		<div class="col-md-8">
			<button
				type="submit"
				class="btn text-white main-blue-bg btn-lg col-md-5 mr-4 green-btn"
				[disabled]="changePasswordForm.invalid || (confirm_password != new_password) || (old_password == new_password && new_password == confirm_password)"
			>
				{{'g.submit' | translate}}
			</button>
			<button
				type="button"
				class="btn text-white main-grey-bg btn-lg col-md-5"
				routerLink="/sites-map"
			>
				{{'g.cancel' | translate}}
			</button>
		</div>
	</div>
</form>
