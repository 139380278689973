import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-horizontal-chart',
	templateUrl: './horizontal-chart.component.html',
	styleUrls: ['./horizontal-chart.component.css']
})
export class HorizontalChartComponent {
	@Input() title: any = {};
	@Input() devices: any = [];
	@Input() data: any = [];
	@Input() vAxisTitle: string = this.translate.instant('g.device');

	showChart = false;

	chart = {
		title: this.translate.instant('g.utilization_percentage'),
		data: [],
		columnNames: [this.translate.instant('g.device'), this.translate.instant('g.usage_percentage'), { role: 'annotation' }],
		config: {
			hAxis: {
				title: this.translate.instant('g.usage_percentage'),
				viewWindow: {
					min: 0,
					max: 100
				}
			},
			vAxis: {
				title: this.translate.instant('g.device'),
			},
			colors: ['red'],
			legend: 'none',
			titleTextStyle: {
				fontSize: 16,
				bold: true,
				color: '#333',
				paddingBottom: 20,
				paddingTop: 20
			},
			bar: {
				groupWidth: '20%'
			},
			annotations: {
				textStyle: {
					fontSize: 14,
					color: 'black'
				}
			},
		}
	}

	constructor(private translate: TranslateService) { }

	ngOnInit() {
		this.chart.title = this.title;
		this.chart.config.vAxis.title = this.vAxisTitle;
		this.formatSelectedData();
	}

	ngOnChanges() {
		this.formatSelectedData();
	}

	formatSelectedData() {
		if (!this.data || !Object.keys(this.devices).length)
			return;

		this.showChart = true;
		const data = [];

		for (const mac in this.data) {
			const name = this.devices[mac]?.truck_id || this.devices[mac]?.serial_number;
			if (!name || !this.data[mac])
				continue;

			data.push([name, this.data[mac], (this.data[mac] || 0).toFixed(2) + '%']);
		}

		this.chart.data = data;
	}
}
