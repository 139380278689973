import { Component, Input } from '@angular/core';
import { SiteWidget } from '../widget.interface';
import { TranslateService } from '@ngx-translate/core';
import { BattlinkSitePerformanceService } from '../battlink-site-performance.service';
import * as _ from 'underscore';
import { TableData } from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';


@Component({
	selector: 'app-ebu-daily-usage-summary',
	templateUrl: './ebu-daily-usage-summary.component.html',
})
export class EbuDailyUsageSummaryComponent {
	@Input() siteId: number;
	@Input() customerId: number;
	@Input() devices: any = {};
	@Input() deviceTags: any;
	@Input() tags: any;
	@Input() data: any = {};

	widgetConfig: SiteWidget = {
		data: [],
		tableData: {},
		scale: 1,
		config: {
			hAxis: { title: this.translateService.instant('site_performance.daily_ebus_usage') },
			vAxis: { title: this.translateService.instant('site_performance.batteries_percent'), format: "0" },
			legend: "none",
			colors: ['#afd7e6', '#b4dae8', '#c2acff', '#b7acd9', '#b7acd9', '#b7acd9', '#bfb6dd' ],
			isStacked: true
		},
		columnNames: [ "Type", "" , { role: "style", type: "string" }],
		type: "ColumnChart",
		title: this.translateService.instant('site_performance.ebu_daily_usage_summary'),
		tooltip: "",
		widgetId: "battery_events_ebu_usage_summary",
		dataRanges: [ "last_7_days", "last_30_days", "last_90_days", "yesterday", "last_week" ],
		selectedDateRange: "yesterday",
		filterByTag: true,
		selectedTags: [],
		filterByType: true,
		selectedType: "all",
		hideWidget: true,
		stackColumn: "truck_type",
		groups: [
			["<0.5", 0.5],
			["0.75", 0.875],
			["1", 1.125],
			["1.25", 1.375],
			["1.5", 1.625],
			["1.75", 1.875],
			["2", 2],
		],
		formateWidgetData: this.formateData,
		tableConfig:{
			columns: [
				{ key: 'batteryId', name: this.translateService.instant('device_performance.battery_id'), type: 'link' },
				{ key: 'batterySn', name: this.translateService.instant('device_performance.battery_sn'), type: 'string' },
				{ key: 'averageEbu', name: this.translateService.instant('device_performance.average_ebu'), type: 'number' },
				{ key: 'maxEbus', name: this.translateService.instant('device_performance.max_ebus'), type: 'number' }
			],
			gridTableColumns:
			[
				{ headerName: this.translateService.instant('device_performance.battery_id'), field: "batteryId", width: 400},
				{ headerName: this.translateService.instant('device_performance.battery_sn'), field: "batterySn", width: 800},
				{ headerName: this.translateService.instant('device_performance.average_ebu'), field: "averageEbu", width: 800},
				{ headerName: this.translateService.instant('device_performance.max_ebus'), field: "maxEbus", width: 400},
			],
			dataKeys: ['batteryId', 'batterySn', 'averageEbu', 'maxEbus'],
			sortBy: 'appearName',
			exportFileName: this.translateService.instant('site_performance.ebu_daily_usage_summary'),
			sizeColumnsToFit: true
		},
		dropDownGroupList: [
			{
				name: 'date_range',
				label: '',
				options: [
					{label: 'Yesterday', value: 'yesterday'},
					{label: 'Last Week', value: 'last_week'},
					{label: 'Last 7 days', value: 'last_7_days'},
					{label: 'Last 30 days', value: 'last_30_days'},
					{label: 'Last 90 days', value: 'last_90_days'},
				],
			},
			{
				name: 'truck_type',
				label: 'Truck Type',
				options: [
					{label: 'All Types', value: 'all'},
				]
			},
			{
				name: 'tag',
				label: 'Tags',
				options: [
					{label: 'All Tags', value: 'all'},
				]
			},
		],
		selectedDropDown: {date_range: 'yesterday', truck_type: 'all', tag: 'all'},
	};

	tableItems: any[] = [];
	chartTableConfig: any = {data: [], column: []};
	tableColumns: any[] = [];
	exportFileName: string = "";
	sizeColumnsToFit: boolean = true;
	noDataForCurrentFilters: boolean = false;

	constructor(
		private translateService: TranslateService,
		private battlinkSitePerformanceService: BattlinkSitePerformanceService,
	) {
		this.formateData = this.formateData.bind(this);
	}

	ngOnInit() {
		this.updateWidgetData();
		this.battlinkSitePerformanceService.updateTagsList(this.widgetConfig, this.tags);
		this.battlinkSitePerformanceService.updateTypesListForWidget(this.widgetConfig);
	}

	formateData(widget: SiteWidget, data: any, devices: any, defaultCountOfDays: number, availableDaysByDevices: any): any {
		const returnData = {};
		for(let macAddress in data) {
			if(!devices[macAddress])
				continue;

			let countOfDays = defaultCountOfDays;
			returnData[macAddress] = {};
			let avg = data[macAddress][0];

			if(availableDaysByDevices[macAddress])
				countOfDays = availableDaysByDevices[macAddress];

			returnData[macAddress].avg = avg;
			returnData[macAddress].serial_number = devices[macAddress].serial_number;
			returnData[macAddress].countOfDays = countOfDays;
			returnData[macAddress].max = data[macAddress][1];
		}

		return returnData;
	}

	onSelect(event) {
		if(!event)
			return;

		let selectedIndex = event.row;
		let selectedDevicesInfo = this.widgetConfig.tableData[selectedIndex];
		this.fillGridTableData(selectedDevicesInfo);
		this.prepareWidgetModalTable();
	}

	prepareWidgetModalTable() {
		const config = this.widgetConfig.tableConfig;
		this.tableColumns = config.gridTableColumns;
		this.exportFileName = config.exportFileName;
		this.sizeColumnsToFit = config.sizeColumnsToFit;

		if (config) {
			this.chartTableConfig.column = config.columns;
			this.chartTableConfig.data = this.tableItems.map((row) => {
				const rowData: TableData = {
					batterySn: { value: row.batterySn },
					batteryId: { value: row.batteryId },
					averageEbu: { value: row.averageEbu || "0" },
					maxEbus: { value: row.maxEbus || "0" },
				};

				return rowData;
			});
		}
	}

	fillGridTableData(selectedDevicesInfo, ) {
		let tableItems = [];

		for(let item of selectedDevicesInfo) {
			if(!this.devices[item.mac])
				continue;

			let temp = {
				batterySn: item.serial_number,
				batteryId: item.truck_id,
				averageEbu: item.avgValue || 0,
				maxEbus: item.maxValue || 0
			};
			tableItems.push(temp);
		}
		if(this.widgetConfig.tableConfig.sortBy)
			tableItems = _.sortBy(tableItems, this.widgetConfig.tableConfig.sortBy).reverse();

		this.tableItems = tableItems;
	}

	onFilter(filter) {
		this.battlinkSitePerformanceService.updateFilter(this.widgetConfig, filter);
		this.updateWidgetData();
	}

	updateWidgetData() {
		this.battlinkSitePerformanceService.generateWidgetData(this.widgetConfig, this.data, this.devices, this.deviceTags);
		this.noDataForCurrentFilters = this.widgetConfig.data.length === 0;
	}
}
