import { Component, Input } from '@angular/core';
import { DeviceFactory } from '../../site-dashboard/device/device-factory/device-factory';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-energy-utilization-charts',
	templateUrl: './energy-utilization-charts.component.html',
	styleUrls: ['./energy-utilization-charts.component.css']
})
export class EnergyUtilizationChartsComponent {
	@Input() deviceType?: number = DeviceFactory.types.iotah;

	@Input() kwhQuarterlyData: any = [];
	@Input() kwhMonthlyData: any = [];

	@Input() ahQuarterlyData: any = [];
	@Input() ahMonthlyData: any = [];

	kwhQuarterChartObject = {
		config:  {
			legend: 'none',
			bar: {
				groupWidth: 30
			},
			vAxis: {
				minValue: 0
			},
			tooltip: {isHtml: true},
		},
		columnNames: ['Quarter', 'Utilization'],
		hasData: false,
		title: ''
	}

	kwhMonthlyChartObject = {
		config:  {
			legend: 'none',
			bar: {
				groupWidth: 30
			},
			vAxis: {
				minValue: 0
			},
			tooltip: {isHtml: true},
		},
		columnNames: ['Quarter', 'Utilization'],
		hasData: false,
		title: ''
	}

	ahQuarterChartObject = {
		config:  {
			legend: 'none',
			bar: {
				groupWidth: 30
			},
			vAxis: {
				minValue: 0
			},
			tooltip: {isHtml: true},
		},
		columnNames: ['Quarter', 'Utilization'],
		hasData: false,
		title: ''
	}

	ahMonthlyChartObject = {
		config:  {
			legend: 'none',
			bar: {
				groupWidth: 30
			},
			vAxis: {
				minValue: 0
			},
			tooltip: {isHtml: true},
		},
		columnNames: ['Quarter', 'Utilization'],
		hasData: false,
		title: ''
	}

	periodFilters = [
		{ id: 'quarterly', durations: this.translateService.instant('g.quarterly') },
		{ id: 'monthly', durations: this.translateService.instant('g.monthly') }
	];
	selectedPeriod = 'quarterly';

	selectedYear = moment().utc().get('year').toString();
	yearsList = [];
	deviceConfig = DeviceFactory.createDevice(this.deviceType).config;

	constructor(
		private translateService: TranslateService
	) { }

	ngOnInit() {
		this.deviceConfig = DeviceFactory.createDevice(this.deviceType).config;

		if (this.kwhQuarterlyData[this.selectedYear]?.length > 0)
			this.kwhQuarterChartObject.hasData = true;

		if (this.kwhMonthlyData[this.selectedYear]?.length > 0)
			this.kwhMonthlyChartObject.hasData = true;

		if (this.ahQuarterlyData[this.selectedYear]?.length > 0)
			this.ahQuarterChartObject.hasData = true;

		if (this.ahMonthlyData[this.selectedYear]?.length > 0)
			this.ahMonthlyChartObject.hasData = true;

		this.prepareYearsList();
		this.updateTitles();
	}

	prepareYearsList() {
		if (this.selectedPeriod === 'quarterly')
			this.yearsList = Object.keys(this.kwhQuarterlyData).filter(year => year != 'all').map((year) => ({ year, id: year })).reverse();
		else
			this.yearsList = Object.keys(this.kwhMonthlyData).filter(year => year != 'all').map((year) => ({ year, id: year })).reverse();

		this.yearsList.unshift({ year: this.translateService.instant("durations.all_years"), id: 'all' });
	}

	updateTitles() {
		const translation = {
			[DeviceFactory.types.charglink]: {
				device: 'Chargers',
				action: 'Charge'
			},
			[DeviceFactory.types.battlink]: {
				device: 'Batteries',
				action: 'Discharge'
			},
		}

		this.kwhQuarterChartObject.title = `${translation[this.deviceType].device} ${this.translateService.instant('g.quarterly')} kWh of ${translation[this.deviceType].action}`;
		this.kwhMonthlyChartObject.title = `${translation[this.deviceType].device} ${this.translateService.instant('g.monthly')} kWh of ${translation[this.deviceType].action}`;

		this.ahQuarterChartObject.title = `${translation[this.deviceType].device} ${this.translateService.instant('g.quarterly')} Ah of ${translation[this.deviceType].action}`;
		this.ahMonthlyChartObject.title = `${translation[this.deviceType].device} ${this.translateService.instant('g.monthly')} Ah of ${translation[this.deviceType].action}`;
	}
}
