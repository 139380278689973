import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DomainsService } from 'src/app/shared/services/domains.service';
import { CaptchaComponent } from 'src/app/users/captcha/captcha.component';
import { Router } from '@angular/router';

@Component({
	selector: 'app-before-login-layout',
	templateUrl: './before-login-layout.component.html',
	styleUrls: ['./before-login-layout.component.scss']
})
export class BeforeLoginLayoutComponent {
	@ViewChild('captchaComponent', { static: false }) captchaComponent: CaptchaComponent;
	page = 'login';

	pagesTitles = {
		login: 'login_register.login_into',
		register: 'login_register.sign_up',
		'forgot-password': 'login_register.forgot_password',
		'reset-password': 'login_register.reset_password',
		contact: 'contact_us.title',
	}

	pagesSubTitles = {
		login: this.translateService.instant('login_register.access_real_time'),
		register: this.translateService.instant('login_register.sign_up_message'),
		'forgot-password': this.translateService.instant('login_register.forgot_password_message'),
		'reset-password': '',
		contact: this.translateService.instant('contact_us.subtitle'),
	}

	isValidEmail = false;
	showCaptcha: boolean = false;
	copyrightYear = new Date().getFullYear();

	googlePlayAppLink: string = this.domainsService.mobileAppLinks.google_play.iotah;
	appStoreAppLink: string = this.domainsService.mobileAppLinks.app_store.iotah;
	siteName: string = this.translateService.instant('g.smart_telemetrics');
	imageClass: string = 'smart_telemetrics';

	constructor(
		private router: Router,
		private translateService: TranslateService,
		private domainsService: DomainsService,
	) {
	}

	yearAge = new Date().getFullYear();
	loginLogo: string = '';

	ngOnInit() {
		this.updatePage();
		this.router.events.subscribe(() => {
			this.updatePage();
		});

		this.updateLoginImage();
		this.pagesSubTitles.contact = `${this.pagesSubTitles.contact} ${this.siteName}.`;
		this.pagesSubTitles.register = `${this.pagesSubTitles.register} ${this.siteName}.`;

		const domainLinks = this.domainsService.getAppLinks();

		this.googlePlayAppLink = domainLinks.google_play;
		this.appStoreAppLink = domainLinks.app_store;
	}

	updateLoginImage() {
		if(this.domainsService.isChargLink) {
			this.imageClass = 'charglink';
			this.siteName = this.translateService.instant('g.charglink');
			this.loginLogo = '/images/chargLink/charglink-logo.png';
			return
		}

		if(this.domainsService.isBattlink) {
			this.imageClass = 'battlink';
			this.siteName = this.translateService.instant('g.battlink');
			this.loginLogo = '/images/battLink/battlink-logo.png';
			return
		}

		if(this.domainsService.isIotah) {
			this.imageClass = 'iotah';
			this.siteName = this.translateService.instant('g.iotah');
			this.loginLogo = '/images/logo-v1.png';
			return
		}

		this.imageClass = 'smart_telemetrics';
		this.siteName = this.translateService.instant('g.smart_telemetrics');
		this.loginLogo = '/images/smartTelemetrics/logo.png';
		return;
	}

	updatePage() {
		this.page = this.router.url.split('/')[1] || 'login';
	}
}
