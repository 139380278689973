<div class="bg-white">
	<nav class="navbar-expand-lg analytics-nav py-2">
		<div class="navbar-collapse">
			<ul class="navbar-nav mr-auto">
				<li class="nav-item" *ngIf="true" [ngClass]="{'active': isActiveTab('site-history')}">
					<a class="nav-link py-1 pointer" [routerLink]="['/', customerId, siteId, 'charglink-brm', 'site-history']" [queryParams]="{ pageName: 'site-history' }" (click)="commonService.handleLinkClickEvents($event)">{{'nav.site_history' | translate}}</a>
				</li>
				<li class="nav-item" *ngIf="true" [ngClass]="{'active': isActiveTab('brm-groups')}">
					<a class="nav-link py-1 pointer" [routerLink]="['/', customerId, siteId, 'charglink-brm', 'brm-groups']" [queryParams]="{ pageName: 'brm-groups' }" (click)="commonService.handleLinkClickEvents($event)">{{'nav.brm_groups' | translate}}</a>
				</li>
			</ul>
		</div>
	</nav>
</div>

<div [ngSwitch]="activePage">
	<app-charglink-brm-site-history *ngSwitchCase="'site-history'" [customerId]="customerId" [siteId]="siteId"></app-charglink-brm-site-history>
	<app-brm-groups *ngSwitchCase="'brm-groups'" [siteId]="siteId"></app-brm-groups>
</div>
