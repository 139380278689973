import { PipeTransform, Pipe } from '@angular/core';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';

@Pipe({name: 'device_translation'})
export class DeviceTranslationPipe implements PipeTransform {

	constructor(private deviceService: DeviceService) {};

	transform(value, args: number) : any {
		return this.deviceService.translate(value, args);
	}
}
