import { CommonDataService } from './shared/services/common-data.service';
import { NotificationMessageService } from './shared/notification-message/notification-message.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LeftPanelService } from './shared/left-panel/left-panel.service';
import { CustomHttpParams } from './shared/loader/custom-httpParam';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { filter } from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { CommonService } from './shared/services/common.service';
import { UsersService } from './users/users.service';
import { DomainsService } from './shared/services/domains.service';
import { SideMenuService } from './shared/side-menu/side-menu.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {
	beforeLoginPages = ['/register', '/forgot-password', '/login', '/reset-password', '/change-expired-password'];
	beforeAndAfterLoginPages = ['change-user-password', '/contact-us'];
	isPublicPage = false;
	versionKey: string = 'current-version';
	showBanner: boolean = true;
	selectedLang = 'en';

	sub1: Subscription = new Subscription();
	sub2: Subscription = new Subscription();
	sub3: Subscription = new Subscription();

	constructor(
		public translate: TranslateService,
		public leftPanelService: LeftPanelService,
		private router: Router,
		private httpClient: HttpClient,
		private cookieService: CookieService,
		public commonDataService: CommonDataService,
		private notificationMessageService: NotificationMessageService,
		private titleService: Title,
		private commonService: CommonService,
		private usersService: UsersService,
		public domainsService: DomainsService,
		private sideMenuService: SideMenuService
	) {
		localStorage.removeItem(this.versionKey)

		let is_cookie_policy_confirmed = this.cookieService.get("cookiePolicy");
		this.showBanner = !is_cookie_policy_confirmed;

		translate.addLangs(['en']);
		// this language will be used as a fallback when a translation isn't found in the current language
		translate.setDefaultLang('en');

		this.sub1 = router.events.pipe(
			filter(event => event instanceof NavigationStart)
		).subscribe(() => {
			let ver = localStorage.getItem(this.versionKey);

			const notificationMessage = this.notificationMessageService.messageSubject.value;
			if (notificationMessage) {
				if (notificationMessage.numberOfNavigate === notificationMessage.clearOnXTimeNavigate - 1)
					this.notificationMessageService.closeNotification();
				else
					notificationMessage.numberOfNavigate++;
			}

			this.sub2 = this.httpClient.post('/user/updateCheck', {ver}, {
				observe: "body",
				params: new CustomHttpParams({noUIBlock: true})
			}).subscribe((response: any) => {

				if(response.newVersion) {

					localStorage.setItem(this.versionKey, response.newVersion);

					if(ver)
						this.commonDataService.showRefreshMessage = true;
				}
			});
		});

	}

	ngOnInit() {
		this.sub3 = this.httpClient.get('/user/getGitData', {
			observe: "body"
		}).subscribe((response: any) => {
			console.log(response);
			if(Object.keys(response).length > 0)
				console.log("%c"+JSON.stringify(response), "color: red; font-size: x-large");
		});

		// Check if the current page is a public page and update the background color
		this.router.events.subscribe(event => {
			if (this.commonService.isTabletOrMobileScreen)
				this.sideMenuService.setHideMenuView(true);

			const currentUser = this.usersService.getCurrentUser();
			if (event instanceof NavigationEnd) {
				this.isPublicPage = false
				for(const page of this.beforeLoginPages) {
					if (event.urlAfterRedirects.includes(page))
						this.isPublicPage = true;
				}
				for(const page of this.beforeAndAfterLoginPages) {
					if (event.urlAfterRedirects.includes(page) && !currentUser.id)
						this.isPublicPage = true;
				}

				if (this.isPublicPage)
					this.leftPanelService.hide();
				else
					this.leftPanelService.show();
			}
		});

		this.domainsService.hasDefinedDomainObservable.subscribe((hasDomain) => {
			if (hasDomain)
				this.handleTitleAndFavicon();
		});
	}

	closeBanner() {
		let exprireDate = new Date();
		exprireDate.setFullYear(exprireDate.getFullYear() + 10);
		this.cookieService.set('cookiePolicy', '1', exprireDate);
		this.showBanner = false;
	}

	ngOnDestroy() {
		this.sub1.unsubscribe();
		this.sub2.unsubscribe();
		this.sub3.unsubscribe();
	}

	handleTitleAndFavicon() {
		const favIcon: HTMLLinkElement = document.querySelector('#favIcon');
		favIcon.href = '/sctportal/images/smartTelemetrics/favicon.ico';
		this.titleService.setTitle(this.translate.instant('g.smart_telemetrics'));

		if(this.domainsService.isChargLink) {
			this.titleService.setTitle(this.translate.instant('g.charglink'));
			favIcon.href = '/sctportal/images/chargLink/favicon.ico';
		}

		if(this.domainsService.isIotah) {
			this.titleService.setTitle(this.translate.instant('g.iotah'));
			favIcon.href = '/sctportal/images/iotah/favicon.ico';
		}

		if(this.domainsService.isBattlink) {
			this.titleService.setTitle(this.translate.instant('g.battlink'));
			favIcon.href = '/sctportal/images/battLink/favicon.ico';
		}
	}
}
