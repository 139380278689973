<div>
	<h3 class="mb-4 font-weight-bold">
		<a class="main-blue" [routerLink]="['/', customerId]">{{customer?.customer_name}}</a>
		<span *ngIf="customer?.customer_name && site?.name" class="light-grey"> - {{site?.name}}</span>
	</h3>

	<div>
		<nav class="navbar navbar-expand-lg border-radius-top navbar-light bg-white topnav pt-2 pb-0 px-4 nav-border main-black">
			<ul class="navbar-nav mr-auto card-container" #cardContainer>
				<li class="nav-item">
					<a class="nav-link p-3 light-grey small" [ngClass]="{'active': siteDashboardService.activePage == 'site-info'}" [routerLink]="['/', customerId, siteId]"><i class="fa fa-info-circle fa-lg"></i><b> {{'g.site_info' | translate}}</b></a>
				</li>

				<!-- old site performance -->
				<li class="nav-item" *ngIf="site_has_iotah_devices && !useShowCharts">
					<a class="nav-link p-3 light-grey small" [ngClass]="{'active': siteDashboardService.activePage == 'site-performance'}" [routerLink]="['/', customerId, siteId, 'performance']" (click)="siteDashboardService.setActiveTab('site-performance')"><i class="fa fa-bar-chart fa-lg"></i><b> {{'g.site_performance' | translate}}</b></a>
				</li>
				<li class="nav-item" *ngIf="site_has_chargLink_devices && !useShowCharts">
					<a class="nav-link p-3 light-grey small" [ngClass]="{'active': siteDashboardService.activePage == 'site-chargers-performance'}" [routerLink]="['/', customerId, siteId, 'chargers-performance']" (click)="siteDashboardService.setActiveTab('site-chargers-performance')"><i class="fa fa-bar-chart fa-lg"></i><b> {{'g.chargers_performance' | translate}}</b></a>
				</li>

				<!-- new site performance -->
				<li class="nav-item" *ngIf="site_has_iotah_devices && useShowCharts">
					<a class="nav-link p-3 light-grey small" [ngClass]="{'active': siteDashboardService.activePage == 'site-utilization-iotah'}" [routerLink]="['/', customerId, siteId, 'site-utilization-iotah']" (click)="siteDashboardService.setActiveTab('site-utilization-iotah')"><i class="fa fa-bar-chart fa-lg"></i><b> {{'g.iotah_site_utilization' | translate}}</b></a>
				</li>
				<li class="nav-item" *ngIf="site_has_chargLink_devices && useShowCharts">
					<a class="nav-link p-3 light-grey small" [ngClass]="{'active': siteDashboardService.activePage == 'site-utilization-charglink'}" [routerLink]="['/', customerId, siteId, 'site-utilization-charglink']" (click)="siteDashboardService.setActiveTab('site-utilization-charglink')"><i class="fa fa-bar-chart fa-lg"></i><b> {{'g.charglink_site_utilization' | translate}}</b></a>
				</li>
				<li class="nav-item" *ngIf="site_has_battLink_devices && useShowCharts && isShowCustomer">
					<a class="nav-link p-3 light-grey small" [ngClass]="{'active': siteDashboardService.activePage == 'site-utilization-battlink'}" [routerLink]="['/', customerId, siteId, 'site-utilization-battlink']" (click)="siteDashboardService.setActiveTab('site-utilization-battlink')"><i class="fa fa-bar-chart fa-lg"></i><b> {{'g.battlink_site_utilization' | translate}}</b></a>
				</li>

				<li class="nav-item">
					<a class="nav-link p-3 light-grey small" [ngClass]="{'active': siteDashboardService.activePage == 'devices-listing'}" [routerLink]="['/', customerId, siteId, 'devices-listing']" (click)="siteDashboardService.setActiveTab('iotah_devices')"><i class="fa fa-tachometer fa-lg"></i><b> {{'site.iotah_devices_tabs' | translate}}</b></a>
				</li>
				<li class="nav-item">
					<a class="nav-link p-3 light-grey small" [ngClass]="{'active': siteDashboardService.activePage == 'device-dash'}" [routerLink]="['/', customerId, siteId, 0]" (click)="siteDashboardService.setActiveTab('analytics_settings')"><i class="fa fa-cogs fa-lg"></i><b> {{'nav.analytics_settings' | translate}}</b></a>
				</li>
				<li class="nav-item">
					<a class="nav-link p-3 light-grey small" [ngClass]="{'active': siteDashboardService.activePage == 'site-notes'}" [routerLink]="['/', customerId, siteId, 'site-notes']" (click)="siteDashboardService.setActiveTab('site-notes')"><i class="fa fa-sticky-note-o fa-lg"></i><b> {{'note.site_notes' | translate}}</b></a>
				</li>
				<li class="nav-item">
					<a class="nav-link p-3 light-grey small" [ngClass]="{'active': siteDashboardService.activePage == 'site-attachments'}" [routerLink]="['/', customerId, siteId, 'site-attachments']" (click)="siteDashboardService.setActiveTab('site-attachments')"><i class="fa fa-paperclip fa-lg"></i><b> {{'attachments.site' | translate}}</b></a>
				</li>
				<li class="nav-item" *ngIf="usersService.hasAccessFunction('show_warnings')">
					<a class="nav-link p-3 light-grey small" [ngClass]="{'active': siteDashboardService.activePage == 'site-warnings'}" [routerLink]="['/', customerId, siteId, 'site-warnings']" (click)="siteDashboardService.setActiveTab('site-warnings')"><i class="fa fa-exclamation-triangle fa-lg"></i><b> {{'nav.warnings' | translate}}</b></a>
				</li>
				<li class="nav-item">
					<a class="nav-link p-3 light-grey small" [ngClass]="{'active': siteDashboardService.activePage == 'tags-management'}" [routerLink]="['/', customerId, siteId, 'tags']" (click)="siteDashboardService.setActiveTab('tags-management')"><i class="fa fa-tags fa-lg"></i><b> {{'nav.manage_tags' | translate}}</b></a>
				</li>
				<li class="nav-item" *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'basic_setup') && site_has_iotah_devices">
					<a class="nav-link p-3 light-grey small" [ngClass]="{'active': siteDashboardService.activePage == 'maintenance-report'}" [routerLink]="['/', customerId, siteId, 'maintenance-report']" (click)="siteDashboardService.setActiveTab('maintenance-report')"><i class="fa fa-medkit fa-lg"></i><b> {{'nav.maintenance_report' | translate}}</b></a>
				</li>
				<li class="nav-item" *ngIf="usersService.userHasNOCAccess(enterprisePermissions)">
					<a class="nav-link p-3 light-grey small" [ngClass]="{'active': siteDashboardService.activePage == 'site-warranty'}" [routerLink]="['/', customerId, siteId, 'site-warranty']" (click)="siteDashboardService.setActiveTab('site-warranty')"><i class="fa fa-check-square-o fa-lg"></i><b> {{'nav.warranty' | translate}}</b></a>
				</li>
				<li class="nav-item" *ngIf="site_has_brm_devices">
					<a class="nav-link p-3 light-grey small" [ngClass]="{'active': siteDashboardService.activePage == 'charglink-brm'}" [routerLink]="brmLink" [queryParams]="{siteId}" (click)="siteDashboardService.setActiveTab('charglink-brm'); this.enableBuzzer()"><i class="fa fa-check-square-o fa-lg"></i><b> {{'nav.charglink_brm' | translate}}</b></a>
				</li>
				<li class="nav-item" *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'basic_setup', 'write')">
					<a class="nav-link p-3 light-grey small" [ngClass]="{'active': siteDashboardService.activePage == 'working-days'}" [routerLink]="['/', customerId, siteId, 'working-days']" (click)="siteDashboardService.setActiveTab('working-days')"><i class="fa fa-calendar-check-o fa-lg"></i><b> {{'nav.site_settings' | translate}}</b></a>
				</li>
				<li class="nav-item" *ngIf="hasStudies && this.commonService.isShowPowerStudyDomain()">
					<a class="nav-link p-3 light-grey small" [ngClass]="{'active': siteDashboardService.activePage == 'site-studies'}" [routerLink]="['/', customerId, siteId, 'site-studies']" (click)="siteDashboardService.setActiveTab('site-studies')"><i class="fa fa-newspaper-o fa-lg"></i><b> {{'studies.power_studies' | translate}}</b></a>
				</li>
			</ul>
		</nav>
		<router-outlet></router-outlet>
	</div>
</div>
