import { CommonService } from './../../shared/services/common.service';
import { UsersService } from './../../users/users.service';
import { Component, OnInit, ViewChild } from '@angular/core';

import * as _ from 'underscore';

import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { ColumnsConfig, TableConfig, TableData} from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';
import { TranslateService } from '@ngx-translate/core';
import { ModalComponent } from 'src/app/shared/modal/modal.component';

@Component({
	selector: 'app-blocked-fw-reports',
	templateUrl: './blocked-fw-reports.component.html',
	styleUrls: ['./blocked-fw-reports.component.css']
})
export class BlockedFwReportsComponent implements OnInit {
	@ViewChild("unblockConfirmationPopup") unblockConfirmationPopup: ModalComponent;

	// table data
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: true,
		fileName: this.translateService.instant('nav.blocked_fw_reports'),
		hasPagination: true,
		pageSize: 100,
		hasSelectionColumn: true,
		hasActionButtons: true,
		actionButtonsList: [{text: this.translateService.instant('g.unblock'), action: ()=>{this.showUnBlockConfirmation()}}]
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'id', name: 'id', type: 'id', hidden: true },
		{ key: 'customer_name', name: this.translateService.instant('g.customer_name'), type: 'link'},
		{ key: 'site_name', name: this.translateService.instant('g.site_name'), type: "link"},
		{ key: 'mac_address', name: this.translateService.instant('g.mac_address'), type: "link"},
		{ key: 'serial_number', name: this.translateService.instant('devices.serial_number'), type: "string"},
		{ key: 'fw_version', name: this.translateService.instant('devices.fw_version'), type: "number"},
		{ key: 'blocked_until', name: this.translateService.instant('devices.blocked_until'), type: "timestamp"},
		{ key: 'reason', name: this.translateService.instant('g.reason'), type: "string"},
		{ key: 'action', name: this.translateService.instant('g.action'), type: "dropdown"},
	];

	reportData: any = {};
	isAdmin = false;
	selectedDevices: {mac_address: string, version: string, serial_number: string}[] = [];
	selectedDevicesLimit = 20;

	constructor(
		private deviceService: DeviceService,
		private notificationMessageService: NotificationMessageService,
		private sideMenuService: SideMenuService,
		private userService: UsersService,
		public common: CommonService,
		private translateService: TranslateService
	) { }

	ngOnInit() {
		if (this.common.isDevelopmentDomain())
			this.selectedDevicesLimit = 3;

		this.sideMenuService.hide();
		this.isAdmin = this.userService.getCurrentUser().is_admin;
		this.getBlockedFWReport();
	}

	ngAfterViewInit() {
		this.unblockConfirmationPopup.onClose.subscribe((ok) => {
			if(!ok)
				return;

			this.unBlock();
		});
	}

	getBlockedFWReport() {
		const tableData = [];
		this.deviceService.getBlockedFWReport().subscribe(
			(data: any) => {
				let filteredDevices = {};
				data.devices.forEach((device) => {
					if(!device.is_deleted) {
						filteredDevices[device.mac_address] = device;
					}
				});
				const devices = this.filterData(data, filteredDevices);

				for(const device of devices) {

					tableData.push({
						id: {value: device.mac_address},
						customer_name: {value: device.customer_name == 'customer_deleted'? this.translateService.instant('g.customer_deleted') : device.customer_name, link:  device.customer_name == 'customer_deleted'? null : ['/', device.customer_id]},
						site_name: {value: device.site_name == 'site_deleted' ? this.translateService.instant('g.site_deleted') :  device.site_name, link: device.site_name == 'site_deleted' ? null : ['/', device.customer_id, device.site_id]},
						mac_address: {value: device.mac_address, link: ['/', device.customer_id, device.site_id, device.mac_address, 'settings']},
						serial_number: {value: device.serial_number},
						fw_version: {value: this.common.toFixedWithoutRounding(device.fw_version || 0, this.isAdmin ? 4 : 2)},
						versionString: device.fw_version,
						blocked_until: {value: +device.blocked_until * 1000},
						reason: {value: this.translateService.instant('blocked_fw_reasons.'+(device.reason || 'na'))},
						action: {value: null, options: [{
							text: this.translateService.instant('g.unblock'),
							action: ()=>{
								this.selectedDevices = [{mac_address: device.mac_address, version: device.fw_version, serial_number: device.serial_number}]
								this.showUnBlockConfirmation();
							}}]
						},
					})
				}

				this.tableData = tableData;
			}
		);
	}

	filterData(data, devices) {
		let devicesIDs = _.pluck(devices, 'mac_address');

		let result = [];
		data.data.forEach((item) => {
			if(devicesIDs.includes(item.id)) {
				let device = Object.assign({}, devices[item.id]);
				device.fw_version = item.version;
				device.blocked_until = item.time;
				device.reason = item.reason;
				result.push(device);
			}
		});

		return result;
	}

	unBlock() {
		this.deviceService.unBlockFWReport(this.selectedDevices).subscribe(
			() => {
				// success message then reload
				this.notificationMessageService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
				this.getBlockedFWReport();
			}
		);
	}

	updateSelectedDevices(devices) {
		this.selectedDevices = devices.map((device) =>{
			return {
				mac_address: device.mac_address.value,
				version: device.versionString,
				serial_number: device.serial_number.value
			}
		});
	}

	showUnBlockConfirmation() {
		if (this.selectedDevices.length < this.selectedDevicesLimit)
			return this.unblockConfirmationPopup.show();

		return this.notificationMessageService.setMessage('translate|sim.unblock_sims_limit', {clearOnXTimeNavigate: 1, type: 'warning'});
	}
}
