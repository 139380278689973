<div class="widget-container mb-3" *ngIf="showChart">
	<sct-charts
		class="chart"
		[id]="chart.title"
		[title]="chart.title"
		[data]="chart.data"
		[columnNames]="chart.columnNames"
		[options]="chart.config"
		[type]="'BarChart'"
		[height]="200"
	></sct-charts>
</div>
