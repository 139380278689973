import { Component, Input } from '@angular/core';
import { SiteWidget } from '../widget.interface';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';
import { TableData } from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';
import { BattlinkSitePerformanceService } from '../battlink-site-performance.service';

@Component({
	selector: 'app-battery-remaining-warranty',
	templateUrl: './battery-remaining-warranty.component.html',
})
export class BatteryRemainingWarrantyComponent {
	@Input() siteId: number;
	@Input() customerId: number;
	@Input() devices: any = {};
	@Input() deviceTags: any;
	@Input() tags: any;

	widgetConfig: SiteWidget = {
		data: [],
		tableData: {},
		scale: 1,
		config: {
			legend: { position: 'right', textStyle: { fontSize: 16 }, alignment: 'center'},
			colors: [ "#e92f31", "#5462eb", "#fb902c", "#faa82f", "#830080", "#07941d"],
			isStacked: true
		},
		columnNames: [ "Type", "", { role: "style", type: "string" }],
		type: "PieChart",
		title: this.translateService.instant('perf_analytic.battery_remaining_warranty'),
		tooltip: "",
		widgetId: "battery_remaining_warranty",
		dataRanges: [],
		selectedDateRange: "",
		filterByTag: true,
		selectedTags: [],
		filterByType: true,
		selectedType: "all",
		hideWidget: true,
		stackColumn: "truck_type",
		groups: [
			[this.translateService.instant("remaining_warrenty_levels.less_than_1_year"), 0, 1], // [group name, min value, max value]
			[this.translateService.instant("remaining_warrenty_levels.1_year"), 1, 2],
			[this.translateService.instant("remaining_warrenty_levels.2_year"), 2, 3],
			[this.translateService.instant("remaining_warrenty_levels.3_year"), 3, 4],
			[this.translateService.instant("remaining_warrenty_levels.more_than_4_years"), 4, 10000],
		],
		tableConfig:{
			columns: [
				{ key: 'battery_id', name: this.translateService.instant('device_performance.battery_id'), type: 'link' },
				{ key: 'warranted_ahrs', name: this.translateService.instant('device_performance.warranted_ahrs'), type: 'number' },
				{ key: 'remaining_ahrs', name: this.translateService.instant('device_performance.remaining_ahrs'), type: 'number' },
				{ key: 'warranted_years', name: this.translateService.instant('device_performance.warranted_years'), type: 'number' },
				{ key: 'remaining_years', name: this.translateService.instant('device_performance.remaining_years'), type: 'number' },
			],
			gridTableColumns:
			[
				{ headerName: this.translateService.instant('device_performance.battery_id'), field: "battery_id", width: 800},
				{ headerName: this.translateService.instant('device_performance.warranted_ahrs'), field: "warranted_ahrs", width: 800},
				{ headerName: this.translateService.instant('device_performance.remaining_ahrs'), field: "remaining_ahrs", width: 800},
				{ headerName: this.translateService.instant('device_performance.warranted_years'), field: "warranted_years", width: 800},
				{ headerName: this.translateService.instant('device_performance.remaining_years'), field: "remaining_years", width: 800},
			],
			dataKeys: ['battery_id', 'warranted_ahrs', 'remaining_ahrs', 'warranted_years', 'remaining_years'],
			sortBy: 'battery_id',
			exportFileName: this.translateService.instant('site_performance.site_battery_aging'),
			sizeColumnsToFit: true
		},
		dropDownGroupList: [],
		selectedDropDown: {},
	};

	tableItems: any[] = [];
	chartTableConfig: any = {data: [], column: []};
	tableColumns: any[] = [];
	exportFileName: string = "";
	sizeColumnsToFit: boolean = true;
	noDataForCurrentFilters: boolean = false;
	fastBatteryType = 1;

	constructor(
		private translateService: TranslateService,
		private battlinkSitePerformanceService: BattlinkSitePerformanceService,
	) {}

	ngOnInit() {
		this.updateWidgetData();
	}

	updateWidgetData() {
		const devicesData = [];
		const deviceTableData = {};
		const chartData = [];
		let groupIndex = 0;

		for(let group of this.widgetConfig.groups) {
			deviceTableData[groupIndex] = [];
			chartData.push([group[0], 0, this.widgetConfig.config.colors[groupIndex++]]);
		}

		for(let mac in this.devices) {
			let device = this.devices[mac];
			let deviceData = this.getDeviceData(device);
			devicesData.push(deviceData);
			this.widgetConfig.hideWidget = false;
		}

		const {groupedChartData, groupedTableData, hasData} = this.groupData(devicesData, chartData, deviceTableData);

		this.widgetConfig.data = groupedChartData;
		this.widgetConfig.tableData = groupedTableData;
		this.noDataForCurrentFilters = !hasData;
	}

	getDeviceData(device) {
		const currentDay = new Date();
		const installationDate = new Date(device.installation_date);
		let totalLifeYears = 5;

		if (+(device.charger_type) === this.fastBatteryType) { // if fast
			totalLifeYears = 4;
		}

		let totalAhrs	=  1250 * (+device.battery_capacity); //device.battery_capacity
		let usedAhrs	= +device.inuse_ahr + (+device.add_inuse_ahr);

		const monthsSinceInstallation = this.battlinkSitePerformanceService.getElapsedMonths(installationDate, currentDay);
		var usedLifeYears = parseFloat((monthsSinceInstallation / 12).toFixed(1));

		if (usedLifeYears > totalLifeYears) {
			usedLifeYears = totalLifeYears;
		}

		var remainingLifeAhrs = totalLifeYears * ((totalAhrs - usedAhrs) / totalAhrs);

		 return {
			deviceId: device.mac_address,
			truck_id: device.truck_id,
			remainingLifeAhrs: Math.round(remainingLifeAhrs * 100) / 100,
			remainingLifeYrs: totalLifeYears - usedLifeYears,
			totalLifeYears: totalLifeYears,
			totalAhrs: totalAhrs,
		};
	}

	groupData(devicesData, defaultChartData, defaultTableData) {
		const groupedChartData = [...defaultChartData];
		const groupedTableData = {...defaultTableData};
		let hasData = false;

		for (let device of devicesData) {
			let groupIndex = 0;

			for (let group of this.widgetConfig.groups) {
				const groupMin = group[1];
				const groupMax = group[2];

				if (device.remainingLifeYrs >= groupMin && device.remainingLifeYrs < groupMax) {
					groupedChartData[groupIndex][1]++;
					groupedTableData[groupIndex].push(device);
					hasData = true;
				}

				groupIndex++;
			}
		}

		return {groupedChartData, groupedTableData, hasData};
	}

	onSelect(event) {
		if(!event)
			return;

		let selectedIndex = event.row;
		let selectedDevicesInfo = this.widgetConfig.tableData[selectedIndex];
		this.fillGridTableData(selectedDevicesInfo);
		this.prepareWidgetModalTable();
	}

	prepareWidgetModalTable() {
		const config = this.widgetConfig.tableConfig;
		this.tableColumns = config.gridTableColumns;
		this.exportFileName = config.exportFileName;
		this.sizeColumnsToFit = config.sizeColumnsToFit;

		if (config) {
			this.chartTableConfig.column = config.columns;
			this.chartTableConfig.data = this.tableItems.map((row) => {
				const rowData: TableData = {
					battery_id: {value: row.battery_id },
					warranted_ahrs: {value: row.warranted_ahrs || "0"},
					remaining_ahrs: {value: row.remaining_ahrs || "0"},
					warranted_years: {value: row.warranted_years || "0"},
					remaining_years: {value: row.remaining_years || "0"},
				};

				return rowData;
			});
		}
	}

	fillGridTableData(selectedDevicesInfo) {
		let tableItems = [];

		for(let item of selectedDevicesInfo) {
			if(!this.devices[item.deviceId])
				continue;

			let temp = {
				battery_id: item.truck_id,
				warranted_ahrs: item.totalAhrs || 0,
				remaining_ahrs: item.remainingLifeAhrs || 0,
				warranted_years: item.totalLifeYears || 0,
				remaining_years: item.remainingLifeYrs || 0,
			};
			tableItems.push(temp);
		}
		if(this.widgetConfig.tableConfig.sortBy)
			tableItems = _.sortBy(tableItems, this.widgetConfig.tableConfig.sortBy).reverse();

		this.tableItems = tableItems;
	}
}
