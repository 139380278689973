import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { Router } from '@angular/router';
import { SideMenuService } from './side-menu.service';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import { SitesService } from 'src/app/sites/sites.service';
import { UsersService } from 'src/app/users/users.service';
import { TranslateService } from '@ngx-translate/core';
import * as abbreviations from "../../../../../Common/abbreviations.json";
import { CommonService } from '../services/common.service';

enum SitesToHide {
	EMPTY_SITES= 'Empty Sites',
	SPECIAL_SITES= 'Special Sites',
	TEST_SITES= 'Test Sites',
	EMPTY_ENTERPRISE= 'Zombie Sites',
	SMART_REBATES = 'smart_rebates',
	NON_SR_SITES = 'non_sr_sites'
}

@Component({
	selector: 'app-side-menu',
	templateUrl: './side-menu.component.html',
	styleUrls: ['./side-menu.component.css'],
	animations: [
		trigger(
			'enterAnimation', [
				transition(':enter', [
					style({transform: 'translateX(-100%)'}),
					animate('250ms', style({transform: 'translateX(0)'}))
				]),
				transition(':leave', [
					style({transform: 'translateX(0)'}),
					animate('250ms', style({transform: 'translateX(-100%)'}))
				])
			]
		)
	],
})
export class SideMenuComponent implements OnInit, OnDestroy {
	countries = {canada: 'Canada', usa: 'United States'};
	locationOptions: {
			[key: string]:  {stateList: {name: string, abbreviation: string}[], abbreviation: string}
		} = {
			"Canada": {stateList: [], abbreviation: "CA"},
			"United States": {stateList: [], abbreviation: "USA"}
		};
	filteredLocationOptions: any[] = [];
	selectedLocations: {country: string, state: string}[] = [];
	abbreviations = abbreviations;
	locationFilterString = '';
	showAllOptions = false;

	hideMenuView: boolean = false;
	searchText = '';
	currentCustomer: any = {};
	currentSite: any = {};
	data: any = [];
	customers: any = [];
	ccSubscription: Subscription = new Subscription();
	csSubscription: Subscription = new Subscription();
	expCstrSubscription: Subscription = new Subscription();
	filteredSitesSub: Subscription = new Subscription();
	sideMenuFiltersSub: Subscription = new Subscription();
	locationSub: Subscription = new Subscription();
	clickedSiteId = 0;
	filteredEnterprise: any;
	enterprisesList: any[];
	conditionalSites: any;
	sitesToHide = [];
	hideSites: string[];
	filteredSites: number[] = [];
	currentUser: any = {};
	showZombie: boolean = false;
	smartRebates = [];
	showSmartRebateSites: any;
	devicesTypeList: Array<{ label: string, value: string }> = [
		{ label: this.translateService.instant('g.charglink'), value: this.commonService.DEVICES_TYPE.ChargLink },
		{ label: this.translateService.instant('g.iotah'), value: this.commonService.DEVICES_TYPE.IoTAh },
		{ label: this.translateService.instant('g.iotah_ps'), value: this.commonService.DEVICES_TYPE.IoTAh_ps },
		{ label: this.translateService.instant('g.brm'), value: this.commonService.DEVICES_TYPE.ChargLink_brm },
		{ label: this.translateService.instant('g.battlink'), value: this.commonService.DEVICES_TYPE.Battlink },
	];
	showSitesRelatedDevicesType: string[]=[];
	commonSites = {
		EMPTY_SITES: 'Empty Sites',
		SPECIAL_SITES: 'Special Sites',
		TEST_SITES: 'Test Sites'
	};


	constructor(
		private sideMenuService: SideMenuService,
		private router: Router,
		private cdref: ChangeDetectorRef,
		private sitesService: SitesService,
		public usersService: UsersService,
		private translateService: TranslateService,
		public commonService: CommonService
	) { }

	ngOnInit() {
		this.currentUser = this.usersService.getCurrentUser();
		if (this.currentUser.isSCT) {
			this.showZombie = true;
		}

		if (this.currentUser.is_admin) {
			this.smartRebates.push({ label: this.translateService.instant('g.show_smart_rebate_site'), value: SitesToHide.SMART_REBATES });
			this.smartRebates.push({ label: this.translateService.instant('g.non_smart_rebates_sites'), value: SitesToHide.NON_SR_SITES});
		}

		this.conditionalSites = this.showZombie
			? {...this.commonSites, EMPTY_ENTERPRISE: 'Zombie Sites'}
			: this.commonSites;

		this.sitesToHide = Object.values(this.conditionalSites);
		this.router.routeReuseStrategy.shouldReuseRoute = function(future: any, curr: any) {
			let devicePagesRegExp = /\d+\/\d+\/[0-9a-fA-F]+\//; // :customerId/:siteId/:deviceId
			if(devicePagesRegExp.test(curr._routerState.url) && devicePagesRegExp.test(future._routerState.url)) {
				return true;
			}
			return false;
		}

		this.sitesService.getUserSitesInfo({getDevicesCounts: true, getEnterprises: true}).subscribe((data: any)=> {
			this.data = data.customers;
			this.locationOptions = this.getLocationOptions(data.customers);

			this.data.sort((a, b) => {
				let customerNameA = a.customer_name ? a.customer_name.toLowerCase() : '';
				let customerNameB = b.customer_name ? b.customer_name.toLowerCase() : '';
				return customerNameA < customerNameB ? -1 : customerNameA > customerNameB ? 1 : 0
			});
			this.data.forEach(item=> {
				item.sites.sort((a, b) => {
					let nameA = a.name ? a.name.toLowerCase() : '';
					let nameB = b.name ? b.name.toLowerCase() : '';
					return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
				});
			});
			this.customers = this.data;
			this.updateExpanded({});
			this.sideMenuService.setData(this.customers);

			this.enterprisesList = this.commonService.sortDataAlphabetically(data.enterprises, 'name');
			this.applySearchFilter(false);
		});

		this.ccSubscription = this.sideMenuService.currentCustomer.subscribe(customer => {
			this.currentCustomer = customer;
			this.cdref.detectChanges();
		});

		this.csSubscription = this.sideMenuService.currentSite.subscribe(site => {
			this.currentSite = site;
			this.cdref.detectChanges();
		});

		this.expCstrSubscription = this.sideMenuService.expandedCustomers.subscribe(customers => {
			this.updateExpanded(customers);
			this.cdref.detectChanges();
		});

		this.sideMenuService.hideMenuView.subscribe(v=> {
			this.hideMenuView = v;
		});

		this.filteredSitesSub = this.sideMenuService.filteredSites.subscribe((data: any[]) => {
			let filteredSites = [];
			for(let i in data) {
				if(data[i] && data[i].id)
					filteredSites.push(data[i].id);
			}

			this.filteredSites = filteredSites;
		});

		this.sideMenuFiltersSub = this.sideMenuService.sideMenuFilters.subscribe((filters: any) => {
			let filter = false;
			if(this.searchText != filters.searchText) {
				this.searchText = filters.searchText || '';
				filter = true;
			}
			if(this.filteredEnterprise != filters.filteredEnterprise) {
				this.filteredEnterprise = filters.filteredEnterprise;
				filter = true;
			}
			if(this.hideSites != filters.hideSites) {
				this.hideSites = filters.hideSites || [];
				filter = true;
			}
			if(this.showSmartRebateSites != filters.showSmartRebateSites) {
				this.showSmartRebateSites = filters.showSmartRebateSites
				filter = true;
			}

			if (this.showSitesRelatedDevicesType != filters.showSitesRelatedDevicesType) {
				this.showSitesRelatedDevicesType = filters.showSitesRelatedDevicesType;
				filter = true;
			}

			this.selectedLocations = this.sideMenuService.getLocationFilter();
			if(filter)
				this.applySearchFilter(false);
		});

		this.locationSub = this.sideMenuService.locations.subscribe((filters: any) => {
			this.selectedLocations = filters;
		})
	}

	getLocationOptions(customersList: any ) {
		const locationOptions: {
			[key: string]:  {stateList: {name: string, abbreviation: string}[], abbreviation: string}
		} = {
			[this.countries.canada]: {stateList: [], abbreviation: "CA"},
			[this.countries.usa]: {stateList: [], abbreviation: "USA"}
		}

		for ( const customer of customersList) {
			const sitesList = customer.sites;

			if (!sitesList || !sitesList.length)
				continue;

			for(const site of sitesList) {
				if (!Object.keys(locationOptions).includes(site.country_id) )
					continue;

				const countyAbbreviation = this.abbreviations[locationOptions[site.country_id].abbreviation];
				const existStateNames = locationOptions[site.country_id].stateList.map(state => state.name);

				if (!existStateNames.includes(site.state_id)) {
					const state = {name: site.state_id, abbreviation: countyAbbreviation[site.state_id]}
					locationOptions[site.country_id].stateList.push(state)
				}
			}
		}

		return locationOptions;
	}

	filterLocation() {
		this.filteredLocationOptions = [];

		if (this.locationFilterString == '')
			return;

		const countriesNameList = Object.keys(this.locationOptions);
		for (const countryName of countriesNameList) {
			// filter by country name and country abbreviation
			this.pushFilterOption(countryName,'', countryName, this.locationOptions[countryName].abbreviation);

			for(const state of this.locationOptions[countryName].stateList || []) {
				// filter by state name and state abbreviations
				this.pushFilterOption(countryName, state.name, state.name, state.abbreviation);
			}
		}
	}

	pushFilterOption(country: string, state: string, searchByText: string, searchByAbbreviation: string) {
		if (searchByText.toLowerCase().indexOf(this.locationFilterString.toLowerCase()) !== -1)
			this.filteredLocationOptions.push({country, state})
		else if (searchByAbbreviation.toLowerCase().indexOf(this.locationFilterString.toLowerCase()) !== -1)
			this.filteredLocationOptions.push({country, state})
	}

	addToSelectedLocations(location: {country: string, state: string}) {
		const existLocation = this.selectedLocations.filter(l => location.country == l.country && location.state == l.state)

		if(!existLocation.length) {
			this.selectedLocations.push(location);
			this.applySearchFilter(false);
		}
	}

	formatLocation(location: {country: string, state: string}) {
		let stateAbbreviation = '';
		const countryAbbreviation = this.locationOptions[location.country].abbreviation || '';

		if (countryAbbreviation != '')
			stateAbbreviation = this.abbreviations[countryAbbreviation][location.state] || '';

		return stateAbbreviation != '' ? `${stateAbbreviation},${countryAbbreviation}`: `${countryAbbreviation}`;
	}

	formateTitle(location: {country: string, state: string}) {
		return location.state != '' ? `${location.state}, ${location.country}`: `${location.country}`;
	}

	removeFilterOption(location: {country: string, state: string}) {
		const updatedLocationsObject = this.selectedLocations.filter(l => location.country != l.country || location.state != l.state);
		this.selectedLocations = [...updatedLocationsObject];
		this.sideMenuService.setLocations(updatedLocationsObject);
		this.applySearchFilter(false);
	}

	updateExpanded(customers) {
		let ids = _.pluck(customers, "id");
		this.customers.forEach(item => {
			if (ids.indexOf(item.id) > -1) {
				item.expanded = true;
			} else {
				item.expanded = false;
			}
		});
	}

	applySearchFilter(removeAllExpanded=true) {
		if(removeAllExpanded) {
			// remove all expanded
			this.sideMenuService.setExpandedCustomers([]);
		}

		//filter customers
		let filteredCustomersWithSites = {};
		this.data.filter(customer => {
			let exists = customer.customer_name.toLowerCase().includes(this.searchText.toLowerCase());
			if(exists) {
				filteredCustomersWithSites[customer.id] = customer.sites;
				return true;
			}

			customer.sites.forEach(site => {
				let name = site.name ? site.name.toLowerCase() : '';
				if (name.includes(this.searchText.toLowerCase())) {
					if(!filteredCustomersWithSites[customer.id]) {
						filteredCustomersWithSites[customer.id] = [];
					}
					filteredCustomersWithSites[customer.id].push(site);
				}
			});

			return true;
		});

		if(this.filteredEnterprise && this.filteredEnterprise.length) {
			let enterpriseSites = [];
			this.filteredEnterprise.forEach(enterprise => enterpriseSites = [ ...enterpriseSites, ...enterprise.sitesIds]);

			for(let customerId in filteredCustomersWithSites) {

				let sites = filteredCustomersWithSites[customerId];

				let deleteCustomer = true;
				let filteredSites = [];
				for(let i in sites) {
					if(enterpriseSites.includes(sites[i].id)) {
						filteredSites.push(sites[i]);
						deleteCustomer = false;
					}
				}
				if(deleteCustomer)
					delete filteredCustomersWithSites[customerId];
				else
					filteredCustomersWithSites[customerId] = filteredSites;
			}
		}

		if(this.hideSites) {
			for(let customerId in filteredCustomersWithSites) {
				let sites = filteredCustomersWithSites[customerId];
				let filteredSites = [];
				//iterate over each site belonging to the customer
				for(let siteIndex in sites) {
					let match = true;
					//iterate over each condition in the hideSites array
					for (let hideConditionIndex in this.hideSites) {
						if (this.hideSites[hideConditionIndex] === SitesToHide.EMPTY_SITES && sites[siteIndex].devices_count === 0) {
							match = false;
							break;
						}
						if (this.hideSites[hideConditionIndex] === SitesToHide.SPECIAL_SITES && sites[siteIndex].is_special) {
							match = false;
							break;

						}
						if (this.hideSites[hideConditionIndex] === SitesToHide.TEST_SITES && sites[siteIndex].test_site) {
							match = false;
							break;
						}
						if (this.hideSites[hideConditionIndex] === SitesToHide.EMPTY_ENTERPRISE) {
							let emptyEnterprise = true;
							// Check if site has an empty enterprise group
							for( let enterprise in this.enterprisesList) {
								if (this.enterprisesList[enterprise].sitesIds.includes(sites[siteIndex].id)) {
									emptyEnterprise = false;
								}
							}
							if (emptyEnterprise) {
								match = false;
							}
						}
					}
					// if the site does not match any of the hide conditions, add it to the filteredSites array
					if(match) filteredSites.push(sites[siteIndex]);
				}
				if(filteredSites.length === 0)
					delete filteredCustomersWithSites[customerId];
				else
					filteredCustomersWithSites[customerId] = filteredSites;
			}
		}

		if (this.showSmartRebateSites) {
			for(let customerId in filteredCustomersWithSites) {
				let showSmartRebates = [];
				let sites = filteredCustomersWithSites[customerId];
				for(let siteIndex in sites) {
					let match = false;
					if (this.showSmartRebateSites == SitesToHide.SMART_REBATES && sites[siteIndex].is_smart_rebates)
						match = true;

					if (this.showSmartRebateSites == SitesToHide.NON_SR_SITES && !sites[siteIndex].is_smart_rebates)
						match = true;

					if(match) showSmartRebates.push(sites[siteIndex]);
				}
				if(showSmartRebates.length === 0)
					delete filteredCustomersWithSites[customerId];
				else
					filteredCustomersWithSites[customerId] = showSmartRebates;
			}
		}

		if (this.showSitesRelatedDevicesType) {
			for(let customerId in filteredCustomersWithSites) {
				let showSitesRelatedDevicesType = [];
				let sites = filteredCustomersWithSites[customerId];

				for (const siteKey in sites) {
					const site = sites[siteKey];
					const matchesAll = this.showSitesRelatedDevicesType.every(deviceType => {
						switch (deviceType) {
							case this.commonService.DEVICES_TYPE.IoTAh:
								return site.iotah_devices_count > 0;
							case this.commonService.DEVICES_TYPE.ChargLink:
								return site.chargLink_devices_count > 0;
							case this.commonService.DEVICES_TYPE.IoTAh_ps:
								return site.iotah_ps_devices_count > 0;
							case this.commonService.DEVICES_TYPE.ChargLink_brm:
								return site.brm_devices_count > 0;
							case this.commonService.DEVICES_TYPE.Battlink:
								return site.battlink_devices_count > 0;
							default:
								return false;
						}
					});

					if (matchesAll) showSitesRelatedDevicesType.push(site);
				}

				if(showSitesRelatedDevicesType.length == 0)
					delete filteredCustomersWithSites[customerId];
				else
					filteredCustomersWithSites[customerId] = showSitesRelatedDevicesType;
			}
		}

		// apply location filter
		if (this.selectedLocations.length) {
			const usaStates = this.selectedLocations.filter(location => location.country == this.countries.usa).map((location) => location.state);
			const getAllUsaStates = usaStates.includes('');
			const canadaStates = this.selectedLocations.filter(location => location.country == this.countries.canada).map((location) => location.state);
			const getAllCanadaStates = canadaStates.includes('');

			for (let customerId in filteredCustomersWithSites) {
				let sites = filteredCustomersWithSites[customerId];
				let showSits = [];

				for(let siteIndex in sites) {
					const country = sites[siteIndex].country_id;
					const inUSA = country == this.countries.usa;
					const inCanada = country == this.countries.canada;
					const hasUsaSelectedState = inUSA && (usaStates.includes(sites[siteIndex].state_id));
					const hasCanadaSelectedState = inCanada && (canadaStates.includes(sites[siteIndex].state_id));

					if (hasUsaSelectedState || (inUSA && getAllUsaStates) || hasCanadaSelectedState  || (inCanada && getAllCanadaStates))
						showSits.push(sites[siteIndex]);
				}
				if(showSits.length === 0)
					delete filteredCustomersWithSites[customerId];
				else
					filteredCustomersWithSites[customerId] = showSits;
			}
		}

		this.customers = this.data.filter(customer => {
			return filteredCustomersWithSites[customer.id];
		});

		let sites = _.flatten(_.values(filteredCustomersWithSites));

		// set filtered sites to be reflected on map
		this.sideMenuService.setFilteredSites(sites);
		this.sideMenuService.setSideMenuFilters({
			searchText: this.searchText,
			filteredEnterprise: this.filteredEnterprise,
			hideSites: this.hideSites,
			showSmartRebateSites: this.showSmartRebateSites,
			showSitesRelatedDevicesType: this.showSitesRelatedDevicesType
		});

		this.sideMenuService.setLocations(this.selectedLocations);
		this.sideMenuService.setDeviceTypeFilterOption(this.showSitesRelatedDevicesType || []);
	}

	onSelectCustomer(customer) {
		if (this.router.url == '/sites-map' && !customer.expanded)
			this.toggleCustomerExpand(customer);
	}

	toggleCustomerExpand(customer) {
		customer.expanded = !customer.expanded;
		if (customer.expanded) {
			this.sideMenuService.addExpandedCustomer(customer);
		} else {
			this.sideMenuService.removeExpandedCustomer(customer);
		}
	}

	selectSite($event, site) {
		$event.preventDefault();

		let siteClickedTwice = this.clickedSiteId == site.id;
		let siteInfoBoxVisibility = this.sideMenuService.getSiteInfoBoxVisibility();

		if ((!siteInfoBoxVisibility || (siteInfoBoxVisibility && !siteClickedTwice)) && this.sideMenuService.getRouteHasSiteInfoBox()) {
			this.clickedSiteId = site.id;
			this.sideMenuService.setSitesMapClickedSite(site);
		} else {
			this.router.navigate([site.customer_id, site.id]);
		}
	}

	getCustomerSites(customer) {
		let result = [];
		customer.sites.forEach((site) => {
			if(this.filteredSites.length === 0 || this.filteredSites.includes(site.id))
				result.push(site);
		});
		return result;
	}

	ngOnDestroy() {
		this.ccSubscription.unsubscribe();
		this.csSubscription.unsubscribe();
		this.expCstrSubscription.unsubscribe();
		this.filteredSitesSub.unsubscribe();
		this.sideMenuFiltersSub.unsubscribe();
		this.locationSub.unsubscribe();
		this.cdref.detach();
	}
}
