import { Component, Input } from '@angular/core';
import { SiteWidget } from '../widget.interface';
import { TranslateService } from '@ngx-translate/core';
import { BattlinkSitePerformanceService } from '../battlink-site-performance.service';
import * as _ from 'underscore';

@Component({
	selector: 'app-hourly-idle-time',
	templateUrl: './hourly-idle-time.component.html',
})
export class HourlyIdleTimeComponent {
	@Input() siteId: number;
	@Input() customerId: number;
	@Input() devices: any = {};
	@Input() tags: any;
	@Input() deviceTags: any;
	@Input() data: any;

	widgetConfig: SiteWidget = {
		data: [],
		tableData: {},
		scale: 1,
		config: {
			vAxis: { title: this.translateService.instant('g.minutes'), format: "0" },
			legend: "none",
			colors: [ "#fcff99" ],
			isStacked: true
		},
		columnNames: [ "Type", "", { role: "style", type: "string" }],
		type: "ColumnChart",
		title: this.translateService.instant('perf_analytic.hourly_idle_times'),
		tooltip: "",
		widgetId: "battery_hourly_idle_times",
		dataRanges: [ "last_7_days", "last_30_days", "last_90_days", "yesterday", "last_week" ],
		selectedDateRange: "yesterday",
		filterByTag: true,
		selectedTags: [],
		filterByType: true,
		selectedType: "all",
		hideWidget: true,
		stackColumn: "truck_type",
		groups: [
			["12AM", 0],
			["1AM", 1],
			["2AM", 2],
			["3AM", 3],
			["4AM", 4],
			["5AM", 5],
			["6AM", 6],
			["7AM", 7],
			["8AM", 8],
			["9AM", 9],
			["10AM", 10],
			["11AM", 11],
			["12PM", 12],
			["1PM", 13],
			["2PM", 14],
			["3PM", 15],
			["4PM", 16],
			["5PM", 17],
			["6PM", 18],
			["7PM", 19],
			["8PM", 20],
			["9PM", 21],
			["10PM", 22],
			["11PM", 23]
		],
		dataFormatter: this.battlinkSitePerformanceService.hoursMinFormatter,
		formateWidgetData: this.formateData,
		groupFunction: this.groupFunction,
		tableConfig:{
			columns: [],
			gridTableColumns:[],
			dataKeys: [],
			sortBy: 'appearName',
			exportFileName: this.translateService.instant('site_performance.battery_fleet_utilization'),
			sizeColumnsToFit: true
		},
		dropDownGroupList: [
			{
				name: 'date_range',
				label: '',
				options: [
					{label: 'Yesterday', value: 'yesterday'},
					{label: 'Last Week', value: 'last_week'},
					{label: 'Last 7 days', value: 'last_7_days'},
					{label: 'Last 30 days', value: 'last_30_days'},
					{label: 'Last 90 days', value: 'last_90_days'},
				],
			},
			{
				name: 'tag',
				label: 'Tags',
				options: [
					{label: 'All Tags', value: 'all'},
				]
			},
		],
		selectedDropDown: {date_range: 'yesterday', tag: 'all'},
	};

	tableItems: any[] = [];
	chartTableConfig: any = {data: [], column: []};
	tableColumns: any[] = [];
	exportFileName: string = "";
	sizeColumnsToFit: boolean = true;
	noDataForCurrentFilters: boolean = false;

	constructor(
		private translateService: TranslateService,
		private battlinkSitePerformanceService: BattlinkSitePerformanceService,
	) {
		this.formateData = this.formateData.bind(this);
	}

	ngOnInit() {
		this.updateWidgetData();
		this.battlinkSitePerformanceService.updateTagsList(this.widgetConfig, this.tags);
	}

	formateData(widget: SiteWidget, data: any, devices: any, defaultCountOfDays: number, availableDaysByDevices: any): any {
		const returnData = {};
		for (let day= 0; day < 24; day++) {
			const dayData = data[day] || {};
			returnData[day] = {};

			for(let macAddress in dayData) {
				if(!devices[macAddress])
					continue;

				let countOfDays = defaultCountOfDays;
				returnData[day][macAddress] = {};
				let avg = 0;

				if(availableDaysByDevices[macAddress])
					countOfDays = availableDaysByDevices[macAddress];

				if(countOfDays > 0)
					avg = data[day][macAddress] / countOfDays;

				returnData[day][macAddress].avg = avg;
				returnData[day][macAddress].countOfDays = countOfDays;
				returnData[day][macAddress].total = data[day][macAddress];
			}

			if(Object.keys(returnData[day]).length === 0)
				delete returnData[day];
		}

		return returnData;
	}

	onFilter(filter) {
		this.battlinkSitePerformanceService.updateFilter(this.widgetConfig, filter);
		this.updateWidgetData();
	}

	updateWidgetData() {
		this.battlinkSitePerformanceService.generateWidgetData(this.widgetConfig, this.data, this.devices, this.deviceTags);
		this.noDataForCurrentFilters = this.widgetConfig.data.length === 0;
	}

	groupFunction(widget: SiteWidget, widgetData: any, devices: any) {
		const chartData = [];
		const tableObjects: any = {};
		let groups = widget.groups;

		// prepare chart data and table objects
		let counter = 0;
		let countOfStackedItems = 1;

		for(let group of groups) {
			let itemp = [];

			itemp.push(group[0]);

			for(let i = 0; i < countOfStackedItems; i++) {
				itemp.push(0);
			}

			tableObjects[counter] = [];
			chartData.push(itemp);
			counter++;
		}

		// fill chart data and table objects
		for(let hour in widgetData) {
			for(let mac_address in widgetData[hour]) {
				let macAddress = mac_address;

				if(!devices[macAddress])
					continue;

				chartData[+hour][1] += widgetData[hour][macAddress].avg;
			}
		}

		// add colors to chart data
		for (let i = 0; i < chartData.length; i++) {
			chartData[i].push(widget.config.colors[0]);
		}

		return { chartData, tableData: tableObjects };
	}
}
