import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-daily-ah-fleet-utilization',
	templateUrl: './daily-ah-fleet-utilization.component.html',
	styleUrls: ['./daily-ah-fleet-utilization.component.css']
})
export class DailyAhFleetUtilizationComponent {
	@Input() data: any = {};
	@Input() devices: any = {};
	chart = {
		firstChartTitle: this.translate.instant('utilization.daily_ahr_fleet_utilization'),
		secondChartTitle: this.translate.instant('utilization.daily_percetn_ahr_fleet_utilization'),
		firstChartYAxeTitle: this.translate.instant('g.ahr'),
	}

	constructor(
		private translate: TranslateService
	) {}
}
