import { Component, OnInit } from '@angular/core';
import { LoaderService } from './loader.service';
import { DomainsService } from '../services/domains.service';

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
	show: boolean;
	domainIcon = '';

	constructor(
		private loaderService: LoaderService,
		private domainsService: DomainsService,
	) { }

	ngOnInit() {
		this.domainIcon = ''; // default icon (smartTelemetrics)

		if(this.domainsService.isChargLink)
			this.domainIcon = 'charglink-logo';

		if(this.domainsService.isIotah)
			this.domainIcon = 'iotah-logo';

		if(this.domainsService.isBattlink)
			this.domainIcon = 'battlink-logo';

		this.loaderService.isLoading.subscribe((value) => {
			this.show = value;
		});
	}
}
