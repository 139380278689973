<div *ngIf="siteDevices?.length > 0 && !multiDevices" class="bg-white">
	<nav class="navbar-expand-lg analytics-nav py-2">
		<div class="navbar-collapse">
			<ul class="navbar-nav mr-auto links-container" [class.links-container]="isTabletOrMobile" *ngIf="deviceInfo.config_info && !isStudyDevice">
				<li class="nav-item" [ngClass]="{'active': isActiveTab('analytics')}">
					<a class="nav-link py-1 pointer" [routerLink]="getPageUrl('performance')" (click)="commonService.handleLinkClickEvents($event)">{{'g.analytics' | translate}}</a>
				</li>
				<li class="nav-item" *ngIf="hasSettingsPermission" [ngClass]="{'active': isActiveTab('settings')}">
					<a class="nav-link py-1 pointer" [routerLink]="getPageUrl('settings')" [queryParams]="{ tab: 'info' }" (click)="commonService.handleLinkClickEvents($event)">{{'g.settings' | translate}}</a>
				</li>
				<li class="nav-item" [ngClass]="{'active': isActiveTab('admin-tool')}" *ngIf="canAccessAdminTool()">
					<a class="nav-link py-1 pointer" [routerLink]="getPageUrl(availableAdminToolPage)" (click)="commonService.handleLinkClickEvents($event)">{{'nav.admin_tool' | translate}}</a>
				</li>
				<li class="nav-item" [ngClass]="{'active': isActiveTab('quick-view')}">
					<a class="nav-link py-1 pointer" [routerLink]="getPageUrl('quick-view')" (click)="commonService.handleLinkClickEvents($event)">{{'nav.quick_view' | translate}}</a>
				</li>
				<li class="nav-item" [ngClass]="{'active': isActiveTab('device-notes')}">
					<a class="nav-link py-1 pointer" [routerLink]="getPageUrl('device-notes')" (click)="commonService.handleLinkClickEvents($event)">{{'note.device_notes' | translate}}</a>
				</li>

				<li class="nav-item" [ngClass]="{'active': isActiveTab('device-attachments')}">
					<a class="nav-link py-1 pointer" [routerLink]="getPageUrl('device-attachments')" (click)="commonService.handleLinkClickEvents($event)">{{'attachments.device' | translate}}</a>
				</li>

			</ul>
			<ul class="navbar-nav mr-auto" *ngIf="deviceInfo.config_info && isStudyDevice">
				<li class="nav-item ml-4 study-details">
					<div class="font-weight-bold">{{'studies.name' | translate}}</div>
					<div>{{studyInfo.name}}</div>
				</li>
				<li class="nav-item ml-4"><div class="study-details-split mx-3"></div></li>
				<li class="nav-item ml-4 study-details">
					<div class="font-weight-bold">{{'studies.study_status' | translate}}</div>
					<div>{{'studies.'+(studyInfo.is_done ? 'status_done' : 'status_not_done') | translate}}</div>
				</li>
				<li class="nav-item ml-4" *ngIf="usersService.userHasNOCAccess(enterprisePermissions)"><div class="study-details-split mx-3"></div></li>
				<li class="nav-item ml-4 study-details" *ngIf="usersService.userHasNOCAccess(enterprisePermissions)">
					<div class="font-weight-bold">{{'studies.start_event_id' | translate}}</div>
					<div>{{studyInfo.start_event_id}}</div>
				</li>
				<li class="nav-item ml-4"><div class="study-details-split mx-3"></div></li>
				<li class="nav-item ml-4 study-details">
					<div class="font-weight-bold">{{'studies.start_study_time' | translate}}</div>
					<div>{{getStudyStartDate}}</div>
				</li>
				<li class="nav-item ml-4" *ngIf="usersService.userHasNOCAccess(enterprisePermissions)"><div class="study-details-split mx-3"></div></li>
				<li class="nav-item ml-4 study-details" *ngIf="usersService.userHasNOCAccess(enterprisePermissions)">
					<div class="font-weight-bold">{{'studies.end_event_id' | translate }}</div>
					<div>{{studyInfo.end_event_id || '-'}}</div>
				</li>
				<li class="nav-item ml-4"><div class="study-details-split mx-3"></div></li>
				<li class="nav-item ml-4 study-details">
					<div class="font-weight-bold">{{'studies.end_study_time' | translate}}</div>
					<div>{{!studyInfo.is_done ? '-' : getStudyEndtDate}}</div>
				</li>
			</ul>
			<div *ngIf="shouldShowDropdown()" class="nav-item py-2 mx-4 btn-group" dropdown>
				<fa-icon icon="cog" size="lg" dropdownToggle class="pointer"></fa-icon>
				<ul *dropdownMenu class="dropdown-menu pull-menu-left device-actions-menue" role="menu">
					<li *ngIf="!studyInfo.is_done" role="menuitem">
						<a class="dropdown-item pointer" (click)="showNewStudyForm()" style="color: black;">{{'studies.end_create_new_study' | translate}}</a>
					</li>
					<li role="menuitem" *ngIf="usersService.hasAccessPermission(null, 'noc', 'write')">
						<a class="dropdown-item pointer " (click)="showEditEventIdPopup()" style="color: black;" >{{'studies.edit_start_and_end_event' | translate}}</a>
					</li>
					<li role="menuitem" *ngIf="usersService.hasAccessPermission(null, 'delete_power_studies', 'write')">
						<a class="dropdown-item pointer " (click)="deletePowerStudy()" style="color: black;">{{'g.delete' | translate}}</a>
					</li>
				</ul>
			</div>

			<ul *ngIf="!isStudyDevice" class="navbar-nav ml-auto">
				<li>
					<app-device-select-criteria
						#deviceSelectCriteria
						*ngIf="siteDevices?.length > 0"
						[activePage]="activePage"
						[siteID]="siteID"
						[deviceID]="deviceID"
						[customerID]="customerID"
						[device]="deviceInfo"
						[changesStack]="changesStack"
						[simInfo]="simInfo"
						[currentSite]="currentSite"
						[enterprisePermissions]="enterprisePermissions"
						[siteDevices]="siteDevices"
						[deviceLastConnectTime]="deviceLastConnectTime"
						[isStudyDevice]="isStudyDevice"
						[studyId]="studyInfo.id"
						(changePage)="goToPage($event['pageName'])"
						(onGetSimInfo)="onGetSimInfo($event)"
						(onDeviceSelect)="onDeviceSelect($event)"
					></app-device-select-criteria>
				</li>
			</ul>
		</div>
	</nav>

	<div class="d-flex justify-content-around page-cards" *ngIf="isActiveTab('analytics')">
		<a class="p-4 pointer" [routerLink]="getPageUrl('performance')" (click)="commonService.handleLinkClickEvents($event)" [ngClass]="{'active': isActiveTab('performance', true)}">
			<img src="/sctportal/images/settings/performance.png">
			<div class="font-weight-bold">
				{{'g.performance' | translate}}
			</div>
		</a>
		<a class="p-4 pointer" *ngIf="hasEventsPermission && usersService.hasAccessPermission(enterprisePermissions, 'read_events')" [routerLink]="getPageUrl('events')" (click)=" hideEvents(); commonService.handleLinkClickEvents($event);" [ngClass]="{'active': isActiveTab('events', true)}">
			<img src="/sctportal/images/settings/events-v1.png">
			<div class="font-weight-bold">
				{{'nav.events_log' | translate}}
			</div>
		</a>
		<a class="p-4 pointer" *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'read_events')" [routerLink]="getPageUrl('daily-details')" (click)="commonService.handleLinkClickEvents($event);" [ngClass]="{'active': isActiveTab('daily-details')}">
			<img src="/sctportal/images/settings/daily_details-v1.png">
			<div class="font-weight-bold">
				{{'nav.daily_details' | translate}}
			</div>
		</a>
		<a class="p-4 pointer" *ngIf="isStudyDevice && usersService.userHasNOCAccess(enterprisePermissions)" [routerLink]="getPageUrl('charger-prediction')" (click)="commonService.handleLinkClickEvents($event);" [ngClass]="{'active': isActiveTab('charger-prediction')}">
			<img src="/sctportal/images/settings/operation_analysis.png">
			<div class="font-weight-bold">
				{{'nav.charger-prediction' | translate}}
			</div>
		</a>
		<a class="p-4 pointer" *ngIf="hasDebugRecordsPermission && !isStudyDevice" [routerLink]="getPageUrl('debug-records')" (click)="commonService.handleLinkClickEvents($event);" [ngClass]="{'active': isActiveTab('debug-records')}">
			<img src="/sctportal/images/settings/debug_records-v1.png">
			<div class="font-weight-bold">
				{{'nav.debug_records' | translate}}
			</div>
		</a>
		<a class="p-4 pointer" *ngIf="hasGlobalRecordsPermission && !isStudyDevice" [routerLink]="getPageUrl('global-records')" (click)="commonService.handleLinkClickEvents($event);" [ngClass]="{'active': isActiveTab('global-records')}">
			<img src="/sctportal/images/settings/global_records.png">
			<div class="font-weight-bold">
				{{'nav.global_records' | translate}}
			</div>
		</a>
		<a class="p-4 pointer" *ngIf="hasRTRecordsPermission && usersService.hasAccessPermission(enterprisePermissions, 'read_rts', 'read') && !isStudyDevice" [routerLink]="getPageUrl('rt-records')" (click)="commonService.handleLinkClickEvents($event);" [ngClass]="{'active': isActiveTab('rt-records')}">
			<img src="/sctportal/images/settings/rt_records.png">
			<div class="font-weight-bold">
				{{'nav.rt_records' | translate}}
			</div>
		</a>
		<a class="p-4 pointer" *ngIf="isStudyDevice && usersService.userHasNOCAccess()" [routerLink]="getPageUrl('reverse-events')" (click)="commonService.handleLinkClickEvents($event);" [ngClass]="{'active': isActiveTab('study-reverse-events', true)}">
			<fa-icon icon="retweet" size="3x"></fa-icon>
			<div class="font-weight-bold pt-3">
				{{'action_log.reverse_events' | translate}}
			</div>
		</a>
	</div>

	<div class="d-flex justify-content-around page-cards card-container" *ngIf="isActiveTab('admin-tool') && canAccessAdminTool()" #cardContainer>
		<a class="p-4 pointer" *ngIf="usersService.hasAccessFunction('access_device_config_track')" [routerLink]="getPageUrl('config-track')" (click)="commonService.handleLinkClickEvents($event)" [ngClass]="{'active': isActiveTab('config-track', true)}">
			<fa-icon icon="cogs" size="3x"></fa-icon>
			<div class="font-weight-bold pt-3">
				{{('nav.config_track') | translate}}
			</div>
		</a>
		<a class="p-4 pointer" *ngIf="usersService.hasAccessFunction('access_device_connectivity_track')" [routerLink]="getPageUrl('connectivity-track')" (click)="commonService.handleLinkClickEvents($event)" [ngClass]="{'active': isActiveTab('connectivity-track', true)}">
			<fa-icon icon="exchange-alt" size="3x"></fa-icon>
			<div class="font-weight-bold pt-3">
				{{('nav.connectivity_track') | translate}}
			</div>
		</a>
		<a class="p-4 pointer" *ngIf="currentUser.is_admin"
			[routerLink]="getPageUrl('actions-history')" (click)="commonService.handleLinkClickEvents($event)" [ngClass]="{'active': isActiveTab('actions-history', true)}">
			<fa-icon icon="exchange-alt" size="3x"></fa-icon>
			<div class="font-weight-bold pt-3">
				{{'nav.actions_history' | translate}}
			</div>
		</a>
		<a class="p-4 pointer" *ngIf="currentUser.is_admin && usersService.userHasNOCAccess(enterprisePermissions)"
			[routerLink]="getPageUrl('warning-settings')" (click)="commonService.handleLinkClickEvents($event)" [ngClass]="{'active': isActiveTab('warning-settings', true)}">
			<i class="fa fa-exclamation-triangle"></i>
			<div class="font-weight-bold pt-3">
				{{'nav.warning_settings' | translate}}
			</div>
		</a>
		<a class="p-4 pointer" *ngIf="deviceService.showManageDeviceOption(deviceInfo, currentSite)" [routerLink]="getPageUrl('manage-device')" (click)="commonService.handleLinkClickEvents($event)" [ngClass]="{'active': isActiveTab('manage-device', true)}">
			<fa-icon icon="cog" size="3x"></fa-icon>
			<div class="font-weight-bold pt-3">
				{{'devices.manage_device' | translate}}
			</div>
		</a>
		<a class="p-4 pointer" *ngIf="usersService.hasAccessFunction('device_management')" [routerLink]="getPageUrl('device-admin')" (click)="commonService.handleLinkClickEvents($event)" [ngClass]="{'active': isActiveTab('device-admin', true)}">
			<fa-icon icon="cog" size="3x"></fa-icon>
			<div class="font-weight-bold pt-3">
				{{'admin.device_admin' | translate}}
			</div>
		</a>
		<a class="p-4 pointer" *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'debug_data')" [routerLink]="getPageUrl('cellular-settings')" (click)="commonService.handleLinkClickEvents($event)" [ngClass]="{'active': isActiveTab('cellular-settings', true)}">
			<img src="/sctportal/images/lte-tab.png" style="height: 40px;">
			<div class="font-weight-bold pt-3">
				{{'admin.cellular_settings' | translate}}
			</div>
		</a>
		<a class="p-4 pointer" *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'debug_data')" [routerLink]="getPageUrl('cellular-track')" (click)="commonService.handleLinkClickEvents($event)" [ngClass]="{'active': isActiveTab('cellular-track', true)}">
			<fa-icon icon="cog" size="3x"></fa-icon>
			<div class="font-weight-bold pt-3">
				{{('admin.cellular_track') | translate}}
			</div>
		</a>
		<a class="p-4 pointer" *ngIf="usersService.hasAccessFunction('device_management')" [routerLink]="getPageUrl('reverse-events')" (click)="commonService.handleLinkClickEvents($event)" [ngClass]="{'active': isActiveTab('reverse-events', true)}">
			<fa-icon icon="retweet" size="3x"></fa-icon>
			<div class="font-weight-bold pt-3">
				{{'action_log.reverse_events' | translate}}
			</div>
		</a>
		<a class="p-4 pointer" *ngIf="deviceInfo.config_info && usersService.hasAccessFunction('device_management')" [routerLink]="getPageUrl('reverse-calibration')" (click)="commonService.handleLinkClickEvents($event)" [ngClass]="{'active': isActiveTab('reverse-calibration', true)}">
			<fa-icon icon="retweet" size="3x"></fa-icon>
			<div class="font-weight-bold pt-3">
				{{'action_log.reverse_calibration' | translate}}
			</div>
		</a>
		<a class="p-4 pointer" *ngIf="usersService.hasAccessFunction('device_management')" [routerLink]="getPageUrl('reverse-rt')" (click)="commonService.handleLinkClickEvents($event)" [ngClass]="{'active': isActiveTab('reverse-rt', true)}">
			<fa-icon icon="retweet" size="3x"></fa-icon>
			<div class="font-weight-bold pt-3">
				{{'action_log.reverse_rt' | translate}}
			</div>
		</a>
		<a class="p-4 pointer" *ngIf="deviceInfo.config_info && usersService.hasAccessFunction('replace_device')" [routerLink]="getPageUrl('replace-device')" (click)="commonService.handleLinkClickEvents($event)" [ngClass]="{'active': isActiveTab('replace-device', true)}">
			<fa-icon icon="retweet" size="3x"></fa-icon>
			<div class="font-weight-bold pt-3">
				{{'admin.replace_device' | translate}}
			</div>
		</a>
		<a class="p-4 pointer" *ngIf="usersService.hasAccessFunction('test_fw') && currentSite.test_site" [routerLink]="getPageUrl('update-device-fw')" (click)="commonService.handleLinkClickEvents($event)" [ngClass]="{'active': isActiveTab('update-device-fw', true)}">
			<img class="upload-fw" src="/sctportal/images/settings/upload-fw.png">
			<div class="font-weight-bold pt-3">
				{{'admin.push_fw' | translate}}
			</div>
		</a>
	</div>
</div>
<div>
	<div class="box-border" *ngIf="siteDevices?.length == 0">
		<small><strong>{{'site.no_site_devices' | translate}}</strong></small>
	</div>
	<div [ngSwitch]="activePage" *ngIf="deviceID && deviceID!='0'">

		<app-date-range-select
			*ngIf="siteDevices?.length > 0"
			[activePage]="activePage"
			[showLeftRightArrows]="activePage == 'rt-records'"
			[device]="deviceInfo"
			[minDate]="getMaxMinDateRangeForStudyDevice.minDate"
			[maxDate]="getMaxMinDateRangeForStudyDevice.maxDate"
			(onDatesChanged)="onDatesChanged($event)"
		></app-date-range-select>

		<app-device-performance-analytics *ngSwitchCase="'performance'" [device]="deviceInfo" [currentSite]="currentSite" [studyInfo]="isStudyDevice ? studyInfo : {}" [enterprisePermissions]="enterprisePermissions"></app-device-performance-analytics>

		<app-config-track *ngSwitchCase="'config-track'" [device]="deviceInfo" [currentSite]="currentSite" [dateRange]="dateRange" [invalidDateRange]="invalidDateRange"></app-config-track>

		<app-connectivity-track *ngSwitchCase="'connectivity-track'" [device]="deviceInfo" [currentSite]="currentSite" [dateRange]="dateRange" [invalidDateRange]="invalidDateRange"></app-connectivity-track>
		<app-actions-history *ngSwitchCase="'actions-history'" [device]="deviceInfo" [currentSite]="currentSite"
			[dateRange]="dateRange" [invalidDateRange]="invalidDateRange"></app-actions-history>

		<app-warning-settings
			*ngSwitchCase="'warning-settings'"
			[enterprisePermissions]="enterprisePermissions"
			[device]="deviceInfo"
			(updateDeviceAttributes)="onUpdateDeviceAttributes($event)"
			(onDeviceDataChange)="onDeviceDataChange($event)"
		></app-warning-settings>

		<app-reverse-events *ngSwitchCase="'reverse-events'" [device]="deviceInfo"></app-reverse-events>
		<app-reverse-rt *ngSwitchCase="'reverse-rt'" [device]="deviceInfo"></app-reverse-rt>
		<app-force-read-debug *ngSwitchCase="'force-read-debug'" [device]="deviceInfo"></app-force-read-debug>
		<app-update-device-fw *ngSwitchCase="'update-device-fw'" [device]="deviceInfo"></app-update-device-fw>
		<app-reverse-calibration *ngSwitchCase="'reverse-calibration'" [device]="deviceInfo"></app-reverse-calibration>
		<app-replace-device *ngSwitchCase="'replace-device'" [device]="deviceInfo"></app-replace-device>
		<app-device-admin *ngSwitchCase="'device-admin'" [device]="deviceInfo"></app-device-admin>
		<app-cellular-settings *ngSwitchCase="'cellular-settings'" [device]="deviceInfo" [simInfo]="simInfo"></app-cellular-settings>
		<app-cellular-track *ngSwitchCase="'cellular-track'" [device]="deviceInfo" [currentSite]="currentSite" [dateRange]="dateRange" [invalidDateRange]="invalidDateRange"></app-cellular-track>
		<app-official-report *ngSwitchCase=" 'official-reporting'" [device]="deviceInfo"></app-official-report>

		<app-manage-device-page
			*ngSwitchCase="'manage-device'"
			[devicesIds]="deviceID"
			[render]="'partial'"
			(deviceOwnerSaved)="onOwnerSaved()"
		></app-manage-device-page>

		<app-debug-records *ngSwitchCase="'debug-records'" [device]="deviceInfo" (onDeviceDataChange)="onDeviceDataChange($event)"></app-debug-records>

		<div *ngIf="checkFieldsInputsExist()">
			<app-device-settings *ngSwitchCase="'settings'"
				[device]="deviceInfo"
				[config_info]="deviceInfo.config_info"
				[deviceIds]="deviceID"
				[multiDevices]="multiDevices"
				[currentSite]="currentSite"
				[enterprisePermissions]="enterprisePermissions"
				[changesStack]="changesStack"
				(updateDeviceAttributes)="onUpdateDeviceAttributes($event)"
				(onDeviceDataChange)="onDeviceDataChange($event)"
			></app-device-settings>
		</div>

		<span *ngSwitchCase="'events'">
			<div class="pt-4 pl-4" *ngIf="usersService.userHasNOCAccess(enterprisePermissions)">
				<mat-slide-toggle [(ngModel)]="showCorruptedEvents">{{'g.offline_records' | translate}}</mat-slide-toggle>
				&nbsp; <mat-slide-toggle [(ngModel)]="showDefectiveEvents">{{'g.defective_records' | translate}}</mat-slide-toggle>
			</div>
			<app-events
				[device]="deviceInfo"
				[currentSite]="currentSite"
				[dateRange]="dateRange"
				[studyId]="studyId"
				[invalidDateRange]="invalidDateRange"
				[showCorruptedEvents]="showCorruptedEvents"
				[showDefectiveEvents]="showDefectiveEvents">
			</app-events>
		</span>

		<app-daily-details *ngSwitchCase="'daily-details'" [device]="deviceInfo" [studyId]="studyId" [currentSite]="currentSite" [dateRange]="dateRange" [invalidDateRange]="invalidDateRange"></app-daily-details>
		<app-charger-prediction *ngSwitchCase="'charger-prediction'" [studyInfo]="studyInfo" [device]="deviceInfo"></app-charger-prediction>

		<app-global-records *ngSwitchCase="'global-records'" [device]="deviceInfo" [currentSite]="currentSite" [enterprisePermissions]="enterprisePermissions"></app-global-records>

		<app-quick-view *ngSwitchCase="'quick-view'" [device]="deviceInfo" [currentSite]="currentSite" [enterprisePermissions]="enterprisePermissions"></app-quick-view>

		<app-device-notes *ngSwitchCase="'device-notes'" [device]="deviceInfo" [currentSite]="currentSite"></app-device-notes>

		<app-device-attachments *ngSwitchCase="'device-attachments'" [device]="deviceInfo"></app-device-attachments>

		<app-rt *ngSwitchCase="'rt-records'" [device]="deviceInfo" [currentSite]="currentSite" [dateRange]="dateRange" (onDeviceDataChange)="onDeviceDataChange($event)"></app-rt>

		<app-set-adc *ngSwitchCase="'set-adc'" [device]="deviceInfo"></app-set-adc>

		<app-reverse-events *ngSwitchCase="'study-reverse-events'" [device]="deviceInfo" [study]="studyInfo"></app-reverse-events>
	</div>
</div>

<app-modal #confirmDeleteStudy [title]="'g.confirm_action' | translate">
	<p>
		{{'studies.del_study_prompt' | translate}}
	</p>
</app-modal>

<app-new-study *ngIf="showNewStudyFormFlag" (onClose)="onNewStudyFormClose($event)" [parent]="'study'" [device]="deviceInfo" [customerId]="customerID" [siteId]="siteID" [studyInfo]="studyInfo"></app-new-study>

<app-modal
	#editStartAndEndEventPopup
	[title]="'studies.edit_start_and_end_event' | translate"
	[hasCloseButton]="false"
	[hasConfirmButton]="false"
>
	<ngx-slider
		[(value)]="editStudyRangeOptions.minValue"
		[(highValue)]="editStudyRangeOptions.maxValue"
		[options]="editStudyRangeOptions.options"
		(userChange)="onValueChange($event)"
	></ngx-slider>
	<p *ngIf="showStudyEventNumberErrorMassage" class="text-error">{{"studies.study_must_have_at_least_2_events" | translate}}</p>
	<div class="mt-3">
		<p>{{'studies.start_event_id' | translate}}: {{ editStudyRangeOptions.minValue }}</p>
		<p *ngIf="editStudyRangeOptions.maxValue">{{'studies.end_event_id' | translate}}: {{ editStudyRangeOptions.maxValue }}</p>
	</div>
	<div dialog-content>
		<div class="mt-5 d-flex flex-row-reverse">
			<button class="btn btn-light" (click)="editStartAndEndEventPopup.hide()">{{'g.close' | translate}}</button>
			<button class="btn btn-primary me-2" [disabled]="showStudyEventNumberErrorMassage" (click)="confirmEditStartAndEndEvent()">{{'g.confirm' | translate}}</button>
		</div>
	</div>
</app-modal>

<app-modal #confirmEditStartAndEndEventPopup [title]="'g.confirm_action' | translate">
	<p>{{ editStudyRangeOptions.editEventIdMessage | translate }}</p>
</app-modal>
