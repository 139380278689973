import { Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
	selector: 'site-settings-page',
	templateUrl: './site-settings-page.component.html',
	styleUrls: ['./site-settings-page.component.css']
})
export class SiteSettingsPage {
	@ViewChild('siteSettings', { static: true }) siteSettings!: TemplateRef<any>;
	@ViewChild('siteAlertsSettings', { static: true }) siteAlertsSettings!: TemplateRef<any>;
	@ViewChild('userAlertsSettings', { static: true }) userAlertsSettings!: TemplateRef<any>;

	tabs: Record<string, TemplateRef<any>> = {};
	activeTab!: TemplateRef<any>;

	constructor(
	) { }

	ngOnInit(): void {
		// Initialize tabs with TemplateRefs
		this.tabs = {
			siteSettings: this.siteSettings,
			siteAlertsSettings: this.siteAlertsSettings,
			userAlertsSettings: this.userAlertsSettings,
		};
		this.activeTab = this.tabs.siteSettings; // Default active tab
	}

	setActiveTable(tab: TemplateRef<any>) {
		this.activeTab = tab;
	}
}
