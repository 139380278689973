import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { SiteDashboardService } from '../../site-dashboard.service';
import { UsersService } from 'src/app/users/users.service';
import objectMappers from 'src/app/shared/services/mapper.json';
import * as _ from 'underscore';
import { DeviceService } from '../device.service';
import { CommonDataService } from 'src/app/shared/services/common-data.service';
import { StudiesService } from 'src/app/study/studies.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import * as moment from 'moment';
import { CommonService } from 'src/app/shared/services/common.service';
import { DomainsService } from 'src/app/shared/services/domains.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-device-dashboard',
	templateUrl: './device-dashboard.component.html',
	styleUrls: ['./device-dashboard.component.css']
})
export class DeviceDashboardComponent implements OnInit, AfterViewInit {
	@ViewChild("deviceSelectCriteria") deviceSelectCriteria;
	@ViewChild("confirmDeleteStudy") confirmDeleteStudy;
	@ViewChild("editStartAndEndEventPopup") editStartAndEndEventPopup;
	@ViewChild("confirmEditStartAndEndEventPopup") confirmEditStartAndEndEventPopup;
	@ViewChild('cardContainer', { static: false }) cardContainer: ElementRef;
	@ViewChild('mainCardContainer', { static: false }) mainCardContainer: ElementRef;
	leftMenuSub: Subscription = new Subscription();

	doneScrolling: boolean = false;
	theUIInitDone: boolean = false;
	doneScrollingToMainCard: boolean = false;
	isTabletOrMobile: boolean = false;
	leftMenuOpen: boolean = false;

 	deviceInfo: any = {};
 	simInfo: any = {};
	studyInfo: any = {};

	deviceLastConnectTime;
	deviceID:string = '0';
	changesStack: any = {};
	siteID = 0;
	customerID = 0;
	studyId = 0;

	dateRange: {
		'fromDate': Date,
		'toDate': Date
	} = {
		'fromDate': new Date(),
		'toDate': new Date()
	};
	invalidDateRange: boolean = false;
	activePage: string;
	activeDeviceDashboardTab: string;
	siteSubscription: any;
	currentSite: any = {};
	permissionsSub: any;
	updateConfigDataSbj: any;
	enterprisePermissions: any = {};
	hasRTRecordsPermission: boolean = false;
	hasEventsPermission: boolean = false;
	hasDebugRecordsPermission: boolean = false;
	hasSettingsPermission: boolean = false;
	hasGlobalRecordsPermission: boolean = false;
	showCorruptedEvents: boolean = false;
	showDefectiveEvents: boolean = false;

	availableAdminToolPage = null;

	siteDevices: any[];
	siteMacList: string[];
	currentUser: any = {};
	multiDevices: boolean = false;

	showNewStudyFormFlag = false;

	editStudyRangeOptions = {
		minValue: 0,
		maxValue: 0,
		editEventIdMessage: "",
		options: {
			floor: 0,
			ceil: 100,
			step: 1,
			showTicks: true
		},
	};

	showStudyEventNumberErrorMassage = false;
	lastConnectTimeInterval: any;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private sideMenuService: SideMenuService,
		private siteDashboardService: SiteDashboardService,
		public usersService: UsersService,
		public deviceService: DeviceService,
		public commonData: CommonDataService,
		public studiesService: StudiesService,
		private sctToastService: NotificationMessageService,
		public commonService: CommonService,
		private domainsService: DomainsService
	) { }

	ngOnInit() {
		this.isTabletOrMobile = this.commonService.isTabletOrMobileScreen;
		this.leftMenuSub = this.sideMenuService.hideMenuView.subscribe(v=> {
			this.leftMenuOpen = !v;
		});

		this.currentUser = this.usersService.getCurrentUser();
		this.siteID = this.route.parent.snapshot.params.siteId || 0;
		this.customerID = this.route.parent.snapshot.params.customerId || 0;
		this.siteSubscription = this.sideMenuService.currentSite.subscribe(siteInfo => {
			if(!siteInfo['id'])
				return;

			this.currentSite = siteInfo;
		});

		let eventsPermissionFields = Object.keys(objectMappers['events']);
		let settingsTabPermissionFields = [
			'setup_key',
			'memory_signature',
			'serial_number',
			"fw_version",
			"installation_date",
			"study_start_event_id",
			"is_study_device",
			"device_id",
			"truck_id",
			"truck_sn",
			"truck_type",
			"study_name",
			"hardware_version",
			"device_password",
			"in_test_mode",
			'current_cable_scale',
			'current_idle_to_charge',
			'current_idle_to_inuse',
			'current_charge_to_idle',
			'current_charge_to_inuse',
			'current_inuse_to_charge',
			'current_inuse_to_idle',
			'remote_flash_timer',
			'dead_man_current_limit',
			'charge_to_idle_timer',
			'charge_to_inuse_timer',
			'inuse_to_charge_timer',
			'inuse_to_idle_timer',
			'idle_to_charge_timer',
			'idle_to_inuse_timer',
			'time_hysteresis',
			'operation_mode',
			'replacement_part',
			'zone_id',
			'enable_rt',
			'rt_log_frequency',
			'has_water_level',
			'has_hall_effect',
			'has_temperature_sensor',
			'has_current_low_range',
			'fi_duration',
			'eq_duration',
			'vpc',
			'trickle_voltage',
			'cv_target_voltage',
			'fi_target_voltage',
			'eq_voltage',
			'trickle_current_rate',
			'cc_rate',
			'cv_end_current_rate',
			'cv_current_step',
			'fi_current_rate',
			'eq_current_rate',
			'battery_capacity',
			'battery_type',
			'number_of_cells',
			'battery_temperature_compensation_in_mv',
			'fi_dt',
			'fi_dv',
			'current_drift_adc_low_range',
			'voltage_drift_adc',
			'voltage_scale_adc',
			'current_drift_adc',
			'current_scale_adc',
			'temperature_beta',
			'current_scale_adc_low_range',
			'temperature_res_dividor',
			'temperature_res_drift',
			'temperature_res_dividor_drift',
			'probe_drift_adc',
			'probe_scale_adc',
			'gallons_count_scale',
			'high_probe_voltage',
			'remoter_server_port',
			'mobile_port',
			'master_port',
			'reconnect_time',
			'server_socket_port',
			'remoter_server_ip',
			'ap_password',
			'ap_ssid',
			'ap_channel',
			'enable_wifi_sta',
			'enable_multicast',
			'multicast_port',
			'multicast_ip',
			'enable_wifi_ap_on_start',
			'enable_serversocket',
			'is_online_enabled',
			'enable_cellular',
			'cellular_region',
			'cell_access_point_name',
			'lte_cellular_bands',
			'nbiot_cellular_bands',
			'cellular_reconnect_time',
			'sct_device_type'
		];

		let netowrkingPermissions = [
			'ssid',
			'bssid',
			'password',
			'priority'
		];

		let globalRecordsPermissions = [
			'debug_end',
			'events_end',
			'rt_end',
			'inuse_ahr',
			'inuse_kwhr',
			'charge_ahr',
			'charge_kwhr',
			'inuse_seconds',
			'charge_seconds',
			'idle_seconds',
			'accurate_inuse_seconds'
		];

		this.permissionsSub = this.siteDashboardService.permissionsOfCurrentSite.subscribe(data=> {
			this.enterprisePermissions = data;
			//TODO: remove the hardcoded arrays
			this.hasRTRecordsPermission		= this.checkTabPermission(['sequence_id', 'timestamp', 'voltage', 'current', 'temperature', 'version'], 'rt');
			this.hasEventsPermission		= this.checkTabPermission(eventsPermissionFields, 'events');
			this.hasDebugRecordsPermission	= this.checkTabPermission(['read_debug_records']);
			this.hasGlobalRecordsPermission	= this.checkTabPermission(globalRecordsPermissions) || this.usersService.hasAccessPermission(this.enterprisePermissions, 'debug_data', 'write');
			this.hasSettingsPermission = (
				this.checkTabPermission(settingsTabPermissionFields) ||
				this.checkTabPermission(netowrkingPermissions, 'mobile_master') ||
				this.checkTabPermission(netowrkingPermissions, 'router_network') ||
				this.checkTabPermission(netowrkingPermissions, 'mobile_network') ||
				this.checkTabPermission(netowrkingPermissions, 'online_network')
			);

			this.theUIInitDone = true;
		});

		this.updateConfigDataSbj = this.deviceService.updateConfigData.subscribe((res) => {
			if (res.isDone) {
				this.deviceInfo.config_info = {...this.deviceInfo.config_info, ...res.data};
			}
		})

		this.route.params.subscribe(params => {
			this.studyId = +params.studyId;
			if (!this.studyId) {
				return this.getSiteDevices();
			}

			if (this.studyId && this.domainsService.isChargLink)
				return this.router.navigate(['/unauthorized']);

			this.getStudyInfo();
			this.siteDashboardService.setActiveTab('site-studies');
		});

		//! rerender the page when the siteId changes
		this.route.parent?.params.subscribe(parentParams => {
		 if (parentParams.siteId != this.siteID)
			 window.location.reload();
		});

		this.lastConnectTimeInterval = setInterval(() => {
			this.getLastConnectTime();
		}, 60000);
	}

	getLastConnectTime() {
		let macAddress = this.deviceInfo?.mac_address;
		if (macAddress) {
			if(this.multiDevices) macAddress = macAddress.split(',');
			this.deviceService.deviceLastConnectTime(macAddress, this.studyInfo.id).subscribe((res:any) => {
				this.deviceLastConnectTime = res.last_connect_time;
			})
		}
	}

	ngAfterViewInit() {
		this.route.data.subscribe(params => {
			this.activePage = params.pageName;
			if(this.activePage == 'settings')
				this.deviceSelectCriteria?.getCachedDevices();
		});

		this.confirmDeleteStudy.onClose.subscribe((ok) => {
			if (ok) {
				this.deletePowerStudy(true);
			} else {
				this.confirmDeleteStudy.hide();
			}
		});

		this.confirmEditStartAndEndEventPopup.onClose.subscribe((ok) => {
			if (ok) {
				this.editStartAndEndEvent()
			} else {
				this.confirmEditStartAndEndEventPopup.hide();
			}
		});
	}

	ngAfterViewChecked(): void {
		if (!this.doneScrolling && this.theUIInitDone && this.cardContainer?.nativeElement?.querySelector('.active'))
			this.doneScrolling = this.commonService.scrollToActiveLink(this.cardContainer);
	}

	confirmEditStartAndEndEvent() {
		if(this.editStudyRangeOptions.minValue == this.editStudyRangeOptions.maxValue) {
			this.showStudyEventNumberErrorMassage = true;
			return;
		}

		this.editStudyRangeOptions.editEventIdMessage = 'studies.edit_event_id_message_ongoing'; // Ongoing

		if (this.studyInfo.end_event_id)
			this.editStudyRangeOptions.editEventIdMessage = 'studies.edit_event_id_message_completed'; // Completed

		this.editStartAndEndEventPopup.hide();
		this.confirmEditStartAndEndEventPopup.show();
	}

	private getSiteDevices() {
		this.deviceService.fetchSiteDevicesList(this.siteID, this.studyInfo.id).subscribe((devices: any) => {
			devices.forEach((device: { prettyName: string; truck_sn: string; serial_number: string; }) => {
				device.prettyName = device.truck_sn + ' ('+device.serial_number+')';
			});
			this.siteDevices = _.chain(devices).sortBy('serial_number').sortBy('truck_sn').value();
			this.siteMacList = _.pluck(this.siteDevices, 'mac_address');
		});
	}

	checkTabPermission(permissionsArr, key?) {
		let permissions = this.enterprisePermissions;
		let parentPermission = '';
		if(key) {
			//Nested field
			parentPermission = key+'.';
		}
		for (let permission of permissionsArr) {
			permission = parentPermission + permission;
			if (this.usersService.hasAccessPermission(permissions, permission)) {
				return true;
			}
		}
		return false;
	}

	ngOnDestroy() {
		if(this.siteSubscription)
			this.siteSubscription.unsubscribe();

		if(this.permissionsSub)
			this.permissionsSub.unsubscribe();

		if(this.leftMenuSub)
			this.leftMenuSub.unsubscribe();

		if (this.lastConnectTimeInterval)
			clearInterval(this.lastConnectTimeInterval);
	}

	onDeviceSelect(event) {
		this.multiDevices = event.multiDevices;
		let device = event.device,
			zoneId = event.zoneId;
		this.deviceInfo = device;
		this.getLastConnectTime();
		this.deviceID = device.mac_address;
		if(!this.siteMacList.includes(this.deviceID) && !this.multiDevices)
			return this.router.navigateByUrl('/404');
		else if(this.multiDevices) {
			for(const did of this.deviceID.split(',')) {
				if(!this.siteMacList.includes(did))
					return this.router.navigateByUrl('/404');
			}
		}

		if(!this.multiDevices)
			this.changesStack = this.deviceService.formatChangesStack(device.changes_stack, zoneId);
	}

	onGetSimInfo(event) {
		this.simInfo = event.simInfo;
	}

	onDeviceDataChange(event) {
		const newDeviceConfig = { ... this.deviceInfo.config_info , ...event }
		this.deviceInfo.config_info = newDeviceConfig;
	}

	goToPage(page) {
		let url: any[] = [this.deviceID, page];
		if (this.studyId)
			url.push(this.studyId);

		let queryParams = {};
		if(page == 'settings')
			queryParams = {tab: 'info'};

		else if(page == 'events')
			this.hideEvents();

		this.router.navigate(url, {relativeTo: this.route.parent, queryParams});
	}

	hideEvents() {
		this.showCorruptedEvents = false;
		this.showDefectiveEvents = false;
	}

	getPageUrl(page) {
		if (!page)
			return null;

		const url = ['/', this.customerID, this.siteID, this.deviceID, page];
		if (this.studyId)
			url.push(this.studyId);

		return url;
	}

	canAccessAdminTool() {
		if(this.usersService.hasAccessFunction('access_device_config_track'))
			this.availableAdminToolPage = 'config-track';
		else if(this.usersService.hasAccessFunction('access_device_connectivity_track'))
			this.availableAdminToolPage = 'connectivity-track';
		else if(this.currentUser.is_admin)
			this.availableAdminToolPage = 'actions-history';
		else if(this.usersService.hasAccessFunction('device_management'))
			this.availableAdminToolPage = 'reverse-events';
		else if(this.usersService.hasAccessFunction('replace_device'))
			this.availableAdminToolPage = 'replace-device';

		return this.usersService.hasAccessFunction('access_device_config_track') ||
		this.usersService.hasAccessFunction('access_device_connectivity_track') ||
		this.usersService.hasAccessFunction('device_management') ||
		this.usersService.hasAccessFunction('replace_device') ||
		this.currentUser.is_admin;
	}

	isActiveTab(tab, isSubTab?) {
		var subTabs = null;

		switch(tab) {
			case "analytics":
				subTabs = [
					'performance', 'events', 'rt-records', 'debug-records', 'global-records', 'daily-details', 'study-reverse-events', 'charger-prediction'
				];
			break;
			case "admin-tool":
				subTabs = [
					'config-track', 'connectivity-track', 'actions-history', 'warning-settings', 'manage-device', 'reverse-events', 'reverse-calibration',
					'reverse-rt', 'replace-device', 'force-read-debug', 'device-admin', 'update-device-fw', 'cellular-settings', 'cellular-track'
				];
			break;
		}

		if(subTabs)
			return subTabs.includes(this.activePage);

		return this.activePage == tab;
	}

	onOwnerSaved() {
		this.router.navigate(['/', this.customerID, this.siteID]);
	}

	onDatesChanged(event) {
		this.dateRange = event.dates;
		this.invalidDateRange = event.invalidRange;
	}

	onUpdateDeviceAttributes(data) {
		let deviceId			= data.deviceId,
			updatedDeviceData	= data.updatedDeviceData;

		for(let i in this.siteDevices) {
			let device = this.siteDevices[i];
			if(device.mac_address == deviceId) {
				let updatePrettyName = false;
				for(let attribute in updatedDeviceData) {
					if(['truck_sn', 'serial_number'].includes(attribute))
						updatePrettyName = true;
					device[attribute] = updatedDeviceData[attribute];
				}

				if(updatePrettyName)
					device.prettyName = device.truck_sn + ' ('+device.serial_number+')';
				break;
			}
		}

		this.siteDevices = _.chain(this.siteDevices).sortBy('serial_number').sortBy('truck_sn').value();

		this.deviceSelectCriteria.siteDevices = JSON.parse(JSON.stringify(this.siteDevices));
	}

	getStudyInfo() {
		this.studiesService.getStudy(this.studyId).subscribe((res: any) => {
			this.studyInfo = res;
			this.getSiteDevices();
		});
	}

	get getStudyStartDate() {
		return moment(this.studyInfo.start_study_time * 1000).utc().format('MM/DD/YYYY');
	}

	get getStudyEndtDate() {
		return moment(this.studyInfo.end_study_time * 1000).utc().format('MM/DD/YYYY');
	}

	deletePowerStudy(confirmed=false) {
		if (!confirmed) {
			if (!this.studyInfo.is_done)
				return this.sctToastService.setMessage('translate|studies.delete_ongoing_study', {clearOnXTimeNavigate: 1, type: 'warning'});
			return this.confirmDeleteStudy.show();
		}

		this.studiesService.deletePowerStudies(this.studyId).subscribe((res: any) => {
			switch(res) {
				case 2:
					this.sctToastService.setMessage('translate|studies.delete_ongoing_study', {clearOnXTimeNavigate: 1, type: 'warning'});
					break;
				default:
					this.sctToastService.setMessage('translate|g.deleted_successfully', {clearOnXTimeNavigate: 1, type: 'success'});
					this.router.navigate(['/', this.customerID, this.siteID, 'site-studies']);
					this.studyInfo = {};
					break;
			}
		});
	}

	showNewStudyForm() {
		this.showNewStudyFormFlag = true;
	}

	shouldShowDropdown(): boolean {
		return (
			this.deviceInfo.config_info &&
			this.isStudyDevice &&
			(
				!this.studyInfo.is_done ||
				this.usersService.hasAccessPermission(null, 'noc', 'write') ||
				this.usersService.hasAccessPermission(null, 'delete_power_studies', 'write')
			)
		);
	}

	showEditEventIdPopup() {
		this.studiesService.getMinMaxAllowedEventsToEditStudy(this.studyId, this.studyInfo.mac_address).subscribe((res: any) => {
			this.editStudyRangeOptions.minValue =  this.studyInfo.start_event_id;
			this.editStudyRangeOptions.maxValue = this.studyInfo.end_event_id;

			if (!this.studyInfo.is_done)
				this.editStudyRangeOptions.maxValue = null;

			this.editStudyRangeOptions.options = {
				floor: res.min,
				ceil: res.max,
				step: 1,
				showTicks: false,
			};

			this.showStudyEventNumberErrorMassage = false;
			this.editStartAndEndEventPopup.show();
		});
	}

	onValueChange(change: any) {
		if (change.value == change.highValue) {
			this.editStudyRangeOptions.minValue--;
			this.editStudyRangeOptions.maxValue++;
			this.showStudyEventNumberErrorMassage = true;
			return;
		}

		this.showStudyEventNumberErrorMassage = false;
	}

	editStartAndEndEvent() {
		this.studiesService.editStartAndEndEvent(this.studyInfo.mac_address, this.studyId, this.editStudyRangeOptions.minValue, this.editStudyRangeOptions.maxValue).subscribe((res: any) => {
			let msg = 'globalSuccessMsg';
			let type: "warning" | "success" | "info" | "danger" = 'success';

			switch (res?.api_status) {
				case 2:
					msg = 'translate|studies.no_changes_between_old_and_new_values';
					type = 'warning';
					break;
				case 3:
					type = 'warning';
					const nonExistEvents = res.response;

					switch (true) {
						case nonExistEvents.startEventId && nonExistEvents.endEventId:
							msg = 'translate|studies.start_and_end_events_do_not_exist';
							break;
						case nonExistEvents.startEventId:
							msg = 'translate|studies.start_event_does_not_exist';
							break;
						case nonExistEvents.endEventId:
							msg = 'translate|studies.end_event_does_not_exist';
							break;
						default:
							break;
					}

					break;
				default:
					this.studyInfo.start_event_id = this.editStudyRangeOptions.minValue;
					this.studyInfo.end_event_id = this.editStudyRangeOptions.maxValue;

					this.editStudyRangeOptions = { minValue: 0, maxValue: 0, editEventIdMessage: "",
						options: { floor: 0, ceil: 100, step: 1, showTicks: true },
					};
					break;
			}

			return this.sctToastService.setMessage(msg, { clearOnXTimeNavigate: 1, type });
		});
	}

	get isStudyDevice() {
		return this.deviceInfo.config_info?.is_study_device && this.studyId;
	}

	checkFieldsInputsExist() {
		return Object.keys(this.enterprisePermissions).length > 0 && Object.keys(this.deviceInfo).length > 0;
	}

	get getMaxMinDateRangeForStudyDevice() {
		return {
			maxDate: !this.isStudyDevice? null:  (this.studyInfo.is_done? new Date(this.studyInfo.end_study_time*1000): new Date()),
			minDate: !this.isStudyDevice? null : new Date(this.studyInfo.start_study_time*1000)
		}
	}

	onNewStudyFormClose(id: number) {
		this.showNewStudyFormFlag = false;
		if(id > 0)
			this.router.navigate(['/', this.customerID, this.siteID, this.deviceID, 'performance', id]);
	}
}
