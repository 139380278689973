import { Component, Input } from '@angular/core';
import { SiteWidget } from '../widget.interface';
import { TranslateService } from '@ngx-translate/core';
import { BattlinkSitePerformanceService } from '../battlink-site-performance.service';
import * as _ from 'underscore';
import { TableData } from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';

@Component({
	selector: 'app-idle-after-event-time',
	templateUrl: './idle-after-event-time.component.html',
})
export class IdleAfterEventTimeComponent {
	@Input() siteId: number;
	@Input() customerId: number;
	@Input() devices: any = {};
	@Input() deviceTags: any;
	@Input() tags: any;
	@Input() data: any;

	widgetConfig: SiteWidget = {
		data: [],
		tableData: {},
		scale: 1,
		config: {
			vAxis: { title: this.translateService.instant('site_performance.count_of_batteries'), format: "0" },
			legend: { position: 'top' },
			colors: ["#fad938", "#faa82f", "#fb5025", "#e92f31"],
			isStacked: false
		},
		columnNames: [ '', '30-60 mins', '60-120 mins', '120-240 mins', '>240 mins' ],
		type: "ColumnChart",
		title: this.translateService.instant('site_performance.idle_after_event_times'),
		tooltip: "",
		widgetId: "battery_idle_after_event_times",
		dataRanges: [ "last_7_days", "last_30_days", "last_90_days", "yesterday", "last_week" ],
		selectedDateRange: "yesterday",
		filterByTag: true,
		selectedTags: [],
		filterByType: true,
		selectedType: "all",
		hideWidget: true,
		stackColumn: "",
		stackedItems: ['30-60 mins', '60-120 mins', '120-240 mins', '>240 mins'],
		subGroups: [
			['30-60 mins', 30, 60], // [label, min, max)
			['60-120 mins', 60, 120],
			['120-240 mins', 120, 240],
			['>240 mins', 240, null]
		],
		groups: [
			["Mon", 0],
			["Tue", 1],
			["Wed", 2],
			["Thu", 3],
			["Fri", 4],
			["Sat", 5],
			["Sun", 6],
		],
		dataFormatter: this.battlinkSitePerformanceService.hoursMinFormatter,
		formateWidgetData: this.formateData,
		groupFunction: this.groupFunction,
		tableConfig:{
			columns: [
				{ key: 'batteryId', name: this.translateService.instant('device_performance.battery_id'), type: 'link' },
				{ key: 'idleAfterEventTimesValue', name: this.translateService.instant('g.value'), type: 'number' }
			],
			gridTableColumns:
			[
				{ headerName: this.translateService.instant('device_performance.battery_id'), field: "batteryId", width: 800},
				{ headerName: this.translateService.instant('g.value'), field: "idleAfterEventTimesValue", width: 800},
			],
			dataKeys: ['batteryId', 'idleAfterEventTimesValue'],
			sortBy: 'batteryId',
			exportFileName: this.translateService.instant('site_performance.idle_after_event_times'),
			sizeColumnsToFit: true
		},
		dropDownGroupList: [
			{
				name: 'date_range',
				label: '',
				options: [
					{label: 'Yesterday', value: 'yesterday'},
					{label: 'Last Week', value: 'last_week'},
					{label: 'Last 7 days', value: 'last_7_days'},
					{label: 'Last 30 days', value: 'last_30_days'},
					{label: 'Last 90 days', value: 'last_90_days'},
				],
			},
			{
				name: 'truck_type',
				label: 'Truck Type',
				options: [
					{label: 'All Types', value: 'all'},
				]
			},
			{
				name: 'tag',
				label: 'Tags',
				options: [
					{label: 'All Tags', value: 'all'},
				]
			},
		],
		selectedDropDown: {date_range: 'yesterday', truck_type: 'all', tag: 'all'},
	};

	tableItems: any[] = [];
	chartTableConfig: any = {data: [], column: []};
	tableColumns: any[] = [];
	exportFileName: string = "";
	sizeColumnsToFit: boolean = true;
	noDataForCurrentFilters: boolean = false;

	constructor(
		private translateService: TranslateService,
		private battlinkSitePerformanceService: BattlinkSitePerformanceService,
	) {
		this.formateData = this.formateData.bind(this);
	}

	ngOnInit() {
		this.updateWidgetData();
		this.battlinkSitePerformanceService.updateTagsList(this.widgetConfig, this.tags);
		this.battlinkSitePerformanceService.updateTypesListForWidget(this.widgetConfig);
	}

	formateData(widget: SiteWidget, data: any, devices: any, defaultCountOfDays: number, availableDaysByDevices: any): any {
		const returnData = {};
		for (let day= 0; day < 7; day++) {
			const dayData = data[day] || {};
			returnData[day] = {};

			for(let macAddress in dayData) {
				if(!devices[macAddress])
					continue;

				let countOfDays = defaultCountOfDays;
				returnData[day][macAddress] = {};
				let avg = 0;

				if(availableDaysByDevices[macAddress])
					countOfDays = availableDaysByDevices[macAddress];

				if(countOfDays > 0)
					avg = data[day][macAddress] / countOfDays;

				returnData[day][macAddress].avg = avg;
				returnData[day][macAddress].countOfDays = countOfDays;
				returnData[day][macAddress].total = data[day][macAddress];
			}

			if(Object.keys(returnData[day]).length === 0)
				delete returnData[day];
		}

		return returnData;
	}

	onSelect(event) {
		if(!event)
			return;

		let selectedDay = event.row;
		let selectedRange = event.column;
		let selectedDevicesInfo = this.widgetConfig.tableData?.[selectedDay]?.[selectedRange - 1];

		if(!selectedDevicesInfo)
			return;

		this.fillGridTableData(selectedDevicesInfo);
		this.prepareWidgetModalTable();
	}

	prepareWidgetModalTable() {
		const config = this.widgetConfig.tableConfig;
		this.tableColumns = config.gridTableColumns;
		this.exportFileName = config.exportFileName;
		this.sizeColumnsToFit = config.sizeColumnsToFit;

		if (config) {
			this.chartTableConfig.column = config.columns;
			this.chartTableConfig.data = this.tableItems.map((row) => {
				const rowData: TableData = {
					idleAfterEventTimesValue: { value: row.idleAfterEventTimesValue || 0 },
					batteryId: { value: row.batteryId },
				};

				return rowData;
			});
		}
	}

	fillGridTableData(selectedDevicesInfo, ) {
		let tableItems = [];

		for(let item of selectedDevicesInfo) {
			if(!this.devices[item.mac])
				continue;

			let temp = {
				idleAfterEventTimesValue: item.scaledAvgValue || 0,
				batteryId: item.truck_id,
			};
			tableItems.push(temp);
		}
		if(this.widgetConfig.tableConfig.sortBy)
			tableItems = _.sortBy(tableItems, this.widgetConfig.tableConfig.sortBy).reverse();

		this.tableItems = tableItems;
	}

	onFilter(filter) {
		this.battlinkSitePerformanceService.updateFilter(this.widgetConfig, filter);
		this.updateWidgetData();
	}

	updateWidgetData() {
		this.battlinkSitePerformanceService.generateWidgetData(this.widgetConfig, this.data, this.devices, this.deviceTags);
		this.noDataForCurrentFilters = this.widgetConfig.data.length === 0;
	}


	groupFunction(widget: SiteWidget, widgetData: any, devices: any) {
		const chartData = [];
		const tableObjects: any = {};
		let groups = widget.groups;

		// prepare chart data and table objects
		let counter = 0;
		let countOfStackedItems = 4;

		for(let group of groups) {
			let itemp = [];

			itemp.push(group[0]);

			tableObjects[counter] = [];
			for(let i = 0; i < countOfStackedItems; i++) {
				tableObjects[counter].push([]);
				itemp.push(0);
			}

			chartData.push(itemp);
			counter++;
		}

		// fill chart data and table objects
		for(let day in widgetData) {
			for(let mac_address in widgetData[day]) {
				let totalValue	= widgetData[day][mac_address].total;
				let avgValue = widgetData[day][mac_address].avg;
				let macAddress = mac_address;

				if(!devices[macAddress])
					continue;

				for (let subGroups of widget.subGroups) {
					let valueRangeIndex = widget.subGroups.indexOf(subGroups);
					let scaledAvgValue = avgValue / widget.scale;
					let scaledTotalValue = totalValue / widget.scale;

					const minValue = subGroups[1];
					const maxValue = subGroups[2];

					if (minValue <= scaledAvgValue && (scaledAvgValue < maxValue || maxValue === null)) {
						chartData[day][valueRangeIndex + 1]++;
						tableObjects[day][valueRangeIndex].push({
							mac: macAddress,
							serial_number: devices[macAddress].serial_number,
							truck_id: devices[macAddress].truck_id,
							totalValue: scaledTotalValue,
							scaledAvgValue,
							avgValue: scaledAvgValue,
							eventId: mac_address
						});
					}
				}
			}
		}

		return { chartData, tableData: tableObjects };
	}
}
