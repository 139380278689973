<div class="white-box p-3 mb-3">
	<app-site-battery-fleet-utilization-score *ngIf="data.site_battery_fleet_utilization_score" [data]="data.site_battery_fleet_utilization_score"></app-site-battery-fleet-utilization-score>
	<app-battery-fleet-energy-usage-score *ngIf="data.battery_fleet_energy_usage_score" [data]="data.battery_fleet_energy_usage_score"></app-battery-fleet-energy-usage-score>
	<app-battery-ah-return-score *ngIf="data.battery_ah_return_score" [data]="data.battery_ah_return_score"></app-battery-ah-return-score>
	<app-daily-battery-fleet-utilization *ngIf="data.daily_battery_fleet_utilization" [data]="data.daily_battery_fleet_utilization" [devices]="devices"></app-daily-battery-fleet-utilization>
	<app-daily-ah-battery-fleet-utilization *ngIf="data.daily_amp_hour_battery_fleet_utilization" [data]="data.daily_amp_hour_battery_fleet_utilization" [devices]="devices"></app-daily-ah-battery-fleet-utilization>
	<app-percent-battery-utilization-scores
		*ngIf="data.site_battery_fleet_utilization_score || data.battery_fleet_energy_usage_score || data.battery_ah_return_score"
		[utilizationScoreData]="data.site_battery_fleet_utilization_score"
		[energyScoreData]="data.battery_fleet_energy_usage_score"
		[ahReturnData]="data.battery_ah_return_score"
		[devices]="devices"
	></app-percent-battery-utilization-scores>
</div>

<app-battlink-site-performance *ngIf="0"></app-battlink-site-performance>
