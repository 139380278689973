import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { UsersService } from '../users.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { NgForm } from '@angular/forms';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { PermissionsService } from 'src/app/permissions/permissions.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';

@Component({
	selector: 'app-edit-user',
	templateUrl: './edit-user.component.html'
})
export class EditUserComponent implements OnInit {
	activePage: 'user_data' | 'email_subscription' = 'user_data';

	userId: any;
	user: any = {
		is_admin: false,
		email_confirmed: false,
		phone_confirmed: false,
		test_user: false,
		force_password_change: true,
		special_user: false,
		account_deactivate: false,
		email_subscription_list: {
			connectivity_verification: false,
			purchase_order_notifications: false,
			engineering : false,
			device_management_notifications: false,
			user_registration_request: false,
			device_operations: false
		}
	};

	readonly subscriptionsTranslationKeys = {
		connectivity_verification: 'connectivity_verification.connectivity_verification',
		purchase_order_notifications: 'orders.purchase_order_notifications',
		engineering : 'g.engineering',
		device_management_notifications: 'device_management.device_management_notifications',
		user_registration_request: 'action_log.user_registration_request',
		device_operations: 'g.device_operations'
	}

	currentUser: any = {};
	isAdminEdit: boolean = false;
	isAddNewUser: boolean = false;
	hasAuthCode: boolean = false;
	enterpriseList: any = [];
	permissionList: any = [];

	langList: any = [
		{value: 'en', label: 'English'},
		{value: 'fr', label: 'Français'},
		{value: 'es', label: 'Español'}
	];

	verificationPassword: string;

	@ViewChild("editUserFrm") editUserFrm:NgForm;
	@ViewChild("deleteAccountModal", { static: true }) deleteAccountModal;
	@ViewChild("RemoveBlockModal", { static: true }) RemoveBlockModal;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private navigation: NavigationService,
		public usersService: UsersService,
		private notificationMessage: NotificationMessageService,
		private enterpriseService: EnterpriseService,
		private sideMenuService: SideMenuService,
		private commonService: CommonService,
		private permissionsService: PermissionsService
	) { }

	ngOnInit() {
		this.sideMenuService.hide();
		this.isAdminEdit = this.route.snapshot.data['isAdminEdit'];

		if(this.isAdminEdit){
			this.route.params.subscribe( (params: any) => {
				this.userId = +params.userId || 0;
				let hasAddAccess  = !this.userId && this.usersService.hasAccessFunction('add_user_admin');
				let hasEditAccess = this.userId && this.usersService.hasAccessFunction('edit_user_admin');

				if(!hasEditAccess && !hasAddAccess){
					this.notificationMessage.setMessage('globalAuthMsg');
					this.router.navigate(['/'], {})
				}

				if(this.userId > 0)
					this.getUserInfo(this.userId);
				else
					this.isAddNewUser = true;

				let source = hasAddAccess ? 'add_user' : 'edit_user';

				this.enterpriseService.getEnterprises({source}).subscribe((data) => {
					this.enterpriseList = data;
				});
				this.permissionsService.getPermissionsList('user').subscribe((data) => {
					this.permissionList = data;
				});
			});

		} else {
			this.currentUser = this.usersService.getCurrentUser();
			this.userId = this.currentUser.id;
			this.getUserInfo(this.userId);
		}
	}

	ngAfterViewInit() {
		this.deleteAccountModal.onClose.subscribe(
			(ok) => {
				if(ok)
					this.deleteUserAccount();
				this.verificationPassword	= '';
			}
		);

		this.RemoveBlockModal.onClose.subscribe(
			(ok) => {
				if(ok)
					this.removeUserBlock();
			}
		);
	}

	goBack() {
		this.navigation.goBack('/');
	}

	editUser() {
		let formFields = this.editUserFrm.value;
		formFields.id = this.userId;

		if(!this.hasAuthCode)
			formFields.auth_code = "";

		for(const subscription of Object.keys(this.user.email_subscription_list)) {
			formFields[`email_subscription_list_${subscription}`] = this.user.email_subscription_list[subscription];
		}

		this.usersService.editUser(this.userId, formFields, {isAdminEdit: this.isAdminEdit}).subscribe((data: any) => {
			switch (data) {
				case 1:
					this.notificationMessage.setMessage('globalSuccessMsg');
					this.goBack();
					break;
				case 2:
					this.notificationMessage.setMessage('translate|g.invalid_input', {clearOnXTimeNavigate: 1});
					break;
				case 3:
					this.notificationMessage.setMessage('translate|users.email_used', {clearOnXTimeNavigate: 1});
					break;
				case 4:
					this.notificationMessage.setMessage('translate|users.phone_used', {clearOnXTimeNavigate: 1});
					break;
			}
		});
	}

	getUserInfo(userId) {
		this.usersService.getUsersInfo(userId, {getUserIsBlocked: true}).subscribe((data:any) => {
			data = data[0];
			data.enterprise_id = (data.enterprise_id == 0 ? null : data.enterprise_id);
			data.email_subscription_list = {...this.user.email_subscription_list, ...data.email_subscription_list};
			this.user = data;
			this.hasAuthCode = this.user.auth_code && this.user.auth_code.length > 0;
		});
	}
	onChangeHasAuthCode(val) {
		if(val.checked && (!this.user.auth_code || this.user.auth_code.length === 0))
			this.generateRandomAuthCode();
	}
	generateRandomAuthCode() {
		this.user.auth_code = this.commonService.genRandomString(8);
	}

	deleteUserAccount() {
		this.usersService.deleteUserAccount(this.verificationPassword).subscribe(response => {
			if (response.api_status == 2)
				return this.notificationMessage.setMessage('translate|login_register.incorrect_password', {clearOnXTimeNavigate: 1});

			this.usersService.setCurrentUser({});
			this.navigation.setPreviousUrl(null, true);
			this.router.navigate(['/login']);

		});
	}

	removeUserBlock() {
		this.usersService.removeUserBlock(this.userId).subscribe(res => {
			this.notificationMessage.setMessage('globalSuccessMsg');
			this.user.is_blocked = false;
		});
	}

	setActivePage(page: 'email_subscription' | 'user_data') {
		this.activePage = page;
	}
}
