import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { MainLayoutComponent } from './layouts/main-layout.component';
import { SimpleLayoutComponent } from './layouts/simple-layout.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { CustomersComponent } from './customers/customers.component';
import { CustomerEditPageComponent } from './customers/customer-edit-page/customer-edit-page.component';
import { LoginComponent } from './users/login/login.component';
import { EditUserComponent } from './users/edit-user/edit-user.component';
import { RegisterComponent } from './users/register/register.component';
import { ConfirmUserEmailComponent } from './users/confirm-user-email/confirm-user-email.component';
import { RegistrationRequestsComponent } from './admin/registration-requests/registration-requests.component';
import { ValidateRegistrationRequestsComponent } from './admin/validate-registration-requests/validate-registration-requests.component';
import { UsersComponent } from './users/users.component';
import { EnterpriseComponent } from './enterprise/enterprise.component';
import { EnterpriseEditComponent } from './enterprise/enterprise-edit/enterprise-edit.component';
import { SitesComponent } from './sites/sites.component';
import { SiteEditPageComponent } from './sites/site-edit-page/site-edit-page.component';
import { CustomerInfoComponent } from './home/customer-dashboard/customer-info/customer-info.component';
import { SearchDevicesComponent } from './device/search-devices/search-devices.component';
import { ManageDevicePageComponent } from './device/manage-device-page/manage-device-page.component';
import { ErrorLogComponent } from './admin/logs/error-log/error-log.component';
import { ViewErrorComponent } from './admin/logs/error-log/view-error/view-error.component';
import { SchedControlComponent } from './admin/sched/sched-control/sched-control.component';
import { SchedMonitorComponent } from './admin/sched/sched-monitor/sched-monitor.component';
import { FirmwareUpdateComponent } from './management/firmware-update/firmware-update.component';
import { DeviceListingAdminComponent } from './admin/device/device-listing-admin/device-listing-admin.component';
import { DeviceEditPageComponent } from './admin/device/device-edit-page/device-edit-page.component';
import { EditAccessFunctionsComponent } from './users/edit-access-functions/edit-access-functions.component';
import { PermissionEditComponent } from './admin/permission-edit/permission-edit.component';
import { ForgotPasswordComponent } from './users/forgot-password/forgot-password.component';
import { ContactUsAdminComponent } from './admin/contact-us-admin/contact-us-admin.component';
import { ViewContactUsAdminComponent } from './admin/view-contact-us-admin/view-contact-us-admin.component';
import { ContactUsComponent } from './users/contact-us/contact-us.component';
import { EditUserPasswordComponent } from './users/edit-user-password/edit-user-password.component';

import { HomeComponent } from './home/home.component';
import { SitesMapComponent } from './home/sites-map/sites-map.component';
import { SiteDashboardComponent } from './home/site-dashboard/site-dashboard.component';
import { SiteInfoComponent } from './home/site-dashboard/site-info/site-info.component';

import { DevicesListingComponent } from './home/site-dashboard/device/devices-listing/devices-listing.component';
import { DeviceDashboardComponent } from './home/site-dashboard/device/device-dashboard/device-dashboard.component';
import { AdminComponent } from './admin/admin.component';
import { ZombieSitesComponent } from './admin/customer/zombie-sites/zombie-sites.component';
import { RejectedDeviceConnectionComponent } from './admin/logs/rejected-device-connection/rejected-device-connection.component';
import { ActionLogComponent } from './admin/logs/action-log/action-log.component';
import { BecomeUserComponent } from './users/become-user/become-user.component';
import { BlockedFwReportsComponent } from './admin/blocked-fw-reports/blocked-fw-reports.component';
import { LoginNonConfirmedEmailComponent } from './users/login-non-confirmed-email/login-non-confirmed-email.component';
import {CookiePolicyComponent} from './home/cookie-policy/cookie-policy.component';
import { SitePerformanceComponent } from './home/site-dashboard/site-performance/site-performance.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { PermissionsEditComponent } from './permissions/permissions-edit/permissions-edit.component';
import { SiteNotesComponent } from './home/site-dashboard/site-notes/site-notes.component';
import { TagsManagementComponent } from './home/site-dashboard/tags-management/tags-management.component';
import { ManageDeviceRequestComponent } from './device/manage-device-request/manage-device-request.component';
import { ManagementRequestsComponent } from './admin/device/management-requests/management-requests.component';
import { DeviceRequestsComponent } from './device/management-requests/device-requests/device-requests.component';
import { CustomerRequestsComponent } from './device/management-requests/customer-requests/customer-requests.component';
import { SiteRequestsComponent } from './device/management-requests/site-requests/site-requests.component';
import { CustomerRequestEditComponent } from './device/management-requests/customer-request-edit/customer-request-edit.component';
import { SiteRequestEditComponent } from './device/management-requests/site-request-edit/site-request-edit.component';
import { DeviceReplacementRequestsComponent } from './device/management-requests/device-replacement-requests/device-replacement-requests.component';
import { SiteWarningsComponent } from './home/site-dashboard/site-warnings/site-warnings.component';
import { WarningsComponent } from './admin/warnings/warnings.component';
import { TermsAndConditionsComponent } from './home/terms-and-conditions/terms-and-conditions.component';
import { MaintenanceReportComponent } from './home/site-dashboard/maintenance-report/maintenance-report.component';
import { ReceivingShipmentComponent } from './management/receiving-shipment/receiving-shipment.component';
import { ShipmentQualityComponent } from './management/shipment-quality/shipment-quality.component';
import { ShippingOutComponent } from './management/shipping-out/shipping-out.component';
import { SiteWarrantyComponent } from './site-dashboard/site-warranty/site-warranty.component';
import { SiteSettingsPage } from './home/site-dashboard/site-settings/site-settings-page/site-settings-page.component';
import { TimeLostReportComponent } from './admin/time-lost-report/time-lost-report.component';
import { DownloadStatusComponent } from './home/site-dashboard/download-status/download-status.component';
import { DeviceReportComponent } from './admin/device-report/device-report.component';
import { UserAuditComponent } from './admin/audit/user-audit/user-audit.component';
import { AuditComponent } from './admin/audit/audit.component';
import { PermissionAuditComponent } from './admin/audit/permission-audit/permission-audit.component';
import { AdminActionsComponent } from './admin/sched/admin-actions/admin-actions.component';
import { LandingPageComponent } from './users/landing-page/landing-page.component';
import { SchedRunComponent } from './admin/sched/sched-run/sched-run.component';
import { UploadFwFilesComponent } from './admin/upload-fw-files/upload-fw-files.component';
import { IccidChangesReportComponent } from './iccid-changes-report/iccid-changes-report.component';
import { UnblockLoginComponent } from './users/unblock-login/unblock-login.component';
import { ShipmentHistoryComponent } from './management/shipment-history/shipment-history.component';
import { SiteAttachmentsComponent } from './home/site-dashboard/site-attachments/site-attachments.component';
import { SimManagementComponent } from './admin/sim-management/sim-management.component';
import { SmartRebatesSitesComponent } from './admin/smart-rebates-sites/smart-rebates-sites.component';
import { DisconnectedDevicesComponent } from './admin/disconnected-device/disconnected-device.component';
import { DisconnectedSitesComponent } from './admin/disconnected-sites-report/disconnected-sites-report.component';
import { ManageOrdersComponent } from './management/orders/manage-orders/manage-orders.component';
import { EditOrderComponent } from './management/orders/edit-order/edit-order.component';
import { RmaComponent } from './management/rma/rma.component';
import { RmaProcessingComponent } from './management/rma-processing/rma-processing.component';
import { ReceivingShipmentHistoryComponent } from './management/receiving-shipment-history/receiving-shipment-history.component';
import { ReceivingShipmentHistoryResultComponent } from './management/receiving-shipment-history-result/receiving-shipment-history-result.component';
import { RegistrationReportsComponent } from './registration-reports/registration-reports.component';
import { ApisLogsComponent } from './admin/apis-logs/apis-logs.component';
import { UnusedLogsComponent } from './admin/logs/unused-logs/unused-logs.component';
import { EditDevicesOwnersComponent } from './admin/device/edit-devices-owners/edit-devices-owners.component';
import { ConnectivityVerificationLogComponent } from './admin/logs/connectivity-verification-log/connectivity-verification-log.component';
import { FakeDataGeneratorComponent } from './admin/fake-data-generator/fake-data-generator.component';
import { UpdateFirmwareLogsComponent } from './admin/logs/update-firmware-logs/update-firmware-logs.component';
import { simUsageManagementComponent } from './admin/sim-usage-management/sim-usage-management.component';
import { MissingSimsComponent } from './admin/missing-sims/missing-sims.component';
import { StudyComponent } from './study/study.component';
import { SiteStudiesComponent } from './home/site-dashboard/site-studies/site-studies.component';
import { DeviceQueueChangesComponent } from './management/device-queue-changes/device-queue-changes.component';
import { ConnectivityVerificationStatisticsComponent } from './admin/logs/connectivity-verification-statistics/connectivity-verification-statistics.component';
import { DeviceLocationMismatchReportComponent } from './admin/device-location-mismatch-report/device-location-mismatch-report.component';
import { BulkFillDeviceInfoComponent } from './home/site-dashboard/device/bulk_fill_device_info/bulk_fill_device_info.component';
import { IncorrectEventsComponent } from './admin/incorrect-events/incorrect-events.component';
import { MonitoringDevicesPageComponent } from './admin/monitoring-devices-page/monitoring-devices-page.component';
import { DisableExternalRtcComponent } from './management/disable-external-rtc/disable-external-rtc.component';
import { SuggestedInstallationDateReportComponent } from './noc-tools/suggested-installation-date-report/suggested-installation-date-report.component';
import { DisableXTRAFileComponent } from './management/disable-xtra-file/disable-xtra-file.component';
import { SearchDeviceEventsReportComponent } from './noc-tools/search-device-events-report/search-device-events-report.component';
import { RtRequestsHistoryComponent } from './management/rt-requests-history/rt-requests-history.component';
import { DefectiveDevicesPageComponent } from './noc-tools/defective-devices-page/defective-devices-page.component';
import { DebugLogsComponent } from './admin/debug-logs/debug-logs.component';
import { QuickViewHistoryComponent } from './noc-tools/quick-view-history/quick-view-history.component';
import { DevicesWithoutLocationComponent } from './management/devices-without-location/devices-without-location.component';
import { SitesGroupComponent } from './admin/sites-group/sites-group.component';
import { ConfigureStudyDevicesComponent } from './management/configure-study-devices/configure-study-devices.component';
import { AppVersionSettingComponent } from './admin/app-version-setting/app-version-setting.component';
import { IotahUtilizationReportComponent } from './home/site-dashboard/device/device-dashboard/utilization-reports/iotah-utilization-report/iotah-utilization-report.component';
import { CharglinkUtilizationReportComponent } from './home/site-dashboard/device/device-dashboard/utilization-reports/charglink-utilization-report/charglink-utilization-report.component';
import { BattlinkUtilizationReportComponent } from './home/site-dashboard/device/device-dashboard/utilization-reports/battlink-utilization-report/battlink-utilization-report.component';
import { CharglinkBrmComponent } from './site-dashboard/charglink-brm/charglink-brm.component';
import { CharglinkBrmPublicComponent } from './site-dashboard/charglink-brm-public/charglink-brm-public.component';
import { BeforeLoginLayoutComponent } from './layouts/before-login-layout/before-login-layout.component';
import { ContactUsFormComponent } from './users/contact-us-form/contact-us-form.component';
import { ResetPasswordFormComponent } from './users/reset-pasword-form/reset-pasword-form.component';

const routes: Routes = [
	{ path: '', pathMatch: 'full', redirectTo: 'sites-map'},
		{ path: '', component: SimpleLayoutComponent, canActivate: [AuthGuard], data: {noAuth: true}, children: [
			{ path: 'landing', component: LandingPageComponent},
			{ path: 'unblock-login', component: UnblockLoginComponent},
		]
	},
	{ path: 'charglink-brm/public/:token', component: CharglinkBrmPublicComponent},
	{
		path: '', component: BeforeLoginLayoutComponent, canActivate: [AuthGuard], data: {noAuth: true}, children: [
			{ path: 'login', component: LoginComponent },
			{ path: 'forgot-password', component: ForgotPasswordComponent },
			{ path: 'register', component: RegisterComponent },
			{ path: 'contact', component: ContactUsFormComponent },
			{ path: 'reset-password/:userId', component: ResetPasswordFormComponent },
		]
	},
	{ path: '', component: MainLayoutComponent, children: [

	{ path: 'unauthorized', component: UnauthorizedComponent },
	{ path: '404', component: PageNotFoundComponent },
	{ path: 'customers', component: CustomersComponent, canActivate: [AuthGuard] },
	{ path: 'smart-rebates-sites', component: SmartRebatesSitesComponent, canActivate: [AuthGuard]},
	{ path: 'contact-us', component: ContactUsComponent },
	{ path: 'confirm-user-email/:userId/:enc', component: ConfirmUserEmailComponent},
	{ path: 'non-confirmed-user-email/:userId', component: LoginNonConfirmedEmailComponent},
	{ path: 'user/edit/:userId', component: EditUserComponent, data: {isAdminEdit: true}, canActivate: [AuthGuard] },
	{ path: 'user/edit-settings', component: EditUserComponent, data: {isAdminEdit: false}, canActivate: [AuthGuard] },
	{ path: 'customers/edit/:customerId', component: CustomerEditPageComponent, canActivate: [AuthGuard] },
	{ path: 'enterprise', component: EnterpriseComponent, canActivate: [AuthGuard] },
	{ path: 'enterprise/edit/:enterpriseId', component: EnterpriseEditComponent, canActivate: [AuthGuard] },
	{ path: 'sites/:customerId', component: SitesComponent, canActivate: [AuthGuard] },
	{ path: 'zombie-sites/:customerId', component: ZombieSitesComponent, canActivate: [AuthGuard] },
	{ path: 'sites/:customerId/edit/:siteId', component: SiteEditPageComponent, canActivate: [AuthGuard] },
	{ path: 'devices/:customerId/:siteId', component: DeviceListingAdminComponent, canActivate: [AuthGuard] },
	{ path: 'devices/:customerId/:siteId/edit/:deviceId', component: DeviceEditPageComponent, canActivate: [AuthGuard] },
	{ path: 'device/search', component: SearchDevicesComponent },
	{ path: 'device/management/:deviceId', component: ManageDevicePageComponent, canActivate: [AuthGuard] },
	{ path: 'device/management-request/:deviceId', component: ManageDeviceRequestComponent, canActivate: [AuthGuard] },
	{ path: 'change-user-password', component: EditUserPasswordComponent, canActivate: [AuthGuard] },
	{ path: 'change-expired-password', component: EditUserPasswordComponent, data: {pageName: "force_change"} },
	{ path: 'cookie-policy', component: CookiePolicyComponent },
	{ path: 'terms-and-conditions', component: TermsAndConditionsComponent, data: {hideItems: true} },
	{ path: 'admin-panel', component: AdminComponent, canActivate: [AuthGuard], children: [
		{ path: 'new-device', component: DeviceEditPageComponent, data: {cmDevice: true}},
		{ path: 'sim-usage-management/:pageName', component: simUsageManagementComponent, data: {cmDevice: true}},
		{ path: 'missing_from_1nce', component: MissingSimsComponent, data: {cmDevice: true}},
		{ path: 'edit-devices-owners', component: EditDevicesOwnersComponent },
		{ path: 'cm-devices', component: DeviceListingAdminComponent, data: {cmDevice: true}},
		{ path: 'cm-devices/edit/:deviceId', component: DeviceEditPageComponent, data: {cmDevice: true}},
		{ path: 'logs/error-logs', component: ErrorLogComponent },
		{ path: 'logs/error-logs/:id', component: ViewErrorComponent },
		{ path: 'logs/unused-logs', component: UnusedLogsComponent },
		{ path: 'logs/update-firmware-logs', component: UpdateFirmwareLogsComponent },
		{ path: 'sched/sched-control', component: SchedControlComponent },
		{ path: 'sched/sched-monitor', component: SchedMonitorComponent },
		{ path: 'sched/sched-run', component: SchedRunComponent },
		{ path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
		{ path: 'contact-us', component: ContactUsAdminComponent},
		{ path: 'contact-us/:id', component: ViewContactUsAdminComponent},
		{ path: 'apis-logs', component: ApisLogsComponent},
		{ path: 'users/edit-access-functions/:userId', component: EditAccessFunctionsComponent},
		{ path: 'permission-edit/:permissionId', component: PermissionEditComponent },
		{ path: 'logs/rejected-device-connection', component: RejectedDeviceConnectionComponent },
		{ path: 'logs/action-logs', component: ActionLogComponent },
		{ path: 'validate-registration-request', component: RegistrationRequestsComponent },
		{ path: 'validate-registration-request/:id', component: ValidateRegistrationRequestsComponent },
		{ path: 'warnings', component: WarningsComponent },
		{ path: 'disconnected-device', component: DisconnectedDevicesComponent},
		{ path: 'disconnected-site', component: DisconnectedSitesComponent},
		{ path: 'time-lost-report', component: TimeLostReportComponent },
		{ path: 'device-report', component: DeviceReportComponent },
		{ path: 'incorrect-event', component: IncorrectEventsComponent },
		{ path: 'connectivity-verification-log', component: ConnectivityVerificationLogComponent},
		{ path: 'connectivity-verification-statistics', component: ConnectivityVerificationStatisticsComponent},
		{ path: 'connectivity-verification-log/:reportId', component: ConnectivityVerificationLogComponent},
		{ path: 'site-groups', component: SitesGroupComponent},
		{ path: 'audit', component: AuditComponent, canActivate: [AuthGuard], data: {pageName: 'user'}, children: [
			{ path: '', component: UserAuditComponent}, //user
			{ path: 'permission', component: PermissionAuditComponent, data: {pageName: 'permission'}},
		] },
		{ path: 'admin-actions', component: AdminActionsComponent },
		{ path: 'upload-fw-files', component: UploadFwFilesComponent },
		{ path: 'iccid-changes-report', component: IccidChangesReportComponent},
		{ path: 'sim-management', component: SimManagementComponent},
		{ path: 'data-generate', component: FakeDataGeneratorComponent},
		{ path: 'device-location-mismatch-report', component: DeviceLocationMismatchReportComponent },
		{ path: 'bulk_fill_device_info/:customerId/:siteId', component: BulkFillDeviceInfoComponent},
		{ path: 'monitoring-devices', component: MonitoringDevicesPageComponent},
		{ path: 'device-queue-changes', component: DeviceQueueChangesComponent, canActivate: [AuthGuard] },
		{ path: 'debug-logs', component: DebugLogsComponent, canActivate: [AuthGuard] },
		{ path: 'app-version-settings', component: AppVersionSettingComponent, canActivate: [AuthGuard] },
	]},
	{ path: 'become-user/:token', component: BecomeUserComponent },

	{ path: 'orders/manage', component: ManageOrdersComponent, canActivate: [AuthGuard] },
	{ path: 'orders/:action', component: EditOrderComponent, canActivate: [AuthGuard] },
	{ path: 'orders/:action/:id', component: EditOrderComponent, canActivate: [AuthGuard] },
	{ path: 'management/firmware-update', component: FirmwareUpdateComponent, canActivate: [AuthGuard] },
	{ path: 'management/disable_external_rtc', component: DisableExternalRtcComponent, canActivate: [AuthGuard] },
	{ path: 'management/disable-xtra-file', component: DisableXTRAFileComponent, canActivate: [AuthGuard] },
	{ path: 'management/rma', component: RmaComponent, canActivate: [AuthGuard] },
	{ path: 'management/rma-processing', component: RmaProcessingComponent, canActivate: [AuthGuard] },
	{ path: 'management/receiving-shipment', component: ReceivingShipmentComponent, canActivate: [AuthGuard] },
	{ path: 'management/receiving-shipment-history', component: ReceivingShipmentHistoryComponent, canActivate: [AuthGuard]},
	{ path: 'management/receiving-shipment-history/:reportId', component: ReceivingShipmentHistoryResultComponent, canActivate: [AuthGuard]},
	{ path: 'management/shipment-quality', component: ShipmentQualityComponent, canActivate: [AuthGuard] },
	{ path: 'management/shipment-quality-history', component: ReceivingShipmentHistoryComponent, canActivate: [AuthGuard]},
	{ path: 'management/shipment-quality-history/:reportId', component: ReceivingShipmentHistoryResultComponent, canActivate: [AuthGuard]},
	{ path: 'management/shipping-out', component: ShippingOutComponent, canActivate: [AuthGuard] },
	{ path: 'management/shipment-history', component: ShipmentHistoryComponent, canActivate: [AuthGuard]},
	{ path: 'management/configure-ps-devices', component: ConfigureStudyDevicesComponent, canActivate: [AuthGuard]},
	{ path: 'management/blocked-fw-reports', component: BlockedFwReportsComponent, canActivate: [AuthGuard] },
	{ path: 'management/devices-without-location', component: DevicesWithoutLocationComponent, canActivate: [AuthGuard] },
	{ path: 'registration-reports', component: RegistrationReportsComponent, canActivate: [AuthGuard] },
	{ path: 'management/device-management-requests', component: ManagementRequestsComponent, canActivate: [AuthGuard], data: {pageName: 'devices'}, children: [
		{ path: '', component: DeviceRequestsComponent}, //devices
		{ path: 'customers', component: CustomerRequestsComponent, data: {pageName: 'customers'}},
		{ path: 'sites', component: SiteRequestsComponent, data: {pageName: 'sites'}},
		{ path: 'replacement', component: DeviceReplacementRequestsComponent, data: {pageName: 'replacement'}},
		{ path: 'customer-edit/:customerId', component: CustomerRequestEditComponent},
		{ path: 'site-edit/:siteId', component: SiteRequestEditComponent},
	] },
	{ path: 'management/studies', component: StudyComponent, canActivate: [AuthGuard] },

	{ path: 'noc-tools/suggested-installation-date-report', component: SuggestedInstallationDateReportComponent, canActivate: [AuthGuard] },
	{ path: 'noc-tools/search-device-events-report', component: SearchDeviceEventsReportComponent, canActivate: [AuthGuard]},
	{ path: 'noc-tools/defective-devices', component: DefectiveDevicesPageComponent, canActivate: [AuthGuard]},

	{ path: 'noc-tools/rt-requests-history', component: RtRequestsHistoryComponent, canActivate: [AuthGuard] },
	{ path: 'noc-tools/rt-requests-history/:deviceId', component: RtRequestsHistoryComponent, canActivate: [AuthGuard] },
	{ path: 'noc-tools/quick-view-history/:deviceId', component: QuickViewHistoryComponent, canActivate: [AuthGuard]},

	{ path: 'permissions', canActivate: [AuthGuard], children: [
		{ path: ':permissionType', component: PermissionsComponent },
		{ path: ':permissionType/edit/:permissionId', component: PermissionsEditComponent },
	] },

	{ path: '', component: HomeComponent, canActivate: [AuthGuard], children: [
		{ path: 'sites-map', component: SitesMapComponent},
		{ path: ':customerId', component: CustomerInfoComponent },
		{ path: ':customerId/:siteId', component: SiteDashboardComponent, data: {'pageName': 'site-info'}, children: [
			{ path: '', component: SiteInfoComponent},
			{ path: 'devices-listing', component: DevicesListingComponent, data: {'pageName': 'devices-listing'}},
			{ path: 'performance', component: SitePerformanceComponent, data: {'pageName': 'site-performance'}},
			{ path: 'chargers-performance', component: SitePerformanceComponent, data: {'pageName': 'site-chargers-performance'}},
			{ path: 'site-utilization-iotah', component: IotahUtilizationReportComponent, data: {'pageName': 'site-utilization-iotah'}},
			{ path: 'site-utilization-charglink', component: CharglinkUtilizationReportComponent, data: {'pageName': 'site-utilization-charglink'}},
			{ path: 'site-utilization-battlink', component: BattlinkUtilizationReportComponent, data: {'pageName': 'site-utilization-battlink'}},
			{ path: 'site-notes', component: SiteNotesComponent, data: {'pageName': 'site-notes'}},
			{ path: 'site-attachments', component: SiteAttachmentsComponent, data: {'pageName': 'site-attachments'}},
			{ path: 'tags', component: TagsManagementComponent, data: {'pageName': 'tags-management'}},
			{ path: 'maintenance-report', component: MaintenanceReportComponent, data: {'pageName': 'maintenance-report'}},
			{ path: 'working-days', component: SiteSettingsPage, data: {'pageName': 'working-days'}},
			{ path: 'site-warnings', component: SiteWarningsComponent, data: {'pageName': 'site-warnings'}},
			{ path: 'site-warranty', component: SiteWarrantyComponent, data: {'pageName': 'site-warranty'}},
			{ path: 'charglink-brm', component: CharglinkBrmComponent, data: {'pageName': 'charglink-brm'}},
			{ path: 'charglink-brm/site-history', component: CharglinkBrmComponent, data: {'pageName': 'site-history'}},
			{ path: 'charglink-brm/brm-groups', component: CharglinkBrmComponent, data: {'pageName': 'brm-groups'}},
			{ path: 'site-studies', component: SiteStudiesComponent, data: {'pageName': 'site-studies'}},
			{ path: 'download-status', component: DownloadStatusComponent, data: {'pageName': 'site-info'} },
			{ path: ':deviceId', pathMatch: 'full', redirectTo: ':deviceId/performance'},
			{ path: ':deviceId/settings', component: DeviceDashboardComponent,  data: {'pageName': 'settings'} },
			{ path: ':deviceId/connectivity-track', component: DeviceDashboardComponent, data: {'pageName': 'connectivity-track'} },
			{ path: ':deviceId/actions-history', component: DeviceDashboardComponent, data: {'pageName': 'actions-history'} },
			{ path: ':deviceId/warning-settings', component: DeviceDashboardComponent, data: {'pageName': 'warning-settings'} },
			{ path: ':deviceId/quick-view', component: DeviceDashboardComponent, data: {'pageName': 'quick-view'} },
			{ path: ':deviceId/config-track', component: DeviceDashboardComponent, data: {'pageName': 'config-track'} },
			{ path: ':deviceId/reverse-calibration', component: DeviceDashboardComponent, data: {'pageName': 'reverse-calibration'} },
			{ path: ':deviceId/reverse-rt', component: DeviceDashboardComponent, data: {'pageName': 'reverse-rt'} },
			{ path: ':deviceId/force-read-debug', component: DeviceDashboardComponent, data: {'pageName': 'force-read-debug'} },
			{ path: ':deviceId/update-device-fw', component: DeviceDashboardComponent, data: {'pageName': 'update-device-fw'} },
			{ path: ':deviceId/replace-device', component: DeviceDashboardComponent, data: {'pageName': 'replace-device'} },
			{ path: ':deviceId/device-admin', component: DeviceDashboardComponent, data: {'pageName': 'device-admin'} },
			{ path: ':deviceId/cellular-settings', component: DeviceDashboardComponent, data: {'pageName': 'cellular-settings'} },
			{ path: ':deviceId/cellular-track', component: DeviceDashboardComponent, data: {'pageName': 'cellular-track'} },
			{ path: ':deviceId/manage-device', component: DeviceDashboardComponent, data: {'pageName': 'manage-device'} },
			{ path: ':deviceId/performance', component: DeviceDashboardComponent, data: {'pageName': 'performance'} },
			{ path: ':deviceId/events', component: DeviceDashboardComponent, data: {'pageName': 'events'} },
			{ path: ':deviceId/daily-details', component: DeviceDashboardComponent, data: {'pageName': 'daily-details'} },
			{ path: ':deviceId/rt-records', component: DeviceDashboardComponent, data: {'pageName': 'rt-records'} },
			{ path: ':deviceId/reverse-events', component: DeviceDashboardComponent, data: {'pageName': 'reverse-events'} },
			{ path: ':deviceId/global-records', component: DeviceDashboardComponent, data: {'pageName': 'global-records'} },
			{ path: ':deviceId/debug-records', component: DeviceDashboardComponent, data: {'pageName': 'debug-records'} },
			{ path: ':deviceId/performance/:studyId', component: DeviceDashboardComponent, data: { 'pageName': 'performance'} },
			{ path: ':deviceId/events/:studyId', component: DeviceDashboardComponent, data: {'pageName': 'events'} },
			{ path: ':deviceId/rt-records/:studyId', component: DeviceDashboardComponent, data: {'pageName': 'rt-records'} },
			{ path: ':deviceId/daily-details/:studyId', component: DeviceDashboardComponent, data: {'pageName': 'daily-details'} },
			{ path: ':deviceId/reverse-events/:studyId', component: DeviceDashboardComponent, data: {'pageName': 'study-reverse-events'} },
			{ path: ':deviceId/set-adc', component: DeviceDashboardComponent, data: {'pageName': 'set-adc'}},
			{ path: ':deviceId/actions',  component: DeviceDashboardComponent, data: {'pageName': 'actions'} },
			{ path: ':deviceId/device-notes', component: DeviceDashboardComponent, data: {'pageName': 'device-notes'} },
			{ path: ':deviceId/official-reporting', component: DeviceDashboardComponent, data: {'pageName': 'official-reporting'}},
			{ path: ':deviceId/device-attachments', component: DeviceDashboardComponent, data: {'pageName': 'device-attachments'} },
			{ path: ':deviceId/charger-prediction/:studyId', component: DeviceDashboardComponent, data: {'pageName': 'charger-prediction'}}
		]},
	]},
	{ path: '**', redirectTo: '404' }
	] },
];



@NgModule({
	imports: [RouterModule.forRoot(routes,{ useHash: true, preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
