import { Directive, Input, OnInit, ElementRef, Renderer2, OnChanges } from '@angular/core';
import { UsersService } from 'src/app/users/users.service';

@Directive({
	selector: '[check-permission]'
})
export class CheckPermissionDirective implements OnInit, OnChanges {

	@Input('check-permission') fieldName: string;
	@Input('params') params: any;

	constructor(
		private el: ElementRef,
		private userService: UsersService,
		private renderer: Renderer2
	) {
	}

	ngOnChanges(changes) {
		if(changes.params)
			this.checkUserPermissions();
	}

	ngOnInit() {
	}

	checkUserPermissions() {
		let accessType = this.userService.checkFieldPermissions(this.fieldName, this.params.permissions, this.params.permissionMapperKey);
		if(!accessType || accessType == 'noAccess')
			this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
		else if(accessType == 'read')
			this.renderer.setProperty(this.el.nativeElement, 'disabled', 'disabled');
		else //write
			this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
	}
}