
<div class="main-black border-radius-bottom white-box m-0 p-4 page-content">
	<div class="mb-3 d-flex justify-content-between align-items-center">
		<span *ngIf="!isLoggedIn"></span>
		<button class="btn btn-default" *ngIf="isLoggedIn" (click)="goBack()"><i class="fa fa-lg pointer fa-arrow-left main-blue" title="Back"></i><span class="ml-2">{{'g.back' | translate}}</span></button>
		<button class="btn btn-primary" (click)="toggleBuzzer()">{{(buzzerEnabled ? 'charglink_brm.disable_buzzer' : 'charglink_brm.enable_buzzer') | translate}}</button>
	</div>

	<div class="page-haeder">
		<img src="/sctportal/images/BRM/brm-logo.png" alt="charglink BRM">
	</div>

	<div class="d-flex justify-content-start flex-wrap" *ngIf="!isExpiredToken">
		<div class="group-box mx-3" *ngFor="let group of groups">
			<div class="header">
				<h4>{{group.name}}</h4>
			</div>
			<div class="body" [class.no-battery]="!group.location">
				<div class="d-flex justify-content-between align-items-center px-3">
					<div class="img-container" *ngIf="group.location">
						<img src="/sctportal/images/BRM/ready-charger.png" alt="battery">

					</div>
					<div class="location" *ngIf="group.location">
						<p>{{"charglink_brm.location" | translate}}</p>
						<p>{{group.location}}</p>
					</div>
					<div *ngIf="!group.location">
						<p><b>{{"charglink_brm.no_ready_to_pick" | translate}}</b></p>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="isExpiredToken" class="alert alert-danger" role="alert">
		<span>{{'charglink_brm.expired_token' | translate}}</span>
	</div>
</div>
