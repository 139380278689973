import { Component, OnInit, Input, OnChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { DeviceService } from '../../device.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { UsersService } from 'src/app/users/users.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderInterceptorService } from 'src/app/shared/loader/loader-interceptor.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceFactory } from '../../device-factory/device-factory';
@Component({
	selector: 'app-warning-settings',
	templateUrl: './warning-settings.component.html',
})
export class warningSettingsComponent implements OnInit, OnChanges {
	@Input() device: any = {};
	@Input() enterprisePermissions: any = {};
	@ViewChild("saveOptionsModal") saveOptionsModal;
	@Output() updateDeviceAttributes = new EventEmitter<any>();
	@Output() onDeviceDataChange = new EventEmitter<Object>();

	deviceSettingsFormFields: any;
	validationRules: any;
	oldDevice: any = {};
	dirtyFields: any = {};

	constructor(
		private deviceService: DeviceService,
		public usersService: UsersService,
		private router: Router,
		private commonUtil: CommonService,
		private loader: LoaderInterceptorService,
		private notificationMessage: NotificationMessageService,
		private translate: TranslateService,
		private route: ActivatedRoute,
	) { }

	ngOnInit() {
		const fullPath = this.route.snapshot['_routerState'].url;
		const pathSegments = fullPath.split('/');
		const siteId = pathSegments[2];

		this.usersService.getPermissions(siteId).subscribe((permissions: any) => {
			this.enterprisePermissions = permissions;
			if(!this.usersService.getCurrentUser().is_admin || !this.usersService.hasAccessPermission(this.enterprisePermissions, 'noc'))
				return this.router.navigate(['/unauthorized']);

		});

		this.prepareDeviceSettingsForm();
	}

	prepareDeviceSettingsForm() {
		const deviceConfig = DeviceFactory.createDevice(this.device.config_info.sct_device_type);

		this.deviceSettingsFormFields = [
			{
				name: 'max_current',
				label: 'devices.max_current',
				permission: 'max_current',
				type: 'number',
				validation: this.validationRules.max_current,
				tooltip: 'max_current',

			},
			{
				name: 'max_ah',
				label: 'devices.max_ah',
				permission: 'max_ah',
				type: 'number',
				validation: this.validationRules.max_ah,
				tooltip: 'max_ah'
			},
		];

		if (deviceConfig.config.supportIncorrectEvent){
			this.deviceSettingsFormFields.push({
				name: 'incorrect_event_percentage_limit',
				label: 'devices.incorrect_event_percentage_limit',
				permission: 'incorrect_event_percentage_limit',
				type: 'number',
				validation: this.validationRules.incorrect_event_percentage_limit
			});
		}
	}

	prepareValidationRules() {
		this.validationRules = this.commonUtil.getValidationRules();
	}

	ngOnChanges() {
		if (this.device.config_info) {
			this.initVariables();
			this.prepareValidationRules();
			this.prepareDeviceSettingsForm();
		}
	}

	checkChanges(formModels) {
		let dirtyFields	= {};
		let arrayFields	= [];
		let freshDevice	= this.device.config_info;
		let oldDevice	= this.oldDevice;

		for(let model in formModels) {
			let originalModelName = model;

			let oldValue = oldDevice[originalModelName];
			let newValue = freshDevice[model];

			if(arrayFields.indexOf(model) != -1) {
				if(oldValue && newValue && !this.commonUtil.arrayCompare(oldValue, newValue)) {
					dirtyFields[model] = newValue;
				}
			} else if(oldValue != newValue) {
				dirtyFields[originalModelName] = newValue;
			}
		}

		this.resetForms();

		if(Object.keys(dirtyFields).length > 0) {
			this.dirtyFields = dirtyFields;
			this.saveOptionsModal.show();
		}
	}

	resetForms() {
		this.dirtyFields = {};
	}

	saveDeviceSettings(stage) {
		let originalStage = stage;
		if(this.usersService.hasAccessFunction('allow_device_db_save') && this.saveOptionsModal.render)
			this.saveOptionsModal.hide();

		if(stage == 'cancel')
			return;

		let dirtyFields	= this.dirtyFields;
		let wait = 0;
		let mac_address	= this.device.mac_address;
		let options: any = {};

		this.deviceService.saveDeviceSettings(mac_address, dirtyFields, stage, options, wait).subscribe((response: any) => {
			this.handleSaveResponse(response, originalStage, dirtyFields, mac_address);
		});
	}

 	handleSaveResponse(response, stage, dirtyFields, deviceId?) {
		const apiStatus = response.api_status;

		if (response.response_status == this.loader.resStatus.api_timeout || apiStatus == this.deviceService.deviceCommandsResponse) { // if the api timed-out OR if wait sent and the device is disconnected.
			this.notificationMessage.setMessage('translate|settings.queue_and_wait_timeout_msg', {clearOnXTimeNavigate: 1, type: 'warning'});
			response = response.response;
		} else {
			if(response.invalid_fields)
				return this.notificationMessage.setMessage(this.translate.instant('g.invalid_input'), {clearOnXTimeNavigate: 1});
			this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
		}

		this.device.config_info = response.config_info;
		this.initVariables();

		// update select device options
		if (stage == 'direct' || (stage == 'queue_and_wait' && apiStatus != this.deviceService.deviceCommandsResponse)) {
			this.updateDeviceAttributes.emit({deviceId, updatedDeviceData: dirtyFields});
			this.onDeviceDataChange.emit(this.device); // to update device config data
		}
	}

	initVariables() {
		this.oldDevice = JSON.parse(JSON.stringify(this.device));
		this.deviceService.formatDeviceFields(this.device);
	}
}
