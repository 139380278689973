import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { SitesService } from 'src/app/sites/sites.service';
import { CommonService } from './../../shared/services/common.service';
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import _ from 'underscore';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { BreadcrumbService } from 'src/app/shared/breadcrumb/breadcrumb.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { DomainsService } from 'src/app/shared/services/domains.service';


@Component({
	selector: 'app-device-report',
	templateUrl: './device-report.component.html',
	styleUrls: ['./device-report.component.css']
})
export class DeviceReportComponent implements OnInit, AfterViewInit, OnDestroy {
	isCollapsed: {
		site: boolean,
		warnings: boolean,
		version: boolean,
		dates : boolean,
		configs: boolean,
	} = {
		site: true,
		warnings: true,
		version: true,
		dates : true,
		configs: false
	};

	model: {
		hasSite: boolean,
		selectedCustomersNegation: boolean,
		selectedCustomers: number[],
		selectedSites: number[],
		selectedEnterpriseNegation: boolean,
		selectedEnterprise: number[],
		fwVersionOperation: string,
		warningStatus: string,
		fwVersion: number,
		hwReversion: number,
		selectedWarnings: number[],
		truckManufacturingYearOperation: string,
		truckManufacturingYear: number | null,
		installationDateOperation: string,
		installationDate: Date,
		fromWarningsDate: Date,
		toWarningsDate: Date,
		hwReversionOperation: string,
		disconnectedDevicesCriteria: number,
		serviceNote: string
		userTimeZoneDiff: number
	} = {
		hasSite: true,
		selectedCustomersNegation: false,
		selectedCustomers: [],
		selectedSites: [],
		selectedEnterpriseNegation: false,
		selectedEnterprise: [],
		selectedWarnings: [],
		fwVersionOperation: '',
		warningStatus: '',
		fwVersion: null,
		hwReversion: null,
		truckManufacturingYearOperation: '',
		truckManufacturingYear: null,
		installationDateOperation: '',
		installationDate: null,
		fromWarningsDate: null,
		toWarningsDate: null,
		hwReversionOperation: '',
		disconnectedDevicesCriteria: 168,
		serviceNote: '',
		userTimeZoneDiff: new Date().getTimezoneOffset() * -1
	};

	customers: any[] = [];
	customersSites: Object;
	sites: any[] = [];
	allSites: any[];
	enterprises: any[] = [];
	warnings: {id: string, value: string}[] = this.deviceService.getWarningOptionsList();
	reportName: string = null;
	readonly disconnectedDevicesCriteriaMin = 24;

	@ViewChild("modal") modal;

	constructor(
		private route: ActivatedRoute,
		private deviceManagementService: DeviceManagementService,
		private breadcrumb: BreadcrumbService,
		private notificationMessage: NotificationMessageService,
		private deviceService: DeviceService,
		private translateService: TranslateService,
		private sitesService:  SitesService,
		private commonService: CommonService,
		public domainsService: DomainsService,
	) {
		this.breadcrumb.setBreadcrumb([
			{label: this.translateService.instant('device.charger_search'), url: ''}
		]);
	}

	ngOnInit() {
		const warningsArray = this.deviceService.getWarningsKeys();
		warningsArray.forEach(warning => {
			this.warnings.push({id: warning, value: this.translateService.instant(`warnings.${warning}`)})
		})

		this.route.queryParams.subscribe(params => {
			if(params.isExpired){
				this.notificationMessage.setMessage('translate|g.file_has_been_deleted', {clearOnXTimeNavigate: 1, timeout: 20000, type: 'warning'});
			}
			if(params.enc){
				this.deviceManagementService.prepareFileDownload('too_big_report', params.enc).subscribe((data:any)=>{
					window.open('/reporting/downloadFile?enc='+encodeURIComponent(data.file), '_self');
				});
			}
		});
		this.sitesService.getUserSitesInfo({get_customers: true, getEnterprises: true}).subscribe(
			(data: any) => {
				this.customers = _.map(data.customers, (item) => ({id: item.id, customer_name: item.customer_name}));
				this.customersSites = _.chain(data.customers).pluck('sites').flatten(true).groupBy('customer_id').value();
				this.sites = _.chain(data.customers).pluck('sites').flatten(true).value();
				this.allSites = this.sites;
				this.enterprises = data.enterprises;
			}
		);
	}

	ngAfterViewInit() {
		this.modal.onClose.subscribe(
			(ok) => {
				if(ok) {
					this.scheduleReport();
				}
			}
		);
	}

	updateSitesAvailable() {
		this.sites = [];

		if(this.model.selectedCustomersNegation) {
			for(const customerId in this.customersSites) {
				if (this.model.selectedCustomers.indexOf((+customerId)) === -1) {
					this.sites = this.sites.concat(this.customersSites[customerId]);
				}
			}
		} else {
			if (this.model.selectedCustomers.length === 0) {
				_.extend(this.sites, this.allSites);
			} else {
				for(const customerId of this.model.selectedCustomers) {
					this.sites = this.sites.concat(this.customersSites[customerId]);
				}
			}
		}

		if (this.sites.length === 0)
			return;

		// remove any selected site not exists in list of sites (this.sites)
		const siteIds = _.pluck( this.sites, 'id');
		const selectedSites = [];
		for(const selectedSite of this.model.selectedSites) {
			if(siteIds.indexOf(selectedSite) > -1){
				selectedSites.push(selectedSite);
			}
		}
		this.model.selectedSites = selectedSites;
	}

	scheduleReport() {
		if (!this.reportName)
			return this.notificationMessage.setMessage(this.translateService.instant('users.please_enter_report_name'), {clearOnXTimeNavigate: 1});

		if (this.reportName.trim().length === 0)
			return this.notificationMessage.setMessage(this.translateService.instant('g.invalid_input'), {clearOnXTimeNavigate: 1});

		const model: any = JSON.parse(JSON.stringify(this.model));
		model.reportName = this.reportName;
		model.state = 'device';

		if (model.installationDate) {
			model.installationDate = this.commonService.getUnixDateByUTCZone(model.installationDate);
		}

		if (model.fromWarningsDate) {
			model.fromWarningsDate = this.commonService.getUnixDateByUTCZone(model.fromWarningsDate);
		}

		if (model.toWarningsDate) {
			model.toWarningsDate = this.commonService.getUnixDateByUTCZone(model.toWarningsDate);
		}

		this.deviceManagementService.scheduleReport(model).subscribe(
			(res) => {
				this.reportName = '';
				this.notificationMessage.setMessage(this.translateService.instant('users.the_report_will_be_processed_and_sent_to_your_email'), {clearOnXTimeNavigate: 1, type: 'success', timeout: 20000});
			}
		);
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}

	showSubmitModal() {
		if (!this.model.disconnectedDevicesCriteria && this.model.disconnectedDevicesCriteria != 0)
			return this.notificationMessage.setMessage(this.translateService.instant('device_report.disconnected_devices_criteria_required'), {clearOnXTimeNavigate: 1, type: 'warning', timeout: 20000});

		if (this.model.disconnectedDevicesCriteria < this.disconnectedDevicesCriteriaMin)
			return this.notificationMessage.setMessage(this.translateService.instant('device_report.disconnected_devices_criteria_min'), {clearOnXTimeNavigate: 1, type: 'warning', timeout: 20000});

		this.modal.show()
	}

	restrictToIntegers(event: Event): void {
		const inputElement = event.target as HTMLInputElement;
		const originalValue = inputElement.value;
		const numericValue = originalValue.replace(/\D/g, '');

		inputElement.value = numericValue;
		this.model.disconnectedDevicesCriteria = +numericValue;
	}
}
