import { Component, OnInit, OnDestroy, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from 'src/app/users/users.service';
import { SiteDashboardService } from './site-dashboard.service';
import * as _ from 'underscore';
import { Subscription } from 'rxjs';
import { StudiesService } from 'src/app/study/studies.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { SitesService } from 'src/app/sites/sites.service';
import { DomainsService } from 'src/app/shared/services/domains.service';

@Component({
	selector: 'app-site-dashboard',
	templateUrl: './site-dashboard.component.html',
	styleUrls: ['./site-dashboard.component.css']
})
export class SiteDashboardComponent implements OnInit, OnDestroy {
	@ViewChild('cardContainer', { static: false }) cardContainer: ElementRef;
	brmLink: any[] = [];
	doneScrolling: boolean = false;
	theUIInitDone: boolean = false;
	customerId = 0;
	siteId = 0;
	customer: any = {};
	site: any = {};
	enterprisePermissions: any = {};
	activeTab: string;
	currentUser: any = {};
	useShowCharts: boolean = true;
	isShowCustomer: any = false;

	showCustomers = [1, 2211, 2077, 1990];
	stagingShowCustomers = [467];
	productionShowCustomers = [1, 528];

	dataSub: any;
	data = {};
	permissionSub: Subscription = new Subscription();
	isChargLinkDomain: boolean = false;
	sitePages: any[] = [
		'site-info',
		'devices-listing',
		'site-performance',
		'site-chargers-performance',
		'site-notes',
		'site-warnings',
		'tags-management',
		'maintenance-report',
		'site-warranty',
		'working-days',
		'site-attachments',
		'site-studies',
		'site-utilization-iotah',
		'site-utilization-charglink',
		'site-utilization-battlink',
		'charglink-brm'
	];

	site_has_iotah_devices: boolean = false;
	site_has_chargLink_devices: boolean = false;
	site_has_battLink_devices: boolean = false;
	site_has_brm_devices: boolean = false;
	hasStudies: boolean = false;
	showChargLinkBRMOptions: boolean = false;
	activeCharglinkBRMOption: string = '';

	constructor(
		public usersService: UsersService,
		public siteDashboardService: SiteDashboardService,
		public commonService: CommonService,
		private route: ActivatedRoute,
		private sideMenuService: SideMenuService,
		private router: Router,
		private studiesService: StudiesService,
		private sitesService: SitesService,
		private domainsService: DomainsService,
	) { }

	ngOnInit() {
		this.currentUser = this.usersService.getCurrentUser();
		this.isChargLinkDomain = this.domainsService.isChargLink;
		this.siteDashboardService.activePage = (this.sitePages.includes(this.route.snapshot.firstChild.data.pageName) ? this.route.snapshot.firstChild.data.pageName : 'device-dash');
		this.siteId = parseInt(this.route.snapshot.params['siteId']);

		if (!this.siteId || isNaN(this.siteId))
			return this.router.navigateByUrl('/404');

		this.dataSub = this.sideMenuService.data.subscribe((data:any) => {
			if (data && data.length > 0) {
				this.customerId = parseInt(this.route.snapshot.params['customerId']);
				this.isShowCustomer = this.showUtilizationCharts();

				if (!_.isEqual(this.data, data)) {
					this.data = data;
					this.setGlobalSite(data);
					this.getEnterprisePermission();
				}
			}
		});
		this.siteHasIotahOrChargeLinkDevices();
		this.getBrmLink();

		if (this.currentUser.has_access_to_any_study)
			this.checkHasStudies();
	}

	ngAfterViewChecked(): void {
		if (!this.doneScrolling && this.theUIInitDone && this.cardContainer?.nativeElement?.querySelector('.active'))
			this.doneScrolling = this.commonService.scrollToActiveLink(this.cardContainer);
	}

	onActivate(activeComponent) {
		let tab = '';
		let componentName = activeComponent.constructor.name;

		switch(componentName) {
			case 'SiteInfoComponent':
				tab = 'site-info';
				break;
			case 'DevicesListingComponent':
				tab = 'devices-listing';
				break;
			case 'DeviceDashboardComponent':
				tab = 'analytics-settings';
				break;
			case 'SiteNotesComponent':
				tab = 'site-notes';
				break;
			case 'TagsManagementComponent':
				tab = 'tags-management';
				break;
			case 'MaintenanceReportComponent':
				tab = 'maintenance-report';
				break;
			case 'SiteWarrantyComponent':
				tab = 'site-warranty';
				break;
			case 'SiteSettingsPage':
				tab = 'site-settings-page';
				break;
			case 'SiteAttachmentsComponent':
				tab = 'site-attachments';
				break;
			case 'SiteStudiesComponent':
				tab = 'site-studies';
				break;
			case 'CharglinkBrmComponent':
				tab = 'charglink-brm';
				break;
			default:
				break;
		}

		this.activeTab = tab;
	}

	getEnterprisePermission() {
		this.permissionSub = this.usersService.getPermissions(this.siteId).subscribe((permissions: any) => {
			this.enterprisePermissions = permissions;
			this.siteDashboardService.setPermissionsOfCurrentSite(permissions);
		});
	}

	setGlobalSite(data) {
		const customers = _.indexBy(data, "id");
		this.customer = customers[this.customerId];

		if (!this.customer)
			return this.redirectToRightCustomerId(data);

		this.sideMenuService.setCurrentCustomer(customers[this.customerId]);
		let sites = {};
		if(this.customer) {
			sites = _.indexBy(this.customer.sites, "id");
			this.site = sites[this.siteId]
			if (!this.site)
				return this.redirectToRightCustomerId(data);
		}
		this.sideMenuService.setCurrentSite(sites[this.siteId] || {});
	}

	redirectToRightCustomerId(data) {
		let customerId, siteId, child, queryParams;

		for (let customer of data) {
			for (let site of customer.sites) {
				if (site.id == this.siteId) {
					customerId = customer.id;
					siteId = site.id;
					child = this.route.firstChild.routeConfig.path;
					if (child) {
						queryParams = this.route.firstChild.snapshot.queryParams;
						let deviceId = this.route.firstChild.snapshot.params['deviceId'];
						if (deviceId)
							child = child.replace(":deviceId", deviceId);
					}
					break;
				}
			}
		}
		if (!customerId || !siteId)
			return;

		if (!child)
			return this.router.navigate(['/', customerId , siteId]);

		let qp = '';
		if (queryParams && Object.keys(queryParams).length > 0) {
			qp += '?';
			for (let p in queryParams) {
				qp = qp + p + '='+ queryParams[p];
			}
		}
		return this.router.navigateByUrl('/'+ customerId + '/' + this.siteId + '/'  + child + qp);
	}

	ngOnDestroy() {
		this.permissionSub.unsubscribe();
		if(this.dataSub)
			this.dataSub.unsubscribe();
	}

	siteHasIotahOrChargeLinkDevices() {
		this.siteDashboardService.siteHasIotahOrChargeLinkDevices(this.siteId).subscribe((res: any) => {
			this.site_has_iotah_devices = !!res.iotah_devices;
			this.site_has_chargLink_devices = !!res.chargLink_devices;
			this.site_has_battLink_devices = !!res.battLink_devices;
			this.site_has_brm_devices = !!res.brm_devices;

			if (this.siteDashboardService.activePage == 'site-performance' && !res.iotah_devices)
				this.router.navigateByUrl('/404');
			if (this.siteDashboardService.activePage == 'site-chargers-performance' && !res.chargLink_devices)
				this.router.navigateByUrl('/404');

			this.theUIInitDone = true;
		});
	}

	checkHasStudies() {
		this.studiesService.checkHasStudies(this.siteId).subscribe((res: any) => {
			this.hasStudies = res;
		});
	}

	openChargLinkBRMOptions() {
		this.showChargLinkBRMOptions = true;
	}

	updateChargLinkBRMOption(option: string) {
		this.activeCharglinkBRMOption = option;
		this.showChargLinkBRMOptions = false;
	}

	enableBuzzer() {
		this.sitesService.enableBuzzer();
	}

	getBrmLink() {
		this.brmLink = ['/', 'charglink-brm', 'public', '0'];

		if (this.usersService.hasAccessFunction('show_charglink_brm'))
			this.brmLink = ['/', this.customerId, this.siteId, 'charglink-brm'];
	}

	showUtilizationCharts() {
		let customersList = this.showCustomers;

		if (this.domainsService.isProductionDomain) {
			customersList = this.productionShowCustomers;
		} else if (this.domainsService.isStagingDomain) {
			customersList = this.stagingShowCustomers;
		}

		return customersList.includes(this.customerId);
	}
}
