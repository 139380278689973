import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'underscore';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '../../device.service';

@Component({
	selector: 'app-device-alerts',
	templateUrl: './device-alerts.component.html',
	styleUrls: ['./device-alerts.component.css']
})
export class DeviceAlertsComponent implements OnInit  {
	@Input() device: any = {};
	@Input() siteAlertsSettings: any;

	dailyDetails: any =  [];
	data: any = [];

	/* alerts_summary: [
			batterySummaryObj.lowWaterAlert, // water_level_low ==> Exist
			batterySummaryObj.highTempAlert, // max_temperature_exceeded
			batterySummaryObj.missedEQ, // calculated in frontend
			batterySummaryObj.ebuOverUsage, // calculated in frontend
			batterySummaryObj.missedFi, // calculated in frontend
			batterySummaryObj.potentialWeakCells, // deep_use_limit
			batterySummaryObj.deepDischarge, // deep_discharge_limit
			batterySummaryObj.sulfatedBatteryAlerts, // CALCULATED in FRONTEND
			batterySummaryObj.overChargeAlerts, // calcualted in frontend
			batterySummaryObj.coldTempAlert, // start_temperature ==> need to discussed with AMR depend on min_temperature_exceeded: 0, // its initialized, but not used ==> used for alert cold tempAlert
		], */

	alertsColors = {
		low_water: '#3366cc',
		high_temp: '#9F8AD1',
		ebu_over_usage: '#D18A99',
		missed_plugged_in: '#FFD700',
		missed_fi: '#FF6347',
		weekly_missed_eq: '#ff9900',
		potential_weak_cells: '#0099c6',
		deep_discharge: '#dd4477'
	};

	invalidDateRange: boolean = false;
	deviceInstallationDate: any = null;
	fromDate: Date = moment().subtract(3, 'months').toDate();
	toDate: Date = moment().subtract(1, 'day').toDate();
	maxDate = moment().subtract(1, 'day').toDate();

	constructor(
		private devicesService: DeviceService,
		private translateService: TranslateService
	) {}

	ngOnInit(): void {

	}

	chartOptions = {
		title: this.translateService.instant('perf_analytic.device_alerts'),
		height: 650,
		legend: { position: 'right', textStyle: { fontSize: 16 }, alignment: 'center'},
		tooltip: {
			trigger: 'hover',
			isHtml: false,
			text: 'percentage',
			showColorCode: true
		},
		slices: {
			0: { color: this.alertsColors.low_water },
			1: { color: this.alertsColors.high_temp },
			2: { color: this.alertsColors.weekly_missed_eq },
			3: { color: this.alertsColors.ebu_over_usage },
			4: { color: this.alertsColors.potential_weak_cells },
			5: { color: this.alertsColors.deep_discharge },
			6: { color: this.alertsColors.missed_plugged_in },
			7: { color: this.alertsColors.missed_fi },
		},
		pieSliceText: 'percentage',
		pieSliceTextStyle: {
			fontSize: 12
		},
	};

	ngOnChanges(changes) {
		if(changes.device && this.device && this.device.mac_address) {
			this.deviceInstallationDate = this.device.config_info.installation_date;
			return this.getDailyDetails();
		}
	}

	getDailyDetails() {
		const startDate = moment(this.fromDate).unix();
		const endDate = moment(this.toDate).unix();
		if (startDate > endDate)
			this.invalidDateRange = true;

		if (!this.device.mac_address || this.invalidDateRange)
            return;

		this.devicesService.getDailyDetails(this.device.mac_address, startDate, endDate).subscribe((response: any ) => {
			this.dailyDetails = response;
			if (response.length)
				return this.data = this.prepareDeviceAlerts();
		});
	}

	prepareDeviceAlerts() {
		let waterLevelLowDays	= 0,
			countOfValidDays	= 0,
			oneDaySeconds	= 60*60*24,
			dataHasSulfatedBatteryAlerts	= true,
			missedEqThisWeek	= true,
			thisWeekEqAfterIdleCount	= 0,
			tempSulfatedBatteryAlertsCount	= 0,
			missedFiThisWeek	= true,
			totalInuseAs2	= 0,
			totalChargeAs2	= 0,
			countOfWorkingDays2	= 0,
			totalInuseAs	= 0,
			tempWaterLevelAlerts	= 0;

		let deviceAlerts: {
			lowWaterAlert: 0,
			highTempAlert: 0,
			missedEQ: 0,
			missedFi: 0,
			overChargeAlerts: 0,
			sulfatedBatteryAlerts: 0,
			ebuOverUsage: 0,
			potentialWeakCells: 0,
			deepDischarge: 0,
			coldTempAlert: 0
		}

		let endOfCurrentWeek,
			endOfLastWeek;


		let expectedEBU;
		switch (this.device.chargertype) { // check siteAlertSettings
			case 0: // fast
				expectedEBU =  1.6;
				break;
			case 1: // conventional
				expectedEBU = 1;
				break;
			case 2: // opportunity
				expectedEBU = 1.25;
				break;
			// case 3:
			// 	expectedEBU = this.siteAlertsSettings.lithium_ebu_limit || 1.65;
			// 	break;
		}

		let sortedDailyDetails = [];
		if (this.dailyDetails.length > 0) {
			sortedDailyDetails = this.dailyDetails.sort((a,b) => {return (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0);});
			let endDate = +moment(this.toDate).utc().startOf('day').format("X");

			endOfCurrentWeek	= endDate - (7*oneDaySeconds);
			endOfLastWeek		= endOfCurrentWeek;
		}

		for (let dayIndex in sortedDailyDetails) {
			const currentDayData = sortedDailyDetails[dayIndex];

			// calculate missed EQ
			if (currentDayData.date <= endOfCurrentWeek) {
				if (endOfLastWeek <= endOfCurrentWeek && countOfValidDays === 0) {
					dataHasSulfatedBatteryAlerts = false;
				}
				if (countOfValidDays > 3) {
					if (missedEqThisWeek === true && thisWeekEqAfterIdleCount != 7) {
						deviceAlerts.missedEQ++;
						if (dataHasSulfatedBatteryAlerts) {
							tempSulfatedBatteryAlertsCount++;
						}
					} else {
						if (tempSulfatedBatteryAlertsCount >= 4) {
							// increase the alerts
							deviceAlerts.sulfatedBatteryAlerts++;
						}
						tempSulfatedBatteryAlertsCount = 0;
					}

					if (missedFiThisWeek === true) {
						deviceAlerts.missedFi++;
					}

					// over charge
					let chargePercentage = -1;
					let forcedOkCharge	= false;
					if (totalInuseAs2 > 0) {
						chargePercentage = (totalChargeAs2 / totalInuseAs2) * 100;
						forcedOkCharge =  (totalInuseAs2 / (3600 * 0.8 * this.device.config.battery_capacity * countOfWorkingDays2) < 0.25);
					}

					forcedOkCharge	= forcedOkCharge || ((Math.abs(totalChargeAs2 - totalInuseAs2) / 3600) < this.device.config.battery_capacity);

					if (chargePercentage > 130 && !forcedOkCharge) {
						deviceAlerts.overChargeAlerts++;
					}

					totalChargeAs2		= 0;
					totalInuseAs2		= 0;
					countOfWorkingDays2	= 0;
				}

				missedEqThisWeek = true;
				thisWeekEqAfterIdleCount = 0;
				missedFiThisWeek = true;

				countOfValidDays = 0;
				endOfCurrentWeek -= 7 * oneDaySeconds;
			}

			// calculate water_level_low alert
			if (currentDayData.water_level_low) {
				waterLevelLowDays++;
				tempWaterLevelAlerts++;
			}

			if ((currentDayData.count_of_eqs > 0 || currentDayData.has_eq_start) && waterLevelLowDays >= this.siteAlertsSettings.water_level_low_days) { // check siteAlertSettings
				deviceAlerts.lowWaterAlert += tempWaterLevelAlerts;
				tempWaterLevelAlerts = 0;
			}
			if (!currentDayData.water_level_low) {
				waterLevelLowDays = 0;
				tempWaterLevelAlerts = 0;
			}

			// calculate highTempAlert
			if (currentDayData.max_temperature_exceeded > 0) {
				deviceAlerts.highTempAlert += 1;
            }

			if(currentDayData.min_temperature_exceeded > 0) {
                deviceAlerts.coldTempAlert += 1;
            }

			// if (deviceAlerts.highTempAlert < this.siteAlertsSettings.high_temperature_limit_days) { // check siteAlertsSettings.high_temperature_limit_days
			// 	deviceAlerts.highTempAlert = 0;
			// }

			if (missedEqThisWeek === true && (currentDayData.count_of_eqs > 0 || currentDayData.has_eq_start)) {
				missedEqThisWeek = false;
			}

			if(currentDayData.eq_after_idle_day) {
				thisWeekEqAfterIdleCount++;
			}

			let inuseAsValue = currentDayData.inuse_as;
			let chargeAsValue = currentDayData.charge_as;

			totalChargeAs2 += chargeAsValue;
			totalInuseAs2 += inuseAsValue;

			// calculate EBU
			if (currentDayData.is_working_day) {
				totalInuseAs += inuseAsValue;
				const EBU	= inuseAsValue / (3600 * 0.8 * this.device.config_info.battery_capacity);
				if (EBU > expectedEBU && EBU <= 3) {
					deviceAlerts.ebuOverUsage++;
				}
			}

			if (currentDayData.potential_week_cells_exceeded > 0) {
				deviceAlerts.potentialWeakCells++;
			}

			if (currentDayData.deep_discharge_exceeded > 0) {
				deviceAlerts.deepDischarge++;
			}

			// if (deviceAlerts.highTempAlert < this.siteAlertsSettings.high_temperature_limit_days) { // check siteAlertSettings
			// 	deviceAlerts.highTempAlert = 0;
			// }

			// if (deviceAlerts.potentialWeakCells < this.siteAlertsSettings.potential_weak_cells_limit_days) { // check siteAlertSettings
			// 	deviceAlerts.potentialWeakCells = 0;
			// }

			if (countOfValidDays > 3) {
				if (missedEqThisWeek === true && thisWeekEqAfterIdleCount != 7) {
					deviceAlerts.missedEQ++;
					if (dataHasSulfatedBatteryAlerts) {
						tempSulfatedBatteryAlertsCount++;
					}
				}

				if (missedFiThisWeek === true) {
					deviceAlerts.missedFi++;
				}

				// over charge
				let chargePercentage = -1;
				let forcedOkCharge	= false;
				if (totalInuseAs2 > 0) {
					chargePercentage = (totalChargeAs2 / totalInuseAs2) * 100;
					forcedOkCharge =  (totalInuseAs2 / (3600 * 0.8 * this.device.ahrcapacity * countOfWorkingDays2) < 0.25);
				}

				forcedOkCharge	= forcedOkCharge || ((Math.abs(totalChargeAs2 - totalInuseAs2) / 3600) < this.device.ahrcapacity);

				if (chargePercentage > 130 && !forcedOkCharge) {
					deviceAlerts.overChargeAlerts++;
				}
			}

			if (tempSulfatedBatteryAlertsCount >= 4) {
				// increase the alerts
				deviceAlerts.sulfatedBatteryAlerts++;
			}
		}

		return  [
			deviceAlerts.lowWaterAlert,
			deviceAlerts.highTempAlert,
			deviceAlerts.missedEQ,
			deviceAlerts.ebuOverUsage,
			deviceAlerts.missedFi,
			deviceAlerts.potentialWeakCells,
			deviceAlerts.deepDischarge,
			deviceAlerts.sulfatedBatteryAlerts,
			deviceAlerts.overChargeAlerts,
			deviceAlerts.coldTempAlert,
		];
	}
}
