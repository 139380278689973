import { Component, Input } from '@angular/core';
import { SiteWidget } from '../widget.interface';
import { TranslateService } from '@ngx-translate/core';
import { BattlinkSitePerformanceService } from '../battlink-site-performance.service';
import * as _ from 'underscore';
import { TableData } from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';

@Component({
	selector: 'app-site-battery-aging',
	templateUrl: './site-battery-aging.component.html',
})
export class SiteBatteryAgingComponent {
	@Input() siteId: number;
	@Input() customerId: number;
	@Input() devices: any = {};
	@Input() tags: any;
	@Input() deviceTags: any;

	widgetConfig: SiteWidget = {
		data: [],
		tableData: {},
		scale: 1,
		config: {
			hAxis: { title: this.translateService.instant('site_performance.remaining_life_percent') },
			vAxis: { title: this.translateService.instant('site_performance.batteries_percent'), format: "0" },
			legend: "none",
			colors: [ "#e92f31", "#e92f31", "#fb902c", "#faa82f", "#b6fd94", "#16fe45"],
			isStacked: true
		},
		columnNames: [ "Type", "", { role: "style", type: "string" }],
		type: "ColumnChart",
		title: this.translateService.instant('perf_analytic.site_battery_aging'),
		tooltip: "",
		widgetId: "site_battery_aging",
		dataRanges: [],
		selectedDateRange: "",
		filterByTag: true,
		selectedTags: [],
		filterByType: true,
		selectedType: "all",
		hideWidget: true,
		stackColumn: "truck_type",
		groups: [
			["0%", 0],
			["20%", 1],
			["40%", 2],
			["60%", 3],
			["80%", 4],
			["100%", 5],
		],
		tableConfig:{
			columns: [
				{ key: 'battery_id', name: this.translateService.instant('device_performance.battery_id'), type: 'link' },
				{ key: 'age_by_installation_date', name: this.translateService.instant('device_performance.age_by_installation_date'), type: 'link' },
				{ key: 'age_by_usage', name: this.translateService.instant('device_performance.age_by_usage'), type: 'string' },
				{ key: 'battery_sn', name: this.translateService.instant('device_performance.battery_sn'), type: 'string' }
			],
			gridTableColumns:
			[
				{ headerName: this.translateService.instant('device_performance.battery_id'), field: "battery_id", width: 600},
				{ headerName: this.translateService.instant('device_performance.age_by_installation_date'), field: "age_by_installation_date", width: 1200},
				{ headerName: this.translateService.instant('device_performance.age_by_usage'), field: "age_by_usage", width: 1200},
				{ headerName: this.translateService.instant('device_performance.battery_sn'), field: "battery_sn", width: 600},
			],
			dataKeys: ['battery_id', 'age_by_installation_date', 'age_by_usage', 'battery_sn'],
			sortBy: 'battery_id',
			exportFileName: this.translateService.instant('perf_analytic.site_battery_aging'),
			sizeColumnsToFit: true
		},
		dropDownGroupList: [],
		selectedDropDown: {},
	};

	tableItems: any[] = [];
	chartTableConfig: any = {data: [], column: []};
	tableColumns: any[] = [];
	exportFileName: string = "";
	sizeColumnsToFit: boolean = true;
	noDataForCurrentFilters: boolean = false;
	fastBatteryType = 1;

	constructor(
		private translateService: TranslateService,
		private battlinkSitePerformanceService: BattlinkSitePerformanceService
	) {}

	ngOnInit() {
		this.updateWidgetData();
	}

	updateWidgetData() {
		const devicesData = [];
		const deviceTableData = {};
		const chartData = [];
		let groupIndex = 0;

		for(let group of this.widgetConfig.groups) {
			deviceTableData[groupIndex] = [];
			chartData.push([group[0], 0, this.widgetConfig.config.colors[groupIndex++]]);
		}

		for(let mac in this.devices) {
			let device = this.devices[mac];
			let deviceData = this.getDeviceData(device);
			devicesData.push(deviceData);
			this.widgetConfig.hideWidget = false;
		}

		const {groupedChartData, groupedTableData, hasData} = this.groupData(devicesData, chartData, deviceTableData);

		this.widgetConfig.data = groupedChartData;
		this.widgetConfig.tableData = groupedTableData;
		this.noDataForCurrentFilters = !hasData;
	}

	getDeviceData(device) {
		const currentDay = new Date();
		let totalLifeYears		= 5;
		const installationDate	= new Date(device.installation_date);

		if (device.charger_type == this.fastBatteryType)  // fast
			totalLifeYears = 4;

		const monthsSinceInstallation = this.battlinkSitePerformanceService.getElapsedMonths(installationDate, currentDay);

		const remainingYears1	= Math.round((monthsSinceInstallation/12) * 10) / 10;
		let timeLeftYrs		= totalLifeYears - remainingYears1;

		if (timeLeftYrs < 0)
			timeLeftYrs = 0;

		const remainingMonthsByInstallationDate = Math.round(timeLeftYrs * 12);

		const warrantedAhr			= (1250 * (+device.battery_capacity));
		let remainingAHRWarranty	= warrantedAhr;

		let batteryUsagePerMonth = 0;
		let totalUsedAhr = (+device.inuse_ahr + +device.add_inuse_ahr);
		let remainingMonthsByUsage = 0;

		if (monthsSinceInstallation > 0) {
			batteryUsagePerMonth = totalUsedAhr / monthsSinceInstallation;
			remainingAHRWarranty -= totalUsedAhr;
			remainingMonthsByUsage = batteryUsagePerMonth ? remainingAHRWarranty / batteryUsagePerMonth : monthsSinceInstallation * 12;
		}

		timeLeftYrs = Math.round(timeLeftYrs * 10) / 10;

		const batteryAging = (remainingMonthsByInstallationDate > remainingMonthsByUsage) ? remainingMonthsByUsage : remainingMonthsByInstallationDate;

		const batteryAgingYears = batteryAging  / (totalLifeYears * 12);
		let batteryAgingIndex = Math.floor(((batteryAgingYears - 0.1) / 0.2)+1);
		if (batteryAgingIndex < 0)
			batteryAgingIndex = 0;

		if (batteryAgingIndex > 5)
			batteryAgingIndex = 5;

		return {
			mac: device.mac_address,
			batteryId: device.truck_id,
			batterySn: device.serial_number,
			remainingMonthsByInstallationDate, // according to installation date
			remainingMonthsByUsage, // according to usage
			batteryAgingIndex, // 0-5 for chart grouping
		}
	}

	getElapsedMonths(start, end) {
		var months;
		months = (end.getFullYear() - start.getFullYear()) * 12;
		months -= start.getMonth() + 1;
		months += end.getMonth();
		return months <= 0 ? 0 : months;
	}

	groupData(devicesData, defaultChartData, defaultTableData) {
		const groupedChartData = [...defaultChartData];
		const groupedTableData = {...defaultTableData};
		let hasData = false;

		for (let device of devicesData) {
			let groupIndex = 0;

			for (let group of this.widgetConfig.groups) {
				const yearIndex = group[1];

				if (device.batteryAgingIndex == yearIndex) {
					groupedChartData[groupIndex][1]++;
					groupedTableData[groupIndex].push(device);
					hasData = true;
				}

				groupIndex++;
			}
		}

		return {groupedChartData, groupedTableData, hasData};
	}

	onSelect(event) {
		if(!event)
			return;

		let selectedIndex = event.row;
		let selectedDevicesInfo = this.widgetConfig.tableData[selectedIndex];
		this.fillGridTableData(selectedDevicesInfo);
		this.prepareWidgetModalTable();
	}

	prepareWidgetModalTable() {
		const config = this.widgetConfig.tableConfig;
		this.tableColumns = config.gridTableColumns;
		this.exportFileName = config.exportFileName;
		this.sizeColumnsToFit = config.sizeColumnsToFit;

		if (config) {
			this.chartTableConfig.column = config.columns;
			this.chartTableConfig.data = this.tableItems.map((row) => {
				const rowData: TableData = {
					battery_sn: { value: row.battery_sn },
					battery_id: { value: row.battery_id },
					age_by_installation_date: { value: row.age_by_installation_date || "0" },
					age_by_usage: { value: row.age_by_usage || "0" },
					appearName: { value: row.appearName, link: ['/', this.customerId, this.siteId, row.mac_address, 'performance'] },
				};

				return rowData;
			});
		}
	}

	fillGridTableData(selectedDevicesInfo) {
		let tableItems = [];

		for(let item of selectedDevicesInfo) {
			if(!this.devices[item.mac])
				continue;

			let temp = {
				battery_sn: item.batterySn,
				age_by_installation_date: item.remainingMonthsByInstallationDate || 0,
				age_by_usage: item.remainingMonthsByUsage || 0,
				battery_id: item.batteryId,
			};
			tableItems.push(temp);
		}
		if(this.widgetConfig.tableConfig.sortBy)
			tableItems = _.sortBy(tableItems, this.widgetConfig.tableConfig.sortBy).reverse();

		this.tableItems = tableItems;
	}
}
