import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as _ from 'underscore';
import { SiteWidget } from '../widget.interface';
import { CommonService } from 'src/app/shared/services/common.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-site-performance-widget',
	templateUrl: './site-performance-widget.component.html',
})
export class SitePerformanceWidgetComponent {
	@Input() widgetConfig: SiteWidget;
	@Input() devices: any;
	@Input() customerId: number;
	@Input() siteId: number;
	@Input() tableItems: any[] = [];
	@Input() chartTableConfig: any = {data: [], column: []};
	@Input() tableColumns: any[] = [];
	@Input() exportFileName: string = "";
	@Input() sizeColumnsToFit: boolean = true;
	@Input() tags: any[] = [];
	@Input() noDataForCurrentFilters: boolean = false;
	@Output() select = new EventEmitter<any>();
	@Output() filter = new EventEmitter<any>();

	isCollapsed: boolean = false;
	itemsPerPage: number = 2;
	selectedDropDown: any;
	selectedTags: any[] = [];

	constructor(
		private commonService: CommonService,
		private translateService: TranslateService,
	) { }

	ngOnInit() {
		this.selectedDropDown = this.widgetConfig.selectedDropDown;
	}

	onSelect(event: string) {
		this.select.emit(event);
	}

	onFilter(event: any) {
		this.filter.emit(event);
	}

	onPrint() {
		let appendPrintContent = this.getPrintItems();
		return this.print({
			selectedDropDown: this.selectedDropDown,
			selectedTags: this.selectedTags,
			widgetId: this.widgetConfig.widgetId,
			appendPrintContent: appendPrintContent
		});
	}

	private print(options) {
		let addPrintHeaderFn		= options.addPrintHeaderFn,
			customerName			= options.customerName,
			siteName				= options.siteName,
			selectedDropDown		= options.selectedDropDown,
			selectedTags			= options.selectedTags,
			addPrintContentFn		= options.addPrintContentFn,
			widgetId				= options.widgetId,
			appendPrintContent		= options.appendPrintContent;

		let htmlContent = '';
		if (addPrintHeaderFn) {
			htmlContent+= addPrintHeaderFn();
		} else {
			htmlContent += '<div id="dvCompanyFullName"><h3>';
			if (customerName) {
				htmlContent += customerName;
				if (siteName) {
					htmlContent += ' / '+ siteName;
				}
			}
			htmlContent += '</h3></div>';
			//add selected dropdowns
			for (let dropDownGroup of this.widgetConfig.dropDownGroupList) {
				let value = selectedDropDown[dropDownGroup.name];
				if(dropDownGroup.options) {
					for (let option of dropDownGroup.options) {
						if (option.value == value) {
							htmlContent+= option.label + '<br/>';
							break;
						}
					}
				}
			}

			// Add selected tags
			if(this.tags && selectedTags && selectedTags.length) {
				let tagNames = [];
				for(let tag of this.tags) {
					if(selectedTags.includes(tag.id)) {
						tagNames.push(tag.tag_name);
					}
				}
				if(tagNames.length) {
					htmlContent+= this.translateService.instant('tags.tags') + ': ' + tagNames.join(', ');
				}
			}
		}

		// additional content
		if (addPrintContentFn) {
			htmlContent+= addPrintContentFn();
		} else {
			if(widgetId) {
				const printId = widgetId.replace( /(:|\.|\[|\])/g, "\\$1" );
				const item = document.getElementById(printId);
				htmlContent+=  item.innerHTML;
			}
		}

		if(appendPrintContent) {
			htmlContent+= appendPrintContent;
		}

		this.commonService.printContent(htmlContent);
	}

	private getPrintItems() {
		let groups;
		groups = this.widgetConfig.groups;

		let html = '<div class="showOnPrint">';

		html += '<div class="page-break-inside">';
		html += '<span style="font-weight:bold;">'+this.widgetConfig.title+'</span>';
		html += '<table class="table table-striped table-condensed table-bordered"><thead>';
		let columns = this.tableColumns

		for (let columnIndex in columns) {
			html += '<th>'+columns[columnIndex].headerName+'</th>';
		}
		html += '</thead><tbody>';

		for (let itemIndex in this.tableItems) {
			let printItem = this.tableItems[itemIndex];
			html += '<tr>';
			for (let itemIndex in printItem) {
				html += '<td>';
				html += '<span>'+printItem[itemIndex]+'</span>';
				html += '</td>';
			}
			html += '</tr>';
		}
		html += '</tbody></table></div>';

		html += '</div>';
		return html;
	}
}
