<div class="col-sm-10 col-md-10 ml-3">
	<div class="col-sm-6 col-md-6 p-3 mb-3">
		<select (change)="selectOption($event.target.value)" class="form-control">
			<option value="" disabled selected>{{'g.select_user' | translate}}</option>
			<option *ngFor="let user of usersList" [value]="user.id">{{user.name}}</option>
		</select>
	</div>

	<form *ngIf="selectedUser">
		<legend class="margin-bottom-lg">{{"working_days.maintenance_reports" | translate}}</legend>
		<div *ngTemplateOutlet="sliderTemplate; context: { label: 'working_days.maintenance_alert_frequency', rangeValue: selectedUserSettings.maintenance_reports, id: 'maintenance_reports' }"></div>
		<legend class="margin-bottom-lg">{{"site_settings.temperature" | translate}}</legend>
		<div *ngTemplateOutlet="sliderTemplate; context: { label: 'site_settings.temperature_alert_frequency', rangeValue: selectedUserSettings.temperature_alert_freq, id: 'temperature_alert_freq' }"></div>
		<div *ngTemplateOutlet="sliderTemplate; context: { label: 'site_settings.cold_temperature_alert_frequency', rangeValue: selectedUserSettings.cold_temperature_alert_freq, id: 'cold_temperature_alert_freq' }"></div>
		<legend class="margin-bottom-lg">{{"site_settings.ebu_over_usage" | translate}}</legend>
		<div *ngTemplateOutlet="sliderTemplate; context: { label: 'site_settings.ebu_alert_frequency', rangeValue: selectedUserSettings.ebu_alert_freq, id: 'ebu_alert_freq' }"></div>
		<legend class="margin-bottom-lg">{{"site_settings.deep_usage" | translate}}</legend>
		<div *ngTemplateOutlet="sliderTemplate; context: { label: 'site_settings.potential_weak_cells_alert_frequency', rangeValue: selectedUserSettings.potential_weak_cells_alert_freq, id: 'potential_weak_cells_alert_freq' }"></div>
		<div *ngTemplateOutlet="sliderTemplate; context: { label: 'site_settings.deep_discharge_alert_frequency', rangeValue: selectedUserSettings.deep_discharge_alert_freq, id: 'deep_discharge_alert_freq' }"></div>
		<div *ngTemplateOutlet="sliderTemplate; context: { label: 'site_settings.over_charge_alert_frequency', rangeValue: selectedUserSettings.over_charge_alert_freq, id: 'over_charge_alert_freq' }"></div>
		<legend class="margin-bottom-lg">{{"site_settings.water_level" | translate}}</legend>
		<div *ngTemplateOutlet="sliderTemplate; context: { label: 'site_settings.low_water_level_alert_frequency', rangeValue: selectedUserSettings.low_water_level_alert_freq, id: 'low_water_level_alert_freq' }"></div>
		<legend class="margin-bottom-lg">{{"site_settings.missed_connection" | translate}}</legend>
		<div *ngTemplateOutlet="sliderTemplate; context: { label: 'site_settings.missed_connection_alert_frequency', rangeValue: selectedUserSettings.missed_connection_alert_freq, id: 'missed_connection_alert_freq' }"></div>
		<div *ngTemplateOutlet="sliderTemplate; context: { label: 'site_settings.idle_times_frequency', rangeValue: selectedUserSettings.idle_times_alert_freq, id: 'idle_times_alert_freq' }"></div>
		<div class="row">
			<div class="col-md-12">
				<button (click)="saveUserAlertSettings(selectedUser)" class="btn btn-primary mb-3" style="float: right;">{{'g.save' | translate}}</button>
			</div>
		</div>
	</form>
</div>

<ng-template #sliderTemplate let-label="label" let-rangeValue="rangeValue" let-id="id">
	<div class="ml-3 mb-3 row align-items-center">
		<b class="col-md-4">{{ label | translate }}</b>
		<div class="col-md-8">
			<div class="ml-2 d-flex justify-content-between">
				<b>{{ 'note.reminder_freq_none' | translate }}</b>
				<b>{{ 'note.reminder_freq_daily' | translate }}</b>
				<b>{{ 'note.reminder_freq_weekly' | translate }}</b>
			</div>
			<div class="row ml-2">
				<input
					type="range"
					min="0"
					max="2"
					[value]="rangeValue"
					class="slider"
					(change)="onChange($event)"
					[id]="id">
			</div>
		</div>
	</div>
</ng-template>
