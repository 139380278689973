import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { UsersService } from 'src/app/users/users.service';
import { mainDeviceConfig } from '../../device-factory/device-config';
import { DeviceFactory } from '../../device-factory/device-factory';
import { IotahDevice } from '../../device-factory/Iotah-device';
import { DeviceService } from '../../device.service';

@Component({
	selector: 'app-global-records',
	templateUrl: './global-records.component.html',
	styleUrls: ['./global-records.component.css']
})
export class GlobalRecordsComponent implements OnInit, OnChanges {

	@Input() device: any = {};
	@Input() currentSite: any;
	@Input() enterprisePermissions: any = {};

	data:any = {};
	deviceConfig: mainDeviceConfig | null = (new IotahDevice).config;
	times = {
		inuse_seconds: 0,
		charge_seconds: 0,
		idle_seconds: 0,
		accurate_inuse_seconds: 0,
		accurate_charge_seconds: 0,
	};
	tooltips: { [key: string]: string } = {
		total_inuse_ahr: 'total_inuse_ahr',
		total_inuse_kwhr: 'total_inuse_kwhr',
		total_inuse_events_time: 'total_inuse_events_time',
		total_idle_time: 'total_idle_time',
		dead_man_time: 'dead_man_time',
	};

	constructor(
		public common: CommonService,
		public usersService: UsersService,
		private deviceService: DeviceService
	) { }

	ngOnInit() {}

	ngOnChanges() {
		this.data = this.device && this.device['config_info'] ? this.device['config_info'] : {};
		this.deviceConfig = DeviceFactory.createDevice(this.data.sct_device_type).config;

		for (let timeField of ['inuse_seconds', 'charge_seconds', 'idle_seconds', 'accurate_inuse_seconds', 'accurate_charge_seconds']) {
			if(!+this.data[timeField])
				continue;

			this.times[timeField] = this.common.secondsToElapsedTime((+this.data['add_'+timeField]) + (+this.data[timeField]), {hoursOnly: true}) || 0;
		}

		for(const field in this.tooltips) {
			this.tooltips[field] = this.deviceService.getTranslationKey(field, this.device.config_info);
		}
	}

	canAccessNumbers() {
		return this.usersService.hasAccessPermission(this.enterprisePermissions, 'debug_end') ||
				this.usersService.hasAccessPermission(this.enterprisePermissions, 'events_end') ||
				this.usersService.hasAccessPermission(this.enterprisePermissions, 'rt_end') ||
				this.usersService.hasAccessPermission(this.enterprisePermissions, 'inuse_ahr') ||
				this.usersService.hasAccessPermission(this.enterprisePermissions, 'inuse_kwhr') ||
				this.usersService.hasAccessPermission(this.enterprisePermissions, 'charge_ahr') ||
				this.usersService.hasAccessPermission(this.enterprisePermissions, 'charge_kwhr');
	}
}
