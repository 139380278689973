import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class DomainsService {
	private hasDefinedDomainObj = new BehaviorSubject<boolean>(false);
	hasDefinedDomainObservable = this.hasDefinedDomainObj.asObservable();

	projectId: number;

	productionDomain = 'www.smarttelemetrics.com';
	stagingDomain = 'staging.smarttelemetrics.com';
	developmentDomain = 'telemetrics.iotah-view.org';

	iotahProductionDomain = 'www.iotah-view.com';
	iotahStagingDomain = 'staging.iotah-view.com';
	iotahDevelopmentDomain = 'iotah-view.org';

	charglinkProductionDomain = 'www.charglink.com';
	charglinkStagingDomain = 'staging.charglink.com';
	charglinkDevelopmentDomain = 'charglink.iotah-view.org';

	battlinkProductionDomain = 'www.batt-link.com';
	battlinkStagingDomain = 'staging.batt-link.com';
	battlinkDevelopmentDomain = 'battlink.iotah-view.org';

	mobileAppLinks = {
		google_play: {
			iotah: 'https://play.google.com/store/apps/details?id=com.smartchargetech.iotah&pcampaignid=web_share&pli=1',
			chargLink: 'https://play.google.com/store/apps/details?id=com.smartchargetech.charglink&pcampaignid=web_share',
			battlink: 'https://play.google.com/store/apps/details?id=com.smartchargetech.telemetrics',
			smart_telemetric: 'https://play.google.com/store/apps/details?id=com.smartchargetech.telemetrics'
		},
		app_store: {
			iotah: 'https://apps.apple.com/us/app/iotah/id1534874730',
			chargLink: 'https://apps.apple.com/us/app/charglink/id6448758620',
			battlink: 'https://apps.apple.com/us/app/id6741804567',
			smart_telemetric: 'https://apps.apple.com/us/app/id6741804567'
		}
	};


	get isProductionDomain() {
		return [this.productionDomain, this.iotahProductionDomain, this.charglinkProductionDomain, this.battlinkProductionDomain].includes(window.location.hostname);
	}

	get isStagingDomain() {
		return [this.stagingDomain, this.iotahStagingDomain, this.charglinkStagingDomain, this.battlinkStagingDomain].includes(window.location.hostname);
	}

	get isDevelopmentDomain() {
		return [this.developmentDomain, this.iotahDevelopmentDomain, this.charglinkDevelopmentDomain, this.battlinkDevelopmentDomain].includes(window.location.hostname);
	}

	get isIotah() {
		return this.projectId == 0;
	}

	get isChargLink() {
		return this.projectId == 1;
	}

	get isBattlink() {
		return this.projectId == 2;
	}

	get isSmartTelemetrics() {
		return this.projectId == 3;
	}

	hasDefinedDomain() {
		return this.projectId != undefined;
	}

	setProjectId(projectId: number): void {
		this.projectId = projectId;
		this.updateHasDefinedDomain();
	}

	private updateHasDefinedDomain(): void {
		this.hasDefinedDomainObj.next(this.hasDefinedDomain());
	}

	//! to do - use the config on the common file
	getAppLinks() {
		let app_store = this.mobileAppLinks.app_store.smart_telemetric;
		let google_play = this.mobileAppLinks.google_play.smart_telemetric;

		if (this.isIotah) {
			app_store = this.mobileAppLinks.app_store.iotah;
			google_play = this.mobileAppLinks.google_play.iotah;
		} else if (this.isChargLink) {
			app_store = this.mobileAppLinks.app_store.chargLink;
			google_play = this.mobileAppLinks.google_play.chargLink;
		}

		return { app_store, google_play };
	}
}
