<form class="form-auth">
	<div class="container-fluid d-flex w-100 justify-content-center" id="main">
		<div class="bg-white rounded p-3 col-md-8 col-md-offset-1" id="left">
			<div>
				<h2 class="text-center">{{ "site.iotah_report" | translate }}</h2>
				<fieldset>
					<legend class="p-3">
						{{ "g.site" | translate }}
						<a class="pull-right noprint" href="javascript:">
							<i
								(click)="isCollapsed['site'] = !isCollapsed['site']"
								class="fa"
								[ngClass]="{
									'fa-minus': !isCollapsed['site'],
									'fa-plus': isCollapsed['site']
								}"
							></i>
						</a>
					</legend>

					<div *ngIf="!isCollapsed['site']">
						<div class="row col-md-12 p-3">
							<div class="col-md-4">
								<strong>{{ "g.customer" | translate }}</strong>
							</div>
							<div class="col-md-3">
								<label>
									<input
										type="checkbox"
										[(ngModel)]="model.selectedCustomersNegation"
										name="selectedCustomersNegation"
										(change)="updateSitesAvailable()"
									/>
									{{ "g.exclude" | translate }}
								</label>
							</div>
							<div class="col-md-5">
								<ng-select
									[items]="customers"
									bindLabel="customer_name"
									bindValue="id"
									placeholder="{{ 'g.all_customers' | translate }}"
									name="customer_id"
									[(ngModel)]="model.selectedCustomers"
									multiple="true"
									(change)="updateSitesAvailable()"
								>
								</ng-select>
							</div>
						</div>
						<div class="row col-md-12 p-3">
							<div class="col-md-4">
								<strong>{{ "site.sites" | translate }}</strong>
							</div>
							<div class="col-md-8">
								<ng-select
									[items]="sites"
									bindLabel="name"
									bindValue="id"
									name="site_id"
									[(ngModel)]="model.selectedSites"
									multiple="true"
								>
								</ng-select>
							</div>
						</div>
						<div class="row col-md-12 p-3">
							<div class="col-md-4">
								<strong>{{ "ent.enterprise" | translate }}</strong>
							</div>
							<div class="col-md-3">
								<label>
									<input
										type="checkbox"
										[(ngModel)]="model.selectedEnterpriseNegation"
										name="selectedEnterpriseNegation"
									/>
									{{ "g.exclude" | translate }}
								</label>
							</div>
							<div class="col-md-5">
								<ng-select
									[items]="enterprises"
									bindLabel="name"
									bindValue="id"
									name="enterprise_id"
									[(ngModel)]="model.selectedEnterprise"
									multiple="true"
								>
								</ng-select>
							</div>
						</div>
					</div>
				</fieldset>
				<fieldset>
					<legend class="p-3">
						{{ "g.warnings_and_faults" | translate }}
						<a class="pull-right noprint" href="javascript:">
							<i
								(click)="isCollapsed['warnings'] = !isCollapsed['warnings']"
								class="fa"
								[ngClass]="{
									'fa-minus': !isCollapsed['warnings'],
									'fa-plus': isCollapsed['warnings']
								}"
							></i
						></a>
					</legend>
					<div *ngIf="!isCollapsed['warnings']">
						<div class="row col-md-12 p-3">
							<div class="col-md-4">
								<strong>{{
									"nav.warnings" | translate
								}}</strong>
							</div>
							<div class="col-md-8">
								<ng-select
									[items]="warnings"
									bindLabel="value"
									bindValue="id"
									name="warnings"
									[(ngModel)]="model.selectedWarnings"
									multiple="true"
								>
								</ng-select>
							</div>
						</div>
						<div class="row col-md-12 p-3">
							<div class="col-md-4">
								<strong>{{
									"g.date_range" | translate
								}}</strong>
							</div>
							<div class="col-md-4">
								<input class="form-control" [placeholder]="'g.from_date' | translate" name="fromWarningsDate" [(ngModel)]="model.fromWarningsDate" bsDatepicker #fromWarningsDate="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }">
							</div>
							<div class="col-md-4">
								<input class="form-control" [placeholder]="'g.to_date' | translate" name="toWarningsDate" [(ngModel)]="model.toWarningsDate" bsDatepicker #toWarningsDate="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }">
							</div>
						</div>
						<div class="row col-md-12 p-3">
							<div class="col-md-4">
								<strong>{{
									"warnings.warning_status" | translate
								}}</strong>
							</div>
							<div class="col-md-8">
								<select
									[(ngModel)]="model.warningStatus"
									class="form-control"
									name="warningStatus"
								>
									<option value="0" label="{{'warnings.all_warnings' | translate }}" selected>{{ "warnings.all_warnings" | translate }}</option>
									<option value="1" label="{{ 'warnings.cleared' | translate }}">{{ "warnings.cleared" | translate }}</option>
									<option value="2" label="{{ 'warnings.uncleared' | translate }}">{{ "warnings.uncleared" | translate }}</option>
								</select>
							</div>
						</div>
					</div>
				</fieldset>
				<fieldset>
					<legend class="p-3">
						{{ "g.version" | translate }}
						<a class="pull-right noprint" href="javascript:">
							<i
								(click)="isCollapsed['version'] = !isCollapsed['version']"
								class="fa"
								[ngClass]="{
									'fa-minus': !isCollapsed['version'],
									'fa-plus': isCollapsed['version']
								}"
							></i
						></a>
					</legend>
					<div *ngIf="!isCollapsed['version']">
						<div class="row col-md-12 p-3">
							<div class="col-md-4">
								<strong>{{ "devices.firmware_version" | translate }}</strong>
							</div>
							<div class="col-md-3">
								<select
									[(ngModel)]="model.fwVersionOperation"
									class="form-control"
									name="fwVersionOperation"
								>
									<option value="" label="{{ 'g.not_selected' | translate }}">
										{{ "g.not_selected" | translate }}
									</option>
									<option value="=" label="=">=</option>
									<option value=">" label=">">></option>
									<option value="<" label="<"><</option>
									<option value="!=" label="!=">!=</option>
								</select>
							</div>
							<div class="col-md-5">
								<input
									type="number"
									step="0.01"
									name="fwVersion"
									[(ngModel)]="model.fwVersion"
									[disabled]="!model.fwVersionOperation"
								/>
							</div>
						</div>
			<div class="row col-md-12 p-3">
				<div class="col-md-4">
					<strong>{{ "devices.iotah_hw_revision" | translate }}</strong>
				</div>
				<div class="col-md-3">
					<select
						[(ngModel)]="model.hwReversionOperation"
						name="installationDateOperation"
						class="form-control"
					>
						<option value="" label="{{ 'g.not_selected' | translate }}">
						{{ "g.not_selected" | translate }}
						</option>
						<option value="=" label="=">=</option>
						<option value="!=" label="!=">!=</option>
					</select>
					</div>
				<div class="col-md-5">
					<input
					type="text"
					maxlength="2"
					name="hwReversion"
					[disabled]="!model.hwReversionOperation"
					[(ngModel)]="model.hwReversion"
					/>
				</div>
				</div>
					</div>
				</fieldset>
				<fieldset>
					<legend class="p-3">
						{{ "g.dates" | translate }}
						<a class="pull-right noprint" href="javascript:">
							<i
								(click)="isCollapsed['dates'] = !isCollapsed['dates']"
								class="fa"
								[ngClass]="{
									'fa-minus': !isCollapsed['dates'],
									'fa-plus': isCollapsed['dates']
								}"
							></i
						></a>
					</legend>
					<div *ngIf="!isCollapsed['dates']">
						<div class="row col-md-12 p-3" *ngIf="!isChargeLinkDomain">
							<div class="col-md-4">
								<strong>{{ "devices.truck_manufacturing_year" | translate }}</strong>
							</div>
							<div class="col-md-3">
								<select
									[(ngModel)]="model.truckManufacturingYearOperation"
									name="truckManufacturingYearOperation"
									class="form-control"
								>
									<option value="" label="{{ 'g.not_selected' | translate }}">
										{{ "g.not_selected" | translate }}
									</option>
									<option value=">" label=">">></option>
									<option value="<" label="<"><</option>
									<option value="=" label="=">=</option>
									<option value="!=" label="!=">!=</option>
								</select>
							</div>
							<div class="col-md-5">
								<input
									class="form-control"
									name="truckManufacturingYear"
					type="number"
									[(ngModel)]="model.truckManufacturingYear"
									[disabled]="!model.truckManufacturingYearOperation"
								/>
							</div>
						</div>
						<div class="row col-md-12 p-3">
							<div class="col-md-4">
								<strong>{{ "devices.installation_date" | translate }}</strong>
							</div>
							<div class="col-md-3">
								<select
									[(ngModel)]="model.installationDateOperation"
									name="installationDateOperation"
									class="form-control"
								>
									<option value="" label="{{ 'g.not_selected' | translate }}">
										{{ "g.not_selected" | translate }}
									</option>
									<option value="=" label="=">=</option>
									<option value=">" label=">">></option>
									<option value="<" label="<"><</option>
									<option value="!=" label="!=">!=</option>
								</select>
							</div>
				<div class="col-md-5">
				<input class="form-control" name="installationDate" [(ngModel)]="model.installationDate" bsDatepicker #installationDate="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }" [disabled]="!model.installationDateOperation">
							</div>
						</div>
					</div>
				</fieldset>
				<div>
					<div class="row col-md-12 p-3">
						<div class="col-md-4">
							<strong>{{
								"device_report.disconnected_devices_criteria" | translate
							}}</strong>
						</div>
						<div class="col-md-8">
							<input
								type="text"
								class="form-control"
								[placeholder]="'device_report.disconnected_devices_criteria' | translate"
								name="disconnectedDevicesCriteria"
								[(ngModel)]="model.disconnectedDevicesCriteria"
								(input)="restrictToIntegers($event)"
							>
						</div>
					</div>
					<div class="row col-md-12 p-3">
						<div class="col-md-4">
							<strong>{{
								"device_report.service_note" | translate
							}}</strong>
						</div>
						<div class="col-md-8">
							<input type="text" class="form-control" [placeholder]="'device_report.service_note' | translate" name="serviceNote" [(ngModel)]="model.serviceNote">
						</div>
					</div>
				</div>
			</div>
			<div class="form-buttons d-flex justify-content-end">
				<button
					class="btn btn-primary mb20"
					type="button"
					(click)="showSubmitModal()"
				>
					<span class="glyphicon glyphicon-search"></span>
					{{ "g.search" | translate }}
				</button>
			</div>
		</div>
	</div>
</form>

<app-modal
	#modal
	[title]="'g.report_name' | translate"
	[confirmBtnText]="'g.submit' | translate"
	[hasCloseButton]="false"
>
	<span>{{ "g.enter_report_name" | translate }}</span>
	<input
		type="text"
		class="form-control"
		[(ngModel)]="reportName"
		placeholder="{{ 'g.report_name' | translate }}"
	/>
</app-modal>
