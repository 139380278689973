import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import * as moment from 'moment';
import { UsersService } from 'src/app/users/users.service';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { DecimalPipe } from '@angular/common';

@Injectable()
export class ChartDataService {

	user;
	enablePrintPeriod = false;
	enablePrintOperation = false;
	enablePrintModel = false;
	enablePrintPluggedIn = false;
	enablePrintEQtype = false;
	disableMissedPlugs = true;
	voltageLevels = [];

	constructor(
		private userService: UsersService,
		private deviceService: DeviceService
	) {
		this.user = this.userService.getCurrentUser();
	}

	getCountOfDaysForPeriod(period) {
		let countOfDays = 7;
		switch(period) {
			case 'yesterday':
				countOfDays = 1;
			break;
			case 'last_week':
			case 'last_7_days':
				countOfDays = 7;
			break;
			case 'last_30_days':
				countOfDays = 30;
			break;
			case 'last_90_days':
				countOfDays = 90;
			break;
		}
		return countOfDays;
	}

	generateWidgetData(data, widgetName, options) {
		let dateRange				= options.dateRange,
			truckType				= options.truckType,
			truckYear				= options.truckYear,
			batteryCapacityFilter	= options.batteryCapacity,
			batteryVoltageFilter	= options.batteryVoltage,
			batteryTypeFilter		= options.batteryType,
			devices					= options.devices,
			tags					= options.tags,
			deviceTags				= options.deviceTags,
			regenOption				= options.regenOption;

		if (!data)
			return false;

		let returnData: any = {};

		let widgetData = {};
		if(data[widgetName] && data[widgetName][dateRange])
			widgetData = data[widgetName][dateRange];

		let filteredDevices = this.filterDevicesByTags(devices, tags, deviceTags);
		let defaultCountOfDays = this.getCountOfDaysForPeriod(dateRange);

		switch (widgetName) {
			case 'truck_usage_duration':
			case 'truck_usage_as':
			case 'truck_usage_ws':
				for(let macAddress in widgetData) {
					if(!filteredDevices[macAddress])
						continue;

					// filter filteredDevices
					if(truckType && truckType != 'all' && filteredDevices[macAddress].truck_type != truckType)
						continue;
					if(
						truckYear && (
							truckYear == 'new' && !this.deviceService.isNewDevice(filteredDevices[macAddress].truck_manufacturing_year) ||
							truckYear == 'old' && this.deviceService.isNewDevice(filteredDevices[macAddress].truck_manufacturing_year)
					)) continue;

					let countOfDays = defaultCountOfDays;
					if(data.available_days && data.available_days[dateRange] && data.available_days[dateRange][macAddress])
						countOfDays = data.available_days[dateRange][macAddress];

					let avg = 0;
					if(countOfDays > 0)
						avg = widgetData[macAddress] / countOfDays;

					returnData[macAddress] = {};
					returnData[macAddress].total = widgetData[macAddress] || 0;
					returnData[macAddress].countOfDays = countOfDays;
					returnData[macAddress].avg = avg;
				}
			break;
			case 'regen_report':
				widgetData = {};
				if(data.total_charge_as_in_idle_and_inuse_events && data.total_charge_as_in_idle_and_inuse_events[dateRange])
					widgetData = data.total_charge_as_in_idle_and_inuse_events[dateRange];

				for(let macAddress in widgetData) {
					if(!filteredDevices[macAddress])
						continue;

					// filter filteredDevices
					if(truckType && truckType != 'all' && filteredDevices[macAddress].truck_type != truckType)
						continue;

					if(
						truckYear && (
							truckYear == 'new' && !this.deviceService.isNewDevice(filteredDevices[macAddress].truck_manufacturing_year) ||
							truckYear == 'old' && this.deviceService.isNewDevice(filteredDevices[macAddress].truck_manufacturing_year)
					)) continue;

					let countOfDays = defaultCountOfDays;
					if(data.available_days && data.available_days[dateRange] && data.available_days[dateRange][macAddress])
						countOfDays = data.available_days[dateRange][macAddress];

					let asValue = widgetData[macAddress] || 0;
					let ahrValue = asValue / 3600;
					let total;
					if(regenOption == 'usage') {
						if(data.truck_usage_as[dateRange][macAddress] == 0)
							continue;

						total = asValue * 100 / data.truck_usage_as[dateRange][macAddress];
					} else if(regenOption == 'capacity') {
						if(devices[macAddress].battery_capacity == 0)
							continue;

						total = ahrValue * 100 / devices[macAddress].battery_capacity;
					} else
						total = ahrValue;

					returnData[macAddress] = {};
					returnData[macAddress].total = total || 0;
					returnData[macAddress].countOfDays = countOfDays;
					returnData[macAddress].avg = total / countOfDays;
				}
			break;
			case 'idle_duration':
				if(!data.idle_duration_per_weekday || !data.idle_duration_per_weekday[dateRange])
					break;

				for(let dayIndex in data.idle_duration_per_weekday[dateRange]) {
					for(let macAddress in data.idle_duration_per_weekday[dateRange][dayIndex]) {
						if(!filteredDevices[macAddress])
							continue;

						// filter filteredDevices
						if(truckType && truckType != 'all' && filteredDevices[macAddress].truck_type != truckType)
							continue;
						if(
							truckYear && (
								truckYear == 'new' && !this.deviceService.isNewDevice(filteredDevices[macAddress].truck_manufacturing_year) ||
								truckYear == 'old' && this.deviceService.isNewDevice(filteredDevices[macAddress].truck_manufacturing_year)
						)) continue;

						let avg = 0;
						if(
							data.idle_duration_per_weekday[dateRange][dayIndex] && data.idle_duration_per_weekday[dateRange][dayIndex][macAddress] &&
							data.idle_occurences_per_weekday[dateRange][dayIndex] && data.idle_occurences_per_weekday[dateRange][dayIndex][macAddress]
						)
							avg = data.idle_duration_per_weekday[dateRange][dayIndex][macAddress] / data.idle_occurences_per_weekday[dateRange][dayIndex][macAddress];

						// temporary remove averages
						// avg = data.idle_duration_per_weekday[dateRange][dayIndex][macAddress];

						// ignore less than half an hour
						if (avg < (30*60))
							continue;

						if(!returnData[macAddress])
							returnData[macAddress] = {};

						returnData[macAddress][dayIndex] = avg;
					}
				}
			break;
			case 'relative_truck_usage_kws':
				let maxKWHR = 0;
				widgetData = {};
				if(data.truck_usage_ws && data.truck_usage_ws[dateRange])
					widgetData = data.truck_usage_ws[dateRange];

				for(let macAddress in widgetData) {
					if(!filteredDevices[macAddress])
						continue;

					// filter filteredDevices
					if(truckType && truckType != 'all' && filteredDevices[macAddress].truck_type != truckType)
						continue;

					if(
						truckYear && (
							truckYear == 'new' && !this.deviceService.isNewDevice(filteredDevices[macAddress].truck_manufacturing_year) ||
							truckYear == 'old' && this.deviceService.isNewDevice(filteredDevices[macAddress].truck_manufacturing_year)
					)) continue;
					if(batteryCapacityFilter && batteryCapacityFilter != 'all' && filteredDevices[macAddress].battery_capacity != batteryCapacityFilter)
						continue;
					if(batteryVoltageFilter && batteryVoltageFilter != 'all' && filteredDevices[macAddress].battery_voltage != batteryVoltageFilter)
						continue;

					if(maxKWHR < widgetData[macAddress])
						maxKWHR = widgetData[macAddress];
				}

				for(let macAddress in widgetData) {
					if(!filteredDevices[macAddress])
						continue;

					// filter filteredDevices
					if(truckType && truckType != 'all' && filteredDevices[macAddress].truck_type != truckType)
						continue;
					if(
						truckYear && (
							truckYear == 'new' && !this.deviceService.isNewDevice(filteredDevices[macAddress].truck_manufacturing_year) ||
							truckYear == 'old' && this.deviceService.isNewDevice(filteredDevices[macAddress].truck_manufacturing_year)
					)) continue;
					if(batteryCapacityFilter && batteryCapacityFilter != 'all' && filteredDevices[macAddress].battery_capacity != batteryCapacityFilter)
						continue;
					if(batteryVoltageFilter && batteryVoltageFilter != 'all' && filteredDevices[macAddress].battery_voltage != batteryVoltageFilter)
						continue;

					let avg = 0;
					if(maxKWHR > 0)
						avg = widgetData[macAddress] / maxKWHR;

					returnData[macAddress] = avg * 100;
				}
			break;
			case 'end_of_voltages_vpc':
				for(let eventId in widgetData) {
					let macAddress = eventId.split('.')[0];
					if(!filteredDevices[macAddress])
						continue;

					// filter filteredDevices
					if(truckType && truckType != 'all' && filteredDevices[macAddress].truck_type != truckType)
						continue;
					if(
						truckYear && (
							truckYear == 'new' && !this.deviceService.isNewDevice(filteredDevices[macAddress].truck_manufacturing_year) ||
							truckYear == 'old' && this.deviceService.isNewDevice(filteredDevices[macAddress].truck_manufacturing_year)
					)) continue;
					if(batteryTypeFilter && batteryTypeFilter != 'all' && filteredDevices[macAddress].battery_type != batteryTypeFilter)
						continue;

					returnData[eventId] = widgetData[eventId];
				}
			break;
		}
		return returnData;
	}

	getWidgetGroups(widgetName, options?) {
		options = options || {};
		let groups	= [],
			min, max;
		switch (widgetName) {
			case 'truck_usage_duration':
				groups = [
					["< 2", 2],
					["2-4", 4],
					["4-6", 6],
					["6-8", 8],
					["8-10", 10],
					["10-12", 12],
					["12-14", 14],
					["14-16", 16],
					["16-18", 18],
					["18-20", 20],
					["20-22", 22],
					["> 22", 48],
				];
			break;
			case 'truck_usage_as':
				groups = [
					["< 0.25", 0.25],
					["0.25 - 0.5", 0.5],
					["0.5 - 0.75", 0.75],
					["0.75 - 1", 1],
					["1 - 1.25", 1.25],
					["1.25 - 1.5", 1.5],
					["1.5 - 1.75", 1.75],
					["1.75 - 2", 2],
					["2 - 2.25", 2.25],
					["2.25 - 2.5", 2.5],
					["2.5 - 2.75", 2.75],
					["2.75 - 3", 3],
					["> 3", 9999],
				];
			break;
			case 'regen_report':
				if(!options.max)
					options.max = 1000;

				min = 0;
				max = 1.05 * options.max;
				if(options.regenOption != 'ahr' && max < 5)
					max = 5;

				let step = (max - min) / 10;
				if(options.regenOption == 'ahr')
					step = Math.round(step);
				else
					step = Math.round(step * 100) / 100;

				if(step == 0)
					step = 1;

				let lastValue = min;
				for(let i = min; i < max; i+=step) {
					let j = Math.round(i * 100) / 100;
					lastValue = Math.round((i+step) * 100) / 100;
					groups.push([j+'-'+lastValue, lastValue]);
				}
				groups.push(['> '+lastValue, lastValue]);
			break;
			case 'truck_usage_ws':
				groups = [
					["< 5", 5],
					["5 - 10", 10],
					["10 - 15", 15],
					["15 - 20", 20],
					["20 - 25", 25],
					["25 - 30", 30],
					["30 - 35", 35],
					["35 - 40", 40],
					["40 - 45", 45],
					["45 - 50", 50],
					["> 50", 9999],
				];
			break;
			case 'idle_duration':
				groups = [
					['<30 mins', 30],
					['30-60 mins', 60],
					['60-120 mins', 120],
					['120-240 mins', 240],
					['>240 mins', 1440]
				];
			break;
			case 'end_of_voltages_vpc':
				if(!options.min) {
					options.min = 1;
				}
				if(!options.max) {
					options.max = 2;
				}
				min = Math.floor((1.9/2)  * options.min * 100);
				max = Math.ceil(1.05 * options.max * 100);
				if(min < 190 && max > 190) {
					min = 190;
				}

				groups.push(['<'+(min/100), (min/100), this.getEndVoltageColors((min/100))]);
				for(let i = min; i <= max; i++) {
					groups.push([(i/100), ((i/100)+0.01), this.getEndVoltageColors((i/100))]);
				}
			break;
		}
		return groups;
	}

	getEndVoltageColors(value) {
		const matchedLevel = this.voltageLevels.find((level) => value >= level.min && value <= level.max);
		const color = matchedLevel ? matchedLevel.color : this.voltageLevels[0].color;
		return color;
	}

	setVoltageLevels(levels) {
		this.voltageLevels = levels;
	}

	getWidgetDataFormatter(widgetName) {
		let formatterFunction:any;
		let decimalPipe: DecimalPipe = new DecimalPipe("en-US");
		switch (widgetName) {
			case 'truck_usage_duration':
			case 'idle_duration':
				formatterFunction = (value) => {
					if(widgetName == 'idle_duration') {
						value /= 60;
					}
					let timeValue: any = moment.duration(value, 'hours');
					timeValue = {
						hrs: timeValue.days() * 24 + timeValue.hours(),
						min: timeValue.minutes()
					}
					for(let t in timeValue)
						if(timeValue[t] < 10) timeValue[t] = '0'+timeValue[t];
					return timeValue.hrs + ':' + timeValue.min;
				}
			break;
			case 'truck_usage_as':
			case 'regen_report':
				formatterFunction = (value) => {
					return decimalPipe.transform(value, '1.0-2');
				}
			break;
			case 'truck_usage_ws':
				formatterFunction = (value) => {
					return decimalPipe.transform(value, '1.0-1');
				}
			break;
			case 'relative_truck_usage_kws':
				formatterFunction = (value) => {
					return Math.round(value);
				}
			break;
			default:
				formatterFunction = (value) => {
					return value;
				}
		}
		return formatterFunction;
	}

	scaleValue(value, device, widgetName) {
		let scaledValue = value;
		switch (widgetName) {
			case 'truck_usage_duration':
				scaledValue /= 3600;
			break;
			case 'truck_usage_ws':
				scaledValue /= (3600 * 1000);
			break;
			case 'idle_duration':
				scaledValue /= 60;
			break;
			case 'truck_usage_as':
				scaledValue /= (device.battery_capacity * 0.8 * 3600);
			break;
		}
		return scaledValue;
	}

	filterDevicesByTags(devices, tags, deviceTags) {
		if(!tags || !tags.length)
			return devices;

		let filteredDevices = {};
		for(let macAddress in devices) {
			let device = devices[macAddress];
			if(deviceTags[macAddress]) {
				let takeTheDevice = false;
				for(let item of deviceTags[macAddress]) {
					if(tags.includes(+item.tag_id)) {
						takeTheDevice = true;
						break;
					}
				}
				if(takeTheDevice) {
					filteredDevices[macAddress] = device;
				}
			}
		}
		return filteredDevices;
	}
}
