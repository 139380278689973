<div class="white-box p-3 mb-3" *ngIf="isShowCustomer">
	<app-site-fleet-utilization-score *ngIf="data.site_fleet_utilization_score" [data]="data.site_fleet_utilization_score" [devices]="devices"></app-site-fleet-utilization-score>
	<app-site-energy-usage-score *ngIf="data.site_energy_usage_score" [data]="data.site_energy_usage_score" [devices]="devices"></app-site-energy-usage-score>
	<app-truck-utilization-score
		*ngIf="data.site_fleet_utilization_score || data.site_energy_usage_score"
		[utilizationScoreData]="data.site_fleet_utilization_score"
		[energyScoreData]="data.site_energy_usage_score"
		[devices]="devices"
	></app-truck-utilization-score>
	<app-daily-fleet-utilization *ngIf="data.daily_fleet_utilization" [data]="data.daily_fleet_utilization" [devices]="devices"></app-daily-fleet-utilization>
	<app-hourly-fleet-utilization *ngIf="data.hourly_fleet_utilization" [data]="data.hourly_fleet_utilization" [devices]="devices"></app-hourly-fleet-utilization>
	<app-daily-ah-fleet-utilization *ngIf="data.daily_ah_fleet_utilization" [data]="data.daily_ah_fleet_utilization" [devices]="devices"></app-daily-ah-fleet-utilization>
</div>

<app-site-performance></app-site-performance>
