
<div class="main-black border-radius-bottom white-box">
  <div class="row m-0 px-4">
    <div class="col-12">
      <div class="row m-0 mt-3">
        <h3 class="font-weight-bold">{{customer.customer_name}}</h3>
        <a *ngIf="userService.hasAccessFunction('customer_management') && currentUser.isSCT"
          class="btn main-green-bg text-white ml-auto px-4 my-auto rounded-lg"
          [routerLink]="['/customers', 'edit', customer.id]">
          {{'customer.edit_customer' | translate}}
        </a>
        <div class="w-100" *ngIf="currentUser.isSCT && customer.original_enterprise">
          <p class="badge text-white bg-main-black-color">
            {{'customer.this_customer_is_generated' | translate}}
            <span *ngIf="!userService.hasAccessFunction('enterprise_management')">
              {{customer.original_enterprise_name}}
            </span>
            <a *ngIf="userService.hasAccessFunction('enterprise_management')"  routerLink="/enterprise/edit/{{customer.original_enterprise}}"> {{customer.original_enterprise_name}} </a>
          </p>
        </div>
      </div>

      <ng-container *ngIf="customer.customer_contact_name || customer.customer_phone_num">
        <h5 class="font-weight-bold mt-4">
          {{'g.contact_info' | translate}}
          <app-help-tooltip *ngIf="tooltips.customer_contact_info" [field]="tooltips.customer_contact_info"></app-help-tooltip>
        </h5>
        <p class="light-grey">{{customer.customer_contact_name}}</p>
        <p class="light-grey">{{customer.customer_phone_num}}</p>
      </ng-container>
    </div>
  </div>
	<div class="row m-0 px-4" [ngClass]="{'py-5': !commonService.isTabletOrMobileScreen, 'py-3': commonService.isTabletOrMobileScreen}">
		<div class="col-xl-6 col-md-12 order-md-2 order-xl-1">
			<app-info-box *ngIf="infoBoxSiteAnalytics"
				[ioTAhDevices]="infoBoxSiteAnalytics.ioTAhDevices"
				[chargLinkDevices]="infoBoxSiteAnalytics.chargLinkDevices"
			></app-info-box>
		</div>
		<div class="col-xl-6 col-md-12 order-md-1 order-xl-2">
			<div class="mapbox-widget">
				<app-mapbox
					class="border-radius main-blue-border"
					dotsType="sites"
					[latitude]="sitesService.defaultMapLocation.latitude"
					[longitude]="sitesService.defaultMapLocation.longitude"
					zoom="2.8"
					[dots]="sites"
				></app-mapbox>
			</div>
		</div>
	</div>
</div>

<div class="white-box main-black mt-4 p-4 border-radius" *ngIf="shouldShowStudiesSection()">
	<app-studies-list parent="customer" [customerId]="customerId" [customerName]="customer.customer_name"></app-studies-list>
</div>

<!-- Iotah devices-->
<div class="white-box main-black mt-4 p-4 border-radius" *ngIf="selectedMonthData && iotahDevices.length > 0">
	<div class="row">
		<div class="col">
			<h4>
				{{'site.ioTAh_devices.site_summary_report' | translate:{customer: customer.customer_name} }}
				<app-help-tooltip *ngIf="tooltips.monthly_customer_report" [field]="tooltips.monthly_customer_report"></app-help-tooltip>
			</h4>
		</div>
	</div>
	<div class="row py-4">
		<div class="col-sm-6 col-md-3 d-inline-flex">
			<ng-select [searchable]="false" class="mr-auto w-100" [items]="yearList" bindLabel="label" bindValue="id" placeholder="{{'site.year' | translate}}" name="year" [(ngModel)]="monthReportFilters.year" (ngModelChange)="loadMonthData(true)" [clearable]="false">
			</ng-select>
		</div>
		<div class="col-sm-6 col-md-3 d-inline-flex">
			<ng-select [searchable]="false" class="mr-auto w-100" [items]="monthList" bindLabel="label" bindValue="id" placeholder="{{'site.month' | translate}}" name="month" [(ngModel)]="monthReportFilters.month" (ngModelChange)="loadMonthData(false)" [clearable]="false">
			</ng-select>
		</div>
		<div class="col-sm-6 col-md-1 ml-auto pl-5 d-flex flex-row-reverse" [ngClass]="{'pt-2': commonService.isMobileScreen}">
			<button class="fa fa-upload btn btn-primary" title="{{'g.export_to_csv' | translate}}" (click)="exportData(monthData, 'site.ioTAh_devices.site_summary_report')"></button>
		</div>
	</div>
	<div>
		<sct-custom-table
			[columnsConfig]="trucksReportConfig.columns"
			[data]="trucksReportConfig.data"
			[config]="trucksReportConfig.table"
		></sct-custom-table>
	</div>
</div>

<!-- battlink devices -->
<div class="white-box main-black mt-4 p-4 border-radius" *ngIf="battlinkSelectedMonthData && battlinkDevices.length > 0">
	<div class="row">
		<div class="col">
			<h4>
				{{'site.battLink_devices.site_summary_report' | translate:{customer: customer.customer_name} }}
				<app-help-tooltip *ngIf="tooltips.monthly_customer_report" [field]="tooltips.monthly_customer_report"></app-help-tooltip>
			</h4>
		</div>
	</div>
	<div class="row py-4">
		<div class="col-sm-6 col-md-3 d-inline-flex">
			<ng-select [searchable]="false" class="mr-auto w-100" [items]="yearList" bindLabel="label" bindValue="id" placeholder="{{'site.year' | translate}}" name="year" [(ngModel)]="battlinkMonthReportFilters.year" (ngModelChange)="loadBattlinkMonthData(true)" [clearable]="false">
			</ng-select>
		</div>
		<div class="col-sm-6 col-md-3 d-inline-flex">
			<ng-select [searchable]="false" class="mr-auto w-100" [items]="monthList" bindLabel="label" bindValue="id" placeholder="{{'site.month' | translate}}" name="quarter" [(ngModel)]="battlinkMonthReportFilters.month" (ngModelChange)="loadBattlinkMonthData(false)" [clearable]="false">
			</ng-select>
		</div>
		<div class="col-sm-6 col-md-1 ml-auto pl-5 d-flex flex-row-reverse" [ngClass]="{'pt-2': commonService.isMobileScreen}">
			<button class="fa fa-upload btn btn-primary" title="{{'g.export_to_csv' | translate}}" (click)="exportBattlinkData(battlinkMonthData, 'site.battLink_devices.site_summary_report')"></button>
		</div>
	</div>
	<div>
		<sct-custom-table
			[columnsConfig]="battlinkReportConfig.columns"
			[data]="battlinkReportConfig.data"
			[config]="battlinkReportConfig.table"
		></sct-custom-table>
	</div>
</div>

<!-- charglink devices -->
<div class="white-box main-black mt-4 p-4 border-radius" *ngIf="selectedQuarterData && chargLinkDevices.length > 0">
	<div class="row">
		<div class="col">
			<h4>
				{{'site.chargLink_devices.site_summary_report' | translate:{customer: customer.customer_name} }}
				<app-help-tooltip *ngIf="tooltips.quarterly_customer_report" [field]="tooltips.quarterly_customer_report"></app-help-tooltip>
			</h4>
		</div>
	</div>
	<div class="row py-4">
		<div class="col-sm-6 col-md-3 d-inline-flex">
			<ng-select [searchable]="false" class="mr-auto w-100" [items]="quartersList" bindLabel="label" bindValue="id" placeholder="{{'site.quarter' | translate}}" name="quarter" [(ngModel)]="quarterReportFilters.quarter" (ngModelChange)="loadQuarterData()" [clearable]="false">
			</ng-select>
		</div>
		<div class="col-sm-6 col-md-1 ml-auto pl-5 d-flex flex-row-reverse" [ngClass]="{'pt-2': commonService.isMobileScreen}">
			<button class="fa fa-upload btn btn-primary" title="{{'g.export_to_csv' | translate}}" (click)="exportQuarterData('site.chargLink_devices.site_summary_report')"></button>
		</div>
	</div>
	<div>
		<sct-custom-table
			[columnsConfig]="chargersReportConfig.columns"
			[data]="chargersReportConfig.data"
			[config]="chargersReportConfig.table"
		></sct-custom-table>
	</div>
</div>

<!-- Iotah devices-->
<app-quarterly-usage-data *ngIf="iotahDevices.length > 0"
	[data]="sitesPerformanceData.months.iotah"
	[devices]="iotahDevices"
	[deviceType]="DeviceFactory.types.iotah"
	[sites]="sites"
	[options]="{hasTruckTypeFilter: true, hasYearFilter: true, hasStackedColumn: true, hasSiteFilter: true}"
	[siteInfo]="{id: customer.id, name: customer.customer_name}"
	parentPage="customer"
	[truckTypes]="customer.sitesAnalytics.trucksIndexes.iotah"
></app-quarterly-usage-data>

<!-- battlink devices -->
<app-quarterly-usage-data *ngIf="battlinkDevices.length > 0"
	[data]="sitesPerformanceData.months.battlink"
	[devices]="battlinkDevices"
	[deviceType]="DeviceFactory.types.battlink"
	[sites]="sites"
	[options]="{hasTruckTypeFilter: true, hasYearFilter: true, hasStackedColumn: true, hasSiteFilter: true}"
	[siteInfo]="{id: customer.id, name: customer.customer_name}"
	parentPage="customer"
	[truckTypes]="customer.sitesAnalytics.trucksIndexes.battlink"
></app-quarterly-usage-data>

<!-- charglink devices -->
<app-quarterly-usage-data *ngIf="chargLinkDevices.length > 0"
	[data]="sitesPerformanceData.quarter.chargLink"
	[devices]="chargLinkDevices"
	[deviceType]="DeviceFactory.types.charglink"
	[sites]="sites"
	[options]="{hasTruckTypeFilter: true, hasYearFilter: true, hasStackedColumn: true, hasSiteFilter: true}"
	[siteInfo]="{id: customer.id, name: customer.customer_name}"
	parentPage="customer"
	[truckTypes]="customer.sitesAnalytics.trucksIndexes.chargLink"
></app-quarterly-usage-data>

<!-- battlink devices -->
<app-energy-utilization-charts
	*ngIf="hasEnergyUtilizationData('battlink')"
	[deviceType]="DeviceFactory.types.battlink"
	[kwhMonthlyData]="customer?.sitesAnalytics?.utilizationData?.battlink?.monthly.kwh_utilization || {}"
	[kwhQuarterlyData]="customer?.sitesAnalytics?.utilizationData?.battlink?.quarterly.kwh_utilization || {}"
	[ahMonthlyData]="customer?.sitesAnalytics?.utilizationData?.battlink?.monthly.ah_utilization || {}"
	[ahQuarterlyData]="customer?.sitesAnalytics?.utilizationData?.battlink?.quarterly.ah_utilization || {}"
></app-energy-utilization-charts>

<!-- charglink devices -->
<app-energy-utilization-charts
	*ngIf="hasEnergyUtilizationData('chargLink')"
	[deviceType]="DeviceFactory.types.charglink"
	[kwhMonthlyData]="customer?.sitesAnalytics?.utilizationData?.chargLink?.monthly.kwh_utilization || {}"
	[kwhQuarterlyData]="customer?.sitesAnalytics?.utilizationData?.chargLink?.quarterly.kwh_utilization || {}"
	[ahMonthlyData]="customer?.sitesAnalytics?.utilizationData?.chargLink?.monthly.ah_utilization || {}"
	[ahQuarterlyData]="customer?.sitesAnalytics?.utilizationData?.chargLink?.quarterly.ah_utilization || {}"
></app-energy-utilization-charts>

<div class="white-box main-black mt-4 p-4 border-radius" *ngIf="customer.id">
	<app-note
		[title]="'note.customer_notes' | translate"
		[modelType]="'customer'"
		[modelId]="customer.id"
		[limit]="3"
		[tooltipKey]="tooltips.customer_notes"
	></app-note>
</div>
