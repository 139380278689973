<div class="white-box p-4 border-radius-bottom">
	<div class="preperty-values row">
		<div class="centered-values col-4" *ngIf="canAccessNumbers()">
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'debug_start')">
				<div class="p-4">{{'global_records.debug_start' | translate}}</div>
				<div class="p-4">{{ data.debug_start | number }}</div>
			</div>
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'debug_end')">
				<div class="p-4">{{'global_records.debug_count' | translate}}</div>
				<div class="p-4">{{ data.debug_end | number }}</div>
			</div>
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'events_start')">
				<div class="p-4">{{'global_records.events_start' | translate}}</div>
				<div class="p-4">{{ data.events_start | number }}</div>
			</div>
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'events_end')">
				<div class="p-4">
					{{'global_records.events_count' | translate}}
					<app-help-tooltip [isSmall]="true" field="events_end" class="float-right"></app-help-tooltip>
				</div>
				<div class="p-4">{{ data.events_end | number}}</div>
			</div>
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'rt_start')">
				<div class="p-4">{{'global_records.rt_start' | translate}}</div>
				<div class="p-4">{{ data.rt_start | number }}</div>
			</div>
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'rt_end')">
				<div class="p-4">{{'global_records.rt_count' | translate}}</div>
				<div class="p-4">{{ data.rt_end | number}}</div>
			</div>
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'inuse_ahr')">
				<div class="p-4">
					{{'global_records.total_inuse_ahr' | translate}}
					<app-help-tooltip [isSmall]="true" [field]="tooltips.total_inuse_ahr" class="float-right"></app-help-tooltip>
				</div>
				<div class="p-4">{{ (+data.add_inuse_ahr) + (+data.inuse_ahr) | number}}</div>
			</div>
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'inuse_kwhr')">
				<div class="p-4">
					{{'global_records.total_inuse_kwhr' | translate}}
					<app-help-tooltip [isSmall]="true" [field]="tooltips.total_inuse_kwhr" class="float-right"></app-help-tooltip>
				</div>
				<div class="p-4">{{ (+data.add_inuse_kwhr) + (+data.inuse_kwhr) | number}}</div>
			</div>
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'charge_ahr')">
				<div class="p-4">{{'global_records.total_charge_ahr' | translate}}</div>
				<div class="p-4">{{ (+data.add_charge_ahr) + (+data.charge_ahr) | number}}</div>
			</div>
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'charge_kwhr')">
				<div class="p-4">{{'global_records.total_charge_kwhr' | translate}}</div>
				<div class="p-4">{{ (+data.add_charge_kwhr) + (+data.charge_kwhr) | number}}</div>
			</div>
		</div>
		<div class="col-4" *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'debug_data', 'read')">
			<div>
				<div class="p-4">{{'global_records.left_over_inuse_as' | translate}}</div>
				<div class="p-4">{{(data.left_over_inuse_as || 0) / 3600 | number: '1.0-2'}}</div>
			</div>
			<div>
				<div class="p-4">{{'global_records.left_over_inuse_ws' | translate}}</div>
				<div class="p-4">{{(data.left_over_inuse_ws || 0) / 3600000 | number: '1.0-2'}}</div>
			</div>
			<div>
				<div class="p-4">{{'global_records.left_over_charge_as' | translate}}</div>
				<div class="p-4">{{(data.left_over_charge_as || 0) / 3600 | number: '1.0-2'}}</div>
			</div>
			<div>
				<div class="p-4">{{'global_records.left_over_charge_ws' | translate}}</div>
				<div class="p-4">{{(data.left_over_charge_ws || 0) / 3600000 | number: '1.0-2'}}</div>
			</div>
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'debug_data')">
				<div class="p-4">{{'global_records.event_reset_factory_id' | translate}}</div>
				<div class="p-4">{{ data.event_reset_factory_id | number}}</div>
			</div>
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'debug_data')">
				<div class="p-4">{{'global_records.rt_reset_factory_id' | translate}}</div>
				<div class="p-4">{{ data.rt_reset_factory_id | number}}</div>
			</div>
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'debug_data')">
				<div class="p-4">{{'global_records.last_debug_id' | translate}}</div>
				<div class="p-4">{{ data.last_debug_id | number}}</div>
			</div>
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'debug_data')">
				<div class="p-4">{{'global_records.last_event_id' | translate}}</div>
				<div class="p-4">{{ data.last_event_id | number}}</div>
			</div>
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'debug_data')">
				<div class="p-4">{{'global_records.last_rt_id' | translate}}</div>
				<div class="p-4">{{ data.last_rt_id | number}}</div>
			</div>
		</div>
		<div class="col-4">
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'inuse_seconds')">
				<div class="p-4">
					{{'global_records.total_inuse_events_time' | translate}}
					<app-help-tooltip [isSmall]="true" [field]="tooltips.total_inuse_events_time" class="float-right"></app-help-tooltip>
				</div>
				<div class="p-4">{{times['inuse_seconds']}}</div>
			</div>
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'charge_seconds')">
				<div class="p-4">{{'global_records.total_charge_time' | translate}}</div>
				<div class="p-4">{{times['charge_seconds']}}</div>
			</div>
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'idle_seconds')">
				<div class="p-4">
					{{'global_records.total_idle_time' | translate}}
					<app-help-tooltip [isSmall]="true" [field]="tooltips.total_idle_time" class="float-right"></app-help-tooltip>
				</div>
				<div class="p-4">{{times['idle_seconds']}}</div>
			</div>
			<div *ngIf="this.deviceConfig.fields.deadManTime && usersService.hasAccessPermission(enterprisePermissions, 'accurate_inuse_seconds')">
				<div class="p-4">
					{{'global_records.dead_man_time' | translate}}
					<app-help-tooltip [isSmall]="true" [field]="tooltips.dead_man_time" class="float-right"></app-help-tooltip>
				</div>
				<div class="p-4">{{times['accurate_inuse_seconds']}}</div>
			</div>
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'debug_data', 'read')">
				<div class="p-4">{{'global_records.accurate_charge_seconds' | translate}}</div>
				<div class="p-4">{{times['accurate_charge_seconds']}}</div>
			</div>
		</div>
	</div>
</div>
