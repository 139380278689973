<div *ngIf="hasDevices">
	<app-battery-fleet-utilization [siteId]="siteId" [customerId]="customerId" [devices]="devices" [data]="data" [deviceTags]="deviceTags" [tags]="tags"></app-battery-fleet-utilization>
	<app-ebu-daily-usage-summary [siteId]="siteId" [customerId]="customerId" [devices]="devices" [data]="data" [deviceTags]="deviceTags" [tags]="tags"></app-ebu-daily-usage-summary>
	<app-eq-weekly-summary [siteId]="siteId" [customerId]="customerId" [devices]="devices" [data]="data" [deviceTags]="deviceTags" [tags]="tags"></app-eq-weekly-summary>
	<app-idle-after-event-time [siteId]="siteId" [customerId]="customerId" [devices]="devices" [data]="data" [deviceTags]="deviceTags" [tags]="tags"></app-idle-after-event-time>
	<app-hourly-idle-time [siteId]="siteId" [customerId]="customerId" [devices]="devices" [data]="data" [deviceTags]="deviceTags" [tags]="tags"></app-hourly-idle-time>
	<app-battery-alert-summary [siteId]="siteId" [customerId]="customerId" [devices]="devices" [data]="data" [deviceTags]="deviceTags" [tags]="tags"></app-battery-alert-summary>
	<app-battery-remaining-warranty [siteId]="siteId" [customerId]="customerId" [devices]="devices"></app-battery-remaining-warranty>
	<app-site-battery-aging [siteId]="siteId" [customerId]="customerId" [devices]="devices"></app-site-battery-aging>
</div>
