import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-battery-fleet-energy-usage-score',
	templateUrl: './battery-fleet-energy-usage-score.component.html',
	styleUrls: ['./battery-fleet-energy-usage-score.component.css']
})
export class BatteryFleetEnergyUsageScoreComponent {
	@Input() data = null;
}
