import { DeviceConfig, mainDeviceConfig } from './device-config';

export class IotahDevice implements DeviceConfig {
	config: mainDeviceConfig = {
		sct_device_type: 0,
		isIotah: true,
		isCharglink: false,
		isBattlink: false,
		isBrm: false,
		hasTypes: true,
		couldHaveStudy: true,
		typeString: 'IoTAh',
		translationPath: '',
		imageFolder: '/forklift',
		logoStyleClass: '',
		mapColor: 'orange',
		setLastMaintenanceDate: true,
		hasMaintenanceSchedule: true,
		hasBatterySettings: true,
		CO2data: true,
		fields: {
			kwhr: 'inuse_kwhr',
			hasMaxChargerKw: false,
			hasTemperatureSensor: true,
			hasWaterLevel: true,
			hasHallEffect: true,
			trickleVoltage: true,
			cvTargetVoltage: true,
			fiTargetVoltage: true,
			eqVoltage: true,
			trickleCurrentRate: true,
			ccRate: true,
			cvEndCurrentRate: true,
			cvCurrentStep: true,
			fiCurrentRate: true,
			eqCurrentRate: true,
			batteryCapacity: true,
			batteryType: true,
			numberOfCells: true,
			batteryTemperatureCompensationInMv: true,
			fiDt: true,
			fiDv: true,
			liftCurrentThresholdPercentage: true,
			liftCurrentThresholdValue: true,
			liftCurrentThresholdDeltaPercentage: true,
			liftCurrentThresholdDeltaValue: true,
			deadManTime: true,
			returnFactor: true,
			batteryEfficiency: true,
			chargerEfficiency: true,
			deadManCurrentLimit: true,
			chargerType: true,
		},
		requiredFields: {
			truckType: true,
			truckManufacturer: true,
			truckManufacturingYear: true,
			currentLiftLowerThreshold: true,
			currentLiftUpperThreshold: true,
		},
		incorrectEvent: {
			type: 'charge',
		},
		quarterlyUsage: {
			hasTypeFilter: true,
			hasYearFilter: true,
			performanceChartType: 't',
			hasChartLegend: false,
			chartGridColumns: 12,
			hasTotalTravelTimeChart: true,
			hasTotalLiftTimeChart: true,
			period: 'months',
			perfAnalyticsKey: 'perf_analytic.monthly_data',
		},
		reporting: {
			device: 'iotah',
		},
		dailyDetails: {
			hasField: {
				billed_kwh: true,
				accurate_inuse_seconds: true,
				lift_time: true,
				travel_time: true,
				ah_h: false,
				ebu: false,
			},
			hasTypes: {
				endVoltage: true,
				idleTimes: true,
			},
			useBilledKwh: false
		},
		events: {
			hasFields: {
				billedKwh: true,
				liftTime: true,
				travelTime: true,
				accurateInuseSeconds: true,
			}
		},
		usage: {
			summaryField: 'usage_summary',
			accurateSecondsField: 'accurate_inuse_seconds'
		},
		performance: {
			hasEndVoltageReport: true,
			hasIdleEventsReport: true,
			hasRegenReport: true,
			hasUsageAsReport: true,
			hasUsageTimeWidget: true,
			usageSummaryKey: 'amp_usage_summary',
			kwuUsageSummaryKey: 'kw_usage_summary',
			hasDataWidget: true,
			hasRelativeUsageWidget: true,
			hasBatteriesCustomizedCharts: false,
			chargeOpportunity: false,
		},
		customer: {
			mix_quarterly_usage_kwh: false
		},
	};

	getImagePath(imageType: 'not_connected' | 'connected' | 'inuse' | 'idle', options: {is_study_device: boolean} = {is_study_device : false}): string {
		let imageLink = '/images' + this.config.imageFolder;

		switch (imageType) {
			case 'not_connected':
				imageLink += `_gray.png`;
				if (options.is_study_device)
					imageLink = 'images/studies/not_connected.png';
				break;
			case 'connected':
				imageLink += `_connected.png`;
				if (options.is_study_device)
					imageLink = 'images/studies/connected.png';
				break;
			case 'inuse':
				imageLink = '/images/forklift_inuse.gif';
				break;
			case 'idle':
				imageLink = '/images/forklift_idle.png';
				break;
		}

		return imageLink;
	}

	getEventImage(eventType: string, is_study_device: boolean): string {
		let eventTypeText : 'not_connected' | 'connected' | 'inuse' | 'idle' = eventType == 'inuse'? 'inuse' : 'idle';

		if (is_study_device)
			eventTypeText = 'connected';

		return this.getImagePath( eventTypeText, {is_study_device});
	}

	getTypesString(options: {getPowerStudyDevices: boolean, is_study_device: boolean} = {getPowerStudyDevices: false, is_study_device: false}) {
		if (options.getPowerStudyDevices && this.config.couldHaveStudy && options.is_study_device)
			return 'Study';

		return 'IoTAh';
	}
}
