<h6 class="text-warning mb-4" *ngIf="highlightEmpty">* {{'form.highlighted_fields_help' | translate}}</h6>
<form name="{{name}}" #formRef="ngForm">
	<ng-container *ngFor="let field of fields; let idx = index;">
		<div class="row mt-2 align-items-center" [ngClass]="{'d-none': (field.controlledBy && !data[field.controlledBy])}" check-permission="{{field.permission}}" [params]="{permissions: permissions, permissionMapperKey: permissionMapperKey}">
			<div class="col-md-4" [ngSwitch]="field.type">
				<span *ngSwitchCase="'subSectionTitle'">
					<h3 class="mt-2">{{field.label | translate}}</h3>
				</span>
				<div class="d-flex justify-content-between" *ngSwitchDefault>
					<strong [ngClass]="{'text-warning': highlightEmptyField(field.name)}">
						{{field.label | translate}} <span *ngIf="field.required">*</span>
					</strong>
					<span class="ml-2">
						<app-help-tooltip [field]="field.tooltip" [isSmall]="true" [isTranslated]="field.tooltipIsTranslated || false" *ngIf="field.tooltip"></app-help-tooltip>
					</span>
				</div>
			</div>
			<div
				class="col-md-7 px-0"
				[ngSwitch]="field.type"
			>
				<span *ngSwitchCase="'subSectionTitle'">
				</span>
				<span *ngSwitchCase="'label'">
					{{field.value ? field.value : data[field.name]}}
				</span>
				<span *ngSwitchCase="'link'">
					<a [routerLink]="field.link">{{field.value ? field.value : data[field.name]}}</a>
				</span>
				<span *ngSwitchCase="'dateLabel'">
					{{data[field.name] * 1000 | date:(field.format||'MM/dd/yyyy hh:mm:ss a'):'UTC/GMT'}}
				</span>
				<div
					#dateEle
					[tooltip]="checkHasStack(
						dateEle,
						(isArray(field.name)?field.name.join('.'):field.name).replace('_fmt', '')
					) ? changesStackTooltip : ''"
					(onShown)="getStackHTML(dateEle, (isArray(field.name)?field.name.join('.'):field.name).replace('_fmt', ''))"
					placement="bottom"
					containerClass="changes-stack-tooltip"
					*ngSwitchCase="'date'"
				>
					<input class="form-control" placeholder="mm/dd/yyyy" name="{{field.name}}" [ngClass]="{'invalid-input' : invalidFields[field.name]}" [minDate]="field.minDate" [maxDate]="field.maxDate" [(ngModel)]="data[field.name]" bsDatepicker #d="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }" check-permission="{{field.permission}}" [params]="{permissions: permissions, permissionMapperKey: permissionMapperKey}" calender/>
				</div>
				<span *ngSwitchCase="'boolean'">
					<div
						class="form-check form-check-inline"
						#boolEle
						[tooltip]="checkHasStack(
							boolEle,
							(isArray(field.name)?field.name.join('.'):field.name),
							'bg'
						) ? changesStackTooltip : ''"
						(onShown)="getStackHTML(boolEle, (isArray(field.name)?field.name.join('.'):field.name))"
						containerClass="changes-stack-tooltip"
					>
						<mat-slide-toggle [(ngModel)]="data[field.name]" name="{{field.name}}" [disabled]="field.disabled || checkReadOnly(field.permission)"></mat-slide-toggle>
					</div>
				</span>
				<div
					#selectEle
					[tooltip]="checkHasStack(
						selectEle,
						(isArray(field.name)?field.name.join('.'):field.name),
						'bg'
					) ? changesStackTooltip : ''"
					(onShown)="getStackHTML(selectEle, (isArray(field.name)?field.name.join('.'):field.name))"
					containerClass="changes-stack-tooltip"
					*ngSwitchCase="'dropdown'"
				>
					<ng-select [items]="field.items" bindLabel="{{field.bindLabel}}" bindValue="{{field.bindValue}}" placeholder="{{(field.placeholder || field.label) | translate}}" [clearable]="field.clearable || false" name="{{field.name}}" [(ngModel)]="data[field.name]" check-permission="{{field.permission}}" [params]="{permissions: permissions, permissionMapperKey: permissionMapperKey}" *ngIf="!isArray(field.name); else nestedSelect" (ngModelChange)="onDropdownChanged(field.name, $event)" [disabled]="checkReadOnly(field.permission)" [multiple]="field.multiple"></ng-select>
					<ng-template #nestedSelect>
						<ng-select [items]="field.items" bindLabel="{{field.bindLabel}}" bindValue="{{field.bindValue}}" placeholder="{{(field.placeholder || field.label) | translate}}" [clearable]="field.clearable || false" name="{{field.name.join('.')}}" [(ngModel)]="data[field.name[0]][field.name[1]]" check-permission="{{field.permission}}" [params]="{permissions: permissions, permissionMapperKey: permissionMapperKey}" (ngModelChange)="onDropdownChanged(field.name, $event)" [disabled]="checkReadOnly(field.permission)"></ng-select>
					</ng-template>
				</div>
				<div
					#pwdEle
					[tooltip]="checkHasStack(
						pwdEle,
						(isArray(field.name)?field.name.join('.'):field.name)
					) ? changesStackTooltip : ''"
					(onShown)="getStackHTML(pwdEle, (isArray(field.name)?field.name.join('.'):field.name))"
					placement="bottom"
					containerClass="changes-stack-tooltip"
					*ngSwitchCase="'password'"
				>
					<input type="password" class="form-control" name="{{field.name}}" [(ngModel)]="data[field.name]" [ngClass]="{'invalid-input' : invalidFields[field.name]}" check-permission="{{field.permission}}" [params]="{permissions: permissions, permissionMapperKey: permissionMapperKey}" *ngIf="!isArray(field.name); else nestedInput" show-password/>
					<ng-template #nestedInput>
						<input type="password" class="form-control" name="{{field.name.join('.')}}" [(ngModel)]="data[field.name[0]][field.name[1]]" [ngClass]="{'invalid-input' : invalidFields[field.name[0]] && invalidFields[field.name[0]][field.name[1]]}" check-permission="{{field.permission}}" [params]="{permissions: permissions, permissionMapperKey: permissionMapperKey}" show-password/>
					</ng-template>
				</div>
				<div
					#ele
					[tooltip]="checkHasStack(
						ele,
						(isArray(field.name)?field.name.join('.'):field.name)
					) ? changesStackTooltip : ''"
					(onShown)="getStackHTML(ele, (isArray(field.name)?field.name.join('.'):field.name))"
					placement="bottom"
					containerClass="changes-stack-tooltip"
					*ngSwitchCase="'autocomplete'"
				>
					<input
						class="form-control"
						name="{{field.name}}"
						[(ngModel)]="data[field.name]"
						[attr.list]="field.name+'_list'"
						[ngClass]="{'invalid-input' : invalidFields[field.name]}"
						check-permission="{{field.permission}}"
						[params]="{permissions: permissions, permissionMapperKey: permissionMapperKey}"
					/>
					<datalist id="{{field.name}}_list">
						<option *ngFor="let item of field.items" [value]="item">
					</datalist>
				</div>
				<div
					#ele
					[tooltip]="checkHasStack(
						ele,
						(isArray(field.name)?field.name.join('.'):field.name)
					) ? changesStackTooltip : ''"
					(onShown)="getStackHTML(ele, (isArray(field.name)?field.name.join('.'):field.name))"
					placement="bottom"
					containerClass="changes-stack-tooltip"
					*ngSwitchDefault
				>
					<input
						type="{{field.type}}"
						class="form-control"
						name="{{field.name}}"
						[(ngModel)]="data[field.name]"
						[ngClass]="{'invalid-input' : invalidFields[field.name]}"
						check-permission="{{field.permission}}"
						[params]="{permissions: permissions, permissionMapperKey: permissionMapperKey}"
						[readOnly]="isReadOnly(field)"
						[disabled]="!!field.disabled || checkReadOnly(field.permission)"
						*ngIf="!isArray(field.name); else nestedInput"
					/>
					<ng-template #nestedInput>
						<input type="{{field.type}}" class="form-control" name="{{field.name.join('.')}}" [(ngModel)]="data[field.name[0]][field.name[1]]" [ngClass]="{'invalid-input' : invalidFields[field.name[0]] && invalidFields[field.name[0]][field.name[1]]}" check-permission="{{field.permission}}" [params]="{permissions: permissions, permissionMapperKey: permissionMapperKey}"/>
					</ng-template>
				</div>
				<small class="alert text-danger" *ngIf="!isArray(field.name) && invalidFields[field.name]">{{invalidFields[field.name]}}</small>
				<small class="alert text-danger" *ngIf="isArray(field.name) && invalidFields[field.name[0]] && invalidFields[field.name[0]][field.name[1]]">{{invalidFields[field.name[0]][field.name[1]]}}</small>
			</div>
			<div class="col-md-1">
				<fa-icon *ngIf="showRemoveChangesQueueBtn(field)"  class="pointer" icon="trash" (click)="deleteChangeStack(field)" [tooltip]="'devices.remove_changes_stack' | translate"></fa-icon>
			</div>
		</div>
	</ng-container>
	<div *ngIf="showSubmitButton || showCancelButton">
		<div class="row mt-4 align-items-center">
			<div class="col-md-4">
			</div>
			<div class="col-md-8 px-0">
				<button type="button" class="btn text-white main-blue-bg btn-lg col-5 mr-4" (click)="onSubmit()" *ngIf="showSubmitButton">{{'g.save_changes' | translate}}</button>
				<button type="button" class="btn text-white main-grey-bg btn-lg px-5" (click)="onCancel()" *ngIf="showCancelButton">{{'g.back' | translate}}</button>
			</div>
		</div>
	</div>
</form>
<ng-template #changesStackTooltip>
	<div class="changes-stack-html" [innerHtml]="changesStackHtml"></div>
</ng-template>
