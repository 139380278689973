import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-daily-fleet-utilization',
	templateUrl: './daily-fleet-utilization.component.html',
	styleUrls: ['./daily-fleet-utilization.component.css']
})
export class DailyFleetUtilizationComponent {
	@Input() data: any = {};
	@Input() devices: any = {};
	chart = {
		firstChartTitle: this.translate.instant('utilization.daily_fleet_utilization'),
		secondChartTitle: this.translate.instant('utilization.daily_percent_fleet_utilization'),
	}

	constructor(
		private translate: TranslateService
	) {}
}
