<div class="white-box main-black mt-4 p-4 border-radius">
	<div class="row">
		<div class="col">
			<h4>
				{{"site.utilization_charts" | device_translation: deviceType}}
			</h4>
		</div>
	</div>
	<div class="row py-4">
		<!-- period filter quarterly or monthly -->
		<div class="col-sm-6 col-md-4 col-lg-2 d-inline-flex">
			<ng-select class="mr-auto w-100" [items]="periodFilters" bindLabel="durations" bindValue="id" placeholder="{{'g.durations' | translate}}" name="durations" [(ngModel)]="selectedPeriod" [clearable]="false">
			</ng-select>
		</div>
		<!-- years filter -->
		<div *ngIf="yearsList.length" class="col-sm-6 col-md-4 col-lg-2 d-inline-flex">
			<ng-select class="mr-auto w-100" [items]="yearsList" bindLabel="year" bindValue="id" placeholder="{{'g.year' | translate}}" name="year" [(ngModel)]="selectedYear" [clearable]="false">
			</ng-select>
		</div>
	</div>

	<!-- months chart -->
	<sct-charts
		*ngIf="selectedPeriod == 'monthly' && this.monthlyChartObject.hasData"
		id="monthly-customer-utilization-chart-{{deviceType}}"
		title="{{'g.utilization' | translate}}"
		[data]="monthlyData[selectedYear]"
		[columnNames]="monthlyChartObject.columnNames"
		[options]="monthlyChartObject.config"
		[type]="'ColumnChart'"
		[height]="500"
	></sct-charts>

	<!-- quarters chart -->
	<sct-charts
		*ngIf="selectedPeriod == 'quarterly' && this.quarterChartObject.hasData"
		id="quarterly-customer-utilization-chart-{{deviceType}}"
		title="{{'g.utilization' | translate}}"
		[data]="quarterlyData[selectedYear]"
		[columnNames]="quarterChartObject.columnNames"
		[options]="quarterChartObject.config"
		[type]="'ColumnChart'"
		[height]="500"
	></sct-charts>
</div>
