<div class="white-box main-black mt-4 p-4 border-radius" *ngIf="hasData">
	<div class="row">
		<div class="col">
			<h4>
				{{'perf_analytic.recent_data' | device_translation: deviceType}}
				<app-help-tooltip *ngIf="tooltips" [field]="tooltips"></app-help-tooltip>
			</h4>
		</div>
	</div>
	<div class="row py-4">
		<div class="col-sm-6 col-md-4 col-lg-2 d-inline-flex" *ngIf="options.hasYearFilter && deviceConfig.quarterlyUsage.hasYearFilter">
			<ng-select class="mr-auto w-100" [items]="ddList.truckYearsList" bindLabel="label" bindValue="value" placeholder="{{'devices.truck_manufacturing_year' | translate}}" name="truckYear" [(ngModel)]="selectedTruckYear" (ngModelChange)="rebuildPerformanceCharts()" [clearable]="false">
			</ng-select>
		</div>
		<div class="col-sm-6 col-md-4 col-lg-2 d-inline-flex" *ngIf="options.hasTruckTypeFilter && deviceConfig.quarterlyUsage.hasTypeFilter">
			<ng-select class="mr-auto w-100" [items]="ddList.truckTypes" bindLabel="text" bindValue="id" placeholder="{{'devices.truck_type' | translate}}" name="truckTypes" [(ngModel)]="selectedTruckType" (ngModelChange)="rebuildPerformanceCharts()" [clearable]="false">
			</ng-select>
		</div>
		<div class="col-sm-6 col-md-4 col-lg-2 d-inline-flex" *ngIf="options.hasSiteFilter">
			<ng-select class="mr-auto w-100" [items]="sites" bindLabel="name" bindValue="id" placeholder="{{'site.all_sites' | translate}}" name="site" [(ngModel)]="selectedSites" (ngModelChange)="rebuildPerformanceCharts()" [multiple]="true">
			</ng-select>
		</div>
		<div class="col-sm-6 col-md-4 col-lg-2 d-inline-flex" *ngIf="tags && tags.length > 0">
			<ng-select
				[multiple]="true"
				[items]="tags"
				[addTag]="false"
				[clearable]="false"
				[hideSelected]="true"
				bindLabel="tag_name"
				bindValue="id"
				(ngModelChange)="onSelectedTagsChanges($event)"
				[placeholder]="'tags.filter_by_tag' | translate"
				[(ngModel)]="selectedTags"
				class="mr-auto w-100"
			></ng-select>
		</div>
	</div>
	<div id="recent-performance-chart" *ngIf="hasData">
		<sct-custom-table
			[columnsConfig]="tableColumns"
			[data]="tableData"
			[config]="tableConfig"
		></sct-custom-table>
	</div>
</div>
