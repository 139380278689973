<form (ngSubmit)="onLogin(f)" #f="ngForm" class="before-login-form">
	<div class="form-group validated-field mb-4">
		<label for="email">{{"login_register.email" | translate}}</label>
		<input type="email" name="email" ngModel #emailComponent #email="ngModel" required email class="form-control rounded" placeholder="{{'login_register.email' | translate}}" (blur)="checkEmailValidation()">
		<div class="invalid-tooltip" *ngIf="email.touched && email.invalid">
			{{"g.fill_out_massage" | translate}}
		</div>
			<div class="invalid-tooltip" *ngIf="email.touched && !isValidEmail">
			{{"g.invalid_email_format" | translate}}
		</div>
	</div>
	<div class="form-group validated-field">
		<div class="d-flex justify-content-between">
			<label for="email">{{"g.password" | translate}}</label>
			<a routerLink="/forgot-password"><small>{{'login_register.forgot_password' | translate}}?</small></a>
		</div>
		<input type="password" name="password" ngModel #password="ngModel" required class="form-control rounded" placeholder="{{'g.password' | translate}}" show-password>
		<div class="invalid-tooltip" *ngIf="password.touched && password.invalid">
			{{"g.fill_out_massage" | translate}}
		</div>
	</div>
	<div *ngIf="showCaptcha">
		<app-captcha #captchaComponent [fullSize]="true"></app-captcha>
	</div>
	<div class="submit-area form-group">
		<div class="submit-text">
			<p>{{'login_register.do_not_have_account' | translate}} <a routerLink="/register">{{'login_register.sign_up_now' | translate}}</a></p>
			<p>{{'login_register.need_support' | translate}} <a routerLink="/contact">{{'contact_us.title' | translate}}</a></p>
		</div>
		<button type="submit" class="btn">{{'login_register.login' | translate}}</button>
	</div>
</form>
