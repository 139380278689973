import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-site-battery-fleet-utilization-score',
	templateUrl: './site-battery-fleet-utilization-score.component.html',
	styleUrls: ['./site-battery-fleet-utilization-score.component.css']
})
export class SiteBatteryFleetUtilizationScoreComponent {
	@Input() data = null;
}
