<div class="performance-values white-box main-black p-4 border-radius-bottom mb-2">
	<div class="preperty-values row" *ngIf="device.config_info">
		<div class="col-4 p-0" *ngIf="canAccessFirstGroup()">
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'truck_id')">
				<div class="p-2">{{'devices.truck_id' | device_translation:deviceType}}</div>
				<div class="p-2">{{device.config_info.truck_id}}</div>
			</div>
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'truck_type')">
				<div class="p-2">{{'devices.truck_type' | device_translation:deviceType}}</div>
				<div class="p-2">{{device.config_info.truck_type}}</div>
			</div>
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'truck_sn')">
				<div class="p-2">{{ 'devices.truck_sn' | device_translation:deviceType}}</div>
				<div class="p-2">{{device.config_info.truck_sn}}</div>
			</div>
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'battery_capacity')">
				<div class="p-2">{{'devices.battery_capacity' | translate}}</div>
				<div class="p-2">{{device.config_info.battery_capacity}}</div>
			</div>
		</div>
		<div class="col-4">
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'number_of_cells') && deviceVPC !== undefined">
				<div class="p-2">{{'rt.voltage' | translate}}</div>
				<div class="p-2">{{device.config_info.number_of_cells * deviceVPC | number}}</div>
			</div>
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'serial_number')">
				<div class="p-2">{{'g.iotah_sn' | device_translation:deviceType}}</div>
				<div class="p-2"><a [routerLink]="studyInfo.id && studyInfo.has_site_device_acces? ['/', studyInfo.device_customer_id, studyInfo.device_site_id, device.config_info.mac_address, 'performance'] : null">{{device.config_info.serial_number}}</a></div>
			</div>
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'installation_date')">
				<div class="p-2">{{'devices.installation_date' | translate}}</div>
				<div class="p-2">{{device.config_info.installation_date_fmt}}</div>
			</div>
		</div>
		<div class="col-4">
			<div *ngIf="usersService.hasAccessFunction('shipment_management')">
				<div class="p-2">{{'g.purchase_order' | translate}}</div>
				<div class="p-2">{{device.config_info.po_number || '-'}}</div>
			</div>
			<div *ngIf="usersService.hasAccessFunction('shipment_management')">
				<div class="p-2">{{'ready_to_ship.shipping_date' | translate}}</div>
				<div class="p-2">{{ device.config_info.shipping_date }}</div>
			</div>
			<div *ngIf="usersService.hasAccessFunction('shipment_management')">
				<div class="p-2">{{'ready_to_ship.tracking_number' | translate}}</div>
				<div class="p-2">{{device.config_info.tracking_number || '-'}}</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="!studyInfo.id" class="white-box main-black mt-4 p-4 border-radius mb-2">
	<div class="row p-1">
		<div class="col-sm-6 col-md-2">
			<h4>
				{{'site_performance.device_tags' | translate}}
				<app-help-tooltip *ngIf="tooltips.device_tags" [field]="tooltips.device_tags"></app-help-tooltip>
			</h4>
		</div>
		<div class="col-sm-6 col-md-8 ml-auto d-inline-flex"></div>
		<div class="col-sm-6 col-md-2 ml-auto d-inline-flex"></div>
		<div class="panel-body p-3">
			<app-tag-input
				[items]="device.tags.siteTagsValues"
				[deviceId]="device.mac_address"
				[tagInput]="device.tags.deviceTags[device.mac_address] || []"
				[siteId]="currentSite.id"
				[isSCTuser]="currentUser.isSCT"
			></app-tag-input>
		</div>
	</div>
</div>

<div class="white-box main-black mt-4 p-4 border-radius" *ngIf="this.commonService.isShowPowerStudyDomain() && device.config_info.is_study_device && !studyInfo.id">
	<app-studies-list parent="device" [device]="device"></app-studies-list>
</div>
<app-batteries-customized-charts
	*ngIf="deviceConfig.performance.hasBatteriesCustomizedCharts"
	[minDeviceDate]="deviceInstallationDate"
	[maxDeviceDate]="maxDate"
	[devices]="[device]"
	[siteInfo]="currentSite"
	[siteAlertSettings]="siteAlertSettings"
></app-batteries-customized-charts>

<app-battery-performance [dailyDetails]="dailyDetails" [deviceInfo]="device" [currentSite]="currentSite"></app-battery-performance>

<div *ngIf="deviceConfig.couldHaveStudy && device.config_info.is_study_device">
	<app-power-study-soc-chart [studyInfo]="studyInfo" [minDeviceDate]="deviceInstallationDate" [maxDeviceDate]="maxDate" 	[device]="device"></app-power-study-soc-chart>
</div>

<div *ngIf="deviceConfig.couldHaveStudy && device.config_info.is_study_device && studyInfo.id">
	<app-study-charge-cycle-chart [studyInfo]="studyInfo" [minDeviceDate]="deviceInstallationDate" [maxDeviceDate]="maxDate" 	[device]="device"></app-study-charge-cycle-chart>
</div>

<app-usage-data
	*ngIf="data?.usageData?.recent?.[deviceConfig.reporting.device]"
	[data]="data.usageData.recent[deviceConfig.reporting.device]"
	[columns]="data.usageData.recent[deviceConfig.reporting.device][0] ?? []"
	[devices]="[device]"
	[siteInfo]="currentSite"
	[deviceType]="deviceType"
	parentPage="device"
></app-usage-data>

<app-usage-time-widget [devices]="[device]" [parentPage]="'device'" [studyInfo]="studyInfo" [minDeviceDate]="deviceInstallationDate" [maxDeviceDate]="maxDate"></app-usage-time-widget>
<app-quarterly-usage-data
	*ngIf="data"
	[data]="data.usageData[deviceConfig.quarterlyUsage.period][deviceConfig.reporting.device]"
	[devices]="[device]"
	[siteInfo]="currentSite"
	parentPage="device"
	[deviceType]="deviceType"
	[truckTypes]="data?.trucksIndexes[deviceConfig.reporting.device]"
></app-quarterly-usage-data>

<div *ngIf="!data">{{'g.no_data_to_show' | translate}}</div>
<div class="white-box main-black mt-4 p-4 border-radius" *ngIf="deviceConfig.performance.hasIdleEventsReport">
	<div class="row">
		<div class="col">
			<h4>
				{{'site_performance.idle_events_report' | device_translation:deviceType}}
				<app-help-tooltip *ngIf="tooltips.idle_events_report" [field]="tooltips.idle_events_report"></app-help-tooltip>
			</h4>
		</div>
	</div>
	<div class="row py-4">
		<div class="col-sm-6 col-md-7 d-inline-flex">
			<div class="input-group col-6 p-0 mr-3 d-inline-flex">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.start_date' | translate}}</span>
				</div>
				<input class="form-control" [ngClass]="{'text-danger': idleTimesInvalidDateRange}" [minDate]="deviceInstallationDate" [maxDate]="maxDate" placeholder="mm/dd/yyyy" name="startDB" [(ngModel)]="idleTimesFromDate" (ngModelChange)="idleTimesDatesChanged()" bsDatepicker #startDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
				<div class="input-group-append pointer" (click)="startDB.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>

			<div class="input-group col-6 p-0 mr-3 d-inline-flex">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.end_date' | translate}}</span>
				</div>
				<input class="form-control" placeholder="mm/dd/yyyy" name="endDB" [(ngModel)]="idleTimesToDate" [minDate]="deviceInstallationDate" [maxDate]="maxDate" (ngModelChange)="idleTimesDatesChanged()" bsDatepicker #endDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
				<div class="input-group-append pointer" (click)="endDB.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
		</div>
		<div class="col-sm-6 col-md-3 d-inline-flex">
			<div class="input-group">
				<div class="form-check form-check-inline">
					<input class="form-check-input" type="radio" name="idleDataFilter" [(ngModel)]="idleDataFilter" id="inlineRadio1" value="avg" (change)="onSelectionChanged($event.target.value)">
					<label class="form-check-label" for="inlineRadio1">{{'site_performance.avg_truck_hours' | translate}}</label>
				</div>
				<div class="form-check form-check-inline">
					<input class="form-check-input" type="radio" name="idleDataFilter" [(ngModel)]="idleDataFilter" id="inlineRadio2" value="total" (change)="onSelectionChanged($event.target.value)">
					<label class="form-check-label" for="inlineRadio2">{{'site_performance.truck_hours' | translate}}</label>
				</div>
			</div>
		</div>
		<div class="col-sm-6 col-md-1 ml-auto d-inline-flex">
			<button class="btn fa fa-print fa-lg ml-1" printTitle="{{'g.performance' | translate}}" [useExistingCss]="true" printSectionId="idle-chart" ngxPrint title="{{'g.print' | translate}}"></button>
			<button class="fa fa-upload btn" title="{{'g.export_to_csv' | translate}}" (click)="exportIdleEvents()"></button>
		</div>
	</div>
	<div id="idle-chart">
		<div class="showOnPrint">
			<h3>{{'devices.truck_sn_short' | translate}}: {{device.config_info.truck_sn}}</h3>
			<h3>{{'devices.serial_number' | translate}}: {{device.serial_number}}</h3>
			<h4>{{'perf_analytic.period' | translate}}: {{idleTimesFromDate | date:'MM/dd/yyyy'}} - {{idleTimesToDate | date:'MM/dd/yyyy'}}</h4>
		</div>
		<ng-container *ngFor="let type of ['avg', 'total']">
			<span>
				<div class="showOnPrint">
					<h4>
						<span class="col-md">
							{{'device_performance.'+type | translate}}
						</span>
					</h4>
				</div>
				<sct-charts
					id="idle_chart_{{idleDataFilter}}"
					[chartId]="'idle_chart_'+idleDataFilter"
					class="showOnPrintToggle"
					[ngClass]="{'invisible': type != idleDataFilter, 'd-none': type != idleDataFilter && chartsReady['idle_chart_'+idleDataFilter]}"
					[title]="'site_performance.idle_events_report' | device_translation:deviceType"
					[data]="idleChartData[type]"
					[columnNames]="idleChartColumnNames"
					[options]="idleTimesOptions"
					[type]="'ColumnChart'"
					[height]="500"
					[formatterType]="'colorFormat'"
					[formatter]="'anything'"
					(ready)="chartReadyFunct($event)"
				></sct-charts>
				<div class="showOnPrint">
					<table class="table">
						<thead>
							<th>{{'g.time' | translate}}</th>
							<th>{{'perf_analytic.value_mins' | translate}}</th>
						</thead>
						<tbody>
							<ng-container *ngFor="let item of idleChartData[type]">
								<tr *ngIf="item[1] != 0">
									<td>{{item[0]}}</td>
									<td>{{item[1] | number:'1.2-2'}}</td>
								</tr>
							</ng-container>
						</tbody>
					</table>
				</div>
			</span>
		</ng-container>
	</div>
</div>
<div class="white-box main-black mt-4 p-4 border-radius" *ngIf="endVoltageChartReady && deviceConfig.performance.hasEndVoltageReport">
	<div class="row p-1">
		<div class="col-sm-6 col-md-3">
			<h4>
				{{'site_performance.end_voltage_report' | device_translation:deviceType}}
				<app-help-tooltip *ngIf="tooltips.end_voltage_report" [field]="tooltips.end_voltage_report"></app-help-tooltip>
			</h4>
		</div>
		<div class="col-sm-6 col-md-8 ml-auto d-inline-flex">
			<div class="input-group col-5 p-0 mr-3 d-inline-flex">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.start_date' | translate}}</span>
				</div>
				<input class="form-control" [ngClass]="{'text-danger': endVoltageInvalidDateRange}" [minDate]="deviceInstallationDate" [maxDate]="maxDate" placeholder="mm/dd/yyyy" name="startDB" [(ngModel)]="endVoltageFromDate" (ngModelChange)="endVoltageDatesChanged()" bsDatepicker #startDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
				<div class="input-group-append pointer" (click)="startDB.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>

			<div class="input-group col-5 p-0 mr-3 d-inline-flex">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.end_date' | translate}}</span>
				</div>
				<input class="form-control" placeholder="mm/dd/yyyy" name="endDB" [(ngModel)]="endVoltageToDate" [minDate]="deviceInstallationDate" [maxDate]="maxDate" (ngModelChange)="endVoltageDatesChanged()" bsDatepicker #endDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
				<div class="input-group-append pointer" (click)="endDB.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
		</div>
		<div class="col-sm-6 col-md-1 ml-auto d-inline-flex" *ngIf="endVoltageChartData.length">
			<button class="btn fa fa-print fa-lg ml-1" printTitle="{{'g.performance' | translate}}" [useExistingCss]="true" printSectionId="endVoltage-chart" ngxPrint title="{{'g.print' | translate}}"></button>
			<button class="fa fa-upload btn" title="{{'g.export_to_csv' | translate}}" (click)="exportEndVoltage()"></button>
		</div>
	</div>
	<div id="endVoltage-chart" *ngIf="endVoltageChartData.length; else noData">
		<div class="showOnPrint">
			<h3>{{'devices.truck_sn_short' | translate}}: {{device.config_info.truck_sn}}</h3>
			<h3>{{'devices.serial_number' | translate}}: {{device.serial_number}}</h3>
			<h4>{{'perf_analytic.period' | translate}}: {{endVoltageFromDate | date:'MM/dd/yyyy'}} - {{endVoltageToDate | date:'MM/dd/yyyy'}}</h4>
		</div>
		<sct-charts
			id="endVoltage_chart"
			[title]="'site_performance.end_voltage_report_device' | translate"
			[data]="endVoltageChartData"
			[columnNames]="endVoltageChartColumnNames"
			[options]="endVoltageOptions"
			[type]="'ColumnChart'"
			[height]="500"
			[formatterType]="'colorFormat'"
			[formatter]="'anything'"
			(select)="onSelectEndVoltage($event)"
		></sct-charts>
		<div class="showOnPrint">
			<table class="table">
				<thead>
					<th>{{'events.sequence_id' | translate}}</th>
					<th>{{'events.timestamp' | translate}}</th>
					<th>{{'event.vpc' | translate}}</th>
				</thead>
				<tbody>
					<ng-container *ngFor="let dayItems of endVoltageAllTableItems">
						<tr *ngFor="let item of dayItems">
							<td>{{item.sequence_id}}</td>
							<td>{{item.timestamp}}</td>
							<td>{{item.vpc}}</td>
						</tr>
					</ng-container>
				</tbody>
			</table>
		</div>
		<app-modal #endVoltageModal [title]="'event.end_voltage' | translate" [hasConfirmButton]="false" [hasCloseButton]="true" [size]="'lg'">
			<app-grid
				[columns]="endVoltageTableColumns"
				[data]="endVoltageTableItems"
				[sizeColumnsToFit]="true"
				[settings]="{
					hasExport: false,
					hasSelectColumn: false,
					hasReset: false,
					showSummary: false,
					height: 'auto',
					enableFloatingFilter: false,
					pagination: false,
					domLayout: 'autoHeight'
				}"
				*ngIf="endVoltageModal.render"
			></app-grid>
		</app-modal>
	</div>
</div>

<div class="white-box main-black mt-4 p-4 border-radius" *ngIf="usageChartReady">
	<div class="row p-1">
		<div class="col-sm-6 col-md-3">
			<h4>
				{{'site_performance.total_truck_usage' | device_translation:deviceType}}
				<app-help-tooltip *ngIf="tooltips.total_truck_usage" [field]="tooltips.total_truck_usage"></app-help-tooltip>
			</h4>
		</div>
		<div class="col-sm-6 col-md-8 ml-auto d-inline-flex">
			<div class="input-group col-5 p-0 mr-3 d-inline-flex">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.start_date' | translate}}</span>
				</div>
				<input class="form-control" [ngClass]="{'text-danger': usageInvalidDateRange}" [minDate]="deviceInstallationDate" [maxDate]="maxDate" placeholder="mm/dd/yyyy" name="startUsageDB" [(ngModel)]="usageFromDate" (ngModelChange)="usageDatesChanged()" bsDatepicker #startUsageDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
				<div class="input-group-append pointer" (click)="startUsageDB.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>

			<div class="input-group col-5 p-0 mr-3 d-inline-flex">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.end_date' | translate}}</span>
				</div>
				<input class="form-control" placeholder="mm/dd/yyyy" name="endUsageDB" [(ngModel)]="usageToDate" [minDate]="deviceInstallationDate" [maxDate]="maxDate" (ngModelChange)="usageDatesChanged()" bsDatepicker #endUsageDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
				<div class="input-group-append pointer" (click)="endUsageDB.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
		</div>
		<div class="col-sm-6 col-md-1 ml-auto d-inline-flex" *ngIf=" usageChartDataAmp.length && !usageInvalidDateRange">
		<button class="btn fa fa-print fa-lg ml-1" printTitle="{{'g.performance' | translate}}" [useExistingCss]="true" printSectionId="usage-chart" ngxPrint title="{{'g.print' | translate}}"></button>
		<button class="fa fa-upload btn" title="{{'g.export_to_csv' | translate}}" (click)="exportTotalUsage()"></button>
		</div>
	</div>
	<div id="usage-chart" *ngIf="usageChartDataAmp.length && !usageInvalidDateRange; else noData">
		<div class="showOnPrint">
			<h3>{{'devices.truck_sn_short' | translate}}: {{device.config_info.truck_sn}}</h3>
			<h3>{{'devices.serial_number' | translate}}: {{device.serial_number}}</h3>
			<h4>{{'perf_analytic.period' | translate}}: {{usageFromDate | date:'MM/dd/yyyy'}} - {{usageToDate | date:'MM/dd/yyyy'}}</h4>
		</div>
		<sct-charts
			id="usage_chart"
			[title]="'site_performance.total_truck_amp_usage' | device_translation:deviceType"
			[data]="usageChartDataAmp"
			[columnNames]="usageAmpColumnNames"
			[options]="usageAmpOptions"
			[type]="'LineChart'"
			[height]="500"
		></sct-charts>
		<div class="showOnPrint">
			<table class="table">
				<thead>
					<th>{{'g.time' | translate}}</th>
					<th>{{'perf_analytic.value_amps' | translate}}</th>
				</thead>
				<tbody>
					<ng-container *ngFor="let item of usageChartDataAmp">
						<tr *ngIf="item[1] != 0">
							<td>{{item[0] | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
							<td>{{item[1]}}</td>
						</tr>
					</ng-container>
				</tbody>
			</table>
		</div>

		<sct-charts
			id="usage_chart"
			[title]="'site_performance.total_truck_kw_usage' | device_translation:deviceType"
			[data]="usageChartDataWatt"
			[columnNames]="usageKwColumnNames"
			[options]="usageKwOptions"
			[type]="'LineChart'"
			[height]="500"
		></sct-charts>
		<div class="showOnPrint">
			<table class="table">
				<thead>
					<th>{{'g.time' | translate}}</th>
					<th>{{'perf_analytic.value_kws' | translate}}</th>
				</thead>
				<tbody>
					<ng-container *ngFor="let item of usageChartDataWatt">
						<tr *ngIf="item[1] != 0">
							<td>{{item[0] | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
							<td>{{item[1]}}</td>
						</tr>
					</ng-container>
				</tbody>
			</table>
		</div>
	</div>
</div>

<div class="white-box main-black mt-4 p-4 border-radius" *ngIf="truckHoursChartReady">
	<div class="row">
		<div class="col">
			<h4>
				{{'device_performance.daily_truck_hours' | device_translation:deviceType}}
				<app-help-tooltip *ngIf="tooltips.daily_truck_hours" [field]="tooltips.daily_truck_hours"></app-help-tooltip>
			</h4>
		</div>
	</div>
	<div class="row py-4">
		<div class="col-sm-6 col-md-7 d-inline-flex">
			<div class="input-group col-6 p-0 mr-3 d-inline-flex">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.start_date' | translate}}</span>
				</div>
				<input class="form-control" [ngClass]="{'text-danger': truckHoursInvalidDateRange}" [minDate]="deviceInstallationDate" [maxDate]="maxDate" placeholder="mm/dd/yyyy" name="thStartDB" [(ngModel)]="truckHoursFromDate" (ngModelChange)="truckHoursDatesChanged()" bsDatepicker #thStartDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
				<div class="input-group-append pointer" (click)="thStartDB.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>

			<div class="input-group col-6 p-0 mr-3 d-inline-flex">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.end_date' | translate}}</span>
				</div>
				<input class="form-control" placeholder="mm/dd/yyyy" name="thEndDB" [(ngModel)]="truckHoursToDate" [minDate]="deviceInstallationDate" [maxDate]="maxDate" (ngModelChange)="truckHoursDatesChanged()" bsDatepicker #thEndDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
				<div class="input-group-append pointer" (click)="thEndDB.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
		</div>
		<div class="col-sm-6 col-md-1 ml-auto d-inline-flex" *ngIf="truckHoursChartData.length && !truckHoursInvalidDateRange">
			<button class="btn fa fa-print fa-lg ml-1" printTitle="{{'g.performance' | translate}}" [useExistingCss]="true" printSectionId="truck-hours" ngxPrint title="{{'g.print' | translate}}"></button>
			<button class="fa fa-upload btn" title="{{'g.export_to_csv' | translate}}" (click)="exportTruckHours()"></button>
		</div>
	</div>
	<div id="truck-hours" *ngIf="truckHoursChartData.length && !truckHoursInvalidDateRange; else noData">
		<div class="showOnPrint">
			<h3>{{'devices.truck_sn_short' | translate}}: {{device.config_info.truck_sn}}</h3>
			<h3>{{'devices.serial_number' | translate}}: {{device.serial_number}}</h3>
			<h4>{{'perf_analytic.period' | translate}}: {{truckHoursFromDate | date:'MM/dd/yyyy'}} - {{truckHoursToDate | date:'MM/dd/yyyy'}}</h4>
		</div>
		<span>
			<sct-charts
				id="daily_truck_hours"
				[chartId]="'daily_truck_hours'"
				[title]="'device_performance.daily_truck_hours' | device_translation:deviceType"
				[data]="truckHoursChartData"
				[columnNames]="truckHoursChartColumnNames"
				[options]="truckHoursOptions"
				[type]="'ColumnChart'"
				[height]="500"
				(ready)="chartReadyFunct($event)"
			></sct-charts>
			<div class="showOnPrint">
				<table class="table">
					<thead>
						<th>{{'g.date' | translate}}</th>
						<th>{{'site_performance.time_report_title' | translate}}</th>
					</thead>
					<tbody>
						<ng-container *ngFor="let item of truckHoursChartData">
							<tr *ngIf="item[1] != 0">
								<td>{{item[0]}}</td>
								<td>{{item[1]}}</td>
							</tr>
						</ng-container>
					</tbody>
				</table>
			</div>
		</span>
	</div>
</div>
<app-ebu-and-charge-opportunity
	*ngIf="studyInfo.id || deviceConfig.performance.chargeOpportunity"
	[studyInfo]="studyInfo"
	[device]="device"
></app-ebu-and-charge-opportunity>

<!-- For Batt-link devices only add condition-->
<div *ngIf="deviceConfig.performance.chargeOpportunity">
	<app-device-alerts [device]="device"></app-device-alerts>
</div>

<ng-template #noData>
	<div class="mt-4 alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>
