import { Component, OnInit, OnDestroy, Input, OnChanges } from '@angular/core';
import { DeviceService } from "../../device.service";
import { CommonService } from "src/app/shared/services/common.service";
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { UsersService } from 'src/app/users/users.service';
import * as lodash from 'lodash-es';
import * as moment from 'moment';
import { DeviceCommonService } from '../../device-common.service';
import { ColumnsConfig, TableConfig, TableData} from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';
import { DeviceFactory } from '../../device-factory/device-factory';
import { mainDeviceConfig } from '../../device-factory/device-config';
import { IotahDevice } from '../../device-factory/Iotah-device';

@Component({
	selector: 'app-quick-view',
	templateUrl: './quick-view.component.html',
	styleUrls: ['./quick-view.component.css']
})
export class QuickViewComponent implements OnInit, OnChanges, OnDestroy {

	@Input() device: any= {};
	@Input() currentSite: any;
	@Input() enterprisePermissions: any = {};
	deviceConfig: mainDeviceConfig | null = (new IotahDevice).config;

	QV: any = {};
	QVNotCompressed: any = {};
	calcs: any;
	intervalID: any;
	warnings: any = [];

	currentGaugeNumbers: any = [];
	voltageGaugeNumbers: any = [];

	currentMinMax: any = {min: 0, max: 1000};
	voltageMinMax: any = {min: 0, max: 0};
	connectivityImage!: string;
	wifiImage!: string;
	oneDayDisconnect: number = 24 * 60 * 60;
	threeDaysDisconnect: number = 3 * (24 * 60 * 60);
	sevenDayDisconnect: number = 7 * (24 * 60 * 60);
	thirtyDayDisconnected: number = 30 * (24 * 60 * 60);
	sixMonths: number = this.oneDayDisconnect * 180;
	isAdminUser: Boolean;
	waterLevelImage: string = '/sctportal/images/water_level_indicator/water_level_low.png';

	connectivityTooltipMessages = '';
	lastConnectTimeFields = [
		'metered_wifi_last_connect_time',
		'metered_cellular_last_connect_time',
		'non_metered_wifi_last_connect_time',
		'non_metered_cellular_last_connect_time',
	];

	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		tableId: "quick_view_warning"
	}

	column: ColumnsConfig[] = [
		{key: 'warning_name', name : this.translate.instant('warnings.warning_name'), type: 'string'},
		{key: 'warning_raise_time', name: this.translate.instant('warnings.warning_raise_time'), type: 'date'}
	];

	constructor(
		public deviceService: DeviceService,
		public common: CommonService,
		private datePipe: DatePipe,
		public translate: TranslateService,
		public usersService: UsersService,
		public deviceCommonService: DeviceCommonService,
	) { }

	ngOnInit() {
		let currentUser = this.usersService.getCurrentUser();
		this.isAdminUser = currentUser.is_admin;
		const deviceConfig = DeviceFactory.createDevice(this.device.config_info.sct_device_type);
		this.deviceConfig = deviceConfig.config;

		this.intervalID = setInterval(() => {
			this.getQuickView();
		}, 3000); //every 3 seconds
		this.getQuickView();
		this.checkCurrecntLowRange();
		this.connectivityTooltipMessages = this.deviceService.getLastConnectionTimeMassage(this.device.config_info);
	}

	checkCurrecntLowRange() {
			this.currentMinMax.max = this.device.config_info.has_current_low_range ? 750 : 1000;
	}

	getQuickView(noUiBlock=true) {
		if (!this.device.mac_address) return;
		this.deviceService.getQuickView(this.device.mac_address, noUiBlock).subscribe((data: any) => {
			this.QVNotCompressed = data.qv;
			this.QV = this.common.decompress(data.qv || {}, 'quick_view');
			this.calcs = this.common.decompress(data.cv || {}, 'calcVars');
			this.getQuickViewImages(this.QV.dv);

			if(this.showInsight()){
				this.prepareWarnings(data);
				this.prepareGauges();
				this.prepareData(data.nullifyCurrent);
			}

			this.connectivityTooltipMessages = this.deviceService.getLastConnectionTimeMassage(this.device.config_info);
		});
	}

	getQuickViewImages(response) {
		this.wifiImage = '';
		this.connectivityImage = '';

		const nowTime = this.common.nowTime();
		const installatioDate = response.installation_date + this.oneDayDisconnect;

		// WIFI
		let wifiLastConnectTime = lodash.max([response.non_metered_wifi_last_connect_time, response.metered_wifi_last_connect_time]);
		let wifiDisconnectTime = nowTime - wifiLastConnectTime;
		let hasWifiImage = false;
		if (wifiLastConnectTime > installatioDate && wifiDisconnectTime < this.sixMonths) {
			hasWifiImage = true;
			this.showWifiImage(response);
		}

		// LTE
		const lteLastConnectTime = lodash.max([response.non_metered_cellular_last_connect_time, response.metered_cellular_last_connect_time]);
		const lteDisconnectTime = nowTime - lteLastConnectTime;
		let hasLteImage = false;
		if(lteLastConnectTime > installatioDate && lteDisconnectTime < this.sixMonths) {
			hasLteImage = true;
			this.showLteImage(nowTime, lteDisconnectTime, response)
		}

		if (!hasWifiImage && !hasLteImage) {
			if (lteLastConnectTime > wifiLastConnectTime) {
				this.showLteImage(nowTime, lteDisconnectTime, response)
			} else {
				this.showWifiImage(response);
			}
		}
	}

	showLteImage(nowTime, lastConnectTime, response) {
			if (response.enable_cellular === false)
				return;
			if (!response.cellular_iccid) {
				if (!this.usersService.userHasNOCAccess())
					return;

				// if the user has NOC permission and the device doesn’t have ICCID show the lte_bad
				this.connectivityImage = 'images/lte/lte_BAD.png';
				return;
			}
			if (lastConnectTime >= this.thirtyDayDisconnected) { // 30 days
				this.connectivityImage = 'images/lte/lte_disconnected_30d.png';
				return;
			}
			if (lastConnectTime >= this.sevenDayDisconnect) { // 7 days
				this.connectivityImage = 'images/lte/lte_disconnected_7d.png';
				return;
			}
			if (lastConnectTime >= this.threeDaysDisconnect) { // 3 days
				this.connectivityImage = 'images/lte/lte_disconnected_3d.png';
				return;
			}
			if (lastConnectTime >= this.oneDayDisconnect) { // 1 day

				const simCardTime = nowTime - response.last_sim_connect_time;
				// device connected for one day and less than 3 days we need to check SIM CARD DB
				if (simCardTime < this.oneDayDisconnect) {
					this.connectivityImage = 'images/lte/lte_connected.png';
					return;
				}
				this.connectivityImage = 'images/lte/lte_disconnected_3d.png';
				return;
			}
			this.connectivityImage = 'images/lte/lte_connected.png';
			return;
	}

	showWifiImage(response) {
		if (response._wifiConnected === true)
			this.wifiImage = '/images/wifi_connected.png';
		else
			this.wifiImage = '/images/wifi_not_connected_red.png';
	}

	showInsight() {

		let qvLength = Object.keys(this.QV).length;

		if(qvLength == 0)
			return false;

		if(qvLength == 1 && this.QV.hasOwnProperty('live_event'))
			return false;

		return true;
	}

	showEvent() {
		if(!this.QV.live_event || Object.keys(this.QV.live_event).length == 0)
			return false;

		return true;
	}

	showCalcs() {
		if(!this.calcs || Object.keys(this.calcs).length == 0)
			return false;

		return true;
	}

	showRt() {
		if(!this.QV.live_rt || Object.keys(this.QV.live_rt).length == 0)
			return false;

		return true;
	}

	formatTime(val, type?, options:any={}) {
		let zoneId = this.QV.dv._zoneId;

		if(options.convertToTimestamp)
			val = moment(val).utc().unix();

		let time = this.common.getZoneTimestampFromUTC(zoneId, val);
		time = this.common.getDateFormattedFromUnixTimeStamp(time);

		if(options.timeOnly)
			return this.datePipe.transform(time, 'hh:mm:ss a');

		if(options.dateOnly){
			return this.datePipe.transform(time, 'MM/dd/yyyy');
		}
		return this.datePipe.transform(time, 'MM/dd/yyyy hh:mm:ss a');
	}

	formatNotes() {
		var flags = [
			'midnight_split',
			'time_change_split',
			'data_from_bv',
			'split_at_start',
			'split_at_end',
			'flag_finish',
			'flag_eq',
			'flag_finish_start',
			'flag_eq_start',
			'water_level_high',
			'temperature_exists'
		];

		var notes = [];

		flags.forEach((key) => {
			if(this.QV.live_event[key])
				notes.push(this.translate.instant('event.'+key));
		});

		return notes.join(', ');
	}

	getStaStatus() {

		let STA_STATUS_STARTED = 0,
		STA_STATUS_STOPPED = 1,
		STA_STATUS_CONNECTTING = 2,
		STA_STATUS_CONNECTED = 3,
		STA_STATUS_GOT_IP = 4,
		STA_STATUS_DISOCNNECTED = 5,
		STA_STATUS_AFTER_IP = 6,
		STA_STATUS_DISOCNNECTING = 7,
		STA_STATUS_DIS = 8,
		STA_STATUS_MAX = 9;

		if(this.QV.wifi_status == STA_STATUS_AFTER_IP && this.QV.wifi_ip != "0.0.0.0"){
			return this.translate.instant('qv.sta_connected')+' '+this.QV.wifi_ip;
		}

		return this.translate.instant('qv.sta_on');
	}

	formatQv(key, type="any", options={}) {
		let booleans = {
			'event': ['isValidCRC16'],
			'any': ['light_sleep_mode', 'low_range_is_inuse', 'battery_connected']
		};

		switch (type) {
			case "rt":
				switch(key) {
					case "timestamp":
						return this.formatTime(this.QV.live_rt[key]);
					break;
					case "rt_flag_current_error":
						return this.translate.instant(this.QV.live_rt[key] ? 'g.yes' : 'g.no');
					break;
					case "rt_el_level_flag":
						return this.translate.instant(this.QV.live_rt[key] ? 'g.high' : 'g.low');
					break;
				}
			break;
			case "event":
				switch(key) {
					case "event_type":
						if(this.deviceService.eventTypes[this.QV._type] !== undefined)
							return this.translate.instant('event.'+this.deviceService.eventTypes[this.QV._type]);
					break;
					case "finish_reason":
						if(this.deviceService.finishReasons[this.QV.live_event[key]] !== undefined)
							return this.translate.instant('event.'+this.deviceService.finishReasons[this.QV.live_event[key]]);
					break;
					case "eq_reason":
						if(this.deviceService.eqReasons[this.QV.live_event[key]] !== undefined)
							return this.translate.instant('event.'+this.deviceService.eqReasons[this.QV.live_event[key]]);
					break;
					case "my_zone_id":
						let zoneId = this.QV.live_event[key];
						return this.common.getZoneName(zoneId) + ' ['+zoneId+']';
					break;
					case "water_level_high":
						let waterLevel = this.QV.live_event[key] ? 'high' : 'low';
						return this.translate.instant('g.'+waterLevel);
					break;
					case "timestamp_utc":
					case "end_timestamp_utc":
						return this.formatTime(this.QV.live_event[key], 'event', options);
					break;
					case "notes":
						return this.formatNotes();
					break;
					default:
						if(booleans['event'].includes(key))
							return !!this.QV.live_event[key];
					break;
				}
			break;

			default:
				switch(key) {
					case "time":
						return this.formatTime(this.QV[key]);
					break;
					case "hw_status":
						let statuses = {
							1: 'warnings.plc',
							2: 'warnings.rtc',
							4: 'warnings.adc',
							8: 'warnings.flash_size',
							16: 'cellular_chip_hardware_failure',
							32: 'disabled_hall_effect'
						};

						let status = [];
						let hwValue = this.QV[key];

						for(let id in statuses) {
							if((hwValue & +id) != 0) {
								status.push(this.translate.instant(statuses[id]))
							}
						}

						return status.join(', ');
					break;
					case "readings":
						let readings = {
							"water_level": 'event.water_level',
							"water_flow": 'qv.water_flow',
							"voltage": 'rt.voltage',
							"current": 'rt.current',
							"temperature": 'rt.temperature',
							"current_low_range": "qv.current_low_range"
						};

						let reading = [];
						let value = this.QV[key];

						for (const item of value) {
							if (item == 'temperature' && !this.device.config_info.has_temperature_sensor)
								continue;

							if (item == 'water_level' && !this.device.config_info.has_water_level)
								continue;

							reading.push(this.translate.instant(readings[item]));
						}

						return reading.join(', ');
					break;

					case 'wifi_cellular_last_connect_time':
						return this.getLatestLastConnectTime();
					case 'el_level':
						const translateKey = this.QV[key] ? 'g.high' : 'g.low';
						return this.translate.instant(translateKey)
					default:
						if(booleans['any'].includes(key))
							return !!this.QV[key];
					break;
				}
			break;
		}
	}

	prepareData(nullifyCurrent){
		let ahrPercentage	= 0,
			kwhrPercentage	= 0,
			voltageDegree	= 0,
			currentDegree	= 0;

		let absCurrent = Math.abs(this.QV.current);
		let currentGaugeValue = (absCurrent <= 5 || nullifyCurrent) ? 0 : this.QV.current;
		if(absCurrent <= this.currentMinMax.min || currentGaugeValue == 0)
			currentDegree = 0;
		else if(absCurrent >= this.currentMinMax.max)
			currentDegree = -90;
		else {
			currentDegree = (absCurrent - this.currentMinMax.min) / (this.currentMinMax.max - this.currentMinMax.min);
			currentDegree = -1 * (currentDegree * 100 * (90/100));
		}

		if(this.QV.voltage <= this.voltageMinMax.min)
			voltageDegree = 0;
		else if(this.QV.voltage >= this.voltageMinMax.max)
			voltageDegree = -90;
		else {
			voltageDegree = (this.QV.voltage - this.voltageMinMax.min) / (this.voltageMinMax.max - this.voltageMinMax.min);
			voltageDegree = -1 * (voltageDegree * 100 * (90/100));
		}

		let eventTypeTxt= this.deviceService.eventTypes[this.QV._type];
		ahrPercentage	= ((eventTypeTxt == 'charge' ? this.QV._charge_as : this.QV._inuse_as) / 3600) / this.QV.dv._maxAHR;
		kwhrPercentage	= ((eventTypeTxt == 'charge' ? this.QV._charge_ws : this.QV._inuse_ws) / 3600000) / this.QV.dv._maxKWHR;

		ahrPercentage = (ahrPercentage > 1) ? 1 : ((ahrPercentage < 0) ? 0 : ahrPercentage);
		kwhrPercentage = (kwhrPercentage > 1) ? 1 : ((kwhrPercentage < 0) ? 0 : kwhrPercentage);

		const deviceConfig = DeviceFactory.createDevice(this.device.config_info.sct_device_type);
		let eventImage = deviceConfig.getEventImage(eventTypeTxt, this.device.config_info.is_study_device);

		Object.assign(this.QV, {
			ahrPercentage: (1- ahrPercentage) * 100,
			kwhrPercentage: (1 - kwhrPercentage) * 100,
			currentGaugeValue,
			currentDegree,
			voltageDegree,
			eventImage,
			eventTypeTxt,
		});
	}

	prepareWarnings(data: any) {
		this.warnings = [];
		let warningList = [];

		warningList = data.warnings.map((item: any) => {
			item.warning = this.deviceService.getWarningsMabValues(item.warning_id);
			return item;
		});

		let ignorePermissionList = this.deviceService.excludePermissionWarnings();

		for(let warningData of warningList) {
			if((ignorePermissionList.includes(warningData.warning)) || this.usersService.hasAccessPermission({permission:this.enterprisePermissions.permission}, 'warnings.'+warningData.warning))
				this.warnings.push(warningData);
		}
		this.tableData = this.warnings.map((item: any) => {
			const warningValue = this.deviceService.getWarningsMabValues(item.warning_id);
			return {
				row: { ...item, warning: warningValue },
				warning_name: { value: this.translate.instant(`warnings.${warningValue}`) },
				warning_raise_time: { value: this.datePipe.transform(item.trigger_time, 'long'), sortValue: item.trigger_time}
			};
		});
	}

	prepareGauges(){
		this.currentGaugeNumbers = [];
		this.voltageGaugeNumbers = [];
		let gaugeNumbers = [
			{top: '88px', left: '70px', val: 0},
			{top: '70px', left: '60px', val: 0},
			{top: '55px', left: '53px', val: 0},
			{top: '40px', left: '42px', val: 0},
			{top: '28px', left: '33px', val: 0},
			{top: '20px', left: '15px', val: 0},
			{top: '17px', left: '-4px', val: 0},
		];
		let currentSteps,
			voltageSteps;

		let deviceVPC = this.device.config_info.vpc;
		if(deviceVPC === undefined) {
			deviceVPC = (this.device.config_info.battery_type == this.deviceService.batteryTypesByName.lithuim_ion) ? 3 : 2;
		}

		this.voltageMinMax.min = +(1.7  * (deviceVPC / 2) * this.QV.dv._noc).toFixed(0);
		this.voltageMinMax.max = +(2.37 * (deviceVPC / 2) * this.QV.dv._noc).toFixed(0);

		currentSteps = (this.currentMinMax.max - this.currentMinMax.min) / 6;
		voltageSteps = (this.voltageMinMax.max - this.voltageMinMax.min) / 6;

		for (let index = 0; index < gaugeNumbers.length; index++) {
			let rowC = Object.assign({}, gaugeNumbers[index]);
			rowC.val = +((currentSteps*index) + this.currentMinMax.min).toFixed(0);
			this.currentGaugeNumbers.push(rowC);

			let rowV = Object.assign({}, gaugeNumbers[index]);
			rowV.val = +((voltageSteps*index) + this.voltageMinMax.min).toFixed(0);
			this.voltageGaugeNumbers.push(rowV);
		}
	}

	getWaterLevelIndicator() {
		if (this.QV.water_level_high) {
			this.waterLevelImage = '/sctportal/images/water_level_indicator/water_level_high.png';
			return this.translate.instant('g.high');
		}

		this.waterLevelImage = '/sctportal/images/water_level_indicator/water_level_low.png';
		return this.translate.instant('g.low');
	}

	getLatestLastConnectTime() {
		let latestTime = '';
		let latestTimeField = '';

		for (const field of this.lastConnectTimeFields) {
			if (this.QV.dv[field] > latestTime) {
				latestTimeField = field;
				latestTime = this.QV.dv[field];
			}
		}
		return { label: latestTimeField, value: this.formatTime(latestTime) };
	}

	ngOnChanges() {
		this.getQuickView(false);
	}

	ngOnDestroy() {
		clearInterval(this.intervalID);
	}
}
