import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { SitesService } from 'src/app/sites/sites.service';
import { Howl } from 'howler';
import * as _ from 'underscore';
import { UsersService } from 'src/app/users/users.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';

@Component({
	selector: 'app-charglink-brm-public',
	templateUrl: './charglink-brm-public.component.html',
	styleUrls: ['./charglink-brm-public.component.css']
})
export class CharglinkBrmPublicComponent implements OnInit, OnDestroy {
	groups = [];
	siteId = 0;
	token = '';
	data = [];
	dataSorted = [];
	timeoutId;

	// buzzer sound
	buzzing: boolean = false;
	sound: Howl;
	buzzerEnabled: boolean = true;
	getChargLinkGroupsData: any;

	refreshTimerId;
	failedDataFetchCounter: number = 0;
	getRankEnables: boolean = true;
	isLoggedIn: boolean = false;
	isExpiredToken: boolean = false;

	constructor(
		private sideMenuService: SideMenuService,
		private route: ActivatedRoute,
		private sitesService: SitesService,
		private cdref: ChangeDetectorRef,
		private router: Router,
		private usersService: UsersService,
		private navigation: NavigationService,
	) {}

	ngOnInit() {
		this.token = this.route.snapshot.params['token'];
		this.siteId = this.route.snapshot.queryParams['siteId'] || 0;
		const currentUser = this.usersService.getCurrentUser();

		if (currentUser?.id)
			this.isLoggedIn = true;

		this.initSound();

		this.sideMenuService.hide();
		this.getChargLinkGroups(false);

		this.refreshTimerId = setInterval(() => {
			window.location.reload();
		}, 15*60*1000);
	}

	getChargLinkGroups(noUiBlock=true) {
		let checkForUpdate = true;

		const filters = {
			siteId: this.siteId,
			token: this.token,
			fromDate: '',
			toDate: '',
			isHistory: false,
			publicPageReq: true
		};

		this.sitesService.getBRMGroupsData(filters, noUiBlock).subscribe((data: any) => {
			if ('siteId' in data)
				return this.updateSiteId(data.siteId);

			if (this.failedDataFetchCounter) {
				this.failedDataFetchCounter = 0;
				this.setPageAsConnectivityStatus(true);
			}

			data = this.mapResponseData(data);
			let groups = data.groups;

			// Remove empty groups
			groups = _.filter(groups, function(group){ return group['groupname'];})

			if (!groups.length)
				return this.repeatGetChargLinkGroups();

			for (let group of groups) {
				group['groupname'] = group['groupname'].toUpperCase();
				let {thisGroup, checkUpdate} = this.getGroupDataById(group.id, data, checkForUpdate);
				checkForUpdate = checkUpdate;
				let statuses = group.innergroups;

				for (let status in statuses) {
					let thisGroupStatusChargersById = {};
					let oldGroupIds = [];
					let newGroupIds = [];

					let groupStatuses = group['innergroups'][status];
					let oldGroupStatuses = thisGroup?.['innergroups']?.[status] || [];

					if (checkForUpdate) {
						oldGroupIds = _.pluck(oldGroupStatuses, 'id');
						newGroupIds = _.pluck(groupStatuses, "id");

						checkForUpdate = this.updateDomIfFindChange(oldGroupIds, newGroupIds, data);
					}

					thisGroupStatusChargersById = _.indexBy(oldGroupStatuses, 'id');
					for (let charger of groupStatuses) {
						if (checkForUpdate)
							this.updateChargesData(charger, thisGroupStatusChargersById, data);

						if (this.buzzerEnabled)
							this.checkAndPlayBuzzer(charger);
					}
				}
				this.dataSorted = _.sortBy(this.data, ["is_full_row", "innergroups['ready']"]).reverse();
				this.prepareGroupsData();
			}

			this.repeatGetChargLinkGroups();
		}, () => {
			this.failedDataFetchCounter++;
			if (this.failedDataFetchCounter == 30)
				this.setPageAsConnectivityStatus(false);

			this.repeatGetChargLinkGroups();
		});
	}

	updateSiteId(siteId: number) {
		if (!siteId)
			return this.isExpiredToken = true;

		this.router.navigate(
			['charglink-brm', 'public', this.token],
			{ queryParams: {'siteId': siteId}, replaceUrl: true }
		);

		this.repeatGetChargLinkGroups();
		return;
	}

	repeatGetChargLinkGroups() {
		this.getChargLinkGroupsData = setTimeout(() => {
			if(this.getRankEnables)
				this.getChargLinkGroups();
		}, 3000);
	}

	goBack() {
		if (window.history.length > 1)
			this.navigation.goBack('/');
		else
			this.router.navigate(['/']);
	}

	mapResponseData(data: any) {
		let mappedData: any = {};
		mappedData.site = {
			customerid: data.s.cid,
			id: data.s.id,
		};

		mappedData.groups = [];

		data.g.forEach((group) => {
			const chargers = [];
			group.c.forEach((charger) => {
				chargers.push({
					id: charger.id,
					alertFlag: charger.af,
					currentSOC: charger.csoc,
					connected: charger.c,
					name: charger.n
				});
			});

			mappedData.groups.push(
				{
					groupname: group.gn,
					id: group.id,
					innergroups: {
						ready: chargers
					}
				}
			);

		});

		return mappedData;
	}

	getGroupDataById(id: number, data: any, checkForUpdate: boolean) {
		let thisGroup = {};

		for (let dataGroup of this.data) {
			if (dataGroup.id == id) {
				thisGroup = dataGroup;
				break;
			}
		}

		if (!thisGroup['id']) {
			this.data = data['groups'];
			checkForUpdate = false;
		}

		return {thisGroup, checkUpdate: checkForUpdate};
	}

	updateDomIfFindChange(oldIds, newIds, data) {
		let checkForUpdate = true;

		let areEquals = _.isEmpty(_.difference(oldIds, newIds)) && _.isEmpty(_.difference(oldIds, newIds));
		if (!areEquals) {
			this.data = data['groups'];
			checkForUpdate = false;
			this.cdref.detectChanges();
		}

		return checkForUpdate;
	}

	updateChargesData(charger, thisGroupStatusChargersById, data) {
		for (let key in charger) {
			// update data if there is change in chargers list except for these attributes: ['relaxedBatteryTimer', 'time'];
			if (!['relaxedBatteryTimer', 'time'].includes(key)) {
				if (!thisGroupStatusChargersById[charger.id] || thisGroupStatusChargersById[charger.id][key] != charger[key]) {
					this.data = data.groups;
					break;
				}
			}
		}
	}

	checkAndPlayBuzzer(charger) {
		if (!this.buzzing && charger.alertFlag) {
			this.playBuzzer();
		}
	}

	toggleBuzzer() {
		this.buzzerEnabled = !this.buzzerEnabled;

		if (this.buzzerEnabled)
			this.sitesService.enableBuzzer();
	}

	initSound() {
		let pc = this;

		this.sound = new Howl({
			src: ['/sctportal/audio/buzz.mp3'],
			format: ['mp3'],
			autoUnlock: true,
			loop: true,

			onplayerror: function() {
				this.sound.once('unlock', function() {
					pc.playBuzzer();
				});
			}
		});
	}

	playBuzzer() {
		let buzzerTime = 16000;
		this.sound.play();
		this.buzzing = true;

		this.timeoutId = setTimeout(() => {
			this.buzzing = false;
			this.sound.stop();
		}, buzzerTime);
	}

	prepareGroupsData() {
		const groups = [];

		for (const data of this.dataSorted) {
			if (!data.groupname)
				continue;

			groups.push({
				name: data.groupname,
				location: data.innergroups?.ready?.[0]?.name || null,
			});
		}

		this.groups = groups;
	}

	setPageAsConnectivityStatus(isOnline: boolean) {
		if (!isOnline) {
			clearInterval(this.refreshTimerId)
			return;
		}

		if (!this.refreshTimerId) {
			this.refreshTimerId = setInterval(() => {
				window.location.reload();
			}, 15*60*1000);
		}
	}

	ngOnDestroy() {
		this.sideMenuService.show();
		this.sound.stop();

		if(this.timeoutId)
			clearTimeout(this.timeoutId);

		if(this.getChargLinkGroupsData)
			clearTimeout(this.getChargLinkGroupsData);

		this.getRankEnables = false;
		if (this.refreshTimerId)
			clearInterval(this.refreshTimerId);
	}
}
