import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { SitesService } from 'src/app/sites/sites.service';
import { UsersService } from 'src/app/users/users.service';
import _ from 'underscore';
import * as moment from 'moment';
import { CommonService } from 'src/app/shared/services/common.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { DeviceFactory } from 'src/app/home/site-dashboard/device/device-factory/device-factory';

@Component({
	selector: 'app-search-device-events-report',
	templateUrl: './search-device-events-report.component.html',
	styleUrls: ['./search-device-events-report.component.css']
})

export class SearchDeviceEventsReportComponent implements OnInit, AfterViewInit {
	model: {
		selectedCustomers: number[],
		selectedSites: number[],
		selectedDeviceType: number,
		thresholdValue: number
		fromDate: Date,
		toDate: Date
	} = {
		selectedCustomers: [],
		selectedSites: [],
		selectedDeviceType: null,
		thresholdValue: null,
		fromDate: new Date(new Date().setDate(new Date().getDate() - 30)),
		toDate: new Date(),
	};

	today: Date =  new Date();
	minDate: Date;
	maxDate: Date;

	customers: any[] = [];
	customersSites: Object;
	sites: any[] = [];
	allSites: any[];
	devicesType = DeviceFactory.options;

	thresholdValueRequired: boolean = false;
	invalidStartDate: boolean = false;
	invalidEndDate: boolean = false;
	processOnGoing: boolean = false;

	@ViewChild("confirmActionModal") confirmActionModal;
	@ViewChild("confitmCancleSearchModal") confitmCancleSearchModal;
	constructor(
		private usersService: UsersService,
		private router: Router,
		private sitesService: SitesService,
		private translateService: TranslateService,
		private deviceManagement: DeviceManagementService,
		private sctToastService: NotificationMessageService,
		private commonService: CommonService,
		private sideMenuService: SideMenuService,
	) { }

	ngOnInit() {
		if (!this.usersService.hasAccessPermission(null, 'noc'))
			return this.router.navigate(['/unauthorized']);

		this.sideMenuService.hide();

		this.sitesService.getUserSitesInfo({get_customers: true}).subscribe(
			(data: any) => {
				this.customers = _.map(data.customers, (item) => ({id: item.id, customer_name: item.customer_name}));
				this.customersSites = _.chain(data.customers).pluck('sites').flatten(true).groupBy('customer_id').value();
				this.sites = _.chain(data.customers).pluck('sites').flatten(true).value();
				this.allSites = this.sites;
				this.getSearchProcessStatus();
			}
		);
	}

	ngAfterViewInit() {
		this.confirmActionModal.onClose.subscribe((ok) => {
			if(ok)
				return this.searchDevicesEventsByAh();
		});

		this.confitmCancleSearchModal.onClose.subscribe((ok) => {
			if (ok)
				return this.cancleSearchAhEventsProcess();
		})
	}

	updateMinDate(newEndDate: Date) {
		const minDate = new Date(newEndDate.getTime());
		minDate.setMonth(minDate.getMonth() - 1);
		this.minDate = minDate;
		this.maxDate = new Date(newEndDate);
	}

	searchDevicesEventsByAh() {
		const reportData: any = JSON.parse(JSON.stringify(this.model));
		reportData.fromDate = this.commonService.getUnixDateByUTCZone(this.model.fromDate);
		reportData.toDate = this.commonService.getUnixDateByUTCZone(this.model.toDate);

		this.deviceManagement.searchDevicesEventsByAh(reportData).subscribe((response: any) => {
			switch(response) {
				case 'no data found':
					return this.sctToastService.setMessage('translate|g.no_data_available', {clearOnXTimeNavigate: 1, type: 'warning'});
				default:
					this.updateFormModel(response);
					return this.sctToastService.setMessage('globalSuccessMsg');
			}
		});
	}

	getSearchProcessStatus() {
		this.deviceManagement.getSearchProcessStatus().subscribe((res: any) => {
			if (res.length) {
				res = JSON.parse(res[0]);
				this.updateFormModel(res);
			}
		})
	}

	cancleSearchAhEventsProcess() {
		this.deviceManagement.cancleSearchAhEventsProcess().subscribe((res: any) => {
			if (res) {
				this.processOnGoing = false;
				this.sctToastService.setMessage('globalSuccessMsg', {clearOnXTimeNavigate: 1, type: 'success'});
				return this.getSearchProcessStatus();
			}
		});
	}

	updateSitesAvailable() {
		this.sites = [];
		if(this.model.selectedCustomers.length > 0) {
			for(const customerId of this.model.selectedCustomers) {
				this.sites = this.sites.concat(this.customersSites[customerId]);
			}
		}

		if (this.sites.length === 0)
			return;
	}

	updateFormModel(data) {
		this.processOnGoing = true;
		this.model.thresholdValue = data.thresholdValue;
		this.model.fromDate = new Date(moment(data.fromDate*1000).format('MM/DD/YYYY'));
		this.model.toDate = new Date(moment(data.toDate*1000).format('MM/DD/YYYY'));
		this.model.selectedCustomers = data.selectedCustomers || null;
		this.model.selectedSites = data.selectedSites || null;
		this.model.selectedDeviceType = data.selectedDeviceType || null;
	}

	showActionModal() {
		this.thresholdValueRequired = !this.model.thresholdValue;
		this.invalidStartDate = !this.model.fromDate;
		this.invalidEndDate = !this.model.toDate;

		const maxAllowedRangeDate = 30*24*60*60;
		const startTimeStamp = moment(this.model.fromDate).unix();
		const endTimeStamp = moment(this.model.toDate).unix();
		if (this.thresholdValueRequired || this.invalidStartDate || this.invalidEndDate)
			return this.sctToastService.setMessage('translate|g.field_is_required');

		if(startTimeStamp > endTimeStamp) {
			this.invalidStartDate = true;
			return this.sctToastService.setMessage('translate|g.start_lg_end');
		}

		if (endTimeStamp - startTimeStamp > maxAllowedRangeDate) {
			this.invalidEndDate = true;
			return this.sctToastService.setMessage('translate|g.exceed_max_range_allowed_one_month_msg');
		}

		this.confirmActionModal.show();
	}
}
