<div class="main-black border-radius-bottom white-box p-4" *ngIf="showInsight()">
	<div class="rounded light-grey-border">
		<div class="d-flex flex-column" style="height: 400px;">
			<div class="row p-0 m-2" style="height: 50px;">
				<div *ngIf="wifiImage" tooltip="{{connectivityTooltipMessages}}" [ngClass]="{ pointer: connectivityTooltipMessages != ''}">
					<a [routerLink]="deviceService.getImageRouterLink('wifi', currentSite.customer_id, {mac_address: device.mac_address, site_id: currentSite.id})" [queryParams]="{tab: 'networking'}">
						<img class="size-35x35 mr-1" src="{{wifiImage}}">
					<span *ngIf="QV.dv._wifiConnected" class="font-weight-bold light-grey">{{'qv.wifi_connected' | translate}}</span>
					</a>
				</div>
				<div *ngIf="connectivityImage" tooltip="{{connectivityTooltipMessages}}" [ngClass]="{ pointer: connectivityTooltipMessages != ''}">
					<a [routerLink]="deviceService.getImageRouterLink('cellular', currentSite.customer_id, {mac_address: device.mac_address, site_id: currentSite.id})">
						<img class="size-35x35 mr-1" src="/sctportal/{{connectivityImage}}">
					</a>
				</div>
				<div *ngIf="usersService.hasAccessPermission(null, 'noc')">
					<a [routerLink]="['/noc-tools', 'quick-view-history', device.mac_address]"><button class="btn btn-primary ml-4" >{{'qv.qv_log' | translate}}</button></a>
				</div>
			</div>
			<div class="d-flex">
				<div class="d-flex align-items-center justify-content-center w-50 p-5">
					<div class="mx-auto d-flex flex-column">
						<div class="ml-4" style="height: 115px;">
							<div class="position-relative">
								<div class="position-absolute" style="top: 93px;" [ngStyle]="{'transform': 'rotate('+QV.currentDegree+'deg)'}">
									<img class="position-absolute gauge-pointer" src="/sctportal/images/qv/blue-arch-100.png">
								</div>
								<span *ngFor="let gaugeNumber of currentGaugeNumbers" class="position-absolute blue-gauge-numbers" [ngStyle]="{'top': gaugeNumber.top, 'left': gaugeNumber.left}">{{gaugeNumber.val}}</span>
								<img class="position-absolute" src="/sctportal/images/qv/arch-100.png">
							</div>
						</div>
						<h4 class="mx-auto light-grey">{{'g.current_caps' | translate}}</h4>
						<h2 class="mx-auto font-weight-bold">{{QV.currentGaugeValue | number: '1.0-2'}}A</h2>
					</div>
				</div>
				<div class="vertical-split my-auto mx-3"></div>
				<div class="d-flex align-items-center justify-content-center w-50 p-5">
					<div class="mx-auto d-flex flex-column">
						<div class="ml-5" style="height: 115px;">
							<div class="position-relative">
								<div class="position-absolute" style="top: 93px;" [ngStyle]="{'transform': 'rotate('+QV.voltageDegree+'deg)'}">
									<img class="position-absolute gauge-pointer" src="/sctportal/images/qv/green-arch-100.png">
								</div>
								<span *ngFor="let gaugeNumber of voltageGaugeNumbers" class="position-absolute green-gauge-numbers" [ngStyle]="{'top': gaugeNumber.top, 'left': gaugeNumber.left}">{{gaugeNumber.val}}</span>
								<img class="position-absolute" src="/sctportal/images/qv/arch-100.png">
							</div>
						</div>
						<h4 class="mx-auto light-grey">{{'g.voltage_caps' | translate}}</h4>
						<h2 class="mx-auto font-weight-bold">{{QV.voltage | number: '1.0-2'}}V</h2>
					</div>
				</div>
			</div>
		</div>
		<div class="horizontal-split"></div>
		<div class="d-flex p-4" style="height: 230px;">
			<div class="d-flex align-items-center flex-column w-50">
				<div class="my-auto p-2 d-flex" style="height: 120px;">
					<div class="mt-auto mr-4 d-inline-flex position-relative">
						<div class="gauge-bar kwhr-bar"></div>
						<div class="position-absolute left-arrow-container">
							<div class="left-arrow position-absolute" [ngStyle]="{'top': QV.kwhrPercentage+'%'}"></div>
						</div>
						<div class="black-bar"></div>
					</div>
					<div class="d-flex align-items-center flex-column mt-auto ml-3">
						<h2 class="font-weight-bold">{{(QV.eventTypeTxt == 'charge' ? QV._charge_ws : QV._inuse_ws) / 3600000 | number: '1.0-1'}}</h2>
						<h3 class="font-weight-bold light-grey">{{'g.kwhr' | translate}}</h3>
					</div>
				</div>
			</div>
			<div class="vertical-split my-auto mx-3"></div>
			<div class="d-flex align-items-center flex-column w-50">
				<div class="my-auto p-2 d-flex" style="height: 120px;">
					<div class="mt-auto mr-4 d-inline-flex position-relative">
						<div class="gauge-bar ahr-bar"></div>
						<div class="position-absolute left-arrow-container">
							<div class="left-arrow position-absolute" [ngStyle]="{'top': QV.ahrPercentage+'%'}"></div>
						</div>
						<div class="black-bar"></div>
					</div>
					<div class="d-flex align-items-center flex-column mt-auto">
						<h2 class="font-weight-bold">{{(QV.eventTypeTxt == 'charge' ? QV._charge_as : QV._inuse_as) / 3600 | number: '1.0-1'}}</h2>
						<h3 class="font-weight-bold light-grey">{{'g.ahr' | translate}}</h3>
					</div>
				</div>
			</div>
		</div>
		<div class="horizontal-split"></div>
		<div class="d-flex main-bg rounded-bottom" style="height: 250px;">
			<div class="d-flex align-items-center flex-column w-50 py-5 my-auto">
				<img class="" src="/sctportal/{{QV.eventImage}}" height="64" class="mb-2">
				<h2 class="font-weight-bold">{{formatQv('event_type', 'event')}}</h2>
				<h5 class="font-weight-bold light-grey">{{'g.event' | translate}}</h5>
			</div>
			<div class="vertical-split my-auto mx-3"></div>
			<div class="d-flex align-items-center flex-column w-50 py-5 my-auto">
				<img class="" src="/sctportal/images/qv/time.png">
				<h2 class="font-weight-bold">{{formatTime(QV.time, null, {timeOnly: true})}}</h2>
				<h5 class="font-weight-bold light-grey">{{'g.time' | translate}}</h5>
			</div>
			<div class="vertical-split my-auto mx-3"></div>
			<div class="d-flex align-items-center flex-column w-50 py-5 my-auto">
				<img class="" src="/sctportal/images/qv/event_duration.png">
				<h2 class="font-weight-bold">{{common.secondsToDaysHoursMinutesAndSeconds(QV._duration)}}</h2>
				<h5 class="font-weight-bold light-grey">{{'g.event_duration' | translate}}</h5>
			</div>
			<ng-container *ngIf="deviceConfig.fields.hasWaterLevel && QV.water_level_exist">
				<div class="vertical-split my-auto mx-3"></div>
				<div class="d-flex align-items-center flex-column w-50 py-5 my-auto">
					<img class="" src="{{waterLevelImage}}">
					<h2 class="font-weight-bold">{{getWaterLevelIndicator()}}</h2>
					<h5 class="font-weight-bold light-grey">{{'event.water_level' | translate}}</h5>
				</div>
			</ng-container>
			<div class="vertical-split my-auto mx-3"></div>
			<div class="d-flex align-items-center flex-column w-50 py-4 my-auto">
				<a
					*ngIf="device.config_info.last_latitude && device.config_info.last_longitude && device.config_info.last_update_location_time; else elsecase"
					[href]="'https://www.google.com/maps/search/?api=1&query=' + device.config_info.last_latitude+','+ device.config_info.last_longitude"
					target="_blank"
					class="location-box d-flex align-items-center flex-column"
				>
					<fa-icon class="location-icon" icon="map-marker-alt" size="lg"></fa-icon>
					<div class="mt-2 text-nowrap ">
						<strong>{{'event.latitude' | translate}}: </strong><span>{{device.config_info.last_latitude}}</span>
					</div>
					<div class="mt-2 text-nowrap">
						<strong>{{'event.longitude' | translate}}: </strong><span>{{device.config_info.last_longitude}}</span>
					</div>
					<div class="mt-2 text-nowrap">
						<strong>{{'qv.last_update_location_time' | translate}}: </strong><span>{{formatTime(device.config_info.last_update_location_time * 1000, null, {convertToTimestamp: true, dateOnly: true})}}</span>
					</div>
				</a>
				<ng-template #elsecase class="location-box d-flex align-items-center flex-column">
					<fa-icon class="location-icon" icon="map-marker-alt" size="lg"></fa-icon>
					<div class="mt-2">
						<strong>{{'event.latitude' | translate}}: </strong><span>{{'g.n/a' | translate}}</span>
					</div>
					<div class="mt-2">
						<strong>{{'event.longitude' | translate}}: </strong><span>{{'g.n/a' | translate}}</span>
					</div>
					<div class="mt-2">
						<strong>{{'qv.last_update_location_time' | translate}}: </strong><span>{{'g.n/a' | translate}}</span>
					</div>
				</ng-template>
				<h5 class="font-weight-bold light-grey">{{'g.device_location' | translate}}</h5>
			</div>
		</div>
	</div>
</div>


<div class="main-black border-radius white-box p-4 mt-4" *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'debug_data')">
	<div class="row" *ngIf="showInsight()">
		<div class="col-md-12">
			<h5 class="mt-2">{{'qv.insight' | translate}}</h5>
		</div>
		<div class="col-md-4 mt-2" *ngIf="QV.time !== undefined">
			<strong>{{'g.time' | translate}}: </strong><span>{{formatQv('time')}} ({{common.getZoneName(QV.dv._zoneId)}})</span>
		</div>
		<div class="col-md-4 mt-2" *ngIf="QV.version !== undefined">
			<strong>{{'g.version' | translate}}: </strong><span>{{QV.version}}</span>
		</div>
		<div class="col-md-4 mt-2" *ngIf="QV.light_sleep_mode !== undefined">
			<strong>{{'qv.sleep_mode' | translate}}: </strong><span>{{formatQv('light_sleep_mode')}}</span>
		</div>
		<div class="col-md-4 mt-2" *ngIf="QV.battery_connected !== undefined">
			<strong>{{'qv.battery_connected' | translate}}: </strong><span>{{formatQv('battery_connected')}}</span>
		</div>
		<div class="col-md-4 mt-2" *ngIf="QV.hw_status !== undefined">
			<strong>{{'qv.hw_status' | translate}}: </strong><span>{{formatQv('hw_status')}}</span>
		</div>
		<div class="col-md-4 mt-2">
			<strong>{{'g.wifi' | translate}}: </strong>
			<span>{{getStaStatus()}}</span>
		</div>
		<div class="col-md-4 mt-2">
			<strong>{{'qv.ap_status' | translate}}: </strong>
			<span>{{deviceCommonService.getApStatus(QV.ap_status, QV.ap_ip)}}</span>
		</div>
		<div class="col-md-4 mt-2" *ngIf="QV.halleffect_c !== undefined">
			<strong>{{'qv.hall_effect' | translate}}: </strong><span>{{QV.halleffect_c}}</span>
		</div>
		<div class="col-md-4 mt-2" [ngClass]="{'text-danger': QV.readings && !QV.readings.includes('voltage')}">
			<strong>{{'rt.voltage' | translate}}: </strong><span>{{QV.voltage | number: '1.0-2'}}</span>
		</div>
		<div class="col-md-4 mt-2" *ngIf="QV.ext_adc_voltage !== undefined">
			<strong>{{'qv.voltage_adc' | translate}}: </strong><span>{{QV.ext_adc_voltage | number: '1.0-2'}}</span>
		</div>
		<div class="col-md-4 mt-2" [ngClass]="{'text-danger': QV.readings && !QV.readings.includes('current')}">
			<strong>{{'rt.current' | translate}}: </strong><span>{{QV.current | number: '1.0-2'}}</span>
		</div>
		<div class="col-md-4 mt-2" *ngIf="QV.ext_adc_current !== undefined">
			<strong>{{'qv.current_adc' | translate}}: </strong><span>{{QV.ext_adc_current | number: '1.0-2'}}</span>
		</div>
		<div class="col-md-4 mt-2" *ngIf="QV.temperature_adc !== undefined">
			<strong>{{'qv.temperature_adc' | translate}}: </strong><span>{{QV.temperature_adc | number: '1.0-2'}}</span>
		</div>
		<div class="col-md-4 mt-2" *ngIf="QV.ext_adc_current_low_range !== undefined">
			<strong>{{'qv.low_current_adc' | translate}}: </strong><span>{{QV.ext_adc_current_low_range | number: '1.0-2'}}</span>
		</div>
		<div class="col-md-4 mt-2" *ngIf="QV.readings !== undefined">
			<strong>{{'qv.readings' | translate}}: </strong><span>{{formatQv('readings')}}</span>
		</div>
		<div class="col-md-4 mt-2" *ngIf="QV.rssi !== undefined">
			<strong>{{'qv.rssi' | translate}}: </strong><span>{{QV.rssi}}</span>
		</div>
		<div class="col-md-4 mt-2" *ngIf="QV.network_idx !== undefined">
			<strong>{{'qv.network_idx' | translate}}: </strong><span>{{QV.network_idx}}</span>
		</div>
		<div class="col-md-4 mt-2" *ngIf="device.config_info.has_current_low_range !== undefined">
			<strong>{{'devices.has_current_low_range' | translate}}: </strong><span>{{!!device.config_info.has_current_low_range}}</span>
		</div>
		<div class="col-md-4 mt-2" *ngIf="QV.low_range_is_inuse !== undefined">
			<strong>{{'qv.low_range_is_inuse' | translate}}: </strong><span>{{formatQv('low_range_is_inuse')}}</span>
		</div>
		<div class="col-md-4 mt-2">
			<strong>{{'qv.'+formatQv('wifi_cellular_last_connect_time').label | translate}}: </strong><span>{{formatQv('wifi_cellular_last_connect_time').value}}</span>
		</div>
		<div class="col-md-4 mt-2" *ngIf="deviceConfig.fields.hasWaterLevel && device.config_info.has_water_level && QV.el_level !== undefined">
			<strong>{{'qv.el_level' | translate}}: </strong><span>{{formatQv('el_level')}}</span>
		</div>
	</div>

	<div class="my-2" *ngIf="showEvent()">
		<app-simple-widget [title]="'qv.current_event' | translate" [isCollapsed]="true">
			<div class="row">
				<div class="col-md-4 mt-2" *ngIf="QV.live_event.sequence_id !== undefined">
					<strong>{{'event.sequence_id' | translate}}: </strong><span>{{QV.live_event.sequence_id}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_event.event_type !== undefined">
					<strong>{{'event.event_type' | translate}}: </strong><span>{{formatQv('event_type', 'event')}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_event.timestamp !== undefined">
					<strong>{{'g.start_time' | translate}}: </strong><span>{{formatQv('timestamp_utc', 'event')}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_event.end_timestamp !== undefined">
					<strong>{{'g.end_time' | translate}}: </strong><span>{{formatQv('end_timestamp_utc', 'event')}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_event.my_zone_id !== undefined">
					<strong>{{'g.time_zone' | translate}}: </strong><span>{{formatQv('my_zone_id', 'event')}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_event.isValidCRC16 !== undefined">
					<strong>{{'g.is_valid' | translate}}: </strong><span>{{formatQv('isValidCRC16', 'event')}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_event.charge_ws !== undefined">
					<strong>{{'events.charge_kwhr' | translate}}: </strong><span>{{QV.live_event.charge_ws / 3600000}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_event.inuse_ws !== undefined">
					<strong>{{'events.inuse_kwhr' | translate}}: </strong><span>{{QV.live_event.inuse_ws / 3600000}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_event.charge_as !== undefined">
					<strong>{{'events.charge_ahr' | translate}}: </strong><span>{{QV.live_event.charge_as / 3600}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_event.inuse_as !== undefined">
					<strong>{{'events.inuse_ahr' | translate}}: </strong><span>{{QV.live_event.inuse_as / 3600}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_event.number_of_cells !== undefined">
					<strong>{{'event.number_of_cells' | translate}}: </strong><span>{{QV.live_event.number_of_cells}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_event.vpc !== undefined">
					<strong>{{'event.vpc' | translate}}: </strong><span>{{QV.live_event.vpc}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_event.min_voltage !== undefined">
					<strong>{{'event.min_voltage' | translate}}: </strong><span>{{QV.live_event.min_voltage | number: '1.1-3'}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_event.start_voltage !== undefined">
					<strong>{{'event.start_voltage' | translate}}: </strong><span>{{QV.live_event.start_voltage | number: '1.1-3'}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_event.end_voltage !== undefined">
					<strong>{{'event.end_voltage' | translate}}: </strong><span>{{QV.live_event.end_voltage | number: '1.1-3'}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_event.event_max_current !== undefined">
					<strong>{{'event.event_max_current' | translate}}: </strong><span>{{QV.live_event.event_max_current | number: '1.1-3'}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_event.event_min_current !== undefined">
					<strong>{{'event.event_min_current' | translate}}: </strong><span>{{QV.live_event.event_min_current | number: '1.1-3'}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_event.event_average_current !== undefined">
					<strong>{{'event.event_average_current' | translate}}: </strong><span>{{QV.live_event.event_average_current | number: '1.1-3'}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_event.hall_effect_count !== undefined">
					<strong>{{'event.hall_effect_count' | translate}}: </strong><span>{{QV.live_event.hall_effect_count}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_event.start_soc !== undefined && (device.config_info.is_study_device || deviceConfig.events.hasOrPowerStudy.start_soc)">
					<strong>{{'event.start_soc' | translate}}: </strong><span>{{QV.live_event.start_soc}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_event.end_soc !== undefined && (device.config_info.is_study_device || deviceConfig.events.hasOrPowerStudy.end_soc)">
					<strong>{{'event.end_soc' | translate}}: </strong><span>{{QV.live_event.end_soc}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="deviceConfig.dailyDetails.hasField.accurate_inuse_seconds && QV.live_event.accurate_inuse_seconds !== undefined">
					<strong>{{'event.accurate_inuse_seconds' | translate}}: </strong><span>{{QV.live_event.accurate_inuse_seconds}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_event.accurate_charge_seconds !== undefined">
					<strong>{{'event.accurate_charge_seconds' | translate}}: </strong><span>{{QV.live_event.accurate_charge_seconds}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_event.latitude !== undefined && isAdminUser">
					<strong>{{'event.latitude' | translate}}: </strong><span>{{QV.live_event.latitude}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_event.longitude!== undefined && isAdminUser">
					<strong>{{'event.longitude' | translate}}: </strong><span>{{QV.live_event.longitude}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV._duration !== undefined">
					<strong>{{'events.event_duration' | translate}}: </strong><span>{{common.secondsToDaysHoursMinutesAndSeconds(QV._duration)}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="deviceConfig.fields.hasWaterLevel && device.config_info.has_water_level && QV.live_event.water_level_high !== undefined">
					<strong>{{'event.water_level' | translate}}: </strong><span>{{formatQv('water_level_high', 'event')}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_event.lift_time !== undefined && deviceConfig.dailyDetails.hasField.lift_time">
					<strong>{{'events.lift_time' | translate}}: </strong> <span>{{QV.live_event.lift_time}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_event.travel_time !== undefined && deviceConfig.dailyDetails.hasField.travel_time">
					<strong>{{'events.travel_time' | translate}}: </strong> <span>{{QV.live_event.travel_time}}</span>
				</div>
				<div class="col-md-4 mt-2">
					<strong>{{'g.notes' | translate}}: </strong><span>{{formatQv('notes', 'event')}}</span>
				</div>
			</div>
		</app-simple-widget>
	</div>

	<div class="my-2" *ngIf="showRt()">
		<app-simple-widget [title]="'qv.rt_record' | translate" [isCollapsed]="true">
			<div class="row">
				<div class="col-md-4 mt-2" *ngIf="QV.live_rt.sequence_id !== undefined">
					<strong>{{'rt.sequence_id' | translate}}: </strong><span>{{QV.live_rt.sequence_id}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_rt.timestamp !== undefined">
					<strong>{{'rt.timestamp' | translate}}: </strong><span>{{formatQv('timestamp', 'rt')}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_rt.current !== undefined">
					<strong>{{'rt.current' | translate}}: </strong><span>{{QV.live_rt.current | number: '1.0-2'}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_rt.voltage !== undefined">
					<strong>{{'rt.voltage' | translate}}: </strong><span>{{QV.live_rt.voltage | number: '1.0-2'}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_rt.temperature !== undefined">
					<strong>{{'rt.temperature' | translate}}: </strong><span>{{QV.live_rt.temperature | number: '1.0-2'}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_rt.version !== undefined">
					<strong>{{'rt.version' | translate}}: </strong><span>{{QV.live_rt.version | number: '1.0-2'}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="QV.live_rt.rt_flag_current_error !== undefined">
					<strong>{{'rt.rt_flag_current_error' | translate}}: </strong><span>{{(formatQv('rt_flag_current_error', 'rt'))}}</span>
				</div>
				<div class="col-md-4 mt-2" *ngIf="deviceConfig.fields.hasWaterLevel && device.config_info.has_water_level && QV.live_rt.rt_el_level_flag !== undefined">
					<strong>{{'rt.rt_el_level_flag' | translate}}: </strong><span>{{(formatQv('rt_el_level_flag', 'rt'))}}</span>
				</div>
			</div>
		</app-simple-widget>
	</div>

	<div class="my-2" *ngIf="showCalcs()">
		<app-simple-widget [title]="'g.calculator' | translate" [isCollapsed]="true">
			<div class="row">
				<div class="col-md-4 mt-2">
					<strong>{{'calcs.battery_capacity_as' | translate}}: </strong><span>{{calcs.battery_capacity_as | number}}</span>
				</div>
				<div class="col-md-4 mt-2">
					<strong>{{'event.start_voltage' | translate}}: </strong><span>{{calcs.start_voltage | number: '1.1-3'}}</span>
				</div>
				<div class="col-md-4 mt-2">
					<strong>{{'event.charge_as' | translate}}: </strong><span>{{calcs.charge_as_no_transition}}</span>
				</div>
				<div class="col-md-4 mt-2">
					<strong>{{'event.discharge_as' | translate}}: </strong><span>{{calcs.discharge_as_no_transition}}</span>
				</div>
				<div class="col-md-4 mt-2">
					<strong>{{'event.charge_ws' | translate}}: </strong><span>{{calcs.charge_ws_no_transition}}</span>
				</div>
				<div class="col-md-4 mt-2">
					<strong>{{'event.discharge_ws' | translate}}: </strong><span>{{calcs.discharge_ws_no_transition}}</span>
				</div>
				<div class="col-md-4 mt-2">
					<strong>{{'event.max_event_voltage' | translate}}: </strong><span>{{calcs.max_event_voltage_no_transition | number: '1.1-3'}}</span>
				</div>
				<div class="col-md-4 mt-2">
					<strong>{{'event.min_event_voltage' | translate}}: </strong><span>{{calcs.min_event_voltage_no_transition | number: '1.1-3'}}</span>
				</div>
				<div class="col-md-4 mt-2">
					<strong>{{'event.charge_as' | translate}}: </strong><span>{{calcs.charge_as_in_transition}}</span>
				</div>
				<div class="col-md-4 mt-2">
					<strong>{{'event.discharge_as' | translate}}: </strong><span>{{calcs.discharge_as_in_transition}}</span>
				</div>
				<div class="col-md-4 mt-2">
					<strong>{{'event.charge_ws' | translate}}: </strong><span>{{calcs.charge_ws_in_transition}}</span>
				</div>
				<div class="col-md-4 mt-2">
					<strong>{{'event.discharge_ws' | translate}}: </strong><span>{{calcs.discharge_ws_in_transition}}</span>
				</div>
				<div class="col-md-4 mt-2">
					<strong>{{'event.end_current_slow' | translate}}: </strong><span>{{calcs.last300_seconds_event_average_current | number: '1.1-3'}}</span>
				</div>
				<div class="col-md-4 mt-2">
					<strong>{{'event.end_current' | translate}}: </strong><span>{{calcs.event_last2_seconds_current | number: '1.1-3'}}</span>
				</div>
				<div class="col-md-4 mt-2">
					<strong>{{'event.max_current' | translate}}: </strong><span>{{calcs.max_event_current | number: '1.1-3'}}</span>
				</div>
				<div class="col-md-4 mt-2">
					<strong>{{'event.end_voltage_slow' | translate}}: </strong><span>{{calcs.last_event_seconds_voltage_no_transition | number: '1.1-3'}}</span>
				</div>
				<div class="col-md-4 mt-2">
					<strong>{{'event.end_voltage' | translate}}: </strong><span>{{calcs.last2_seconds_voltage_no_transition | number: '1.1-3'}}</span>
				</div>
				<div class="col-md-4 mt-2">
					<strong>{{'event.inuse_seconds' | translate}}: </strong><span>{{calcs.inuse_seconds}}</span>
				</div>
			</div>
		</app-simple-widget>
	</div>

	<div class="my-2" *ngIf="usersService.hasAccessFunction('show_warnings')">
		<app-simple-widget [title]="'g.warnings' | translate" [isCollapsed]="true">
			<sct-custom-table
				[data]="tableData"
				[columnsConfig]="column"
				[config]="tableConfig"
			>
			</sct-custom-table>
		</app-simple-widget>
	</div>
</div>
