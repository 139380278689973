<app-site-performance-widget
	[siteId]="siteId"
	[customerId]="customerId"
	[widgetConfig]="widgetConfig"
	[devices]="devices"
	[tableItems]="tableItems"
	[tableColumns]="tableColumns"
	[exportFileName]="exportFileName"
	[sizeColumnsToFit]="sizeColumnsToFit"
	[chartTableConfig]="chartTableConfig"
	[tags]="tags"
	[noDataForCurrentFilters]="noDataForCurrentFilters"
	(select)="onSelect($event)"
></app-site-performance-widget>
