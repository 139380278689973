import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/app/users/users.service';
import * as moment from 'moment';
import { DecimalPipe } from '@angular/common';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
	selector: 'app-battery-performance',
	templateUrl: './battery-performance.component.html',
	styleUrls: ['./battery-performance.component.scss']
})

export class BatteryPerformanceComponent implements OnInit, OnChanges {
	@Input() dailyDetails: any = {};
	@Input() deviceInfo: any = {};
	@Input() currentSite: any = {};
	@Input() userAlertSettings: any = {};

	currentUser: any = {};
	batterySummary: any = {};
	summaryObject: any = {}; // TODO: summary daily details in ACTVIEW

	utilizationChartData: any = [];
	remainingLifeAhrsPercentChartData: any = [];
	remainingLifeYrsPercentChartData: any = [];

	utilizationChartOptions: any = {};
	remainingLifeAhrsPercentChartOptions: any = {};
	remainingLifeYrsPercentChartOptions: any = {};

	noDataColor = '#e5e1e1';
	workingDaysOnly: boolean = false;

	pieChartOptions = {
		utilization: {title: this.translate.instant('battery_performance.utilization')},
		remaining_lift_ahrs: {title: this.translate.instant('battery_performance.remaining_lift_ahrs')},
		remaining_lift_Yrs: {title: this.translate.instant('battery_performance.remaining_lift_Yrs')}
	}

	barHeightsInPixels = 400;
	arrowHeight = 0;
	barMaxPercentage = 150;
	limits = [100, 130, 147];
	limitsColors = ['orange', '#3dd3b2', 'rgb(218, 45, 45)'];

	batteryBarHeights = {
		total: this.barHeightsInPixels,
		filled: 0,
		red:      ( (this.limits[2] - this.limits[1]) / this.barMaxPercentage) * this.barHeightsInPixels,
		green:    ( (this.limits[1] - this.limits[0]) / this.barMaxPercentage) * this.barHeightsInPixels,
		orange:   ( this.limits[0]                    / this.barMaxPercentage) * this.barHeightsInPixels,
		arrow:    ( this.arrowHeight                  / this.barMaxPercentage) * 100,
		color: this.limitsColors[0]
	};

	constructor(
		private translate: TranslateService,
		private commonService: CommonService
	) { }

	ngOnInit() {
		this.utilizationChartOptions = this.getPieChartOptions();
		this.remainingLifeAhrsPercentChartOptions = this.getPieChartOptions();
		this.remainingLifeYrsPercentChartOptions = this.getPieChartOptions();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.dailyDetails &&  (Object.keys(changes.dailyDetails.currentValue).length > 0))
			return this.prepareData();
		return;
	}

	getPieChartOptions() {
		return {
			title: "",
			height: 200,
			width: 250,
			legend: { position: 'none'},
			tooltip: {
				trigger: 'hover',
				isHtml: false,
				text: 'percentage',
				showColorCode: true
			},
			slices: {
				0: { color: "#009fe3" },
				1: { color: this.noDataColor },
			},
			pieSliceText: 'percentage',
			pieSliceTextStyle: {
				fontSize: 14
			},
			chartArea: { width: '100%' },
		};
	}

	prepareData() {
		this.generateDailyDetailsSummaryData();
		const workingDaysOnly = (this.batterySummary && this.batterySummary.workingDaysOnly) || false;
		this.batterySummary = this.calculateDailyDetails(workingDaysOnly);

		this.batterySummary.hideEBU = this.summaryObject.totalchargeAHR < this.deviceInfo.config_info.battery_capacity || this.summaryObject.totalDischargeAHR < this.deviceInfo.config_info.battery_capacity;
		this.batterySummary.hideEBU = this.batterySummary.hideEBU || (this.summaryObject.totalDischargeAHR / (0.8 * this.deviceInfo.config_info.battery_capacity * this.summaryObject.countOfWorkingDays) < 0.25);

		this.batterySummary.ahrs_return_text =  Math.round(this.batterySummary.ahrs_return);
		this.batterySummary.ahrs_return_width = this.batterySummary.ahrs_return;

		if (this.batterySummary.ahrs_return_width > 165)
			this.batterySummary.ahrs_return_width = 165;

		this.batterySummary.ahrs_return_width /=1.65;

		if (this.batterySummary.minChargeOppurtinityDuration > 8*60*60 )
			this.batterySummary.minChargeOppurtinityDuration = 8*60*60;


		this.batterySummary.min_charge_oppurtinity_width = ( this.batterySummary.minChargeOppurtinityDuration / (8*60*60) ) * 100;
		this.batterySummary.min_charge_oppurtinity_duration = this.commonService.timeFormat(this.batterySummary.minChargeOppurtinityDuration);

		/* remaining life Ahr */
		this.batterySummary.remaining_life_ahrs_percentage = [this.batterySummary.usedAhrs, this.batterySummary.totalAhrs - this.batterySummary.usedAhrs, this.batterySummary.remaingMonthsPerUsage];
		this.batterySummary.remaining_life_ahrs_percent = this.calcProportion((this.batterySummary.totalAhrs - this.batterySummary.usedAhrs), this.batterySummary.usedAhrs);
		this.batterySummary.remaining_life_months_per_ahrs = this.batterySummary.remaingMonthsPerUsage;


		/* remaining life Year */
		this.batterySummary.remaining_life_yrs = [this.batterySummary.usedLifeYears, this.batterySummary.totalLifeYears - this.batterySummary.usedLifeYears]
		this.batterySummary.remaining_life_yrs_percent = this.calcProportion(this.batterySummary.remaining_life_yrs[1], this.batterySummary.remaining_life_yrs[0]);
		this.workingDaysChanged();
		this.applyBatterySummaryToCharts();
	}

	calculateDailyDetails(workingDaysOnly=false) {
		let decimalPipe: DecimalPipe = new DecimalPipe("en-US");
		let countOfWorkingDays = 0,
			totalInuseAs = 0,
			maxDailyInuse = 0,
			chargerType = 1, // conventional (will be updated later)
			totalChargeAs = 0;

		const calculatedDailyDetails = {
			batteryUtilization: 0,
			avgDailyEBU: '0',
			ahrReturn: 0,
			maxDailyEBU: '0',
			workingDaysOnly: workingDaysOnly,
			usedAhrs: 0,
			totalAhrs: 0,
			remaingMonthsPerUsage: '',
			totalLifeYears:0,
			usedLifeYears: 0,
			minChargeOppurtinityDuration: 99999,
		};


		let expectedEBU;
		// TODO: Add charger type, for now will assume the charge type is conventional until further update.
		switch (chargerType) {
			case 0: // fast
				// expectedEBU = this.siteAlertsSettings.fast_ebu_limit || 1.6;
				expectedEBU =  1.6;
				break;
			case 1: // conventional
				// expectedEBU = this.siteAlertsSettings.conventional_ebu_limit || 1;
				expectedEBU =  1;
				break;
			case 2: // opportunity
				// expectedEBU = this.siteAlertsSettings.opportunity_ebu_limit || 1.25;
				expectedEBU = 1.25;
				break;
			default:
				expectedEBU = 1.6
				break;
		}

		let sortedDailyDetails = [];
		if (this.dailyDetails.length > 0)
			sortedDailyDetails = this.dailyDetails.sort((a,b) => {return (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0);});

		for (let dayIndex in sortedDailyDetails) {
			let currentDayDailyDetails = sortedDailyDetails[dayIndex];
			let inuseAsValue = currentDayDailyDetails.inuse_as;
			let chargeAsValue = currentDayDailyDetails.charge_as;

			totalChargeAs += chargeAsValue;

			if (currentDayDailyDetails.is_working_day) {
				totalInuseAs += inuseAsValue;
				const dayOfWeekNum = moment(currentDayDailyDetails.date*1000).utc().day();
				if (!calculatedDailyDetails.workingDaysOnly || this.isWorkingDay(dayOfWeekNum))
					countOfWorkingDays++;

				if (currentDayDailyDetails.charge_oppurtinity > 0) {
					if (currentDayDailyDetails.charge_oppurtinity_duration < calculatedDailyDetails.minChargeOppurtinityDuration) {
						calculatedDailyDetails.minChargeOppurtinityDuration = currentDayDailyDetails.charge_oppurtinity_duration;
					}
				}
			}

			if (inuseAsValue > maxDailyInuse)
				maxDailyInuse = inuseAsValue;
		}

		if (countOfWorkingDays > 0) {
			if (totalInuseAs > 0) {
				const averageInuseAhr = (totalInuseAs / countOfWorkingDays) / 3600;
				let averageInuseAhrOverEBU = averageInuseAhr / (0.8 * this.deviceInfo.config_info.battery_capacity);

				calculatedDailyDetails.batteryUtilization = +((averageInuseAhrOverEBU / expectedEBU) * 100).toFixed(0);

				if (calculatedDailyDetails.batteryUtilization > 100)
					calculatedDailyDetails.batteryUtilization = 100;

				if (averageInuseAhrOverEBU > 3)
					averageInuseAhrOverEBU = 3;

				calculatedDailyDetails.avgDailyEBU = decimalPipe.transform((averageInuseAhrOverEBU).toFixed(2));
			}
		}

		if (totalInuseAs != 0)
			calculatedDailyDetails.ahrReturn = (totalChargeAs / totalInuseAs) * 100;

		let max_daily_ebu = maxDailyInuse / (3600 * 0.8 * this.deviceInfo.config_info.battery_capacity);
		if (max_daily_ebu > 3)
			max_daily_ebu = 3;

		calculatedDailyDetails.maxDailyEBU = decimalPipe.transform((max_daily_ebu).toFixed(2));

		if (calculatedDailyDetails.minChargeOppurtinityDuration == 99999)
			calculatedDailyDetails.minChargeOppurtinityDuration = 0;

		let totalAhrs	=  1250 * (+this.deviceInfo.config_info.battery_capacity),
			usedAhrs	= +this.deviceInfo.config_info.inuse_ahr + (+this.deviceInfo.config_info.add_inuse_ahr);

		if(usedAhrs > totalAhrs)
			usedAhrs = totalAhrs;

		let installationDate		= new Date(this.deviceInfo.config_info.installation_date),
			currentDay				= new Date(),
			deviceManufacturingDate	= new Date(this.deviceInfo.config_info.truck_manufacturing_year, 0, 1),
			remaingMonthsPerUsage	= 0,
			batteryUsagePerMonth	= 0,
			maxRemainingMonths		= 0,
			totalLifeYears			= 5,
			usedLifeYears,
			monthsSinceInstallation;

		if (deviceManufacturingDate.getTime() > 1*24*60*60*1000) {
			/* get month in it */
			monthsSinceInstallation = this.getElapsedMonths(deviceManufacturingDate, currentDay);
		} else {
			monthsSinceInstallation = this.getElapsedMonths(installationDate, currentDay);
		}

		if (deviceManufacturingDate.getTime() > 1*24*60*60*1000) {
			usedLifeYears = parseFloat((this.getElapsedMonths(deviceManufacturingDate, currentDay) / 12).toFixed(1));
			if (deviceManufacturingDate < installationDate) {
				const duration = moment.duration(installationDate.getTime() - deviceManufacturingDate.getTime());
				usedAhrs	+= duration.asYears() * batteryUsagePerMonth * 12;
				if (usedAhrs > totalAhrs) {
					usedAhrs = totalAhrs;
				}
			}
		} else {
			usedLifeYears = parseFloat((this.getElapsedMonths(installationDate, currentDay) / 12).toFixed(1));
		}

		if (chargerType == 0) // conventional (will be updated later)
			totalLifeYears = 4;

		maxRemainingMonths = totalLifeYears * 12;

		if (monthsSinceInstallation > 0) {
			batteryUsagePerMonth = usedAhrs / monthsSinceInstallation;
			remaingMonthsPerUsage = (totalAhrs - usedAhrs) / batteryUsagePerMonth;
		}

		if (remaingMonthsPerUsage < 0)
			remaingMonthsPerUsage = 0;

		if(remaingMonthsPerUsage > maxRemainingMonths)
			remaingMonthsPerUsage = maxRemainingMonths;

		if (usedLifeYears > totalLifeYears)
			usedLifeYears = totalLifeYears;

		/* remaining life Ahr */
		calculatedDailyDetails.usedAhrs = usedAhrs;
		calculatedDailyDetails.totalAhrs = totalAhrs;
		calculatedDailyDetails.remaingMonthsPerUsage = decimalPipe.transform(remaingMonthsPerUsage.toFixed(0));

		/* remaining  life Year */
		calculatedDailyDetails.usedLifeYears = usedLifeYears;
		calculatedDailyDetails.totalLifeYears = totalLifeYears;
	}

	isWorkingDay(dayOfWeekNum) {
		return dayOfWeekNum > 0 && dayOfWeekNum < 6;
	}

	workingDaysChanged() {
		let decimalPipe: DecimalPipe = new DecimalPipe("en-US");
		let totalInuseAs            = 0,
			countOfWorkingDays      = 0,
			avgDailyEBU				= null;

		for (let currentDay of this.dailyDetails) {
			if (!this.batterySummary.workingDaysOnly || currentDay.is_working_day) {
				let inuseAsValue = currentDay.inuse_as;
				totalInuseAs += inuseAsValue;
				countOfWorkingDays++;
			}
		}

		if (countOfWorkingDays > 0) {
			if (totalInuseAs > 0) {
				const averageInuseAhr = (totalInuseAs / countOfWorkingDays) / 3600;
				const averageInuseAhrOverEBU = averageInuseAhr / (0.8 * this.deviceInfo.config_info.battery_capacity);

				avgDailyEBU = averageInuseAhrOverEBU;
				if (avgDailyEBU > 3)
					avgDailyEBU = 3;

				avgDailyEBU = decimalPipe.transform((averageInuseAhrOverEBU).toFixed(2));
			}
		}

		this.batterySummary.avg_daily_ebu = avgDailyEBU;
	}

	calcProportion = (a, b) => {
		return Math.round(a / (a + b) * 100);
	}

	getElapsedMonths(start, end) {
		let months;
		months = (end.getFullYear() - start.getFullYear()) * 12;
		months -= start.getMonth() + 1;
		months += end.getMonth();
		return months <= 0 ? 0 : months;
	}

	applyBatterySummaryToCharts() {
		this.utilizationChartData = [
			['value', this.batterySummary.battery_utilization],
			['blank', 100 - this.batterySummary.battery_utilization],
		];
		this.remainingLifeAhrsPercentChartData = [
			['value', this.batterySummary.remaining_life_ahrs_percent],
			['blank', 100 - this.batterySummary.remaining_life_ahrs_percent],
		];
		this.remainingLifeYrsPercentChartData = [
			['value', this.batterySummary.remaining_life_yrs_percent],
			['blank', 100 - this.batterySummary.remaining_life_yrs_percent],
		];

		this.arrowHeight = this.batterySummary.ahrs_return_text;
		if (this.arrowHeight) {
			this.batteryBarHeights = {
				filled:   ( this.arrowHeight                  / this.barMaxPercentage) * this.barHeightsInPixels,
				total:     this.barHeightsInPixels,
				red:      ( (this.limits[2] - this.limits[1]) / this.barMaxPercentage) * this.barHeightsInPixels,
				green:    ( (this.limits[1] - this.limits[0]) / this.barMaxPercentage) * this.barHeightsInPixels,
				orange:   ( this.limits[0]                    / this.barMaxPercentage) * this.barHeightsInPixels,
				arrow:    ( this.arrowHeight                  / this.barMaxPercentage) * 100,
				color: this.arrowHeight < this.limits[0] ? this.limitsColors[0] : this.arrowHeight < this.limits[1] ? this.limitsColors[1] : this.limitsColors[2]
			};
		}
	}

	generateDailyDetailsSummaryData() {
		let DailyDetails: any = {};
		DailyDetails.totalDischargeAHR = 0;
		DailyDetails.totalchargeAHR = 0;
		DailyDetails.countOfWorkingDays = 0;

		let sortedDailyDetails = [];
		if (this.dailyDetails.length > 0)
			sortedDailyDetails = this.dailyDetails.sort((a,b) => {return (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0);});

		for (let index in sortedDailyDetails) {
			const currentDay = this.dailyDetails[index];

			let inuseAsValue = currentDay.inuse_as;
			let chargeAsValue = currentDay.charge_as;


			let inuse_ah = (+inuseAsValue) / 3600;
			let charge_ah = +(chargeAsValue) / 3600;

			let dayOfWeekNum = new Date(currentDay.date * 1000).getDay();

			if (currentDay.is_working_day) {
				DailyDetails.totalWorkingDays++;
				if ([6,0].indexOf(dayOfWeekNum) == -1)
					DailyDetails.countOfWorkingDays++;
			}

			if (inuse_ah != 0)
				DailyDetails.totalDischargeAHR += inuse_ah;

			DailyDetails.totalchargeAHR += charge_ah;
		}

		this.summaryObject = DailyDetails;
	}
}
