import { DeviceConfig, mainDeviceConfig, TypesString } from './device-config';

export class BattlinkDevice implements DeviceConfig {
	config: mainDeviceConfig = {
		sct_device_type: 2,
		isIotah: false,
		isCharglink: false,
		isBattlink: true,
		isBrm: false,
		couldHaveStudy: false,
		hasTypes: false,
		typeString: 'Battlink',
		translationPath: 'battlinkDevice.',
		imageFolder: '/battLink/battlink',
		logoStyleClass: 'battlink-logo',
		mapColor: '#a020f0',
		setLastMaintenanceDate: false,
		hasMaintenanceSchedule: false,
		hasBatterySettings: true,
		CO2data: false,
		permissionMapperKey: 'battlink',
		supportIncorrectEvent: false,
		fields: {
			kwhr: 'inuse_kwhr',
			hasMaxChargerKw: false,
			hasTemperatureSensor: true,
			hasWaterLevel: true,
			hasHallEffect: true,
			trickleVoltage: true,
			cvTargetVoltage: true,
			fiTargetVoltage: true,
			eqVoltage: true,
			trickleCurrentRate: true,
			ccRate: true,
			cvEndCurrentRate: true,
			cvCurrentStep: true,
			fiCurrentRate: true,
			eqCurrentRate: true,
			batteryCapacity: true,
			batteryType: true,
			numberOfCells: true,
			batteryTemperatureCompensationInMv: true,
			fiDt: true,
			fiDv: true,
			liftCurrentThresholdPercentage: false,
			liftCurrentThresholdValue: false,
			liftCurrentThresholdDeltaPercentage: false,
			liftCurrentThresholdDeltaValue: false,
			deadManTime: true,
			returnFactor: false,
			batteryEfficiency: false,
			chargerEfficiency: false,
			deadManCurrentLimit: false,
			chargerType: true,
		},
		requiredFields: {
			truckType: false,
			truckManufacturer: false,
			truckManufacturingYear: true,
			currentLiftLowerThreshold: false,
			currentLiftUpperThreshold: false,
		},
		incorrectEvent: {
			type: 'charge',
		},
		quarterlyUsage: {
			hasTypeFilter: false,
			hasYearFilter: false,
			performanceChartType: 't',
			hasChartLegend: false,
			chartGridColumns: 12,
			hasTotalTravelTimeChart: false,
			hasTotalLiftTimeChart: false,
			period: 'months',
			perfAnalyticsKey: 'perf_analytic.monthly_data',
		},
		reporting: {
			device: 'battlink',
		},
		dailyDetails: {
			hasField: {
				billed_kwh: true,
				accurate_inuse_seconds: true,
				lift_time: true,
				travel_time: true,
				ah_h: true,
				ebu: true,
				missed_eq: true,
				missed_fi: true,
			},
			hasTypes: {
				endVoltage: false,
				idleTimes: false,
			},
			hasOrPowerStudy: {
				min_soc: true,
			},
			useBilledKwh: false
		},
		events: {
			hasFields: {
				billed_kwh: true,
				lift_time: false,
				travel_time: false,
				accurate_inuse_seconds: true,
				flag_finish: true,
				flag_eq: true,
				flag_finish_start: true,
				flag_eq_start: true,
				finish_reason: true,
				eq_reason: true,
			},
			hasOrPowerStudy: {
				start_soc: true,
				end_soc: true,
			}
		},
		usage: {
			summaryField: 'usage_summary',
			accurateSecondsField: 'accurate_inuse_seconds'
		},
		performance: {
			hasEndVoltageReport: true,
			hasIdleEventsReport: true,
			hasRegenReport: true,
			hasUsageAsReport: true,
			hasUsageTimeWidget: false,
			usageSummaryKey: 'amp_usage_summary',
			kwuUsageSummaryKey: 'kw_usage_summary',
			hasDataWidget: true,
			hasRelativeUsageWidget: true,
			hasBatteriesCustomizedCharts: true,
			chargeOpportunity: true,
			hasBatteryPerformance: true,
		},
		customer: {
			mix_quarterly_usage_kwh: false
		},
	};

	getImagePath(imageType: 'not_connected' | 'connected' | 'inuse' | 'idle', options: {is_study_device: boolean} = {is_study_device : false}): string {
		let imageLink = '/images' + this.config.imageFolder;

		switch (imageType) {
			case 'not_connected':
				imageLink += `-offline.png`;
				break;
			case 'connected':
				imageLink += `-online.png`;
				break;

			default:
				imageLink += `-offline.png`;
				break;
		}

		return imageLink;
	}

	getEventImage(eventType: string, is_study_device: boolean): string {
		return this.getImagePath('connected', {is_study_device});
	}

	getTypesString(options: {getPowerStudyDevices: boolean, is_study_device: boolean}): TypesString {
		return 'BattLink';
	}
}
